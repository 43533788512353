import WidgetsIcon from "@mui/icons-material/Widgets";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import {
  DamageTypeDto,
  PaginationDtoOfDamageTypeDto,
  DamageTypeSearchPaginatedDto,
} from "@/core/api/generated";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import AppIcon from "@/common/components/Icons/AppIcon";

const generalGroupName = "General";
const isProDamageTypeGroup = (groupName: string) => groupName !== generalGroupName;

export type DamageTypeAutocompleteOption = BaseAutocompleteOption<DamageTypeDto>;

const filter = createFilterOptions<DamageTypeAutocompleteOption>();

const damageTypeToOption = (data: DamageTypeDto): DamageTypeAutocompleteOption => ({
  id: data.id!,
  title: StringHelper.joinIntoString([data.name], ", ", {
    skipEmpty: true,
  }),
  optionType: AutocompleteOptionType.Normal,
  groupBy: data.isCategoryRoot ? generalGroupName : data.category,
  data: data,
});
const damageTypesToOptions = (data?: DamageTypeDto[] | null): DamageTypeAutocompleteOption[] =>
  (data && data.map((x) => damageTypeToOption(x))) || [];

const sortOptions = (options: DamageTypeAutocompleteOption[]): DamageTypeAutocompleteOption[] => {
  return _.chain(options)
    .groupBy((x) => x.groupBy)
    .mapValues((v, k) => _.orderBy(v, (x) => x.data?.sortOrder, "asc"))
    .values()
    .flatten()
    .value();
};

interface DamageTypeAutocompleteProps {
  damageTypeId?: string | null;
  damageType?: DamageTypeDto;
  searchFilters?: Pick<
    DamageTypeSearchPaginatedDto,
    "category" | "categories" | "includeIds" | "vehiclePartTypeId" | "isEnabledForDamageDetection"
  >;
  isPreload?: boolean;
  disabled?: boolean;
  required?: boolean;
  size?: "small" | "medium";
  onChange?: (newDamageType?: DamageTypeDto | null) => void;
}

function DamageTypeAutocomplete({
  damageTypeId,
  damageType,
  searchFilters,
  isPreload = true,
  disabled,
  required,
  size,
  onChange,
}: DamageTypeAutocompleteProps) {
  const includeIds = damageTypeId
    ? [damageTypeId, ...(searchFilters?.includeIds || [])]
    : searchFilters?.includeIds;

  const [open, setOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [options, setOptions] = useState<readonly DamageTypeAutocompleteOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<DamageTypeAutocompleteOption | null>(null);
  const [limit, setLimit] = useState(50);
  const [inputValue, setInputValue] = useState("");

  const paginatedDamageTypesRequest = useApiRequest(
    apiClient.damageTypesApi.apiV1ReferenceDataDamageTypesSearchPost,
    {
      damageTypeSearchPaginatedDto: {
        limit,
        search: inputValue,
        category: searchFilters?.category || undefined,
        categories: searchFilters?.categories || undefined,
        isCategoryRoot: undefined,
        isEnabledForDamageDetection: true,
        includeIds,
        ids: undefined,
        vehiclePartTypeId: searchFilters?.vehiclePartTypeId || undefined,
      },
    },
    {
      deps: [searchFilters, inputValue],
      debouncedDeps: {
        deps: [searchFilters],
        wait: 500,
        options: { leading: false, trailing: true },
      },
    },
  );
  const paginatedDamageTypes = paginatedDamageTypesRequest.data;

  useEffect(() => {
    if (
      (damageTypeId && !options.some((x) => x.id === damageTypeId)) ||
      (includeIds && !includeIds.every((id) => options.some((o) => o.id === id)))
    ) {
      paginatedDamageTypesRequest.refetch();
    }
  }, [damageTypeId, includeIds, options]);

  useEffect(() => {
    const newOptions = [
      ...(selectedOption ? [selectedOption] : []),
      ...damageTypesToOptions(paginatedDamageTypes?.items || []),
    ];
    setOptions(sortOptions(_.uniqBy(newOptions, (x) => x.id)));

    if (damageTypeId && selectedOption?.id !== damageTypeId) {
      setSelectedOption(newOptions.find((x) => x.id === damageTypeId) || null);
    }
  }, [paginatedDamageTypes, damageTypeId, selectedOption]);

  useEffect(() => {
    if (!damageType && !damageTypeId) {
      setSelectedOption(null);
    } else if (damageType && selectedOption?.id !== damageType.id) {
      setOptions(
        sortOptions(damageTypesToOptions(_.uniqBy([damageType, ...options], (x) => x.id))),
      );
      setSelectedOption(damageTypeToOption(damageType));
    } else if (damageTypeId && selectedOption?.id !== damageTypeId) {
      setSelectedOption(options.find((x) => x.id === damageTypeId) || null);
    }
  }, [damageTypeId, damageType, selectedOption, options]);

  return (
    <Autocomplete
      sx={{ minWidth: 200 }}
      disabled={disabled}
      size={size}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.title}
      filterOptions={(x) => x} // disable the built-in filtering
      groupBy={(option) => option.groupBy || ""}
      options={options}
      loading={isSearching}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={selectedOption}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        setSelectedOption(newValue);
        onChange && onChange(newValue?.data);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Damage type'
          placeholder='Search...'
          fullWidth
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isSearching ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderGroup={(params) => (
        <ListItem key={params.key} disablePadding>
          <Stack sx={{ width: "100%" }}>
            <ListSubheader component='div' sx={{ position: "sticky", top: "-8px" }}>
              <span>{params.group}</span>
              {isProDamageTypeGroup(params.group) && (
                <Chip size='small' color='primary' variant='outlined' label='Pro' sx={{ ml: 1 }} />
              )}
            </ListSubheader>
            <List disablePadding>{params.children}</List>
          </Stack>
        </ListItem>
      )}
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              <AppIcon of='widgets' />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography component='div' variant='body1'>
                  {option.title}
                </Typography>
              }
              secondary={
                <Typography component='div' variant='body2' color='secondary'>
                  {option.data?.description}
                </Typography>
              }
            />
          </ListItem>
        );
      }}
    />
  );
}

export default DamageTypeAutocomplete;

import { useHistory, useParams } from "react-router";

import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import { useQueryParams } from "@/common/hooks/useQueryParams";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import VisualInspectionCreateUpdate from "@/common/components/Entity/VisualInspection/VisualInspectionCreateUpdate";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { ROUTE_PATH } from "@/common/constants/routing";

export interface VisualInspectionCreateUpdateQueryParams extends GeneralQueryParams {
  vehicleId?: string | null;
  contractId?: string | null;
}

export default function VisualInspectionCreateUpdatePage() {
  const { visualInspectionId } = useParams<{ visualInspectionId?: string }>();
  const { vehicleId, contractId } = useQueryParams<VisualInspectionCreateUpdateQueryParams>();
  const history = useHistory();
  const isCreate = !visualInspectionId;
  const isEdit = !!visualInspectionId;

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new visual inspection" : "Edit visual inspection"}
        />
      }
    >
      <VisualInspectionCreateUpdate
        visualInspectionId={visualInspectionId}
        defaultValues={{
          vehicleId: vehicleId || undefined,
          contractId: contractId || undefined,
        }}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.VISUAL_INSPECTION_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}

import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { enumService } from "@/common/services/enum";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { VehicleDto, VehicleSearchPaginatedDto } from "@/core/api/generated";

export type VehicleAutocompleteOption = BaseAutocompleteOption<VehicleDto>;

export const getVehicleOptionTitle = (data: VehicleDto): string =>
  StringHelper.joinIntoString(
    [
      data.plateNo?.plateNo || data.identificationNumber || data.localNumber,
      data.spec?.make?.name,
      data.spec?.model?.name,
      data.spec?.generation?.name,
      data.spec?.modification?.name,
      data.spec?.manufactureYear?.toString(),
      enumService.getEnumValueName("VehicleBodyType", data?.spec?.bodyType),
      enumService.getEnumValueName("VehicleFuelType", data?.spec?.fuelType),
    ],
    ", ",
    {
      skipEmpty: true,
    },
  );

export const vehicleToOption = (data: VehicleDto): VehicleAutocompleteOption => ({
  id: data.id!,
  title: getVehicleOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface VehicleAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<VehicleDto> {
  searchFilters?: Partial<Omit<VehicleSearchPaginatedDto, "search" | "includeIds">>;
}

export default function VehicleAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: VehicleAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={vehicleToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.vehiclesApi.apiV1VehiclesSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          vehicleSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          vehicleSearchPaginatedDto: {
            ...params.vehicleSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Vehicle'
      placeholder='Search for vehicle...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='vehicle' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant={
                    option.optionType === AutocompleteOptionType.DynamicCreateNew
                      ? "subtitle1"
                      : "body1"
                  }
                  component='div'
                >
                  {option.title}
                </Typography>
              }
              secondary={
                option.data && (
                  <Typography component='div' variant='body2' color='secondary'>
                    {StringHelper.joinIntoString(
                      [
                        // enumService.getEnumValueName("VehicleType", option.data.spec?.type),
                        enumService.getEnumValueName("VehicleBodyType", option.data.spec?.bodyType),
                        enumService.getEnumValueName("VehicleFuelType", option.data.spec?.fuelType),
                        enumService.getEnumValueName(
                          "VehicleGearboxType",
                          option.data.spec?.gearboxType,
                        ),
                        enumService.getEnumValueName(
                          "VehicleDrivetrainType",
                          option.data.spec?.drivetrainType,
                        ),
                      ],
                      ", ",
                      {
                        skipEmpty: true,
                      },
                    )}
                  </Typography>
                )
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

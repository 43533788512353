import { AssetSubscriptionSpotInfoDto } from "@/core/api/generated";
import { Stack, Typography } from "@mui/material";
import Datetime from "../../Datetime/Datetime";
import FieldValue from "../../Form/Display/FieldValue";
import DepartmentLink from "../Department/DepartmentLink";
import LocationLink from "../Location/LocationLink";
import ProductLocationLink from "../ProductLocation/ProductLocationLink";
import SpotLink from "../Spot/SpotLink";

interface Props {
  title: string;
  info: AssetSubscriptionSpotInfoDto | null | undefined;
}

export default function AssetSubscriptionSpotInfoDisplay({ title, info }: Props) {
  return (
    <Stack spacing={1}>
      <Typography component='div' variant='h6'>
        {title}
      </Typography>

      <FieldValue label='Date & Time' isEmpty={!info?.date}>
        <Datetime datetime={info?.date} />
      </FieldValue>

      {info?.departmentId && (
        <FieldValue label='Department' isEmpty={!info?.departmentId}>
          <DepartmentLink entity={undefined} entityId={info?.departmentId} />
        </FieldValue>
      )}

      {info?.locationId && (
        <FieldValue label='Location' isEmpty={!info?.locationId}>
          <LocationLink entity={undefined} entityId={info?.locationId} />
        </FieldValue>
      )}

      {info?.productLocationId && (
        <FieldValue label='Sales location' isEmpty={!info?.productLocationId}>
          <ProductLocationLink entity={undefined} entityId={info?.productLocationId} />
        </FieldValue>
      )}

      <FieldValue label='Spot' isEmpty={!info?.spot?.id}>
        <SpotLink entity={undefined} entityId={info?.spot?.id} />
      </FieldValue>

      <FieldValue label='Notes' isEmpty={!info?.notes}>
        {info?.notes}
      </FieldValue>
    </Stack>
  );
}

import { Box, Button, Chip, IconButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { useCallback, useState } from "react";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import CustomerInline from "@/common/components/Entity/Customer/CustomerInline";
import DepartmentInline from "@/common/components/Entity/Department/DepartmentInline";
import RoleInline from "@/common/components/Entity/Role/RoleInline";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { clipboardService } from "@/common/services/clipboard";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  InviteType,
  TenantInviteDto,
  TenantInviteGetPaginatedDto,
} from "@/core/api/generated";
import * as tenantInvitesSlice from "@/store/management/tenantInvites/slice";
import { GridSortItem } from "@mui/x-data-grid";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
  create: true,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function Invites({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const thunkDispatch = useAppThunkDispatch();
  const commonRequestParams = useCommonRequestParams<TenantInviteGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.Invite,
    },
  });

  // const { offset, setOffset, limit } = usePaginationRequest({});
  // const [search, setSearch] = useState("");
  // const [inviteType, setInviteType] = useState<InviteType | undefined>(undefined);
  // const [inviteUserType, setInviteUserType] = useState<InviteUserType | undefined>(undefined);
  // const [allRoleIds, setAllRoleIds] = useState<string[] | undefined>(undefined);

  const [inviteToResend, setInviteToResend] = useState<TenantInviteDto | null>(null);
  const [inviteToDelete, setInviteToDelete] = useState<TenantInviteDto | null>(null);
  const [confirmResendInviteModalOpen, setConfirmResendInviteModalOpen] = useState(false);
  const [confirmDeleteInviteModalOpen, setConfirmDeleteInviteModalOpen] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortItem | undefined>(undefined);

  const paginatedInvitesRequest = useApiRequest(
    apiClient.tenantInvitesApi.apiV1TenantInvitesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantInviteGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedInvites = paginatedInvitesRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Invite)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedInvitesRequest.handleEntityChanged(data);
    },
  });

  const handleResendPersonalInvite = useCallback(async () => {
    try {
      await thunkDispatch(
        tenantInvitesSlice.resendPersonalInvite({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          inviteId: inviteToResend!.id!,
        }),
      );
      enqueueSnackbar("Invite resent", { variant: "success" });
      setConfirmResendInviteModalOpen(false);
    } catch (err: any) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [inviteToResend]);

  const handleDeleteInvite = useCallback(async () => {
    try {
      await thunkDispatch(
        tenantInvitesSlice.deleteInvite({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          inviteId: inviteToDelete!.id!,
        }),
      );
      enqueueSnackbar("Invite deleted", { variant: "success" });
      paginatedInvitesRequest.refetch();
      setConfirmDeleteInviteModalOpen(false);
    } catch (err: any) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [inviteToDelete]);

  return (
    <Box>
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title={undefined}
            primaryActions={
              displayProps?.create && (
                <>
                  <AuthorizedElement permissionsAny={[AppPermission.TenantInviteManage]}>
                    <Button
                      variant='contained'
                      color='primary'
                      startIcon={<AppIcon of='add' />}
                      component={AppLink}
                      to={ROUTE_PATH.MANAGEMENT_INVITES_CREATE_PERSONAL()}
                    >
                      New personal invite
                    </Button>
                  </AuthorizedElement>

                  <AuthorizedElement permissionsAny={[AppPermission.TenantInviteManage]}>
                    <Button
                      sx={{ ml: 1 }}
                      variant='contained'
                      color='primary'
                      startIcon={<AppIcon of='add' />}
                      component={AppLink}
                      to={ROUTE_PATH.MANAGEMENT_INVITES_CREATE_ANONYMOUS()}
                    >
                      New anonymous invite
                    </Button>
                  </AuthorizedElement>
                </>
              )
            }
          />
        }
      >
        <Stack>
          <DataTabular<TenantInviteDto>
            columns={[
              {
                field: "type",
                title: "Type",
                flex: 1,
                renderCell: (item) => <InlineApiEnumValue type='InviteType' value={item.type} />,
              },
              {
                field: "userType",
                title: "User type",
                flex: 1,
                renderCell: (item) => (
                  <InlineApiEnumValue type='InviteUserType' value={item.userType} />
                ),
              },
              {
                field: "currentUsers",
                title: "Current/Max users",
                flex: 1,
                renderCell: (item) => (
                  <>
                    {item.currentUsers} / {item.maxUsers}
                  </>
                ),
              },
              {
                field: "userInfo.email",
                title: "Receiver",
                flex: 2,
                renderCell: (item) =>
                  item.userInfo ? (
                    <Stack direction='column'>
                      <Box>{item.userInfo?.email}</Box>
                      {/* <Box>{item.userInfo?.phoneNumber}</Box>
                      <Box>
                        {item.userInfo?.personName?.firstName} {item.userInfo?.personName?.lastName}
                      </Box> */}
                    </Stack>
                  ) : (
                    "-"
                  ),
              },
              {
                field: "sentAt",
                title: "Sent at",
                flex: 1,
                renderCell: (item) => (
                  <Datetime datetime={item.sentAt} direction='column' withDurationFromNow />
                ),
              },
              {
                field: "expiresAt",
                title: "Expires at",
                flex: 1,
                renderCell: (item) => (
                  <>
                    <Datetime datetime={item.expiresAt} direction='column' withDurationFromNow />{" "}
                    {item.isExpired && (
                      <Chip
                        size='small'
                        color='error'
                        variant='outlined'
                        label='Expired'
                        sx={{ ml: 1 }}
                      />
                    )}
                  </>
                ),
              },
              {
                field: "roleIds",
                title: "Roles",
                flex: 1,
                renderCell: (item) => (
                  <Stack>
                    {item?.roleIds?.map((id, i) => (
                      <RoleInline key={i} entity={undefined} entityId={id} />
                    ))}
                  </Stack>
                ),
              },
              {
                field: "departmentId",
                title: "Departments",
                flex: 1,
                renderCell: (item) =>
                  item.departmentId ? (
                    <DepartmentInline entity={undefined} entityId={item.departmentId} />
                  ) : (
                    "-"
                  ),
              },
              {
                field: "customerId",
                title: "Customer",
                flex: 1,
                renderCell: (item) =>
                  item.customerId ? (
                    <CustomerInline entity={undefined} entityId={item.customerId} />
                  ) : (
                    "-"
                  ),
              },
            ]}
            rows={paginatedInvitesRequest.data?.items}
            isLoading={paginatedInvitesRequest.isLoading}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.MANAGEMENT_INVITE_VIEW(item.id)}
            renderRowAction={({ item }) => (
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <AuthorizedMenuItem
                  permissions={[AppPermission.TenantInviteRead]}
                  onClick={async () => {
                    try {
                      await clipboardService.writeText(item.inviteUrl as string);
                      enqueueSnackbar("Link copied to clipboard.", {
                        variant: "success",
                      });
                    } catch (error) {
                      console.error("Clipboard copy error", error);
                    }
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='link' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Copy link</ListItemText>
                </AuthorizedMenuItem>

                <AuthorizedMenuItem
                  permissions={[AppPermission.TenantInviteManage]}
                  component={AppLink}
                  to={
                    (item.type === InviteType.Personal
                      ? ROUTE_PATH.MANAGEMENT_INVITES_CREATE_PERSONAL({ sourceInviteId: item.id })
                      : undefined) ||
                    (item.type === InviteType.Anonymous
                      ? ROUTE_PATH.MANAGEMENT_INVITES_CREATE_ANONYMOUS({ sourceInviteId: item.id })
                      : undefined)
                  }
                >
                  <ListItemIcon>
                    <AppIcon of='add' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Create new from this invite</ListItemText>
                </AuthorizedMenuItem>

                <AuthorizedMenuItem
                  permissions={[AppPermission.TenantInviteManage]}
                  disabled={item.isExpired || item.type !== InviteType.Personal}
                  onClick={() => {
                    setInviteToResend(item);
                    setConfirmResendInviteModalOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='email' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Resend</ListItemText>
                </AuthorizedMenuItem>

                <AuthorizedMenuItem
                  permissions={[AppPermission.TenantInviteManage]}
                  onClick={() => {
                    setInviteToDelete(item);
                    setConfirmDeleteInviteModalOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='delete' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </AuthorizedMenuItem>
              </MenuWithTrigger>
            )}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            refetch={commonRequestParams.dataTabularProps.refetch}
          />
        </Stack>

        {/* Resend confirmation */}
        {inviteToResend && (
          <ConfirmationModal
            title='Resend the invite?'
            body={
              <>
                {`You're going to resend the invite to`}{" "}
                <Box>
                  <strong>{inviteToResend.userInfo?.email}</strong>
                </Box>
              </>
            }
            open={confirmResendInviteModalOpen}
            onClose={() => setConfirmResendInviteModalOpen(false)}
            onCancel={() => setConfirmResendInviteModalOpen(false)}
            onConfirm={handleResendPersonalInvite}
          />
        )}

        {/* Delete confirmation */}
        {inviteToDelete && (
          <ConfirmationModal
            title='Delete the invite?'
            body={
              <>
                {`You're going to delete the invite`}. {`This action can't be undone.`}
              </>
            }
            open={confirmDeleteInviteModalOpen}
            onClose={() => setConfirmDeleteInviteModalOpen(false)}
            onCancel={() => setConfirmDeleteInviteModalOpen(false)}
            onConfirm={handleDeleteInvite}
          />
        )}
      </ViewLayout>
    </Box>
  );
}

import { Stack, SxProps, Theme } from "@mui/material";

import { ContractDto } from "@/core/api/generated";

import _ from "lodash";
import BooleanValue from "../../Form/Display/BooleanValue";
import FieldValue from "../../Form/Display/FieldValue";

export interface OwnProps {
  contract: ContractDto;
  sx?: SxProps<Theme>;
}

export type ContractReminderSettingsDisplayProps = OwnProps;

export default function ContractReminderSettingsDisplay({
  contract,
  sx,
}: ContractReminderSettingsDisplayProps) {
  return (
    <Stack sx={sx}>
      <Stack spacing={1}>
        <FieldValue
          label='Require visual inspection before check-out'
          isEmpty={_.isNil(contract.settings?.isRequireVisualInspectionBeforeCheckOut)}
        >
          <BooleanValue value={contract.settings?.isRequireVisualInspectionBeforeCheckOut} />
        </FieldValue>

        <FieldValue
          label='Require visual inspection before check-in'
          isEmpty={_.isNil(contract.settings?.isRequireVisualInspectionBeforeCheckIn)}
        >
          <BooleanValue value={contract.settings?.isRequireVisualInspectionBeforeCheckIn} />
        </FieldValue>
      </Stack>
    </Stack>
  );
}

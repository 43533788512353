import { Box, Chip, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";

import BaseEntitiesSearchAutocomplete, {
  InheritableBaseEntitiesSearchAutocompleteProps,
} from "@/common/components/Entity/components/BaseEntitiesSearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { AutocompleteOptionType } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { AssetDto, AssetSearchPaginatedDto } from "@/core/api/generated";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import GeneralStrictEntityRelationInline from "../General/Display/GeneralStrictEntityRelationInline";
import { entityToOption } from "./AssetAutocomplete";

export interface AssetsAutocompleteProps
  extends InheritableBaseEntitiesSearchAutocompleteProps<AssetDto> {
  searchFilters?: Partial<Omit<AssetSearchPaginatedDto, "search" | "includeIds">>;
}

export default function AssetsAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: AssetsAutocompleteProps) {
  return (
    <BaseEntitiesSearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={entityToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.assetsApi.apiV1AssetsSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          assetSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          assetSearchPaginatedDto: {
            ...params.assetSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Assets'
      placeholder='Search for assets...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='asset' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    <GeneralStrictEntityRelationInline
                      value={option.data?.entity}
                      inlineProps={{
                        withIcon: false,
                      }}
                    />
                  </Typography>
                </>
              }
              secondary={
                <Typography component='div' variant='body2' color='secondary'>
                  {option.data && (
                    <Box>
                      <Stack direction='row' spacing={1}>
                        <Chip
                          variant='outlined'
                          size='small'
                          color='secondary'
                          label={option.data.localNumber}
                        />
                        {option.data.entityType && (
                          <Chip
                            variant='outlined'
                            size='small'
                            color='secondary'
                            label={
                              <InlineApiEnumValue
                                type='AssetEntityType'
                                value={option.data.entityType}
                              />
                            }
                          />
                        )}
                      </Stack>
                    </Box>
                  )}
                </Typography>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

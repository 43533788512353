import GeneralPriceDisplay from "@/common/components/Entity/General/GeneralPrice/GeneralPriceDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import GeneralPriceSummaryInline from "@/common/components/PriceSummary/GeneralPriceSummaryInline";
import {
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  SubscriptionPlanPriceDto,
} from "@/core/api/generated";
import { Box } from "@mui/material";
import BaseEntityInline, {
  BaseEntityInlineInheritableProps,
} from "../../components/BaseEntityInline";

interface Props extends BaseEntityInlineInheritableProps {
  basePrice: SubscriptionPlanPriceDto | null | undefined;
  price: SubscriptionPlanPriceDto | null | undefined;
  currency: GeneralCurrencyDto | GeneralCurrencyInputDto | null | undefined;
}

/** Inline brief information about the entity. */
export default function SubscriptionPlanPriceInline({
  basePrice,
  price,
  currency,
  ...otherProps
}: Props) {
  if (!price) {
    return null;
  }

  return (
    <BaseEntityInline
      entity={price}
      icon={<AppIcon of='price' inText />}
      content={
        <>
          <GeneralPriceDisplay
            price={{
              price: price?.total,
              currency: currency || undefined,
            }}
          />{" "}
          per <InlineApiEnumValue type='BillingPeriod' value={basePrice?.period} />{" "}
          {basePrice?.period !== price?.period && (
            <>
              (when paid every <InlineApiEnumValue type='BillingPeriod' value={price?.period} />)
            </>
          )}
        </>
      }
      {...otherProps}
      tooltipTitle={
        otherProps?.tooltipTitle || (
          <Box>
            <Box>Subscription plan price</Box>
            <Box>
              <GeneralPriceSummaryInline
                summary={{
                  currency: currency,
                  subTotal: price?.subTotal,
                  total: price?.total,
                  discount: price?.discount,
                  tax: price?.tax,
                }}
              />
            </Box>
          </Box>
        )
      }
    />
  );
}

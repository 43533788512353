import { UseApiRequestBaseResult } from "@/common/hooks/api/useApiRequest";
import { IBaseEntityDto } from "@/core/api/generated";
import { Box } from "@mui/material";
import { ReactNode } from "react";
import AppTooltip from "../../AppTooltip";
import AppIcon from "../../Icons/AppIcon";
import TextLineSkeleton from "../../Skeleton/TextLineSkeleton";

export interface BaseEntityInlineProps {
  entity: IBaseEntityDto | null | undefined;
  request?: UseApiRequestBaseResult;
  content: ReactNode;
  icon?: ReactNode;
  withIcon?: boolean;
  withTooltip?: boolean;
  tooltipTitle?: ReactNode;
}

export interface BaseEntityInlineInheritableProps {
  fetch?: boolean;
  withIcon?: boolean;
  withTooltip?: boolean;
  tooltipTitle?: ReactNode;
}

/** Inline brief information about the entity. */
export default function BaseEntityInline({
  entity,
  request,
  content,
  icon,
  withIcon = true,
  withTooltip = false,
  tooltipTitle = "",
}: BaseEntityInlineProps) {
  const isRequestFailed =
    (request && request.isEnded && request.responseDetails && !request.responseDetails.isOk) ??
    false;

  if (request && !request.isEnded && request.isLoading) {
    return <TextLineSkeleton sx={{ display: "inline-block", minWidth: 40 }} />;
  }
  if (!entity && !isRequestFailed) {
    return null;
  }

  return (
    <AppTooltip
      enabled={withTooltip}
      title={tooltipTitle}
      wrapperProps={{ component: "span", sx: { maxWidth: "100%", display: "inline-block" } }}
    >
      <Box component='span' sx={{ maxWidth: "100%", display: "inline-block" }}>
        {isRequestFailed && (
          <Box component='span'>
            {withIcon && (
              <Box component='span' sx={{ mr: 1 }}>
                <AppIcon of='error' inText />
              </Box>
            )}

            <Box component='span'>
              {(request?.responseDetails?.isBadRequest && "Bad request") ||
                (request?.responseDetails?.isUnauthorized && "Login required") ||
                (request?.responseDetails?.isUnauthorized && "Login required") ||
                (request?.responseDetails?.isForbidden && "Insufficient permissions") ||
                (request?.responseDetails?.isNotFound && "Entity not found") ||
                (request?.responseDetails?.isInternalServerError && "Internal error") ||
                "Error"}
            </Box>
          </Box>
        )}

        {!isRequestFailed && (
          <Box component='span' sx={{ maxWidth: "100%", display: "flex", flexDirection: "row" }}>
            {withIcon && icon && (
              <Box component='span' sx={{ mr: 1 }}>
                {icon}
              </Box>
            )}

            <Box
              component='span'
              sx={{
                // display: "inline-block",
                width: "100%",
                minWidth: 0,
              }}
            >
              {content}
            </Box>
          </Box>
        )}
      </Box>
    </AppTooltip>
  );
}

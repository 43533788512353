import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Theme,
  Tooltip,
} from "@mui/material";

import { SortOrder } from "@/core/api/generated";
import AppIcon from "../../Icons/AppIcon";

interface FieldItem {
  field: string;
  title: string;
}

interface SortSelectorProps {
  sortBy?: string;
  sortOrder?: SortOrder;
  fields: FieldItem[];
  onChange?: (sortBy?: string, sortOrder?: SortOrder) => void;
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
}

export default function SortSelector({
  sortBy = undefined,
  sortOrder = undefined,
  fields,
  onChange,
  size = "medium",
  sx,
}: SortSelectorProps) {
  if (sortBy !== undefined && !fields.some((x) => x.field === sortBy)) {
    throw new Error("Invalid 'field' value");
  }
  if (
    sortOrder !== undefined &&
    ![SortOrder.Asc, SortOrder.Desc, SortOrder.None].some((x) => x === sortOrder)
  ) {
    throw new Error("Invalid 'order' value");
  }

  sortOrder = sortOrder || SortOrder.None;

  const handleChangeOrder = () => {
    switch (sortOrder) {
      case SortOrder.Asc:
        onChange && onChange(sortBy, SortOrder.Desc);
        break;
      case SortOrder.Desc:
        onChange && onChange(sortBy, SortOrder.None);
        break;
      case SortOrder.None:
        onChange && onChange(sortBy, SortOrder.Asc);
        break;
    }
  };

  return (
    <Stack direction='row' sx={{ alignItems: "center", ...sx }}>
      <FormControl size={size} sx={{ width: "-webkit-fill-available" }}>
        <InputLabel>Sort by</InputLabel>
        <Select
          displayEmpty={false}
          autoWidth
          placeholder='Sort by...'
          label='Sort by'
          value={fields.find((x) => x.field === sortBy)?.field || ""}
          onChange={(e) => onChange && onChange(e.target.value, sortOrder)}
        >
          {fields.map((item, index) => (
            <MenuItem key={index} value={item.field}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Tooltip
        title={
          <>
            {(sortOrder === undefined || sortOrder === SortOrder.None) && "None"}
            {sortOrder === SortOrder.Asc && "Asc"}
            {sortOrder === SortOrder.Desc && "Desc"}
          </>
        }
      >
        <IconButton onClick={handleChangeOrder} sx={{ width: "40px", height: "40px", ml: 1 }}>
          {(sortOrder === undefined ||
            sortOrder === SortOrder.Asc ||
            sortOrder === SortOrder.None) && (
            <ArrowUpwardIcon sx={{ opacity: sortOrder === SortOrder.None ? 0.3 : 1 }} />
          )}
          {sortOrder === SortOrder.Desc && <AppIcon of='arrowDownward' />}
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

import { LoadingButton } from "@mui/lab";
import { Paper, Stack, TextField, Typography } from "@mui/material";
import { getIn, useFormik } from "formik";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import GeneralBrandingInputs from "@/common/components/Entity/General/GeneralBranding/GeneralBrandingInputs";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import PhoneInput from "@/common/components/Form/Input/PhoneInput";
import { FormikHelper } from "@/common/helpers/formik";
import { ObjectHelper } from "@/common/helpers/object";
import { useTenantProfile } from "@/common/hooks/entity/tenant/useTenantProfile";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { GeneralBrandingInputDto, TenantProfileUpdateDto } from "@/core/api/generated";
import * as tenantSlice from "@/store/tenantProfile/slice";
import { useHistory } from "react-router";

export default function CompanyProfileEditPage() {
  const mounted = useMounted();
  const profile = useTenantProfile();
  const history = useHistory();
  const thunkDispatch = useAppThunkDispatch();

  const formik = useFormik<TenantProfileUpdateDto & BaseFormikValues>({
    enableReinitialize: true,
    initialValues: {
      name: profile?.name || undefined,
      description: profile?.description || undefined,
      contactPerson: {
        personName: {
          firstName: profile?.contactPerson?.personName?.firstName || undefined,
          lastName: profile?.contactPerson?.personName?.lastName || undefined,
        },
        email: profile?.contactPerson?.email || undefined,
        phoneNumber: profile?.contactPerson?.phoneNumber || undefined,
      },
      branding: {
        fullLegalName: profile?.branding?.fullLegalName || undefined,
        shortName: profile?.branding?.shortName || undefined,
        disclaimer: profile?.branding?.disclaimer || undefined,
        logo: profile?.branding?.logo || undefined,
      },
      submit: "",
    },
    validationSchema: Yup.object().shape({
      // name: Yup.string().required("Name is required"),
      // description: Yup.string().required("Description is required"),
      // contactPerson: Yup.object().shape({
      //   firstName: Yup.string().required("First name is required"),
      //   lastName: Yup.string().required("Last name is required"),
      //   email: Yup.string().required("Email is required"),
      //   phoneNumber: Yup.string().required("Phone number is required"),
      // }),
      // branding: Yup.object().shape({
      //   fullLegalName: Yup.string().required("Full legal name of branding is required"),
      //   shortName: Yup.string().required("Short name is required"),
      //   disclaimer: Yup.string(),
      //   logo: Yup.object(),
      // }),
    }),
    onSubmit: async (values, { setFieldError, setStatus, setSubmitting }) => {
      try {
        await thunkDispatch(
          tenantSlice.updateTenantProfile({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            tenantProfileUpdateDto: {
              name: values.name,
              description: values.description,
              contactPerson: values.contactPerson,
              branding: ObjectHelper.isValuesEmpty(values.branding) ? undefined : values.branding,
            },
          }),
        );

        if (mounted.current) {
          setStatus({ success: true });
          setSubmitting(false);
        }
        history.goBack();
      } catch (err: any) {
        if (mounted.current) {
          ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
  } = formik;

  const brandingFormikProps = FormikHelper.getSubProps<
    TenantProfileUpdateDto & BaseFormikValues,
    GeneralBrandingInputDto | undefined
  >(formik, "branding", (v) => v.branding);

  return (
    <CreateUpdatePageLayout header={<SimpleViewPageHeader title={"Company profile edit"} />}>
      <form noValidate onSubmit={handleSubmit}>
        <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%" }}>
          <TextField
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            label='Name'
            name='name'
            sx={{ m: 1, width: "50%" }}
            required
            value={values.name || ""}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <TextField
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            label='Description'
            name='description'
            multiline
            maxRows={2}
            sx={{ m: 1, width: "50%" }}
            value={values.description || ""}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Stack>

        <Paper sx={{ mx: 1, mb: 1, p: 1 }}>
          <Typography component='div' variant='h2'>
            Contact person
          </Typography>
          <Stack direction={{ xs: "column", md: "row" }}>
            <TextField
              error={Boolean(
                getIn(touched, "contactPerson.personName.firstName") &&
                  getIn(errors, "contactPerson.personName.firstName"),
              )}
              helperText={
                getIn(touched, "contactPerson.personName.firstName") &&
                getIn(errors, "contactPerson.personName.firstName")
              }
              label='First name'
              name='contactPerson.personName.firstName'
              sx={{ m: 1, width: "50%" }}
              required
              value={values.contactPerson?.personName?.firstName || ""}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              error={Boolean(
                getIn(touched, "contactPerson.personName.lastName") &&
                  getIn(errors, "contactPerson.personName.lastName"),
              )}
              helperText={
                getIn(touched, "contactPerson.personName.lastName") &&
                getIn(errors, "contactPerson.personName.lastName")
              }
              label='Last name'
              name='contactPerson.personName.lastName'
              sx={{ m: 1, width: "50%" }}
              required
              value={values.contactPerson?.personName?.lastName || ""}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "100%" }}>
            <TextField
              error={Boolean(
                getIn(touched, "contactPerson.email") && getIn(errors, "contactPerson.email"),
              )}
              helperText={
                getIn(touched, "contactPerson.email") && getIn(errors, "contactPerson.email")
              }
              label='Email'
              name='contactPerson.email'
              sx={{ m: 1, width: "50%" }}
              required
              disabled
              value={values.contactPerson?.email || ""}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <PhoneInput
              error={Boolean(
                getIn(touched, "contactPerson.phoneNumber") &&
                  getIn(errors, "contactPerson.phoneNumber"),
              )}
              helperText={
                getIn(touched, "contactPerson.phoneNumber") &&
                getIn(errors, "contactPerson.phoneNumber")
              }
              sx={{ m: 1, width: "50%" }}
              required
              label='Phone number'
              margin='normal'
              name='contactPerson.phoneNumber'
              variant='outlined'
              color='primary'
              value={values.contactPerson?.phoneNumber || ""}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Stack>
        </Paper>

        <Paper sx={{ m: 1, p: 1 }}>
          <Typography component='div' variant='h2'>
            Branding
          </Typography>
          <GeneralBrandingInputs formikProps={brandingFormikProps} />
        </Paper>

        <GeneralValidationError sx={{ my: 1 }} errors={errors} />

        <LoadingButton
          loading={isSubmitting}
          color='primary'
          fullWidth
          type='submit'
          variant='contained'
          sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
        >
          Save
        </LoadingButton>
      </form>
    </CreateUpdatePageLayout>
  );
}

import { apiClient } from "@/core/api/ApiClient";
import { EntityType, LocationDto } from "@/core/api/generated";
import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: LocationDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function LocationDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.Location}
      entity={entity}
      entityId={entity.id}
      deleteFunc={(params) =>
        apiClient.locationsApi.apiV1LocationsLocationIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          locationId: params.entityId,
        })
      }
      {...otherProps}
    />
  );
}

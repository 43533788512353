import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import ApiEnumIcon from "@/common/components/Icons/ApiEnumIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  PaginationDtoOfPoolDto,
  PoolDto,
  PoolGetPaginatedDto,
} from "@/core/api/generated";
import { Alert, Button, Stack, SxProps, Theme, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link as RouterLink, useHistory } from "react-router-dom";
import AuthorizedElement from "../../../Auth/AuthorizedElement";
import DataTabular, { TabularProps } from "../../../DataTabular/DataTabular";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import AppIcon from "../../../Icons/AppIcon";
import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralEntitySubTypeInline from "../../General/Display/GeneralEntitySubTypeInline";
import PoolAvailabilityDisplay from "../PoolAvailabilityDisplay";
import PoolMenu from "../PoolMenu";

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type PoolItemPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface PoolPaginatedListOwnProps {
  shouldFetch?: boolean;
  paginated?: PaginationDtoOfPoolDto | null;
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
    ids?: string[];
  } & Partial<PoolGetPaginatedDto>;
  propagatedDeps?: PropagatedDeps<PoolItemPaginatedListPropagatedDepsMap>;
  dataTabularProps?: Partial<TabularProps<PoolDto>>;
  sx?: SxProps<Theme>;
}

export type PoolPaginatedListProps = PoolPaginatedListOwnProps;

export default function PoolPaginatedList({
  shouldFetch = true,
  paginated,
  displayProps,
  defaultValues,
  propagatedDeps,
  dataTabularProps,
  sx,
}: PoolPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<PoolGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.Pool,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
  });

  const paginatedPoolsRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      skip: !shouldFetch,
      deps: [...commonRequestParams.deps, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedPools = paginated || paginatedPoolsRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Pool)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedPoolsRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayout
      displayProps={displayProps}
      header={
        displayProps?.header && (
          <>
            <Alert severity='info' sx={{ mb: 2 }}>
              Pools are used for object accounting. For instance, vehicles, assets, etc.
            </Alert>

            <SimpleViewPageHeader
              title={undefined}
              primaryActions={
                displayProps?.create && (
                  <AuthorizedElement permissionsAny={[AppPermission.PoolManage]}>
                    {displayProps?.create && (
                      <Button
                        variant='contained'
                        color='primary'
                        startIcon={<AppIcon of='add' />}
                        component={RouterLink}
                        to={ROUTE_PATH.POOL_CREATE}
                      >
                        Create new pool
                      </Button>
                    )}
                  </AuthorizedElement>
                )
              }
            />
          </>
        )
      }
    >
      <Stack direction='column' spacing={1}>
        <DataTabular
          columns={[
            {
              field: getTypedPath<PoolDto>().id.$path,
              title: "ID",
              width: 250,
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.id,
              filters: {
                fieldType: FilterFieldType.Id,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<PoolDto>().localNumber.$path,
              title: "Number",
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => (
                <Stack direction='row' spacing={0.5}>
                  <span>{item.localNumber}</span> <EntityChipList entity={item} variant='compact' />
                </Stack>
              ),
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<PoolDto>().name.$path,
              title: "Name",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.name || "-",
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<PoolDto>().description.$path,
              title: "Description",
              width: 200,
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) => item.description || "-",
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<PoolDto>().structureType.$path,
              title: "Structure type",
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => (
                <InlineApiEnumValue type='PoolStructureType' value={item.structureType} />
              ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.PoolStructureType,
                  },
                },
              },
            },
            {
              field: getTypedPath<PoolDto>().itemType.$path,
              title: "Item type",
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => (
                <InlineApiEnumValue type='PoolItemType' value={item.itemType} />
              ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.PoolItemType,
                  },
                },
              },
            },
            {
              field: getTypedPath<PoolDto>().itemEntityType.$path,
              title: "Item entity type",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => (
                <Box>
                  <ApiEnumIcon type='EntityType' value={item.itemEntityType} inText />{" "}
                  <InlineApiEnumValue type='PoolItemEntityType' value={item.itemEntityType} />
                  {item.itemEntitySubType && (
                    <>
                      {" "}
                      (
                      <Typography component='span' variant='body2'>
                        <GeneralEntitySubTypeInline value={item.itemEntitySubType} withIcon />
                      </Typography>
                      )
                    </>
                  )}
                </Box>
              ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.PoolItemEntityType,
                  },
                },
              },
            },
            {
              field: getTypedPath<PoolDto>().itemEntitySubType.entityType.$path,
              title: "Item entity sub type",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.itemEntitySubType ? (
                  <InlineApiEnumValue
                    type='EntityType'
                    value={item.itemEntitySubType?.entitySubType}
                  />
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.PoolItemEntityType,
                  },
                },
              },
            },
            {
              field: getTypedPath<PoolDto>().state.pendingCount.$path,
              title: "Pending items #",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.state?.pendingCount ?? "-",
              filters: {
                fieldType: FilterFieldType.Number,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<PoolDto>().state.availableCount.$path,
              title: "Available items #",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.state?.availableCount ?? "-",
              filters: {
                fieldType: FilterFieldType.Number,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<PoolDto>().state.lockedCount.$path,
              title: "Locked items #",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.state?.lockedCount ?? "-",
              filters: {
                fieldType: FilterFieldType.Number,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<PoolDto>().state.unavailableCount.$path,
              title: "Unavailable items #",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.state?.unavailableCount ?? "-",
              filters: {
                fieldType: FilterFieldType.Number,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<PoolDto>().state.totalCount.$path,
              title: "Total items #",
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.state?.totalCount ?? "-",
              filters: {
                fieldType: FilterFieldType.Number,
                isUseDefaultOperators: true,
              },
            },
            {
              field: "availability",
              title: "Availability",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => (
                <PoolAvailabilityDisplay poolId={item.id} availability={undefined} isLoad />
              ),
            },
            {
              field: getTypedPath<PoolDto>().createdAt.$path,
              title: "Created at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<PoolDto>().updatedAt.$path,
              title: "Updated at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<PoolDto>().createdBy.$path,
              title: "Created by",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
              },
            },
            {
              field: getTypedPath<PoolDto>().updatedBy.$path,
              title: "Updated by",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
              },
            },
            {
              field: "affiliation",
              title: "Affiliation",
              description: "Affiliation to departments & locations",
              width: 120,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              isToDisabled: true,
              renderCell: (item) => (
                <AffiliationInfoDisplay
                  variant='compact'
                  tenantId={item.tenantId}
                  departmentIds={item.departmentIds}
                  locationIds={item.locationIds}
                />
              ),
            },
          ]}
          isLoading={paginatedPoolsRequest.isLoading}
          rows={paginatedPools?.items}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.POOL_VIEW(item.id)}
          renderRowAction={({ item }) => (
            <PoolMenu
              entity={item}
              onDelete={() => paginatedPoolsRequest.refetch()}
              onUpdate={() => paginatedPoolsRequest.refetch()}
              displayProps={{
                actions: {
                  edit: displayProps?.edit || false,
                  delete: displayProps?.delete || false,
                },
              }}
            />
          )}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
          refetch={commonRequestParams.dataTabularProps.refetch}
          {...dataTabularProps}
        />
      </Stack>
    </ViewLayout>
  );
}

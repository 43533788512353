import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Skeleton, SxProps, Theme, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

import _ from "lodash";
import { useCallback, useRef } from "react";
import {
  useBreadcrumbsContext,
  useBreadcrumbsEventEmitterSubscription,
} from "../contexts/breadcrumbs";
import { BreadcrumbHelper } from "../helpers/breadcrumb";
import { useEffectWithDeepCompare } from "../hooks/effect/useEffectWithDeepCompare";
import { useTriggerRender } from "../hooks/render/useTriggerRender";
import {
  AppBreadcrumbsReplacements,
  IAppBreadcrumb,
  IAppBreadcrumbIdValueReplacement,
} from "../ts/breadcrumbs";
import DelayedRender from "./DelayedRender";
import AppLink from "./Link/AppLink";

const maxCacheKeys = 100;

const defaultReplacements: AppBreadcrumbsReplacements = {
  waitTimeout: 1000,
  isWaitInfinitely: false,
};

export type AppBreadcrumbsReplacementsMap = Record<
  string,
  IAppBreadcrumbIdValueReplacement | undefined
>;

export interface AppBreadcrumbsProps {
  /** Override breadcrumbs resolved from current path. */
  breadcrumbs?: IAppBreadcrumb[];
  replacements?: AppBreadcrumbsReplacements;
  /** Always show Home as first breadcrumb. */
  withHome?: boolean;
  sx?: SxProps<Theme>;
}

export default function AppBreadcrumbs({ breadcrumbs, replacements, sx }: AppBreadcrumbsProps) {
  const location = useLocation();
  const breadcrumbsContext = useBreadcrumbsContext();

  // use imperative style render call to be able to use refs in event handlers
  const { triggerRender } = useTriggerRender();

  const breadcrumbsComputedRef = useRef<IAppBreadcrumb[]>([]);
  const replacementsComputedRef = useRef<AppBreadcrumbsReplacements>({ ...defaultReplacements });
  // {id: value}
  const idValueReplacementsMapRef = useRef({} as AppBreadcrumbsReplacementsMap);

  const applyIdValueReplacements = useCallback(
    (
      currentBreadCrumbs: IAppBreadcrumb[],
      idValueReplacementsMap: AppBreadcrumbsReplacementsMap,
    ) => {
      const newBreadcrumbsComputed = currentBreadCrumbs.map((b) => {
        const idValueReplacement = idValueReplacementsMap[b.idValue || ""];
        return {
          ...b,
          title: (b.idValue && idValueReplacement?.newTitle) || b.title,
          isReplacementApplied: !!idValueReplacement,
        };
      });

      if (!_.isEqual(newBreadcrumbsComputed, currentBreadCrumbs)) {
        breadcrumbsComputedRef.current = newBreadcrumbsComputed;
        triggerRender();
      }

      // keep local cache small
      const keys = Object.keys(idValueReplacementsMap);
      if (keys.length >= maxCacheKeys) {
        const diff = keys.length - maxCacheKeys;
        const keysToDeleteCount = Math.max(keys.length - diff - maxCacheKeys / 2, 0);
        let i = 0;
        while (i < keysToDeleteCount) {
          delete idValueReplacementsMap[keys[i]];
          i += 1;
        }
      }
    },
    [triggerRender],
  );

  useEffectWithDeepCompare(() => {
    // combine replacements
    const newReplacementsComputed = BreadcrumbHelper.combineReplacements(
      replacementsComputedRef.current,
      replacements,
    );
    const isReplacementsChanged = !_.isEqual(
      newReplacementsComputed,
      replacementsComputedRef.current,
    );

    if (isReplacementsChanged) {
      replacementsComputedRef.current = newReplacementsComputed;
      idValueReplacementsMapRef.current = BreadcrumbHelper.combineReplacementsMap(
        idValueReplacementsMapRef.current,
        replacements,
      );
    }

    // use new breadcrumbs
    const newValue =
      breadcrumbs || BreadcrumbHelper.getByPath(location.pathname, { includeHome: true });
    const isBreadcrumbsChanged = !_.isEqual(newValue, breadcrumbsComputedRef.current);
    if (isBreadcrumbsChanged) {
      breadcrumbsComputedRef.current = newValue;
      triggerRender();
    }

    if (isReplacementsChanged || isBreadcrumbsChanged) {
      applyIdValueReplacements(newValue, idValueReplacementsMapRef.current);
    }
  }, [breadcrumbs, replacements, location.pathname]);

  // listen for replacements from actual pages
  useBreadcrumbsEventEmitterSubscription(
    breadcrumbsContext,
    "idBreadcrumbReplacementReceived",
    (data) => {
      const newValue = data;
      const currentValue = idValueReplacementsMapRef.current[data.idValue];

      if (!_.isEqual(newValue, currentValue)) {
        const key = data.idValue;
        idValueReplacementsMapRef.current[key] = data;

        applyIdValueReplacements(breadcrumbsComputedRef.current, idValueReplacementsMapRef.current);
      }
    },
  );

  useBreadcrumbsEventEmitterSubscription(
    breadcrumbsContext,
    "breadcrumbReplacementsReceived",
    (newReplacements) => {
      // combine replacements
      const newReplacementsComputed = BreadcrumbHelper.combineReplacements(
        replacementsComputedRef.current,
        newReplacements,
      );
      const isReplacementsChanged = !_.isEqual(newReplacements, replacementsComputedRef.current);

      if (isReplacementsChanged) {
        replacementsComputedRef.current = newReplacementsComputed;
        idValueReplacementsMapRef.current = BreadcrumbHelper.combineReplacementsMap(
          idValueReplacementsMapRef.current,
          newReplacements,
        );
      }

      if (isReplacementsChanged) {
        applyIdValueReplacements(breadcrumbsComputedRef.current, idValueReplacementsMapRef.current);
      }
    },
  );

  if (breadcrumbsComputedRef.current.length < 2) {
    return null;
  }

  return (
    <Breadcrumbs
      sx={{
        "& .MuiBreadcrumbs-separator": {
          mx: 0.5,
        },
        mb: "16px !important",
        ...sx,
      }}
      maxItems={isMobile ? 2 : undefined}
      aria-label='breadcrumb'
      separator={<NavigateNextIcon fontSize='small' />}
    >
      {breadcrumbsComputedRef.current.map((breadcrumb, index) => {
        return (
          // for id value breadcrumbs render skeleton and wait for some time if title provided
          <DelayedRender
            key={index}
            enabled={(breadcrumb.isReplacementNeeded && !breadcrumb.isReplacementApplied) || false}
            delayMs={
              replacementsComputedRef.current?.isWaitInfinitely
                ? -1
                : replacementsComputedRef.current?.waitTimeout || 0
            }
            interruptIf={
              (breadcrumb.isReplacementNeeded && breadcrumb.isReplacementApplied) || false
            }
            isReScheduleOnDelayChange
            placeholder={<Skeleton variant='text' width={50} />}
          >
            <Typography
              component={AppLink}
              to={breadcrumb.to}
              variant='body1'
              sx={{ textDecoration: "none" }}
            >
              {breadcrumb.title}
            </Typography>
          </DelayedRender>
        );
      })}
    </Breadcrumbs>
  );
}

import { Stack } from "@mui/material";
import { Box } from "@mui/system";

import DamageCostEvaluationAggregateView from "@/common/components/Entity/DamageCostEvaluationAggregate/View/DamageCostEvaluationAggregateView";
import DamageDetectionAggregateView from "@/common/components/Entity/DamageDetectionAggregate/View/DamageDetectionAggregateView";
import { GeneralScopeDto, GeneralScopeType, GeneralScopeUnwoundDto } from "@/core/api/generated";
import { renderIf } from "@/common/helpers/render/renderIf";

export interface OwnProps {
  scope: GeneralScopeDto | null | undefined;
}

export default function GeneralScopeDisplay({ scope }: OwnProps) {
  if (!scope) {
    return null;
  }

  return (
    <Box>
      <Stack direction='column' spacing={1}>
        {renderIf()
          .if(scope.type === GeneralScopeType.DamageDetectionAggregate)
          .then(
            <DamageDetectionAggregateView
              aggregate={scope?.damageDetectionAggregate}
              headerProps={{
                withLink: true,
              }}
              displayProps={{
                vehicle: false,
                contract: false,
              }}
            />,
          )
          .elseif(scope.type === GeneralScopeType.DamageDetectionAggregateItem)
          .then(
            <DamageDetectionAggregateView
              aggregate={scope?.damageDetectionAggregate}
              headerProps={{
                withLink: true,
              }}
              highlightProps={{
                itemId: scope?.damageDetectionAggregateItem?.id,
              }}
            />,
          )
          .elseif(scope.type === GeneralScopeType.DamageCostEvaluationAggregate)
          .then(
            <DamageCostEvaluationAggregateView
              aggregate={scope.damageCostEvaluationAggregate}
              headerProps={{
                withLink: true,
              }}
            />,
          )
          .elseif(scope.type === GeneralScopeType.DamageCostEvaluationAggregateItem)
          .then(
            <DamageCostEvaluationAggregateView
              aggregate={scope.damageCostEvaluationAggregate}
              headerProps={{
                withLink: true,
              }}
              highlightProps={{
                itemId: scope?.damageCostEvaluationAggregateItem?.id,
              }}
            />,
          )
          .else(<>?????</>)
          .render()}
      </Stack>
    </Box>
  );
}

import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

// Figma name:
export default forwardRef<SVGSVGElement, SvgIconProps>(function ShieldCheckCircleIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      color='secondary'
      {...props}
      width='24'
      height='24'
      viewBox='0 0 15 15'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='7.5' cy='7.5' r='7.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.50061 11.8099C7.3523 11.8099 7.20399 11.7731 7.0723 11.6987L6.93974 11.6239C4.8568 10.4523 3.56311 8.24029 3.56311 5.85067V5.78723C3.56311 5.47267 3.73374 5.18042 4.00849 5.0251L7.07186 3.30223C7.33655 3.15348 7.66511 3.15304 7.92892 3.30179L10.9919 5.02467C11.2675 5.18042 11.4381 5.47267 11.4381 5.78723V5.85067C11.4381 8.24029 10.1444 10.4523 8.06149 11.6239L7.92936 11.6983C7.79724 11.7727 7.64892 11.8099 7.50061 11.8099Z'
        fill='white'
      />
      <mask
        id='mask0_1_2308'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='3'
        y='3'
        width='9'
        height='9'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.50061 11.8099C7.3523 11.8099 7.20399 11.7731 7.0723 11.6987L6.93974 11.6239C4.8568 10.4523 3.56311 8.24029 3.56311 5.85067V5.78723C3.56311 5.47267 3.73374 5.18042 4.00849 5.0251L7.07186 3.30223C7.33655 3.15348 7.66511 3.15304 7.92892 3.30179L10.9919 5.02467C11.2675 5.18042 11.4381 5.47267 11.4381 5.78723V5.85067C11.4381 8.24029 10.1444 10.4523 8.06149 11.6239L7.92936 11.6983C7.79724 11.7727 7.64892 11.8099 7.50061 11.8099Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_1_2308)'>
        <rect x='2.25061' y='2.25004' width='10.5' height='10.5' fill='white' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.87739 9.25004C6.79689 9.25004 6.71989 9.21679 6.66476 9.15817L5.24639 7.64762C5.13585 7.53037 5.14197 7.34575 5.25922 7.2355C5.37676 7.12525 5.56139 7.13108 5.67135 7.24833L6.87447 8.52904L9.32681 5.84512C9.43589 5.72583 9.62022 5.71796 9.73922 5.82646C9.85793 5.93496 9.8661 6.11958 9.7576 6.23829L7.09264 9.15496C7.0381 9.21504 6.96051 9.24946 6.87943 9.25004H6.87739Z'
        />
        <mask
          id='mask1_1_2308'
          // style='mask-type:luminance'
          maskUnits='userSpaceOnUse'
          x='5'
          y='5'
          width='5'
          height='5'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.87739 9.25004C6.79689 9.25004 6.71989 9.21679 6.66476 9.15817L5.24639 7.64762C5.13585 7.53037 5.14197 7.34575 5.25922 7.2355C5.37676 7.12525 5.56139 7.13108 5.67135 7.24833L6.87447 8.52904L9.32681 5.84512C9.43589 5.72583 9.62022 5.71796 9.73922 5.82646C9.85793 5.93496 9.8661 6.11958 9.7576 6.23829L7.09264 9.15496C7.0381 9.21504 6.96051 9.24946 6.87943 9.25004H6.87739Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask1_1_2308)'>
          <rect x='4.00061' y='4.00004' width='7' height='7' />
        </g>
      </g>
    </SvgIcon>
  );
});

import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import {
  AppPermission,
  AssetSubscriptionDto,
  ContractDto,
  ContractStage,
  ContractType,
  EntityType,
} from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AppTooltip from "@/common/components/AppTooltip";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import GeneralStageHistory from "@/common/components/GeneralStageHistory";
import AllocationStatusIcon from "@/common/components/Icons/AllocationStatusIcon";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { DatetimeHelper } from "@/common/helpers/datetime";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { useCallback, useState } from "react";
import AccessoryGrid from "../../../Accessory/AccessoryGrid";
import AssetSubscriptionLink from "../../../AssetSubscription/AssetSubscriptionLink";
import CustomerLink from "../../../Customer/CustomerLink";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import VehicleLink from "../../../Vehicle/VehicleLink";
import ContractCustomerCommunicationInfo from "../../ContractCustomerCommunicationInfo";
import ContractCustomerCommunicationInfoModal from "../../ContractCustomerCommunicationInfoModal";
import ContractReminderSettingsDisplay from "../../ContractReminderSettingsDisplay";
import ContractSettingsDisplay from "../../ContractSettingsDisplay";
import ContractSpotInfoDisplay from "../../ContractSpotInfoDisplay";
import ContractUpdateSpotInfoModal from "../../ContractUpdateSpotInfoModal";
import CreateContractCustomerCommunicationEntryModal from "../../CreateContractCustomerCommunicationEntryModal";

interface Props {
  contract: ContractDto;
  assetSubscription: AssetSubscriptionDto | null | undefined;
  onUpdate: (newValue?: ContractDto) => void;
  onReallocateVehicle: () => void;
  viewVariant?: ViewLayoutVariant;
}

export default function OverviewTabContent({
  contract,
  assetSubscription,
  viewVariant = ViewLayoutVariant.Tab,
  onUpdate,
  onReallocateVehicle,
}: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);
  const [isReallocateVehicleModalOpenLoading, setIsReallocateVehicleModalOpenLoading] =
    useState(false);
  const [isReallocateVehicleModalOpen, setIsReallocateVehicleModalOpen] = useState(false);
  const [isCommunicationInfoModalOpen, setIsCommunicationInfoModalOpen] = useState(false);
  const [isCreateCommunicationEntryModalOpen, setIsCreateCommunicationEntryModalOpen] =
    useState(false);
  const [isEditCheckOutSpotInfoModalOpen, setIsEditCheckOutSpotInfoModalOpen] = useState(false);
  const [isEditCheckInSpotInfoModalOpen, setIsEditCheckInSpotInfoModalOpen] = useState(false);

  const handleUpdateStage = useCallback(
    async (newStage: ContractStage) => {
      try {
        const response = await apiClient.contractsApi.apiV1ContractsContractIdStagePut({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          contractId: contract.id!,
          updateContractStageDto: {
            newStage,
          },
        });
        onUpdate(response.data);
      } catch (err) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        validation2.hasErrors &&
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      }
    },
    [contract],
  );

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={contract} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack direction='column' spacing={2}>
            {/* <Stack direction={{ xxs: "column", md: "row" }} alignItems='flex-start' spacing={2}>
              <VehicleBlock
                sx={{ maxWidth: { md: "400px" } }}
                variant='reference'
                vehicle={contract.vehicle}
                withDetailsToggle
                withLink
              />

              <CustomerBlock
                sx={{ maxWidth: { md: "400px" } }}
                variant='reference'
                customer={contract.customer}
                withDetailsToggle
                withLink
              />
            </Stack> */}

            <Box>
              <Grid container columnSpacing={2} rowSpacing={1} sx={{ mb: 1 }}>
                <Grid item xxs={12} md minWidth={200}>
                  <Stack direction='column' spacing={1}>
                    <FieldValue label='Type'>
                      <InlineApiEnumValue type='ContractType' value={contract.type} />
                    </FieldValue>

                    <FieldValue label='Vehicle' isEmpty={!contract?.vehicle}>
                      <Stack>
                        <VehicleLink entity={contract?.vehicle} />
                        <Box>
                          <AllocationStatusIcon status={contract.allocationStatus} />{" "}
                          <InlineApiEnumValue
                            type='AllocationStatus'
                            value={contract.allocationStatus}
                            withHelperTooltip
                          />{" "}
                          {contract?.canReallocate && (
                            <AppTooltip title='Reallocate vehicle' isInline>
                              <AppIconButton
                                size='small'
                                loading={isReallocateVehicleModalOpenLoading}
                                onClick={() => onReallocateVehicle && onReallocateVehicle()}
                              >
                                <AppIcon of='edit' />
                              </AppIconButton>
                            </AppTooltip>
                          )}
                        </Box>
                      </Stack>
                    </FieldValue>

                    <FieldValue label='Customer' isEmpty={!contract?.customer}>
                      <CustomerLink entity={contract?.customer} />
                    </FieldValue>

                    {contract.type === ContractType.Subscription && (
                      <FieldValue label='Asset subscription' isEmpty={!contract?.assetSubscription}>
                        <AssetSubscriptionLink entity={contract?.assetSubscription} />
                      </FieldValue>
                    )}

                    <FieldValue label='Starts at' isEmpty={!contract.startsAt}>
                      <Datetime datetime={contract.startsAt} withDurationFromNow />
                    </FieldValue>

                    <FieldValue label='Ends at' isEmpty={!contract.endsAt}>
                      <Datetime datetime={contract.endsAt} withDurationFromNow />
                    </FieldValue>

                    <FieldValue label='Duration' isEmpty={!contract.startsAt && !contract.endsAt}>
                      {DatetimeHelper.humanizeDateRangeDuration(contract.startsAt, contract.endsAt)}
                    </FieldValue>
                  </Stack>
                </Grid>
                <Grid item xxs={12} md minWidth={200}>
                  <Stack direction='column' spacing={1}>
                    <FieldValue label='Local number' isEmpty={!contract.localNumber}>
                      {contract.localNumber}
                    </FieldValue>

                    <FieldValue label='External number' isEmpty={!contract.externalNumber}>
                      {contract.externalNumber}
                    </FieldValue>

                    <FieldValue label='Notes' isEmpty={!contract.notes}>
                      {contract.notes}
                    </FieldValue>
                  </Stack>
                </Grid>
              </Grid>
            </Box>

            {/* Affiliation */}
            <Stack spacing={1} sx={{ mt: 2 }}>
              <Typography variant='h6'>
                Affiliation{" "}
                <AppIconButton
                  tooltipProps={{ title: "Edit entity affiliation" }}
                  onClick={() => setIsUpdateAffiliationModalOpened(true)}
                >
                  <AppIcon of='edit' fontSize='small' />
                </AppIconButton>
              </Typography>
              <AffiliationInfoDisplay
                withoutTenant
                departmentIds={contract.departmentIds}
                locationIds={contract.locationIds}
                sx={{ mt: 1 }}
              />
              <UpdateEntityAffiliationCascadeModal
                open={isUpdateAffiliationModalOpened}
                onClose={() => setIsUpdateAffiliationModalOpened(false)}
                entityType={EntityType.Contract}
                entityId={contract.id}
              />
            </Stack>

            {/* Stages */}
            <Box sx={{ my: 2 }}>
              <Typography component='div' variant='h6'>
                Stages
              </Typography>

              <GeneralStageHistory
                stageEnumType='ContractStage'
                stageHistory={contract.stageHistory}
                withUpdateButtons
                onUpdateStage={async (newStage) => await handleUpdateStage(newStage)}
              />
            </Box>

            {/* Check-in, check-out spot info */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: `1fr`,
                  lg: `repeat(2, 1fr)`,
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 4,
              }}
            >
              <ContractSpotInfoDisplay
                contract={contract}
                spotInfoType='CheckOut'
                action={
                  <Button
                    variant='outlined'
                    size='small'
                    color='secondary'
                    startIcon={<AppIcon of='edit' />}
                    onClick={() => setIsEditCheckOutSpotInfoModalOpen(true)}
                  >
                    Edit
                  </Button>
                }
              />
              <ContractSpotInfoDisplay
                contract={contract}
                spotInfoType='CheckIn'
                action={
                  <Button
                    variant='outlined'
                    size='small'
                    color='secondary'
                    startIcon={<AppIcon of='edit' />}
                    onClick={() => setIsEditCheckInSpotInfoModalOpen(true)}
                  >
                    Edit
                  </Button>
                }
              />
            </Box>

            {/* Accessories */}
            <Stack spacing={2}>
              <Typography component='div' variant='h6'>
                Accessories
              </Typography>

              {(!contract?.accessories || contract?.accessories?.length === 0) && <NoDataAlert />}

              <AccessoryGrid accessories={contract?.accessories} sx={{ pl: 2 }} />
            </Stack>

            {/* Customer communication */}
            <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
              <Box>
                <Grid container rowSpacing={1}>
                  <Grid item xxs={12} md={12}>
                    <Stack direction='row' justifyContent='space-between'>
                      <Typography component='div' variant='h6'>
                        Customer communication
                      </Typography>

                      <Stack direction='row' spacing={1}>
                        <Button
                          variant='outlined'
                          color='secondary'
                          size='small'
                          onClick={() => setIsCreateCommunicationEntryModalOpen(true)}
                        >
                          Customer contacted
                        </Button>

                        <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                          <MenuWithTrigger
                            withAuthCloseOnClick
                            trigger={
                              <IconButton sx={{ ml: "auto" }}>
                                <AppIcon of='moreVert' />
                              </IconButton>
                            }
                          >
                            <MenuItem onClick={() => setIsCommunicationInfoModalOpen(true)}>
                              <ListItemIcon>
                                <AppIcon of='history' fontSize='small' />
                              </ListItemIcon>
                              <ListItemText>View communication entries</ListItemText>
                            </MenuItem>
                          </MenuWithTrigger>
                        </AuthorizedElement>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xxs={12} md={12}>
                    {contract && (
                      <ContractCustomerCommunicationInfo
                        contract={contract}
                        displayProps={{ overview: true, createNewEntry: false, entries: false }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </AuthorizedElement>

            {/* Reminders */}
            <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
              <Grid container rowSpacing={1}>
                <Grid item xxs={12} md={12}>
                  <Typography component='div' variant='h6'>
                    Reminders
                  </Typography>
                </Grid>
                <Grid item xxs={12} md={12}>
                  {contract && (
                    <ContractReminderSettingsDisplay
                      contract={contract}
                      onUpdate={(newValue) => {
                        onUpdate(newValue.contract);
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </AuthorizedElement>

            {/* Reminders */}
            <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
              <Stack spacing={2}>
                <Typography component='div' variant='h6'>
                  Settings
                </Typography>

                {contract && <ContractSettingsDisplay contract={contract} />}
              </Stack>
            </AuthorizedElement>
          </Stack>
        </Stack>
      </ViewLayout>

      {/* Update check-out/check-in spot info */}
      {contract && (
        <ContractUpdateSpotInfoModal
          open={isEditCheckOutSpotInfoModalOpen}
          onClose={() => setIsEditCheckOutSpotInfoModalOpen(false)}
          subProps={{
            contract: contract,
            spotInfo: contract.checkOutSpotInfo,
            spotInfoType: "CheckOut",
            onSave: (newValue) => {
              setIsEditCheckOutSpotInfoModalOpen(false);
              onUpdate(newValue);
            },
          }}
        />
      )}
      {contract && (
        <ContractUpdateSpotInfoModal
          open={isEditCheckInSpotInfoModalOpen}
          onClose={() => setIsEditCheckInSpotInfoModalOpen(false)}
          subProps={{
            contract: contract,
            spotInfo: contract.checkInSpotInfo,
            spotInfoType: "CheckIn",
            onSave: (newValue) => {
              setIsEditCheckInSpotInfoModalOpen(false);
              onUpdate(newValue);
            },
          }}
        />
      )}

      {/* Customer contacted */}
      {contract && (
        <CreateContractCustomerCommunicationEntryModal
          createEntryProps={{
            contractId: contract.id!,
            onSave: () => setIsCreateCommunicationEntryModalOpen(false),
          }}
          open={isCreateCommunicationEntryModalOpen}
          onClose={() => setIsCreateCommunicationEntryModalOpen(false)}
        />
      )}

      {/* Communication info */}
      {contract && (
        <ContractCustomerCommunicationInfoModal
          contractCustomerCommunicationInfoProps={{
            contract: contract,
          }}
          open={isCommunicationInfoModalOpen}
          onClose={() => setIsCommunicationInfoModalOpen(false)}
        />
      )}
    </PageTabContent>
  );
}

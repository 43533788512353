import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import DamageCostEvaluationAggregatePaginatedList from "@/common/components/Entity/DamageCostEvaluationAggregate/ListView/DamageCostEvaluationAggregatePaginatedList";

export default function DamageCostEvaluationAggregatesPage() {
  return (
    <ListPageLayout>
      <DamageCostEvaluationAggregatePaginatedList />
    </ListPageLayout>
  );
}

import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { RoleDto, RoleReferenceDto } from "@/core/api/generated";
import TextLineSkeleton from "../../Skeleton/TextLineSkeleton";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";

export interface RoleInlineProps extends BaseEntityInlineInheritableProps {
  entity: RoleDto | RoleReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the role. */
export default function RoleInline({ entity, entityId, ...otherProps }: RoleInlineProps) {
  const request = useApiRequest(
    apiClient.tenantAccountApi.apiV1TenantAccountRolesRoleIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      roleId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as RoleDto;
  const ref = entity as RoleReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='role' inText />}
      content={
        <>
          {entity?.name} {entity?.isBuiltIn && <> (Built-in)</>}
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Role"}
    />
  );
}

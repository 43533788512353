import {
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  ChatType,
  DataUpdatesHubClientMethodName,
  EntityChangeType,
  EntityType,
} from "@/core/api/generated";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout from "@/App/Layouts/ViewLayout";
import ContractCustomerCommunicationInfoModal from "@/common/components/Entity/Contract/ContractCustomerCommunicationInfoModal";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import EmbeddedChat from "../../Chats/components/EmbeddedChat";
import ContractAssessmentFlowStateFilterTypeIcon from "./components/ContractAssessmentFlowStateFilterTypeIcon";
import SendAssessmentFlowToCustomerModal from "./components/SendAssessmentFlowToCustomerModal";

export interface AssessmentFlowChatPageQueryParams extends GeneralQueryParams {
  chatId?: string | null;
}

export default function AssessmentFlowChatPage() {
  const { assessmentFlowId } = useParams<{ assessmentFlowId?: string }>();
  const { chatId } = useQueryParams<AssessmentFlowChatPageQueryParams>();

  const history = useHistory();
  const currentTenant = useCurrentTenant();

  const [isSendToCustomerModalOpen, setIsSendToCustomerModalOpen] = useState(false);
  const [isCommunicationInfoModalOpen, setIsCommunicationInfoModalOpen] = useState(false);

  const assessmentFlowRequest = useApiRequest(
    apiClient.assessmentFlowsApi.apiV1FlowsAssessmentFlowsAssessmentFlowIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assessmentFlowId: assessmentFlowId!,
    },
    {
      deps: [assessmentFlowId],
      skip: !assessmentFlowId,
    },
  );
  const assessmentFlow = assessmentFlowRequest?.data;

  // handle old route (fetched by contractId) (remove this after 2024-06)
  const assessmentFlowByContractRequest = useApiRequest(
    apiClient.assessmentFlowsApi.apiV1FlowsAssessmentFlowsByContractContractIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      contractId: assessmentFlowId!,
    },
    {
      deps: [assessmentFlowId, assessmentFlow],
      skip: !assessmentFlowId || !assessmentFlowRequest.isEnded || !!assessmentFlow,
    },
  );
  const assessmentFlowByContract = assessmentFlowByContractRequest?.data;
  useEffect(() => {
    if (assessmentFlowByContract) {
      history.push(ROUTE_PATH.ASSESSMENT_FLOW_CHAT_VIEW(assessmentFlowByContract.id));
    }
  }, [assessmentFlowByContract]);

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.AssessmentFlow,
        assessmentFlowId || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      if (data?.changeType === EntityChangeType.Updated) {
        assessmentFlowRequest.updateData((fetchedData) => {
          fetchedData.contract = data?.entity;
        });
      }
      if (data?.changeType === EntityChangeType.Deleted) {
        assessmentFlowRequest.replaceData(null);
      }
    },
  });

  const chatIdComputed = useMemo(() => chatId || assessmentFlow?.chatId, [chatId, assessmentFlow]);

  if (!assessmentFlow && assessmentFlowRequest.isEnded) {
    return <EntityNotFoundAlert />;
  }

  return (
    <ViewLayout
      breadcrumbs={{
        replacements: {
          isWaitInfinitely: true,
          idBreadcrumb: assessmentFlow && {
            idValue: assessmentFlow.id || "",
            newTitle: `${assessmentFlow?.localNumber} (contract ${
              assessmentFlow?.contract?.externalNumber || assessmentFlow?.contract?.localNumber
            })`,
          },
        },
      }}
      header={
        <SimpleViewPageHeader
          title={
            <>
              <span>Assessment chat</span>

              {assessmentFlow && (
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <ContractAssessmentFlowStateFilterTypeIcon
                    assessmentFlowId={assessmentFlow?.id || ""}
                    value={assessmentFlow?.state?.filterState}
                    isEnableStateRecompute
                    fontSize='medium'
                    withTooltip
                    onUpdated={(newValue) => assessmentFlowRequest.replaceData(newValue)}
                    sx={{ ml: 1 }}
                  />
                </AuthorizedElement>
              )}
            </>
          }
          actions={
            assessmentFlow && (
              <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                <MenuWithTrigger
                  withAuthCloseOnClick
                  trigger={
                    <IconButton sx={{ ml: "auto" }}>
                      <AppIcon of='moreVert' />
                    </IconButton>
                  }
                >
                  <MenuItem
                    component={RouterLink}
                    to={ROUTE_PATH.CONTRACT_VIEW(assessmentFlow?.contract?.id)}
                  >
                    <ListItemIcon>
                      <AppIcon of='contract' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>View contract</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => setIsSendToCustomerModalOpen(true)}>
                    <ListItemIcon>
                      <AppIcon of='email' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Send to customer</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => setIsCommunicationInfoModalOpen(true)}>
                    <ListItemIcon>
                      <AppIcon of='contacted' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Customer communication</ListItemText>
                  </MenuItem>
                </MenuWithTrigger>
              </AuthorizedElement>
            )
          }
        />
      }
    >
      <Stack direction='column' spacing={2}>
        {assessmentFlowRequest.isLoading && <LinearProgress />}
        {/* Chat */}
        {chatIdComputed && (
          <EmbeddedChat
            chatType={ChatType.Negotiation}
            chatId={chatIdComputed}
            chatProps={{
              size: "medium",
              displayProps: {
                contentHeader: true,
                contentFolding: true,
              },
            }}
          />
        )}
      </Stack>

      {/* Send to customer */}
      {assessmentFlow && (
        <SendAssessmentFlowToCustomerModal
          assessmentFlow={assessmentFlow}
          open={isSendToCustomerModalOpen}
          onClose={() => setIsSendToCustomerModalOpen(false)}
        />
      )}

      {/* Communication info */}
      {assessmentFlow?.contract && (
        <ContractCustomerCommunicationInfoModal
          contractCustomerCommunicationInfoProps={{
            contract: assessmentFlow.contract,
          }}
          open={isCommunicationInfoModalOpen}
          onClose={() => setIsCommunicationInfoModalOpen(false)}
        />
      )}
    </ViewLayout>
  );
}

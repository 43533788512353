import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

// Figma name:
export default forwardRef<SVGSVGElement, SvgIconProps>(function ShieldCancelCircleIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      color='secondary'
      {...props}
      width='24'
      height='24'
      viewBox='0 0 15 15'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='7.5' cy='7.5' r='7.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 11.8098C7.35169 11.8098 7.20337 11.7731 7.07169 11.6987L6.93913 11.6239C4.85619 10.4523 3.5625 8.24025 3.5625 5.85063V5.78719C3.5625 5.47263 3.73313 5.18038 4.00787 5.02506L7.07125 3.30219C7.33594 3.15344 7.6645 3.153 7.92831 3.30175L10.9913 5.02463C11.2669 5.18038 11.4375 5.47263 11.4375 5.78719V5.85063C11.4375 8.24025 10.1438 10.4523 8.06087 11.6239L7.92875 11.6983C7.79662 11.7726 7.64831 11.8098 7.5 11.8098Z'
        fill='white'
      />
      <mask
        id='mask0_1_2311'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='3'
        y='3'
        width='9'
        height='9'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.5 11.8098C7.35169 11.8098 7.20337 11.7731 7.07169 11.6987L6.93913 11.6239C4.85619 10.4523 3.5625 8.24025 3.5625 5.85063V5.78719C3.5625 5.47263 3.73313 5.18038 4.00787 5.02506L7.07125 3.30219C7.33594 3.15344 7.6645 3.153 7.92831 3.30175L10.9913 5.02463C11.2669 5.18038 11.4375 5.47263 11.4375 5.78719V5.85063C11.4375 8.24025 10.1438 10.4523 8.06087 11.6239L7.92875 11.6983C7.79662 11.7726 7.64831 11.8098 7.5 11.8098Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_1_2311)'>
        <rect x='2.25' y='2.25' width='10.5' height='10.5' fill='white' />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.9086 7.24875L9.16028 5.99707C9.27428 5.88307 9.27428 5.6988 9.16028 5.5848C9.04628 5.4708 8.86201 5.4708 8.74801 5.5848L7.49633 6.83648L6.24466 5.5848C6.13066 5.4708 5.94639 5.4708 5.83239 5.5848C5.71839 5.6988 5.71839 5.88307 5.83239 5.99707L7.08407 7.24875L5.83239 8.50043C5.71839 8.61443 5.71839 8.7987 5.83239 8.9127C5.88924 8.96955 5.96388 8.99812 6.03852 8.99812C6.11316 8.99812 6.1878 8.96955 6.24466 8.9127L7.49633 7.66102L8.74801 8.9127C8.80487 8.96955 8.87951 8.99812 8.95415 8.99812C9.02879 8.99812 9.10343 8.96955 9.16028 8.9127C9.27428 8.7987 9.27428 8.61443 9.16028 8.50043L7.9086 7.24875Z'
      />
      <mask
        id='mask1_1_2311'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='5'
        y='5'
        width='5'
        height='4'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.9086 7.24875L9.16028 5.99707C9.27428 5.88307 9.27428 5.6988 9.16028 5.5848C9.04628 5.4708 8.86201 5.4708 8.74801 5.5848L7.49633 6.83648L6.24466 5.5848C6.13066 5.4708 5.94639 5.4708 5.83239 5.5848C5.71839 5.6988 5.71839 5.88307 5.83239 5.99707L7.08407 7.24875L5.83239 8.50043C5.71839 8.61443 5.71839 8.7987 5.83239 8.9127C5.88924 8.96955 5.96388 8.99812 6.03852 8.99812C6.11316 8.99812 6.1878 8.96955 6.24466 8.9127L7.49633 7.66102L8.74801 8.9127C8.80487 8.96955 8.87951 8.99812 8.95415 8.99812C9.02879 8.99812 9.10343 8.96955 9.16028 8.9127C9.27428 8.7987 9.27428 8.61443 9.16028 8.50043L7.9086 7.24875Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask1_1_2311)'>
        <rect x='3.99756' y='3.75' width='6.9975' height='6.9975' />
      </g>
    </SvgIcon>
  );
});

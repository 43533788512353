import { Box, BoxProps, CircularProgress, IconButton, Stack } from "@mui/material";
import _, { parseInt } from "lodash";
import { MouseEvent, useEffect, useMemo, useRef, useState } from "react";

import { VehicleVisualModelSvgPathMetadataDto } from "@/core/api/generated";

import {
  ExtendedDamageType,
  VisualModelPointClickedInfo,
  VisualModelPointDraggedInfo,
} from "../Entity/DamageDetection/DamageDetectionCreateUpdate";
import AppIcon from "../Icons/AppIcon";
import DamagePoint from "./DamagePoint";

export function getSvgElementFromSvgContainer(
  svgContainerEl: HTMLDivElement | null | undefined,
): SVGSVGElement | null | undefined {
  return svgContainerEl && svgContainerEl.children
    ? (svgContainerEl.children[0] as SVGSVGElement)
    : undefined;
}

export function getSvgElementFromSvgContainerOrThrow(
  svgContainerEl: HTMLDivElement | null | undefined,
): SVGSVGElement {
  const svgEl = getSvgElementFromSvgContainer(svgContainerEl);
  if (!svgEl) {
    throw new Error(`Can't find SVG element in specified container element!`);
  }
  return svgEl;
}

/** Describes point coordinates of the cursor. */
export interface CursorPoint {
  x: number;
  xp: number;
  y: number;
  yp: number;
  width: number;
  height: number;
}

interface Props {
  imageUrl?: string | null;
  containerSx?: BoxProps["sx"];
  onVisualModelClick?: (info: VisualModelPointClickedInfo) => void;
  onPointClicked?: (info: ExtendedDamageType) => void;
  onPointMoved?: (info: VisualModelPointDraggedInfo) => void;
  pointsInfo: Array<ExtendedDamageType>;
  pointCircleRadius?: number;
  defaultPointColor?: string;
  withZooming?: boolean;
  selectedPerspective?: boolean;
}

export default function DamagePointsVisualizer({
  pointsInfo,
  imageUrl,
  containerSx,
  onVisualModelClick,
  onPointClicked,
  onPointMoved,
  pointCircleRadius = 1.5,
  defaultPointColor = "red",
  withZooming = false,
  selectedPerspective = false,
}: Props) {
  const [svgContent, setSvgContent] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const svgContainerRef = useRef<HTMLDivElement | null>(null); // HTML element that contains SVG directly
  const containerRef = useRef<HTMLDivElement | null>(null); // HTMLDivElement
  const panDataRef = useRef({
    isPanning: false,
    initialCoords: { x: 0, y: 0 },
    startCoords: { x: 0, y: 0 },
  });

  const zoomVarClosureCheatFixRef = useRef({ _zoom: 1 });
  const [zoom, setZoom] = useState(1);

  const pointsInfoComputed = useMemo(() => pointsInfo?.filter((x) => !!x.point), [pointsInfo]);

  // load SVG by URL
  useEffect(() => {
    setIsLoading(true);
    if (imageUrl) {
      // TODO: move to VehicleVisualModelHelper
      fetch(imageUrl)
        .then(async (response) => {
          const res = await response.text();
          // TODO: what this code does?
          const resFixed = res.replace(
            /(?<svgTagInfo><svg(\s*[a-zA-Z:]+=".*")+>)/,
            (svgTagInfo) => {
              const tagFixed = svgTagInfo.replace(/(width|height)+=".*"/g, "");
              return tagFixed;
            },
          );
          setSvgContent(resFixed);
        })
        .catch((err) => setIsError(true))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);
    }
  }, [imageUrl]);

  const zoomTo = (step: number) => {
    const speed = 0.1;
    zoomVarClosureCheatFixRef.current._zoom +=
      -1 * Math.max(-1, Math.min(1, step)) * speed * zoomVarClosureCheatFixRef.current._zoom;
    const max_scale = 10;
    const min_scale = 1;
    zoomVarClosureCheatFixRef.current._zoom = Math.max(
      min_scale,
      Math.min(max_scale, zoomVarClosureCheatFixRef.current._zoom),
    );

    setZoom(zoomVarClosureCheatFixRef.current._zoom);
  };
  const handleWheel = (event: WheelEvent) => {
    event.stopPropagation();
    event.preventDefault();

    zoomTo(event.deltaY);

    return false;
  };
  const handleScaleDownClick = () => {
    zoomTo(-10);
  };
  const handleScaleUpClick = () => {
    zoomTo(10);
  };
  const handleCenterZoom = () => {
    if (containerRef.current) {
      const arrowHeight = containerRef.current.offsetHeight - containerRef.current?.clientHeight;
      const viewportHeight = containerRef.current.clientHeight;
      const contentHeight = containerRef.current.scrollHeight;
      const viewableRatio = viewportHeight / contentHeight;
      const scrollBarArea = viewportHeight - arrowHeight * 2;
      const thumbSizeVertical = scrollBarArea * viewableRatio;

      const arrowWidth = containerRef.current.offsetWidth - containerRef.current.clientWidth;
      const viewportWidth = containerRef.current.clientWidth;
      const contentWidth = containerRef.current.scrollWidth;
      const viewableRatio2 = viewportWidth / contentWidth;
      const scrollBarArea2 = viewportWidth - arrowWidth * 2;
      const thumbSizeHorizontal = scrollBarArea2 * viewableRatio2;

      containerRef.current.scrollTo(
        containerRef.current.scrollWidth / 2 - thumbSizeHorizontal,
        containerRef.current.scrollHeight / 2 - thumbSizeVertical,
      );
    }
  };

  const handleResize = (aspectRatio: number) => {
    // requestAnimationFrame for fix ResizeObserver loop completed with undelivered notifications.
    requestAnimationFrame(() => {
      if (!panDataRef.current.isPanning && containerRef.current) {
        const currentWidth = Number(containerRef.current.style.width.replace("px", ""));
        if (currentWidth !== 0) {
          const newHeight = currentWidth * aspectRatio;
          containerRef.current.style.height = `${newHeight}px`;
        }
      }
    });
  };

  useEffect(() => {
    if (containerRef.current) {
      const computedStyles = getComputedStyle(containerRef.current);
      const currentHeight = Number(computedStyles.height.replace("px", ""));
      const currentWidth = Number(computedStyles.width.replace("px", ""));
      const aspectRatio = currentHeight / currentWidth;
      const containerElement = containerRef.current;
      const resizeObserver = new ResizeObserver(_.throttle(() => handleResize(aspectRatio), 100));
      resizeObserver.observe(containerElement);
      return () => {
        resizeObserver.unobserve(containerElement);
      };
    }
  }, [containerRef.current]);

  // subscribe on wheel to handle zooming
  useEffect(() => {
    if (withZooming) {
      containerRef.current?.addEventListener("wheel", handleWheel, {
        passive: false,
      });

      return () => {
        containerRef.current?.removeEventListener("wheel", handleWheel);
      };
    }
  }, [containerRef.current]);

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "400px",
          width: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...containerSx,
        }}
      >
        <CircularProgress size='24px' />
      </Box>
    );
  }
  if (!svgContent) {
    return null;
  }

  const getCursorPoint = (evt: React.MouseEvent<Element>): CursorPoint | null => {
    try {
      if (svgContainerRef.current) {
        const svgElement = getSvgElementFromSvgContainerOrThrow(svgContainerRef.current);
        const refBBox = svgElement.getBBox(); // get bounding box
        const pt = new DOMPoint();
        pt.x = evt.pageX;
        pt.y = evt.pageY;
        const svgMatrix = svgElement.getScreenCTM()?.inverse();

        if (svgMatrix) {
          const pointTransformed = pt.matrixTransform(svgMatrix);
          const pointAsSimpleObj: CursorPoint = {
            x: pointTransformed.x,
            xp: pointTransformed.x / (refBBox.width + refBBox.x * 2),
            y: pointTransformed.y,
            yp: pointTransformed.y / (refBBox.height + refBBox.y * 2),
            width: parseInt((refBBox.width + refBBox.x * 2).toFixed(0)),
            height: parseInt((refBBox.height + refBBox.y * 2).toFixed(0)),
          };

          // console.log(0, {
          //   evt,
          //   svgElement,
          //   refBBox,
          //   pt,
          //   svgMatrix,
          //   pointTransformed,
          //   pointAsSimpleObj,
          //   temp: {
          //     width: parseInt(refBBox.width.toFixed(0)),
          //     height: parseInt(refBBox.height.toFixed(0)),
          //   },
          // });

          return pointAsSimpleObj;
        }
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleClick = (e: MouseEvent<Element>) => {
    try {
      // handle click on <path /> only as only them represent vehicle part types
      const el = e.target as Element;
      if (el.nodeName !== "path") {
        return;
      }

      // parse vehicle part type metadata from the path
      const desc = el.querySelector("desc")?.innerHTML || "";
      let info: VehicleVisualModelSvgPathMetadataDto = {
        vehicleProjection: undefined,
        vehicleArea: undefined,
      };
      if (desc) {
        try {
          info = JSON.parse(desc);
        } catch (err) {
          console.error(err);
        }
      }
      const point = getCursorPoint(e);
      if (!point) {
        return;
      }
      const newInfo = {
        id: _.uniqueId("frontend-temp-id-"),
        area: info.vehicleArea,
        projection: info.vehicleProjection,
        point,
        svgPathMetadata: info,
      };
      onVisualModelClick && onVisualModelClick(newInfo);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Stack direction='row' spacing={1}>
      {/* Container */}
      <Box
        // must have to prevent whole page scrolling bug
        onScroll={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        ref={containerRef}
        sx={{
          width: "400px",
          display: "flex",
          overflow: withZooming ? "scroll" : "hidden",
          resize: `${selectedPerspective ? "horizontal" : "none"}`,
          ...containerSx,
        }}
      >
        {/* SVG container */}
        <Box
          ref={svgContainerRef}
          component={"div"}
          dangerouslySetInnerHTML={{ __html: svgContent }}
          sx={{
            width: "100%",
            borderRadius: "inherit",
            position: "relative",
            transform: `scale(${zoom}, ${zoom})`,
            transformOrigin: `0 0`,
            margin: "auto",
          }}
          onMouseDown={(event: MouseEvent<Element>) => {
            panDataRef.current.isPanning = true;
            panDataRef.current.startCoords.x = event.clientX;
            panDataRef.current.startCoords.y = event.clientY;
            panDataRef.current.initialCoords.x = event.clientX;
            panDataRef.current.initialCoords.y = event.clientY;
          }}
          onMouseUp={(event: MouseEvent<Element>) => {
            panDataRef.current.isPanning = false;
            if (
              event.clientX === panDataRef.current.initialCoords.x &&
              event.clientY === panDataRef.current.initialCoords.y
            ) {
              handleClick(event);
            }
          }}
        ></Box>

        {/* Damage points */}
        {pointsInfoComputed.map((pointInfo) => {
          return (
            <DamagePoint
              key={pointInfo.id}
              ref={svgContainerRef}
              svgContainerRef={svgContainerRef}
              svgContent={svgContent}
              addParentOnMoveListener={(fn) => {
                if (svgContainerRef.current) {
                  const callback: EventListener = (event) => {
                    fn && fn(event, zoomVarClosureCheatFixRef.current._zoom);
                  };
                  svgContainerRef?.current?.addEventListener("mousemove", callback);

                  return () => {
                    svgContainerRef?.current?.removeEventListener("mousemove", callback);
                  };
                }
              }}
              pointInfo={pointInfo}
              onPointClicked={onPointClicked}
              pointCircleRadius={pointCircleRadius}
              defaultPointColor={defaultPointColor}
              onPointMoved={onPointMoved}
            />
          );
        })}
      </Box>

      {/* Zoom controls */}
      {withZooming && (
        <Stack
          onClick={(event) => {
            event.stopPropagation();
          }}
          direction='column'
          sx={{
            ml: 1,
            mt: "auto !important",
          }}
        >
          {/* Center image */}
          <Stack
            direction='column'
            sx={{
              p: 0.5,
              borderRadius: "16px",
              boxShadow: (theme) => theme.shadows[1],
            }}
          >
            <IconButton onClick={handleCenterZoom}>
              <AppIcon of='adjust' />
            </IconButton>
          </Stack>

          {/* Zoom in/out */}
          <Stack
            direction='column'
            sx={{
              mt: 1,
              p: 0.5,
              borderRadius: "16px",
              boxShadow: (theme) => theme.shadows[2],
            }}
          >
            <IconButton onClick={handleScaleDownClick}>
              <AppIcon of='add' />
            </IconButton>
            <IconButton onClick={handleScaleUpClick} sx={{ mt: 1 }}>
              <AppIcon of='remove' />
            </IconButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { LocationDto } from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import DepartmentLink from "@/common/components/Entity/Department/DepartmentLink";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";

interface Props {
  location: LocationDto;
}

export default function OverviewTabContent({ location }: Props) {
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={location} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <FieldValue label='Department' isEmpty={!location?.department}>
                <DepartmentLink entity={location.department} />
              </FieldValue>

              <FieldValue label='Name' isEmpty={!location?.name}>
                {location?.name}
              </FieldValue>

              <FieldValue label='Description' isEmpty={!location?.description}>
                {location?.description}
              </FieldValue>

              <FieldValue label='Address' isEmpty={!location?.address}>
                <GeneralAddressDisplay address={location?.address} />
              </FieldValue>
            </Stack>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

import { useState } from "react";

import { VehicleVisualModelHelper } from "@/common/helpers/entity/vehicleVisualModel";
import { VehicleSpecDto, VehicleSpecInputDto, VehicleVisualModelDto } from "@/core/api/generated";
import { useEffectWithDeepCompare } from "../../effect/useEffectWithDeepCompare";

interface Props {
  spec: VehicleSpecDto | VehicleSpecInputDto | null | undefined;
  onChange?: (newValue: VehicleVisualModelDto | null | undefined) => void;
}

/** Returns the most suitable or default VehicleVisualModel for provided VehicleSpec. */
export const useSuggestedVehicleVisualModel = ({
  spec,
  onChange,
}: Props): VehicleVisualModelDto | null | undefined => {
  const [visualModel, setVisualModel] = useState<VehicleVisualModelDto | null | undefined>();

  useEffectWithDeepCompare(() => {
    (async () => {
      const oldVisualModel = visualModel;
      const newVisualModel = await VehicleVisualModelHelper.getSuggestedModel(spec);
      setVisualModel(newVisualModel);

      if (newVisualModel?.id !== oldVisualModel?.id) {
        onChange && onChange(newVisualModel);
      }
    })();
  }, [spec]);

  return visualModel;
};

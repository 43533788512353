import { useHistory, useParams } from "react-router";

import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import IntegrationApiClientCreateUpdate from "@/common/components/Entity/IntegrationApiClient/IntegrationApiClientCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function IntegrationApiClientCreateUpdatePage() {
  const { integrationApiClientId } = useParams<{ integrationApiClientId?: string }>();
  const history = useHistory();

  const isCreate = !integrationApiClientId;

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader title={isCreate ? "Create new API client" : "Edit API client"} />
      }
    >
      <IntegrationApiClientCreateUpdate
        integrationApiClientId={integrationApiClientId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.MANAGEMENT_INTEGRATION_API_CLIENT_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}

import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import RepairOperationView from "@/common/components/Entity/RepairOperation/View/RepairOperationView";

export type RepairOperationViewPageQueryParams = GeneralQueryParams;

export default function RepairOperationViewPage() {
  const { repairOperationId } = useParams<{ repairOperationId?: string }>();

  return (
    <>
      <RepairOperationView
        repairOperationId={repairOperationId}
        repairOperation={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}

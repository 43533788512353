import { ContractDto, ContractStage } from "@/core/api/generated";
import { StringHelper } from "../string";
import { enumService } from "@/common/services/enum";

export class ContractHelper {
  public static getComputedState(contract: ContractDto | null | undefined) {
    if (!contract) {
      return undefined;
    }

    const checkOutAllowedStages: ContractStage[] = [ContractStage.Draft];
    const checkInAllowedStages: ContractStage[] = [ContractStage.Active];

    return {
      confirmAllocation: {
        can: !!contract?.mustConfirmAllocation,
        reason: undefined,
      },
      visualInspectionBeforeCheckOut: {
        can: !!contract?.mustPerformVisualInspectionBeforeCheckOut,
        reason: undefined,
      },
      visualInspectionBeforeCheckIn: {
        can: !!contract?.mustPerformVisualInspectionBeforeCheckIn,
        reason: undefined,
      },
      vehicleCheckOut: {
        can: !!contract?.canCheckOut,
        reason: contract?.canCheckOut
          ? undefined
          : `Check-out is allowed only on these stages: ${StringHelper.joinIntoString(
              checkOutAllowedStages.map((x) => enumService.getEnumValueName("ContractStage", x)),
              ", ",
            )}.`,
      },
      vehicleCheckIn: {
        can: !!contract?.canCheckIn,
        reason: contract?.canCheckIn
          ? undefined
          : `Check-in is allowed only on these stages: ${StringHelper.joinIntoString(
              checkInAllowedStages.map((x) => enumService.getEnumValueName("ContractStage", x)),
              ", ",
            )}.`,
      },
    };
  }
}

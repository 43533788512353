import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { TENANT_IDENTIFIER_HEADER_NAME } from "@/common/constants/multitenancy";
import NetworkErrorModel from "@/common/models/api/NetworkErrorModel";
import ServerErrorModel from "@/common/models/api/ServerErrorModel";
import ServerForbiddenErrorModel from "@/common/models/api/ServerForbiddenErrorModel";
import ServerNotFoundErrorModel from "@/common/models/api/ServerNotFoundErrorModel";
import ServerUnauthorizedErrorModel from "@/common/models/api/ServerUnauthorizedErrorModel";
import ServerValidationErrorModel from "@/common/models/api/ServerValidationErrorModel";

import { API_HTTP_HEADER_NAME } from "@/common/constants/common";
import { TypedEventEmitter } from "@/common/eventEmmiters/typedEventEmitter";
import { TypeHelper } from "@/common/helpers/type";
import ServerBaseErrorModel from "@/common/models/api/ServerBaseErrorModel";
import { IUserI18nSettings } from "@/common/ts/i18n";
import { appCommonConfig } from "../../config/config";
import {
  AccessoriesApi,
  AccessoryChecksApi,
  AccountApi,
  AdminAccountApi,
  AdminCustomSessionsApi,
  AdminDamageTypesApi,
  AdminDomainEventsApi,
  AdminGeneralReferenceDataApi,
  AdminIntegrationApiClientsApi,
  AdminInvitesApi,
  AdminInvoicesApi,
  AdminNotificationsApi,
  AdminRepairMaterialsApi,
  AdminRepairSparePartApi,
  AdminRepairSpecsApi,
  AdminRepairWorkApi,
  AdminSubscriptionPlansApi,
  AdminSubscriptionsApi,
  AdminSystemCacheApi,
  AdminTenantRequestsApi,
  AdminTenantsApi,
  AdminUsersApi,
  AdminVehicleBodyTypesApi,
  AdminVehicleFuelTypesApi,
  AdminVehicleGenerationsApi,
  AdminVehicleMakesApi,
  AdminVehicleModelsApi,
  AdminVehicleModificationsApi,
  AdminVehiclePartTypesApi,
  AdminVehicleTypesApi,
  AdminVehicleVisualModelsApi,
  AssessmentFlowsApi,
  AssetSubscriptionPlansApi,
  AssetSubscriptionsApi,
  AssetsApi,
  CascadeActionsApi,
  ChatActivityApi,
  ChatActivityOverviewsApi,
  ChatEventsApi,
  ChatHistoryApi,
  ChatMessagesApi,
  ChatParticipantsApi,
  ChatUserSettingsApi,
  ChatsApi,
  ContractsApi,
  CustomTagsApi,
  CustomersApi,
  CustomersInvitesApi,
  DamageCostEvaluationAggregatesApi,
  DamageCostEvaluationsApi,
  DamageDetectionAggregatesApi,
  DamageDetectionsApi,
  DamageTypesApi,
  DataGrantsApi,
  DepartmentsApi,
  DeviceTokensApi,
  DocumentsApi,
  EnumsApi,
  FileApi,
  GeneralBrandingApi,
  GeneralEventLogsApi,
  GeneralHistoryApi,
  GeneralReferenceDataApi,
  GeneralScopesApi,
  GeneralTagsApi,
  GlobalSearchApi,
  IntegrationApiClientsApi,
  InvitesApi,
  InvoicesApi,
  LocationsApi,
  NegotiationsApi,
  NotificationsApi,
  OperationsApi,
  PartiesApi,
  PaymentsApi,
  PingApi,
  PoolItemsApi,
  PoolsApi,
  ProblemDetails,
  ProductLocationsApi,
  ProfileApi,
  RepairCatalogsApi,
  RepairMaterialsApi,
  RepairOperationsApi,
  RepairSparePartApi,
  RepairSpecsApi,
  RepairWorkApi,
  SpotsApi,
  SubscriptionPlansApi,
  SubscriptionsApi,
  TenantAccountApi,
  TenantConnectionRequestsApi,
  TenantConnectionsApi,
  TenantExportApi,
  TenantImportApi,
  TenantInvitesApi,
  TenantProfileApi,
  TenantRequestsApi,
  TenantStructureApi,
  TenantToTenantApi,
  TenantsApi,
  TestIdentityApi,
  TestPermissionsApi,
  UserSecretsApi,
  UsersApi,
  ValidationProblemDetails,
  VehicleBodyTypesApi,
  VehicleDamagesApi,
  VehicleFuelTypesApi,
  VehicleGenerationsApi,
  VehicleHistoryApi,
  VehicleMakesApi,
  VehicleModelsApi,
  VehicleModificationsApi,
  VehiclePartTypesApi,
  VehicleTypesApi,
  VehicleVisualModelsApi,
  VehiclesApi,
  VisualInspectionsApi,
  WebPushWebhookApi,
  WebhooksApi,
} from "./generated/api";
import { BaseAPI } from "./generated/base";
import { Configuration } from "./generated/configuration";
import {
  TeslaAuthApi,
  TeslaConnectionsApi,
  TeslaFeatureInfoApi,
  TeslaVehiclesApi,
} from "./generated/v0.1-demo";

/** Represents last known API connection state (whether it was available or not). */
export enum ApiConnectionStatus {
  Connected = "Connected",
  Disconnected = "Disconnected",
}

type ApiResponseHandler = (
  error?: AxiosError<any, any>,
  response?: AxiosResponse<any, any>,
) => void | Promise<void>;
type ApiSuccessHandler = (response: AxiosResponse<any, any>) => void | Promise<void>;
type ApiErrorHandler = (error: AxiosError<any, any>) => void | Promise<void>;

export class ApiClient extends TypedEventEmitter<{
  // list of supported events
  connectionStateChange: ApiConnectionStatus;
  connected: undefined;
  disconnected: undefined;
}> {
  private _accessToken: string;
  private _connectionStatus: ApiConnectionStatus = ApiConnectionStatus.Disconnected;
  private _apiConfiguration: Configuration;
  private apiResponseHandlers: ApiResponseHandler[] = [];
  private apiSuccessHandlers: ApiSuccessHandler[] = [];
  private apiErrorHandlers: ApiErrorHandler[] = [];

  public pingApi!: PingApi;

  public adminAccountApi!: AdminAccountApi;
  public adminTenantsApi!: AdminTenantsApi;
  public adminUsersApi!: AdminUsersApi;
  public adminInvoicesApi!: AdminInvoicesApi;
  public adminNotificationsApi!: AdminNotificationsApi;
  public adminSubscriptionPlansApi!: AdminSubscriptionPlansApi;
  public adminSubscriptionsApi!: AdminSubscriptionsApi;
  public adminGeneralReferenceDataApi!: AdminGeneralReferenceDataApi;
  public adminVehicleTypesApi!: AdminVehicleTypesApi;
  public adminVehicleFuelTypesApi!: AdminVehicleFuelTypesApi;
  public adminVehicleMakesApi!: AdminVehicleMakesApi;
  public adminVehicleModelsApi!: AdminVehicleModelsApi;
  public adminVehicleGenerationsApi!: AdminVehicleGenerationsApi;
  public adminVehicleModificationsApi!: AdminVehicleModificationsApi;
  public adminVehicleBodyTypesApi!: AdminVehicleBodyTypesApi;
  public adminDamageTypesApi!: AdminDamageTypesApi;
  public adminVehiclePartTypesApi!: AdminVehiclePartTypesApi;
  public adminVehicleVisualModelsApi!: AdminVehicleVisualModelsApi;
  public adminRepairMaterialsApi!: AdminRepairMaterialsApi;
  public adminRepairWorkApi!: AdminRepairWorkApi;
  public adminRepairSparePartApi!: AdminRepairSparePartApi;
  public adminRepairSpecsApi!: AdminRepairSpecsApi;
  public adminSystemCacheApi!: AdminSystemCacheApi;
  public adminCustomSessionsApi!: AdminCustomSessionsApi;
  public adminIntegrationApiClientsApi!: AdminIntegrationApiClientsApi;
  public adminInvitesApi!: AdminInvitesApi;
  public adminDomainEventsApi!: AdminDomainEventsApi;
  public adminTenantRequestsApi!: AdminTenantRequestsApi;

  public accountApi!: AccountApi;
  public invitesApi!: InvitesApi;
  public invoicesApi!: InvoicesApi;
  public paymentsApi!: PaymentsApi;
  public profileApi!: ProfileApi;
  public tenantProfileApi!: TenantProfileApi;
  public subscriptionsApi!: SubscriptionsApi;
  public subscriptionPlansApi!: SubscriptionPlansApi;
  public tenantAccountApi!: TenantAccountApi;
  public tenantInvitesApi!: TenantInvitesApi;
  public usersApi!: UsersApi;
  public vehiclesApi!: VehiclesApi;
  public vehicleHistoryApi!: VehicleHistoryApi;
  public accessoriesApi!: AccessoriesApi;
  public customersApi!: CustomersApi;
  public tenantCustomerInvitesApi!: CustomersInvitesApi;
  public contractsApi!: ContractsApi;
  public assessmentFlowsApi!: AssessmentFlowsApi;
  public vehicleTypesApi!: VehicleTypesApi;
  public vehicleFuelTypesApi!: VehicleFuelTypesApi;
  public vehicleModelsApi!: VehicleModelsApi;
  public vehicleMakesApi!: VehicleMakesApi;
  public vehicleGenerationsApi!: VehicleGenerationsApi;
  public vehicleModificationsApi!: VehicleModificationsApi;
  public vehicleBodyTypesApi!: VehicleBodyTypesApi;
  public vehiclePartTypesApi!: VehiclePartTypesApi;
  public vehicleVisualModelsApi!: VehicleVisualModelsApi;
  public vehicleDamagesApi!: VehicleDamagesApi;
  public damageTypesApi!: DamageTypesApi;
  public departmentsApi!: DepartmentsApi;
  public tenantStructureApi!: TenantStructureApi;
  public locationsApi!: LocationsApi;
  public productLocationsApi!: ProductLocationsApi;
  public spotsApi!: SpotsApi;
  public assetSubscriptionPlansApi!: AssetSubscriptionPlansApi;
  public assetSubscriptionsApi!: AssetSubscriptionsApi;
  public generalReferenceDataApi!: GeneralReferenceDataApi;
  public visualInspectionsApi!: VisualInspectionsApi;
  public damageDetectionsApi!: DamageDetectionsApi;
  public operationsApi!: OperationsApi;
  public damageDetectionAggregatesApi!: DamageDetectionAggregatesApi;
  public damageCostEvaluationAggregatesApi!: DamageCostEvaluationAggregatesApi;
  public damageCostEvaluationsApi!: DamageCostEvaluationsApi;
  public accessoryChecksApi!: AccessoryChecksApi;
  public repairOperationsApi!: RepairOperationsApi;
  public tenantImportApi!: TenantImportApi;
  public tenantExportApi!: TenantExportApi;
  public filesApi!: FileApi;
  public enumsApi!: EnumsApi;
  public notificationsApi!: NotificationsApi;
  public chatsApi!: ChatsApi;
  public negotiationsApi!: NegotiationsApi;
  public chatParticipantsApi!: ChatParticipantsApi;
  public chatMessagesApi!: ChatMessagesApi;
  public chatEventsApi!: ChatEventsApi;
  public chatHistoryApi!: ChatHistoryApi;
  public chatUserSettingsApi!: ChatUserSettingsApi;
  public chatActivityApi!: ChatActivityApi;
  public chatActivityOverviewsApi!: ChatActivityOverviewsApi;
  public documentsApi!: DocumentsApi;
  public globalSearchApi!: GlobalSearchApi;
  public repairMaterialsApi!: RepairMaterialsApi;
  public repairWorkApi!: RepairWorkApi;
  public repairSparePartApi!: RepairSparePartApi;
  public repairSpecsApi!: RepairSpecsApi;
  public repairCatalogsApi!: RepairCatalogsApi;
  public partiesApi!: PartiesApi;
  public generalTagsApi!: GeneralTagsApi;
  public generalEventLogsApi!: GeneralEventLogsApi;
  public generalScopesApi!: GeneralScopesApi;
  public generalBrandingApi!: GeneralBrandingApi;
  public deviceTokensApi!: DeviceTokensApi;
  public webPushWebhookApi!: WebPushWebhookApi;
  public poolsApi!: PoolsApi;
  public poolItemsApi!: PoolItemsApi;
  public assetsApi!: AssetsApi;
  public integrationApiClientsApi!: IntegrationApiClientsApi;
  public tenantToTenantApi!: TenantToTenantApi;
  public tenantConnectionRequestsApi!: TenantConnectionRequestsApi;
  public tenantConnectionsApi!: TenantConnectionsApi;
  public tenantRequestsApi!: TenantRequestsApi;
  public dataGrantsApi!: DataGrantsApi;
  public tenantsApi!: TenantsApi;
  public userSecretsApi!: UserSecretsApi;
  public customTagsApi!: CustomTagsApi;
  public webhooksApi!: WebhooksApi;
  public cascadeActionsApi!: CascadeActionsApi;
  public generalHistoryApi!: GeneralHistoryApi;
  public teslaFeatureInfoApi!: TeslaFeatureInfoApi;
  public teslaAuthApi!: TeslaAuthApi;
  public teslaConnectionsApi!: TeslaConnectionsApi;
  public teslaVehiclesApi!: TeslaVehiclesApi;

  public testIdentityApi!: TestIdentityApi;
  public testPermissionsApi!: TestPermissionsApi;

  constructor(apiConfiguration: Configuration) {
    super();
    this._accessToken = "";
    this._apiConfiguration = apiConfiguration;
    this.regenerateApiWithNewConfiguration(apiConfiguration);
    this.startApiConnectionTracking();
  }

  public get connectionStatus(): ApiConnectionStatus {
    return this._connectionStatus;
  }

  private regenerateApiWithNewConfiguration(apiConfiguration: Configuration) {
    this._apiConfiguration = apiConfiguration;

    this._apiConfiguration.baseOptions ||= {};
    const axiosConfig = this._apiConfiguration.baseOptions as AxiosRequestConfig;

    // enable credentials so we can read Cookies set by the API server
    axiosConfig.withCredentials = true;

    this.pingApi = this.withApiProxy(new PingApi(this._apiConfiguration));

    this.accountApi = this.withApiProxy(new AccountApi(this._apiConfiguration));
    this.adminAccountApi = this.withApiProxy(new AdminAccountApi(this._apiConfiguration));
    this.adminTenantsApi = this.withApiProxy(new AdminTenantsApi(this._apiConfiguration));
    this.adminUsersApi = this.withApiProxy(new AdminUsersApi(this._apiConfiguration));
    this.adminInvoicesApi = this.withApiProxy(new AdminInvoicesApi(this._apiConfiguration));
    this.adminNotificationsApi = this.withApiProxy(
      new AdminNotificationsApi(this._apiConfiguration),
    );
    this.adminSubscriptionPlansApi = this.withApiProxy(
      new AdminSubscriptionPlansApi(this._apiConfiguration),
    );
    this.adminSubscriptionsApi = this.withApiProxy(
      new AdminSubscriptionsApi(this._apiConfiguration),
    );
    this.adminGeneralReferenceDataApi = this.withApiProxy(
      new AdminGeneralReferenceDataApi(this._apiConfiguration),
    );
    this.adminVehicleTypesApi = this.withApiProxy(new AdminVehicleTypesApi(this._apiConfiguration));
    this.adminVehicleFuelTypesApi = this.withApiProxy(
      new AdminVehicleFuelTypesApi(this._apiConfiguration),
    );
    this.adminVehicleMakesApi = this.withApiProxy(new AdminVehicleMakesApi(this._apiConfiguration));
    this.adminVehicleModelsApi = this.withApiProxy(
      new AdminVehicleModelsApi(this._apiConfiguration),
    );
    this.adminVehicleGenerationsApi = this.withApiProxy(
      new AdminVehicleGenerationsApi(this._apiConfiguration),
    );
    this.adminVehicleModificationsApi = this.withApiProxy(
      new AdminVehicleModificationsApi(this._apiConfiguration),
    );
    this.adminVehicleBodyTypesApi = this.withApiProxy(
      new AdminVehicleBodyTypesApi(this._apiConfiguration),
    );
    this.adminDamageTypesApi = this.withApiProxy(new AdminDamageTypesApi(this._apiConfiguration));
    this.adminVehiclePartTypesApi = this.withApiProxy(
      new AdminVehiclePartTypesApi(this._apiConfiguration),
    );
    this.adminVehicleVisualModelsApi = this.withApiProxy(
      new AdminVehicleVisualModelsApi(this._apiConfiguration),
    );
    this.adminRepairMaterialsApi = this.withApiProxy(
      new AdminRepairMaterialsApi(this._apiConfiguration),
    );
    this.adminRepairWorkApi = this.withApiProxy(new AdminRepairWorkApi(this._apiConfiguration));
    this.adminRepairSparePartApi = this.withApiProxy(
      new AdminRepairSparePartApi(this._apiConfiguration),
    );
    this.adminRepairSpecsApi = this.withApiProxy(new AdminRepairSpecsApi(this._apiConfiguration));
    this.adminSystemCacheApi = this.withApiProxy(new AdminSystemCacheApi(this._apiConfiguration));
    this.adminCustomSessionsApi = this.withApiProxy(
      new AdminCustomSessionsApi(this._apiConfiguration),
    );
    this.adminIntegrationApiClientsApi = this.withApiProxy(
      new AdminIntegrationApiClientsApi(this._apiConfiguration),
    );
    this.adminInvitesApi = this.withApiProxy(new AdminInvitesApi(this._apiConfiguration));
    this.adminDomainEventsApi = this.withApiProxy(new AdminDomainEventsApi(this._apiConfiguration));
    this.adminTenantRequestsApi = this.withApiProxy(
      new AdminTenantRequestsApi(this._apiConfiguration),
    );

    this.invitesApi = this.withApiProxy(new InvitesApi(this._apiConfiguration));
    this.invoicesApi = this.withApiProxy(new InvoicesApi(this._apiConfiguration));
    this.paymentsApi = this.withApiProxy(new PaymentsApi(this._apiConfiguration));
    this.profileApi = this.withApiProxy(new ProfileApi(this._apiConfiguration));
    this.tenantProfileApi = this.withApiProxy(new TenantProfileApi(this._apiConfiguration));
    this.subscriptionsApi = this.withApiProxy(new SubscriptionsApi(this._apiConfiguration));
    this.subscriptionPlansApi = this.withApiProxy(new SubscriptionPlansApi(this._apiConfiguration));
    this.tenantAccountApi = this.withApiProxy(new TenantAccountApi(this._apiConfiguration));
    this.tenantInvitesApi = this.withApiProxy(new TenantInvitesApi(this._apiConfiguration));
    this.usersApi = this.withApiProxy(new UsersApi(this._apiConfiguration));
    this.vehiclesApi = this.withApiProxy(new VehiclesApi(this._apiConfiguration));
    this.vehicleHistoryApi = this.withApiProxy(new VehicleHistoryApi(this._apiConfiguration));
    this.accessoriesApi = this.withApiProxy(new AccessoriesApi(this._apiConfiguration));
    this.customersApi = this.withApiProxy(new CustomersApi(this._apiConfiguration));
    this.tenantCustomerInvitesApi = this.withApiProxy(
      new CustomersInvitesApi(this._apiConfiguration),
    );
    this.contractsApi = this.withApiProxy(new ContractsApi(this._apiConfiguration));
    this.assessmentFlowsApi = this.withApiProxy(new AssessmentFlowsApi(this._apiConfiguration));
    this.vehicleTypesApi = this.withApiProxy(new VehicleTypesApi(this._apiConfiguration));
    this.vehicleFuelTypesApi = this.withApiProxy(new VehicleFuelTypesApi(this._apiConfiguration));
    this.vehicleModelsApi = this.withApiProxy(new VehicleModelsApi(this._apiConfiguration));
    this.vehicleMakesApi = this.withApiProxy(new VehicleMakesApi(this._apiConfiguration));
    this.vehicleGenerationsApi = this.withApiProxy(
      new VehicleGenerationsApi(this._apiConfiguration),
    );
    this.vehicleModificationsApi = this.withApiProxy(
      new VehicleModificationsApi(this._apiConfiguration),
    );
    this.vehicleBodyTypesApi = this.withApiProxy(new VehicleBodyTypesApi(this._apiConfiguration));
    this.vehiclePartTypesApi = this.withApiProxy(new VehiclePartTypesApi(this._apiConfiguration));
    this.vehicleVisualModelsApi = this.withApiProxy(
      new VehicleVisualModelsApi(this._apiConfiguration),
    );
    this.vehicleDamagesApi = this.withApiProxy(new VehicleDamagesApi(this._apiConfiguration));
    this.damageTypesApi = this.withApiProxy(new DamageTypesApi(this._apiConfiguration));
    this.departmentsApi = this.withApiProxy(new DepartmentsApi(this._apiConfiguration));
    this.tenantStructureApi = this.withApiProxy(new TenantStructureApi(this._apiConfiguration));
    this.locationsApi = this.withApiProxy(new LocationsApi(this._apiConfiguration));
    this.productLocationsApi = this.withApiProxy(new ProductLocationsApi(this._apiConfiguration));
    this.spotsApi = this.withApiProxy(new SpotsApi(this._apiConfiguration));
    this.assetSubscriptionPlansApi = this.withApiProxy(
      new AssetSubscriptionPlansApi(this._apiConfiguration),
    );
    this.assetSubscriptionsApi = this.withApiProxy(
      new AssetSubscriptionsApi(this._apiConfiguration),
    );
    this.generalReferenceDataApi = this.withApiProxy(
      new GeneralReferenceDataApi(this._apiConfiguration),
    );
    this.visualInspectionsApi = this.withApiProxy(new VisualInspectionsApi(this._apiConfiguration));
    this.damageDetectionsApi = this.withApiProxy(new DamageDetectionsApi(this._apiConfiguration));
    this.operationsApi = this.withApiProxy(new OperationsApi(this._apiConfiguration));
    this.damageDetectionAggregatesApi = this.withApiProxy(
      new DamageDetectionAggregatesApi(this._apiConfiguration),
    );
    this.damageCostEvaluationAggregatesApi = this.withApiProxy(
      new DamageCostEvaluationAggregatesApi(this._apiConfiguration),
    );
    this.damageCostEvaluationsApi = this.withApiProxy(
      new DamageCostEvaluationsApi(this._apiConfiguration),
    );
    this.tenantImportApi = this.withApiProxy(new TenantImportApi(this._apiConfiguration));
    this.tenantExportApi = this.withApiProxy(new TenantExportApi(this._apiConfiguration));
    this.accessoryChecksApi = this.withApiProxy(new AccessoryChecksApi(this._apiConfiguration));
    this.repairOperationsApi = this.withApiProxy(new RepairOperationsApi(this._apiConfiguration));
    this.filesApi = this.withApiProxy(new FileApi(this._apiConfiguration));

    this.enumsApi = this.withApiProxy(new EnumsApi(this._apiConfiguration));
    this.notificationsApi = this.withApiProxy(new NotificationsApi(this._apiConfiguration));
    this.chatsApi = this.withApiProxy(new ChatsApi(this._apiConfiguration));
    this.negotiationsApi = this.withApiProxy(new NegotiationsApi(this._apiConfiguration));
    this.chatParticipantsApi = this.withApiProxy(new ChatParticipantsApi(this._apiConfiguration));
    this.chatMessagesApi = this.withApiProxy(new ChatMessagesApi(this._apiConfiguration));
    this.chatEventsApi = this.withApiProxy(new ChatEventsApi(this._apiConfiguration));
    this.chatHistoryApi = this.withApiProxy(new ChatHistoryApi(this._apiConfiguration));
    this.chatUserSettingsApi = this.withApiProxy(new ChatUserSettingsApi(this._apiConfiguration));
    this.chatActivityApi = this.withApiProxy(new ChatActivityApi(this._apiConfiguration));
    this.chatActivityOverviewsApi = this.withApiProxy(
      new ChatActivityOverviewsApi(this._apiConfiguration),
    );
    this.documentsApi = this.withApiProxy(new DocumentsApi(this._apiConfiguration));
    this.globalSearchApi = this.withApiProxy(new GlobalSearchApi(this._apiConfiguration));
    this.repairMaterialsApi = this.withApiProxy(new RepairMaterialsApi(this._apiConfiguration));
    this.repairWorkApi = this.withApiProxy(new RepairWorkApi(this._apiConfiguration));
    this.repairSparePartApi = this.withApiProxy(new RepairSparePartApi(this._apiConfiguration));
    this.repairSpecsApi = this.withApiProxy(new RepairSpecsApi(this._apiConfiguration));
    this.repairCatalogsApi = this.withApiProxy(new RepairCatalogsApi(this._apiConfiguration));
    this.partiesApi = this.withApiProxy(new PartiesApi(this._apiConfiguration));
    this.generalTagsApi = this.withApiProxy(new GeneralTagsApi(this._apiConfiguration));
    this.generalEventLogsApi = this.withApiProxy(new GeneralEventLogsApi(this._apiConfiguration));
    this.generalScopesApi = this.withApiProxy(new GeneralScopesApi(this._apiConfiguration));
    this.generalBrandingApi = this.withApiProxy(new GeneralBrandingApi(this._apiConfiguration));
    this.deviceTokensApi = this.withApiProxy(new DeviceTokensApi(this._apiConfiguration));
    this.webPushWebhookApi = this.withApiProxy(new WebPushWebhookApi(this._apiConfiguration));
    this.poolsApi = this.withApiProxy(new PoolsApi(this._apiConfiguration));
    this.poolItemsApi = this.withApiProxy(new PoolItemsApi(this._apiConfiguration));
    this.assetsApi = this.withApiProxy(new AssetsApi(this._apiConfiguration));
    this.integrationApiClientsApi = this.withApiProxy(
      new IntegrationApiClientsApi(this._apiConfiguration),
    );
    this.tenantToTenantApi = this.withApiProxy(new TenantToTenantApi(this._apiConfiguration));
    this.tenantConnectionRequestsApi = this.withApiProxy(
      new TenantConnectionRequestsApi(this._apiConfiguration),
    );
    this.tenantConnectionsApi = this.withApiProxy(new TenantConnectionsApi(this._apiConfiguration));
    this.tenantRequestsApi = this.withApiProxy(new TenantRequestsApi(this._apiConfiguration));
    this.dataGrantsApi = this.withApiProxy(new DataGrantsApi(this._apiConfiguration));
    this.tenantsApi = this.withApiProxy(new TenantsApi(this._apiConfiguration));
    this.userSecretsApi = this.withApiProxy(new UserSecretsApi(this._apiConfiguration));
    this.customTagsApi = this.withApiProxy(new CustomTagsApi(this._apiConfiguration));
    this.webhooksApi = this.withApiProxy(new WebhooksApi(this._apiConfiguration));
    this.cascadeActionsApi = this.withApiProxy(new CascadeActionsApi(this._apiConfiguration));
    this.generalHistoryApi = this.withApiProxy(new GeneralHistoryApi(this._apiConfiguration));
    this.teslaFeatureInfoApi = this.withApiProxy(new TeslaFeatureInfoApi(this._apiConfiguration));
    this.teslaAuthApi = this.withApiProxy(new TeslaAuthApi(this._apiConfiguration));
    this.teslaConnectionsApi = this.withApiProxy(new TeslaConnectionsApi(this._apiConfiguration));
    this.teslaVehiclesApi = this.withApiProxy(new TeslaVehiclesApi(this._apiConfiguration));

    this.testIdentityApi = this.withApiProxy(new TestIdentityApi(this._apiConfiguration));
    this.testPermissionsApi = this.withApiProxy(new TestPermissionsApi(this._apiConfiguration));
  }

  public getAccessToken(): string {
    return this._accessToken;
  }

  /** Sets access token to be used for future requests. */
  public updateAccessToken(token: string): void {
    this._accessToken = token;
    const configuration = new Configuration({
      ...this._apiConfiguration,
      accessToken: token,
    });
    this.regenerateApiWithNewConfiguration(configuration);
  }

  /** Sets tenant identifier to be used for future requests. */
  public updateTenantIdentifier(identifier: string): void {
    const configuration = new Configuration({
      ...this._apiConfiguration,
    });
    const axiosConfig = (configuration.baseOptions || { headers: {} }) as AxiosRequestConfig;
    axiosConfig.headers ||= {};

    // NB: ensure headers are non-empty here so they can be overwritten by specific API methods
    axiosConfig.headers[`${TENANT_IDENTIFIER_HEADER_NAME}`] = identifier;
    if (TypeHelper.isEmpty(axiosConfig.headers[`${TENANT_IDENTIFIER_HEADER_NAME}`])) {
      delete axiosConfig.headers[`${TENANT_IDENTIFIER_HEADER_NAME}`];
    }

    this.regenerateApiWithNewConfiguration(configuration);
  }

  /** Sets SignalR connection ids to be used for future requests. */
  public updateSignalConnectionIds(props: {
    chatHubConnectionId?: string | null;
    notificationHubConnectionId?: string | null;
    dataUpdatesHubConnectionId?: string | null;
  }): void {
    const configuration = new Configuration({
      ...this._apiConfiguration,
    });
    const axiosConfig = (configuration.baseOptions || { headers: {} }) as AxiosRequestConfig;
    axiosConfig.headers ||= {};

    axiosConfig.headers[API_HTTP_HEADER_NAME.SIGNALR_CHATHUB_CONNECTIONID] =
      props.chatHubConnectionId ||
      axiosConfig.headers[API_HTTP_HEADER_NAME.SIGNALR_CHATHUB_CONNECTIONID];
    axiosConfig.headers[API_HTTP_HEADER_NAME.SIGNALR_NOTIFICATIONHUB_CONNECTIONID] =
      props.notificationHubConnectionId ||
      axiosConfig.headers[API_HTTP_HEADER_NAME.SIGNALR_NOTIFICATIONHUB_CONNECTIONID];
    axiosConfig.headers[API_HTTP_HEADER_NAME.SIGNALR_DATAUPDATESHUB_CONNECTIONID] =
      props.dataUpdatesHubConnectionId ||
      axiosConfig.headers[API_HTTP_HEADER_NAME.SIGNALR_DATAUPDATESHUB_CONNECTIONID];
    this.regenerateApiWithNewConfiguration(configuration);
  }

  /** Sets culture to be used for future requests. */
  public updateUserI18nSettings(i18nSettings: IUserI18nSettings): void {
    const configuration = new Configuration({
      ...this._apiConfiguration,
    });
    const axiosConfig = (configuration.baseOptions || { headers: {} }) as AxiosRequestConfig;
    axiosConfig.headers ||= {};

    axiosConfig.headers[`${API_HTTP_HEADER_NAME.CULTURE_NAME}`] = i18nSettings.cultureName;
    axiosConfig.headers[`${API_HTTP_HEADER_NAME.TIME_ZONE_ID}`] = i18nSettings.tzId;
    this.regenerateApiWithNewConfiguration(configuration);
  }

  /** Registers handler that is called after each successful or error response from generated API client. */
  public registerApiResponseHandler(handler: ApiResponseHandler): void {
    this.apiResponseHandlers.push(handler);
  }

  /** Registers handler that is called after each successful response from generated API client. */
  public registerApiSuccessHandler(handler: ApiSuccessHandler): void {
    this.apiSuccessHandlers.push(handler);
  }

  /** Registers handler that is called after each error response from generated API client. */
  public registerApiErrorHandler(handler: ApiErrorHandler): void {
    this.apiErrorHandlers.push(handler);
  }

  public removeApiHandler(handler: ApiResponseHandler | ApiSuccessHandler | ApiErrorHandler): void {
    const index1 = this.apiResponseHandlers.findIndex((x) => x === handler);
    const index2 = this.apiSuccessHandlers.findIndex((x) => x === handler);
    const index3 = this.apiErrorHandlers.findIndex((x) => x === handler);

    index1 !== -1 && this.apiResponseHandlers.splice(index1, 1);
    index2 !== -1 && this.apiSuccessHandlers.splice(index2, 1);
    index3 !== -1 && this.apiErrorHandlers.splice(index3, 1);
  }

  private withApiProxy<Api = BaseAPI>(api: Api): Api {
    const apiClient = this;

    const proxy: Api = new Proxy(api as any, {
      get(target, prop, receiver) {
        const value: any = (target as any)[prop];
        const isFunction = value instanceof Function;

        if (isFunction) {
          // decorate returned function to add custom behavior after its execution
          return function (...args: any[]) {
            const result = value.apply(target, args);
            if (result instanceof Promise) {
              return result
                .then((response: AxiosResponse<any, any>) => {
                  // call registered handlers without waiting for them to execute
                  apiClient.apiResponseHandlers.map((handler) => handler(undefined, response));
                  apiClient.apiSuccessHandlers.map((handler) => handler(response));

                  return response;
                })
                .catch((err: any) => {
                  if (err instanceof AxiosError) {
                    // call registered handlers without waiting for them to execute
                    apiClient.apiResponseHandlers.map((handler) => handler(err, undefined));
                    apiClient.apiErrorHandlers.map((handler) => handler(err));
                  }

                  throw err;
                });
            } else {
              return result;
            }
          };
        }

        return value;
      },
    }) as Api;

    return proxy;
  }

  /** Checks request responses to figure out whether Network Error occurred. */
  private startApiConnectionTracking() {
    const successHandler: ApiSuccessHandler = (() => {
      this._connectionStatus = ApiConnectionStatus.Connected;
      this.emit("connectionStateChange", this._connectionStatus);
      this.emit("connected", undefined);
    }).bind(this);

    const errorHandler: ApiErrorHandler = ((err: AxiosError) => {
      const isNetworkError = err.code === "ERR_NETWORK" || err.message === "Network Error";
      if (isNetworkError) {
        // console.log("API connection tracker. Network error:", err);
        this._connectionStatus = ApiConnectionStatus.Disconnected;
        this.emit("connectionStateChange", this._connectionStatus);
        this.emit("disconnected", undefined);
      }
    }).bind(this);

    this.registerApiSuccessHandler(successHandler);
    this.registerApiErrorHandler(errorHandler);
  }

  /** Check if provided values is ProblemDetails object. */
  public isProblemDetails(problemDetails: any): boolean {
    return problemDetails?.status && problemDetails?.type && problemDetails?.title;
  }

  /** Returns ProblemDetails from the error. */
  public getProblemDetails(err: any): ValidationProblemDetails | undefined {
    if (!err) {
      return undefined;
    }

    const baseError = err as ServerBaseErrorModel;
    if (baseError.problemDetails) {
      return baseError.problemDetails;
    }

    const axiosError = err as AxiosError;
    const problemDetails = axiosError?.response?.data as
      | ValidationProblemDetails
      | ProblemDetails
      | undefined;
    if (this.isProblemDetails(problemDetails)) {
      return problemDetails;
    }

    return undefined;
  }

  /** Parses AxiosError and returns prepared error model. */
  public getApiError(
    error: AxiosError,
  ):
    | AxiosError
    | NetworkErrorModel
    | ServerValidationErrorModel
    | ServerUnauthorizedErrorModel
    | ServerForbiddenErrorModel
    | ServerNotFoundErrorModel
    | ServerErrorModel {
    const response = error.response;

    if (error?.message === "Network Error") {
      // todo: toast network error message?
      return new NetworkErrorModel(error, "Network error occurred.");
    }

    if (response) {
      const status = response.status;
      const problemDetails = this.getProblemDetails(error);
      const isProblemDetails = this.isProblemDetails(problemDetails);

      if (status === 400) {
        return new ServerValidationErrorModel(
          response,
          problemDetails?.detail || "Validation error occurred.",
          isProblemDetails ? problemDetails : { title: "Validation error occurred." },
        );
      } else if (status === 401) {
        return new ServerUnauthorizedErrorModel(
          response,
          "Unauthorized error occurred.",
          isProblemDetails ? problemDetails : { title: "Unauthorized error occurred." },
        );
      } else if (status === 403) {
        // todo: toast access forbidden message
        return new ServerForbiddenErrorModel(
          response,
          "Forbidden error occurred.",
          isProblemDetails ? problemDetails : { title: "Forbidden error occurred." },
        );
      } else if (status === 404) {
        // todo: toast not found message?
        return new ServerNotFoundErrorModel(
          response,
          "Not found error occurred.",
          isProblemDetails ? problemDetails : { title: "Not found error occurred." },
        );
      } else if (status === 500) {
        // todo: toast server side error message
        return new ServerErrorModel(
          response,
          "Server side error occurred.",
          isProblemDetails ? problemDetails : { title: "Server side error occurred." },
        );
      } else {
        // if unknown error, just rethrow and do not wrap
        return error;
      }
    }
    return error;
  }
}

export const apiClient = new ApiClient(
  new Configuration({
    basePath: appCommonConfig.apiUrl || window.location.origin,
    baseOptions: {
      // NB: base headers must not be set with empty values to allow their rewrite in specific API clients local calls.
      // generated code prioritizes headers in the next order: options.headers, headersFromBaseOptions, localVarHeaderParameter
      // generated code: headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
      headers: {
        [`${API_HTTP_HEADER_NAME.APP_VERSION}`]: appCommonConfig.version,
        // [`${TENANT_IDENTIFIER_HEADER_NAME}`]: "",
      },
    },
  }),
);

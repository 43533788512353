import React, { ReactElement, ReactNode, cloneElement, useMemo } from "react";

import { ValidationHelper } from "@/common/validation";

import _ from "lodash";
import ConfirmationModal, { ConfirmationModalProps } from "./ConfirmationModal";

type TriggerFuncType = (isModalOpened: boolean) => ReactElement;

interface Props {
  trigger: TriggerFuncType | ReactElement;
  body: ReactNode | (({ error }: { error: any }) => ReactElement);
}
type ConfirmationModalWithTriggerProps = Props &
  Omit<ConfirmationModalProps, "open" | "onClose" | "onCancel" | "body">;

export default function ConfirmationModalWithTrigger({
  trigger,
  body,
  onConfirm,
  ...otherProps
}: ConfirmationModalWithTriggerProps) {
  const [isModalOpened, setIsOpened] = React.useState(false);
  const [confirmError, setConfirmError] = React.useState<any>(null);
  const handleOpen = () => {
    setIsOpened(true);
  };
  const handleClose = () => {
    setIsOpened(false);
  };

  const triggerElement = useMemo(() => {
    const element: ReactElement = typeof trigger === "function" ? trigger(isModalOpened) : trigger;
    return cloneElement(element, {
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        e?.stopPropagation();
        e?.preventDefault();
        if ((trigger as ReactElement).props && (trigger as ReactElement).props.onClick) {
          (trigger as ReactElement).props.onClick(e);
        }
        handleOpen();
      },
    });
  }, [trigger, isModalOpened, handleOpen]);

  return (
    <>
      {triggerElement}

      <ConfirmationModal
        open={isModalOpened}
        onClose={handleClose}
        onCancel={handleClose}
        onConfirm={async (params) => {
          try {
            onConfirm && (await onConfirm(params));
            handleClose();
          } catch (error) {
            setConfirmError(ValidationHelper.handleApiErrorResponse(error));
          }
        }}
        body={_.isFunction(body) ? body({ error: confirmError }) : body}
        {...otherProps}
      />
    </>
  );
}

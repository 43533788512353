import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { AssetDto, AssetFullReferenceDto, AssetReferenceDto } from "@/core/api/generated";
import { Typography } from "@mui/material";
import GeneralStrictEntityRelationInline from "../General/Display/GeneralStrictEntityRelationInline";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import AssetIcon from "./AssetIcon";

export interface AssetInlineProps extends BaseEntityInlineInheritableProps {
  entity: AssetDto | AssetReferenceDto | AssetFullReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function AssetInline({ entity, entityId, ...otherProps }: AssetInlineProps) {
  const request = useApiRequest(
    apiClient.assetsApi.apiV1AssetsAssetIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetId: entityId || entity?.id || "",
    },
    {
      deps: [entity?.id, entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as AssetDto | AssetFullReferenceDto;
  const ref = entity as AssetReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={full ? <AssetIcon entity={full} inText /> : <AppIcon of='asset' inText />}
      content={
        <>
          <GeneralStrictEntityRelationInline
            value={entity?.entity || full?.entity}
            inlineProps={{ ...otherProps, withIcon: false }}
            {...otherProps}
          />{" "}
          <>
            <Typography component='span' variant='body2'>
              (asset {entity?.localNumber})
            </Typography>
          </>
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Asset"}
    />
  );
}

import _ from "lodash";

import { ExternalQueryStringParamName } from "@/config/config";
import { AppArea } from "../constants/routing";
import { UrlHelper } from "../helpers/url";
import { GeneralQueryParams } from "../ts/GeneralQueryParams";

/** Helps to build React Router route definition and path.
 *  E.g. /my-area/users/:userId?status=active
 */
export class RoutePathBuilder {
  /** Doesn't start/end with '/ */
  private area?: AppArea;
  /** Starts with '/ */
  private route?: string;
  private queryParams?: GeneralQueryParams;

  constructor() {}

  public static new() {
    return new RoutePathBuilder();
  }

  public static forArea(area: AppArea) {
    return new RoutePathBuilder().withArea(area);
  }

  /** Returns query string for provided query params in format: '?key=value&key2=value2' */
  public static formatQueryParams(
    queryParams?: GeneralQueryParams,
    options = { isPreserveGeneralQueryParamsOnTransition: true },
  ): string {
    queryParams = queryParams || {};

    const desiredQueryParams = UrlHelper.toURLSearchParams(queryParams);
    const currentQueryParams = UrlHelper.getCurrentUrlSearchParams();
    const finalQueryParams = UrlHelper.toURLSearchParams(queryParams);

    if (options.isPreserveGeneralQueryParamsOnTransition) {
      const generalParamNames = Object.values(ExternalQueryStringParamName);
      generalParamNames.forEach((generalParamName) => {
        const desiredParamValue = desiredQueryParams.get(generalParamName);
        const generalParamValue = currentQueryParams.get(generalParamName);

        if (_.isEmpty(desiredParamValue) && !_.isEmpty(generalParamValue)) {
          finalQueryParams.set(generalParamName, generalParamValue || "");
        }
      });
    }

    const paramsStr = finalQueryParams.toString();
    const result = !_.isEmpty(paramsStr) ? `?${paramsStr}` : "";
    return result;
  }

  public static buildSimple<TQueryParams extends GeneralQueryParams>(
    route: string,
    queryParams?: TQueryParams,
  ): string {
    return RoutePathBuilder.new().withRoute(route).withQueryParams(queryParams).build();
  }

  public static buildSimpleInArea<TQueryParams extends GeneralQueryParams>(
    area: AppArea,
    route: string,
    queryParams?: TQueryParams,
  ): string {
    return RoutePathBuilder.new()
      .withArea(area)
      .withRoute(route)
      .withQueryParams(queryParams)
      .build();
  }

  /** Ensures route is a valid route definition string.
   *  E.g. removes query string.
   */
  public static toDefinition(route: string) {
    return UrlHelper.deleteUrlStringSearchParams(route);
  }

  public withArea(area: AppArea): RoutePathBuilder {
    if (area.startsWith("/")) {
      throw new Error("'area' must not start with '/'.");
    }
    if (area.endsWith("/")) {
      throw new Error("'route' must not end with '/'.");
    }
    this.area = area;
    return this;
  }

  public withRoute(route: string): RoutePathBuilder {
    if (!route.startsWith("/")) {
      throw new Error("'route' must start with '/'.");
    }
    if (route.endsWith("/")) {
      throw new Error("'route' must not end with '/'.");
    }
    this.route = route;
    return this;
  }

  public withQueryParams<TQueryParams extends GeneralQueryParams>(
    queryParams?: TQueryParams,
  ): RoutePathBuilder {
    this.queryParams = queryParams;
    return this;
  }

  public build(): string {
    if (!this.route) {
      throw new Error("'route' must be set.");
    }
    return (
      (!_.isEmpty(this.area) ? `/${this.area}` : "") +
      `${this.route}${RoutePathBuilder.formatQueryParams(this.queryParams, {
        isPreserveGeneralQueryParamsOnTransition: true,
      })}`
    );
  }
}

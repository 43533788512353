import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";
import _ from "lodash";

import InlineCode from "@/common/components/Code/InlineCode";
import { AppPermission, PermissionDto } from "@/core/api/generated";

import AppIcon from "../Icons/AppIcon";

export interface PermissionDisplayProps {
  permission?: PermissionDto | AppPermission | string;
  variant?: "inline" | "block";
  withIcon?: boolean;
  sx?: SxProps<Theme>;
}

export default function PermissionDisplay({
  permission,
  variant = "inline",
  withIcon = false,
  sx,
}: PermissionDisplayProps) {
  const permissionDto = permission as PermissionDto;
  const appPermission = permission as AppPermission | string;

  const title = permissionDto?.name || appPermission || "";
  const title2 = permissionDto?.permission || appPermission || "";
  const description = permissionDto?.description || "";

  if (variant === "inline") {
    return (
      <Typography component='span' sx={sx}>
        {withIcon && (
          <span>
            <AppIcon of='permission' color='secondary' />{" "}
          </span>
        )}
        <InlineCode>{title}</InlineCode>
      </Typography>
    );
  } else if (variant === "block") {
    return (
      <Stack direction='row' spacing={1} alignItems='center' sx={sx}>
        {withIcon && (
          <Box>
            <Typography component='div'>
              <AppIcon of='permission' color='secondary' />
            </Typography>
          </Box>
        )}
        <Stack>
          <Box>
            <Typography component='span'>{title}</Typography>
            <Typography component='span' sx={{ ml: 1 }} variant='body2'>
              <InlineCode>{title2}</InlineCode>
            </Typography>
          </Box>
          {description && (
            <Box>
              <Typography component='div' variant='body2' color='text.secondary'>
                {description}
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>
    );
  } else {
    return null;
  }
}

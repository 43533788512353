import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import {
  PaginationDtoOfVehiclePartTypeDto,
  VehicleArea,
  VehiclePartTypeDto,
  VehiclePartTypeSearchPaginatedDto,
  VehicleProjection,
} from "@/core/api/generated";

const generalGroupName = "General";
const isProVehiclePartTypeGroup = (groupName: string) => groupName !== generalGroupName;

export type VehiclePartTypeAutocompleteOption = BaseAutocompleteOption<VehiclePartTypeDto>;

const vehiclePartTypeToOption = (data: VehiclePartTypeDto): VehiclePartTypeAutocompleteOption => ({
  id: data.id!,
  title: StringHelper.joinIntoString([data.name], ", ", {
    skipEmpty: true,
  }),
  optionType: AutocompleteOptionType.Normal,
  groupBy: data.isCategoryRoot ? generalGroupName : data.category,
  data: data,
});
const vehiclePartTypesToOptions = (
  data?: VehiclePartTypeDto[] | null,
): VehiclePartTypeAutocompleteOption[] =>
  (data && data.map((x) => vehiclePartTypeToOption(x))) || [];

const sortOptions = (
  options: VehiclePartTypeAutocompleteOption[],
): VehiclePartTypeAutocompleteOption[] => {
  return _.chain(options)
    .groupBy((x) => x.groupBy)
    .mapValues((v, k) => _.orderBy(v, (x) => x.data?.sortOrder, "asc"))
    .values()
    .flatten()
    .value();
};

export interface VehiclePartTypeAutocompleteProps {
  vehiclePartTypeId?: string | null;
  vehiclePartType?: VehiclePartTypeDto;
  /** Used for better display of results */
  metadata?: {
    area?: VehicleArea;
    projection?: VehicleProjection;
  };
  searchFilters?: Pick<
    VehiclePartTypeSearchPaginatedDto,
    | "category"
    | "type"
    | "types"
    | "descriptor"
    | "descriptors"
    | "area"
    | "projection"
    | "isEnabledForDamageDetection"
  >;
  isPreload?: boolean;
  disabled?: boolean;
  required?: boolean;
  size?: "small" | "medium";
  onChange?: (newVehiclePartType?: VehiclePartTypeDto | null) => void;
  onRemoveAdditionalPartTypesFilteringInfo?: () => void;
}

function VehiclePartTypeAutocomplete({
  vehiclePartTypeId,
  isPreload = true,
  vehiclePartType,
  metadata,
  searchFilters,
  disabled,
  required,
  size,
  onChange,
  onRemoveAdditionalPartTypesFilteringInfo = () => {},
}: VehiclePartTypeAutocompleteProps) {
  const [open, setOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchedPartTypes, setSearchedVehiclePartTypes] = useState<
    PaginationDtoOfVehiclePartTypeDto | undefined
  >(undefined);
  const [options, setOptions] = useState<readonly VehiclePartTypeAutocompleteOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<VehiclePartTypeAutocompleteOption | null>(
    null,
  );
  const [limit, setLimit] = useState(50);
  const [inputValue, setInputValue] = useState("");

  const apiParams: Parameters<
    typeof apiClient.vehiclePartTypesApi.apiV1ReferenceDataVehiclesPartTypesSearchPost
  > = [
    {
      vehiclePartTypeSearchPaginatedDto: {
        limit,
        search: inputValue,
        category: !inputValue ? searchFilters?.category : undefined,
        type: !inputValue ? searchFilters?.type : undefined,
        types: !inputValue ? searchFilters?.types : undefined,
        descriptor: !inputValue ? searchFilters?.descriptor : undefined,
        descriptors: !inputValue ? searchFilters?.descriptors : undefined,
        area: !inputValue ? searchFilters?.area : undefined,
        projection: !inputValue ? searchFilters?.projection : undefined,
        isEnabledForDamageDetection: searchFilters?.isEnabledForDamageDetection,
        isCategoryRoot: undefined,
        isTypeRoot: undefined,
      },
    },
  ];

  const searchVehiclePartTypes = useCallback(
    async (
      ...arg: Parameters<
        typeof apiClient.vehiclePartTypesApi.apiV1ReferenceDataVehiclesPartTypesSearchPost
      >
    ) => {
      setIsSearching(true);
      try {
        const response =
          await apiClient.vehiclePartTypesApi.apiV1ReferenceDataVehiclesPartTypesSearchPost(...arg);
        setSearchedVehiclePartTypes(response.data);
      } finally {
        setIsSearching(false);
      }
    },
    [],
  );
  const searchVehiclePartTypesThrottle = useCallback(
    _.debounce(searchVehiclePartTypes, 500, { leading: true, trailing: false }),
    [searchVehiclePartTypes],
  );
  const searchVehiclePartTypesDebounce = useCallback(
    _.debounce(searchVehiclePartTypes, 500, { leading: false, trailing: true }),
    [searchVehiclePartTypes],
  );

  // initial load
  useEffect(() => {
    if (isPreload && !searchedPartTypes) {
      searchVehiclePartTypesThrottle(...apiParams);
    }
  }, []);

  useEffect(() => {
    if (isPreload) {
      searchVehiclePartTypesThrottle(...apiParams);
    }
  }, [limit, searchFilters]);

  useEffect(() => {
    if (!isPreload && open) {
      searchVehiclePartTypesThrottle(...apiParams);
    }
  }, [open, limit, searchFilters]);

  useEffect(() => {
    if (vehiclePartTypeId && !options.some((x) => x.id === vehiclePartTypeId)) {
      searchVehiclePartTypesThrottle(...apiParams);
    }
  }, [vehiclePartTypeId, options]);

  useEffect(() => {
    if (selectedOption) {
      return;
    }

    searchVehiclePartTypesDebounce(...apiParams);
  }, [inputValue, selectedOption]);

  useEffect(() => {
    if (searchedPartTypes?.pagination?.totalCount === 0) {
      onRemoveAdditionalPartTypesFilteringInfo();
    }
  }, [searchedPartTypes]);

  useEffect(() => {
    const newOptions = [
      ...(selectedOption ? [selectedOption] : []),
      ...vehiclePartTypesToOptions(searchedPartTypes?.items || []),
    ];
    setOptions(sortOptions(_.uniqBy(newOptions, (x) => x.id)));

    if (vehiclePartTypeId && selectedOption?.id !== vehiclePartTypeId) {
      setSelectedOption(newOptions.find((x) => x.id === vehiclePartTypeId) || null);
    }
  }, [searchedPartTypes, vehiclePartTypeId, selectedOption]);

  useEffect(() => {
    if (!vehiclePartType && !vehiclePartTypeId) {
      setSelectedOption(null);
    } else if (vehiclePartType && selectedOption?.id !== vehiclePartType.id) {
      setOptions(
        sortOptions(
          vehiclePartTypesToOptions(_.uniqBy([vehiclePartType, ...options], (x) => x.id)),
        ),
      );
      setSelectedOption(vehiclePartTypeToOption(vehiclePartType));
    } else if (vehiclePartTypeId && selectedOption?.id !== vehiclePartTypeId) {
      setSelectedOption(options.find((x) => x.id === vehiclePartTypeId) || null);
    }
  }, [vehiclePartTypeId, vehiclePartType, selectedOption, options]);

  return (
    <Autocomplete
      sx={{ minWidth: 200 }}
      disabled={disabled}
      size={size}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.title}
      filterOptions={(x) => x} // disable the built-in filtering
      groupBy={(option) => option.groupBy || ""}
      options={options}
      loading={isSearching}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={selectedOption}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        setSelectedOption(newValue);
        onChange && onChange(newValue?.data);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Vehicle part type'
          placeholder='Search...'
          fullWidth
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isSearching ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderGroup={(params) => (
        <ListItem key={params.key} disablePadding>
          <Stack sx={{ width: "100%" }}>
            <ListSubheader component='div' sx={{ position: "sticky", top: "-8px" }}>
              <span>{params.group}</span>
              {isProVehiclePartTypeGroup(params.group) && (
                <Chip size='small' color='primary' variant='outlined' label='Pro' sx={{ ml: 1 }} />
              )}
            </ListSubheader>
            <List disablePadding>{params.children}</List>
          </Stack>
        </ListItem>
      )}
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              <AppIcon of='widgets' />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant='body1'>{option.title}</Typography>}
              secondary={
                <>
                  <Typography variant='body2' color='secondary'>
                    {option.data?.description}
                  </Typography>
                  <Box>
                    {option.data?.areas?.map((area, i) => (
                      <Chip
                        key={i}
                        size='small'
                        color='secondary'
                        variant='outlined'
                        label={<InlineApiEnumValue type='VehicleArea' value={area} />}
                        sx={{ mr: 1 }}
                      />
                    ))}

                    {metadata?.projection && metadata.projection !== VehicleProjection.None && (
                      <Chip
                        size='small'
                        color='secondary'
                        variant='outlined'
                        label={
                          <InlineApiEnumValue
                            type='VehicleProjection'
                            value={metadata.projection}
                            sx={{ fontWeight: "bold" }}
                          />
                        }
                        sx={{ mr: 1 }}
                      />
                    )}
                  </Box>
                </>
              }
            />
          </ListItem>
        );
      }}
    />
  );
}

export default VehiclePartTypeAutocomplete;

/** Most of values synced with BE ValidationConstants.cs */
export const VALIDATION_CONSTANTS = {
  PriceMinValue: 0,
  PriceMaxValue: 10_000_000,
  PriceMaxLength: 8,

  DiscountMinValueForPercent: 0,
  DiscountMaxValueForPercent: 1, // [0;1] range
  DiscountMaxLengthForPercent: 3,
  DiscountMinValueForValue: 0,
  DiscountMaxValueForValue: 10_000_000,
  DiscountMaxLengthForValue: 8,

  TaxMinValueForPercent: 0,
  TaxMaxValueForPercent: 1, // [0;1] range
  TaxMaxLengthForPercent: 3,
  TaxMinValueForValue: 0,
  TaxMaxValueForValue: 10_000_000,
  TaxMaxLengthForValue: 8,
};

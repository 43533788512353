import InlineCode from "@/common/components/Code/InlineCode";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

export default function DevSwitches() {
  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Switches
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>MUI Switches</InlineCode>
      </Typography>
      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={1}>
          <Switch defaultChecked />
          <Switch />
          <Switch disabled defaultChecked />
          <Switch disabled />
        </Stack>
        <Stack direction='row' spacing={1}>
          <FormGroup>
            <FormControlLabel control={<Switch defaultChecked />} label='Label' />
            <FormControlLabel required control={<Switch />} label='Required' />
            <FormControlLabel disabled control={<Switch />} label='Disabled' />
          </FormGroup>
        </Stack>
        <Stack direction='row' spacing={1}>
          {" "}
          <Switch defaultChecked size='small' />
          <Switch defaultChecked />
        </Stack>
        <Stack direction='row' spacing={1}>
          {" "}
          <FormControl>
            <Switch defaultChecked />
            <FormHelperText>basic</FormHelperText>
          </FormControl>
          <FormControl>
            <Switch defaultChecked color='secondary' />
            <FormHelperText>secondary</FormHelperText>
          </FormControl>
          <FormControl>
            <Switch defaultChecked color='warning' />
            <FormHelperText>warning</FormHelperText>
          </FormControl>
          <FormControl>
            <Switch defaultChecked color='default' />
            <FormHelperText>default</FormHelperText>
          </FormControl>
        </Stack>
      </Stack>
    </Box>
  );
}

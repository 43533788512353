import { useHistory, useParams } from "react-router";

import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import PoolItemCreateUpdate from "@/common/components/Entity/PoolItem/PoolItemCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function PoolItemCreateUpdatePage() {
  const { poolId, poolItemId } = useParams<{ poolId: string; poolItemId?: string }>();
  const history = useHistory();

  const isCreate = !poolItemId;

  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={isCreate ? "Create new pool item" : "Edit pool item"} />}
    >
      <PoolItemCreateUpdate
        poolItemId={poolItemId}
        defaultValues={{
          poolId: poolId,
        }}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.POOL_ITEM_VIEW(newValue.poolId, newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}

import { useAuthenticationInfo } from "@/common/hooks/auth/useAuthenticationInfo";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission } from "@/core/api/generated";
import { MenuItemProps, MenuItemTypeMap } from "@mui/material";
import AppMenuItem from "../Menu/AppMenuItem";

type AuthorizedMenuItemProps<
  RootComponent extends React.ElementType = MenuItemTypeMap["defaultComponent"],
  // eslint-disable-next-line @typescript-eslint/ban-types
  AdditionalProps = {},
> = {
  /** List of permissions user must have (all) */
  permissions?: AppPermission[];
  /** List of permissions user must have (at least one from the list) */
  permissionsAny?: AppPermission[];
} & MenuItemProps<RootComponent, AdditionalProps>;

export default function AuthorizedMenuItem<
  RootComponent extends React.ElementType = MenuItemTypeMap["defaultComponent"],
  // eslint-disable-next-line @typescript-eslint/ban-types
  AdditionalProps = {},
>({
  permissions,
  permissionsAny,
  ...otherProps
}: AuthorizedMenuItemProps<RootComponent, AdditionalProps>) {
  const { isAuthenticated } = useAuthenticationInfo();
  const { isPermissionsReady, userPermissions } = useAuthorizationInfo();

  if (!isPermissionsReady || !userPermissions) {
    return null;
  }
  if (!permissions && !permissionsAny) {
    return null;
  }
  if (isAuthenticated === undefined || isAuthenticated === false) {
    return null;
  }

  const hasPermissionsRequired = (permissions || []).every((x) => userPermissions.includes(x));
  const hasPermissionsAny = permissionsAny
    ? permissionsAny.some((x) => userPermissions.includes(x))
    : true;
  const hasPermissions = hasPermissionsRequired && hasPermissionsAny;
  if (!hasPermissions) {
    return null;
  }

  return <AppMenuItem {...otherProps} />;
}

import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import {
  AssetDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  PoolGetPaginatedDto,
  PoolItemEntityType,
} from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import PoolPaginatedList from "@/common/components/Entity/Pool/ListView/PoolPaginatedList";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";

interface Props {
  asset: AssetDto;
}

export default function ItemsTabContent({ asset }: Props) {
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<PoolGetPaginatedDto>({});

  const paginatedPoolsRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsForEntityGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      offset: commonRequestParams.offset,
      limit: commonRequestParams.limit,
      entityType: PoolItemEntityType.Asset,
      entityId: asset.id!,
    },
    {
      deps: [asset.id],
    },
  );
  const paginatedPools = paginatedPoolsRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Pool)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedPoolsRequest.handleEntityChanged(data);
    },
  });
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={<PageTabHeader title={"Pools"} />}
      >
        <Stack direction='column' spacing={2}>
          <PoolPaginatedList
            shouldFetch={false}
            paginated={paginatedPools}
            displayProps={{
              breadcrumbs: false,
              filters: false,
              create: false,
              edit: true,
              delete: false,
              viewVariant: ViewLayoutVariant.Tab,
            }}
            dataTabularProps={{
              isLoading: paginatedPoolsRequest.isLoading,
              pagination: commonRequestParams.dataTabularProps.pagination,
            }}
          />
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

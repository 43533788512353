import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TypographyProps,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";

import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ValidationHelper } from "@/common/validation";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  TenantConnectionDto,
  TenantConnectionStatus,
} from "@/core/api/generated";
import { enqueueSnackbar } from "notistack";
import ChatActivator from "../../Chat/ChatActivator";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import TenantConnectionRequestCreateUpdateModal from "../../TenantConnectionRequest/TenantConnectionRequestCreateUpdateModal";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";

export enum TenantConnectionViewTabs {
  Overview = "Overview",
  GeneralHistory = "GeneralHistory",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<TenantConnectionDto> {
  tenantConnectionId: string | null | undefined;
  tenantConnection: TenantConnectionDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
  onSave?: (newValue: TenantConnectionDto) => void;
}

export type TenantConnectionViewProps = OwnProps;

export default function TenantConnectionView({
  tenantConnectionId,
  tenantConnection,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
  onSave,
}: TenantConnectionViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();

  const [isEndModalOpen, setIsEndModalOpen] = useState(false);
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [isCreateRequestModalOpen, setIsCreateRequestModalOpen] = useState(false);

  const tenantConnectionRequest = useApiRequest(
    apiClient.tenantConnectionsApi.apiV1TenantToTenantTenantConnectionsTenantConnectionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantConnectionId: tenantConnectionId!,
    },
    {
      deps: [tenantConnectionId],
      skip: !tenantConnectionId || !!tenantConnection,
    },
  );
  tenantConnection = tenantConnectionRequest?.data || tenantConnection;

  const tenants = [tenantConnection?.connectedTenant1, tenantConnection?.connectedTenant2];
  const myTenant = tenants?.find((x) => x?.id === currentTenant?.id);
  const otherTenant = tenants?.find((x) => x?.id !== currentTenant?.id);

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(null, EntityType.TenantConnection, tenantConnection?.id || ""),
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.TenantConnection,
        tenantConnection?.id || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      tenantConnectionRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        tenantConnection && {
          idValue: tenantConnection.id!,
          newTitle: tenantConnection.globalNumber || "",
        }) ||
      undefined,
  });

  const handleEndConfirmed = useCallback(async () => {
    if (!tenantConnection) {
      return;
    }
    try {
      const response =
        await apiClient.tenantConnectionsApi.apiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            tenantConnectionId: tenantConnection.id!,
          },
        );
      enqueueSnackbar("Connection was successfully ended.", { variant: "success" });
      setIsEndModalOpen(false);
      tenantConnectionRequest.replaceData(response.data);
      onSave && onSave(response.data);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [tenantConnection]);

  return (
    <BaseEntityView
      entityType={EntityType.TenantConnection}
      entityId={tenantConnectionId}
      entity={tenantConnection}
      entityRequest={tenantConnectionRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`Company connection request ${tenantConnection?.globalNumber}`}
              titleProps={{
                to: headerProps?.withLink
                  ? ROUTE_PATH.TENANT_CONNECTION_VIEW(tenantConnection?.id)
                  : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                <Stack direction='row' spacing={1}>
                  {/* Chat */}
                  {tenantConnection?.chat && (
                    <ChatActivator
                      chatType={tenantConnection.chat.type!}
                      chatId={tenantConnection.chat.id}
                      chatPlacement='popover'
                      to={undefined}
                      withActivityIndicator
                      tooltipTitle='Open chat'
                      activatorButtonProps={{
                        disableRipple: true,
                      }}
                    />
                  )}
                </Stack>
              }
              secondaryActions={
                tenantConnection &&
                displayProps.actions && (
                  <MenuWithTrigger
                    withAuthCloseOnClick
                    trigger={
                      <IconButton sx={{ ml: "auto" }}>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                  >
                    {tenantConnection.status === TenantConnectionStatus.Active && (
                      <AuthorizedElement permissions={[AppPermission.TenantConnectionManage]}>
                        <MenuItem
                          onClick={() => {
                            setIsEndModalOpen(true);
                          }}
                        >
                          <ListItemIcon>
                            <AppIcon of='revoke' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>End connection</ListItemText>
                        </MenuItem>
                      </AuthorizedElement>
                    )}

                    {tenantConnection.status === TenantConnectionStatus.Ended && (
                      <AuthorizedElement permissions={[AppPermission.TenantConnectionManage]}>
                        <MenuItem
                          onClick={() => {
                            setIsActivateModalOpen(true);
                          }}
                        >
                          <ListItemIcon>
                            <AppIcon of='activate' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>Activate connection</ListItemText>
                        </MenuItem>
                      </AuthorizedElement>
                    )}
                  </MenuWithTrigger>
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <PageTabs
            tabIdsDefinition={TenantConnectionViewTabs}
            defaultTabId={TenantConnectionViewTabs.Overview}
            tabs={[
              { label: "Overview", value: TenantConnectionViewTabs.Overview },
              { label: "History", value: TenantConnectionViewTabs.GeneralHistory },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              tenantConnection && (
                <>
                  {activeTab === TenantConnectionViewTabs.Overview && (
                    <OverviewTabContent tenantConnection={tenantConnection} />
                  )}
                  {activeTab === TenantConnectionViewTabs.GeneralHistory && (
                    <GeneralHistoryTabContent tenantConnection={tenantConnection} />
                  )}
                </>
              )
            }
          </PageTabs>

          {/* End */}
          {tenantConnection && (
            <ConfirmationModal
              title='End the company connection?'
              body={
                <Stack spacing={1}>
                  <Box>
                    {`You're going to end the company connection `}
                    <strong>{tenantConnection.globalNumber}</strong>.
                    {` This action can't be undone.`}
                  </Box>

                  <Box>{`You can establish connection with the company again by creating a new connection request.`}</Box>
                </Stack>
              }
              open={isEndModalOpen}
              onClose={() => setIsEndModalOpen(false)}
              onCancel={() => setIsEndModalOpen(false)}
              onConfirm={handleEndConfirmed}
            />
          )}

          {/* Activate */}
          {tenantConnection && (
            <ConfirmationModal
              title='Activate the company connection?'
              body={
                <Stack spacing={1}>
                  <Box>
                    {`You're going to activate the company connection request `}
                    <strong>{tenantConnection.globalNumber}</strong>.
                    {` You will need to create a new connection request.`}
                  </Box>
                </Stack>
              }
              open={isActivateModalOpen}
              onClose={() => setIsActivateModalOpen(false)}
              onCancel={() => setIsActivateModalOpen(false)}
              onConfirm={() => {
                setIsCreateRequestModalOpen(true);
              }}
            />
          )}

          {/* Create request modal */}
          {tenantConnection && (
            <TenantConnectionRequestCreateUpdateModal
              open={isCreateRequestModalOpen}
              onClose={() => setIsCreateRequestModalOpen(false)}
              createUpdateProps={{
                defaultValues: {
                  receiverTenantId: otherTenant?.id,
                },
                onSave: (newValue) => {
                  setIsCreateRequestModalOpen(false);
                  history.push(ROUTE_PATH.TENANT_CONNECTION_REQUEST_VIEW(newValue.id));
                },
              }}
            />
          )}
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}

import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { RepairOperationDto } from "@/core/api/generated";

import RepairOperationCreateUpdate, {
  RepairOperationCreateUpdateProps,
} from "./RepairOperationCreateUpdate";
import RepairOperationCreateUpdateModal from "./RepairOperationCreateUpdateModal";
import RepairOperationAutocomplete, {
  RepairOperationAutocompleteProps,
} from "./RepairOperationAutocomplete";

interface OwnProps {
  autocompleteProps: RepairOperationAutocompleteProps;
  createUpdateProps?: Pick<RepairOperationCreateUpdateProps, "defaultValues">;
  createFormPlacement?: "modal" | "page";
  onCreateStart?: () => void;
  onCreate: (newValue: RepairOperationDto) => void;
}

type Props = OwnProps;

export default function SelectOrCreateRepairOperation({
  autocompleteProps,
  createUpdateProps,
  createFormPlacement = "modal",
  onCreateStart,
  onCreate,
}: Props) {
  const [isCreate, setIsCreate] = useState(false);
  const [defaultValues, setDefaultValues] = useState<
    RepairOperationCreateUpdateProps["defaultValues"] | undefined
  >(undefined);

  return (
    <Stack spacing={2}>
      {/* 1. Search repairOperation */}
      {!isCreate && (
        <Box>
          <RepairOperationAutocomplete
            {...autocompleteProps}
            withCreate
            onCreate={(newOption) => {
              setDefaultValues({
                ...createUpdateProps?.defaultValues,
                name: newOption?.inputValue || undefined,
              });
              setIsCreate(true);
              onCreateStart && onCreateStart();
            }}
          />
        </Box>
      )}

      {isCreate && (
        <Box>
          {isCreate && (
            <>
              {createFormPlacement === "modal" && (
                <RepairOperationCreateUpdateModal
                  open={isCreate}
                  onClose={() => setIsCreate(false)}
                  createUpdateProps={{
                    defaultValues: defaultValues,
                    onCreate: onCreate,
                    onSave: (newValue) => {
                      setIsCreate(false);
                    },
                  }}
                />
              )}

              {createFormPlacement === "page" && (
                <Stack spacing={1}>
                  <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                    <Button
                      variant='text'
                      color='secondary'
                      size='small'
                      startIcon={<AppIcon of='goBack' />}
                      onClick={() => setIsCreate(false)}
                    >
                      Back to search
                    </Button>

                    <Typography component='div' variant='subtitle1'>
                      Create new repair operation
                    </Typography>
                  </Stack>

                  <RepairOperationCreateUpdate
                    defaultValues={defaultValues}
                    onCreate={onCreate}
                    onSave={(newValue) => {
                      setIsCreate(false);
                    }}
                  />
                </Stack>
              )}
            </>
          )}
        </Box>
      )}
    </Stack>
  );
}

import { Box, Button, Grid, GridProps, SxProps, Theme } from "@mui/material";
import _ from "lodash";
import { ReactNode, useMemo, useState } from "react";

import AppIcon from "../../../Icons/AppIcon";
import { TextHelper } from "@/common/helpers/text";

export interface TagListProps {
  tags: ReactNode[];
  direction?: GridProps["direction"];
  maxVisibleItems?: number;
  startAction?: ReactNode;
  endAction?: ReactNode;
  sx?: SxProps<Theme>;
}

export default function TagList({
  tags,
  direction = "row",
  maxVisibleItems,
  startAction,
  endAction,
  sx,
}: TagListProps) {
  const [isAllVisible, setIsAllVisible] = useState(false);

  const visibleTags = useMemo(
    () => (_.isNil(maxVisibleItems) ? tags : tags.slice(0, maxVisibleItems)),
    [tags, maxVisibleItems],
  );
  const invisibleTags = useMemo(
    () => (_.isNil(maxVisibleItems) ? [] : tags.slice(maxVisibleItems, tags.length)),
    [tags, maxVisibleItems],
  );

  return (
    <Box sx={sx}>
      <Grid container columnSpacing={0.5} rowSpacing={0.5} direction={direction}>
        {startAction && (
          <Grid item xxs='auto'>
            {startAction}
          </Grid>
        )}

        {visibleTags?.map((tag, i) => (
          <Grid key={i} item xxs='auto'>
            {tag}
          </Grid>
        ))}

        {isAllVisible &&
          invisibleTags?.map((tag, i) => (
            <Grid key={i} item xxs='auto'>
              {tag}
            </Grid>
          ))}

        {invisibleTags && invisibleTags.length > 0 && (
          <Grid item xxs='auto'>
            <Button
              sx={{ p: 0 }}
              variant='text'
              size='small'
              color='secondary'
              onClick={() => setIsAllVisible(!isAllVisible)}
            >
              {isAllVisible ? "-" : "+"}
              {invisibleTags?.length}{" "}
              {TextHelper.pluralizeManual("tag", invisibleTags?.length || 0, "tags")}{" "}
              <AppIcon of={isAllVisible ? "expandLess" : "expandMore"} />
            </Button>
          </Grid>
        )}

        {endAction && (
          <Grid item xxs='auto'>
            {endAction}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

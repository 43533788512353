import { LoadingButton } from "@mui/lab";
import { FormControl, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import PermissionsSelector from "@/common/components/Permission/PermissionsSelector";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppDispatch, useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { CreateTenantRoleDto, RoleDto, UpdateTenantRoleDto } from "@/core/api/generated";
import * as tenantAccountSlice from "@/store/management/tenantAccount/slice";

function CreateNewRole() {
  const { roleId } = useParams<{ roleId?: string }>();
  const mounted = useMounted();
  const isCreate = !roleId;
  const { enqueueSnackbar } = useAppSnackbar();
  const thunkDispatch = useAppThunkDispatch();
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    return () => {
      dispatch(tenantAccountSlice.resetTenantRole());
    };
  }, []);

  const getTenantRoleRequest = useApiRequest(
    apiClient.tenantAccountApi.apiV1TenantAccountRolesRoleIdGet,
    { nexusOpsTenant: EMPTY_TENANT_IDENTIFIER, roleId: roleId! },
  );
  const role = getTenantRoleRequest.data;

  return (
    <Formik<BaseFormikValues & CreateTenantRoleDto & UpdateTenantRoleDto>
      enableReinitialize={!isCreate}
      initialValues={{
        name: role?.name || "",
        description: role?.description || "",
        permissions: role?.permissions || undefined,
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // name: Yup.string().required("Name is required"),
        // description: Yup.string().required("Description is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          let result: RoleDto;
          if (isCreate) {
            result = await thunkDispatch(
              tenantAccountSlice.createTenantRole({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                createTenantRoleDto: {
                  name: values.name,
                  description: values.description,
                  permissions: values.permissions,
                },
              }),
            );
            enqueueSnackbar("Role created.", { variant: "success" });
          } else {
            result = await thunkDispatch(
              tenantAccountSlice.updateTenantRole({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                roleId,
                updateTenantRoleDto: {
                  name: values.name,
                  description: values.description,
                  permissions: values.permissions,
                },
              }),
            );
            enqueueSnackbar("Role updated.", { variant: "success" });
          }

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
          history.replace(ROUTE_PATH.TENANT_ROLE_VIEW(result.id));
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <CreateUpdatePageLayout
            header={<SimpleViewPageHeader title={isCreate ? " Create new role" : "Edit role"} />}
          >
            <form noValidate onSubmit={handleSubmit}>
              <Box>
                <TextField
                  autoFocus
                  autoComplete='off'
                  required
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='dense'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name}
                  variant='outlined'
                />

                <TextField
                  autoComplete='off'
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  label='Description'
                  margin='dense'
                  name='description'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.description}
                  variant='outlined'
                />

                <FormControl fullWidth margin='dense'>
                  <Paper sx={{ p: 1 }}>
                    <Typography component='div' variant='h6' sx={{ mb: 1 }}>
                      Permissions
                    </Typography>

                    <PermissionsSelector
                      getPermissions={async () => {
                        const response =
                          await apiClient.tenantAccountApi.apiV1TenantAccountPermissionsGet({
                            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                          });
                        return response.data;
                      }}
                      permissions={values.permissions}
                      onChange={(newValue) => {
                        setFieldValue("permissions", newValue);
                      }}
                    />
                  </Paper>
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                disabled={!values.name}
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </form>
          </CreateUpdatePageLayout>
        );
      }}
    </Formik>
  );
}

export default CreateNewRole;

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { Dialog, DialogContent, DialogProps, Stack } from "@mui/material";
import ExportDataForm, { ExportDataProps } from "./DataExportForm";

export interface OwnProps {
  exportDataProps: ExportDataProps;
}

type Props = OwnProps & DialogProps;

export default function DataExportModal({ exportDataProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Data export
      </AppModalTitle>
      <DialogContent>
        <Stack sx={{ py: 1 }} spacing={1}>
          <ExportDataForm
            {...exportDataProps}
            onExportDone={() => dialogProps.onClose && dialogProps.onClose({}, "escapeKeyDown")}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

import { CustomFormikSubProps } from "@/common/helpers/formik";
import { ValidationHelper } from "@/common/validation";
import {
  GeneralCountryInputDto,
  GeneralCurrencyInputDto,
  VehicleTaxInputDto,
  VehicleTaxType,
} from "@/core/api/generated";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { getIn } from "formik";

import ApiEnumAutocomplete from "@/common/components/Enum/ApiEnumAutocomplete";
import ApiEnumsAutocomplete from "@/common/components/Enum/ApiEnumsAutocomplete";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import { renderIf } from "@/common/helpers/render/renderIf";
import GeneralPriceInput from "../../General/GeneralPrice/GeneralPriceInput";
import GeneralTaxInput from "../../General/GeneralTax/GeneralTaxInput";
import CountriesAutocomplete from "../../ReferenceData/CountriesAutocomplete";
import { enumService } from "@/common/services/enum";

interface Props {
  formikProps: CustomFormikSubProps<VehicleTaxInputDto | null | undefined>;
  defaultCountry?: GeneralCountryInputDto | null;
  defaultCurrency?: GeneralCurrencyInputDto | null;
  sx?: SxProps<Theme>;
}

export default function VehicleTaxInput({
  formikProps,
  defaultCountry,
  defaultCurrency,
  sx,
}: Props) {
  const { values, touched, errors, setFieldValue, setFieldError } = formikProps;

  return (
    <Stack spacing={2} sx={sx}>
      <Stack spacing={2}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xxs: "1fr",
              lg: "1fr 10fr",
            },
            gap: 1,
          }}
        >
          <FormControl margin='none'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.isEnabled}
                  onChange={(e) => setFieldValue(`isEnabled`, e.target.checked || false)}
                />
              }
              label={"Enabled"}
            />
          </FormControl>

          <FormControl margin='none'>
            <ApiEnumAutocomplete
              type='VehicleTaxType'
              value={values?.type}
              onlyEnumValues={[VehicleTaxType.FixedFreeValueForRental]} // for now this is the only type required
              onChange={(newValue) => {
                setFieldValue(`type`, newValue);
              }}
              label='Vehicle tax type'
              textFieldProps={{
                error: Boolean(getIn(errors, `type`)),
                helperText: ValidationHelper.getErrorsAsString(getIn(errors, `type`)),
              }}
            />
          </FormControl>
        </Box>

        <Box>
          <Typography variant='subtitle1'>Conditions</Typography>
          <FormHelperText>Specify conditions on which the tax is applied.</FormHelperText>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xxs: "1fr",
              lg: "repeat(3, 1fr)",
            },
            gap: 1,
          }}
        >
          <FormControl margin='none'>
            <CountriesAutocomplete
              entities={values?.countries || undefined}
              entityIds={undefined}
              onChange={(newValues) => {
                setFieldValue(`countries`, newValues);
              }}
              textFieldProps={{
                error: Boolean(getIn(errors, `countries`)),
                helperText: ValidationHelper.getErrorsAsString(getIn(errors, `countries`)),
              }}
            />
            <FormHelperText>Leave empty to match all.</FormHelperText>
          </FormControl>

          <FormControl margin='none'>
            <ApiEnumsAutocomplete
              type='TaxType'
              values={values?.taxTypes}
              onChange={(newValues) => {
                setFieldValue(`taxTypes`, newValues);
              }}
              label='Tax types'
              textFieldProps={{
                error: Boolean(getIn(errors, `taxTypes`)),
                helperText: ValidationHelper.getErrorsAsString(getIn(errors, `taxTypes`)),
              }}
            />
            <FormHelperText>Leave empty to match all.</FormHelperText>
          </FormControl>

          <FormControl margin='none'>
            <ApiEnumsAutocomplete
              type='VehicleFuelType'
              values={values?.fuelTypes}
              onChange={(newValues) => {
                setFieldValue(`fuelTypes`, newValues);
              }}
              label='Fuel types'
              textFieldProps={{
                error: Boolean(getIn(errors, `fuelTypes`)),
                helperText: ValidationHelper.getErrorsAsString(getIn(errors, `fuelTypes`)),
              }}
            />
            <FormHelperText>Leave empty to match all.</FormHelperText>
          </FormControl>
        </Box>

        {values?.type && (
          <>
            <Typography variant='subtitle1'>
              Settings for {`"${enumService.getEnumValueName("VehicleTaxType", values?.type)}"`}
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: 1,
              }}
            >
              {renderIf()
                .if(values?.type === VehicleTaxType.ReducedForPurchase)
                .then(
                  <FormControl margin='none'>
                    <GeneralTaxInput
                      value={values?.reducedForPurchase?.reducedTax || undefined}
                      appliesTo={undefined}
                      onChange={(newValue) => {
                        setFieldValue(`reducedForPurchase.reducedTax`, newValue);
                      }}
                      label='Reduced tax'
                      error={getIn(errors, `reducedForPurchase.reducedTax`)}
                      helperText={ValidationHelper.getErrorsAsString(
                        getIn(errors, `reducedForPurchase.reducedTax`),
                      )}
                    />
                    <FormHelperText error>
                      {ValidationHelper.getFormikErrorsAsString(
                        getIn(errors, `reducedForPurchase`),
                        { isIncludeNested: false },
                      )}
                    </FormHelperText>
                  </FormControl>,
                )
                .elseif(values?.type === VehicleTaxType.ReducedForRental)
                .then(
                  <FormControl margin='none'>
                    <GeneralTaxInput
                      value={values?.reducedForRental?.reducedTax || undefined}
                      appliesTo={undefined}
                      onChange={(newValue) => {
                        setFieldValue(`reducedForRental.reducedTax`, newValue);
                      }}
                      label='Reduced tax'
                      error={getIn(errors, `reducedForRental.reducedTax`)}
                      helperText={ValidationHelper.getErrorsAsString(
                        getIn(errors, `reducedForRental.reducedTax`),
                      )}
                    />
                    <FormHelperText error>
                      {ValidationHelper.getFormikErrorsAsString(getIn(errors, `reducedForRental`), {
                        isIncludeNested: false,
                      })}
                    </FormHelperText>
                  </FormControl>,
                )
                .elseif(values?.type === VehicleTaxType.FixedFreeValueForPurchase)
                .then(
                  <FormControl margin='none'>
                    <GeneralPriceInput
                      value={values?.fixedFreeValueForPurchase?.fixedFreeValue || undefined}
                      onChange={(e, newValue) => {
                        setFieldValue(`fixedFreeValueForPurchase.fixedFreeValue`, newValue);
                      }}
                      defaultCurrency={defaultCurrency}
                      label='Fixed tax free values'
                      error={getIn(errors, `fixedFreeValueForPurchase.fixedFreeValue`)}
                      helperText={ValidationHelper.getErrorsAsString(
                        getIn(errors, `fixedFreeValueForPurchase.fixedFreeValue`),
                      )}
                    />
                    <FormHelperText error>
                      {ValidationHelper.getFormikErrorsAsString(
                        getIn(errors, `fixedFreeValueForPurchase`),
                        { isIncludeNested: false },
                      )}
                    </FormHelperText>
                  </FormControl>,
                )
                .elseif(values?.type === VehicleTaxType.FixedFreeValueForRental)
                .then(
                  <FormControl margin='none'>
                    <GeneralPriceInput
                      value={values?.fixedFreeValueForRental?.fixedFreeValue || undefined}
                      onChange={(e, newValue) => {
                        setFieldValue(`fixedFreeValueForRental.fixedFreeValue`, newValue);
                      }}
                      defaultCurrency={defaultCurrency}
                      label='Fixed tax free values'
                      error={getIn(errors, `fixedFreeValueForRental.fixedFreeValue`)}
                      helperText={ValidationHelper.getErrorsAsString(
                        getIn(errors, `fixedFreeValueForRental.fixedFreeValue`),
                      )}
                    />
                    <FormHelperText error>
                      {ValidationHelper.getFormikErrorsAsString(
                        getIn(errors, `fixedFreeValueForRental`),
                        { isIncludeNested: false },
                      )}
                    </FormHelperText>
                  </FormControl>,
                )
                .render()}
            </Box>
          </>
        )}
      </Stack>
    </Stack>
  );
}

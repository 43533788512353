import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import TenantConnectionView from "@/common/components/Entity/TenantConnection/View/TenantConnectionView";

export default function TenantConnectionViewPage() {
  const { tenantConnectionId } = useParams<{ tenantConnectionId?: string }>();

  return (
    <ViewPageLayout>
      <TenantConnectionView
        tenantConnectionId={tenantConnectionId}
        tenantConnection={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </ViewPageLayout>
  );
}

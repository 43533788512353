import { apiClient } from "@/core/api/ApiClient";
import { DepartmentDto, EntityType } from "@/core/api/generated";
import BaseEntitiesDeleteModal, {
  BaseEntitiesDeleteModalInheritableProps,
} from "../components/BaseEntitiesDeleteModal";

export interface OwnProps {
  entities: DepartmentDto[];
}

type Props = OwnProps & BaseEntitiesDeleteModalInheritableProps;

export default function DepartmentsDeleteModal({ entities, ...otherProps }: Props) {
  return (
    <BaseEntitiesDeleteModal
      cascadeActionDisplay={true}
      entityType={EntityType.Department}
      entities={entities}
      deleteFunc={(params) =>
        apiClient.departmentsApi.apiV1DepartmentsBulkDeleteDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          entityBulkDeleteDto: {
            ids: params.entityIds,
            relatedEntitiesTypesToDelete: params.relatedEntitiesTypes,
          },
        })
      }
      {...otherProps}
    />
  );
}

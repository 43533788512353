import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import TenantConnectionRequestCreateUpdate, {
  TenantConnectionRequestCreateUpdateProps,
} from "./TenantConnectionRequestCreateUpdate";

export interface OwnProps {
  createUpdateProps: TenantConnectionRequestCreateUpdateProps;
}

type Props = OwnProps & DialogProps;

export default function TenantConnectionRequestCreateUpdateModal({
  createUpdateProps,
  ...dialogProps
}: Props) {
  const isCreate = !createUpdateProps.tenantConnectionRequestId;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} company connection request
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <TenantConnectionRequestCreateUpdate {...createUpdateProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { AdminTenantDto, AdminTenantGetPaginatedDto, TenantDto } from "@/core/api/generated";

export type TenantAutocompleteOption = BaseAutocompleteOption<TenantDto>;

export const getTenantOptionTitle = (data: AdminTenantDto): string =>
  StringHelper.joinIntoString([data.name], " ", {
    skipEmpty: true,
  });

export const tenantToOption = (data: AdminTenantDto): TenantAutocompleteOption => ({
  id: data.id!,
  title: `${data.name}`,
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface AdminTenantAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<TenantDto> {
  searchFilters?: Partial<Omit<AdminTenantGetPaginatedDto, "search" | "includeIds">>;
}

export default function AdminTenantAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: AdminTenantAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={tenantToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.adminTenantsApi.apiV1AdminTenantsGetPost,
        limit: 25,
        parameters: {
          adminTenantGetPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          adminTenantGetPaginatedDto: {
            ...params.adminTenantGetPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Tenant'
      placeholder='Search...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='tenant' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body2' color='secondary'>
                      <Box>{option.data?.identifier}</Box>
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

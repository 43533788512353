import _ from "lodash";
import { StatusDisplay, StatusDisplayProps } from "./StatusDisplay";

export interface BooleanStatusDisplayProps {
  value: boolean | null | undefined;
  statusProps?: StatusDisplayProps;
}

export const BooleanStatusDisplay = ({ value, statusProps }: BooleanStatusDisplayProps) => {
  return (
    <StatusDisplay
      {...statusProps}
      color={
        (_.isNil(value) ? (th) => th.palette.secondary.main : undefined) ??
        (value === true ? (th) => th.palette.success.main : undefined) ??
        (value === false ? (th) => th.palette.error.main : undefined)
      }
    />
  );
};

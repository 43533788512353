import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitiesSearchAutocomplete, {
  InheritableBaseEntitiesSearchAutocompleteProps,
} from "@/common/components/Entity/components/BaseEntitiesSearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { AutocompleteOptionType } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { VehicleVisualModelDto, VehicleVisualModelSearchPaginatedDto } from "@/core/api/generated";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import InlineApiEnumValueList from "../../Enum/InlineApiEnumValueList";
import { vehicleVisualModelToOption } from "./VehicleVisualModelAutocomplete";

export interface VehicleVisualModelsAutocompleteProps
  extends InheritableBaseEntitiesSearchAutocompleteProps<VehicleVisualModelDto> {
  searchFilters?: {
    vehicleVisualModelId: string;
  } & Partial<Omit<VehicleVisualModelSearchPaginatedDto, "search" | "includeIds">>;
}

export default function VehicleVisualModelsAutocomplete({
  isPreload = true,
  searchFilters,
  ...otherProps
}: VehicleVisualModelsAutocompleteProps) {
  return (
    <BaseEntitiesSearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={vehicleVisualModelToOption}
      isPreload={isPreload}
      request={{
        requestFunc:
          apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsSearchPost,
        limit: 25,
        parameters: {
          vehicleVisualModelSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          vehicleVisualModelSearchPaginatedDto: {
            ...params.vehicleVisualModelSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Vehicle visual model'
      placeholder='Search for vehicle visual model...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='vehicleVisualModel' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography component='div' variant='body1'>
                    {option.title}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body2' color='secondary'>
                      <Box>
                        <InlineApiEnumValue
                          type='VehicleVisualModelType'
                          value={option.data.type}
                        />
                      </Box>
                      <Box>
                        <InlineApiEnumValueList type='VehicleArea' values={option.data.areas} /> -{" "}
                        <InlineApiEnumValueList
                          type='VehicleProjection'
                          values={option.data.projections}
                        />
                      </Box>
                      <Box>
                        <InlineApiEnumValueList
                          type='VehicleType'
                          values={option.data.vehicleTypes}
                        />
                      </Box>
                      <Box>
                        <InlineApiEnumValueList
                          type='VehicleBodyType'
                          values={option.data.bodyTypes}
                        />
                      </Box>
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

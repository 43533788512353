import { Grid, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import Datetime from "@/common/components/Datetime/Datetime";
import EntityDataBlock, {
  EntityDataBlockProps,
} from "@/common/components/EntityData/EntityDataBlock";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, ContractDto, ContractReferenceDto } from "@/core/api/generated";
import AppTypography from "../../Text/AppTypography";

interface BaseProps extends Pick<EntityDataBlockProps, "withDetailsToggle" | "isDetailsVisible"> {
  withLink?: boolean;
  briefDataProps?: {
    vehicle?: boolean;
    customer?: boolean;
  };
  detailedDataProps?: {
    vehicle?: boolean;
    customer?: boolean;
  };
  sx?: SxProps<Theme>;
}

type Props =
  | ({ variant: "full"; contract?: ContractDto } & BaseProps)
  | ({ variant: "reference"; contract?: ContractReferenceDto } & BaseProps);

const defaultBriefDataProps: Props["briefDataProps"] = {
  vehicle: true,
  customer: true,
};
const defaultDetailedDataProps: Props["detailedDataProps"] = {
  vehicle: true,
  customer: true,
};

/** Contract info as block element. */
export default function ContractBlock({
  variant,
  contract,
  withLink,
  briefDataProps = defaultBriefDataProps,
  detailedDataProps = defaultDetailedDataProps,
  sx,
  ...entityDataBlockProps
}: Props) {
  briefDataProps = { ...defaultBriefDataProps, ...briefDataProps };
  detailedDataProps = {
    ...defaultDetailedDataProps,
    ...detailedDataProps,
  };

  const { hasPermissions } = useAuthorizationInfo();

  const [fetchedContract, setFetchedContract] = useState<ContractDto | null>(null);

  useEffect(() => {
    (async () => {
      if (!!contract && variant === "reference") {
        try {
          const response = await apiClient.contractsApi.apiV1ContractsContractIdGet({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            contractId: contract.id as string,
          });
          setFetchedContract(response.data);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [contract && contract.id]);

  if (!contract) {
    return null;
  }

  const fullDto = fetchedContract || (contract as ContractDto | undefined);

  return (
    <EntityDataBlock
      sx={{
        flex: 1,
        minWidth: 0,
        ...sx,
      }}
      {...entityDataBlockProps}
      to={
        withLink && hasPermissions([AppPermission.FleetAppAccess, AppPermission.ContractRead])
          ? ROUTE_PATH.CONTRACT_VIEW(contract.id)
          : undefined
      }
      title={
        <>
          <AppIcon of='contract' inText /> Contract
        </>
      }
      briefContent={
        <>
          <AppTypography ellipsing={{ enabled: true }} component='div' variant='body1'>
            {fullDto?.externalNumber ?? fullDto?.localNumber}{" "}
            <InlineApiEnumValue type='ContractType' value={contract.type} />
          </AppTypography>
          {fullDto?.startsAt && (
            <Typography component='div' variant='body2'>
              <Datetime datetime={fullDto.startsAt} withDurationFromNow />
            </Typography>
          )}
        </>
      }
      detailedContent={
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              {fullDto?.externalNumber && (
                <FieldValue label='External number' withCopyButton>
                  {fullDto.externalNumber}
                </FieldValue>
              )}
              <FieldValue label='Number' withCopyButton>
                {contract.localNumber}
              </FieldValue>
              {fullDto?.startsAt && (
                <FieldValue label='Starts at'>
                  <Datetime datetime={fullDto.startsAt} withDurationFromNow />
                </FieldValue>
              )}
              {fullDto?.endsAt && (
                <FieldValue label='Ends at'>
                  <Datetime datetime={fullDto.endsAt} withDurationFromNow />
                </FieldValue>
              )}
              <FieldValue label='Type'>
                <InlineApiEnumValue type='ContractType' value={contract.type} />
              </FieldValue>
              {fullDto?.stageHistory?.stage && (
                <FieldValue label='Stage'>
                  <InlineApiEnumValue type='ContractStage' value={fullDto?.stageHistory?.stage} />
                </FieldValue>
              )}
              {fullDto?.notes && (
                <FieldValue label='Notes'>
                  <AppTypography ellipsing={{ enabled: true }} sx={{ width: 300 }} component='span'>
                    {fullDto.notes || "-"}
                  </AppTypography>
                </FieldValue>
              )}
              {fullDto?.accessories && (
                <FieldValue label='Accessories'>{fullDto.accessories?.length}</FieldValue>
              )}
            </Stack>
          </Grid>
          {detailedDataProps.customer && fullDto?.customer && (
            <Grid item xxs={12} md minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Customer name'>
                  {fullDto.customer?.contact?.personName?.name}
                </FieldValue>
                <FieldValue label='Customer email' withCopyButton>
                  {fullDto.customer?.contact?.email}
                </FieldValue>
                <FieldValue label='Customer type'>
                  <InlineApiEnumValue type='CustomerType' value={fullDto.customer?.type} />
                </FieldValue>
              </Stack>
            </Grid>
          )}
        </Grid>
      }
    />
  );
}

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AppIcon from "@/common/components/Icons/AppIcon";
import RouteCategoryIndex from "@/common/components/Route/RouteCategoryIndex";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function RepairPage() {
  return (
    <ViewPageLayout header={<SimpleViewPageHeader title='Repair' />}>
      <RouteCategoryIndex
        direction='row'
        childrenLinks={[
          {
            icon: <AppIcon of='repairMaterial' />,
            title: "Repair materials",
            to: ROUTE_PATH.REPAIR_MATERIALS,
          },
          {
            icon: <AppIcon of='repairWork' />,
            title: "Repair work",
            to: ROUTE_PATH.REPAIR_WORK_LIST,
          },
          {
            icon: <AppIcon of='repairSpec' />,
            title: "Repair specs",
            to: ROUTE_PATH.REPAIR_SPECS,
          },
        ]}
      />
    </ViewPageLayout>
  );
}

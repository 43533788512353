import {
  InputAdornment,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
  TextFieldProps,
} from "@mui/material";
import _ from "lodash";
import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { enumService } from "@/common/services/enum";
import { CurrencyCode, GeneralCurrencyInputDto } from "@/core/api/generated";

import DropdownButton from "../../Button/DropdownButton";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";

interface Props extends Omit<TextFieldProps, "onChange" | "type" | "value" | "startAdornment"> {
  value?: number | null;
  defaultCurrencyCode?: CurrencyCode;
  currencyCode?: CurrencyCode;
  allowCurrencyEdit?: boolean;
  onChange?: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    newValue?: number | null,
    newCurrency?: GeneralCurrencyInputDto | null,
  ) => void;
  onCurrencyChange?: (newValue?: GeneralCurrencyInputDto | null) => void;
}

export default function PriceInput({
  value,
  defaultCurrencyCode,
  allowCurrencyEdit = false,
  currencyCode,
  onChange,
  onCurrencyChange,
  ...textFieldProps
}: Props) {
  const [_currencyCode, _setCurrencyCode] = useState<CurrencyCode | undefined>(defaultCurrencyCode);

  useEffect(() => _setCurrencyCode(currencyCode), [currencyCode]);

  const _onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newInputValue = _.isNil(e.target.value) ? undefined : +e.target.value;
      onChange &&
        onChange(
          e,
          newInputValue,
          (_currencyCode && {
            code: _currencyCode,
          }) ||
            undefined,
        );
    },
    [onChange],
  );

  const _setCurrency = useCallback(
    (newCurrencyCode?: CurrencyCode | null) => {
      _setCurrencyCode(newCurrencyCode as CurrencyCode);
      onCurrencyChange &&
        onCurrencyChange(
          (newCurrencyCode && {
            code: newCurrencyCode,
          }) ||
            undefined,
        );
    },
    [_setCurrencyCode, onCurrencyChange],
  );

  return (
    <TextField
      label='Price'
      inputMode='numeric'
      value={value || ""}
      onChange={_onChange}
      InputProps={{
        endAdornment: _currencyCode && (
          <InputAdornment position='end'>
            <DropdownButton
              size={textFieldProps.size}
              disabled={!allowCurrencyEdit || textFieldProps?.disabled}
              buttonProps={{
                variant: "text",
                color: "secondary",
              }}
              dropdownContent={
                <MenuList>
                  {enumService.getEnumDtos("CurrencyCode").map((x, i) => (
                    <MenuItem
                      key={i}
                      onClick={() => {
                        _setCurrency(x.value as CurrencyCode);
                      }}
                    >
                      <ListItemText>{x.name}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              }
            >
              <InlineApiEnumValue type='CurrencyCode' value={_currencyCode} />
            </DropdownButton>
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
    />
  );
}

import { Skeleton, SkeletonProps, SxProps, Theme, useTheme } from "@mui/material";

interface Props {
  contentProps?: SkeletonProps;
  sx?: SxProps<Theme>;
}

export default function TextLineSkeleton({ contentProps, sx }: Props) {
  const theme = useTheme();

  return (
    <Skeleton
      sx={{
        display: "block",
        ...sx,
      }}
      component='span'
      variant='rectangular'
      width={"100%"}
      height={theme.typography.fontSize}
      {...contentProps}
    >
      &nbsp;
    </Skeleton>
  );
}

import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitiesSearchAutocomplete, {
  InheritableBaseEntitiesSearchAutocompleteProps,
} from "@/common/components/Entity/components/BaseEntitiesSearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { VehicleModelDto, VehicleModelSearchPaginatedDto } from "@/core/api/generated";

import { apiClient } from "@/core/api/ApiClient";
import { vehicleModelToOption } from "./VehicleModelAutocomplete";

export interface VehicleModelsAutocompleteProps
  extends InheritableBaseEntitiesSearchAutocompleteProps<VehicleModelDto> {
  searchFilters?: Partial<Omit<VehicleModelSearchPaginatedDto, "search" | "includeIds">>;
}

export default function VehicleModelsAutocomplete({
  searchFilters,
  isPreload = true,
  ...otherProps
}: VehicleModelsAutocompleteProps) {
  return (
    <BaseEntitiesSearchAutocomplete
      sx={{ minWidth: 200 }}
      entityToOption={vehicleModelToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.vehicleModelsApi.apiV1ReferenceDataVehiclesModelsSearchPost,
        limit: 25,
        parameters: {
          vehicleModelSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          vehicleModelSearchPaginatedDto: {
            ...params.vehicleModelSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Models'
      placeholder='Search for models...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props} key={option.id}>
            <ListItemIcon>
              <AppIcon of='vehicle' />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography component='div' variant='body1'>
                  {option.title}
                </Typography>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

import { Box, Dialog, DialogProps } from "@mui/material";
import {
  ReactElement,
  cloneElement,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTriggerRender } from "../../hooks/render/useTriggerRender";
import AppMountedSensor from "../AppMountedSensor";
import AppModalTitle, { AppModalTitleProps } from "./AppModalTitle";

/** Time to wait after Popover open is requested and then it can be considered as opened. */
const openWaitMs = 300;
/** Time to wait before closing Popover after delayed close was requested. */
const closeDelayedWaitMs = 100;

export interface AppPopoverHoverBehaviorProps {
  /**
   * onTriggerMouseLeave - popover is closed when mouse leaves the trigger.
   * onContentMouseLeave - popover is closed when mouse enters and then leaves the content.
   * onTriggerOrContentMouseLeave - combination of onTriggerMouseLeave and onContentMouseLeave.
   *  If mouse leaves the trigger and then enters the content during short time, then popover is not closed.
   */
  closeBehavior?: "onTriggerMouseLeave" | "onContentMouseLeave" | "onTriggerOrContentMouseLeave";
}

interface OwnProps {
  /** Whether modal is enabled.
   * @default true
   */
  enabled?: boolean;
  /** Element that triggers modal open.. */
  trigger?: ((params: { isOpen: boolean }) => ReactElement) | ReactElement;
  titleProps?: AppModalTitleProps;
  /** Modal content. */
  children: ((params: { close: () => void }) => ReactElement) | ReactElement;
  /** Fired when popover opened (its content is mounted). */
  onOpened?: () => void;
}

export type AppModalV2Props = OwnProps & Partial<Omit<DialogProps, "ref">>;

export type AppPopoverHandle = {
  open: () => void;
  close: () => void;
};

export default forwardRef<AppPopoverHandle, AppModalV2Props>(function AppModalV2(
  { enabled = true, trigger, titleProps, children, onOpened, ...otherProps }: AppModalV2Props,
  ref,
) {
  const { triggerRender } = useTriggerRender();
  const triggerRef = useRef<HTMLElement | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(otherProps.open ?? false);

  const isOpenComputed = useMemo(() => otherProps.open ?? isOpen, [otherProps.open, isOpen]);

  const open = () => {
    setIsOpen(true);
  };

  const close = (e?: any, reason?: "backdropClick" | "escapeKeyDown") => {
    setIsOpen(false);
    otherProps.onClose && otherProps.onClose(e, reason || "escapeKeyDown");
  };

  const triggerElement = useMemo(() => {
    const element = typeof trigger === "function" ? trigger({ isOpen: isOpenComputed }) : trigger;
    if (!element) {
      return null;
    }
    return cloneElement(element, {
      ref: triggerRef,
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        open();
      },
    });
  }, [trigger]);

  useImperativeHandle(
    ref,
    () => ({
      open() {
        open();
      },
      close() {
        close();
      },
    }),
    [triggerRef.current, open, close],
  );

  return (
    // use React.Fragment to render trigger directly without wrapping
    <>
      {triggerElement}

      {enabled && (
        <Dialog
          {...otherProps}
          open={isOpenComputed}
          onClose={(e, reason) => {
            close(e, reason);
          }}
        >
          <Box>
            {titleProps && (
              <AppModalTitle
                {...titleProps}
                onCloseClicked={() => {
                  close();
                  titleProps.onCloseClicked && titleProps.onCloseClicked();
                }}
              />
            )}

            <Box>{typeof children === "function" ? children({ close }) : children}</Box>

            {/* Track when model is opened. */}
            <AppMountedSensor
              onMounted={() => {
                onOpened && onOpened();
              }}
            />
          </Box>
        </Dialog>
      )}
    </>
  );
});

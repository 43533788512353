import { Dialog, DialogContent, DialogProps, LinearProgress } from "@mui/material";
import { useEffect } from "react";

import { useModalOpenStatus } from "@/common/hooks/useModalOpenStatus";
import * as negotiationsSlice from "@/store/communication/negotiationsSlice";

import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import AppModalTitle from "../../../Modals/AppModalTitle";
import PaginatedNegotiationList from "../../Negotiation/PaginatedNegotiationList";

interface OwnProps {
  chatId: string;
}

type Props = OwnProps & DialogProps;

export default function ChatNegotiationsModal({ chatId, ...dialogProps }: Props) {
  const openStatus = useModalOpenStatus(dialogProps);
  const thunkDispatch = useAppThunkDispatch();
  const {
    paginatedNegotiations,
    loading: { getPaginated: isPaginatedLoading },
  } = useAppSelector((x) => x.communication.negotiations);

  useEffect(() => {
    if (openStatus.isOpening) {
      thunkDispatch(
        negotiationsSlice.getPaginatedNegotiations({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          negotiationGetPaginatedDto: {
            offset: 0,
            limit: 100,
            search: undefined,
            chatId,
          },
        }),
      );
    }
  }, [openStatus]);

  return (
    <Dialog maxWidth='lg' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Chat negotiations
      </AppModalTitle>
      <DialogContent>
        {isPaginatedLoading && <LinearProgress sx={{ my: 1 }} />}
        <PaginatedNegotiationList paginated={paginatedNegotiations} />
      </DialogContent>
    </Dialog>
  );
}

import { Stack, SxProps, Theme } from "@mui/material";
import { Moment } from "moment";

import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import { DATETIME_FORMATS } from "@/common/constants/common";
import { EntityChangedByInfoFromDto } from "@/common/ts/dto";

import { renderIf } from "@/common/helpers/render/renderIf";
import { GeneralByWhoDto } from "@/core/api/generated";
import { ResponsiveStyleValueCustom } from "@/theme/types";
import _ from "lodash";
import Datetime from "../Datetime/Datetime";
import GeneralByWhoDisplay from "../Entity/General/Display/GeneralByWhoDisplay";

export type Entity = EntityChangedByInfoFromDto & Record<string, any>;

export interface EntityChangedByInfoDisplayProps {
  dateFormat?: string | null | undefined;
  datetime: string | Moment | null | undefined;
  userId: string | null | undefined;
  who?: GeneralByWhoDto | null;
  direction?: ResponsiveStyleValueCustom<"row" | "column">;
  sx?: SxProps<Theme>;
}

/** Shows who changed the entity */
export default function EntityChangedByInfoDisplay({
  dateFormat = DATETIME_FORMATS.DISPLAY_DATETIME,
  datetime,
  userId,
  who,
  direction = "row",
  sx,
}: EntityChangedByInfoDisplayProps) {
  return (
    <Stack
      sx={{
        ...(direction === "row" && { alignItems: "center", flexWrap: "wrap" }),
        ...(direction === "column" && { justifyContent: "flex-start", alignItems: "flex-start" }),
        ...sx,
      }}
      direction={direction}
      spacing={direction === "row" ? 1 : 0}
    >
      <Datetime datetime={datetime} format={dateFormat} withDurationFromNow />

      {renderIf()
        .if(!_.isNil(who))
        .then(<GeneralByWhoDisplay who={who} />)
        .else(<InlineUser userId={userId} withAvatar />)
        .render()}
    </Stack>
  );
}

import { apiClient } from "@/core/api/ApiClient";
import { EntityType } from "@/core/api/generated";
import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";
import { TeslaConnectionDto } from "@/core/api/generated/v0.1-demo";

export interface OwnProps {
  entity: TeslaConnectionDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function TeslaConnectionDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.TeslaConnection}
      entity={entity}
      entityId={entity.id}
      deleteFunc={(params) =>
        apiClient.teslaConnectionsApi.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          teslaConnectionId: params.entityId,
        })
      }
      {...otherProps}
    />
  );
}

import { LoadingButton } from "@mui/lab";
import { Paper, Stack, TextField, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { getIn, useFormik } from "formik";
import * as Yup from "yup";

import GeneralBrandingInputs from "@/common/components/Entity/General/GeneralBranding/GeneralBrandingInputs";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FormikHelper } from "@/common/helpers/formik";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CreateDepartmentDto,
  DepartmentDto,
  GeneralBrandingInputDto,
  UpdateDepartmentDto,
} from "@/core/api/generated";
import GeneralValidationError from "../../Error/GeneralValidationError";
import GeneralAddressInput from "../General/Input/GeneralAddressInput";
import { BaseEntityCreateUpdateInheritableProps } from "../components/BaseEntityCreateUpdate";

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
}));

const defaultDisplayProps = {
  breadcrumbs: true,
};

export type DepartmentCreateUpdateDefaultValues = {
  name?: CreateDepartmentDto["name"];
};

export interface DepartmentCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<
    DepartmentDto,
    DepartmentCreateUpdateDefaultValues
  > {
  departmentId?: string;
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export type DepartmentCreateUpdateProps = DepartmentCreateUpdateOwnProps;

export default function DepartmentCreateUpdate({
  departmentId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
  displayProps = defaultDisplayProps,
}: DepartmentCreateUpdateProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !departmentId;

  const departmentRequest = useApiRequest(
    apiClient.departmentsApi.apiV1DepartmentsDepartmentIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      departmentId: departmentId!,
    },
    {
      skip: !departmentId,
    },
  );
  const department = departmentRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        department && {
          idValue: department.id!,
          newTitle: department.localNumber || "",
        }) ||
      undefined,
  });

  const formik = useFormik<CreateDepartmentDto & UpdateDepartmentDto & BaseFormikValues>({
    enableReinitialize: !isCreate,
    initialValues: {
      name: defaultValues?.name || department?.name || undefined,
      description: department?.description || undefined,
      address: department?.address || undefined,
      branding: department?.branding || undefined,
      submit: "",
    },
    validationSchema: Yup.object().shape({
      // name: Yup.string().required("Name is required"),
      // description: Yup.string(),
      // address: Yup.object().shape({
      //   country: Yup.string().required("Country is required"),
      //   state: Yup.string(),
      //   city: Yup.string().required("City is required"),
      //   line1: Yup.string().required("Line 1 is required"),
      //   line2: Yup.string(),
      //   postalCode: Yup.string().required("Postal Code is required"),
      // }),
      // branding: Yup.object().shape({
      //   fullLegalName: Yup.string().required("Branding full name is required"),
      //   shortName: Yup.string().required("Branding short name is required"),
      //   disclaimer: Yup.string(),
      // }),
    }),
    onSubmit: async (values, { setFieldError, setStatus, setSubmitting }) => {
      try {
        if (isCreate) {
          const response = await apiClient.departmentsApi.apiV1DepartmentsPost({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            createDepartmentDto: {
              ...values,
            },
          });
          enqueueSnackbar("Department created.", { variant: "success" });
          onCreate && onCreate(response.data);
          onSave && onSave(response.data);
        } else {
          const response = await apiClient.departmentsApi.apiV1DepartmentsDepartmentIdPut({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            departmentId,
            updateDepartmentDto: {
              ...values,
            },
          });
          enqueueSnackbar("Department updated.", { variant: "success" });
          onUpdate && onUpdate(response.data);
          onSave && onSave(response.data);
        }

        if (mounted.current) {
          setStatus({ success: true });
          setSubmitting(false);
        }
      } catch (err: any) {
        if (mounted.current) {
          ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = formik;

  const brandingFormikProps = FormikHelper.getSubProps<
    CreateDepartmentDto & UpdateDepartmentDto & BaseFormikValues,
    GeneralBrandingInputDto | undefined
  >(formik, "branding", (v) => v.branding);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Box>
          <TextField
            error={Boolean(touched.name && errors.name)}
            required
            fullWidth
            helperText={touched.name && errors.name}
            label='Name'
            margin='dense'
            name='name'
            onBlur={handleBlur}
            onChange={handleChange}
            type='text'
            value={values.name || ""}
            variant='outlined'
          />

          <TextField
            error={Boolean(touched.description && errors.description)}
            fullWidth
            multiline
            minRows={2}
            helperText={touched.description && errors.description}
            label='Description'
            margin='dense'
            name='description'
            onBlur={handleBlur}
            onChange={handleChange}
            type='text'
            value={values.description || ""}
            variant='outlined'
          />

          <Paper sx={{ mt: 1, p: 1 }}>
            <Typography component='div' variant='subtitle1'>
              Address
            </Typography>

            <GeneralAddressInput
              errors={getIn(errors, "address")}
              touched={getIn(touched, "address")}
              value={values.address}
              inputsProps={{ all: {} }}
              onChange={(newValue) => setFieldValue("address", newValue)}
            />
          </Paper>

          <Paper sx={{ mt: 1, p: 1 }}>
            <Typography component='div' variant='subtitle1'>
              Branding
            </Typography>
            <GeneralBrandingInputs formikProps={brandingFormikProps} />
          </Paper>
        </Box>

        <GeneralValidationError sx={{ my: 1 }} errors={errors} />

        <LoadingButton
          sx={{ mt: { xs: "auto", md: 2 } }}
          color='primary'
          loading={isSubmitting}
          fullWidth
          type='submit'
          variant='contained'
        >
          Save
        </LoadingButton>
      </Stack>
    </form>
  );
}

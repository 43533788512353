import { Stack, Typography } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { EntityType, PoolDto, PoolStructureType } from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppIconButton from "@/common/components/Button/AppIconButton";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import ApiEnumIcon from "@/common/components/Icons/ApiEnumIcon";
import AppIcon from "@/common/components/Icons/AppIcon";
import _ from "lodash";
import { useState } from "react";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import GeneralEntitySubTypeInline from "../../../General/Display/GeneralEntitySubTypeInline";
import PoolAvailabilityDisplay from "../../PoolAvailabilityDisplay";

interface Props {
  pool: PoolDto;
}

export default function OverviewTabContent({ pool }: Props) {
  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={pool} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <FieldValue label='Name' isEmpty={!pool?.name}>
                {pool?.name}
              </FieldValue>

              <FieldValue label='Description' isEmpty={!pool?.description}>
                {pool?.description}
              </FieldValue>

              <FieldValue label='Structure type' isEmpty={!pool?.structureType}>
                <InlineApiEnumValue
                  type='PoolStructureType'
                  value={pool.structureType}
                  withDescription
                />
              </FieldValue>

              <FieldValue label='Item type' isEmpty={!pool?.itemType}>
                <InlineApiEnumValue type='PoolItemType' value={pool.itemType} withDescription />
              </FieldValue>

              {pool?.structureType === PoolStructureType.Uniform && (
                <FieldValue label='Item entity type' isEmpty={!pool?.itemEntityType}>
                  <ApiEnumIcon type='EntityType' value={pool?.itemEntityType} inText />{" "}
                  <InlineApiEnumValue
                    type='PoolItemEntityType'
                    value={pool.itemEntityType}
                    withDescription={false}
                  />
                  {pool?.itemEntitySubType && (
                    <>
                      {" "}
                      (
                      <Typography component='span' variant='body2'>
                        <GeneralEntitySubTypeInline value={pool?.itemEntitySubType} withIcon />
                      </Typography>
                      )
                    </>
                  )}
                </FieldValue>
              )}
              {pool?.structureType === PoolStructureType.Diverse && (
                <FieldValue label='Item entity types' isEmpty={_.isEmpty(pool?.itemEntityTypes)}>
                  <InlineApiEnumValueList
                    type='PoolItemEntityType'
                    values={pool.itemEntityTypes}
                    valueProps={{
                      withDescription: false,
                    }}
                  />
                </FieldValue>
              )}

              <FieldValue label='Availability'>
                <PoolAvailabilityDisplay poolId={pool?.id} availability={undefined} isLoad />
              </FieldValue>
            </Stack>

            {/* Affiliation */}
            <Stack spacing={1} sx={{ mt: 2 }}>
              <Typography variant='subtitle1'>
                Affiliation{" "}
                <AppIconButton
                  tooltipProps={{ title: "Edit entity affiliation" }}
                  onClick={() => setIsUpdateAffiliationModalOpened(true)}
                >
                  <AppIcon of='edit' fontSize='small' />
                </AppIconButton>
              </Typography>
              <AffiliationInfoDisplay
                tenantId={pool.tenantId}
                departmentIds={pool.departmentIds}
                locationIds={pool.locationIds}
                sx={{ mt: 1 }}
              />
              <UpdateEntityAffiliationCascadeModal
                open={isUpdateAffiliationModalOpened}
                onClose={() => setIsUpdateAffiliationModalOpened(false)}
                entityType={EntityType.Pool}
                entityId={pool.id}
              />
            </Stack>

            <Stack spacing={1}>
              <Typography component='div' variant='h6'>
                Settings
              </Typography>

              <Stack spacing={1}>
                <FieldValue label='Min items' isEmpty={_.isNil(pool?.settings?.minItems)}>
                  {pool.settings?.minItems}
                </FieldValue>

                <FieldValue label='Max items' isEmpty={_.isNil(pool?.settings?.maxItems)}>
                  {pool.settings?.maxItems}
                </FieldValue>

                <FieldValue
                  label='Ensure entity belongs to a single pool'
                  isEmpty={_.isNil(pool?.settings?.isEnsureEntityBelongToSinglePool)}
                >
                  <BooleanValue value={pool.settings?.isEnsureEntityBelongToSinglePool} />
                </FieldValue>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

import { useHistory, useParams } from "react-router";

import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import DepartmentCreateUpdate from "@/common/components/Entity/Department/DepartmentCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function DepartmentCreateUpdatePage() {
  const { departmentId } = useParams<{ departmentId?: string }>();
  const history = useHistory();

  const isCreate = !departmentId;

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader title={isCreate ? "Create new department" : "Edit department"} />
      }
    >
      <DepartmentCreateUpdate
        departmentId={departmentId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.DEPARTMENT_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}

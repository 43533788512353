import { Dialog, DialogProps } from "@mui/material";

import AppModalContent from "@/common/components/Modals/AppModalContent";
import TenantConnectionView, { TenantConnectionViewProps } from "./TenantConnectionView";

export interface OwnProps {
  viewProps: TenantConnectionViewProps;
}

type Props = OwnProps & DialogProps;

export default function TenantConnectionViewModal({ viewProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalContent>
        <TenantConnectionView
          {...viewProps}
          displayProps={{
            ...viewProps?.displayProps,
            breadcrumbs: false,
          }}
        />
      </AppModalContent>
    </Dialog>
  );
}

import { TenantConnectionDto, TenantConnectionRequestDto } from "@/core/api/generated";
import { TypedEventEmitter } from "./typedEventEmitter";

export type TenantToTenantEventsMap = {
  // list of supported events
  tenantConnectionRequestCreated: TenantConnectionRequestDto;
  tenantConnectionRequestUpdated: TenantConnectionRequestDto;
  tenantConnectionRequestDeleted: TenantConnectionRequestDto;
  tenantConnectionUpdated: TenantConnectionDto;
};

/** Common tenant-to-tenant events. */
class TenantToTenantEventEmitter extends TypedEventEmitter<TenantToTenantEventsMap> {}

export const tenantToTenantEventEmitter = new TenantToTenantEventEmitter();

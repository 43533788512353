import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { EntityType, VisualInspectionDto } from "@/core/api/generated";
import GeneralHistoryPaginatedList from "../../../GeneralHistory/ListView/GeneralHistoryPaginatedList";

interface Props {
  visualInspection: VisualInspectionDto;
}

export default function GeneralHistoryTabContent({ visualInspection }: Props) {
  return (
    <PageTabContent>
      <GeneralHistoryPaginatedList
        defaultValues={{
          subjectEntityType: EntityType.VisualInspection,
          subjectEntityId: visualInspection.id,
        }}
        displayProps={{
          viewVariant: ViewLayoutVariant.Tab,
        }}
      />
    </PageTabContent>
  );
}

import { Box, Grid, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import EntityDataBlock, {
  EntityDataBlockProps,
} from "@/common/components/EntityData/EntityDataBlock";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { apiClient } from "@/core/api/ApiClient";
import { VehicleDamageDto, VehicleDamageReferenceDto } from "@/core/api/generated";

import VehicleInline from "../Vehicle/VehicleInline";

interface BaseProps extends Pick<EntityDataBlockProps, "withDetailsToggle" | "isDetailsVisible"> {
  withLink?: boolean;
  briefDataProps?: {
    vehicle?: boolean;
  };
  detailedDataProps?: {
    vehicle?: boolean;
  };
  sx?: SxProps<Theme>;
}

type Props =
  | ({ variant: "full"; vehicleDamage?: VehicleDamageDto } & BaseProps)
  | ({ variant: "reference"; vehicleDamage?: VehicleDamageReferenceDto } & BaseProps);

const defaultBriefDataProps: Props["briefDataProps"] = {
  vehicle: true,
};
const defaultDetailedDataProps: Props["detailedDataProps"] = {
  vehicle: true,
};

/** Info as block element. */
export default function VehicleDamageBlock({
  variant,
  vehicleDamage,
  withLink,
  briefDataProps = defaultBriefDataProps,
  detailedDataProps = defaultDetailedDataProps,
  sx,
  ...entityDataBlockProps
}: Props) {
  briefDataProps = { ...defaultBriefDataProps, ...briefDataProps };
  detailedDataProps = {
    ...defaultDetailedDataProps,
    ...detailedDataProps,
  };

  const vehicleDamageFull = vehicleDamage as VehicleDamageDto | undefined;
  const vehicleDamageRef = vehicleDamage as VehicleDamageReferenceDto | undefined;

  const [fetchedVehicleDamage, setFetchedVehicleDamage] = useState<VehicleDamageDto | null>(null);

  useEffect(() => {
    (async () => {
      if (!!vehicleDamage && variant === "reference") {
        try {
          const response =
            await apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              vehicleId: vehicleDamageRef?.vehicleId || vehicleDamageFull?.vehicle?.id || "",
              vehicleDamageId: vehicleDamage.id as string,
            });
          setFetchedVehicleDamage(response.data);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [vehicleDamage && vehicleDamage.id]);

  if (!vehicleDamage) {
    return null;
  }

  const refDto = vehicleDamage as VehicleDamageReferenceDto | undefined;
  const fullDto = (fetchedVehicleDamage || vehicleDamage) as VehicleDamageDto | undefined;

  return (
    <EntityDataBlock
      sx={{
        flex: 1,
        ...sx,
      }}
      {...entityDataBlockProps}
      to={
        withLink
          ? ROUTE_PATH.VEHICLE_DAMAGE_VIEW({
              vehicleId: fullDto?.vehicle?.id || refDto?.vehicleId || undefined,
              vehicleDamageId: vehicleDamage?.id || undefined,
            })
          : undefined
      }
      title={
        <>
          <AppIcon of='vehicleDamage' inText /> Vehicle damage
        </>
      }
      briefContent={
        <>
          <Typography component='div' variant='body1'>
            {vehicleDamage.localNumber}{" "}
          </Typography>
          {fullDto?.vehicle && (
            <Box>
              <VehicleInline entity={fullDto?.vehicle} />
            </Box>
          )}
        </>
      }
      detailedContent={
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              <FieldValue label='VehicleDamage no'>{vehicleDamage.localNumber}</FieldValue>
              {fullDto?.notes && <FieldValue label='Notes'>{fullDto.notes || "-"}</FieldValue>}
            </Stack>
          </Grid>
          {/* {detailedDataProps.customer && fullDto?.customer && (
            <Grid item xxs={12} md minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Customer name'>{fullDto.customer?.contact?.name}</FieldValue>
                <FieldValue label='Customer email'>{fullDto.customer?.contact?.email}</FieldValue>
                <FieldValue label='Customer type'>
                  <InlineApiEnumValue type='CustomerType' value={fullDto.customer?.type} />
                </FieldValue>
              </Stack>
            </Grid>
          )} */}
        </Grid>
      }
    />
  );
}

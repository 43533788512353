import InlineCode from "@/common/components/Code/InlineCode";
import { VolumeDown, VolumeUp } from "@mui/icons-material";
import { Box, Grid, Slider, Stack, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import MuiInput from "@mui/material/Input";

const marks = [
  {
    value: 0,
    label: "0°C",
  },
  {
    value: 20,
    label: "20°C",
  },
  {
    value: 37,
    label: "37°C",
  },
  {
    value: 100,
    label: "100°C",
  },
];
function valuetext(value: number) {
  return `${value}°C`;
}
const Input = styled(MuiInput)`
  width: 42px;
`;

export default function DevSliders() {
  const [value, setValue] = React.useState<number>(30);
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  const [value1, setValue1] = React.useState<number[]>([20, 37]);

  const handleChange1 = (event: Event, newValue: number | number[]) => {
    setValue1(newValue as number[]);
  };

  const [value2, setValue2] = React.useState<number | string | Array<number | string>>(30);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue2(newValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue2(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue2(0);
    } else if (value > 100) {
      setValue2(100);
    }
  };

  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Sliders
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>MUI Sliders</InlineCode>
      </Typography>
      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={1}>
          <Box sx={{ width: 200 }}>
            <Stack spacing={2} direction='row' sx={{ mb: 1 }} alignItems='center'>
              <VolumeDown />
              <Slider aria-label='Volume' value={value} onChange={handleChange} />
              <VolumeUp />
            </Stack>
            <Slider disabled defaultValue={30} aria-label='Disabled slider' />
          </Box>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Box width={300}>
            <Slider size='small' defaultValue={70} aria-label='Small' valueLabelDisplay='auto' />
            <Slider defaultValue={50} aria-label='Default' valueLabelDisplay='auto' />
          </Box>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Box sx={{ width: 300 }}>
            <Slider
              aria-label='Temperature'
              defaultValue={30}
              getAriaValueText={valuetext}
              valueLabelDisplay='auto'
              step={10}
              marks
              min={10}
              max={110}
            />
          </Box>
          <Box sx={{ width: 300 }}>
            <Slider
              aria-label='Small steps'
              defaultValue={0.00000005}
              getAriaValueText={valuetext}
              step={0.00000001}
              marks
              min={-0.00000005}
              max={0.0000001}
              valueLabelDisplay='auto'
            />
          </Box>
          <Box sx={{ width: 300 }}>
            <Slider
              aria-label='Custom marks'
              defaultValue={20}
              getAriaValueText={valuetext}
              step={10}
              valueLabelDisplay='auto'
              marks={marks}
            />
          </Box>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Box sx={{ width: 300 }}>
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={value1}
              onChange={handleChange1}
              valueLabelDisplay='auto'
              getAriaValueText={valuetext}
            />
          </Box>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Box sx={{ width: 250 }}>
            <Typography id='input-slider' gutterBottom>
              Volume
            </Typography>
            <Grid container spacing={2} alignItems='center'>
              <Grid item>
                <VolumeUp />
              </Grid>
              <Grid item xs>
                <Slider
                  value={typeof value2 === "number" ? value2 : 0}
                  onChange={handleSliderChange}
                  aria-labelledby='input-slider'
                />
              </Grid>
              <Grid item>
                <Input
                  value={value2}
                  size='small'
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 10,
                    min: 0,
                    max: 100,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Box sx={{ width: 300 }}>
            <Slider
              aria-label='Temperature'
              defaultValue={30}
              getAriaValueText={valuetext}
              color='secondary'
            />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

import { getUserOptionTitle } from "@/App/MainAppView/components/User/UserAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { UserDto, UserReferenceDto } from "@/core/api/generated";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";

export interface UserInlineProps extends BaseEntityInlineInheritableProps {
  entity: UserDto | UserReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the user. */
export default function UserInline({ entity, entityId, ...otherProps }: UserInlineProps) {
  const request = useApiRequest(
    apiClient.usersApi.apiV1UsersUserIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      userId: entityId || "",
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as UserDto;
  const ref = entity as UserReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='user' inText />}
      content={<>{full && getUserOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "User"}
    />
  );
}

import { IconButton, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { DataListSetIsLoadingFunc } from "@/common/components/DataList/DataList";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import LoadingMenuItem from "@/common/components/Menu/LoadingMenuItem";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FileHelper } from "@/common/helpers/file";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  DataUpdatesHubClientMethodName,
  EntityType,
  InvoiceDto,
  InvoiceGetPaginatedDto,
} from "@/core/api/generated";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function Invoices({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const commonRequestParams = useCommonRequestParams<InvoiceGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.Invoice,
    },
  });

  const getCurrentSubscriptionRequest = useApiRequest(
    apiClient.subscriptionsApi.apiV1SubscriptionsCurrentGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
  );
  const currentSubscriptionId = getCurrentSubscriptionRequest.data?.id;

  const invoicesRequest = useApiRequest(
    apiClient.invoicesApi.apiV1InvoicesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      invoiceGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      skip: !currentSubscriptionId,
      deps: [currentSubscriptionId, ...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedInvoices = invoicesRequest?.data;

  const currentTenant = useCurrentTenant();
  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Invoice)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      invoicesRequest.handleEntityChanged(data);
    },
  });

  const handleDownloadPdf = useCallback(
    async (invoice: InvoiceDto, setIsLoading: DataListSetIsLoadingFunc) => {
      try {
        setIsLoading(true);
        const downloadResult = await FileHelper.getDownloadFileApiResult(() =>
          apiClient.invoicesApi.apiV1InvoicesInvoiceIdDownloadPdfGet(
            {
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              invoiceId: invoice.id!,
            },
            {
              responseType: "blob",
            },
          ),
        );
        FileHelper.downloadBlobFromApiResult(downloadResult);
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  return (
    <ViewLayout
      displayProps={displayProps}
      header={<SimpleViewPageHeader title={undefined} primaryActions={undefined} />}
    >
      <DataTabular
        columns={[
          {
            field: "localNumber",
            title: "Number",
            flex: 1,
            renderCell: (item) => item.localNumber,
          },
          {
            field: "status",
            title: "Status",
            flex: 1,
            renderCell: (item) => <InlineApiEnumValue type='InvoiceStatus' value={item.status} />,
          },
          {
            field: "date",
            title: "Date",
            flex: 1,
            renderCell: (item) => <Datetime datetime={item.date} />,
          },
          {
            field: "paymentMethod",
            title: "Payment method",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='PaymentMethod' value={item.paymentMethod} />
            ),
          },
          {
            field: "subTotal",
            title: "Sub total",
            flex: 1,
            renderCell: (item) => <CurrencyValue value={item.subTotal} currency={item.currency} />,
          },
          {
            field: "discount",
            title: "Discount",
            flex: 1,
            renderCell: (item) => (
              <GeneralDiscountDisplay discount={item.discount} currency={item.currency} />
            ),
          },
          {
            field: "tax",
            title: "Tax",
            flex: 1,
            renderCell: (item) => <GeneralTaxDisplay tax={item.tax} currency={item.currency} />,
          },
          {
            field: "total",
            title: "Total",
            flex: 1,
            renderCell: (item) => <CurrencyValue value={item.total} currency={item.currency} />,
          },
        ]}
        rows={paginatedInvoices?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.INVOICE_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <MenuWithTrigger
            trigger={
              <IconButton>
                <AppIcon of='moreVert' />
              </IconButton>
            }
          >
            {({ handleClose }) => {
              return [
                <MenuItem key={0} component={RouterLink} to={ROUTE_PATH.INVOICE_VIEW(item.id)}>
                  <ListItemIcon>
                    <AppIcon of='view' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>View</ListItemText>
                </MenuItem>,
                <LoadingMenuItem
                  key={1}
                  onClick={async () => {
                    const downloadResult = await FileHelper.getDownloadFileApiResult(() =>
                      apiClient.invoicesApi.apiV1InvoicesInvoiceIdDownloadPdfGet(
                        {
                          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                          invoiceId: item.id!,
                        },
                        {
                          responseType: "blob",
                        },
                      ),
                    );
                    FileHelper.downloadBlobFromApiResult(downloadResult);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='download' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Download PDF</ListItemText>
                </LoadingMenuItem>,
              ];
            }}
          </MenuWithTrigger>
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
      />
    </ViewLayout>
  );
}

import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { AssessmentFlowDto, AssessmentFlowReferenceDto } from "@/core/api/generated";
import { getContractOptionTitle } from "../Contract/ContractAutocomplete";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { StringHelper } from "@/common/helpers/string";
import { getVehicleOptionTitle } from "../Vehicle/VehicleAutocomplete";

export interface AssessmentFlowInlineProps extends BaseEntityInlineInheritableProps {
  entity: AssessmentFlowDto | AssessmentFlowReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the assessmentFlow. */
export default function AssessmentFlowInline({
  entity,
  entityId,
  ...otherProps
}: AssessmentFlowInlineProps) {
  const request = useApiRequest(
    apiClient.assessmentFlowsApi.apiV1FlowsAssessmentFlowsAssessmentFlowIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assessmentFlowId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as AssessmentFlowDto;
  const ref = entity as AssessmentFlowReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='assessmentFlow' inText />}
      content={
        <>
          {StringHelper.joinIntoString(
            [
              entity?.localNumber,
              full?.vehicle && getVehicleOptionTitle(full.vehicle),
              full?.contract && getContractOptionTitle(full.contract),
            ],
            " | ",
            {
              skipEmpty: true,
            },
          )}
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Assessment flow"}
    />
  );
}

import { CircularProgress } from "@mui/material";
import { Suspense, lazy } from "react";
import { Switch } from "react-router";

import { tenantService } from "@/common/services/tenant";

import { RoutePathBuilder } from "@/common/builders/routePath";
import AuthenticatedGuard from "@/common/components/Auth/AuthenticatedGuard";
import AppRoute from "@/common/components/Route/AppRoute";
import { ROUTE_PATH } from "../../common/constants/routing";
import Auth0CallbackHandler from "../BaseApp/Auth/Auth0CallbackHandler/Auth0CallbackHandler";
import CompleteInfo from "../BaseApp/Auth/CompleteInfo/CompleteInfo";
import Forbidden from "../BaseApp/Auth/Forbidden/Forbidden";
import Invite from "../BaseApp/Auth/Invite/Invite";
import LoggedOut from "../BaseApp/Auth/LoggedOut/LoggedOut";
import LogoutHandler from "../BaseApp/Auth/LogoutHandler/LogoutHandler";
import LogoutBeforeRegisteringPage from "../BaseApp/Auth/Register/LogoutBeforeRegisteringPage";
import TenantRegisterRoutes from "../BaseApp/Auth/Register/Tenant/TenantRegisterRoutes";
import TeslaAuthCallbackHandler from "../BaseApp/Auth/Tesla/TeslaAuthCallbackHandler";
import ApiConnectionErrorPage from "../BaseApp/Error/ApiConnectionErrorPage";
import AppInitErrorPage from "../BaseApp/Error/AppInitErrorPage";
import NoInternetErrorPage from "../BaseApp/Error/NoInternetErrorPage";
import NotFoundErrorPage from "../BaseApp/Error/NotFoundErrorPage";
import Profile from "../BaseApp/Profile/Profile";
import RootRouteEntry from "../BaseApp/RootRouteEntry";
import SelectTenantPage from "../BaseApp/SelectTenant/SelectTenantPage";
import DevStyleguide from "../DevStyleguide/DevStyleguide";
import AuthLayout from "../Layouts/AuthLayout";
import BaseAppLayout from "../Layouts/BaseAppLayout";
import ErrorLayout from "../Layouts/ErrorLayout";

// eslint-disable-next-line react/display-name
const Loadable = (Component: React.ComponentType) => (props: any) => (
  <Suspense fallback={<CircularProgress />}>
    <Component {...props} />
  </Suspense>
);

const Login = Loadable(lazy(() => import("../BaseApp/Auth/Login/Login")));

export default function BaseAppContent() {
  return (
    <>
      <Switch>
        {/* Auth0 callback URL handlers */}
        <AppRoute
          path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH0_CALLBACK)}
          exact
          render={() => {
            return <Auth0CallbackHandler />;
          }}
        />

        {/* Tesla Auth callback URL handlers */}
        <AppRoute
          path={RoutePathBuilder.toDefinition(ROUTE_PATH.TESLA_AUTH_CALLBACK)}
          exact
          render={() => {
            return <TeslaAuthCallbackHandler />;
          }}
        />

        {/* Auth routes */}
        <AppRoute
          path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH)}
          render={() => (
            <AuthLayout>
              <AppRoute
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_SIGN_IN())}
                render={() => <Login />}
              />

              <AppRoute
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_SIGN_UP)}
                render={() => {
                  // registration shouldn't be accessed via tenant URL
                  const tenantInfo = tenantService.resolveTenant(undefined, true);
                  if (
                    tenantInfo &&
                    tenantService.defaultTenantIdentifier !== tenantInfo?.identifier &&
                    !tenantService.isReservedTenantIdentifier(tenantInfo?.identifier)
                  ) {
                    tenantService.redirectToDefaultTenantUrl();
                    return;
                  } else {
                    return <TenantRegisterRoutes />;
                  }
                }}
              />

              <AppRoute
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_SIGN_UP_MUST_SIGN_OUT_WARNING)}
                render={() => <LogoutBeforeRegisteringPage />}
              />

              <AppRoute
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.INVITE)}
                render={() => <Invite />}
              />

              <AppRoute
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_COMPLETE_INFO())}
                render={() => <CompleteInfo />}
              />

              <AppRoute
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_SIGN_OUT)}
                render={() => <LogoutHandler />}
              />
              <AppRoute
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_SIGNED_OUT)}
                render={() => <LoggedOut />}
              />
              <AppRoute
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_FORBIDDEN)}
                render={() => <Forbidden />}
              />
            </AuthLayout>
          )}
        />

        {/* Errors */}
        <AppRoute
          path={RoutePathBuilder.toDefinition(ROUTE_PATH.ERROR)}
          render={() => (
            <ErrorLayout>
              <AppRoute
                exact
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.ERROR_APP_INIT)}
                render={() => <AppInitErrorPage />}
              />
              <AppRoute
                exact
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.ERROR_NO_API_CONNECTION)}
                render={() => <ApiConnectionErrorPage />}
              />
              <AppRoute
                exact
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.ERROR_NO_INTERNET)}
                render={() => <NoInternetErrorPage />}
              />
              <AppRoute
                exact
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.ERROR_NOT_FOUND)}
                render={() => <NotFoundErrorPage />}
              />
            </ErrorLayout>
          )}
        />

        {/* Base routes */}
        <BaseAppLayout>
          {/* Authenticated */}
          <AuthenticatedGuard>
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.SELECT_TENANT)}
              render={() => <SelectTenantPage />}
            />
          </AuthenticatedGuard>

          {/* Development */}
          <AppRoute
            path={ROUTE_PATH.DEVELOPMENT}
            render={() => (
              <AppRoute
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.DEV_STYLEGUIDE)}
                render={() => <DevStyleguide />}
              />
            )}
          />

          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.PROFILE)}
            render={() => <Profile />}
          />

          <AppRoute exact path='/' render={() => <RootRouteEntry />} />
        </BaseAppLayout>
      </Switch>
    </>
  );
}

import { ArrayHelper } from "@/common/helpers/array";
import { CustomFormikSubProps, FormikHelper } from "@/common/helpers/formik";
import {
  GeneralCountryInputDto,
  GeneralCurrencyInputDto,
  TenantVehicleTaxSettingsInputDto,
  VehicleTaxInputDto,
} from "@/core/api/generated";
import { Box, Button, Card, CardContent, Stack, SxProps, Theme } from "@mui/material";
import _ from "lodash";
import NoDataAlert from "../../AppAlerts/NoDataAlert";
import AppIcon from "../../Icons/AppIcon";
import VehicleTaxInput from "../Vehicle/VehicleTax/VehicleTaxInput";

interface Props {
  formikProps: CustomFormikSubProps<TenantVehicleTaxSettingsInputDto | null | undefined>;
  defaultCountry?: GeneralCountryInputDto | null;
  defaultCurrency?: GeneralCurrencyInputDto | null;
  sx?: SxProps<Theme>;
}

export default function TenantVehicleTaxSettingsInput({
  formikProps,
  defaultCountry,
  defaultCurrency,
  sx,
}: Props) {
  const { values, touched, errors, setFieldValue, setFieldError } = formikProps;

  return (
    <Stack spacing={2} sx={sx}>
      {/* <Alert severity='info'>
        Settings for visual inspection operation (will be applied to all new operations).
      </Alert> */}

      {_.isEmpty(values?.vehicleTaxes) && <NoDataAlert />}

      <Stack direction='column' spacing={1}>
        {values?.vehicleTaxes?.map((vehicleTax, index) => {
          return (
            <Card key={index}>
              <CardContent>
                <Stack spacing={2}>
                  <VehicleTaxInput
                    formikProps={FormikHelper.getSubProps(
                      formikProps,
                      `vehicleTaxes[${index}]`,
                      (x) => x.vehicleTaxes?.at(index),
                    )}
                    defaultCountry={defaultCountry}
                    defaultCurrency={defaultCurrency}
                  />

                  {/* Remove */}
                  <Stack direction='row'>
                    <Button
                      sx={{ ml: "auto" }}
                      variant='outlined'
                      size='small'
                      color='secondary'
                      startIcon={<AppIcon of='remove' />}
                      onClick={() => {
                        setFieldValue(
                          "vehicleTaxes",
                          ArrayHelper.removeByIndex(values?.vehicleTaxes, index),
                        );
                      }}
                    >
                      Remove
                    </Button>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          );
        })}
      </Stack>

      {/* Add */}
      <Box>
        <Button
          variant='outlined'
          size='small'
          color='secondary'
          startIcon={<AppIcon of='add' />}
          onClick={() => {
            const newValue: VehicleTaxInputDto = {
              countries: defaultCountry ? [defaultCountry] : undefined,
            };
            setFieldValue("vehicleTaxes", [...(values?.vehicleTaxes || []), newValue]);
          }}
        >
          Add
        </Button>
      </Box>
    </Stack>
  );
}

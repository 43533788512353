import { useParams } from "react-router";

import VehicleActualStateView from "@/common/components/Entity/Vehicle/VehicleActualState/VehicleActualStateView";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";

export default function VehicleActualStatePage() {
  const { vehicleId } = useParams<{ vehicleId?: string }>();

  return (
    <ViewPageLayout>
      <VehicleActualStateView vehicleId={vehicleId} />
    </ViewPageLayout>
  );
}

import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { AccessoryDto } from "@/core/api/generated";

import AccessoriesAutocomplete, { AccessoriesAutocompleteProps } from "./AccessoriesAutocomplete";
import AccessoryCreateUpdate from "./AccessoryCreateUpdate";
import AccessoryCreateUpdateModal from "./AccessoryCreateUpdateModal";

interface OwnProps {
  autocompleteProps: AccessoriesAutocompleteProps;
  createFormPlacement?: "modal" | "page";
  onCreate: (newValue: AccessoryDto) => void;
}

type Props = OwnProps;

export default function AccessoriesSelectOrCreate({
  autocompleteProps,
  createFormPlacement = "modal",
  onCreate,
}: Props) {
  const [isCreate, setIsCreate] = useState(false);
  const [newAccessoryName, setNewAccessoryName] = useState<string | null | undefined>(undefined);

  return (
    <Stack spacing={2}>
      {/* 1. Search */}
      {!isCreate && (
        <Box>
          <AccessoriesAutocomplete
            withCreate
            onCreate={(newOption) => {
              setNewAccessoryName(newOption?.inputValue);
              setIsCreate(true);
            }}
            {...autocompleteProps}
          />
        </Box>
      )}

      {/* 2. Create */}
      {isCreate && (
        <Box>
          {isCreate && (
            <>
              {createFormPlacement === "modal" && (
                <AccessoryCreateUpdateModal
                  open={isCreate}
                  onClose={() => setIsCreate(false)}
                  createUpdateProps={{
                    defaultValues: {
                      name: newAccessoryName || undefined,
                    },
                    onSave: () => setIsCreate(false),
                    onCreate: onCreate,
                  }}
                />
              )}

              {createFormPlacement === "page" && (
                <Stack spacing={1}>
                  <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                    <Button
                      variant='text'
                      color='secondary'
                      size='small'
                      startIcon={<AppIcon of='goBack' />}
                      onClick={() => setIsCreate(false)}
                    >
                      Back to search
                    </Button>

                    <Typography component='div' variant='subtitle1'>
                      Create new Accessory
                    </Typography>
                  </Stack>

                  <AccessoryCreateUpdate />
                </Stack>
              )}
            </>
          )}
        </Box>
      )}
    </Stack>
  );
}

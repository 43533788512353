import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function GaugeTimeIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      color='secondary'
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_281_10048)'>
        <path d='M17.9057 11.2626C17.7796 10.8807 17.6249 10.5078 17.4443 10.1518L15.2495 11.141C15.4436 11.4933 15.5983 11.8654 15.7119 12.2517L17.9057 11.2626ZM6.6611 10.455C6.90347 10.1384 7.17982 9.8513 7.48301 9.5991L5.79269 7.83813C5.49935 8.10106 5.22299 8.38815 4.97168 8.69402L6.6611 10.455ZM8.19491 9.10005C8.53387 8.9024 8.8934 8.74589 9.26634 8.63231L8.34158 6.33473C7.9749 6.46173 7.61537 6.61913 7.27015 6.80337L8.19491 9.10005ZM5.7274 12.334C5.83562 11.945 5.98498 11.5702 6.17279 11.2161L3.96464 10.2627C3.78846 10.6231 3.6391 10.9979 3.51926 11.3806L5.7274 12.334ZM14.1647 6.78906C13.8186 6.60572 13.4581 6.4501 13.0915 6.32489L12.1757 8.62605C12.5486 8.73784 12.909 8.89346 13.2489 9.09021L14.1647 6.78906ZM16.4909 8.69402C16.2387 8.38815 15.9632 8.10106 15.6699 7.83813L13.9796 9.5991C14.2827 9.8513 14.5582 10.1393 14.8006 10.455L16.4909 8.69402ZM11.3117 5.95821C11.1158 5.94211 10.9209 5.93495 10.7313 5.93495C10.5417 5.93495 10.3458 5.943 10.15 5.95821V8.44807C10.3431 8.42571 10.5381 8.41409 10.7313 8.41409C10.9245 8.41409 11.1194 8.42482 11.3117 8.44807V5.95821ZM10.7322 18.7814C9.57489 18.7814 8.63672 17.8432 8.63672 16.6859C8.63672 15.8962 9.07405 15.2084 9.71888 14.8516L10.7322 9.83789L11.7455 14.8507C12.3912 15.2076 12.8276 15.8953 12.8276 16.685C12.8276 17.8432 11.8895 18.7814 10.7322 18.7814ZM21.4643 13.4153C21.4643 15.3685 20.9403 17.2011 20.0244 18.7805L18.4719 17.8834C19.233 16.5679 19.6757 15.0448 19.6757 13.4153C19.6757 8.4758 15.6717 4.4718 10.7322 4.4718C5.79269 4.4718 1.7887 8.4758 1.7887 13.4153C1.7887 15.0448 2.2314 16.5679 2.99249 17.8834L1.4399 18.7805C0.524088 17.2011 0 15.3685 0 13.4153C0 7.49738 4.81427 2.68311 10.7322 2.68311C16.6501 2.68311 21.4643 7.49738 21.4643 13.4153Z' />
      </g>
      <circle cx='18.7826' cy='17.7389' r='6.26087' fill='#EFF7FF' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.8696 17.2171H19.3043V15.6519C19.3043 15.3634 19.0706 15.1302 18.7826 15.1302C18.4946 15.1302 18.2609 15.3634 18.2609 15.6519V17.7389C18.2609 18.0274 18.4946 18.2606 18.7826 18.2606H20.8696C21.1581 18.2606 21.3913 18.0274 21.3913 17.7389C21.3913 17.4504 21.1581 17.2171 20.8696 17.2171ZM18.7826 21.9128C16.4812 21.9128 14.6087 20.0403 14.6087 17.7389C14.6087 15.4375 16.4812 13.565 18.7826 13.565C21.084 13.565 22.9565 15.4375 22.9565 17.7389C22.9565 20.0403 21.084 21.9128 18.7826 21.9128ZM18.7826 12.5215C15.9057 12.5215 13.5652 14.862 13.5652 17.7389C13.5652 20.6157 15.9057 22.9563 18.7826 22.9563C21.6595 22.9563 24 20.6157 24 17.7389C24 14.862 21.6595 12.5215 18.7826 12.5215Z'
      />
      <mask id='mask0_281_10048' maskUnits='userSpaceOnUse' x='13' y='12' width='11' height='11'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20.8696 17.2171H19.3043V15.6519C19.3043 15.3634 19.0706 15.1302 18.7826 15.1302C18.4946 15.1302 18.2609 15.3634 18.2609 15.6519V17.7389C18.2609 18.0274 18.4946 18.2606 18.7826 18.2606H20.8696C21.1581 18.2606 21.3913 18.0274 21.3913 17.7389C21.3913 17.4504 21.1581 17.2171 20.8696 17.2171ZM18.7826 21.9128C16.4812 21.9128 14.6087 20.0403 14.6087 17.7389C14.6087 15.4375 16.4812 13.565 18.7826 13.565C21.084 13.565 22.9565 15.4375 22.9565 17.7389C22.9565 20.0403 21.084 21.9128 18.7826 21.9128ZM18.7826 12.5215C15.9057 12.5215 13.5652 14.862 13.5652 17.7389C13.5652 20.6157 15.9057 22.9563 18.7826 22.9563C21.6595 22.9563 24 20.6157 24 17.7389C24 14.862 21.6595 12.5215 18.7826 12.5215Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_281_10048)'>
        <rect x='12.5217' y='11.478' width='12.5217' height='12.5217' />
      </g>
      <defs>
        <clipPath id='clip0_281_10048'>
          <rect width='21.4643' height='21.4643' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});

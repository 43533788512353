import { useHistory, useParams } from "react-router-dom";

import NegotiationView from "@/common/components/Entity/Negotiation/View/NegotiationView";
import { Box } from "@mui/material";

export default function NegotiationViewPage() {
  const { negotiationId } = useParams<{ negotiationId?: string }>();
  const history = useHistory();

  return (
    <Box>
      <NegotiationView
        negotiationId={negotiationId}
        headerProps={{
          withLink: false,
        }}
        displayProps={{
          breadcrumbs: true,
        }}
        onDelete={() => history.goBack()}
      />
    </Box>
  );
}

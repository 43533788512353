import { FilterOperator, FilterType, FilterValueType } from "@/core/api/generated";
import _ from "lodash";
import { Moment } from "moment";
import { FilterHelper } from "../helpers/filter";
import { IdHelper } from "../helpers/id";
import { TypeHelper } from "../helpers/type";
import { ApiEnumName, ApiEnumValue } from "../services/enum";
import { FilterValue, FilterValueDate } from "../ts/filters";

/** NB: must be serializable to/from JSON. */
export class FilterDefinitionItem {
  public id: string;
  public type: FilterType;
  public field: string;
  public operator: FilterOperator;
  public valueType: FilterValueType;
  public value: FilterValue;

  constructor(params: {
    id?: string;
    type: FilterType;
    field: string;
    operator: FilterOperator;
    valueType: FilterValueType;
    value: FilterValue;
  }) {
    this.id = params.id || IdHelper.newId();
    this.type = params.type;
    this.field = params.field;
    this.operator = params.operator;
    this.valueType = params.valueType;
    this.value = params.value;
  }

  public get isValid(): boolean {
    return !TypeHelper.isEmpty(this.field) && !TypeHelper.isEmpty(this.operator);
  }

  public valueAsString(): string | undefined {
    return FilterHelper.valueAsString(this.value);
  }

  public valueAsNumber(): number | undefined {
    return FilterHelper.valueAsNumber(this.value);
  }

  public valueAsBoolean(): boolean | undefined {
    return FilterHelper.valueAsBoolean(this.value);
  }

  public valueAsDate(): FilterValueDate | undefined {
    return FilterHelper.valueAsDate(this.value);
  }

  public valueAsDateAsMoment(): Moment | undefined {
    return FilterHelper.valueAsDateAsMoment(this.value);
  }

  public valueAsDateAsString(): string | undefined {
    return FilterHelper.valueAsDateAsString(this.value);
  }

  public valueAsId(): string | undefined {
    return FilterHelper.valueAsId(this.value);
  }

  public valueAsEnum(): string | undefined {
    return FilterHelper.valueAsEnum(this.value);
  }

  public valueAsEnumTyped<TEnumName extends ApiEnumName>(
    enumName: TEnumName,
  ): ApiEnumValue<TEnumName> | undefined {
    return FilterHelper.valueAsEnumTyped<TEnumName>(enumName, this.value);
  }

  public valueAsArrayOfString(): string[] | undefined {
    return FilterHelper.valueAsArrayOfString(this.value);
  }

  public valueAsArrayOfEnum(): string[] | undefined {
    return FilterHelper.valueAsArrayOfEnum(this.value);
  }

  public valueAsArrayOfEnumTyped<TEnumName extends ApiEnumName>(
    enumName: TEnumName,
  ): Array<ApiEnumValue<TEnumName>> | undefined {
    return FilterHelper.valueAsArrayOfEnumTyped(enumName, this.value);
  }

  public valueAsArrayOfId(): string[] | undefined {
    return FilterHelper.valueAsArrayOfId(this.value);
  }

  public clone(): FilterDefinitionItem {
    return new FilterDefinitionItem({ ...this, value: this.cloneValue() });
  }

  public cloneValue(): FilterValue {
    let newValue: FilterValue = undefined;

    switch (this.valueType) {
      case FilterValueType.Date:
        if (TypeHelper.isMoment(this.value)) {
          newValue = this.value.clone();
        } else if (TypeHelper.isDate(this.value)) {
          newValue = new Date(this.value);
        } else {
          newValue = _.cloneDeep(this.value);
        }

        break;
      default:
        newValue = _.cloneDeep(this.value);
        break;
    }
    return newValue;
  }

  /** Converts internal values to valid format before serialization to JSON. */
  public formatBeforeJsonSerialization(): void {
    switch (this.valueType) {
      case FilterValueType.Date:
        this.value = this.valueAsDateAsMoment()?.clone()?.utc().format();
        break;
    }
  }
}

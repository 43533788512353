import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import _ from "lodash";
import { forwardRef, useState } from "react";
import AppTooltip, { AppTooltipProps } from "../AppTooltip";

export interface AppButtonProps extends LoadingButtonProps {
  tooltipProps?: Omit<AppTooltipProps, "children">;
  /** Whether to auto-track loading state based on Promise returned by onClick.
   * @default true
   */
  isAutoTrackLoading?: boolean;
  /** If Promise returned, loading is auto-set to true. */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
}

/** Extends MUI IconButton. */
export default forwardRef<HTMLButtonElement, AppButtonProps>(function AppButton(
  {
    loading,
    tooltipProps,
    children,
    sx,
    isAutoTrackLoading = true,
    onClick,
    ...otherProps
  }: AppButtonProps,
  ref,
) {
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingComputed = loading ?? isLoading;

  return (
    <AppTooltip
      enabled={!_.isNil(tooltipProps)}
      title={tooltipProps?.title || ""}
      {...tooltipProps}
    >
      <LoadingButton
        ref={ref}
        sx={{
          ...sx,
        }}
        {...otherProps}
        loading={isLoadingComputed}
        onClick={
          onClick
            ? async (e) => {
                isAutoTrackLoading && setIsLoading(true);
                try {
                  await onClick(e);
                } finally {
                  isAutoTrackLoading && setIsLoading(false);
                }
              }
            : undefined
        }
      >
        {children}
      </LoadingButton>
    </AppTooltip>
  );
});

import { Box, SxProps, Theme } from "@mui/material";
import _ from "lodash";

import { DatetimeHelper } from "@/common/helpers/datetime";
import { DurationRepresentation } from "@/common/ts/duration";
import { useMemo } from "react";
import moment from "moment";

interface Props {
  /** ISO 8601 duration or .NET TimeSpan string. */
  value?: string | null;
  /** Format of in and out value. */
  representation?: DurationRepresentation;
  sx?: SxProps<Theme>;
}

export default function DurationDisplay({ value, representation, sx }: Props) {
  const representationComputed = useMemo(
    () => representation || (value && DatetimeHelper.detectDurationRepresentation(value)),
    [value, representation],
  );
  const valueComputed = useMemo(
    () => moment.duration(value).humanize(),
    [value, representationComputed],
  );

  if (_.isNil(value)) {
    return null;
  }

  return (
    <Box component='span' sx={sx}>
      {valueComputed}
    </Box>
  );
}

import ApiEnumAutocomplete from "@/common/components/Enum/ApiEnumAutocomplete";
import { CustomFormikSubProps, FormikHelper } from "@/common/helpers/formik";
import { EmailProviderType, TenantEmailSettingsInputDto } from "@/core/api/generated";
import {
  Alert,
  Box,
  Divider,
  FormControl,
  FormGroup,
  FormHelperText,
  Stack,
  SxProps,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import SmtpInputs from "./SmtpInputs";
import { ValidationHelper } from "@/common/validation";
import { appCommonConfig } from "@/config/config";
import { FormikProps } from "formik";
import { BaseFormikValues } from "@/common/ts/error";

interface Props {
  formikProps: FormikProps<TenantEmailSettingsInputDto & BaseFormikValues>;
  sx?: SxProps<Theme>;
}

export default function TenantEmailSettingsInput({ formikProps, sx }: Props) {
  const { values, errors, setFieldValue } = formikProps;

  return (
    <Stack direction='column' spacing={2} sx={sx}>
      <Stack direction='column' spacing={2}>
        <FormControl fullWidth>
          <ApiEnumAutocomplete
            type='EmailProviderType'
            value={values?.providerType}
            // allow select only implemented providers
            onlyEnumValues={[EmailProviderType.Default, EmailProviderType.Smtp]}
            label='Email provider'
            required
            textFieldProps={{
              error: Boolean(errors?.providerType),
              helperText: errors?.providerType,
            }}
            onChange={(newValue) => {
              setFieldValue("providerType", newValue);
              setFieldValue(
                "sendGrid",
                newValue === EmailProviderType.SendGrid ? { ...values?.sendGrid } : undefined,
              );
              setFieldValue(
                "mailgun",
                newValue === EmailProviderType.Mailgun ? { ...values?.mailgun } : undefined,
              );
              setFieldValue(
                "amazonSes",
                newValue === EmailProviderType.AmazonSes ? { ...values?.amazonSes } : undefined,
              );
              setFieldValue(
                "mailchimp",
                newValue === EmailProviderType.Mailchimp ? { ...values?.mailchimp } : undefined,
              );
              setFieldValue(
                "microsoft365",
                newValue === EmailProviderType.Microsoft365
                  ? { ...values?.microsoft365 }
                  : undefined,
              );
              setFieldValue(
                "azureCs",
                newValue === EmailProviderType.AmazonSes ? { ...values?.azureCs } : undefined,
              );
              setFieldValue(
                "smtp",
                newValue === EmailProviderType.Smtp ? { ...values?.smtp } : undefined,
              );
            }}
          />
          <FormHelperText>
            You can specify custom email provider which will be used by the system to sent your
            emails.
          </FormHelperText>
        </FormControl>
      </Stack>

      <Divider />

      <Stack direction='column' spacing={2} sx={{ mt: 2 }}>
        {values?.providerType === EmailProviderType.Default && (
          <Alert severity='info'>
            Emails are sent via email provider used by {appCommonConfig.appName}.
          </Alert>
        )}

        {values?.providerType === EmailProviderType.SendGrid && <></>}

        {values?.providerType === EmailProviderType.Mailgun && <></>}

        {values?.providerType === EmailProviderType.AmazonSes && <></>}

        {values?.providerType === EmailProviderType.Mailchimp && <></>}

        {values?.providerType === EmailProviderType.Microsoft365 && <></>}

        {values?.providerType === EmailProviderType.AzureCs && <></>}

        {values?.providerType === EmailProviderType.Smtp && (
          <SmtpInputs formikProps={FormikHelper.getSubProps(formikProps, "smtp", (x) => x.smtp)} />
        )}
      </Stack>
    </Stack>
  );
}

import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AccessoryGrid from "@/common/components/Entity/Accessory/AccessoryGrid";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import { VehicleDto } from "@/core/api/generated";
import _ from "lodash";

interface Props {
  vehicle: VehicleDto;
}

export default function AccessoriesTabContent({ vehicle }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column'>
        <PageTabHeader
          title={"Accessories"}
          subtitle2={<EntityCreatedByInfoDisplay entity={vehicle} />}
        />

        {_.isEmpty(vehicle.accessories) && <NoDataAlert title='No accessories added' />}

        <AccessoryGrid accessories={vehicle.accessories} sx={{ mt: 1 }} />
      </Stack>
    </PageTabContent>
  );
}

import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";

import AppBreadcrumbs from "@/common/components/AppBreadcrumbs";
import AppTooltip from "@/common/components/AppTooltip";
import AppVisibilitySensor from "@/common/components/AppVisibilitySensor";
import BlockCode from "@/common/components/Code/BlockCode";
import InlineCode from "@/common/components/Code/InlineCode";
import BaseContactCard from "@/common/components/Contact/BaseContactCard";
import ContactCard from "@/common/components/Contact/ContactCard";
import Datetime from "@/common/components/Datetime/Datetime";
import DatetimeRange from "@/common/components/Datetime/DatetimeRange";
import DelayedRender from "@/common/components/DelayedRender";
import VersionDisplay from "@/common/components/Display/VersionDisplay";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import GeneralApprovalStatusInfoDisplay from "@/common/components/Entity/General/Display/GeneralApprovalStatusInfoDisplay";
import GeneralByWhoDisplay from "@/common/components/Entity/General/Display/GeneralByWhoDisplay";
import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralPriceDisplay from "@/common/components/Entity/General/GeneralPrice/GeneralPriceDisplay";
import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import EntityDataBlock from "@/common/components/EntityData/EntityDataBlock";
import BlockApiEnumValue from "@/common/components/Enum/BlockApiEnumValue";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import CurrencyCodeDisplay from "@/common/components/Form/Display/CurrencyCodeDisplay";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import DurationDisplay from "@/common/components/Form/Display/DurationDisplay";
import InfoTile from "@/common/components/Form/Display/InfoTile";
import PercentValue from "@/common/components/Form/Display/PercentValue";
import HighlightElement from "@/common/components/HighlightElement";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import PermissionDisplay from "@/common/components/Permission/PermissionDisplay";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import RouteCategoryIndex from "@/common/components/Route/RouteCategoryIndex";
import ScrollIntoView from "@/common/components/Scroll/ScrollIntoView";
import { StatusDisplay } from "@/common/components/StatusDisplay/StatusDisplay";
import { ROUTE_PATH } from "@/common/constants/routing";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DurationRepresentation } from "@/common/ts/duration";
import {
  AppPermission,
  ApprovalStatus,
  CurrencyCode,
  DiscountType,
  DiscountValueType,
  TaxType,
  TaxValueType,
  VehicleType,
} from "@/core/api/generated";

export default function DevDataDisplay() {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isHighlighted, setIsHighlighted] = useState(true);
  const [scroll, setScroll] = useState(false);
  const [isVisibleBlock, setIsVisibleBlock] = useState(false);
  const [dynamicDelayMs, setDynamicDelayMs] = useState(60_000);

  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Data display
      </Typography>

      <Box>
        <Typography component='div' variant='h5' sx={{ my: 2 }}>
          <InlineCode>PermissionDisplay</InlineCode>
        </Typography>
        <Typography
          onClick={() => setScroll(!scroll)}
          variant='h5'
          sx={{ my: 2, cursor: "pointer" }}
        >
          tap to ScrollIntoView
        </Typography>
        <Stack direction='row' spacing={1}>
          <Stack spacing={2}>
            <Typography component='div' variant='subtitle1'>
              <InlineCode>{`variant='inline'`}</InlineCode>
            </Typography>
            <PermissionDisplay variant='inline' permission={AppPermission.AccessoryRead} />
            <PermissionDisplay
              variant='inline'
              permission={{
                permission: AppPermission.AccessoryRead,
                groupName: "Permission group name",
                name: "Permission name",
                description: "Permission description",
                moduleName: "Permission module name",
                isTest: true,
              }}
            />
          </Stack>

          <Stack spacing={2}>
            <Typography component='div' variant='subtitle1'>
              <InlineCode>{`variant='block'`}</InlineCode>
            </Typography>
            <PermissionDisplay variant='block' permission={AppPermission.AccessoryRead} />
            <PermissionDisplay
              variant='block'
              permission={{
                permission: AppPermission.AccessoryRead,
                groupName: "Permission group name",
                name: "Permission name",
                description: "Permission description",
                moduleName: "Permission module name",
                isTest: true,
              }}
            />
          </Stack>
        </Stack>
      </Box>

      <Box>
        <Typography component='div' variant='h5' sx={{ my: 2 }}>
          <InlineCode>Datetime</InlineCode>
        </Typography>
        <Stack spacing={1}>
          <Datetime datetime={moment()} />
          <Datetime datetime={moment()} withDurationFromNow />
          <Datetime datetime={moment().add(2, "days").add(1, "hours")} withDurationFromNow />
          <Datetime datetime={moment()} withDurationFromNow direction='row' />
          <Datetime datetime={moment()} withDurationFromNow direction='column' />
        </Stack>
      </Box>

      <Box>
        <Typography component='div' variant='h5' sx={{ my: 2 }}>
          <InlineCode>DatetimeRange</InlineCode>
        </Typography>
        <Stack spacing={1}>
          <DatetimeRange
            range={{ startsAt: moment(), endsAt: moment().add(2, "days").add(1, "hours") }}
          />
          <Divider />
          <DatetimeRange
            range={{ startsAt: moment(), endsAt: moment().add(2, "days").add(1, "hours") }}
            withDuration
          />
          <Divider />
          <DatetimeRange
            range={{ startsAt: moment(), endsAt: moment().add(2, "days").add(1, "hours") }}
            direction='row'
            withDuration
          />
          <Divider />
          <DatetimeRange
            range={{ startsAt: moment(), endsAt: moment().add(2, "days").add(1, "hours") }}
            direction='column'
            withDuration
          />
          <Divider />
          <DatetimeRange
            range={{ startsAt: moment(), endsAt: moment().add(2, "days").add(1, "hours") }}
            withDuration
          />
          <Divider />
          <DatetimeRange
            range={{ startsAt: moment(), endsAt: moment().add(2, "days").add(1, "hours") }}
            variant='compact'
            withDuration
          />
          <Divider />
          <DatetimeRange
            range={{ startsAt: moment(), endsAt: undefined }}
            variant='compact'
            withDuration
          />
        </Stack>
      </Box>

      <Box>
        <Typography component='div' variant='h5' sx={{ my: 2 }}>
          <InlineCode>DurationDisplay</InlineCode>
        </Typography>
        <Stack spacing={1}>
          <DurationDisplay
            value={"P1Y2M10DT2H30M"}
            representation={DurationRepresentation.ISO8601}
          />
          <DurationDisplay
            value={"7.23:59:59"}
            representation={DurationRepresentation.DotNetTimeSpan}
          />
        </Stack>
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>PercentValue</InlineCode>
      </Typography>
      <Box>
        <PercentValue value={0.9} sx={{ mb: 1 }} />
        <PercentValue value={0.45} sx={{ mb: 1 }} />
        <PercentValue value={0.055} sx={{ mb: 1 }} />
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>InfoTile</InlineCode>
      </Typography>
      <Box>
        <InfoTile iconOf='vehicle' sx={{ m: 1 }} label='Plate No'>
          AE3456TU
        </InfoTile>
        <InfoTile iconOf='data' sx={{ m: 1 }} label='Mileage' isEmpty={true}>
          <></>
        </InfoTile>
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>CurrencyCodeDisplay</InlineCode>
      </Typography>
      <Stack direction='column' spacing={1}>
        <CurrencyCodeDisplay currencyCode={CurrencyCode.Usd} />
        <CurrencyCodeDisplay currencyCode={CurrencyCode.Eur} />
      </Stack>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>GeneralCurrencyDisplay</InlineCode>
      </Typography>
      <Stack direction='column' spacing={1}>
        <GeneralCurrencyDisplay
          currency={{
            code: CurrencyCode.Usd,
          }}
        />
      </Stack>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>CurrencyValue</InlineCode>
      </Typography>
      <Box>
        <CurrencyValue value={0.99} currencyCode={CurrencyCode.Usd} sx={{ mb: 1 }} />
        <CurrencyValue value={123.55} currencyCode={CurrencyCode.Usd} sx={{ mb: 1 }} />
        <CurrencyValue value={499} currencyCode={CurrencyCode.Usd} sx={{ mb: 1 }} />
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>GeneralPriceDisplay</InlineCode>
      </Typography>
      <Box>
        <GeneralPriceDisplay
          price={{
            price: 11.99,
            currency: {
              code: CurrencyCode.Usd,
            },
          }}
          sx={{ mb: 1 }}
        />
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>GeneralTaxDisplay</InlineCode>
      </Typography>
      <Stack direction='row' spacing={2}>
        <GeneralTaxDisplay tax={{ valueType: TaxValueType.Percent, percent: 0.2 }} />

        <GeneralTaxDisplay
          tax={{
            valueType: TaxValueType.Value,
            value: 19.55,
            currency: { code: CurrencyCode.Usd },
          }}
          sx={{ mb: 1 }}
        />

        <Stack direction='row' spacing={1}>
          <span>Inline details -</span>
          <GeneralTaxDisplay
            tax={{ type: TaxType.Vat, valueType: TaxValueType.Percent, percent: 0.2 }}
            detailsPlacement='inline'
            sx={{ mb: 1 }}
          />
        </Stack>

        <Stack direction='row' spacing={1}>
          <span>Tooltip details -</span>
          <GeneralTaxDisplay
            tax={{ type: TaxType.Vat, valueType: TaxValueType.Percent, percent: 0.2 }}
            detailsPlacement='tooltip'
            sx={{ mb: 1 }}
          />
        </Stack>
      </Stack>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>GeneralDiscountDisplay</InlineCode>
      </Typography>
      <Stack direction='row' spacing={2}>
        <GeneralDiscountDisplay discount={{ valueType: DiscountValueType.Percent, percent: 0.2 }} />

        <GeneralDiscountDisplay
          discount={{
            valueType: DiscountValueType.Value,
            value: 19.55,
            currency: { code: CurrencyCode.Usd },
          }}
          sx={{ mb: 1 }}
        />

        <Stack direction='row' spacing={1}>
          <span>Inline details -</span>
          <GeneralDiscountDisplay
            discount={{
              type: DiscountType.Trade,
              valueType: DiscountValueType.Percent,
              percent: 0.2,
            }}
            detailsPlacement='inline'
            sx={{ mb: 1 }}
          />
        </Stack>

        <Stack direction='row' spacing={1}>
          <span>Tooltip details -</span>
          <GeneralDiscountDisplay
            discount={{
              type: DiscountType.Trade,
              valueType: DiscountValueType.Percent,
              percent: 0.2,
            }}
            detailsPlacement='tooltip'
            sx={{ mb: 1 }}
          />
        </Stack>
      </Stack>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>BaseContactCard</InlineCode>
      </Typography>
      <Box>
        <BaseContactCard>Any custom content</BaseContactCard>
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>ContactCard</InlineCode>
      </Typography>
      <Box>
        <ContactCard
          contact={{ name: "Carlos Ortega", email: "test@example.com", phoneNumber: "+1337267232" }}
        />
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>EntityDataBlock</InlineCode>
      </Typography>
      <Box>
        <EntityDataBlock
          withDetailsToggle
          title={
            <>
              <AppIcon of='document' inText /> This is custom title
            </>
          }
          briefContent={
            <>
              <Typography component='div' variant='body1'>
                This is custom brief content
              </Typography>
            </>
          }
          detailedContent={
            <>
              <Typography component='div' variant='body1'>
                This is custom detailed content
              </Typography>
              <Typography component='div' variant='body1'>
                This is custom detailed content
              </Typography>
              <Typography component='div' variant='body1'>
                This is custom detailed content
              </Typography>
              <Typography component='div' variant='body1'>
                This is custom detailed content
              </Typography>
            </>
          }
        />
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>AppTooltip</InlineCode>
      </Typography>
      <Box>
        <AppTooltip title='Some text'>
          <Typography component='div' sx={{ mb: 1 }}>
            Normal tooltip
          </Typography>
        </AppTooltip>

        <AppTooltip title='Some text' variant='helpText'>
          <Typography component='div' sx={{ mb: 1 }}>
            Help text tooltip
          </Typography>
        </AppTooltip>
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>HighlightElement</InlineCode>
      </Typography>
      <Stack spacing={1}>
        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isHighlighted}
                  onChange={(e) => setIsHighlighted(e.target.checked)}
                />
              }
              label={isHighlighted ? "Highlight" : "Do not highlight"}
            />
          </FormGroup>
        </Box>

        <HighlightElement isHighlighted={isHighlighted}>
          <Typography component='div' variant='body1' sx={{ p: 2 }}>
            This is content is highlighted when visible
          </Typography>
        </HighlightElement>

        <HighlightElement isHighlighted={isHighlighted} durationMs={3000}>
          <Typography component='div' variant='body1' sx={{ p: 2 }}>
            This is content is highlighted when visible, and then not highlighted after 3 seconds
          </Typography>
        </HighlightElement>
      </Stack>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>GeneralAddressDisplay</InlineCode>
      </Typography>
      <Box>
        <GeneralAddressDisplay
          address={{
            country: "USA",
            state: "Arizona",
            city: "City A",
            line1: "28, ABC st.",
            line2: "app. 89",
            postalCode: "12345",
          }}
        />
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>GeneralPriceSummaryDisplay</InlineCode>
      </Typography>
      <Box>
        <GeneralPriceSummaryDisplay
          summary={{
            subTotal: 100,
            total: 100,
          }}
        />
      </Box>
      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>BlockCode</InlineCode>
      </Typography>
      <Box>
        <BlockCode>BlockCode children</BlockCode>
      </Box>
      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>InlineCode</InlineCode>
      </Typography>
      <Box>
        <InlineCode>InlineCode children</InlineCode>
      </Box>

      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>InlineApiEnumValue</InlineCode>
      </Typography>
      <InlineApiEnumValue type='VehicleType' value={VehicleType.Car} withDescription />

      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>BlockApiEnumValue</InlineCode>
      </Typography>
      <BlockApiEnumValue type='VehicleType' value={VehicleType.Car} withDescription />

      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>GeneralApprovalStatusInfoDisplay</InlineCode>
      </Typography>
      <GeneralApprovalStatusInfoDisplay approval={{ status: ApprovalStatus.Approved }} />
      <GeneralApprovalStatusInfoDisplay approval={{ status: ApprovalStatus.Declined }} />
      <GeneralApprovalStatusInfoDisplay approval={{ status: ApprovalStatus.ForceApproved }} />
      <GeneralApprovalStatusInfoDisplay approval={{ status: ApprovalStatus.ForceDeclined }} />
      <GeneralApprovalStatusInfoDisplay approval={{ status: ApprovalStatus.None }} />

      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>GeneralByWhoDisplay</InlineCode>
      </Typography>
      <Stack spacing={2}>
        <GeneralByWhoDisplay
          who={{
            type: "System",
            email: "custom@gmail.com",
            personName: {
              name: "customUser",
            },
            phone: "+31111111",
          }}
        />
        <GeneralByWhoDisplay
          who={{
            type: "User",
            email: "custom@gmail.com",
            personName: {
              name: "customUser",
            },
            phone: "+31111111",
          }}
        />
        <GeneralByWhoDisplay
          who={{
            email: "custom@gmail.com",
          }}
        />
      </Stack>

      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>AppLink</InlineCode>
      </Typography>
      <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/data-display`} icon={<AppIcon of='code' inText />}>
        data-display
      </AppLink>

      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>ScrollIntoView</InlineCode>
      </Typography>
      <ScrollIntoView
        shouldScroll={scroll}
        scrollDelayMs={300}
        scrollOptions={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
      >
        <>ScrollIntoView</>
      </ScrollIntoView>

      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>AppBreadcrumbs</InlineCode>
      </Typography>
      <AppBreadcrumbs sx={{ mb: 0 }} />

      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>AppVisibilitySensor</InlineCode>
      </Typography>
      <AppVisibilitySensor onChange={(isVisible) => setIsVisibleBlock(isVisible)}>
        <Box>
          <>AppVisibilitySensor {isVisibleBlock ? "visible" : "invisible"}</>
        </Box>
      </AppVisibilitySensor>

      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>DelayedRender</InlineCode>
      </Typography>
      <Stack direction='column' spacing={2}>
        <DelayedRender delayMs={500}>
          <Box>500 ms</Box>
        </DelayedRender>
        <DelayedRender delayMs={1000}>
          <Box>1000 ms</Box>
        </DelayedRender>
        <DelayedRender delayMs={2000}>
          <Box>2000 ms</Box>
        </DelayedRender>
        <DelayedRender delayMs={3000}>
          <Box>3000 ms</Box>
        </DelayedRender>
        <DelayedRender delayMs={5000}>
          <Box>5000 ms</Box>
        </DelayedRender>
        <DelayedRender delayMs={10000}>
          <Box>10000 ms</Box>
        </DelayedRender>
        <DelayedRender delayMs={60000} placeholder={<Skeleton />}>
          <Box>60000 ms with placeholder</Box>
        </DelayedRender>

        <Stack spacing={1}>
          <Typography variant='subtitle1'>Dynamically change delay</Typography>
          <Box>
            Delay ms -{" "}
            <TextField
              size='small'
              inputMode='numeric'
              value={dynamicDelayMs}
              onChange={(e) => setDynamicDelayMs(+e.target.value)}
            />
          </Box>
          <Box>
            Component -{" "}
            <DelayedRender
              delayMs={dynamicDelayMs}
              isReScheduleOnDelayChange
              onReSchedule={(params) => {
                enqueueSnackbar(`Re-scheduled from ${params.oldMs}ms to ${params.newMs}ms.`, {
                  variant: "info",
                });
              }}
              placeholder={
                <Box>
                  <CircularProgress size={16} /> This is a placeholder while waiting for render
                </Box>
              }
            >
              <Box>This is the main component</Box>
            </DelayedRender>
          </Box>
        </Stack>
      </Stack>

      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>StatusDisplay</InlineCode>
      </Typography>
      <Box sx={{ display: "flex", mb: 2 }}>
        <InlineCode sx={{ mr: 2 }}>variant: default</InlineCode>
        <StatusDisplay
          title='Some title'
          subtitle='subtitle'
          sx={{ ml: 1 }}
          color={(theme) => theme.palette.primary.dark}
          size='small'
        />
        <StatusDisplay
          title='Some title'
          subtitle='subtitle'
          sx={{ ml: 1 }}
          color={(theme) => theme.palette.primary.dark}
          size='medium'
        />
        <StatusDisplay
          title='Some title'
          subtitle='subtitle'
          sx={{ ml: 1 }}
          color={(theme) => theme.palette.primary.dark}
          size='large'
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        <InlineCode sx={{ mr: 2 }}>variant: compact</InlineCode>
        <StatusDisplay title='Some title' variant='compact' size='small' sx={{ ml: 1 }} />
        <StatusDisplay title='Some title' variant='compact' size='medium' sx={{ ml: 1 }} />
        <StatusDisplay title='Some title' variant='compact' size='large' sx={{ ml: 1 }} />
      </Box>

      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>VersionDisplay</InlineCode>
      </Typography>
      <Box sx={{ display: "flex", mb: 2 }}>
        <VersionDisplay value='1.2.3' />
      </Box>

      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>RouteCategoryIndex</InlineCode>
      </Typography>
      <Stack spacing={4} sx={{ mb: 2 }}>
        <RouteCategoryIndex
          direction='row'
          childrenLinks={[
            {
              icon: <AppIcon of='damageDetections' />,
              title: "Damage detections",
              to: ROUTE_PATH.DAMAGE_DETECTIONS,
            },
            {
              icon: <AppIcon of='damageCostEvaluations' />,
              title: "Damage cost evaluations",
              to: ROUTE_PATH.DAMAGE_COST_EVALUATIONS,
            },
            {
              icon: <AppIcon of='accessoryChecks' />,
              title: "Accessory checks",
              to: ROUTE_PATH.ACCESSORY_CHECKS,
            },
            {
              icon: <AppIcon of='repairOperations' />,
              title: "Repair operations",
              to: ROUTE_PATH.REPAIR_OPERATIONS,
            },
            {
              icon: <AppIcon of='repairOperations' />,
              title: "Repair operations 2",
              to: ROUTE_PATH.REPAIR_OPERATIONS,
            },
            {
              icon: <AppIcon of='repairOperations' />,
              title: "Repair operations 3",
              to: ROUTE_PATH.REPAIR_OPERATIONS,
            },
          ]}
        />

        <RouteCategoryIndex
          direction='column'
          childrenLinks={[
            {
              icon: <AppIcon of='damageDetections' />,
              title: "Damage detections",
              to: ROUTE_PATH.DAMAGE_DETECTIONS,
            },
            {
              icon: <AppIcon of='damageCostEvaluations' />,
              title: "Damage cost evaluations",
              to: ROUTE_PATH.DAMAGE_COST_EVALUATIONS,
            },
            {
              icon: <AppIcon of='accessoryChecks' />,
              title: "Accessory checks",
              to: ROUTE_PATH.ACCESSORY_CHECKS,
            },
            {
              icon: <AppIcon of='repairOperations' />,
              title: "Repair operations",
              to: ROUTE_PATH.REPAIR_OPERATIONS,
            },
            {
              icon: <AppIcon of='repairOperations' />,
              title: "Repair operations 2",
              to: ROUTE_PATH.REPAIR_OPERATIONS,
            },
            {
              icon: <AppIcon of='repairOperations' />,
              title: "Repair operations 3",
              to: ROUTE_PATH.REPAIR_OPERATIONS,
            },
          ]}
        />
      </Stack>
    </Box>
  );
}

import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { AssetSubscriptionDto } from "@/core/api/generated";

import { pageTabHeaderTypographyProps } from "@/App/Layouts/PageBody/PageTabHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import AssetView from "../../../Asset/View/AssetView";

interface Props {
  assetSubscription: AssetSubscriptionDto;
}

export default function AssetTabContent({ assetSubscription }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        {!assetSubscription.asset?.asset?.id && (
          <EntityNotFoundAlert title='No specific asset set for this subscription.' />
        )}

        {assetSubscription.asset?.asset?.id && (
          <AssetView
            assetId={assetSubscription.asset?.asset?.id}
            asset={undefined}
            displayProps={{
              breadcrumbs: false,
              header: false,
              actions: false,
              viewVariant: ViewLayoutVariant.Tab,
            }}
            headerProps={{
              withLink: true,
              typographyProps: pageTabHeaderTypographyProps.title,
            }}
          />
        )}
      </Stack>
    </PageTabContent>
  );
}

import {
  AppPermission,
  AssessmentFlowDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  SortOrder,
  TenantRequestDto,
  TenantRequestGetPaginatedDto,
  TenantRequestOperationType,
  TenantRequestStage,
  TenantRequestType,
} from "@/core/api/generated";

import { TenantRequestHelper } from "@/common/helpers/entity/tenantRequest";
import { TextHelper } from "@/common/helpers/text";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useTenantProfile } from "@/common/hooks/entity/tenant/useTenantProfile";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { SortDefinition } from "@/common/sorting/sortDefinition";
import { SortDefinitionItem } from "@/common/sorting/sortDefinitionItem";
import { apiClient } from "@/core/api/ApiClient";
import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";
import AuthorizedElement from "../../Auth/AuthorizedElement";
import AuthorizedMenuItem from "../../Auth/AuthorizedMenuItem";
import DataTabular from "../../DataTabular/DataTabular";
import FoldableBlock from "../../Display/FoldableBlock";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import AppIcon from "../../Icons/AppIcon";
import MenuWithTrigger from "../../Menu/MenuWithTrigger";
import AppTypography from "../../Text/AppTypography";
import TenantInline from "../Tenant/TenantInline";
import TenantRequestCreateUpdateModal from "../TenantRequest/TenantRequestCreateUpdateModal";
import TenantRequestStagePrimaryActions from "../TenantRequest/TenantRequestStagePrimaryActions";
import TenantRequestViewModal from "../TenantRequest/View/TenantRequestViewModal";

const defaultPropagatedDeps = {
  refetch: {},
};
export type AssessmentFlowRelatedTenantRequestsPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface AssessmentFlowRelatedTenantRequestsProps {
  assessmentFlow: AssessmentFlowDto;
  operationType: TenantRequestOperationType | undefined;
  propagatedDeps?: PropagatedDeps<AssessmentFlowRelatedTenantRequestsPropagatedDepsMap>;
}

/** TenantRequests related to the AssessmentFlow. */
export default function AssessmentFlowRelatedTenantRequests({
  assessmentFlow,
  operationType,
  propagatedDeps,
}: AssessmentFlowRelatedTenantRequestsProps) {
  const currentTenant = useCurrentTenant();
  const tenantProfile = useTenantProfile();
  const commonRequestParams = useCommonRequestParams<TenantRequestGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.TenantRequest,
    },
    defaultValues: {
      sortDefinition: new SortDefinition({
        items: [
          new SortDefinitionItem({
            field: getTypedPath<TenantRequestDto>().createdAt.$path,
            sortOrder: SortOrder.Desc,
          }),
        ],
      }),
    },
  });

  const [tenantRequestForAction, setTenantRequestForAction] = useState<
    TenantRequestDto | undefined
  >(undefined);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const paginatedTenantRequestsRequest = useApiRequest(
    apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantRequestGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
        senderTenantId: tenantProfile?.id,
        type: TenantRequestType.Operation,
        operationType: operationType,
        assessmentFlowId: assessmentFlow.id,
      },
    },
    {
      deps: [
        assessmentFlow.id,
        operationType,
        tenantProfile?.id,
        ...commonRequestParams.deps,
        propagatedDeps?.depsMap["refetch"],
      ],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedTenantRequests = paginatedTenantRequestsRequest?.data;
  const isAnyTenantRequests = !_.isEmpty(paginatedTenantRequests?.items);
  const tenantRequestCount = useMemo(
    () => paginatedTenantRequests?.items?.length ?? 0,
    [paginatedTenantRequests],
  );
  const openTenantRequestCount = useMemo(
    () =>
      paginatedTenantRequests?.items?.filter(
        (x) => x.stageHistory?.stage !== TenantRequestStage.Closed,
      )?.length ?? 0,
    [paginatedTenantRequests],
  );
  const closedTenantRequestCount = useMemo(
    () =>
      paginatedTenantRequests?.items?.filter(
        (x) => x.stageHistory?.stage === TenantRequestStage.Closed,
      )?.length ?? 0,
    [paginatedTenantRequests],
  );

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entities(undefined, EntityType.TenantRequest),
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.TenantRequest),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedTenantRequestsRequest.handleEntityChanged(data);
    },
  });

  if (!isAnyTenantRequests) {
    return null;
  }

  return (
    <AuthorizedElement permissions={[AppPermission.FleetAppAccess, AppPermission.ContractRead]}>
      <Card>
        <CardContent>
          <Typography variant='subtitle1'>
            You have external{" "}
            {TextHelper.pluralizeManual("assessment", tenantRequestCount, "assessments")} of damage
            costs.{" "}
            <Chip
              variant='outlined'
              color='secondary'
              size='extraSmall'
              label={`Open: ${openTenantRequestCount}`}
            />{" "}
            <Chip
              variant='outlined'
              color='secondary'
              size='extraSmall'
              label={`Closed: ${closedTenantRequestCount}`}
            />{" "}
            <Chip
              variant='outlined'
              color='secondary'
              size='extraSmall'
              label={`Total: ${tenantRequestCount}`}
            />
          </Typography>

          <Typography>
            As soon as the external accessor finish their work and report back, you will be able to
            apply results.
          </Typography>

          <Box>
            <FoldableBlock
              defaultIsFolded
              trigger={{
                label: <Typography variant='h6'>Details</Typography>,
              }}
            >
              <DataTabular<TenantRequestDto>
                columns={[
                  {
                    field: "name",
                    title: "Name",
                    flex: 1,
                    renderCell: (item) => (
                      <Box>
                        <Box>{item.name}</Box>
                        {item.description && (
                          <AppTypography
                            ellipsing={{ enabled: true }}
                            component='div'
                            variant='body2'
                          >
                            {item.description}
                          </AppTypography>
                        )}
                      </Box>
                    ),
                  },
                  {
                    field: "type",
                    title: "Type",
                    flex: 1,
                    renderCell: (item) => (
                      <Box>
                        <Box>
                          <InlineApiEnumValue type='TenantRequestType' value={item.content?.type} />
                        </Box>
                        {item.content?.operation && (
                          <AppTypography
                            ellipsing={{ enabled: true }}
                            component='div'
                            variant='body2'
                          >
                            <InlineApiEnumValue
                              type='TenantRequestOperationType'
                              value={item.content?.operation?.operationType}
                            />
                          </AppTypography>
                        )}
                      </Box>
                    ),
                  },

                  {
                    field: "stageHistory.stage",
                    title: "Stage",
                    flex: 1,
                    renderCell: (item) => (
                      <InlineApiEnumValue
                        type='TenantRequestStage'
                        value={item.stageHistory?.stage}
                      />
                    ),
                  },
                  {
                    field: "receiverTenant.id",
                    title: "Receiver",
                    flex: 1,
                    renderCell: (item) => <TenantInline entity={item.receiverTenant} />,
                  },
                ]}
                rows={paginatedTenantRequests?.items}
                isLoading={paginatedTenantRequestsRequest.isLoading}
                getRowId={(item) => item.id!}
                rowActions={{
                  variant: "arbitrary",
                  renderActions: ({ item }) => (
                    <Stack
                      direction='row'
                      spacing={0.5}
                      justifyContent='flex-end'
                      alignItems='center'
                    >
                      <TenantRequestStagePrimaryActions
                        variant='compact'
                        tenantRequest={item}
                        onUpdate={(newValue) => {
                          paginatedTenantRequestsRequest.updateData((data) => {
                            data.items = data.items?.map((x) =>
                              x.id === newValue.id ? newValue : x,
                            );
                          });
                        }}
                      />

                      <MenuWithTrigger
                        withAuthCloseOnClick
                        trigger={
                          <IconButton>
                            <AppIcon of='moreVert' />
                          </IconButton>
                        }
                      >
                        {TenantRequestHelper.isSentByMe(currentTenant, item) && (
                          <AuthorizedMenuItem
                            permissions={[AppPermission.TenantRequestRead]}
                            onClick={() => {
                              setTenantRequestForAction(item);
                              setIsViewModalOpen(true);
                            }}
                          >
                            <ListItemIcon>
                              <AppIcon of='view' fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>View</ListItemText>
                          </AuthorizedMenuItem>
                        )}
                        {TenantRequestHelper.isSentByMe(currentTenant, item) && (
                          <AuthorizedMenuItem
                            permissions={[AppPermission.TenantRequestManage]}
                            disabled={!TenantRequestHelper.canBeEdited(item)}
                            onClick={() => {
                              setTenantRequestForAction(item);
                              setIsEditModalOpen(true);
                            }}
                          >
                            <ListItemIcon>
                              <AppIcon of='edit' fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>
                          </AuthorizedMenuItem>
                        )}
                      </MenuWithTrigger>
                    </Stack>
                  ),
                }}
                pagination={commonRequestParams.dataTabularProps.pagination}
                sort={commonRequestParams.dataTabularProps.sort}
                quickFilter={commonRequestParams.dataTabularProps.quickFilter}
                filters={commonRequestParams.dataTabularProps.filters}
                refetch={commonRequestParams.dataTabularProps.refetch}
              />
            </FoldableBlock>
          </Box>
        </CardContent>

        {/* View modal */}
        {tenantRequestForAction && (
          <TenantRequestViewModal
            open={isViewModalOpen}
            onClose={() => {
              setIsViewModalOpen(false);
            }}
            viewProps={{
              tenantRequestId: tenantRequestForAction.id,
              tenantRequest: tenantRequestForAction,
              onSave: (newValue) => {
                setIsViewModalOpen(false);
                paginatedTenantRequestsRequest.updateData((data) => {
                  data.items = data.items?.map((x) => (x.id === newValue.id ? newValue : x));
                });
              },
            }}
          />
        )}

        {/* Edit modal */}
        {tenantRequestForAction && (
          <TenantRequestCreateUpdateModal
            open={isEditModalOpen}
            onClose={() => {
              setIsEditModalOpen(false);
            }}
            createUpdateProps={{
              tenantRequest: tenantRequestForAction,
              onSave: (newValue) => {
                setIsEditModalOpen(false);
                paginatedTenantRequestsRequest.updateData((data) => {
                  data.items = data.items?.map((x) => (x.id === newValue.id ? newValue : x));
                });
              },
            }}
          />
        )}
      </Card>
    </AuthorizedElement>
  );
}

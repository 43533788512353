import { EntitySourceType, GeneralEntitySourceDto } from "@/core/api/generated";

import AccessoryCheckLink from "../Entity/AccessoryCheck/AccessoryCheckLink";
import DamageCostEvaluationAggregateLink from "../Entity/DamageCostEvaluationAggregate/DamageCostEvaluationAggregateLink";
import DamageCostEvaluationLink from "../Entity/DamageCostEvaluation/DamageCostEvaluationLink";
import DamageDetectionAggregateLink from "../Entity/DamageDetectionAggregate/DamageDetectionAggregateLink";
import DamageDetectionLink from "../Entity/DamageDetection/DamageDetectionLink";
import DocumentLink from "../Entity/Document/DocumentLink";
import { AppEntityLinkProps } from "./types";

interface Props extends AppEntityLinkProps {
  source: GeneralEntitySourceDto | null | undefined;
}

export default function GeneralEntitySourceLink({ source, ...linkProps }: Props) {
  if (!source || !source.sourceId) {
    return null;
  }

  return (
    <>
      {source.type === EntitySourceType.DamageDetection && (
        <DamageDetectionLink entity={source.damageDetection} {...linkProps} />
      )}
      {source.type === EntitySourceType.DamageDetectionAggregate && (
        <DamageDetectionAggregateLink entity={source.damageDetectionAggregate} {...linkProps} />
      )}
      {source.type === EntitySourceType.DamageCostEvaluation && (
        <DamageCostEvaluationLink entity={source.damageCostEvaluation} {...linkProps} />
      )}
      {source.type === EntitySourceType.DamageCostEvaluationAggregate && (
        <DamageCostEvaluationAggregateLink
          entity={source.damageCostEvaluationAggregate}
          {...linkProps}
        />
      )}
      {source.type === EntitySourceType.AccessoryCheck && (
        <AccessoryCheckLink entity={source.accessoryCheck} {...linkProps} />
      )}
      {source.type === EntitySourceType.Document && (
        <DocumentLink document={source.document} {...linkProps} />
      )}
    </>
  );
}

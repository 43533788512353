import { Box } from "@mui/material";

import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import RepairMaterialLink from "@/common/components/Entity/RepairMaterial/RepairMaterialLink";
import RepairWorkLink from "@/common/components/Entity/RepairWork/RepairWorkLink";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import PercentValue from "@/common/components/Form/Display/PercentValue";
import { RepairSpecDataDto, RepairSpecDto, RepairSpecItemType } from "@/core/api/generated";

import { IdHelper } from "@/common/helpers/id";
import { renderIf } from "@/common/helpers/render/renderIf";
import { useMemo } from "react";
import DataTabular from "../../DataTabular/DataTabular";
import GeneralDiscountDisplay from "../General/GeneralDiscount/GeneralDiscountDisplay";
import RepairSparePartLink from "../RepairSparePart/RepairSparePartLink";

const defaultDisplayProps = {
  tempPlaceholder: true,
};

interface OwnProps {
  repairSpec: RepairSpecDto | RepairSpecDataDto;
  displayProps?: Partial<typeof defaultDisplayProps>;
}

type Props = OwnProps;

export default function RepairSpecItemDataList({
  repairSpec,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  // Use useMemo to cache localId for items without a backend id
  const itemsWithLocalIds = useMemo(() => {
    return (
      repairSpec.items?.map((item) => ({
        ...item,
        localId: item.id ? undefined : IdHelper.newUuid4(),
      })) ?? []
    );
  }, [repairSpec.items]);

  return (
    <Box>
      <DataTabular
        columns={[
          {
            field: "type",
            title: "Type",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='RepairSpecItemType' value={item.type} />
            ),
          },
          {
            field: "material",
            title: "Material/Work/Spare part",
            flex: 1,
            renderCell: (item) => (
              <>
                {renderIf()
                  .if(item.type === RepairSpecItemType.Material)
                  .then(<RepairMaterialLink entity={item.material} withIcon withTooltip />)
                  .elseif(item.type === RepairSpecItemType.Work)
                  .then(<RepairWorkLink entity={item.work} withIcon withTooltip />)
                  .elseif(item.type === RepairSpecItemType.SparePart)
                  .then(
                    <RepairSparePartLink entity={item.sparePart?.sparePart} withIcon withTooltip />,
                  )
                  .else("-")
                  .render()}
              </>
            ),
          },
          {
            field: "isDividable",
            title: "Dividable?",
            flex: 1,
            renderCell: (item) => <BooleanValue value={item.isDividable} />,
          },
          {
            field: "price",
            title: "Price",
            flex: 1,
            renderCell: (item) => (
              <CurrencyValue
                value={item.price}
                currency={
                  item.material?.currency || item.work?.currency || item.sparePart?.currency
                }
              />
            ),
          },
          {
            field: "amount",
            title: "Amount",
            flex: 1,
            renderCell: (item) => <>{item.amount}</>,
          },
          {
            field: "spotFactor",
            title: "Spot factor",
            flex: 1,
            renderCell: (item) => <PercentValue value={item.spotFactor} />,
          },
          {
            field: "subTotal",
            title: "Sub total",
            flex: 1,
            renderCell: (item) => (
              <CurrencyValue
                value={item.subTotal}
                currency={
                  item.material?.currency || item.work?.currency || item.sparePart?.currency
                }
              />
            ),
          },
          {
            field: "discount",
            title: "Discount",
            flex: 1,
            renderCell: (item) => (
              <GeneralDiscountDisplay
                discount={item.discount}
                currency={
                  item.material?.currency || item.work?.currency || item.sparePart?.currency
                }
                detailsPlacement='tooltip'
              />
            ),
          },
          {
            field: "tax",
            title: "Tax",
            flex: 1,
            renderCell: (item) => (
              <GeneralTaxDisplay
                tax={item.tax}
                currency={
                  item.material?.currency || item.work?.currency || item.sparePart?.currency
                }
                detailsPlacement='tooltip'
              />
            ),
          },
          {
            field: "total",
            title: "Total",
            flex: 1,
            renderCell: (item) => (
              <CurrencyValue
                value={item.total}
                currency={
                  item.material?.currency || item.work?.currency || item.sparePart?.currency
                }
              />
            ),
          },
        ]}
        rows={itemsWithLocalIds.filter((x) => !x.isDetailsHidden)}
        getRowId={(item) => item.id ?? item.localId!}
        rowTo={undefined}
        borderVariant='bordered'
        // getRowHeight={() => "auto"}
        isColumnSelectorEnabled={false}
        isColumnMenuEnabled={false}
        isPaginationEnabled={false}
        // renderRowStart={(item) =>
        //   RepairSpecHelper.isCurrencyMismatch({
        //     repairSpec,
        //     repairMaterial: item.material,
        //     repairWork: item.work,
        //   }) ? (
        //     <CurrencyMismatchAlert
        //       repairSpec={repairSpec}
        //       repairMaterial={item.material}
        //       repairWork={item.work}
        //     />
        //   ) : undefined
        // }
      />
    </Box>
  );
}

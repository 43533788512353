import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function PaperPlaneIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      color='secondary'
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 17.8379L13.144 12.2709L18.712 6.7019L15 17.8379ZM17.298 5.2879L11.73 10.8559L6.162 8.9999L17.298 5.2879ZM21.99 2.9479C21.985 2.8549 21.968 2.7649 21.937 2.6779C21.927 2.6469 21.916 2.6169 21.902 2.5869C21.854 2.4799 21.793 2.3789 21.707 2.2929C21.621 2.2069 21.52 2.1459 21.412 2.0979C21.383 2.0839 21.354 2.0729 21.323 2.0629C21.234 2.0319 21.143 2.0139 21.048 2.0089C21.026 2.0079 21.006 2.0029 20.983 2.0029C20.883 2.0049 20.782 2.0189 20.684 2.0509L2.684 8.0509C2.275 8.1879 2 8.5689 2 8.9999C2 9.4309 2.275 9.8129 2.684 9.9489L11.209 12.7909L14.052 21.3169C14.188 21.7249 14.569 21.9999 15 21.9999C15.431 21.9999 15.812 21.7249 15.948 21.3169L21.948 3.3169C21.98 3.2179 21.995 3.1179 21.996 3.0179C21.997 2.9939 21.991 2.9719 21.99 2.9479Z'
      />
      <mask id='mask0_1128_4668' maskUnits='userSpaceOnUse' x='2' y='2' width='20' height='20'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15 17.8379L13.144 12.2709L18.712 6.7019L15 17.8379ZM17.298 5.2879L11.73 10.8559L6.162 8.9999L17.298 5.2879ZM21.99 2.9479C21.985 2.8549 21.968 2.7649 21.937 2.6779C21.927 2.6469 21.916 2.6169 21.902 2.5869C21.854 2.4799 21.793 2.3789 21.707 2.2929C21.621 2.2069 21.52 2.1459 21.412 2.0979C21.383 2.0839 21.354 2.0729 21.323 2.0629C21.234 2.0319 21.143 2.0139 21.048 2.0089C21.026 2.0079 21.006 2.0029 20.983 2.0029C20.883 2.0049 20.782 2.0189 20.684 2.0509L2.684 8.0509C2.275 8.1879 2 8.5689 2 8.9999C2 9.4309 2.275 9.8129 2.684 9.9489L11.209 12.7909L14.052 21.3169C14.188 21.7249 14.569 21.9999 15 21.9999C15.431 21.9999 15.812 21.7249 15.948 21.3169L21.948 3.3169C21.98 3.2179 21.995 3.1179 21.996 3.0179C21.997 2.9939 21.991 2.9719 21.99 2.9479Z'
        />
      </mask>
      <g mask='url(#mask0_1128_4668)'>
        <rect width='24' height='24' />
      </g>
    </SvgIcon>
  );
});

import { Box, Stack } from "@mui/material";

import InfoTile from "@/common/components/Form/Display/InfoTile";
import { VehicleSpecDto } from "@/core/api/generated";

import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import _ from "lodash";
import VehiclePriceDisplay from "./VehiclePriceDisplay";

interface Props {
  spec: VehicleSpecDto | null | undefined;
  columnCount?: number;
}

export default function VehicleSpecDisplay({ spec, columnCount = 4 }: Props) {
  if (!spec) {
    return null;
  }
  return (
    <Stack spacing={1}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
          gridTemplateRows: "auto",
          rowGap: 1,
          columnGap: 1,
        }}
      >
        <InfoTile label='Make' iconOf='vehicle'>
          {spec?.make?.name}
        </InfoTile>

        <InfoTile label='Model' iconOf='vehicleSide'>
          {spec?.model?.name}
        </InfoTile>

        <InfoTile label='Generation' iconOf='clock' isEmpty={!spec?.generation}>
          {spec?.generation?.name}
        </InfoTile>

        <InfoTile label='Modification' iconOf='modification' isEmpty={!spec?.modification}>
          {spec?.modification?.name}
        </InfoTile>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
          gridTemplateRows: "auto",
          rowGap: 1,
          columnGap: 1,
        }}
      >
        <InfoTile label='Type' iconOf='data' isEmpty={!spec?.type}>
          <InlineApiEnumValue type='VehicleType' value={spec?.type} />
        </InfoTile>

        <InfoTile label='Size' iconOf='data' isEmpty={!spec?.size}>
          <InlineApiEnumValue type='VehicleSize' value={spec?.size} />
        </InfoTile>

        <InfoTile label='Body type' iconOf='repairCar' isEmpty={!spec?.bodyType}>
          <InlineApiEnumValue type='VehicleBodyType' value={spec?.bodyType} />
        </InfoTile>

        <InfoTile label='Fuel type' iconOf='fuel' isEmpty={!spec?.fuelType}>
          <InlineApiEnumValue type='VehicleFuelType' value={spec?.fuelType} />
        </InfoTile>

        <InfoTile label='Gearbox type' iconOf='data' isEmpty={!spec?.gearboxType}>
          <InlineApiEnumValue type='VehicleGearboxType' value={spec?.gearboxType} />
        </InfoTile>

        <InfoTile label='Drivetrain type' iconOf='data' isEmpty={!spec?.drivetrainType}>
          <InlineApiEnumValue type='VehicleDrivetrainType' value={spec?.drivetrainType} />
        </InfoTile>

        <InfoTile label='Purchase price' iconOf='price' isEmpty={!spec?.purchasingCost}>
          {spec?.purchasingCost}
        </InfoTile>

        <InfoTile
          label='Purchase price'
          iconOf='price'
          isEmpty={_.isNil(spec?.purchasePrice?.price ?? spec?.purchasingCost)}
        >
          <VehiclePriceDisplay
            price={spec?.purchasePrice}
            fallbackPrice={{ price: spec?.purchasingCost ?? undefined }}
          />
        </InfoTile>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
          gridTemplateRows: "auto",
          rowGap: 1,
          columnGap: 1,
        }}
      >
        <InfoTile label='Manufacture year' iconOf='data' isEmpty={_.isNil(spec?.manufactureYear)}>
          {spec?.manufactureYear}
        </InfoTile>

        <InfoTile label='Number of seats' iconOf='data' isEmpty={_.isNil(spec?.numberOfSeats)}>
          {spec?.numberOfSeats}
        </InfoTile>

        <InfoTile label='Number of doors' iconOf='data' isEmpty={_.isNil(spec?.numberOfDoors)}>
          {spec?.numberOfDoors}
        </InfoTile>

        <InfoTile label='Boot capacity L' iconOf='data' isEmpty={_.isNil(spec?.bootCapacityL)}>
          {spec?.bootCapacityL}
        </InfoTile>

        <InfoTile label='CO2 emissions g/km' iconOf='data' isEmpty={_.isNil(spec?.cO2EmissionsGKm)}>
          {spec?.cO2EmissionsGKm}
        </InfoTile>

        <InfoTile label='Horsepower' iconOf='data' isEmpty={_.isNil(spec?.horsepower)}>
          {spec?.horsepower}
        </InfoTile>

        <InfoTile
          label='Fuel consumption L/100km'
          iconOf='data'
          isEmpty={_.isNil(spec?.fuelConsumption)}
        >
          {spec?.fuelConsumption?.lPer100Km}
        </InfoTile>

        <InfoTile
          label='Battery capacity kW/h'
          iconOf='data'
          isEmpty={_.isNil(spec?.batteryCapacityKWh)}
        >
          {spec?.batteryCapacityKWh}
        </InfoTile>

        <InfoTile label='Electric range km' iconOf='data' isEmpty={_.isNil(spec?.electricRangeKm)}>
          {spec?.electricRangeKm}
        </InfoTile>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
          gridTemplateRows: "auto",
          rowGap: 1,
          columnGap: 1,
        }}
      >
        <InfoTile
          label='Air conditioner (AC)'
          iconOf='data'
          isEmpty={_.isNil(spec?.hasAirConditioner)}
        >
          <BooleanValue value={spec?.hasAirConditioner} />
        </InfoTile>

        <InfoTile label='Tow hook' iconOf='data' isEmpty={_.isNil(spec?.hasTowHook)}>
          <BooleanValue value={spec?.hasTowHook} />
        </InfoTile>

        <InfoTile label='Tow bar' iconOf='data' isEmpty={_.isNil(spec?.hasTowBar)}>
          <BooleanValue value={spec?.hasTowBar} />
        </InfoTile>

        <InfoTile label='Tow rope' iconOf='data' isEmpty={_.isNil(spec?.hasTowRope)}>
          <BooleanValue value={spec?.hasTowRope} />
        </InfoTile>
      </Box>
    </Stack>
  );
}

import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { enumService } from "@/common/services/enum";
import { apiClient } from "@/core/api/ApiClient";
import { ChatDto, ChatReferenceDto } from "@/core/api/generated";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";

export interface ChatInlineProps extends BaseEntityInlineInheritableProps {
  entity: ChatDto | ChatReferenceDto | null | undefined;
  entityId?: string | null;
}

export default function ChatInline({ entity, entityId, ...otherProps }: ChatInlineProps) {
  const request = useApiRequest(
    apiClient.chatsApi.apiV1ChatsChatIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      chatId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as ChatDto;
  const ref = entity as ChatReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='chat' inText />}
      content={
        <>
          {full?.name || entity?.localNumber} (
          {enumService.getEnumValueName("ChatType", entity?.type)})
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Chat"}
    />
  );
}

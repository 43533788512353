import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { AssetSubscriptionPlanDto } from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AssetSubscriptionPlanAvailabilityDisplay from "@/common/components/Entity/AssetSubscriptionPlan/AssetSubscriptionPlanAvailabilityDisplay";

interface Props {
  assetSubscriptionPlan: AssetSubscriptionPlanDto;
}

export default function AvailabilityTabContent({ assetSubscriptionPlan }: Props) {
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={<PageTabHeader title={"Availability"} />}
      >
        <Stack direction='column' spacing={2}>
          <AssetSubscriptionPlanAvailabilityDisplay
            plan={assetSubscriptionPlan}
            availability={undefined}
            isLoad
          />
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

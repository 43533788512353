import { Box, Button, DialogContent, FormControl, Stack } from "@mui/material";
import { useMemo, useState } from "react";

import LocationAutocompleteOrCreate from "@/common/components/Entity/Location/LocationAutocompleteOrCreate";
import ProductLocationCreateUpdate from "@/common/components/Entity/ProductLocation/ProductLocationCreateUpdate";
import AppModal, { AppModalProps } from "@/common/components/Modals/AppModal";
import AppModalActions from "@/common/components/Modals/AppModalActions";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { ROUTE_PATH } from "@/common/constants/routing";
import { LocationDto, ProductLocationDto } from "@/core/api/generated";
import { useHistory } from "react-router";

interface Props extends Omit<AppModalProps, "children"> {
  sourceLocation: LocationDto | null | undefined;
  isRedirectToViewPageAfterCreate?: boolean;
  onCreate?: (newValue: ProductLocationDto) => void;
}

export default function ProductLocationCreateFromLocationModal({
  sourceLocation,
  isRedirectToViewPageAfterCreate = false,
  onCreate,
  ...modalProps
}: Props) {
  const history = useHistory();

  const [selectedSourceLocation, setSelectedSourceLocation] = useState(sourceLocation);

  const isSourceLocationSelected = useMemo(
    () => !!selectedSourceLocation,
    [selectedSourceLocation],
  );

  const reset = () => {
    setSelectedSourceLocation(undefined);
  };

  return (
    <AppModal
      fullWidth
      maxWidth='md'
      keepMounted={false}
      {...modalProps}
      onClose={(...args) => {
        modalProps?.onClose && modalProps?.onClose(...args);
        reset();
      }}
    >
      <AppModalTitle
        title={"Create sales location from existing location"}
        withCloseIcon
        onCloseClicked={() => {
          modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown");
          reset();
        }}
      />
      <DialogContent>
        {/* 1. Select source Location */}
        {!isSourceLocationSelected && (
          <Stack spacing={2}>
            <Box>
              <FormControl margin='dense' fullWidth>
                <LocationAutocompleteOrCreate
                  autocompleteProps={{
                    fullWidth: true,
                    entityId: selectedSourceLocation?.id,
                    label: "Source location",
                    onChange: (newValue) => setSelectedSourceLocation(newValue),
                  }}
                  createUpdateProps={{
                    defaultValues: {},
                  }}
                  onCreate={(newValue) => {
                    setSelectedSourceLocation(newValue);
                  }}
                />
              </FormControl>
            </Box>

            <AppModalActions
              modalProps={modalProps}
              cancelButton={
                <Button fullWidth variant='outlined' color='secondary'>
                  Cancel
                </Button>
              }
            />
          </Stack>
        )}

        {/* 2. Create ProductLocation */}
        {isSourceLocationSelected && (
          <Stack spacing={2}>
            <Box>
              <ProductLocationCreateUpdate
                defaultValues={{ sourceLocation: selectedSourceLocation }}
                onSave={() => {
                  modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown");
                  reset();
                }}
                onCreate={(newValue) => {
                  onCreate && onCreate(newValue);
                  modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown");
                  if (isRedirectToViewPageAfterCreate) {
                    history.replace(ROUTE_PATH.PRODUCT_SALES_LOCATION_VIEW(newValue.id));
                  }
                }}
              />
            </Box>
          </Stack>
        )}
      </DialogContent>
    </AppModal>
  );
}

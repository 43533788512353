import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import AssetSubscriptionPaginatedList from "@/common/components/Entity/AssetSubscription/ListView/AssetSubscriptionPaginatedList";

export default function AssetSubscriptionsPage() {
  return (
    <>
      <AssetSubscriptionPaginatedList
        displayProps={{
          breadcrumbs: true,
          filters: true,
        }}
      />
    </>
  );
}

import {
  Box,
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { AppPermission, DepartmentDto, UserDto, UserGetPaginatedDto } from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppAvatar from "@/common/components/Avatar/AppAvatar";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { useCallback, useState } from "react";
import DepartmentAddUsersModal from "../../../../../../App/MainAppView/Management/Departments/components/DepartmentAddUsersModal";

interface Props {
  department: DepartmentDto;
}

export default function UsersTabContent({ department }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();
  const { hasPermissions } = useAuthorizationInfo();
  const commonRequestParams = useCommonRequestParams<UserGetPaginatedDto>({});

  const [isAddUsersModalOpen, setIsAddUsersModelOpen] = useState(false);
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);
  const [userToRemove, setUserToRemove] = useState<UserDto | undefined>(undefined);

  const paginatedUsersRequest = useApiRequest(
    apiClient.departmentsApi.apiV1DepartmentsDepartmentIdUsersGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      departmentId: department.id!,
      userGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [department.id, ...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedUsers = paginatedUsersRequest?.data;

  const handleUserRemovalConfirmed = useCallback(
    async (user: UserDto) => {
      try {
        await apiClient.departmentsApi.apiV1DepartmentsDepartmentIdUsersUserIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          departmentId: department!.id!,
          userId: user.id!,
        });
        enqueueSnackbar("User removed from the department.", { variant: "success" });
        paginatedUsersRequest.refetch();
        setIsRemoveUserModalOpen(false);
      } catch (err) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        validation2.hasErrors &&
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      }
    },
    [department],
  );

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Users"}
            primaryAction={
              <AuthorizedElement permissions={[AppPermission.DepartmentManage]}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setIsAddUsersModelOpen(true)}
                >
                  Add users
                </Button>
              </AuthorizedElement>
            }
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <DataTabular
            columns={[
              {
                field: "personName.name",
                title: "Name",
                flex: 1,
                renderCell: (item) => (
                  <Stack direction='row' sx={{ alignItems: "center" }} spacing={1}>
                    <AppAvatar user={item} size='small' />
                    <Stack>
                      <Box>
                        {item.personName?.name}{" "}
                        {profile?.id === item.id && (
                          <Chip
                            size='small'
                            color='primary'
                            variant='filled'
                            label='You'
                            sx={{ ml: 1 }}
                          />
                        )}
                      </Box>
                    </Stack>
                  </Stack>
                ),
              },
              {
                field: "email",
                title: "Email",
                flex: 1,
                renderCell: (item) => <Typography component='div'>{item.email}</Typography>,
              },
              {
                field: "phoneNumber",
                title: "Phone number",
                flex: 1,
                renderCell: (item) => <Typography component='div'>{item.phoneNumber}</Typography>,
              },
            ]}
            isLoading={paginatedUsersRequest.isLoading}
            rows={paginatedUsers?.items}
            getRowId={(item) => item.id!}
            rowTo={
              hasPermissions([AppPermission.FleetAppAccess, AppPermission.TenantRead])
                ? (item) => ROUTE_PATH.USER_VIEW(item.id)
                : undefined
            }
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            refetch={commonRequestParams.dataTabularProps.refetch}
            renderRowAction={(actionParams) => (
              <AuthorizedElement permissions={[AppPermission.DepartmentManage]}>
                <MenuWithTrigger
                  withAuthCloseOnClick
                  trigger={
                    <IconButton>
                      <AppIcon of='moreVert' />
                    </IconButton>
                  }
                >
                  <MenuItem
                    onClick={() => {
                      setUserToRemove(actionParams.item);
                      setIsRemoveUserModalOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <AppIcon of='removePerson' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Remove from department</ListItemText>
                  </MenuItem>
                </MenuWithTrigger>
              </AuthorizedElement>
            )}
          />
        </Stack>

        <DepartmentAddUsersModal
          department={department}
          open={isAddUsersModalOpen}
          onClose={() => {
            setIsAddUsersModelOpen(false);
          }}
          onAdded={() => {
            paginatedUsersRequest.refetch();
          }}
        />

        {/* Confirm user removal */}
        {userToRemove && (
          <ConfirmationModal
            title='Remove user from the department?'
            body={
              <>
                {`You're going to remove user`}{" "}
                <strong>{userToRemove.personName?.name || userToRemove.email}</strong> from the
                department <strong>{department.name}</strong>. {`This action can't be undone.`}
              </>
            }
            open={isRemoveUserModalOpen}
            onClose={() => setIsRemoveUserModalOpen(false)}
            onCancel={() => setIsRemoveUserModalOpen(false)}
            onConfirm={() => handleUserRemovalConfirmed(userToRemove)}
          />
        )}
      </ViewLayout>
    </PageTabContent>
  );
}

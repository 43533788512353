import { useHistory, useParams } from "react-router";

import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import AssetSubscriptionCreateUpdate from "@/common/components/Entity/AssetSubscription/AssetSubscriptionCreateUpdate";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { ROUTE_PATH } from "@/common/constants/routing";

export interface AssetSubscriptionUpdateQueryParams extends GeneralQueryParams {
  planId?: string | null;
}

export default function AssetSubscriptionCreateUpdatePage() {
  const { assetSubscriptionId } = useParams<{ assetSubscriptionId?: string }>();
  const queryParams = useQueryParams<AssetSubscriptionUpdateQueryParams>();
  const history = useHistory();

  const isCreate = !assetSubscriptionId;

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new asset subscription" : "Edit asset subscription"}
        />
      }
    >
      <AssetSubscriptionCreateUpdate
        assetSubscriptionId={assetSubscriptionId}
        defaultValues={{
          ...queryParams,
        }}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.ASSET_SUBSCRIPTION_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}

import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";

import { DatetimeHelper } from "@/common/helpers/datetime";
import { NegotiationHelper } from "@/common/helpers/entity/negotiation";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { ValidationHelper } from "@/common/validation";
import {
  AppPermission,
  ConsensusType,
  NegotiationAllowedActionsDto,
  NegotiationDto,
  NegotiationProposalDto,
  NegotiationProposalResponseDto,
  UserPartiesMembershipDto,
} from "@/core/api/generated";
import * as negotiationsSlice from "@/store/communication/negotiationsSlice";

import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import AuthorizedElement from "../../../Auth/AuthorizedElement";
import AppAvatar from "../../../Avatar/AppAvatar";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import FieldValue from "../../../Form/Display/FieldValue";
import ApiEnumIcon from "../../../Icons/ApiEnumIcon";
import AppIcon from "../../../Icons/AppIcon";
import GeneralScopeLink from "../../../Link/GeneralScopeLink";
import MenuWithTrigger from "../../../Menu/MenuWithTrigger";
import AppModalTitle from "../../../Modals/AppModalTitle";
import GeneralScopeDisplayModal from "../../General/Display/GeneralScopeDisplayModal";
import GeneralEventLogsModal from "../../GeneralEventLog/GeneralEventLogsModal";
import PartyInfoModalWithTrigger from "../../Party/PartyInfoModalWithTrigger";
import PartyInline from "../../Party/PartyInline";
import PartyMemberNameDisplay from "../../PartyMember/PartyMemberNameDisplay";
import NegotiationLink from "../NegotiationLink";
import NegotiationProposalValueDetailedDisplayed from "./NegotiationProposalValueDetailedDisplayed";

interface OwnProps {
  negotiation?: NegotiationDto;
  proposal: NegotiationProposalDto;
  allowedActions: NegotiationAllowedActionsDto;
  partiesMembership?: UserPartiesMembershipDto | null;
}

type Props = OwnProps & DialogProps;

function NegotiationProposalDetailsModal({
  negotiation,
  proposal,
  allowedActions,
  partiesMembership,
  ...dialogProps
}: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const thunkDispatch = useAppThunkDispatch();
  const profile = useUserProfile();

  const [isRespondingWithAgreeMap, setIsRespondingWithAgreeMap] = useState<Record<string, boolean>>(
    {},
  ); // responseId: boolean
  const [isRespondingWithDisagreeMap, setIsRespondingWithDisagreeMap] = useState<
    Record<string, boolean>
  >({}); // responseId: boolean

  const [isEventLogModalOpen, setIsEventLogModalOpen] = useState(false);
  const [isViewScopeModalOpen, setIsViewScopeModalOpen] = useState(false);

  const isResponded = useMemo(
    () => NegotiationHelper.isRespondedOnProposal(proposal, partiesMembership),
    [partiesMembership, proposal],
  );
  const canAgreeOrDisagreeWithResponse =
    !isResponded &&
    NegotiationHelper.canRespondOnProposal({
      proposal,
      allowedActions,
      partiesMembership,
    });

  const agreeWithResponse = async (response: NegotiationProposalResponseDto) => {
    try {
      setIsRespondingWithAgreeMap({
        ...isRespondingWithAgreeMap,
        [response.id!]: true,
      });
      await thunkDispatch(
        negotiationsSlice.respondOnNegotiationProposalWithAgree!({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          negotiationId: negotiation!.id!,
          agreeOrDisagreeWithNegotiationProposalResponseDto: {
            proposalId: proposal?.id,
            responseId: response.id,
          },
        }),
      );
      enqueueSnackbar("Your response saved.", { variant: "success" });
    } catch (err) {
      const validation = ValidationHelper.handleApiErrorResponse(err);
      if (validation.hasErrors) {
        enqueueSnackbar(
          `Can't save your response. Error occurred: ${validation.getErrorsAsString()}`,
          {
            variant: "error",
          },
        );
      }
    } finally {
      setIsRespondingWithAgreeMap({
        ...isRespondingWithAgreeMap,
        [response.id!]: false,
      });
    }
  };

  const disagreeWithResponse = async (response: NegotiationProposalResponseDto) => {
    try {
      setIsRespondingWithDisagreeMap({
        ...isRespondingWithDisagreeMap,
        [response.id!]: true,
      });
      await thunkDispatch(
        negotiationsSlice.respondOnNegotiationProposalWithDisagree!({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          negotiationId: negotiation!.id!,
          agreeOrDisagreeWithNegotiationProposalResponseDto: {
            proposalId: proposal?.id,
            responseId: response.id,
          },
        }),
      );
      enqueueSnackbar("Your response saved.", { variant: "success" });
    } catch (err) {
      const validation = ValidationHelper.handleApiErrorResponse(err);
      if (validation.hasErrors) {
        enqueueSnackbar(
          `Can't save your response. Error occurred: ${validation.getErrorsAsString()}`,
          {
            variant: "error",
          },
        );
      }
    } finally {
      setIsRespondingWithDisagreeMap({
        ...isRespondingWithDisagreeMap,
        [response.id!]: false,
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        <Stack direction='row' spacing={1} sx={{ alignItems: "flex-start" }}>
          <Box>
            <Typography component='div' variant='h4'>
              Negotiation proposal
            </Typography>
            {proposal.createdAt && (
              <Typography component='div' variant='caption' color='text.secondary'>
                Opened {DatetimeHelper.humanizeDateRangeDurationFromNow(proposal.createdAt)}
              </Typography>
            )}
            {proposal.closedAt && (
              <Typography component='div' variant='caption' color='text.secondary'>
                Closed {DatetimeHelper.humanizeDateRangeDurationFromNow(proposal.closedAt)}
              </Typography>
            )}
          </Box>

          <Stack direction='row' spacing={1} sx={{ flex: 1, justifyContent: "flex-end" }}>
            <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
              <MenuWithTrigger
                trigger={
                  <IconButton edge='end' sx={{ ml: "auto" }}>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
                withAuthCloseOnClick
              >
                <MenuItem
                  onClick={() => {
                    setIsEventLogModalOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='history' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Event log</ListItemText>
                </MenuItem>
              </MenuWithTrigger>
            </AuthorizedElement>
          </Stack>
        </Stack>
      </AppModalTitle>

      <DialogContent>
        <Stack direction='column' spacing={2}>
          {proposal.consensusType === ConsensusType.Consensus && (
            <Alert sx={{ mb: 1 }} severity='success'>
              Consensus - all responses match.
            </Alert>
          )}
          {proposal.consensusType === ConsensusType.ForcedConsensus && (
            <Alert sx={{ mb: 1 }} severity='info'>
              Forced consensus - authorized party resolved this proposal.
            </Alert>
          )}
          {proposal.consensusType === ConsensusType.NoConsensus && (
            <Alert
              sx={{ mb: 1 }}
              severity='warning'
            >{`No consensus - responses don't match.`}</Alert>
          )}

          <Stack direction='column' spacing={1}>
            <FieldValue label='Negotiation'>
              <NegotiationLink entity={negotiation} />
            </FieldValue>

            {proposal.scope && (
              <FieldValue
                label='Scope'
                helperTooltip='Scope of the negotiation - is an entity to which this negotiation relates. Results of the negotiation can be applied to the scope.'
              >
                <GeneralScopeLink
                  scope={proposal.scope}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsViewScopeModalOpen(true);
                  }}
                />
              </FieldValue>
            )}

            <FieldValue
              label='Party'
              helperTooltip='Party that created the proposal'
              isEmpty={!proposal.party}
            >
              <PartyInfoModalWithTrigger
                party={proposal.party}
                trigger={<PartyInline entity={proposal.party} />}
              />
            </FieldValue>

            <FieldValue
              label='Party member'
              helperTooltip='Party member who created the proposal'
              isEmpty={!proposal.partyMember}
            >
              <PartyMemberNameDisplay party={proposal.party} partyMember={proposal.partyMember} />
            </FieldValue>

            <FieldValue label='Statement' isEmpty={!proposal.party}>
              {proposal.statement}
            </FieldValue>

            <FieldValue label='Initial'>
              <BooleanValue value={proposal.isInitial} />
            </FieldValue>

            <FieldValue label='Status' isEmpty={!proposal.status}>
              <InlineApiEnumValue type='ProposalStatus' value={proposal.status} withHelperTooltip />
            </FieldValue>

            <FieldValue label='Consensus' isEmpty={!proposal.consensusType}>
              <InlineApiEnumValue
                type='ConsensusType'
                value={proposal.consensusType}
                withHelperTooltip
              />{" "}
              <ApiEnumIcon
                type='ConsensusType'
                value={proposal.consensusType}
                fontSize='medium'
                inText
              />
            </FieldValue>

            <FieldValue label='Approval status' isEmpty={!proposal.approvalStatus}>
              <InlineApiEnumValue
                type='ApprovalStatus'
                value={proposal.approvalStatus}
                withHelperTooltip
              />{" "}
              <ApiEnumIcon
                type='ApprovalStatus'
                value={proposal.approvalStatus}
                fontSize='medium'
                inText
              />
            </FieldValue>
          </Stack>

          {negotiation && proposal && proposal.value && (
            <Stack spacing={1}>
              <Typography component='div' variant='h4'>
                Value
              </Typography>

              <NegotiationProposalValueDetailedDisplayed
                negotiation={negotiation}
                proposal={proposal}
              />
            </Stack>
          )}

          <Stack spacing={1}>
            <Typography component='div' variant='h4'>
              Responses
            </Typography>
            {proposal.responses!.length === 0 && (
              <Typography component='div' variant='body2'>
                No responses yet.
              </Typography>
            )}
            {proposal.responses!.length !== 0 && (
              <>
                <List dense>
                  {proposal.responses!.map((response) => {
                    const isCurrentUser = profile && response?.partyMember?.userId === profile?.id;

                    return (
                      <ListItem
                        key={response.id}
                        secondaryAction={
                          canAgreeOrDisagreeWithResponse && (
                            <Stack direction='row' spacing={0}>
                              <Tooltip title='Agree with the response (give the same response)'>
                                <LoadingButton
                                  color='secondary'
                                  loading={isRespondingWithAgreeMap[response.id!]}
                                  onClick={() => agreeWithResponse(response)}
                                >
                                  <AppIcon of='agree' fontSize='medium' />
                                </LoadingButton>
                              </Tooltip>
                              <Tooltip title='Disagree with the response (give the opposite response)'>
                                <LoadingButton
                                  color='secondary'
                                  loading={isRespondingWithDisagreeMap[response.id!]}
                                  onClick={() => disagreeWithResponse(response)}
                                >
                                  <AppIcon of='disagree' fontSize='medium' />
                                </LoadingButton>
                              </Tooltip>
                            </Stack>
                          )
                        }
                      >
                        <ListItemAvatar>
                          <AppAvatar party={response.party} partyMember={response.partyMember} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <PartyMemberNameDisplay
                              party={response.party!}
                              partyMember={response.partyMember}
                            />
                          }
                          secondary={
                            <Stack component='span'>
                              <Typography component='span' variant='body2' color='text.secondary'>
                                <InlineApiEnumValue
                                  type='ApprovalResponseType'
                                  value={response.responseType}
                                />{" "}
                                <ApiEnumIcon
                                  type='ApprovalResponseType'
                                  value={response.responseType}
                                  inText
                                />
                              </Typography>
                            </Stack>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </>
            )}
          </Stack>
        </Stack>

        {/* Event log */}
        <GeneralEventLogsModal
          open={isEventLogModalOpen}
          onClose={() => setIsEventLogModalOpen(false)}
          entityId={proposal.id!}
        />

        {/* GeneralScope */}
        {proposal.scope && (
          <GeneralScopeDisplayModal
            open={isViewScopeModalOpen}
            onClose={() => {
              setIsViewScopeModalOpen(false);
            }}
            scope={proposal.scope}
            title='Negotiation scope'
            description='This is the scope of the negotiation'
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default NegotiationProposalDetailsModal;

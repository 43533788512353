import { Box, Chip, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitiesSearchAutocomplete, {
  InheritableBaseEntitiesSearchAutocompleteProps,
} from "@/common/components/Entity/components/BaseEntitiesSearchAutocomplete";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import {
  AdminVehiclePartTypeDto,
  VehiclePartTypeDto,
  VehiclePartTypeSearchPaginatedDto,
} from "@/core/api/generated";

type VehiclePartTypeAutocompleteOption = BaseAutocompleteOption<AdminVehiclePartTypeDto>;

const vehiclePartTypeToOption = (
  data: AdminVehiclePartTypeDto,
): VehiclePartTypeAutocompleteOption => ({
  id: data.id!,
  title: StringHelper.joinIntoString([data.name], ", ", {
    skipEmpty: true,
  }),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface VehiclePartTypesAutocompleteProps
  extends InheritableBaseEntitiesSearchAutocompleteProps<VehiclePartTypeDto> {
  searchFilters?: Partial<Omit<VehiclePartTypeSearchPaginatedDto, "search" | "includeIds">>;
}

export default function VehiclePartTypesAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: VehiclePartTypesAutocompleteProps) {
  return (
    <BaseEntitiesSearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={vehiclePartTypeToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.vehiclePartTypesApi.apiV1ReferenceDataVehiclesPartTypesSearchPost,
        parameters: {
          vehiclePartTypeSearchPaginatedDto: {
            limit: 25,
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          vehiclePartTypeSearchPaginatedDto: {
            ...params.vehiclePartTypeSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Vehicle part type'
      placeholder='Search...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='vehiclePartType' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                </>
              }
              secondary={
                <>
                  <Typography variant='body2' color='secondary'>
                    {option.data?.description}
                  </Typography>
                  <Box>
                    {option.data?.areas?.map((area, i) => (
                      <Chip
                        key={i}
                        size='small'
                        color='secondary'
                        variant='outlined'
                        label={<InlineApiEnumValue type='VehicleArea' value={area} />}
                        sx={{ mr: 1 }}
                      />
                    ))}
                  </Box>
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

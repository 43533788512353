import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import {
  TaxType,
  TaxValueType,
  TenantSettingsDto,
  TenantSettingsInputDto,
} from "@/core/api/generated";

import FoldableBlock from "@/common/components/Display/FoldableBlock";
import GeneralTaxInput from "@/common/components/Entity/General/GeneralTax/GeneralTaxInput";
import CountryAutocomplete from "@/common/components/Entity/ReferenceData/CountryAutocomplete";
import CurrencyAutocomplete from "@/common/components/Entity/ReferenceData/CurrencyAutocomplete";
import TimezoneAutocomplete from "@/common/components/Entity/ReferenceData/TimezoneAutocomplete";
import TenantVehicleTaxSettingsInput from "@/common/components/Entity/Tenant/TenantVehicleTaxSettingsInput";
import VisualInspectionSettingsInput from "@/common/components/Entity/VisualInspection/VisualInspectionSettingsInput";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { FormikHelper } from "@/common/helpers/formik";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppDispatch, useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import * as tenantProfileSlice from "@/store/tenantProfile/slice";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import * as Yup from "yup";

interface Props {
  tenantSettings: TenantSettingsDto;
}

export default function MainSettingsTabContent({ tenantSettings }: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();

  useEffect(() => {
    thunkDispatch(
      tenantProfileSlice.getTenantSettings({ nexusOpsTenant: EMPTY_TENANT_IDENTIFIER }),
    );
    return () => {
      dispatch(tenantProfileSlice.resetTenantSettings());
    };
  }, []);

  return (
    <PageTabContent>
      <Formik<TenantSettingsInputDto & BaseFormikValues>
        initialValues={{
          country: tenantSettings?.country || undefined,
          currency: tenantSettings?.currency || undefined,
          tax: tenantSettings?.tax || undefined,
          tzId: tenantSettings?.tzId || undefined,
          isEnableTenantToTenantConnections:
            tenantSettings?.isEnableTenantToTenantConnections ?? undefined,
          visualInspection: tenantSettings?.visualInspection ?? undefined,
          vehicleTax: tenantSettings?.vehicleTax ?? undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // name: Yup.string().required("This field is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            await thunkDispatch(
              tenantProfileSlice.updateTenantSettings({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                tenantSettingsInputDto: {
                  ..._.omit(values, "submit"),
                },
              }),
            );
            enqueueSnackbar("Settings updated.", { variant: "success" });

            if (mounted.current) {
              setStatus({ success: true });
            }
          } catch (err) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {(formikProps) => {
          const {
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
          } = formikProps;

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack direction='column' spacing={2}>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={1}
                  sx={{ alignItems: "center", mt: 2, flex: 1 }}
                >
                  <FormControl fullWidth sx={{ flexGrow: 1 }} error={Boolean(errors.country)}>
                    <CountryAutocomplete
                      required
                      entity={values.country}
                      onChange={(newValue) => setFieldValue("country", newValue)}
                    />
                    <FormHelperText error>
                      {ValidationHelper.getFormikErrorsAsString(errors.country)}
                    </FormHelperText>
                  </FormControl>

                  <FormControl fullWidth sx={{ flexGrow: 1 }} error={Boolean(errors.currency)}>
                    <CurrencyAutocomplete
                      required
                      entity={values.currency}
                      onChange={(newValue) => setFieldValue("currency", newValue)}
                    />
                    <FormHelperText error>
                      {ValidationHelper.getFormikErrorsAsString(errors.currency)}
                    </FormHelperText>
                  </FormControl>
                </Stack>

                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={1}
                  sx={{ alignItems: "center", mt: 2 }}
                >
                  <FormControl
                    fullWidth
                    sx={{ flexGrow: 1 }}
                    error={Boolean(touched.tzId && errors.tzId)}
                  >
                    <TimezoneAutocomplete
                      tzId={values.tzId}
                      required
                      onChange={(newValue) => setFieldValue("tzId", newValue?.name)}
                    />
                    <FormHelperText error>
                      {ValidationHelper.getFormikErrorsAsString(errors.tzId)}
                    </FormHelperText>
                  </FormControl>

                  <FormControl
                    fullWidth
                    sx={{ flexGrow: 1 }}
                    error={Boolean(touched.tax && errors.tax)}
                  >
                    <GeneralTaxInput
                      error={Boolean(touched.tax && errors.tax)}
                      helperText={
                        errors.tax && ValidationHelper.getFormikErrorsAsString(errors.tax)
                      }
                      allowTypeChange
                      allowValueTypeChange
                      defaultType={TaxType.Vat}
                      defaultValueType={TaxValueType.Percent}
                      value={values.tax}
                      appliesTo={undefined}
                      label='Tax'
                      onChange={(newValue) => {
                        setFieldValue(`tax`, newValue);
                      }}
                    />
                    <FormHelperText error>
                      {touched.tax &&
                        errors.tax &&
                        ValidationHelper.getFormikErrorsAsString(errors.tax)}
                    </FormHelperText>
                  </FormControl>
                </Stack>

                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isEnableTenantToTenantConnections'
                        checked={values.isEnableTenantToTenantConnections}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label='Enable Company-to-Company connections'
                  />
                  <FormHelperText>
                    By enabling this option you allow other companies in the system to connect and
                    collaborate with your company.
                  </FormHelperText>
                </FormControl>

                {/* VisualInspection */}
                <FoldableBlock
                  defaultIsFolded
                  trigger={{
                    label: <Typography variant='h6'>Visual inspection settings</Typography>,
                  }}
                >
                  <VisualInspectionSettingsInput
                    formikProps={FormikHelper.getSubProps(
                      formikProps,
                      "visualInspection",
                      (x) => x.visualInspection,
                    )}
                  />
                </FoldableBlock>

                {/* VehicleTax */}
                <FoldableBlock
                  defaultIsFolded
                  trigger={{
                    label: <Typography variant='h6'> Vehicle tax</Typography>,
                  }}
                >
                  <TenantVehicleTaxSettingsInput
                    formikProps={FormikHelper.getSubProps(
                      formikProps,
                      "vehicleTax",
                      (x) => x.vehicleTax,
                    )}
                    defaultCountry={values.country}
                    defaultCurrency={values.currency}
                  />
                </FoldableBlock>
              </Stack>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xxs: 1, md: 2 }, mb: 2 }}
                color='primary'
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </form>
          );
        }}
      </Formik>
    </PageTabContent>
  );
}

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AppTooltip from "@/common/components/AppTooltip";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import Datetime from "@/common/components/Datetime/Datetime";
import DatetimeRange from "@/common/components/Datetime/DatetimeRange";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName, enumService } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  AssetSubscriptionDto,
  AssetSubscriptionGetPaginatedDto,
  ContractProductsSubscriptionsStateFilterType,
  DataUpdatesHubClientMethodName,
  EntityType,
  PaginationDtoOfAssetSubscriptionDto,
} from "@/core/api/generated";
import { Button, Stack, SxProps, Theme } from "@mui/material";
import { Box } from "@mui/system";
import { Link as RouterLink, useHistory } from "react-router-dom";
import DataTabular, { TabularProps } from "../../../DataTabular/DataTabular";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import AppIcon from "../../../Icons/AppIcon";
import AssetInline from "../../Asset/AssetInline";
import AssetSubscriptionPlanInline from "../../AssetSubscriptionPlan/AssetSubscriptionPlanInline";
import CustomerInline from "../../Customer/CustomerInline";
import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralCurrencyDisplay from "../../General/Display/GeneralCurrencyDisplay";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import ProductLocationInline from "../../ProductLocation/ProductLocationInline";
import AssetSubscriptionMenu from "../AssetSubscriptionMenu";
import AssetSubscriptionsDeleteModal from "../AssetSubscriptionsDeleteModal";

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  Delete = "Delete",
}
const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type AssetSubscriptionItemPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface AssetSubscriptionPaginatedListOwnProps {
  shouldFetch?: boolean;
  paginated?: PaginationDtoOfAssetSubscriptionDto | null;
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
    planId?: string | null;
    customerId?: string | null;
    contractId?: string | null;
    assetId?: string | null;
  };
  propagatedDeps?: PropagatedDeps<AssetSubscriptionItemPaginatedListPropagatedDepsMap>;
  dataTabularProps?: Partial<TabularProps<AssetSubscriptionDto>>;
  sx?: SxProps<Theme>;
}

export type AssetSubscriptionPaginatedListProps = AssetSubscriptionPaginatedListOwnProps;

export default function AssetSubscriptionPaginatedList({
  shouldFetch = true,
  paginated,
  displayProps,
  defaultValues,
  propagatedDeps,
  dataTabularProps,
  sx,
}: AssetSubscriptionPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  const history = useHistory();
  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<AssetSubscriptionGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.AssetSubscription,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        filterType: ContractProductsSubscriptionsStateFilterType.All,
        ...defaultValues,
      },
    },
  });

  const paginatedAssetSubscriptionsRequest = useApiRequest(
    apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      skip: !shouldFetch,
      deps: [...commonRequestParams.deps, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedAssetSubscriptions = paginated || paginatedAssetSubscriptionsRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.AssetSubscription),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedAssetSubscriptionsRequest.handleEntityChanged(data);
    },
  });

  return (
    <Box>
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps?.header && (
            <SimpleViewPageHeader
              title={undefined}
              primaryActions={
                displayProps?.create && (
                  <AuthorizedElement permissionsAny={[AppPermission.AssetSubscriptionManage]}>
                    <Button
                      variant='contained'
                      color='primary'
                      startIcon={<AppIcon of='add' />}
                      component={RouterLink}
                      to={ROUTE_PATH.ASSET_SUBSCRIPTION_CREATE()}
                    >
                      Create new asset subscription
                    </Button>
                  </AuthorizedElement>
                )
              }
            />
          )
        }
      >
        <Stack direction='column' spacing={1}>
          <DataTabular
            tabs={{
              value: commonRequestParams.params?.filterType,
              onChange: (e, newValue) => commonRequestParams.setOneParam2({ filterType: newValue }),
              tabs: enumService
                .getEnumValues("ContractProductsSubscriptionsStateFilterType", {
                  except: [ContractProductsSubscriptionsStateFilterType.None],
                })
                .map((value) => ({
                  label: enumService.getEnumValueName(
                    "ContractProductsSubscriptionsStateFilterType",
                    value,
                  ),
                  value,
                })),
            }}
            columns={[
              {
                field: getTypedPath<AssetSubscriptionDto>().id.$path,
                title: "ID",
                width: 250,
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.id,
                filters: {
                  fieldType: FilterFieldType.Id,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().localNumber.$path,
                title: "Number",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <Stack direction='row' spacing={0.5}>
                    <Stack direction='column' alignItems='flex-start'>
                      <Box>{item.localNumber}</Box>
                      {item.externalNumber && (
                        <AppTooltip variant='helpText' title='External number'>
                          <AppTypography
                            ellipsing={{ enabled: true }}
                            component='div'
                            variant='body2'
                          >
                            ({item.externalNumber})
                          </AppTypography>
                        </AppTooltip>
                      )}
                    </Stack>
                    <EntityChipList entity={item} variant='compact' />
                  </Stack>
                ),
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().externalNumber.$path,
                title: "External number",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.externalNumber || "-",
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().plan.id.$path,
                title: "Plan",
                width: 300,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => <AssetSubscriptionPlanInline entity={item.plan} />,
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfAssetSubscriptionPlan(),
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().contract.id.$path,
                title: "Contract",
                width: 300,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => <CustomerInline entity={item.customer} />,
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfContract(),
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().customer.id.$path,
                title: "Customer",
                width: 300,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => <CustomerInline entity={item.customer} />,
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfCustomer(),
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().asset?.asset.id.$path,
                title: "Asset",
                width: 300,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => <AssetInline entity={item.asset?.asset} />,
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfCustomer(),
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().asset?.entityType.$path,
                title: "Asset type",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <InlineApiEnumValue type='AssetEntityType' value={item.asset?.entityType} />
                ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.AssetEntityType,
                    },
                  },
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().productLocationId.$path,
                title: "Sales location",
                width: 300,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => (
                  <ProductLocationInline entity={undefined} entityId={item.productLocationId} />
                ),
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfProductLocation(),
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().status.$path,
                title: "Status",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <InlineApiEnumValue type='SubscriptionStatus' value={item.status} />
                ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.SubscriptionStatus,
                    },
                  },
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().allocationStatus.$path,
                title: "Allocation status",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <InlineApiEnumValue type='AllocationStatus' value={item.allocationStatus} />
                ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.SubscriptionStatus,
                    },
                  },
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().currency.code.$path,
                title: "Currency",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.currency ? <GeneralCurrencyDisplay currency={item.currency} /> : "-",
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.CurrencyCode,
                    },
                  },
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().basePrice.total.$path,
                title: "Base price",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <AppPopover
                    hoverBehavior={{}}
                    trigger={
                      <AppTypography decoration={{ variant: "helpText" }}>
                        <CurrencyValue value={item.basePrice?.total} currency={item.currency} />
                      </AppTypography>
                    }
                  >
                    <AppPopoverContent>
                      <GeneralPriceSummaryDisplay
                        direction='column'
                        summary={{
                          currency: item.currency,
                          subTotal: item.basePrice?.subTotal,
                          subTotalIncDiscount: item.basePrice?.subTotalIncDiscount,
                          discount: item.basePrice?.discount || undefined,
                          tax: item.basePrice?.tax || undefined,
                          insurance: item.basePrice?.insurance || undefined,
                          total: item.basePrice?.total || 0,
                        }}
                        displayProps={{
                          subTotal: true,
                          subTotalIncDiscount: true,
                          discount: true,
                          tax: true,
                          insurance: true,
                          total: true,
                        }}
                      />
                    </AppPopoverContent>
                  </AppPopover>
                ),
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().price.total.$path,
                title: "Price",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <AppPopover
                    hoverBehavior={{}}
                    trigger={
                      <AppTypography decoration={{ variant: "helpText" }}>
                        <CurrencyValue value={item.price?.total} currency={item.currency} />
                      </AppTypography>
                    }
                  >
                    <AppPopoverContent>
                      <GeneralPriceSummaryDisplay
                        direction='column'
                        summary={{
                          currency: item.currency,
                          subTotal: item.price?.subTotal,
                          subTotalIncDiscount: item.price?.subTotalIncDiscount,
                          discount: item.price?.discount || undefined,
                          tax: item.price?.tax || undefined,
                          insurance: item.price?.insurance || undefined,
                          total: item.price?.total || 0,
                        }}
                        displayProps={{
                          subTotal: true,
                          subTotalIncDiscount: true,
                          discount: true,
                          tax: true,
                          insurance: true,
                          total: true,
                        }}
                      />
                    </AppPopoverContent>
                  </AppPopover>
                ),
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().extraOptions.$path,
                title: "Extra options",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => <TableCellContentOfCount count={item.extraOptions?.length} />,
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().startsAt.$path,
                title: "Starts at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.startsAt ? <Datetime datetime={item.startsAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().endsAt.$path,
                title: "Ends at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.endsAt ? <Datetime datetime={item.endsAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: "duration",
                title: "Duration",
                width: 100,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => (
                  <DatetimeRange
                    direction='column'
                    variant='compact'
                    range={{
                      startsAt: item.startsAt,
                      endsAt: item.endsAt,
                    }}
                    withDuration
                  />
                ),
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().createdAt.$path,
                title: "Created at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().updatedAt.$path,
                title: "Updated at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().createdBy.$path,
                title: "Created by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<AssetSubscriptionDto>().updatedBy.$path,
                title: "Updated by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: "affiliation",
                title: "Affiliation",
                description: "Affiliation to departments & locations",
                width: 120,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                isToDisabled: true,
                renderCell: (item) => (
                  <AffiliationInfoDisplay
                    variant='compact'
                    tenantId={item.tenantId}
                    departmentIds={item.departmentIds}
                    locationIds={item.locationIds}
                  />
                ),
              },
            ]}
            isLoading={paginatedAssetSubscriptionsRequest.isLoading}
            rows={paginatedAssetSubscriptions?.items}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.ASSET_SUBSCRIPTION_VIEW(item.id)}
            renderRowAction={({ item }) => (
              <AssetSubscriptionMenu
                entity={item}
                onDelete={() => paginatedAssetSubscriptionsRequest.refetch()}
                onUpdate={() => paginatedAssetSubscriptionsRequest.refetch()}
                displayProps={{
                  actions: {
                    edit: displayProps?.edit || false,
                    delete: displayProps?.delete || false,
                    eventLog: false,
                  },
                }}
              />
            )}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            refetch={commonRequestParams.dataTabularProps.refetch}
            bulkActions={{
              enabled: true,
              definition: BulkActionFlags,
              actionTriggers: ({ currentAction, startAction, selectedIds }) => (
                <>
                  <AppIconButton
                    tooltipProps={{ title: "Update affiliation" }}
                    onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
                  >
                    <AppIcon of='department' />
                  </AppIconButton>

                  <AppIconButton
                    tooltipProps={{ title: "Delete" }}
                    onClick={() => startAction(BulkActionFlags.Delete)}
                  >
                    <AppIcon of='delete' />
                  </AppIconButton>
                </>
              ),
              actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => (
                <>
                  <AssetSubscriptionsDeleteModal
                    entities={EntityHelper.filterEntitiesByIds(
                      paginatedAssetSubscriptions?.items || [],
                      selectedIds as string[],
                    )}
                    open={currentAction === BulkActionFlags.Delete}
                    onClose={() => cancelAction()}
                    onDelete={() => {
                      completeAction();
                      paginatedAssetSubscriptionsRequest.refetch();
                    }}
                  />
                  <TenantStructureMembershipOfEntityEditModal
                    open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                    onClose={() => cancelAction()}
                    tenantStructureMembershipOfEntityEditProps={{
                      entityType: EntityType.AssetSubscription,
                      entities: EntityHelper.filterEntitiesByIds(
                        paginatedAssetSubscriptions?.items || [],
                        selectedIds as string[],
                      ),
                      onSave: () => {
                        completeAction();
                        paginatedAssetSubscriptionsRequest.refetch();
                      },
                    }}
                  />
                </>
              ),
            }}
            {...dataTabularProps}
          />
        </Stack>
      </ViewLayout>
    </Box>
  );
}

import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

// Figma name: Icon/Outline/close-circle
export default forwardRef<SVGSVGElement, SvgIconProps>(function CancelCircleOutlineIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      color='secondary'
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.707 9.29303C14.316 8.90203 13.684 8.90203 13.293 9.29303L12 10.586L10.707 9.29303C10.316 8.90203 9.684 8.90203 9.293 9.29303C8.902 9.68403 8.902 10.316 9.293 10.707L10.586 12L9.293 13.293C8.902 13.684 8.902 14.316 9.293 14.707C9.488 14.902 9.744 15 10 15C10.256 15 10.512 14.902 10.707 14.707L12 13.414L13.293 14.707C13.488 14.902 13.744 15 14 15C14.256 15 14.512 14.902 14.707 14.707C15.098 14.316 15.098 13.684 14.707 13.293L13.414 12L14.707 10.707C15.098 10.316 15.098 9.68403 14.707 9.29303ZM12 20C7.589 20 4 16.411 4 12C4 7.58903 7.589 4.00003 12 4.00003C16.411 4.00003 20 7.58903 20 12C20 16.411 16.411 20 12 20ZM12 2.00003C6.486 2.00003 2 6.48603 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.48603 17.514 2.00003 12 2.00003Z'
      />
      <mask id='mask0_1_408' maskUnits='userSpaceOnUse' x='2' y='2' width='20' height='20'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.707 9.29303C14.316 8.90203 13.684 8.90203 13.293 9.29303L12 10.586L10.707 9.29303C10.316 8.90203 9.684 8.90203 9.293 9.29303C8.902 9.68403 8.902 10.316 9.293 10.707L10.586 12L9.293 13.293C8.902 13.684 8.902 14.316 9.293 14.707C9.488 14.902 9.744 15 10 15C10.256 15 10.512 14.902 10.707 14.707L12 13.414L13.293 14.707C13.488 14.902 13.744 15 14 15C14.256 15 14.512 14.902 14.707 14.707C15.098 14.316 15.098 13.684 14.707 13.293L13.414 12L14.707 10.707C15.098 10.316 15.098 9.68403 14.707 9.29303ZM12 20C7.589 20 4 16.411 4 12C4 7.58903 7.589 4.00003 12 4.00003C16.411 4.00003 20 7.58903 20 12C20 16.411 16.411 20 12 20ZM12 2.00003C6.486 2.00003 2 6.48603 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.48603 17.514 2.00003 12 2.00003Z'
        />
      </mask>
      <g mask='url(#mask0_1_408)'>
        <rect y='3.05176e-05' width='24' height='24' />
      </g>
    </SvgIcon>
  );
});

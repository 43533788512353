import { Box, SxProps, Theme, Typography } from "@mui/material";

import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import {
  CurrencyCode,
  DiscountValueType,
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  GeneralDiscountDto,
  GeneralDiscountInputDto,
} from "@/core/api/generated";

import _ from "lodash";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import CurrencyValue from "../../../Form/Display/CurrencyValue";
import PercentValue from "../../../Form/Display/PercentValue";

interface Props {
  discount?: GeneralDiscountDto | GeneralDiscountInputDto | null;
  detailsPlacement?: "inline" | "tooltip";
  currency?: GeneralCurrencyDto | GeneralCurrencyInputDto | null;
  currencyCode?: CurrencyCode | null;
  sx?: SxProps<Theme>;
}

export default function GeneralDiscountDisplay({
  discount,
  detailsPlacement = undefined,
  currency,
  currencyCode,
  sx,
}: Props) {
  const currentCurrency = useCurrentCurrency();
  const currencyComputed: GeneralCurrencyDto | undefined =
    currency || (currencyCode && { code: currencyCode }) || discount?.currency || currentCurrency;
  const isEmpty = !discount || (_.isNil(discount?.percent) && _.isNil(discount?.value));

  return (
    <Box component='span' sx={sx}>
      {isEmpty && `-`}

      {!isEmpty && (
        <>
          {discount?.valueType === DiscountValueType.Percent && (
            <PercentValue value={discount?.percent} />
          )}
          {discount?.valueType === DiscountValueType.Value && (
            <CurrencyValue value={discount?.value} currency={currencyComputed} />
          )}
          {detailsPlacement === "inline" && (
            <Typography component='span' variant='caption'>
              {" "}
              (<InlineApiEnumValue type='DiscountType' value={discount?.type} />)
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}

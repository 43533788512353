import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import { useCallback, useMemo } from "react";

import { ApiEnumName, ApiEnumValue, enumService } from "@/common/services/enum";
import InlineApiEnumValue, { InlineApiEnumValueProps } from "./InlineApiEnumValue";

interface ApiEnumMultiselectProps<TEnumName extends ApiEnumName> {
  type: TEnumName;
  values?: Array<ApiEnumValue<TEnumName>> | null;
  size?: SelectProps["size"];
  disabled?: boolean;
  disabledValues?: Array<ApiEnumValue<TEnumName>> | null;
  required?: boolean;
  fullWidth?: boolean;
  allowNone?: boolean;
  label?: string;
  selectProps?: SelectProps<Array<ApiEnumValue<TEnumName>>>;
  optionProps?: Partial<Pick<InlineApiEnumValueProps<any>, "direction" | "withDescription">>;
  error?: boolean;
  errorHelperText?: string;
  helperText?: string;
  onChange?: (newValue?: Array<ApiEnumValue<TEnumName>>) => void;
}

/** Multi select for enums generated from Open API spec of WebApi. */
export default function ApiEnumMultiselect<TEnumName extends ApiEnumName>({
  type,
  values,
  size,
  disabled,
  disabledValues,
  required,
  fullWidth,
  allowNone,
  label,
  selectProps,
  optionProps,
  error,
  errorHelperText,
  helperText,
  onChange,
}: ApiEnumMultiselectProps<TEnumName>) {
  const keyValuePairs = enumService.getEnumObjKeyValuePairs(type);

  const options = useMemo(
    () =>
      keyValuePairs
        .filter((kvp) => (allowNone ? true : kvp.value !== "None"))
        .map((kvp, index) => ({
          key: kvp.key,
          value: kvp.value,
        })),
    [keyValuePairs],
  );

  const handleChange = useCallback(
    (e: SelectChangeEvent<Array<ApiEnumValue<TEnumName>>>) => {
      const newValues = options
        .filter((x) => x.value === e.target.value || e.target.value.includes(x.value))
        .map((x) => x.value);
      onChange && onChange(newValues);
    },
    [onChange],
  );

  return (
    <FormControl size={size} fullWidth={fullWidth} error={error}>
      <InputLabel required={required} error={error}>
        {label}
      </InputLabel>
      <Select
        size={size}
        multiple
        label={label}
        disabled={disabled}
        required={required}
        error={error}
        value={values || []}
        onChange={handleChange}
        {...selectProps}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {/* {!selectProps?.required && (
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
        )} */}

        {options.map((option) => (
          <MenuItem
            key={option.key?.toString()}
            value={option.value}
            disabled={disabledValues ? disabledValues.includes(option.value) : undefined}
          >
            <InlineApiEnumValue
              type={type}
              value={option.value}
              {...optionProps}
              direction={optionProps?.direction ?? "column"}
              withDescription={optionProps?.withDescription ?? true}
            />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && <FormHelperText error>{errorHelperText}</FormHelperText>}
    </FormControl>
  );
}

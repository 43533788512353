import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssetSubscriptionPlanAvailabilityDto,
  AssetSubscriptionPlanDto,
  AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGetRequest,
} from "@/core/api/generated";
import { Box, SxProps, Theme } from "@mui/material";
import { useEffect } from "react";
import EntityDataBlock from "../../EntityData/EntityDataBlock";
import AppIcon from "../../Icons/AppIcon";
import SimpleCardListSkeleton from "../../Skeleton/SimpleCardListSkeleton";
import { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import AssetSubscriptionPlanAvailabilityDisplay from "./AssetSubscriptionPlanAvailabilityDisplay";

interface Props extends BaseEntityInlineInheritableProps {
  assetSubscriptionPlan: AssetSubscriptionPlanDto | null | undefined;
  requestParams?: Partial<AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGetRequest>;
  sx?: SxProps<Theme>;
  onChange?: (newValue: AssetSubscriptionPlanAvailabilityDto | null | undefined) => void;
}

export default function AssetSubscriptionPlanAvailabilityCheck({
  assetSubscriptionPlan,
  requestParams,
  sx,
  onChange,
}: Props) {
  const planAvailabilityRequest = useApiRequest(
    apiClient.assetSubscriptionPlansApi
      .apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionPlanId: assetSubscriptionPlan?.id || "",
      ...requestParams,
    },
    {
      skip: !assetSubscriptionPlan?.id,
      deps: [assetSubscriptionPlan?.id, requestParams],
    },
  );
  const planAvailability = planAvailabilityRequest?.data;

  useEffect(() => {
    onChange && onChange(planAvailability);
  }, [planAvailability]);

  if (!assetSubscriptionPlan) {
    return null;
  }

  return (
    <EntityDataBlock
      sx={sx}
      to={undefined}
      withDetailsToggle={false}
      title={
        <>
          <AppIcon of='availability' inText /> Subscription plan availability
        </>
      }
      briefContent={
        <Box>
          {planAvailabilityRequest.isLoading && <SimpleCardListSkeleton itemCount={1} />}

          {!planAvailabilityRequest.isLoading && planAvailability && (
            <AssetSubscriptionPlanAvailabilityDisplay
              plan={assetSubscriptionPlan}
              availability={planAvailability}
            />
          )}
        </Box>
      }
      detailedContent={undefined}
    />
  );
}

import { ProfileDto, VehicleDto } from "@/core/api/generated";
import { CustomEnvName } from "../constants/customEnvName";
import { EnvHelper } from "./env";

// NB: We added API to checking is Tesla feature is enabled.
// Leaving this code for reference as it might be needed.
const whitelistedEnvironmentsForDemo: string[] = [
  CustomEnvName.DevelopmentLocalhost,
  CustomEnvName.DevelopmentAzure,
];

const whitelistedEmailDomainsForDemo = [
  "nexusops.io",
  "unicreo.com",
  "letty.no",
  "pixelzoo.no", // Kristian Sjøvold
  "bislet.no",
];

const whitelistedEmailsForDemo = [
  "mraknes@gmail.com", // Michael Råknes
];

export class TeslaHelper {
  public static isDemoEnabledForCurrentEnvironment(): boolean {
    return whitelistedEnvironmentsForDemo.includes(EnvHelper.customEnv.toString());
  }

  public static isEmailWhitelistedForDemo(email: Nil<string>): boolean {
    return (
      !!email &&
      (whitelistedEmailsForDemo.includes(email) ||
        whitelistedEmailDomainsForDemo.some((domain) =>
          email.toLowerCase().endsWith(domain.toLowerCase()),
        ))
    );
  }

  public static isTeslaVehicle(vehicle: Nil<VehicleDto>): boolean {
    return (
      !!vehicle?.spec?.make &&
      (vehicle?.spec?.make?.identifier === "tesla" ||
        (vehicle?.spec?.make?.name || "").toLowerCase().includes("tesla"))
    );
  }

  public static isDemoEnabledForVehicle(vehicle: Nil<VehicleDto>): boolean {
    return this.isTeslaVehicle(vehicle);
  }

  public static isDemoEnabledForUserAndVehicle(
    profile: Nil<ProfileDto>,
    vehicle: Nil<VehicleDto>,
  ): boolean {
    return (
      this.isDemoEnabledForCurrentEnvironment() &&
      this.isEmailWhitelistedForDemo(profile?.email) &&
      this.isDemoEnabledForVehicle(vehicle)
    );
  }
}

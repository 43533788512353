import { Stack, Typography } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { EntityType, ProductLocationDto } from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import DepartmentLink from "@/common/components/Entity/Department/DepartmentLink";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import LocationLink from "@/common/components/Entity/Location/LocationLink";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { useState } from "react";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import AppIconButton from "@/common/components/Button/AppIconButton";

interface Props {
  productLocation: ProductLocationDto;
}

export default function OverviewTabContent({ productLocation }: Props) {
  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={productLocation} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <FieldValue label='Department' isEmpty={!(productLocation?.departmentIds || [])[0]}>
                <DepartmentLink entityId={(productLocation.departmentIds || [])[0]} />
              </FieldValue>

              <FieldValue label='Location' isEmpty={!(productLocation?.locationIds || [])[0]}>
                <LocationLink entityId={(productLocation.locationIds || [])[0]} />
              </FieldValue>

              <FieldValue label='Name' isEmpty={!productLocation?.name}>
                {productLocation?.name}
              </FieldValue>

              <FieldValue label='Description' isEmpty={!productLocation?.description}>
                {productLocation?.description}
              </FieldValue>

              <FieldValue label='Address' isEmpty={!productLocation?.address}>
                <GeneralAddressDisplay address={productLocation?.address} />
              </FieldValue>
            </Stack>
            {/* Affiliation */}
            <Stack spacing={1} sx={{ mt: 2 }}>
              <Typography variant='subtitle1'>
                Affiliation{" "}
                <AppIconButton
                  tooltipProps={{ title: "Edit entity affiliation" }}
                  onClick={() => setIsUpdateAffiliationModalOpened(true)}
                >
                  <AppIcon of='edit' fontSize='small' />
                </AppIconButton>
              </Typography>
              <AffiliationInfoDisplay
                tenantId={productLocation.tenantId}
                departmentIds={productLocation.departmentIds}
                locationIds={productLocation.locationIds}
                sx={{ mt: 1 }}
              />
              <UpdateEntityAffiliationCascadeModal
                open={isUpdateAffiliationModalOpened}
                onClose={() => setIsUpdateAffiliationModalOpened(false)}
                entityType={EntityType.ProductLocation}
                entityId={productLocation.id}
              />
            </Stack>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

import { Box, SxProps, Theme } from "@mui/material";

import { StringHelper } from "@/common/helpers/string";
import { RepairSpecDetalizationDto } from "@/core/api/generated";
import { enumService } from "@/common/services/enum";

export function getDetalizationInlineText(data: RepairSpecDetalizationDto) {
  return StringHelper.joinIntoString(
    [
      enumService.getEnumValueName("RepairSpecDetalizationType", data?.type),
      data?.vehicleSize && `${enumService.getEnumValueName("VehicleSize", data?.vehicleSize)} size`,
      enumService.getEnumValueName("VehicleBodyType", data?.bodyType),
      data?.make?.name,
      data?.model?.name,
      data?.generation?.name,
      data?.modification?.name,
    ],
    ", ",
    {
      skipEmpty: true,
    },
  );
}

interface Props {
  detalization: RepairSpecDetalizationDto;
  sx?: SxProps<Theme>;
}

export default function RepairSpecDetalizationInline({ detalization, sx }: Props) {
  return (
    <Box component='span' sx={sx}>
      {getDetalizationInlineText(detalization)}
    </Box>
  );
}

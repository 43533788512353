import { FormControl, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { AppTooltipProps } from "../AppTooltip";
import AppIconButton from "../Button/AppIconButton";
import DropdownIconButton from "../Button/DropdownIconButton";
import AppIcon from "../Icons/AppIcon";

export interface AttachmentCaptionInputProps {
  defaultCaption?: string | undefined;
  onSetCaption: (caption: string | undefined) => void;
  tooltipProps?: Omit<AppTooltipProps, "children"> | undefined;
}
export default function AttachmentCaptionInput({
  defaultCaption,
  tooltipProps,
  onSetCaption,
}: AttachmentCaptionInputProps) {
  const [caption, setCaption] = useState<string | undefined>(defaultCaption);
  const [isValid, setIsValid] = useState<boolean>(true);

  // TODO: Should be removed when FullscreenFileViewerV2 will be implemented
  // for now we have no ability to validate caption length on the server side only
  // because of complex component structure and Redux usage related to the attachments
  useEffect(() => {
    if ((caption || "").length > 100) {
      setIsValid(false);
      return;
    }
    if (!isValid) {
      setIsValid(true);
    }
  }, [caption]);
  return (
    <DropdownIconButton
      tooltipProps={tooltipProps ?? { enabled: false, title: "Set caption", placement: "top" }}
      autoCloseOnClick={false}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      paperSx={{ borderRadius: (th) => th.shape.borderRadius }}
      dropdownContent={(params) => (
        <Stack direction='row'>
          <FormControl error={!isValid}>
            <TextField
              error={!isValid}
              size='small'
              autoFocus
              value={caption}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setCaption(e.target.value);
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              InputProps={{
                endAdornment: (
                  <AppIconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setCaption("");
                    }}
                    sx={{ visibility: caption ? "visible" : "hidden" }}
                  >
                    <AppIcon of='clear' fontSize='small' />
                  </AppIconButton>
                ),
              }}
            />
          </FormControl>

          <AppIconButton
            disabled={!isValid}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onSetCaption(caption);
              params.handleClose();
            }}
            size='small'
            variant='default'
            sx={{ p: 1 }}
          >
            <AppIcon of='check' />
          </AppIconButton>
        </Stack>
      )}
    >
      <AppIcon of='edit' fontSize='small' />
    </DropdownIconButton>
  );
}

import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function RepairCarIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      color='secondary'
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_281_9965)'>
        <path d='M23.721 9.62906C23.5719 8.31648 20.0379 9.62906 20.0379 9.62906L19.8297 11.4967C19.7525 11.4526 19.6742 11.4097 19.5918 11.3732L18.6992 7.47835C18.3721 5.62403 16.9725 4.25809 14.9326 4.32827H9.69093C9.65903 4.54578 9.60799 4.75632 9.54477 4.96223H15.2864C16.4771 4.96223 17.5682 5.72843 17.8663 6.99345L18.8251 10.839C18.7647 10.8372 18.705 10.8314 18.6441 10.8314H7.52747L7.53037 14.5801H14.4169V15.4182H7.53095L7.53153 15.663H14.3473V16.5029H7.53153L7.53385 19.6524H14.8867V21.647C14.8867 22.4573 15.8896 23.1156 17.1256 23.1156C18.3616 23.1156 19.3627 22.4579 19.3627 21.647V19.4215C20.419 19.0225 21.1544 18.1495 21.1544 17.1339V13.5651C21.1544 12.7762 20.709 12.0738 20.0193 11.6127L23.721 11.4126C23.721 11.4126 23.8695 10.9405 23.721 9.62906ZM19.4805 16.4402H15.4401V14.5192H19.4805V16.4402Z' />
        <path d='M7.53153 15.6621H7.42886V16.502H7.53153V15.6621Z' />
        <path d='M7.53096 14.5801H7.49905V15.4182H7.53096V14.5801Z' />
        <path d='M8.89574 3.76316C8.89226 2.15187 8.01643 0.748224 6.71197 0V3.47605L4.55372 4.72193L2.39373 3.47199V0C1.09159 0.748224 0.21402 2.15187 0.21286 3.76316C0.21286 5.45275 1.18149 6.91672 2.59326 7.63536V7.65392V21.9508C2.59326 22.5169 2.8212 23.0291 3.19241 23.4008C3.56305 23.7721 4.07462 23.9977 4.64188 24C5.76944 24 6.68587 23.0847 6.68993 21.9514L6.68123 7.5443C8.00309 6.80072 8.894 5.38431 8.89574 3.76316ZM5.09314 22.8579C4.78805 23.1642 4.29155 23.1642 3.98414 22.8574C3.68137 22.5534 3.68195 22.0593 3.98704 21.753C4.29387 21.4479 4.78863 21.4479 5.09139 21.7507C5.39822 22.0575 5.39881 22.5523 5.09314 22.8579Z' />
      </g>
      <defs>
        <clipPath id='clip0_281_9965'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});

import { Box } from "@mui/material";
import { ReactNode } from "react";

import { useNavDisplay } from "@/common/contexts/useNavDisplay";
import { useAppSelector } from "@/common/hooks/redux";
import Header from "../MainAppView/Header/Header";
import Nav from "../MainAppView/Nav/Nav";
import ChatStack from "../MainAppView/SideStack/ChatStack";

interface MainAppViewProps {
  children: ReactNode;
}

export default function FleetAppLayout({ children }: MainAppViewProps) {
  const isNavOpened = useAppSelector((x) => x.app.isNavOpened);
  const { isControlled, navDisplayWidth } = useNavDisplay();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        flex: 1,
        display: "flex",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <Header />

        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "flex-start",
            height: "100%",
            width: "100%",
            overflowY: "hidden",
            overflowX: "hidden",
          }}
        >
          {isNavOpened && <Nav />}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              width: "100%",
              overflowY: "auto",
              overflowX: "auto",
              flex: 1,
              minWidth: 0,
              ml: isControlled ? 0 : navDisplayWidth.collapsed,
            }}
          >
            <Box sx={{ width: "100%" }}>{children}</Box>
          </Box>
          <Box>
            <ChatStack />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { EntityType, NegotiationDto } from "@/core/api/generated";
import GeneralHistoryPaginatedList from "../../../GeneralHistory/ListView/GeneralHistoryPaginatedList";

interface Props {
  negotiation: NegotiationDto;
}

export default function GeneralHistoryTabContent({ negotiation }: Props) {
  return (
    <PageTabContent>
      <GeneralHistoryPaginatedList
        defaultValues={{
          subjectEntityType: EntityType.Negotiation,
          subjectEntityId: negotiation.id,
        }}
        displayProps={{
          viewVariant: ViewLayoutVariant.Tab,
        }}
      />
    </PageTabContent>
  );
}

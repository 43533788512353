import { apiClient } from "@/core/api/ApiClient";
import { DamageCostEvaluationDto, EntityType } from "@/core/api/generated";
import BaseEntitiesDeleteModal, {
  BaseEntitiesDeleteModalInheritableProps,
} from "../components/BaseEntitiesDeleteModal";

export interface OwnProps {
  entities: DamageCostEvaluationDto[];
}

type Props = OwnProps & BaseEntitiesDeleteModalInheritableProps;

export default function DamageCostEvaluationsDeleteModal({ entities, ...otherProps }: Props) {
  return (
    <BaseEntitiesDeleteModal
      entityType={EntityType.DamageCostEvaluation}
      entities={entities}
      deleteFunc={(params) =>
        apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsBulkDeleteDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          entityBulkDeleteDto: {
            ids: params.entityIds,
          },
        })
      }
      {...otherProps}
    />
  );
}

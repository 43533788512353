import { Dialog, DialogContent, DialogProps } from "@mui/material";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import ImageSimpleEditor, { ImageSimpleEditorProps } from "./ImageSimpleEditor";

export interface OwnProps {
  editorProps: ImageSimpleEditorProps;
}

type Props = OwnProps & DialogProps;

export default function ImageSimpleEditorModal({ editorProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Edit image
      </AppModalTitle>
      <DialogContent>
        <ImageSimpleEditor {...editorProps} />
      </DialogContent>
    </Dialog>
  );
}

import { Box, Stack, Typography, TypographyProps } from "@mui/material";
import { ReactNode } from "react";

export const pageTabHeaderTypographyProps = {
  title: { variant: "h1" } as TypographyProps,
  subtitle: { variant: "subtitle1", color: "text.secondary" } as TypographyProps,
  subtitle2: { variant: "caption", color: "text.secondary" } as TypographyProps,
};

interface Props {
  title: ReactNode;
  subtitle?: ReactNode;
  subtitle2?: ReactNode;
  primaryAction?: ReactNode;
  secondaryAction?: ReactNode;
}

export default function PageTabHeader({
  title,
  subtitle,
  subtitle2,
  primaryAction,
  secondaryAction,
}: Props) {
  return (
    <Stack
      direction='row'
      spacing={1}
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <Box>
        <Typography {...pageTabHeaderTypographyProps.title}>{title}</Typography>

        {subtitle && (
          <Typography component='div' {...pageTabHeaderTypographyProps.subtitle}>
            {subtitle}
          </Typography>
        )}
      </Box>

      <Stack spacing={1}>
        <Stack direction={{ xxs: "column", md: "row" }} spacing={1}>
          {secondaryAction && <Box>{secondaryAction}</Box>}

          {primaryAction && <Box>{primaryAction}</Box>}
        </Stack>

        {subtitle2 && (
          <Typography
            component='div'
            {...pageTabHeaderTypographyProps.subtitle2}
            sx={{ minWidth: 0, overflow: "hidden" }}
          >
            {subtitle2}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

import { Badge, Box, IconButton, Stack, Tab, Tabs, TextField } from "@mui/material";
import {
  GridColumnsManagement,
  gridPreferencePanelStateSelector,
  GridToolbarContainer,
  GridToolbarProps,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from "@mui/x-data-grid";

import { useCustomDataGridContext } from "@/common/contexts/customDataGrid";
import { TypeHelper } from "@/common/helpers/type";
import {
  DataGridCustomSortProps,
  DataGridFilterProps,
  DataGridQuickFilterProps,
  DataGridRefetchProps,
} from "@/common/ts/dataGrid";
import { useEffect, useMemo, useRef, useState } from "react";
import AppTooltip from "../AppTooltip";
import AppIconButton from "../Button/AppIconButton";
import { DataListTabsProps } from "../DataList/DataList";
import FilterPopover, { FilterPopoverHandle } from "../Filters/FilterPopover";
import AppIcon from "../Icons/AppIcon";
import AppPopover from "../Popover/AppPopover";
import AppPopoverContent from "../Popover/AppPopoverContent";
import SortPopover from "../Sort/SortPopover";

declare module "@mui/x-data-grid" {
  interface GridToolbarProps {
    // showQuickFilter?: boolean;
    // quickFilterProps?: GridToolbarQuickFilterProps;
    tabs?: DataListTabsProps;
    sort?: DataGridCustomSortProps;
    quickFilter?: DataGridQuickFilterProps;
    filters?: DataGridFilterProps;
    refetch?: DataGridRefetchProps;
  }
}

interface Props extends GridToolbarProps {
  // tabs?: DataListTabsProps;
}

export default function CustomDataGridToolbar({
  tabs,
  sort,
  quickFilter,
  filters,
  refetch,
}: Props) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const preferencePanelState = useGridSelector(apiRef, gridPreferencePanelStateSelector);
  const customDataGridContext = useCustomDataGridContext();
  const filterPopoverRef = useRef<FilterPopoverHandle>(null);

  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const [search, setSearch] = useState<string | undefined>(quickFilter?.initialSearch);
  const [isRefetching, setIsRefetching] = useState(false);

  const isTabsEnabled = useMemo(() => !!tabs, [tabs]);
  const isColumnSelectorEnabled = useMemo(
    () => !rootProps.disableColumnSelector,
    [rootProps.disableColumnSelector],
  );
  const isSortEnabled = useMemo(() => !!sort, [sort]);
  const isQuickFilterEnabled = useMemo(() => !!quickFilter, [quickFilter]);
  const isFiltersEnabled = useMemo(() => !!filters, [filters]);
  const isRefetchEnabled = useMemo(() => !!refetch, [refetch]);
  const enabledList = [
    isTabsEnabled,
    isColumnSelectorEnabled,
    isSortEnabled,
    isQuickFilterEnabled,
    isFiltersEnabled,
    isRefetchEnabled,
  ];
  const isAnyEnabled = useMemo(() => enabledList.some((x) => x === true), [...enabledList]);

  // DataGrid column filter integration
  useEffect(() => {
    const sub = customDataGridContext.on2("addFieldFilterRequested", (params) => {
      filterPopoverRef.current?.addFieldFilterAndOpen(params);
    });
    return () => {
      sub.off();
    };
  }, []);

  // refetch integration
  useEffect(() => {
    const sub1 = refetch?.eventEmitter.on2("refetchStarted", () => {
      setIsRefetching(true);
    });
    const sub2 = refetch?.eventEmitter.on2("refetchEnded", () => {
      setIsRefetching(false);
    });
    return () => {
      sub1?.off();
      sub2?.off();
    };
  }, [refetch]);

  if (!isAnyEnabled) {
    return null;
  }

  return (
    <GridToolbarContainer>
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: { xxs: "1fr", lg: "1fr 1fr" },
          }}
        >
          <Box>
            {/* Tabs */}
            {isTabsEnabled && tabs && (
              <Tabs value={tabs.value} onChange={tabs.onChange}>
                {tabs.tabs.map((tab, index) => (
                  <Tab key={index} {...tab} />
                ))}
              </Tabs>
            )}
          </Box>

          {/* Actions */}
          <Stack
            direction='row'
            spacing={0.25}
            sx={{ justifyContent: "flex-end", alignItems: "center", pr: 1 }}
          >
            {/* <GridToolbar showQuickFilter /> */}

            {/* <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter /> */}

            {/* <GridColumnsManagement /> */}

            {/* <GridToolbarFilterButton /> */}

            {/* Quick filter */}
            {isQuickFilterEnabled && quickFilter && !isSearchOpened && (
              <AppTooltip title='Quick search'>
                <Badge
                  variant='dot'
                  color='primary'
                  overlap='circular'
                  invisible={TypeHelper.isEmpty(search)}
                >
                  <AppIconButton onClick={() => setIsSearchOpened(true)}>
                    <AppIcon of='search' />
                  </AppIconButton>
                </Badge>
              </AppTooltip>
            )}
            {isQuickFilterEnabled && quickFilter && isSearchOpened && (
              <TextField
                sx={{ flex: 1, "& .MuiInputBase-root": { pr: 0 }, minWidth: 0 }}
                label='Search'
                size='small'
                value={search || ""}
                onChange={(e) => {
                  setSearch(e.target.value);
                  quickFilter.onChange(e.target.value || undefined);
                }}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        setIsSearchOpened(false);
                        setSearch(undefined);
                        quickFilter.onChange(undefined);
                      }}
                    >
                      <AppIcon of='close' />
                    </IconButton>
                  ),
                }}
              />
            )}

            {/* Opens DataGrid's columns panel via DataGrid API */}
            {/* <AppTooltip title='Columns'>
        <AppIconButton
          onClick={() => {
            if (
              preferencePanelState.open &&
              preferencePanelState.openedPanelValue === GridPreferencePanelsValue.columns
            ) {
              apiRef.current.hidePreferences();
            } else {
              apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
            }
          }}
        >
          <AppIcon of='columns' />
        </AppIconButton>
      </AppTooltip> */}

            {/* Manually render columns panel. */}
            {isColumnSelectorEnabled && (
              <AppPopover
                clickBehavior={{}}
                trigger={
                  <AppTooltip title='Columns'>
                    <AppIconButton>
                      <AppIcon of='columns' />
                    </AppIconButton>
                  </AppTooltip>
                }
              >
                <AppPopoverContent isUseDefaultStyles={false}>
                  <GridColumnsManagement {...rootProps.slotProps?.columnsManagement} />
                </AppPopoverContent>
              </AppPopover>
            )}

            {/* Filters */}
            {isFiltersEnabled && filters && filters.filterSpec && (
              <FilterPopover
                ref={filterPopoverRef}
                clickBehavior={{}}
                trigger={
                  <AppTooltip title='Filters'>
                    <Badge
                      color='primary'
                      overlap='circular'
                      badgeContent={filters.filterDefinition?.items?.length}
                    >
                      <AppIconButton>
                        <AppIcon of='filters' />
                      </AppIconButton>
                    </Badge>
                  </AppTooltip>
                }
                filterDefinitionInputProps={{
                  filterSpec: filters.filterSpec,
                  filterDefinition: filters.filterDefinition,
                  onChange: filters.onChange,
                }}
              />
            )}

            {/* Sort */}
            {isSortEnabled && sort && sort.sortSpec && (
              <SortPopover
                clickBehavior={{}}
                trigger={
                  <AppTooltip title='Sort'>
                    <Badge
                      color='primary'
                      overlap='circular'
                      badgeContent={sort.sortDefinition?.items?.length}
                    >
                      <AppIconButton>
                        <AppIcon of='sorting' />
                      </AppIconButton>
                    </Badge>
                  </AppTooltip>
                }
                sortDefinitionInputProps={{
                  sortSpec: sort.sortSpec,
                  sortDefinition: sort.sortDefinition,
                  maxSortItems: sort.maxSortItems,
                  onChange: sort.onChange,
                }}
              />
            )}

            {/* Refetch */}
            {isRefetchEnabled && refetch && (
              <AppTooltip title='Refresh'>
                <AppIconButton
                  disabled={isRefetching}
                  onClick={(e) => {
                    refetch.triggerRefetch();
                  }}
                >
                  <AppIcon of='refresh' />
                </AppIconButton>
              </AppTooltip>
            )}
          </Stack>
        </Box>
      </Box>
    </GridToolbarContainer>
  );
}

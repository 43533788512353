import { Box, SxProps, Theme, Typography } from "@mui/material";

import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import {
  CurrencyCode,
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  GeneralTaxDto,
  GeneralTaxInputDto,
  TaxValueType,
} from "@/core/api/generated";

import _ from "lodash";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import CurrencyValue from "../../../Form/Display/CurrencyValue";
import PercentValue from "../../../Form/Display/PercentValue";

interface Props {
  tax?: GeneralTaxDto | GeneralTaxInputDto | null;
  detailsPlacement?: "inline" | "tooltip";
  currency?: GeneralCurrencyDto | GeneralCurrencyInputDto | null;
  currencyCode?: CurrencyCode | null;
  sx?: SxProps<Theme>;
}

export default function GeneralTaxDisplay({
  tax,
  detailsPlacement = undefined,
  currency,
  currencyCode,
  sx,
}: Props) {
  const currentCurrency = useCurrentCurrency();
  const currencyComputed: GeneralCurrencyDto | undefined =
    currency || (currencyCode && { code: currencyCode }) || tax?.currency || currentCurrency;
  const isEmpty = !tax || (_.isNil(tax?.percent) && _.isNil(tax?.value));

  return (
    <Box component='span' sx={sx}>
      {isEmpty && `-`}

      {!isEmpty && (
        <>
          {tax?.valueType === TaxValueType.Percent && <PercentValue value={tax?.percent} />}
          {tax?.valueType === TaxValueType.Value && (
            <CurrencyValue value={tax?.value} currency={currencyComputed} />
          )}
          {detailsPlacement === "inline" && (
            <Typography component='span' variant='caption'>
              {" "}
              (<InlineApiEnumValue type='TaxType' value={tax?.type} />)
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}

import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { AssetDto } from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AssetSpecDisplay from "@/common/components/Entity/Asset/AssetSpecDisplay";

interface Props {
  asset: AssetDto;
}

export default function SpecTabContent({ asset }: Props) {
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={<PageTabHeader title={"Spec"} />}
      >
        <Stack direction='column' spacing={2}>
          {!asset?.spec && <NoDataAlert />}
          <AssetSpecDisplay spec={asset?.spec} />
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

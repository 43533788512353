import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { AppPermission, UserDto } from "@/core/api/generated";
import { Alert, Stack } from "@mui/material";

interface Props {
  user: UserDto;
}

export default function OverviewTabContent({ user }: Props) {
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={<PageTabHeader title={"Details"} />}
      >
        <Stack direction='column' spacing={2}>
          {!user.isPersonalInfoComplete && (
            <Alert severity='warning'>Personal info is incomplete!</Alert>
          )}

          <Stack direction={{ xxs: "column", md: "row" }} spacing={2}>
            <Stack spacing={1} sx={{ flex: 1 }}>
              <FieldValue label='First name'>{user.personName?.firstName}</FieldValue>
              <AuthorizedElement permissions={[AppPermission.TenantUserRead]}>
                <FieldValue label='Last name'>{user.personName?.lastName}</FieldValue>
                <FieldValue label='Email'>{user.email}</FieldValue>
                <FieldValue label='Phone'>{user.phoneNumber}</FieldValue>
              </AuthorizedElement>
            </Stack>
            <Stack spacing={1} sx={{ flex: 1 }}>
              <AuthorizedElement permissions={[AppPermission.TenantUserRead]}>
                <FieldValue label='Status'>
                  <InlineApiEnumValue
                    type='UserStatus'
                    value={user.status}
                    withDescription
                    direction='column'
                  />
                </FieldValue>

                <FieldValue label='Customer user?'>
                  <BooleanValue value={user.isCustomerUser} />
                </FieldValue>

                <FieldValue label='Time zone'>{user.settings?.tzId}</FieldValue>

                <FieldValue label='Culture (locale)'>
                  {user.settings?.culture?.displayName} | {user.settings?.culture?.name}
                </FieldValue>
              </AuthorizedElement>
            </Stack>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

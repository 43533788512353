import { useHistory, useParams } from "react-router";

import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import PoolCreateUpdate from "@/common/components/Entity/Pool/PoolCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function PoolCreateUpdatePage() {
  const { poolId } = useParams<{ poolId?: string }>();
  const history = useHistory();

  const isCreate = !poolId;

  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={isCreate ? "Create new pool" : "Edit pool"} />}
    >
      <PoolCreateUpdate
        poolId={poolId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.POOL_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}

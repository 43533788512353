import { LoadingButton } from "@mui/lab";
import { FormControl, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";

import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  EntityType,
  TenantConnectionRequestCreateDto,
  TenantConnectionRequestDto,
} from "@/core/api/generated";
import GeneralValidationError from "../../Error/GeneralValidationError";
import TenantAutocomplete from "../Tenant/TenantAutocomplete";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  receiverTenantId?: TenantConnectionRequestCreateDto["receiverTenantId"];
};

export interface TenantConnectionRequestCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<TenantConnectionRequestDto, DefaultValues> {
  tenantConnectionRequestId?: string;
}

export type TenantConnectionRequestCreateUpdateProps = TenantConnectionRequestCreateUpdateOwnProps;

export default function TenantConnectionRequestCreateUpdate({
  tenantConnectionRequestId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: TenantConnectionRequestCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !tenantConnectionRequestId;
  const isEdit = !!tenantConnectionRequestId;

  const tenantConnectionRequestRequest = useApiRequest(
    apiClient.tenantConnectionRequestsApi
      .apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantConnectionRequestId: tenantConnectionRequestId!,
    },
    {
      skip: !tenantConnectionRequestId,
    },
  );
  const tenantConnectionRequest = tenantConnectionRequestRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: tenantConnectionRequest && {
      idValue: tenantConnectionRequest.id!,
      newTitle: tenantConnectionRequest.globalNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.TenantConnectionRequest}
      entityId={tenantConnectionRequestId}
      entity={tenantConnectionRequest}
      entityRequest={tenantConnectionRequestRequest}
    >
      <Formik<TenantConnectionRequestCreateDto & BaseFormikValues>
        enableReinitialize
        initialValues={{
          receiverTenantId: defaultValues?.receiverTenantId || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response =
                await apiClient.tenantConnectionRequestsApi.apiV1TenantToTenantTenantConnectionRequestsPost(
                  {
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    tenantConnectionRequestCreateDto: {
                      ...values,
                    },
                  },
                );
              enqueueSnackbar("Company connection request created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              // const response =
              //   await apiClient.tenantConnectionRequestsApi.apiV1TenantConnectionRequestsTenantConnectionRequestIdPut(
              //     {
              //       nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              //       tenantConnectionRequestId,
              //       tenantConnectionRequestUpdateDto: {
              //         ...values,
              //       },
              //     },
              //   );
              // enqueueSnackbar("Company connection request updated.", { variant: "success" });
              // onUpdate && onUpdate(response.data);
              // onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  <FormControl margin='dense' fullWidth>
                    <TenantAutocomplete
                      entityId={values.receiverTenantId}
                      required
                      disabled={!isCreate}
                      label='Receiver company'
                      onChange={(newValue) => setFieldValue("receiverTenantId", newValue?.id)}
                      textFieldProps={{
                        error: Boolean(errors.receiverTenantId),
                        helperText: errors.receiverTenantId,
                      }}
                    />
                  </FormControl>
                </Box>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <LoadingButton
                  sx={{ mt: { xs: "auto", md: 2 } }}
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}

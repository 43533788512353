import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import {
  GeneralCurrencyDto,
  GeneralReferenceDataApiApiV1ReferenceDataGeneralCurrenciesGetRequest,
} from "@/core/api/generated";

type CurrencyAutocompleteOption = BaseAutocompleteOption<GeneralCurrencyDto>;

const currencyToOption = (data: GeneralCurrencyDto): CurrencyAutocompleteOption => ({
  id: data.id || data.code || "",
  title: StringHelper.joinIntoString([data.name || data.code, `(${data.code})`], " ", {
    skipEmpty: true,
  }),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface CurrencyAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<GeneralCurrencyDto> {
  searchFilters?: Partial<GeneralReferenceDataApiApiV1ReferenceDataGeneralCurrenciesGetRequest>;
}

export default function CurrencyAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: CurrencyAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={currencyToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.generalReferenceDataApi.apiV1ReferenceDataGeneralCurrenciesGet,
        parameters: {},
        combineParameters: (params, newParams) => ({
          ...params,
          ...newParams,
        }),
        deps: [],
      }}
      label='Currency'
      placeholder='Search for currency...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='currency' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body2' color='secondary'>
                      {option.data?.code}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

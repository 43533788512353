import { ContractDto, ContractSpotInfoDto } from "@/core/api/generated";
import { Box, Chip, Stack, Typography } from "@mui/material";
import Datetime from "../../Datetime/Datetime";
import FieldValue from "../../Form/Display/FieldValue";
import DepartmentLink from "../Department/DepartmentLink";
import LocationLink from "../Location/LocationLink";
import ProductLocationLink from "../ProductLocation/ProductLocationLink";
import SpotLink from "../Spot/SpotLink";
import VisualInspectionLink from "../VisualInspection/VisualInspectionLink";
import { ReactNode, useMemo } from "react";
import { renderIf } from "@/common/helpers/render/renderIf";

interface Props {
  // title: ReactNode;
  contract: ContractDto;
  spotInfoType: "CheckOut" | "CheckIn";
  // info: ContractSpotInfoDto | null | undefined;
  action?: ReactNode;
}

export default function ContractSpotInfoDisplay({ contract, spotInfoType, action }: Props) {
  const info = useMemo(
    () =>
      spotInfoType === "CheckOut"
        ? contract.checkOutSpotInfo
        : spotInfoType === "CheckIn"
          ? contract.checkInSpotInfo
          : undefined,
    [contract, spotInfoType],
  );

  return (
    <Stack spacing={1}>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 0fr", gap: 2 }}>
        <Typography component='div' variant='h6'>
          {spotInfoType === "CheckOut"
            ? "Vehicle check-out"
            : spotInfoType === "CheckIn"
              ? "Vehicle check-in"
              : undefined}
        </Typography>

        <Box>{action}</Box>
      </Box>

      <FieldValue label='Status'>
        {info?.isCompleted && (
          <>
            <Chip size='small' variant='filled' color='success' label='Completed' />{" "}
          </>
        )}

        {renderIf()
          .if(spotInfoType === "CheckOut")
          .then(
            <>
              {contract?.canCheckOut && (
                <>
                  <Chip
                    size='small'
                    variant='outlined'
                    color='success'
                    label='Can be checked-out'
                  />{" "}
                </>
              )}
              {contract?.isPreparedForCheckOut && (
                <>
                  <Chip
                    size='small'
                    variant='outlined'
                    color='success'
                    label='Prepared for check-out'
                  />{" "}
                </>
              )}
            </>,
          )
          .elseif(spotInfoType === "CheckIn")
          .then(
            <>
              {contract?.canCheckIn && (
                <>
                  <Chip size='small' variant='outlined' color='success' label='Can be checked-in' />{" "}
                </>
              )}
              {contract?.isPreparedForCheckIn && (
                <>
                  <Chip
                    size='small'
                    variant='outlined'
                    color='success'
                    label='Prepared for check-in'
                  />{" "}
                </>
              )}
            </>,
          )
          .else("-")
          .render()}
      </FieldValue>

      <FieldValue label='Date & Time' isEmpty={!info?.date}>
        <Datetime datetime={info?.date} />
      </FieldValue>

      {info?.departmentId && (
        <FieldValue label='Department' isEmpty={!info?.departmentId}>
          <DepartmentLink entity={undefined} entityId={info?.departmentId} />
        </FieldValue>
      )}

      {info?.locationId && (
        <FieldValue label='Location' isEmpty={!info?.locationId}>
          <LocationLink entity={undefined} entityId={info?.locationId} />
        </FieldValue>
      )}

      {info?.productLocationId && (
        <FieldValue label='Sales location' isEmpty={!info?.productLocationId}>
          <ProductLocationLink entity={undefined} entityId={info?.productLocationId} />
        </FieldValue>
      )}

      <FieldValue label='Spot' isEmpty={!info?.spot?.id}>
        <SpotLink entity={undefined} entityId={info?.spot?.id} />
      </FieldValue>

      <FieldValue label='Visual inspection' isEmpty={!info?.visualInspectionId}>
        <VisualInspectionLink entity={undefined} entityId={info?.visualInspectionId || undefined} />
      </FieldValue>

      <FieldValue label='Notes' isEmpty={!info?.notes}>
        {info?.notes}
      </FieldValue>
    </Stack>
  );
}

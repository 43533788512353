import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import ApiEnumIcon from "@/common/components/Icons/ApiEnumIcon";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  PoolItemDto,
} from "@/core/api/generated";
import GeneralEntitySubTypeInline from "../../General/Display/GeneralEntitySubTypeInline";
import GeneralStrictEntityRelationLink from "../../General/Display/GeneralStrictEntityRelationLink";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import PoolItemDeleteModal from "../PoolItemDeleteModal";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import _ from "lodash";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import { PoolItemHelper } from "@/common/helpers/entity/poolItem";
import PoolItemUpdateStatusModal from "../PoolItemUpdateStatusModal";

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
};

interface OwnProps extends BaseEntityViewInheritableProps<PoolItemDto> {
  poolId: string;
  poolItemId: string | null | undefined;
  poolItem: PoolItemDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function PoolItemView({
  poolId,
  poolItemId,
  poolItem,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();

  const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const poolItemRequest = useApiRequest(
    apiClient.poolItemsApi.apiV1PoolsPoolIdItemsPoolItemIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolId: poolId,
      poolItemId: poolItemId!,
    },
    {
      deps: [poolId, poolItemId],
      skip: !poolItemId || !!poolItem,
    },
  );
  poolItem = poolItem || poolItemRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.PoolItem, poolItem?.id || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      poolItemRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumbs: poolItem && [
      {
        idValue: poolItem.poolId!,
        newTitle: "The pool",
      },
      {
        idValue: poolItem.id!,
        newTitle: poolItem.entity?.nameComputed || poolItem.entityType || "",
      },
    ],
  });

  return (
    <BaseEntityView
      entityType={EntityType.PoolItem}
      entityId={poolItemId}
      entity={poolItem}
      entityRequest={poolItemRequest}
    >
      <Stack spacing={2}>
        {displayProps.header && (
          <DetailedViewPageHeader
            image={undefined}
            title={`Pool item ${poolItem?.entity?.nameComputed} (${poolItem?.entityType})`}
            titleProps={{
              to: headerProps?.withLink ? ROUTE_PATH.ACCESSORY_VIEW(poolItem?.id) : undefined,
              typographyProps: headerProps?.typographyProps,
            }}
            primaryActions={
              displayProps.actions && (
                <AuthorizedElement permissions={[AppPermission.PoolManage]}>
                  <Button
                    component={AppLink}
                    to={ROUTE_PATH.POOL_ITEM_EDIT(poolId, poolItem?.id)}
                    variant='outlined'
                    color='secondary'
                    size='medium'
                    startIcon={<AppIcon of='edit' />}
                    disabled={!PoolItemHelper.canUpdate(poolItem)}
                  >
                    Edit
                  </Button>
                </AuthorizedElement>
              )
            }
            secondaryActions={
              displayProps.actions &&
              poolItem && (
                <MenuWithTrigger
                  withAuthCloseOnClick
                  trigger={
                    <IconButton sx={{ ml: "auto" }}>
                      <AppIcon of='moreVert' />
                    </IconButton>
                  }
                >
                  <AuthorizedMenuItem
                    permissions={[AppPermission.PoolManage]}
                    onClick={() => {
                      setIsUpdateStatusModalOpen(true);
                    }}
                    disabled={!PoolItemHelper.canUpdateStatus(poolItem)}
                  >
                    <ListItemIcon>
                      <AppIcon of='edit' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Edit status</ListItemText>
                  </AuthorizedMenuItem>

                  <AuthorizedMenuItem
                    permissions={[AppPermission.PoolManage]}
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                    }}
                    disabled={!PoolItemHelper.canDelete(poolItem)}
                  >
                    <ListItemIcon>
                      <AppIcon of='delete' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </AuthorizedMenuItem>
                </MenuWithTrigger>
              )
            }
          />
        )}

        {poolItem && (
          <Stack spacing={2}>
            <Stack spacing={1}>
              <FieldValue label='Type' isEmpty={!poolItem?.type}>
                <InlineApiEnumValue type='PoolItemType' value={poolItem.type} />
              </FieldValue>

              <FieldValue label='Entity type' isEmpty={!poolItem?.entityType}>
                <ApiEnumIcon type='EntityType' value={poolItem.entityType} inText />{" "}
                <InlineApiEnumValue type='PoolItemEntityType' value={poolItem.entityType} />
                {poolItem.entitySubType && (
                  <>
                    {" "}
                    (
                    <Typography component='span' variant='body2'>
                      <GeneralEntitySubTypeInline value={poolItem.entitySubType} withIcon />
                    </Typography>
                    )
                  </>
                )}
              </FieldValue>

              <FieldValue label='Entity' isEmpty={!poolItem?.entity}>
                <GeneralStrictEntityRelationLink value={poolItem?.entity} />
              </FieldValue>

              <FieldValue label='Status' isEmpty={!poolItem?.status}>
                <InlineApiEnumValue type='PoolItemStatus' value={poolItem.status} />
              </FieldValue>

              <FieldValue label='Status reason' isEmpty={!poolItem?.statusReason}>
                {poolItem?.statusReason}
              </FieldValue>

              <FieldValue label='Used by entity' isEmpty={!poolItem?.usedByEntity}>
                <GeneralStrictEntityRelationLink value={poolItem?.usedByEntity} />
              </FieldValue>
            </Stack>

            <Stack spacing={1}>
              <Typography component='div' variant='h6'>
                Settings
              </Typography>

              <Stack spacing={1}>
                <FieldValue
                  label='Ensure entity belongs to a single pool'
                  isEmpty={_.isNil(poolItem?.settings?.isEnsureEntityBelongToSinglePool)}
                >
                  <BooleanValue value={poolItem.settings?.isEnsureEntityBelongToSinglePool} />
                </FieldValue>
              </Stack>
            </Stack>
          </Stack>
        )}

        {/* Update status */}
        {poolItem && (
          <PoolItemUpdateStatusModal
            open={isUpdateStatusModalOpen}
            onClose={() => setIsUpdateStatusModalOpen(false)}
            poolId={poolItem.poolId!}
            poolItemId={undefined}
            poolItem={poolItem}
            onSave={(newValue) => {
              setIsUpdateStatusModalOpen(false);
              poolItemRequest.replaceData(newValue);
            }}
          />
        )}

        {/* Delete */}
        {poolItem && (
          <PoolItemDeleteModal
            entity={poolItem}
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onDelete={() => history.goBack()}
          />
        )}
      </Stack>
    </BaseEntityView>
  );
}

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import TenantConnectionPaginatedList from "@/common/components/Entity/TenantConnection/ListView/TenantConnectionPaginatedList";

export default function TenantConnectionsPage() {
  return (
    <>
      <TenantConnectionPaginatedList
        displayProps={{
          breadcrumbs: true,
          filters: true,
        }}
      />
    </>
  );
}

import { TypeHelper } from "@/common/helpers/type";
import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
  Theme,
} from "@mui/material";
import { useMemo } from "react";

export interface Props extends Omit<SelectProps, "value" | "onChange"> {
  value: boolean | undefined;
  variant?: SelectProps["variant"];
  formControlProps?: Partial<FormControlProps>;
  selectProps?: Partial<SelectProps>;
  sx?: SxProps<Theme>;
  onChange?: (newValue: boolean | undefined) => void;
}

export default function BooleanSelect({
  value,
  variant,
  formControlProps,
  selectProps,
  sx,
  onChange,
  ...otherProps
}: Props) {
  const options = useMemo(
    () => (otherProps?.required ? [true, false] : [undefined, true, false]),
    [otherProps?.required],
  );

  return (
    <FormControl sx={sx} size={otherProps?.size} {...formControlProps}>
      <InputLabel>{otherProps?.label}</InputLabel>
      <Select
        variant={variant}
        {...otherProps}
        {...selectProps}
        value={value?.toString() ?? ""}
        onChange={(e) => {
          const newValue = TypeHelper.parseBoolean(e.target.value?.toString(), { isLenient: true });
          onChange && onChange(newValue);
        }}
      >
        {options.map((x, i) => (
          <MenuItem key={i} value={x?.toString()}>
            {x === true ? "Yes" : x === false ? "No" : "-"}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import { apiClient } from "@/core/api/ApiClient";
import { AccessoryCheckDto, EntityType } from "@/core/api/generated";
import BaseEntitiesDeleteModal, {
  BaseEntitiesDeleteModalInheritableProps,
} from "../components/BaseEntitiesDeleteModal";

export interface OwnProps {
  entities: AccessoryCheckDto[];
}

type Props = OwnProps & BaseEntitiesDeleteModalInheritableProps;

export default function AccessoryChecksDeleteModal({ entities, ...otherProps }: Props) {
  return (
    <BaseEntitiesDeleteModal
      cascadeActionDisplay={true}
      entityType={EntityType.AccessoryCheck}
      entities={entities}
      deleteFunc={(params) =>
        apiClient.accessoryChecksApi.apiV1AccessoryChecksBulkDeleteDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          entityBulkDeleteDto: {
            ids: params.entityIds,
            relatedEntitiesTypesToDelete: params.relatedEntitiesTypes,
          },
        })
      }
      {...otherProps}
    />
  );
}

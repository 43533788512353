import { LocationDto } from "@/core/api/generated";
import BaseEntityAutocompleteOrCreate, {
  BaseEntityAutocompleteOrCreateInheritableProps,
} from "../components/BaseEntityAutocompleteOrCreate";
import LocationAutocomplete, { LocationAutocompleteProps } from "./LocationAutocomplete";
import LocationCreateUpdate, { LocationCreateUpdateProps } from "./LocationCreateUpdate";
import LocationCreateUpdateModal from "./LocationCreateUpdateModal";

type Props = BaseEntityAutocompleteOrCreateInheritableProps<
  LocationDto,
  LocationCreateUpdateProps["defaultValues"],
  LocationAutocompleteProps,
  Pick<LocationCreateUpdateProps, "defaultValues">
>;

export default function LocationAutocompleteOrCreate({
  autocompleteProps,
  createUpdateProps,
  createFormPlacement = "modal",
  onCreateStart,
  onCreate,
}: Props) {
  return (
    <BaseEntityAutocompleteOrCreate<
      LocationDto,
      LocationCreateUpdateProps["defaultValues"],
      LocationAutocompleteProps,
      Pick<LocationCreateUpdateProps, "defaultValues">
    >
      createFormPlacement={createFormPlacement}
      onCreateStart={onCreateStart}
      onCreate={onCreate}
      renderAutocomplete={(params) => (
        <LocationAutocomplete
          {...autocompleteProps}
          withCreate
          createOptionTitle={(inputValue) => `Create new location "${inputValue}"`}
          onCreate={(newOption) => {
            params.setDefaultValues({
              ...createUpdateProps?.defaultValues,
              name: newOption?.inputValue || createUpdateProps?.defaultValues?.name || undefined,
            });
            params.setIsCreate(true);
            params.onCreateStart && params.onCreateStart();
          }}
        />
      )}
      renderCreateUpdate={(params) => (
        <LocationCreateUpdate
          defaultValues={params.defaultValues}
          onCreate={params.onCreate}
          onSave={(newValue) => {
            params.setIsCreate(false);
          }}
        />
      )}
      renderCreateUpdateModal={(params) => (
        <LocationCreateUpdateModal
          open={params.isCreate}
          onClose={() => params.setIsCreate(false)}
          createUpdateProps={{
            defaultValues: params.defaultValues,
            onCreate: params.onCreate,
            onSave: (newValue) => {
              params.setIsCreate(false);
            },
          }}
        />
      )}
    />
  );
}

import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { clipboardService } from "@/common/services/clipboard";
import _ from "lodash";
import AppIcon from "../../Icons/AppIcon";

interface SecretValueToShow {
  value: string;
}

export interface TextFieldForSecretProps
  extends Omit<TextFieldProps, "type" | "value" | "startAdornment" | "endAdornment"> {
  value?: string | null;
  // withShowValue?: boolean;
  // withCopyValue?: boolean;
  onViewValueClick?: (
    currentValue: string | null | undefined,
  ) => SecretValueToShow | Promise<SecretValueToShow>;
}

/** Extends TextField to properly input and display secret value. */
export default function TextFieldForSecret({
  value,
  onViewValueClick,
  ...textFieldProps
}: TextFieldForSecretProps) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [isValueVisible, setIsValueVisible] = useState(false);
  const [valueToShow, setValueToShow] = useState<string | undefined | null>(undefined);

  const valueComputed = useMemo(
    () => (isValueVisible ? valueToShow : value),
    [value, valueToShow, isValueVisible],
  );

  const getSecretValue = useCallback(async () => {
    if (onViewValueClick) {
      setIsLoading(true);
      try {
        const toShow = await onViewValueClick(value);
        setValueToShow(toShow.value);
        return toShow.value;
      } finally {
        setIsLoading(false);
      }
    } else {
      setValueToShow(value);
      return value;
    }
  }, [value, isValueVisible, onViewValueClick]);

  return (
    <TextField
      type={isValueVisible ? "text" : "password"}
      value={valueComputed || ""}
      {...textFieldProps}
      InputProps={{
        // view value
        startAdornment: textFieldProps?.InputProps?.startAdornment || (
          <InputAdornment position='start'>
            {isLoading && <CircularProgress size={24} />}

            {!isLoading && (
              <Tooltip title={isValueVisible ? "Hide value" : "Show value"}>
                <IconButton
                  size={textFieldProps?.size}
                  disabled={_.isEmpty(value)}
                  onClick={async () => {
                    await getSecretValue();
                    setIsValueVisible(!isValueVisible);
                  }}
                >
                  <AppIcon of={isValueVisible ? "hide" : "view"} />
                </IconButton>
              </Tooltip>
            )}
          </InputAdornment>
        ),
        // copy value
        endAdornment: textFieldProps?.InputProps?.endAdornment || (
          <InputAdornment position='end'>
            <IconButton
              size={textFieldProps?.size}
              disabled={_.isEmpty(value)}
              onClick={async () => {
                try {
                  const secretValue = await getSecretValue();
                  await clipboardService.writeText(secretValue || "");
                  enqueueSnackbar("Value copied to clipboard.", {
                    variant: "success",
                  });
                } catch (error) {
                  console.error("Clipboard copy error", error);
                }
              }}
            >
              <AppIcon of='copy' />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

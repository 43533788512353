import { LocalStorageHelper } from "@/common/helpers/localStorage";
import { IUserI18nSettings } from "../ts/i18n";
import { LocalStorageKey } from "../constants/localStorage";

/** Internationalization helper. */
export class I18nHelper {
  /** Reads user's local i18n info. */
  public static getUserI18nSettingsLocal(): IUserI18nSettings | undefined {
    try {
      const serialized = LocalStorageHelper.getItem(LocalStorageKey.userI18nInfo);
      if (!serialized) {
        return undefined;
      }
      const value = JSON.parse(serialized) as IUserI18nSettings;
      return value;
    } catch (err) {
      console.error("Unable to read and parse user i18n info.", err);
      return undefined;
    }
  }

  /** Saves user's i18n info locally. */
  public static saveUserI18nSettingsLocally(value: IUserI18nSettings | undefined): void {
    const serialized = JSON.stringify(value);
    LocalStorageHelper.setItem(LocalStorageKey.userI18nInfo, serialized);
  }
}

import { Stack, Typography, TypographyProps } from "@mui/material";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import VersionDisplay from "@/common/components/Display/VersionDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { VisualModelImagesList } from "@/common/components/Images/VisualModelImagesList";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import {
  DataUpdatesHubClientMethodName,
  EntityType,
  VehicleVisualModelDto,
} from "@/core/api/generated";
import _ from "lodash";
import VehicleGenerationInline from "../../ReferenceData/VehicleGeneration/VehicleGenerationInline";
import VehicleMakeInline from "../../ReferenceData/VehicleMake/VehicleMakeInline";
import VehicleModelInline from "../../ReferenceData/VehicleModel/VehicleModelInline";
import VehicleModificationInline from "../../ReferenceData/VehicleModification/VehicleModificationInline";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";

export enum VehicleVisualModelViewPageTabs {
  Overview = "Overview",
  Pools = "Pools",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
};

interface OwnProps extends BaseEntityViewInheritableProps<VehicleVisualModelDto> {
  vehicleVisualModelId: string | null | undefined;
  vehicleVisualModel: VehicleVisualModelDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function VehicleVisualModelView({
  vehicleVisualModelId,
  vehicleVisualModel,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const currentTenant = useCurrentTenant();

  const visualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: vehicleVisualModelId!,
    },
    {
      deps: [vehicleVisualModelId],
      skip: !vehicleVisualModelId || !!vehicleVisualModel,
    },
  );
  vehicleVisualModel = vehicleVisualModel || visualModelRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.VehicleVisualModel,
        vehicleVisualModel?.id || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      visualModelRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        vehicleVisualModel && {
          idValue: vehicleVisualModel.id!,
          newTitle: vehicleVisualModel.name || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.VehicleVisualModel}
      entityId={vehicleVisualModelId}
      entity={vehicleVisualModel}
      entityRequest={visualModelRequest}
    >
      <Stack spacing={2}>
        {displayProps.header && (
          <SimpleViewPageHeader
            title={`Vehicle visual model ${
              vehicleVisualModel?.name || vehicleVisualModel?.globalNumber
            }`}
          />
        )}

        <Stack spacing={1}>
          <FieldValue label='Number'>{vehicleVisualModel?.globalNumber}</FieldValue>

          <FieldValue label='Name'>{vehicleVisualModel?.name}</FieldValue>

          <FieldValue label='Version'>
            <VersionDisplay value={vehicleVisualModel?.version} />
          </FieldValue>

          <FieldValue label='Type'>
            <InlineApiEnumValue type='VehicleVisualModelType' value={vehicleVisualModel?.type} />
          </FieldValue>

          <FieldValue label='Areas' isEmpty={_.isEmpty(vehicleVisualModel?.areas)}>
            <InlineApiEnumValueList type='VehicleArea' values={vehicleVisualModel?.areas} />
          </FieldValue>

          <FieldValue label='Projections' isEmpty={_.isEmpty(vehicleVisualModel?.projections)}>
            <InlineApiEnumValueList
              type='VehicleProjection'
              values={vehicleVisualModel?.projections}
            />
          </FieldValue>

          <FieldValue label='Vehicle types' isEmpty={_.isEmpty(vehicleVisualModel?.vehicleTypes)}>
            <InlineApiEnumValueList type='VehicleType' values={vehicleVisualModel?.vehicleTypes} />
          </FieldValue>

          <FieldValue label='Body types' isEmpty={_.isEmpty(vehicleVisualModel?.bodyTypes)}>
            <InlineApiEnumValueList type='VehicleBodyType' values={vehicleVisualModel?.bodyTypes} />
          </FieldValue>

          <FieldValue label='Makes' isEmpty={_.isEmpty(vehicleVisualModel?.makes)}>
            <Stack spacing={1}>
              {vehicleVisualModel?.makes?.map((x, i) => <VehicleMakeInline key={i} make={x} />)}
            </Stack>
          </FieldValue>

          <FieldValue label='Models' isEmpty={_.isEmpty(vehicleVisualModel?.models)}>
            <Stack spacing={1}>
              {vehicleVisualModel?.models?.map((x, i) => <VehicleModelInline key={i} model={x} />)}
            </Stack>
          </FieldValue>

          <FieldValue label='Generations' isEmpty={_.isEmpty(vehicleVisualModel?.generations)}>
            <Stack spacing={1}>
              {vehicleVisualModel?.generations?.map((x, i) => (
                <VehicleGenerationInline key={i} generation={x} />
              ))}
            </Stack>
          </FieldValue>

          <FieldValue label='Modifications' isEmpty={_.isEmpty(vehicleVisualModel?.modifications)}>
            <Stack spacing={1}>
              {vehicleVisualModel?.modifications?.map((x, i) => (
                <VehicleModificationInline key={i} modification={x} />
              ))}
            </Stack>
          </FieldValue>
        </Stack>

        <Typography variant='h4'>Images</Typography>
        {vehicleVisualModel && (
          <VisualModelImagesList
            visualModel={vehicleVisualModel}
            imageContainerSx={{
              height: `200px`,
              width: `200px`,
            }}
          />
        )}
      </Stack>
    </BaseEntityView>
  );
}

import { LoadingButton } from "@mui/lab";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { ValidationHelper } from "@/common/validation";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import {
  AppPermission,
  CheckoutManualDto,
  SubscriptionDto,
  SubscriptionPlanDto,
  TenantPaymentProfileDto,
} from "../../../../../core/api/generated";

interface PlanProps {
  plan: SubscriptionPlanDto;
  selectSubscriptionPlan: (data: CheckoutManualDto) => void;
  currentSubscription: SubscriptionDto | undefined;
  getCurrentSubscription: () => void;
  paymentProfile?: TenantPaymentProfileDto;
  redirectToAppPaymentProfileEdit: () => void;
}

function Plan({
  plan,
  selectSubscriptionPlan,
  paymentProfile,
  currentSubscription,
  getCurrentSubscription,
  redirectToAppPaymentProfileEdit,
}: PlanProps) {
  const { enqueueSnackbar } = useAppSnackbar();

  const { id, name, description, prices, modules } = plan;
  const { plan: currentPlan } = currentSubscription || {};
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState("");
  const isSelected = currentPlan && currentPlan.id === id;

  useEffect(() => {
    if (prices && prices.length > 0) {
      setSelectedPriceId(prices[0].id || "");
    }
  }, [prices]);

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: (theme) => `${theme.shape.borderRadius}px`,
        borderColor: (theme) => {
          if (isSelected) {
            return theme.palette.primary.main;
          }
          return "transparent";
        },
        boxShadow: (theme) => (theme.shadows as string[])[1],
      }}
    >
      <Typography component='div' sx={{ mb: 1 }}>
        {name}
      </Typography>
      <Typography component='div' sx={{ mb: 1 }}>
        {description}
      </Typography>

      <TextField
        sx={{ width: "200px", mb: 1 }}
        select
        label='Pricing period'
        value={isSelected ? currentPlan.price?.id : selectedPriceId || ""}
        onChange={(e) => {
          setSelectedPriceId(e.target.value);
        }}
        variant='outlined'
        margin='normal'
      >
        {prices?.map((price) => (
          <MenuItem key={price.id} value={price.id || ""}>
            {price.period}
          </MenuItem>
        ))}
      </TextField>

      {/* Don't show features for now */}
      {/* <Typography component='div' sx={{ mb: 1 }}>Features:</Typography>
      <Box component='ul' sx={{ mt: 0 }}>
        {modules?.map((m, index) => {
          return (
            <Box key={index} component='li'>
              {m}
            </Box>
          );
        })}
      </Box> */}

      <Typography component='div' sx={{ mb: 1 }}>{`Price: ${
        prices?.find((p) => p.id === selectedPriceId)?.price
      }`}</Typography>

      {isSelected && (
        <Typography component='div' color='primary' sx={{ m: "auto" }}>
          {`Selected (${currentSubscription?.status})`}
        </Typography>
      )}

      {!isSelected && (
        <AuthorizedElement permissions={[AppPermission.TenantSubscriptionManage]}>
          <LoadingButton
            sx={{ mt: "auto" }}
            variant='contained'
            loading={isLoading}
            onClick={async () => {
              try {
                if (paymentProfile?.businessInfo?.email) {
                  setIsLoading(true);
                  await selectSubscriptionPlan({
                    subscriptionPlanId: id,
                    priceId: selectedPriceId,
                  });
                  await getCurrentSubscription();
                  enqueueSnackbar(
                    "New subscription plan selected. To activate it, please checkout the invoice we sent to you.",
                    { variant: "success" },
                  );
                } else {
                  await redirectToAppPaymentProfileEdit();
                  enqueueSnackbar(
                    "To be able to select subscription plan, please configure payment profile.",
                    { variant: "warning" },
                  );
                }
              } catch (err) {
                console.error(err);
                const validation2 = ValidationHelper.handleApiErrorResponse(err);
                validation2.hasErrors &&
                  enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
              } finally {
                setIsLoading(false);
              }
            }}
          >
            Select plan
          </LoadingButton>
        </AuthorizedElement>
      )}
    </Box>
  );
}

export default Plan;

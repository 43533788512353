import {
  Autocomplete,
  Box,
  CircularProgress,
  DialogContent,
  FormControl,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppModal, { AppModalProps } from "@/common/components/Modals/AppModal";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { TextHelper } from "@/common/helpers/text";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { DepartmentDto, UserDto } from "@/core/api/generated";
import { LoadingButton } from "@mui/lab";

interface Props extends Omit<AppModalProps, "children"> {
  department: DepartmentDto;
  onAdded?: () => void;
}

export default function DepartmentAddUsersModal({ department, onAdded, ...modalProps }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState<UserDto[]>([]);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const paginatedUserCandidatesRequest = useApiRequest(
    apiClient.departmentsApi.apiV1DepartmentsDepartmentIdUsersCandidatesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      departmentId: department.id!,
      userGetPaginatedDto: {
        offset: 0,
        limit: 25,
        search: inputValue,
      },
    },
    {
      deps: [department.id],
      debouncedDeps: {
        deps: [inputValue],
        wait: 500,
      },
    },
  );
  const paginatedUserCandidates = paginatedUserCandidatesRequest?.data;
  const candidates = paginatedUserCandidates?.items;

  const reset = () => {
    setSelectedCandidates([]);
    setInputValue("");
  };

  useEffect(() => {
    if (!modalProps.open) {
      reset();
    }
  }, [modalProps.open]);

  const handleAdd = async () => {
    setIsSubmitting(true);

    try {
      await apiClient.departmentsApi.apiV1DepartmentsDepartmentIdUsersPost({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        departmentId: department.id!,
        addDepartmentUsersDto: {
          userIds: selectedCandidates.map((x) => x.id!),
        },
      });
      enqueueSnackbar(
        `${TextHelper.pluralize("User", selectedCandidates.length)} added to department`,
        {
          variant: "success",
        },
      );
      modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown");
      onAdded && onAdded();
      reset();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AppModal fullWidth keepMounted={false} {...modalProps}>
      <AppModalTitle
        title={"Add users to department"}
        withCloseIcon
        onCloseClicked={() => modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown")}
      />
      <DialogContent>
        <Stack sx={{ mt: 1 }} spacing={2}>
          <Box>
            <FormControl margin='dense' fullWidth>
              <Autocomplete
                fullWidth
                multiple
                open={autoCompleteOpen}
                onOpen={() => setAutoCompleteOpen(true)}
                onClose={() => setAutoCompleteOpen(false)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.personName!.name!}
                filterOptions={(x) => x}
                options={candidates || []}
                autoComplete
                includeInputInList
                value={selectedCandidates}
                onChange={(e, newOptions) => {
                  setSelectedCandidates(newOptions);
                }}
                onInputChange={(e, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Users'
                    placeholder='Search by name or email...'
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {paginatedUserCandidatesRequest.isLoading ? (
                            <CircularProgress color='inherit' size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <ListItem {...props} key={option.id}>
                      <ListItemIcon>
                        <AppIcon of='person' color='secondary' />
                      </ListItemIcon>
                      <ListItemText primary={option.personName?.name} secondary={option.email} />
                    </ListItem>
                  );
                }}
              />
            </FormControl>
          </Box>

          <LoadingButton
            fullWidth
            variant='contained'
            disabled={selectedCandidates.length === 0}
            loading={isSubmitting}
            onClick={handleAdd}
          >
            Save
          </LoadingButton>
        </Stack>
      </DialogContent>
    </AppModal>
  );
}

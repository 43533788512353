import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import AssetPaginatedList from "@/common/components/Entity/Asset/ListView/AssetPaginatedList";

export default function AssetsPage() {
  return (
    <>
      <AssetPaginatedList />
    </>
  );
}

import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogProps,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { ROUTE_PATH } from "@/common/constants/routing";
import { HighlightHelper } from "@/common/helpers/highlight";
import useMounted from "@/common/hooks/mount/useMounted";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  RepairOperationAddVehicleDamagesDto,
  RepairOperationDto,
  RepairOperationStage,
  VehicleDamageDto,
  VehicleDamageState,
} from "@/core/api/generated";

import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import AuthorizedElement from "../../Auth/AuthorizedElement";
import GeneralValidationError from "../../Error/GeneralValidationError";
import VehicleDamageLink from "../VehicleDamage/VehicleDamageLink";
import RepairOperationAutocompleteOrCreate from "./RepairOperationAutocompleteOrCreate";
import { RepairOperationViewPageHighlightProps } from "./View/RepairOperationView";

type OwnProps = {
  damages: VehicleDamageDto[];
  isRedirectToRepairOperation?: boolean;
  onSaved?: (repairOperation: RepairOperationDto) => void;
};

type Props = OwnProps & DialogProps;

export function RepairOperationAddVehicleDamagesModal({
  damages,
  isRedirectToRepairOperation = true,
  onSaved,
  ...dialogProps
}: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();

  const [isCreatingRepairOperation, setIsCreatingRepairOperation] = useState(false);
  const [repairOperation, setRepairOperation] = useState<RepairOperationDto | undefined>(undefined);

  const vehicleId = useMemo(
    () =>
      damages
        .map((x) => x.vehicle?.id || "")
        .filter(Boolean)
        .at(0),
    [damages],
  );
  const damageIds = useMemo(() => damages.map((x) => x.id || "").filter(Boolean), [damages]);

  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Add damages to a repair operation
      </AppModalTitle>
      <DialogContent>
        <AuthorizedElement permissions={[AppPermission.RepairOperationManage]} withAlert>
          <Stack spacing={1}>
            <Formik<
              RepairOperationAddVehicleDamagesDto &
                BaseFormikValues & {
                  repairOperationId?: string | undefined;
                }
            >
              enableReinitialize
              initialValues={{
                repairOperationId: undefined,
                vehicleDamageIds: damages?.map((x) => x.id!)?.filter(Boolean) || undefined,
                submit: "",
              }}
              validationSchema={Yup.object().shape({
                // repairOperationId: Yup.string().required("Required"),
              })}
              onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
                try {
                  const response =
                    await apiClient.repairOperationsApi.apiV1RepairOperationsRepairOperationIdDamagesAddPost(
                      {
                        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                        repairOperationId: values.repairOperationId || "",
                        repairOperationAddVehicleDamagesDto: {
                          vehicleDamageIds: values.vehicleDamageIds,
                        },
                      },
                    );
                  const responseRepairOperation = response.data;
                  const addedItem = _.reverse(responseRepairOperation.items || []).find((x) =>
                    damages.some((y) => y.id === x.damage?.id),
                  );

                  enqueueSnackbar("Successfully added.", {
                    variant: "success",
                  });

                  if (mounted.current) {
                    setStatus({ success: true });
                    setSubmitting(false);
                  }

                  onSaved && onSaved(repairOperation!);
                  dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");

                  if (isRedirectToRepairOperation) {
                    history.push(
                      ROUTE_PATH.REPAIR_OPERATION_VIEW(responseRepairOperation.id, {
                        highlightPropsEnc:
                          addedItem &&
                          HighlightHelper.encodePropsForQueryString<RepairOperationViewPageHighlightProps>(
                            {
                              itemId: addedItem?.id,
                            },
                          ),
                      }),
                    );
                  }
                } catch (err) {
                  if (mounted.current) {
                    ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                    setStatus({ success: false });
                    setSubmitting(false);
                  }
                }

                setSubmitting(false);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setErrors,
                setFieldValue,
                setValues,
              }) => {
                return (
                  // Don't use form here as we using nested form for repairOperation create
                  <Stack spacing={2}>
                    {/* Damages */}
                    <Stack spacing={2}>
                      <Typography variant='h2'>Damages</Typography>

                      <Stack spacing={1}>
                        {damages.map((damage, i) => (
                          <Stack spacing={1} key={i}>
                            <VehicleDamageLink vehicleId={damage.vehicle?.id} entity={damage} />

                            {damage?.state === VehicleDamageState.Repaired && (
                              <Alert severity='warning'>This damage is already repaired.</Alert>
                            )}
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>

                    {/* RepairOperation */}
                    <Box>
                      <FormControl
                        margin='dense'
                        fullWidth
                        error={Boolean(errors.repairOperationId)}
                      >
                        <RepairOperationAutocompleteOrCreate
                          autocompleteProps={{
                            entityId: values.repairOperationId,
                            searchFilters: {
                              vehicleId: vehicleId || undefined,
                              excludeStage: RepairOperationStage.Completed,
                            },
                            onChange: (newValue) => {
                              setFieldValue(`repairOperationId`, newValue?.id);
                              setRepairOperation(newValue || undefined);
                            },
                          }}
                          createUpdateProps={{
                            defaultValues: {
                              vehicleId: vehicleId || undefined,
                              vehicleDamageIds: damageIds,
                            },
                          }}
                          createFormPlacement='page'
                          onCreateStart={() => setIsCreatingRepairOperation(true)}
                          onCreate={(newValue) => {
                            setFieldValue(`repairOperationId`, newValue?.id);
                            setRepairOperation(newValue);
                            setIsCreatingRepairOperation(false);
                          }}
                        />
                        <FormHelperText>{errors.repairOperationId}</FormHelperText>
                      </FormControl>
                    </Box>

                    <GeneralValidationError errors={errors} />

                    {!isCreatingRepairOperation && (
                      <LoadingButton
                        sx={{ flex: 1 }}
                        fullWidth
                        color='primary'
                        disabled={!values.repairOperationId}
                        loading={isSubmitting}
                        type='button'
                        variant='contained'
                        onClick={() => handleSubmit()}
                      >
                        Save
                      </LoadingButton>
                    )}
                  </Stack>
                );
              }}
            </Formik>
          </Stack>
        </AuthorizedElement>
      </DialogContent>
    </Dialog>
  );
}

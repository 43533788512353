import { FilterHelper } from "@/common/helpers/filter";
import { FilterOperator } from "@/core/api/generated";
import { SxProps, Theme, Typography } from "@mui/material";

interface Props {
  operator: FilterOperator;
  sx?: SxProps<Theme>;
}

export default function FilterOperatorDisplayName({ operator, sx }: Props) {
  return (
    <Typography component='span' sx={sx}>
      {FilterHelper.getOperatorDisplayName(operator)}
    </Typography>
  );
}

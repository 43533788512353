import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import PoolItemCreateFromEntity, {
  PoolItemCreateFromEntityProps,
} from "./PoolItemCreateFromEntity";

export interface OwnProps {
  createProps: PoolItemCreateFromEntityProps;
}

type Props = OwnProps & DialogProps;

export default function PoolItemCreateFromEntityModal({ createProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Add entity to a pool (create new pool item)
      </AppModalTitle>
      <DialogContent>
        <Box>
          <PoolItemCreateFromEntity {...createProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

import { Stack, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

import AppBreadcrumbs from "@/common/components/AppBreadcrumbs";
import { IBasePageLayout } from "@/common/ts/layout";

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  body: true,
  footer: true,
};

export interface BaseGoBackPageLayoutProps extends IBasePageLayout {
  withGoBack?: boolean;
  goBackProps?: {
    title?: string | ReactNode;
  };
  displayProps?: Partial<typeof defaultDisplayProps>;
  sx?: SxProps<Theme>;
}

export default function BaseGoBackPageLayout({
  breadcrumbs,
  header,
  body,
  footer,
  withGoBack = true,
  goBackProps = {
    title: "Go back",
  },
  displayProps = defaultDisplayProps,
  sx,
  children,
}: BaseGoBackPageLayoutProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const bodyComputed = children || body;

  return (
    <Stack direction='column' sx={sx}>
      <Stack
        direction='row'
        sx={{ px: 3, pt: 2, alignItems: "center", background: (t) => t.palette.background.paper }}
      >
        {/* {withGoBack && (
          <Typography
            variant='subtitle1'
            color='text.secondary'
            sx={{ display: "flex", alignItems: "center" }}
          >
            <GoBackButton size='small' title={goBackProps?.title} />
          </Typography>
        )}

        {withGoBack && <span>|</span>} */}

        {displayProps?.breadcrumbs && <AppBreadcrumbs replacements={breadcrumbs?.replacements} />}
      </Stack>

      <Stack spacing={2}>
        {displayProps?.header && header}
        {displayProps?.body && bodyComputed}
        {displayProps?.footer && footer}
      </Stack>
    </Stack>
  );
}

import { GeneralCultureDto } from "@/core/api/generated";

import { apiClient } from "@/core/api/ApiClient";
import { useApiRequest } from "../api/useApiRequest";

/** Returns default culture that is used when user haven't specified it explicitly in the settings. */
export const useDefaultCulture = (): GeneralCultureDto | undefined | null => {
  const request = useApiRequest(
    apiClient.generalReferenceDataApi.apiV1ReferenceDataGeneralCulturesDefaultGet,
    {},
  );
  return request.data;
};

import { SxProps, Theme } from "@mui/material";
import { useState } from "react";

import { DamagePointInfoModal } from "./DamagePointInfoModal";
import DamagePointsVisualizer from "./DamagePointsVisualizer";
import { ExtendedDamageType } from "../Entity/DamageDetection/DamageDetectionCreateUpdate";

interface Props {
  imageUrl?: string | null;
  containerSx?: SxProps<Theme>;
  pointsInfos: Array<any>;
  selectedPerspective?: boolean;
  onPointClicked: (pointInfo: any) => void;
}

export const DamagePointsViewer = ({
  imageUrl,
  containerSx,
  pointsInfos,
  selectedPerspective = false,
  onPointClicked,
}: Props) => {
  const [isViewingModalOpened, setIsViewingModalOpened] = useState(false);
  const [viewingPointInfo, setViewingPointInfo] = useState<ExtendedDamageType | null>(null);

  return (
    <>
      <DamagePointsVisualizer
        pointsInfo={pointsInfos}
        imageUrl={imageUrl}
        containerSx={containerSx}
        selectedPerspective={selectedPerspective}
        onPointClicked={(pointInfo) => {
          setViewingPointInfo(pointInfo);
          onPointClicked(pointInfo);
          setIsViewingModalOpened(true);
        }}
      />

      <DamagePointInfoModal
        damagePointInfo={viewingPointInfo}
        open={isViewingModalOpened}
        onClose={() => setIsViewingModalOpened(false)}
      />
    </>
  );
};

import { apiClient } from "@/core/api/ApiClient";
import { EntityType, RepairSpecDto } from "@/core/api/generated";
import { AxiosResponse } from "axios";
import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: RepairSpecDto;
  deleteFunc?: (params: { repairSpecId: string }) => Promise<AxiosResponse<unknown, unknown>>;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function RepairSpecDeleteModal({ entity, deleteFunc, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.RepairOperation}
      entityId={entity.id}
      entity={entity}
      deleteFunc={(params) =>
        deleteFunc
          ? deleteFunc({
              repairSpecId: entity.id!,
            })
          : apiClient.repairSpecsApi.apiV1RepairSpecsRepairSpecIdDelete({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              repairSpecId: entity.id!,
            })
      }
      {...otherProps}
    />
  );
}

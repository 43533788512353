import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssetSubscriptionPlanDto,
  AssetSubscriptionPlanSearchPaginatedDto,
} from "@/core/api/generated";

export type AssetSubscriptionPlanAutocompleteOption =
  BaseAutocompleteOption<AssetSubscriptionPlanDto>;

export const getAssetSubscriptionOptionTitle = (data: AssetSubscriptionPlanDto): string =>
  StringHelper.joinIntoString([data.name], ", ", {
    skipEmpty: true,
  });

export const assetSubscriptionPlanToOption = (
  data: AssetSubscriptionPlanDto,
): AssetSubscriptionPlanAutocompleteOption => ({
  id: data.id!,
  title: getAssetSubscriptionOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface AssetSubscriptionPlanAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<AssetSubscriptionPlanDto> {
  searchFilters?: Partial<Omit<AssetSubscriptionPlanSearchPaginatedDto, "search" | "includeIds">>;
}

export default function AssetSubscriptionPlanAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: AssetSubscriptionPlanAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={assetSubscriptionPlanToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.assetSubscriptionPlansApi.apiV1AssetSubscriptionPlansSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          assetSubscriptionPlanSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          assetSubscriptionPlanSearchPaginatedDto: {
            ...params.assetSubscriptionPlanSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Asset subscription plan'
      placeholder='Search for asset subscription plan...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='assetSubscriptionPlan' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body2' color='secondary'>
                      {option.data?.description}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

import { Box, Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import AccessoryCheckPaginatedList from "../../Entity/AccessoryCheck/ListView/AccessoryCheckPaginatedList";
import DamageCostEvaluationPaginatedList from "../../Entity/DamageCostEvaluation/ListView/DamageCostEvaluationPaginatedList";
import DamageDetectionPaginatedList from "../../Entity/DamageDetection/ListView/DamageDetectionPaginatedList";
import RepairOperationPaginatedList from "../../Entity/RepairOperation/ListView/RepairOperationPaginatedList";
import VisualInspectionPaginatedList from "../../Entity/VisualInspection/ListView/VisualInspectionPaginatedList";

export enum VehicleOperationsTabs {
  VisualInspections = "VisualInspections",
  DamageDetections = "DamageDetections",
  DamageCostEvaluations = "DamageCostEvaluations",
  AccessoryChecks = "AccessoryChecks",
  RepairOperations = "RepairOperations",
}

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Tab,
  header: true,
  filters: true,
};

interface OwnProps {
  vehicleId: string | null | undefined;
  contractId?: string | null;
  anyVehicleDamageIds?: string[] | null;
  displayProps?: Partial<typeof defaultDisplayProps>;
  onlyTabs?: VehicleOperationsTabs[];
  excludeTabs?: VehicleOperationsTabs[];
}

type Props = OwnProps;

export default function VehicleOperations({
  vehicleId,
  contractId,
  anyVehicleDamageIds,
  displayProps = defaultDisplayProps,
  onlyTabs,
  excludeTabs,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forView("VehicleOperations"),
      viewVariant: displayProps.viewVariant,
    },
  });

  return (
    <Box>
      <Stack spacing={2}>
        <PageTabs
          tabIdsDefinition={VehicleOperationsTabs}
          defaultTabId={VehicleOperationsTabs.VisualInspections}
          onlyTabIds={onlyTabs}
          excludeTabIds={excludeTabs}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Visual inspections", value: VehicleOperationsTabs.VisualInspections },
            { label: "Damage detections", value: VehicleOperationsTabs.DamageDetections },
            {
              label: "Damage cost evaluations",
              value: VehicleOperationsTabs.DamageCostEvaluations,
            },
            { label: "Accessory checks", value: VehicleOperationsTabs.AccessoryChecks },
            { label: "Repair operations", value: VehicleOperationsTabs.RepairOperations },
          ]}
        >
          {({ activeTabId: activeTab }) => (
            <>
              {activeTab === VehicleOperationsTabs.VisualInspections && (
                <PageTabContent>
                  <VisualInspectionPaginatedList
                    defaultValues={{
                      vehicleId,
                      contractId,
                    }}
                    displayProps={{
                      viewVariant: displayProps?.viewVariant,
                      header: displayProps.header,
                      filters: displayProps.filters,
                    }}
                  />
                </PageTabContent>
              )}
              {activeTab === VehicleOperationsTabs.DamageDetections && (
                <PageTabContent>
                  <DamageDetectionPaginatedList
                    defaultValues={{
                      vehicleId,
                      contractId,
                      anyVehicleDamageIds,
                    }}
                    displayProps={{
                      viewVariant: displayProps?.viewVariant,
                      header: displayProps.header,
                      filters: displayProps.filters,
                    }}
                  />
                </PageTabContent>
              )}
              {activeTab === VehicleOperationsTabs.DamageCostEvaluations && (
                <PageTabContent>
                  <DamageCostEvaluationPaginatedList
                    defaultValues={{
                      vehicleId,
                      contractId,
                      anyVehicleDamageIds,
                    }}
                    displayProps={{
                      viewVariant: displayProps?.viewVariant,
                      header: displayProps.header,
                      filters: displayProps.filters,
                    }}
                  />
                </PageTabContent>
              )}
              {activeTab === VehicleOperationsTabs.AccessoryChecks && (
                <PageTabContent>
                  <AccessoryCheckPaginatedList
                    defaultValues={{ vehicleId, contractId }}
                    displayProps={{
                      viewVariant: displayProps?.viewVariant,
                      header: displayProps.header,
                      filters: displayProps.filters,
                    }}
                  />
                </PageTabContent>
              )}
              {activeTab === VehicleOperationsTabs.RepairOperations && (
                <PageTabContent>
                  <RepairOperationPaginatedList
                    defaultValues={{
                      vehicleId,
                      contractId,
                      anyVehicleDamageIds,
                    }}
                    displayProps={{
                      viewVariant: displayProps?.viewVariant,
                      header: displayProps.header,
                      filters: displayProps.filters,
                    }}
                  />
                </PageTabContent>
              )}
            </>
          )}
        </PageTabs>
      </Stack>
    </Box>
  );
}

import { SxProps, Theme } from "@mui/material";
import { DATETIME_FORMATS } from "@/common/constants/common";
import EntityChangedByInfoDisplay, { Entity } from "./EntityChangedByInfoDisplay";
import { ResponsiveStyleValueCustom } from "@/theme/types";

export interface EntityCreatedByInfoDisplayProps {
  entity: Entity | null | undefined;
  dateFormat?: string | null | undefined;
  direction?: ResponsiveStyleValueCustom<"row" | "column">;
  sx?: SxProps<Theme>;
}

/** Shows who created the entity. */
export default function EntityCreatedByInfoDisplay({
  entity,
  dateFormat = DATETIME_FORMATS.DISPLAY_DATETIME,
  direction,
  sx,
}: EntityCreatedByInfoDisplayProps) {
  return (
    <EntityChangedByInfoDisplay
      dateFormat={dateFormat}
      datetime={entity?.createdAt}
      userId={entity?.createdBy}
      direction={direction}
      sx={sx}
    />
  );
}

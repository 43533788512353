import { BaseFormikValues, ValidationErrors } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { Box, FormHelperText, Stack, SxProps, Theme } from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";
import AppIcon from "../Icons/AppIcon";
import AppLink from "../Link/AppLink";
import ValidationInfoModal from "./ValidationInfoModal";

interface Props {
  errors: ValidationErrors<BaseFormikValues> | null | undefined;
  withDetails?: boolean;
  sx?: SxProps<Theme>;
}

/** Displays general validation error.
 *  E.g. form-level error, not related to specific field.
 */
export default function GeneralValidationError({ errors, withDetails = true, sx }: Props) {
  const [isValidationInfoModalOpen, setIsValidationInfoModalOpen] = useState(false);

  const generalError = useMemo(() => ValidationHelper.getGeneralErrorsAsString(errors), [errors]);
  const validationInfo = useMemo(
    () => ValidationHelper.getValidationInfoFromValidationErrors(errors),
    [errors],
  );
  const isShowDetails = withDetails && !!validationInfo;

  if (!errors || _.isEmpty(generalError)) {
    return null;
  }

  return (
    <Box sx={sx}>
      <FormHelperText component='div' error>
        <Stack spacing={0.5}>
          <Box>{generalError}</Box>

          {isShowDetails && (
            <Box>
              <AppLink
                to='#'
                isInheritStyles
                onClick={(e) => {
                  e.preventDefault();
                  setIsValidationInfoModalOpen(true);
                }}
              >
                <AppIcon of='report' /> Report the issue
              </AppLink>
            </Box>
          )}

          {/* {isShowDetails && (
          <Button
            variant='text'
            size='small'
            startIcon={<AppIcon of='view' />}
            onClick={() => {
              setIsValidationInfoModalOpen(true);
            }}
          >
            View error details
          </Button>
        )} */}
        </Stack>
      </FormHelperText>

      {isShowDetails && validationInfo && (
        <ValidationInfoModal
          open={isValidationInfoModalOpen}
          onClose={() => {
            setIsValidationInfoModalOpen(false);
          }}
          problemDetails={validationInfo.problemDetails}
          validationInfo={validationInfo}
        />
      )}
    </Box>
  );
}

import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import VehicleDamagePaginatedList from "@/common/components/Entity/VehicleDamage/VehicleDamagePaginatedList";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface VehicleDamagesPageRouteParams {
  vehicleId?: string;
}

export type VehicleDamagesPageQueryParams = GeneralQueryParams;

export default function VehicleDamagesPage() {
  const { vehicleId } = useParams<VehicleDamagesPageRouteParams>();
  const queryParams = useQueryParams<VehicleDamagesPageQueryParams>();

  return (
    <ViewPageLayout>
      <VehicleDamagePaginatedList
        vehicleId={vehicleId}
        headerProps={{
          withLink: false,
          typographyProps: {
            variant: "h4",
          },
        }}
      />
    </ViewPageLayout>
  );
}

import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import {
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
} from "@mui/material";
import _ from "lodash";
import { forwardRef, useEffect, useState } from "react";
import { AuthorizedElementProps } from "../Auth/AuthorizedElement";
import NoPermissionsAlert from "../Auth/NoPermissionsAlert";

interface OwnProps {
  disableAllEffects?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  /** When promise returned the item is considered as loading. */
  onClick?: MenuItemProps["onClick"] | (() => void | Promise<void>);
}

export type AppMenuItemProps = OwnProps &
  Partial<Pick<AuthorizedElementProps, "permissions" | "permissionsAny" | "withAlert">> &
  Omit<MenuItemProps, "onClick">;

export default forwardRef<HTMLLIElement, AppMenuItemProps>(function AppMenuItem(
  {
    disableAllEffects,
    isLoading,
    loadingText,
    onClick,
    children,
    permissions,
    permissionsAny,
    withAlert,
    ...menuItemProps
  }: AppMenuItemProps,
  ref,
) {
  const { hasPermissionsAllOrAny } = useAuthorizationInfo();

  const [_isLoading, setIsLoading] = useState(false);
  const isLoadingComputed = isLoading || _isLoading;

  const hasPermissions = hasPermissionsAllOrAny({
    permissions,
    permissionsAny,
  });

  useEffect(() => {
    if (!_.isNil(isLoading)) {
      setIsLoading(isLoading);
    }
  }, [isLoading]);

  if (!hasPermissions) {
    if (withAlert) {
      return <NoPermissionsAlert />;
    }
    return null;
  }

  return (
    <MenuItem
      {...menuItemProps}
      ref={ref}
      {...((disableAllEffects && {
        disableRipple: true,
        disableTouchRipple: true,
        focusRipple: false,
        centerRipple: false,
      }) ||
        {})}
      onClick={async (e) => {
        if (!onClick) {
          return;
        }

        setIsLoading(true);
        try {
          await onClick(e);
        } finally {
          setIsLoading(false);
        }
      }}
      sx={{
        ...menuItemProps?.sx,
        ...((disableAllEffects && {
          "&:hover": { background: "none", backgroundColor: "none" },
        }) ||
          {}),
      }}
    >
      {!isLoadingComputed && children}
      {isLoadingComputed && (
        <>
          <ListItemIcon>
            <CircularProgress color='inherit' size={20} />
          </ListItemIcon>
          <ListItemText>{loadingText || "Loading..."}</ListItemText>
        </>
      )}
    </MenuItem>
  );
});

import AppIcon from "@/common/components/Icons/AppIcon";
import { StringHelper } from "@/common/helpers/string";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { enumService } from "@/common/services/enum";
import { apiClient } from "@/core/api/ApiClient";
import { PoolItemDto, PoolItemReferenceDto } from "@/core/api/generated";
import GeneralStrictEntityRelationInline from "../General/Display/GeneralStrictEntityRelationInline";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";

export interface PoolItemInlineProps extends BaseEntityInlineInheritableProps {
  entity: PoolItemDto | PoolItemReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function PoolItemInline({ entity, entityId, ...otherProps }: PoolItemInlineProps) {
  const request = useApiRequest(
    apiClient.poolItemsApi.apiV1PoolItemsPoolItemIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolItemId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as PoolItemDto;
  const ref = entity as PoolItemReferenceDto;

  const minimalInfo = StringHelper.joinIntoString(
    [
      full?.entity?.nameComputed,
      enumService.getEnumValueName("PoolItemEntityType", ref?.entityType),
      enumService.getEnumValueName("PoolItemType", ref?.type),
      enumService.getEnumValueName("PoolItemStatus", full?.status),
    ],
    ", ",
    {
      skipEmpty: true,
    },
  );

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='poolItem' inText />}
      content={
        <>
          {full?.entity && (
            <>
              <GeneralStrictEntityRelationInline
                value={full?.entity}
                inlineProps={{ ...otherProps, withIcon: false }}
                {...otherProps}
              />
            </>
          )}

          {!full?.entity && <>{minimalInfo}</>}
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || `Pool item: ${minimalInfo}`}
    />
  );
}

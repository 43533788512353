import { Box, Chip, Popover, PopoverProps, Stack, Typography } from "@mui/material";

import { UserDto } from "@/core/api/generated";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import AppAvatar from "@/common/components/Avatar/AppAvatar";

interface Props extends PopoverProps {
  user?: UserDto;
}

export default function UserPopover({ user, ...popoverProps }: Props) {
  const profile = useUserProfile();

  const isMe = user && user?.id === profile?.id;

  return (
    <Popover
      sx={{
        pointerEvents: "none",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      disableRestoreFocus
      {...popoverProps}
    >
      <Box sx={{ minWidth: "200px", maxWidth: { sm: "95vw", md: "400px" }, p: 2 }}>
        <Stack direction='row' spacing={2}>
          <AppAvatar size='medium' user={user} withPopover={false} />

          <Box>
            <Typography component='div' variant='subtitle1'>
              {user?.personName?.name}{" "}
              {isMe && (
                <Chip size='small' color='primary' variant='outlined' label='You' sx={{ ml: 1 }} />
              )}
            </Typography>
            <Typography component='div' variant='body1'>
              {user?.email}
            </Typography>
            <Typography component='div' variant='body1'>
              {user?.phoneNumber}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Popover>
  );
}

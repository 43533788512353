import { InputProps, Stack, SxProps, TextField, Theme } from "@mui/material";
import { FormikHandlers, getIn } from "formik";
import { ReactElement, ReactNode } from "react";

import { ValidationHelper } from "@/common/validation";

import PhoneInput from "../Form/Input/PhoneInput";
import AppIcon from "../Icons/AppIcon";
import BaseContactCard from "./BaseContactCard";
import { ValidationErrors } from "@/common/ts/error";

export interface ContactData {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  // nationalIdentityNumber?: GeneralNationalIdentityNumberInputDto;
}

interface Props {
  value?: ContactData;
  action?: JSX.Element & ReactNode;
  errors?: ValidationErrors<ContactData>;
  onChange?: (newValue: ContactData) => void | FormikHandlers["handleChange"];
  inputsProps?: {
    /** Applied to all inputs */
    all?: {
      size?: InputProps["size"];
      onBlur?: InputProps["onBlur"];
    };
  } & Partial<
    Record<
      keyof ContactData,
      {
        name?: InputProps["name"];
        onChange?: InputProps["onChange"];
        onBlur?: InputProps["onBlur"];
      }
    >
  >;
  sx?: SxProps<Theme>;
  children?: ReactElement;
}

export default function ContactInput({
  value,
  action,
  errors,
  onChange,
  inputsProps,
  sx,
  children,
}: Props): JSX.Element {
  return (
    <BaseContactCard sx={sx}>
      <Stack direction='row' spacing={1}>
        <Stack direction='column'>
          <AppIcon of='person' fontSize='small' sx={{ mt: 0.5 }} />
        </Stack>
        <Stack direction='column' spacing={2}>
          <TextField
            error={Boolean(getIn(errors, "personName.firstName"))}
            fullWidth
            helperText={
              getIn(errors, "personName.firstName") &&
              ValidationHelper.getErrorsAsString(getIn(errors, "personName.firstName"))
            }
            label='First name'
            InputLabelProps={{
              sx: {
                background: "inherit",
              },
            }}
            margin='none'
            size={inputsProps?.all?.size || "small"}
            onBlur={inputsProps?.all?.onBlur}
            onChange={(e) =>
              onChange &&
              onChange({
                ...value,
                firstName: e.target.value,
              })
            }
            type='text'
            value={value?.firstName || ""}
            variant='outlined'
            {...inputsProps?.firstName}
          />

          <TextField
            error={Boolean(getIn(errors, "personName.lastName"))}
            fullWidth
            helperText={
              getIn(errors, "personName.lastName") &&
              ValidationHelper.getErrorsAsString(getIn(errors, "personName.lastName"))
            }
            label='Last Name'
            InputLabelProps={{
              sx: {
                background: "inherit",
              },
            }}
            margin='none'
            size={inputsProps?.all?.size || "small"}
            onBlur={inputsProps?.all?.onBlur}
            onChange={(e) =>
              onChange &&
              onChange({
                ...value,
                lastName: e.target.value,
              })
            }
            type='text'
            value={value?.lastName || ""}
            variant='outlined'
            {...inputsProps?.lastName}
          />

          <TextField
            error={Boolean(errors?.email)}
            fullWidth
            helperText={errors?.email && ValidationHelper.getErrorsAsString(errors?.email)}
            label='Email'
            InputLabelProps={{
              sx: {
                background: "inherit",
              },
            }}
            margin='none'
            size={inputsProps?.all?.size || "small"}
            onBlur={inputsProps?.all?.onBlur}
            onChange={(e) =>
              onChange &&
              onChange({
                ...value,
                email: e.target.value,
              })
            }
            type='email'
            value={value?.email || ""}
            variant='outlined'
            {...inputsProps?.email}
          />

          <PhoneInput
            error={Boolean(errors?.phoneNumber)}
            fullWidth
            helperText={
              errors?.phoneNumber && ValidationHelper.getErrorsAsString(errors?.phoneNumber)
            }
            label='Phone number'
            InputLabelProps={{
              sx: {
                background: "inherit",
              },
            }}
            margin='none'
            value={value?.phoneNumber || ""}
            variant='outlined'
            color='primary'
            placeholder='Phone number'
            size={inputsProps?.all?.size || "small"}
            onBlur={inputsProps?.all?.onBlur}
            onChange={(e, phone, iso) => {
              onChange &&
                onChange({
                  ...value,
                  phoneNumber: phone,
                });
            }}
            {...inputsProps?.phoneNumber}
          />

          {children && children}
        </Stack>
        <Stack direction='column' sx={{ justifyContent: "flex-start" }}>
          {action && action}
        </Stack>
      </Stack>
    </BaseContactCard>
  );
}

import { apiClient } from "@/core/api/ApiClient";
import {
  DamageDetectionAggregateDto,
  DamageDetectionAggregateItemDto,
  EntityType,
} from "@/core/api/generated";
import { Box } from "@mui/material";
import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: DamageDetectionAggregateDto;
  item: DamageDetectionAggregateItemDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function DamageDetectionAggregateDeleteModal({
  entity,
  item,
  onDelete,
  ...otherProps
}: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.DamageDetectionAggregate}
      entity={entity}
      entityId={entity.id}
      title='Delete item?'
      body={
        <Box>
          {`You're`} going to delete the item with number <strong>{item.localNumber}</strong>.
        </Box>
      }
      deleteFunc={async (params) => {
        await apiClient.damageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            aggregateId: params.entityId,
            itemId: item.id || "",
          },
        );
        onDelete && onDelete(params);
      }}
      {...otherProps}
    />
  );
}

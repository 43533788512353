import { useParams } from "react-router";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import PoolItemView from "@/common/components/Entity/PoolItem/View/PoolItemView";

export default function PoolItemViewPage() {
  const { poolId, poolItemId } = useParams<{ poolId: string; poolItemId?: string }>();

  return (
    <ViewPageLayout>
      <PoolItemView
        poolId={poolId}
        poolItemId={poolItemId}
        poolItem={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </ViewPageLayout>
  );
}

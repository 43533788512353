import {
  Autocomplete,
  Box,
  ListItem,
  ListItemText,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";

import { TimeZoneHelper } from "@/common/helpers/timeZone";
import { CustomUtcOffset } from "@/common/ts/datetime";

interface TimezoneAutocompleteProps {
  tzId?: string | null;
  required: boolean;
  disabled?: boolean;
  textFieldProps?: Partial<TextFieldProps>;
  onChange?: (newValue: TimezoneOption | undefined | null) => void;
}

interface TimezoneOption {
  name: string;
  value: string;
  currentOffset: CustomUtcOffset;
}

export default function TimezoneAutocomplete({
  tzId,
  disabled,
  required,
  textFieldProps,
  onChange,
}: TimezoneAutocompleteProps) {
  const options = TimeZoneHelper.getTzList().map((tz) => {
    const now = moment.tz(tz.id);
    return {
      name: tz.displayName,
      value: tz.id,
      currentOffset: TimeZoneHelper.getTzUtcOffset(tz.id, now),
    };
  });

  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<TimezoneOption | null>(
    options.find((option) => option.name === tzId) || null,
  );
  const [inputValue, setInputValue] = useState<string>("");
  const [timezones, setTimezones] = useState<TimezoneOption[] | null>(options);

  const searchOptionsDebounce = useCallback(
    () =>
      setTimezones(
        options.filter((timezone) =>
          timezone.name.toLowerCase().includes(inputValue.toLowerCase()),
        ),
      ),
    [inputValue],
  );

  useEffect(() => {
    if (selectedOption) {
      setTimezones(options);
      return;
    }

    searchOptionsDebounce();
  }, [inputValue, selectedOption]);

  return (
    <Box>
      <Autocomplete
        disabled={disabled}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => `${option.name} (${option.currentOffset.offsetStr})`}
        options={timezones || []}
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={selectedOption}
        inputValue={inputValue}
        onChange={(event, newValue) => {
          setSelectedOption(newValue);
          onChange && onChange(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...textFieldProps}
            {...params}
            required={required}
            label='Time zone'
            placeholder='Search...'
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {/* {isSearching ? <CircularProgress color='inherit' size={20} /> : null} */}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <ListItem {...props}>
              <ListItemText
                primary={
                  <Typography component='div' variant='body1'>
                    {`${option.name} (${option.currentOffset.offsetStr})`}
                  </Typography>
                }
                // secondary={
                //   <Typography component='div' variant='body2' color='secondary'>
                //     {`${option.name} (${option.currentOffset.offsetStr})`}
                //   </Typography>
                // }
              />
            </ListItem>
          );
        }}
      />
    </Box>
  );
}

import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function GaugeIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      color='secondary'
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M20.021 12.593C19.88 12.166 19.707 11.749 19.505 11.351L17.051 12.457C17.268 12.851 17.441 13.267 17.568 13.699L20.021 12.593ZM7.448 11.69C7.719 11.336 8.028 11.015 8.367 10.733L6.477 8.764C6.149 9.058 5.84 9.379 5.559 9.721L7.448 11.69ZM9.163 10.175C9.542 9.954 9.944 9.779 10.361 9.652L9.327 7.083C8.917 7.225 8.515 7.401 8.129 7.607L9.163 10.175ZM6.404 13.791C6.525 13.356 6.692 12.937 6.902 12.541L4.433 11.475C4.236 11.878 4.069 12.297 3.935 12.725L6.404 13.791ZM15.838 7.591C15.451 7.386 15.048 7.212 14.638 7.072L13.614 9.645C14.031 9.77 14.434 9.944 14.814 10.164L15.838 7.591ZM18.439 9.721C18.157 9.379 17.849 9.058 17.521 8.764L15.631 10.733C15.97 11.015 16.278 11.337 16.549 11.69L18.439 9.721ZM12.648 6.662C12.429 6.644 12.211 6.636 11.999 6.636C11.787 6.636 11.568 6.645 11.349 6.662V9.446C11.565 9.421 11.783 9.408 11.999 9.408C12.215 9.408 12.433 9.42 12.648 9.446V6.662ZM12 21C10.706 21 9.657 19.951 9.657 18.657C9.657 17.774 10.146 17.005 10.867 16.606L12 11L13.133 16.605C13.855 17.004 14.343 17.773 14.343 18.656C14.343 19.951 13.294 21 12 21ZM24 15C24 17.184 23.414 19.233 22.39 20.999L20.654 19.996C21.505 18.525 22 16.822 22 15C22 9.477 17.523 5 12 5C6.477 5 2 9.477 2 15C2 16.822 2.495 18.525 3.346 19.996L1.61 20.999C0.586 19.233 0 17.184 0 15C0 8.383 5.383 3 12 3C18.617 3 24 8.383 24 15Z' />
    </SvgIcon>
  );
});

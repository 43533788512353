import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { AdminVehicleBodyTypeDto, VehicleBodyTypeSearchPaginatedDto } from "@/core/api/generated";
import _ from "lodash";

type VehicleBodyTypeAutocompleteOption = BaseAutocompleteOption<AdminVehicleBodyTypeDto>;

const entityToOption = (data: AdminVehicleBodyTypeDto): VehicleBodyTypeAutocompleteOption => ({
  id: data.id!,
  title: StringHelper.joinIntoString([data.name], ", ", {
    skipEmpty: true,
  }),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface VehicleBodyTypeAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<AdminVehicleBodyTypeDto> {
  searchFilters?: Partial<Omit<VehicleBodyTypeSearchPaginatedDto, "search" | "includeIds">>;
}

export default function VehicleBodyTypeAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: VehicleBodyTypeAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={entityToOption}
      isPreload={isPreload}
      request={{
        requestFunc:
          apiClient.adminVehicleBodyTypesApi.apiV1AdminReferenceDataVehiclesBodyTypesSearchPost,
        limit: 25,
        parameters: {
          adminVehicleBodyTypeSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          adminVehicleBodyTypeSearchPaginatedDto: {
            ...params.adminVehicleBodyTypeSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Vehicle body type'
      placeholder='Search for vehicle body type...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='vehicle' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography component='div' variant='body1'>
                    {option.title}
                  </Typography>
                </>
              }
              secondary={
                <Typography component='div' variant='body2'>
                  {option.data?.description}
                </Typography>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

import { AppPermission, TenantRequestDto } from "@/core/api/generated";

import { Stack } from "@mui/material";
import { useCallback } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { apiClient } from "@/core/api/ApiClient";

import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import ConfirmationModalWithTrigger from "@/common/components/Modals/ConfirmationModalWithTrigger";
import { TenantRequestHelper } from "@/common/helpers/entity/tenantRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { ValidationHelper } from "@/common/validation";
import { TenantRequestResponseType, TenantRequestStage } from "@/core/api/generated";
import { enqueueSnackbar } from "notistack";
import AuthorizedElement from "../../Auth/AuthorizedElement";
import AppButton from "../../Button/AppButton";
import AppIconButton from "../../Button/AppIconButton";
import ChatActivator from "../Chat/ChatActivator";
import TenantRequestApplyResultControl from "./TenantRequestApplyResultControl";

interface Props {
  tenantRequest: TenantRequestDto;
  /** Either normal or icon buttons are rendered. */
  variant?: "normal" | "compact";
  onUpdate: (newValue: TenantRequestDto) => void;
}

/** Primary actions for controlling TenantRequest stage. */
export default function TenantRequestStagePrimaryActions({
  tenantRequest,
  variant = "normal",
  onUpdate,
}: Props) {
  const currentTenant = useCurrentTenant();

  const handleSend = useCallback(async () => {
    if (!tenantRequest) {
      return;
    }
    try {
      const response =
        await apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            tenantRequestId: tenantRequest.id!,
          },
        );
      enqueueSnackbar("Request sent. Wait for the answer.", { variant: "success" });
      onUpdate(response.data);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [tenantRequest]);

  const handleRespond = useCallback(
    async (responseType: TenantRequestResponseType, reason?: string) => {
      if (!tenantRequest) {
        return;
      }
      try {
        const response =
          await apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost(
            {
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              tenantRequestId: tenantRequest.id!,
              tenantRequestRespondDto: {
                responseType: responseType,
                reason: reason,
              },
            },
          );
        enqueueSnackbar(
          "Your response was successfully saved. You can now start working on the request.",
          { variant: "success" },
        );
        onUpdate(response.data);
      } catch (err) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        validation2.hasErrors &&
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      }
    },
    [tenantRequest],
  );

  const handleMoveToDraft = useCallback(async () => {
    if (!tenantRequest) {
      return;
    }
    try {
      const response =
        await apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            tenantRequestId: tenantRequest.id!,
          },
        );
      enqueueSnackbar("Request moved to draft.", { variant: "success" });
      onUpdate(response.data);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [tenantRequest]);

  const handleStart = useCallback(async () => {
    if (!tenantRequest) {
      return;
    }
    try {
      const response =
        await apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            tenantRequestId: tenantRequest.id!,
          },
        );
      enqueueSnackbar(
        "Request started (moved to in progress). When you are done, please complete it.",
        { variant: "success" },
      );
      onUpdate(response.data);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [tenantRequest]);

  const handleComplete = useCallback(async () => {
    if (!tenantRequest) {
      return;
    }
    try {
      const response =
        await apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            tenantRequestId: tenantRequest.id!,
          },
        );
      enqueueSnackbar("Request completed. Now, you need to report results to the sender.", {
        variant: "success",
      });
      onUpdate(response.data);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [tenantRequest]);

  const handleReport = useCallback(async () => {
    if (!tenantRequest) {
      return;
    }
    try {
      const response =
        await apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            tenantRequestId: tenantRequest.id!,
          },
        );
      enqueueSnackbar("Request reported. The sender needs to review the results.", {
        variant: "success",
      });
      onUpdate(response.data);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [tenantRequest]);

  const handleRework = useCallback(
    async (reason?: string) => {
      if (!tenantRequest) {
        return;
      }
      try {
        const response =
          await apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost(
            {
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              tenantRequestId: tenantRequest.id!,
              tenantRequestReworkDto: {
                reason: reason,
              },
            },
          );
        enqueueSnackbar(
          "Request returned on rework. The sender needs to revise the result and report again.",
          {
            variant: "success",
          },
        );
        onUpdate(response.data);
      } catch (err) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        validation2.hasErrors &&
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      }
    },
    [tenantRequest],
  );

  const handleClose = useCallback(
    async (reason?: string) => {
      if (!tenantRequest) {
        return;
      }
      try {
        const response =
          await apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost(
            {
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              tenantRequestId: tenantRequest.id!,
              tenantRequestCloseDto: {
                reason: reason,
              },
            },
          );
        enqueueSnackbar("Request closed. No more actions needed.", { variant: "success" });
        onUpdate(response.data);
      } catch (err) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        validation2.hasErrors &&
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      }
    },
    [tenantRequest],
  );

  return (
    <AuthorizedElement permissions={[AppPermission.TenantRequestManage]}>
      <Stack direction='row' spacing={1} alignItems='center'>
        {/* Send */}
        {TenantRequestHelper.isSentByMe(currentTenant, tenantRequest) &&
          tenantRequest?.stageHistory?.stage === TenantRequestStage.Draft && (
            <ConfirmationModalWithTrigger
              trigger={
                variant === "normal" ? (
                  <AppButton variant='contained' color='primary' startIcon={<AppIcon of='send' />}>
                    Send
                  </AppButton>
                ) : (
                  <AppIconButton
                    variant='outlined'
                    color='primary'
                    size='small'
                    tooltipProps={{ title: "Send" }}
                  >
                    <AppIcon of='send' />
                  </AppIconButton>
                )
              }
              title={`Send the request to company '${tenantRequest?.receiverTenant?.name}'?`}
              body={`You won't be able to edit the request once it is sent.`}
              onConfirm={handleSend}
            />
          )}

        {/* Respond */}
        {TenantRequestHelper.isReceivedByMe(currentTenant, tenantRequest) &&
          tenantRequest?.stageHistory?.stage === TenantRequestStage.Pending && (
            <>
              <ConfirmationModalWithTrigger
                trigger={
                  variant === "normal" ? (
                    <AppButton
                      variant='contained'
                      color='success'
                      startIcon={<AppIcon of='accept' />}
                    >
                      Accept
                    </AppButton>
                  ) : (
                    <AppIconButton
                      variant='outlined'
                      color='success'
                      size='small'
                      tooltipProps={{ title: "Accept" }}
                    >
                      <AppIcon of='accept' />
                    </AppIconButton>
                  )
                }
                title={`Accept the request?`}
                body={<>You accept the request.</>}
                withReasonText
                onConfirm={(params) =>
                  handleRespond(TenantRequestResponseType.Accept, params.reasonText)
                }
              />

              <ConfirmationModalWithTrigger
                trigger={
                  variant === "normal" ? (
                    <AppButton
                      variant='contained'
                      color='error'
                      startIcon={<AppIcon of='decline' />}
                    >
                      Decline
                    </AppButton>
                  ) : (
                    <AppIconButton
                      variant='outlined'
                      color='error'
                      size='small'
                      tooltipProps={{ title: "Decline" }}
                    >
                      <AppIcon of='decline' />
                    </AppIconButton>
                  )
                }
                title={`Decline the request?`}
                body={<>You decline the request.</>}
                withReasonText
                onConfirm={(params) =>
                  handleRespond(TenantRequestResponseType.Decline, params.reasonText)
                }
              />
            </>
          )}

        {/* Move to draft */}
        {TenantRequestHelper.isSentByMe(currentTenant, tenantRequest) &&
          tenantRequest?.stageHistory?.stage === TenantRequestStage.Declined && (
            <ConfirmationModalWithTrigger
              trigger={
                variant === "normal" ? (
                  <AppButton variant='contained' color='primary' startIcon={<AppIcon of='edit' />}>
                    Move to draft
                  </AppButton>
                ) : (
                  <AppIconButton
                    variant='outlined'
                    color='primary'
                    size='small'
                    tooltipProps={{ title: "Move to draft" }}
                  >
                    <AppIcon of='edit' />
                  </AppIconButton>
                )
              }
              title={`Move the request back to draft?`}
              body={
                <>
                  This will move it to{" "}
                  <strong>
                    <InlineApiEnumValue
                      type='TenantRequestStage'
                      value={TenantRequestStage.Draft}
                    />
                  </strong>
                  .
                </>
              }
              onConfirm={handleMoveToDraft}
            />
          )}

        {/* Start */}
        {TenantRequestHelper.isReceivedByMe(currentTenant, tenantRequest) &&
          tenantRequest?.stageHistory?.stage === TenantRequestStage.Accepted && (
            <ConfirmationModalWithTrigger
              trigger={
                variant === "normal" ? (
                  <AppButton variant='contained' color='primary' startIcon={<AppIcon of='start' />}>
                    Start
                  </AppButton>
                ) : (
                  <AppIconButton
                    variant='outlined'
                    color='primary'
                    size='small'
                    tooltipProps={{ title: "Start" }}
                  >
                    <AppIcon of='start' />
                  </AppIconButton>
                )
              }
              title={`Start working on the request?`}
              body={
                <>
                  This will move it to{" "}
                  <strong>
                    <InlineApiEnumValue
                      type='TenantRequestStage'
                      value={TenantRequestStage.InProgress}
                    />
                  </strong>
                  .
                </>
              }
              onConfirm={handleStart}
            />
          )}

        {/* Complete */}
        {TenantRequestHelper.isReceivedByMe(currentTenant, tenantRequest) &&
          tenantRequest?.stageHistory?.stage === TenantRequestStage.InProgress && (
            <ConfirmationModalWithTrigger
              trigger={
                variant === "normal" ? (
                  <AppButton
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='complete' />}
                  >
                    Complete
                  </AppButton>
                ) : (
                  <AppIconButton
                    variant='outlined'
                    color='primary'
                    size='small'
                    tooltipProps={{ title: "Complete" }}
                  >
                    <AppIcon of='complete' />
                  </AppIconButton>
                )
              }
              title={`Complete the request?`}
              body={
                <>
                  This will move it to{" "}
                  <strong>
                    <InlineApiEnumValue
                      type='TenantRequestStage'
                      value={TenantRequestStage.Completed}
                    />
                  </strong>
                  .
                </>
              }
              onConfirm={handleComplete}
            />
          )}

        {/* Report */}
        {TenantRequestHelper.isReceivedByMe(currentTenant, tenantRequest) &&
          tenantRequest?.stageHistory?.stage === TenantRequestStage.Completed && (
            <ConfirmationModalWithTrigger
              trigger={
                variant === "normal" ? (
                  <AppButton
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='report' />}
                  >
                    Report
                  </AppButton>
                ) : (
                  <AppIconButton
                    variant='outlined'
                    color='primary'
                    size='small'
                    tooltipProps={{ title: "Report" }}
                  >
                    <AppIcon of='report' />
                  </AppIconButton>
                )
              }
              title={`Report request results to the sender?`}
              body={
                <>
                  This will move it to{" "}
                  <strong>
                    <InlineApiEnumValue
                      type='TenantRequestStage'
                      value={TenantRequestStage.Reporting}
                    />
                  </strong>
                  .
                </>
              }
              onConfirm={handleReport}
            />
          )}

        {/* Close */}
        {TenantRequestHelper.isSentByMe(currentTenant, tenantRequest) &&
          tenantRequest?.stageHistory?.stage === TenantRequestStage.Reporting && (
            <>
              <ConfirmationModalWithTrigger
                trigger={
                  variant === "normal" ? (
                    <AppButton
                      variant='contained'
                      color='success'
                      startIcon={<AppIcon of='accept' />}
                    >
                      Accept & close
                    </AppButton>
                  ) : (
                    <AppIconButton
                      variant='outlined'
                      color='success'
                      size='small'
                      tooltipProps={{ title: "Accept & close" }}
                    >
                      <AppIcon of='accept' />
                    </AppIconButton>
                  )
                }
                title={`Close the request?`}
                body={
                  <>
                    You accept the result. This will move it to{" "}
                    <strong>
                      <InlineApiEnumValue
                        type='TenantRequestStage'
                        value={TenantRequestStage.Closed}
                      />
                    </strong>
                    .
                  </>
                }
                withReasonText
                onConfirm={(params) => handleClose(params.reasonText)}
              />

              <ConfirmationModalWithTrigger
                trigger={
                  variant === "normal" ? (
                    <AppButton
                      variant='contained'
                      color='error'
                      startIcon={<AppIcon of='decline' />}
                    >
                      Decline & send on rework
                    </AppButton>
                  ) : (
                    <AppIconButton
                      variant='outlined'
                      color='error'
                      size='small'
                      tooltipProps={{ title: "Decline & send on rework" }}
                    >
                      <AppIcon of='decline' />
                    </AppIconButton>
                  )
                }
                title={`Rework the request?`}
                body={
                  <>
                    You decline the result. The request will be returned on rework to the receiver.
                    This will move it to{" "}
                    <strong>
                      <InlineApiEnumValue
                        type='TenantRequestStage'
                        value={TenantRequestStage.InProgress}
                      />
                    </strong>
                    .
                  </>
                }
                withReasonText
                onConfirm={(params) => handleRework(params.reasonText)}
              />
            </>
          )}

        {/* Apply result */}
        {TenantRequestHelper.isSentByMe(currentTenant, tenantRequest) &&
          tenantRequest?.stageHistory?.stage === TenantRequestStage.Closed && (
            <TenantRequestApplyResultControl
              tenantRequest={tenantRequest}
              variant={variant}
              isForOperation
              buttonProps={{
                size: "small",
              }}
              onEntityUpdate={(newValue) => {
                onUpdate(newValue);
              }}
            />
          )}

        {/* Chat */}
        {tenantRequest?.chat && (
          <ChatActivator
            chatType={tenantRequest.chat.type!}
            chatId={tenantRequest.chat.id}
            chatPlacement='popover'
            to={undefined}
            withActivityIndicator
            tooltipTitle='Open chat'
            activatorButtonProps={{
              disableRipple: true,
            }}
          />
        )}
      </Stack>
    </AuthorizedElement>
  );
}

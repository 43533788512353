import { Box, FormHelperText } from "@mui/material";

import { renderIf } from "@/common/helpers/render/renderIf";
import { enumService } from "@/common/services/enum";
import { EntityType, GeneralEntitySubTypeInputDto } from "@/core/api/generated";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import ApiEnumAutocomplete, { ApiEnumAutocompleteProps } from "../../../Enum/ApiEnumAutocomplete";

type ApiEnumAutocompletePropsLocal = ApiEnumAutocompleteProps<"EntityType">;

export interface GeneralEntitySubTypeInputProps {
  entityType: EntityType;
  value: GeneralEntitySubTypeInputDto | null | undefined;
  isIgnoreIfSubTypeIsMissing?: boolean;
  autocompleteProps?: Partial<
    Pick<
      ApiEnumAutocompletePropsLocal,
      "disabled" | "required" | "size" | "label" | "textFieldProps"
    >
  >;
  onChange?: (newValue: GeneralEntitySubTypeInputDto | null | undefined) => void;
}

export default function GeneralEntitySubTypeInput({
  entityType,
  value,
  isIgnoreIfSubTypeIsMissing,
  autocompleteProps,
  onChange,
}: GeneralEntitySubTypeInputProps) {
  const autocompletePropsComputed = useMemo<Partial<ApiEnumAutocompletePropsLocal>>(
    () => ({
      label: `${enumService.getEnumValueName("EntityType", entityType)} type`,
      ...autocompleteProps,
      textFieldProps: {
        ...autocompleteProps?.textFieldProps,
      },
    }),
    [entityType, autocompleteProps],
  );

  // trigger initial change
  // useEffect(() => {
  //   if (_.isNil(value) || _.isNil(value.entityType)) {
  //     onChange &&
  //       onChange({
  //         ...value,
  //         entityType: entityType,
  //       });
  //   }
  // }, [entityType, value]);

  return (
    <Box>
      {renderIf()
        .if(entityType === EntityType.Asset)
        .then(
          <ApiEnumAutocomplete
            {...(autocompletePropsComputed as unknown as ApiEnumAutocompleteProps<"AssetEntityType">)}
            type='AssetEntityType'
            value={value?.assetEntityType}
            onChange={(newValue) => {
              onChange &&
                onChange({
                  ...value,
                  entityType: value?.entityType || entityType,
                  entitySubType: newValue || undefined,
                  assetEntityType: newValue || undefined,
                });
            }}
          />,
        )
        .elseif(entityType === EntityType.PoolItem)
        .then(
          <ApiEnumAutocomplete
            {...(autocompletePropsComputed as unknown as ApiEnumAutocompleteProps<"PoolItemEntityType">)}
            type='PoolItemEntityType'
            value={value?.assetEntityType}
            onChange={(newValue) => {
              onChange &&
                onChange({
                  ...value,
                  entityType: value?.entityType || entityType,
                  entitySubType: newValue || undefined,
                  poolItemEntityType: newValue || undefined,
                });
            }}
          />,
        )
        .else(
          isIgnoreIfSubTypeIsMissing ? null : (
            <FormHelperText error>No sub entity type for {entityType}.</FormHelperText>
          ),
        )
        .render()}
    </Box>
  );
}

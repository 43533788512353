import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridRowSelectionModel,
  GridSortModel,
  GridValidRowModel,
  DataGrid as MuiDataGrid,
  DataGridProps as MuiDataGridProps,
  useGridApiRef,
} from "@mui/x-data-grid";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";

import {
  CustomDataGridContext,
  CustomDataGridContextType,
  CustomDataGridContextValue,
} from "@/common/contexts/customDataGrid";
import { PaginationHelper } from "@/common/helpers/pagination";
import { SortHelper } from "@/common/helpers/sort";
import { TypeHelper } from "@/common/helpers/type";
import {
  DataGridBorderVariant,
  DataGridColumnVisibilityProps,
  DataGridColumnWidthProps,
  DataGridCustomSortProps,
  DataGridFilterProps,
  DataGridPaginationProps,
  DataGridQuickFilterProps,
  DataGridRefetchProps,
  GridRowHeightParamsCustom,
} from "@/common/ts/dataGrid";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { GridStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import NoDataAlert from "../AppAlerts/NoDataAlert";
import { DataListTabsProps } from "../DataList/DataList";
import CustomDataGridColumnMenu from "./CustomDataGridColumnMenu";
import CustomDataGridFooter from "./CustomDataGridFooter";
import CustomDataGridPagination from "./CustomDataGridPagination";
import CustomDataGridToolbar from "./CustomDataGridToolbar";

const rowCheckboxSelectionField = GRID_CHECKBOX_SELECTION_FIELD; // by default "__check__"
const rowNumberField = "__rowNumber__";
export const gridInternalFields = [rowCheckboxSelectionField, rowNumberField];
export const gridInternalFieldsMap = _.chain(gridInternalFields)
  .keyBy((x) => x)
  .mapValues((x) => true)
  .value();

/** MUI X: `pageSize` cannot exceed 100 in the MIT version of the DataGrid.
 * https://mui.com/x/react-data-grid/pagination/#size-of-the-page
 */
export const dataGridMaxPageSize = 100;
export const dataGridMaxSortItems = 1;
export const dataGridMaxFilterItems = 1;

export interface DataGridProps<TItem extends GridValidRowModel> {
  sx?: MuiDataGridProps<TItem>["sx"];
  columns: GridColDef<TItem>[];
  rows: TItem[] | null | undefined;
  isLoading?: boolean;
  /** Enables row selection via checkboxes.
   * @default false
   */
  isRowCheckboxSelectionEnabled?: boolean;
  /** Enables row numbers.
   * @default true
   */
  isRowNumbersEnabled?: boolean;
  /** Enables column menu.
   * @default true
   */
  isColumnMenuEnabled?: boolean;
  /** Enables column resize.
   * @default true
   */
  isColumnSelectorEnabled?: boolean;
  /** Enables column resize.
   * @default true
   */
  isColumnResizeEnabled?: boolean;
  /** Enables columns sort.
   * @default true
   */
  isColumnSortEnabled?: boolean;
  /** Enables column filter.
   * @default true
   */
  isColumnFilterEnabled?: boolean;
  /** Enables pagination.
   * @default true
   */
  isPaginationEnabled?: boolean;
  rowTo?: string | ((item: TItem) => string);
  columnVisibility?: DataGridColumnVisibilityProps;
  columnWidth?: DataGridColumnWidthProps;
  tabs?: DataListTabsProps;
  pagination?: DataGridPaginationProps;
  sort?: DataGridCustomSortProps;
  quickFilter?: DataGridQuickFilterProps;
  filters?: DataGridFilterProps;
  refetch?: DataGridRefetchProps;
  rowSelectionModel?: GridRowSelectionModel;
  // GridRowSelectionModel: <string[] | number[]>
  /** Sets the height in pixel of a row in the grid. */
  rowHeight?: number;
  /** @default none */
  borderVariant?: DataGridBorderVariant;
  getRowId: (item: TItem) => string;
  onRowClick?: (item: TItem) => void | Promise<void>;
  onRowSelectionModelChange?: (newValue: GridRowSelectionModel) => void;
  /** Function that sets the row height per row. */
  getRowHeight?: (params: GridRowHeightParamsCustom<TItem>) => GridRowHeightReturnValue;
}

export default function DataGrid<TItem extends GridValidRowModel>({
  sx,
  columns,
  rows,
  isLoading = false,
  isRowCheckboxSelectionEnabled = false,
  isRowNumbersEnabled = true,
  isColumnMenuEnabled = true,
  isColumnSelectorEnabled = true,
  isColumnResizeEnabled = true,
  isColumnSortEnabled = true,
  isColumnFilterEnabled = true,
  isPaginationEnabled = true,
  rowTo,
  columnVisibility,
  columnWidth,
  tabs,
  pagination,
  sort,
  quickFilter,
  filters,
  refetch,
  rowHeight = 52, // 52 is default MUI value
  rowSelectionModel,
  borderVariant = "none",
  getRowId,
  onRowClick,
  getRowHeight,
  onRowSelectionModelChange,
}: DataGridProps<TItem>) {
  const apiRef = useGridApiRef();

  const initialPaginationModelComputed = useMemo<GridPaginationModel | undefined>(
    () =>
      PaginationHelper.mapPaginationInfoToGridPaginationModel(pagination?.initialPaginationInfo),
    [],
  );

  const initialRowCountComputed = useMemo<number | undefined>(
    () => pagination?.initialPaginationInfo?.totalCount ?? 0,
    [],
  );

  const isColumnSortEnabledComputed = useMemo<boolean>(
    () => isColumnSortEnabled && !TypeHelper.isEmpty(sort),
    [isColumnSortEnabled, sort],
  );
  const initialSortModelComputed = useMemo<GridSortModel | undefined>(
    () => SortHelper.mapSortDefinitionToGridSortModel(sort?.initialSortDefinition),
    [],
  );

  const isColumnFilterEnabledComputed = useMemo<boolean>(
    () => isColumnFilterEnabled && !TypeHelper.isEmpty(filters),
    [isColumnFilterEnabled, filters],
  );

  const isColumnMenuEnabledComputed = useMemo<boolean>(
    () => isColumnMenuEnabled,
    [isColumnMenuEnabled],
  );

  const isPaginationEnabledComputed = useMemo<boolean>(
    () => isPaginationEnabled && !!pagination,
    [isPaginationEnabled, pagination],
  );

  const [customDataGridContext, setCustomDataGridContext] = useState<CustomDataGridContextType>(
    new CustomDataGridContextValue(),
  );
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
    columnVisibility?.initialMap ?? {},
  );
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>(initialSortModelComputed);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel | undefined>(
    initialPaginationModelComputed,
  );
  const [rowCount, setRowCount] = useState<number | undefined>(initialRowCountComputed);

  const sortComputed = useMemo<DataGridCustomSortProps | undefined>(
    () =>
      sort
        ? {
            ...sort,
            maxSortItems: dataGridMaxSortItems,
            onChange: (newValue) => {
              // sync SortDefinition -> GridSortModel
              const newSortModel = SortHelper.mapSortDefinitionToGridSortModel(newValue);
              setSortModel(newSortModel);
              sort.onChange(newValue);
            },
          }
        : undefined,
    [sort?.sortDefinition],
  );

  // columns must be memorized once and do not change between renders
  const columnsComputed = useMemo<GridColDef<TItem>[]>(() => {
    const newColumns: GridColDef<TItem>[] = columns.map((column) => ({
      ...column,
      width: columnWidth?.initialMap
        ? (columnWidth?.initialMap[column.field] ?? column.width)
        : column.width,
    }));

    // custom row selection & row number column:
    // 1. just row selection
    // 2. just row number
    // 3. row selection and row row number in a single column
    if (isRowCheckboxSelectionEnabled || isRowNumbersEnabled) {
      newColumns.unshift({
        ...(isRowCheckboxSelectionEnabled
          ? GRID_CHECKBOX_SELECTION_COL_DEF
          : {
              field: rowNumberField,
              headerName: "#",
              headerAlign: "center",
              description: "Row number",
              width: 50,
              maxWidth: 50,
              hideable: false,
              resizable: false,
              isSortable: false,
              filterable: false,
              isColumnMenuDisabled: true,
            }),
        // width: 50,
        // flex: 0,
        renderCell: (params) => {
          const rowId = params.id;
          const isRowSelected = params.api.isRowSelected(rowId);
          const isRowNumberVisible =
            isRowNumbersEnabled &&
            (!isRowCheckboxSelectionEnabled || (isRowCheckboxSelectionEnabled && !isRowSelected));
          const isRowNumberAlwaysVisible = isRowNumbersEnabled && !isRowCheckboxSelectionEnabled;
          const isRowCheckboxVisible =
            isRowCheckboxSelectionEnabled &&
            (!isRowNumbersEnabled || (isRowCheckboxSelectionEnabled && isRowSelected));
          const isRowCheckboxAlwaysVisible = isRowCheckboxSelectionEnabled && !isRowNumbersEnabled;

          return (
            <Stack
              sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}
            >
              {/* Row number */}
              {isRowNumbersEnabled && (
                <Typography
                  sx={{
                    display: isRowNumberVisible ? "block" : "none",
                    ...(isRowNumberAlwaysVisible ? { display: "block !important" } : undefined),
                  }}
                  className='MuiDataGrid-customRowNumber'
                  variant='body2'
                >
                  {params.api.getAllRowIds().indexOf(rowId) + 1}
                </Typography>
              )}

              {/* Row selection checkbox */}
              {isRowCheckboxSelectionEnabled && (
                <Box
                  sx={{
                    display: isRowCheckboxVisible ? "block" : "none",
                    ...(isRowCheckboxAlwaysVisible ? { display: "block !important" } : undefined),
                  }}
                  className='MuiDataGrid-customRowSelectionCheckbox'
                >
                  <Checkbox
                    checked={params.api.isRowSelected(rowId)}
                    onChange={(e) => {
                      const isRowSelectedNew = e.target.checked;
                      params.api.selectRow(rowId, isRowSelectedNew);
                    }}
                  />
                </Box>
              )}
            </Stack>
          );
        },
      });
    }

    return newColumns;
  }, []);

  // track current pagination change from outside
  useEffect(() => {
    // const newPaginationModel = PaginationHelper.mapPaginationInfoToGridPaginationModel(
    //   pagination?.currentPaginationInfo,
    // );
    const newRowCount = pagination?.currentPaginationInfo?.totalCount ?? 0;
    // if (!_.isEqual(paginationModel, newPaginationModel)) {
    //   setPaginationModel(newPaginationModel);
    // }
    if (rowCount !== newRowCount) {
      setRowCount(newRowCount);
    }
  }, [pagination?.currentPaginationInfo]);

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    const newState: GridStateCommunity = {
      ...apiRef.current.state,
      pagination: {
        ...apiRef.current.state.pagination,
        paginationModel: newModel,
      },
    };
    setPaginationModel(newModel);

    const newPaginationInfo = PaginationHelper.mapGridPaginationStateToPaginationInfo(
      newState.pagination,
    );

    console.log("DataGrid.onPaginationModelChange", {
      newModel,
      newState,
      newPaginationInfo,
    });

    pagination?.onChange && pagination?.onChange(newPaginationInfo);
  };

  // console.log("DataGrid.", { columnsComputed, columnWidth });
  // console.log("DataGrid.", {
  //   initialState,
  //   initialStateComputed,
  //   columnVisibilityModel,
  //   sortModel,
  //   initialPaginationModel,
  //   currentPaginationModel,
  //   initialPaginationInfo,
  //   currentPaginationInfo,
  //   paginationModel,
  //   rowCount,
  // });

  return (
    <CustomDataGridContext.Provider value={customDataGridContext}>
      <MuiDataGrid
        sx={{
          "&.MuiDataGrid-root": {
            // border
            ...(borderVariant === "none" && {
              border: "none",
            }),
            ...(borderVariant === "bordered" && {
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: (th) => th.palette.divider,
            }),
            ...(borderVariant === "shadowed" && {
              border: "none",
              boxShadow: (th) => th.shadows[2],
            }),
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          overflow: "hidden",

          // row hover
          "& .MuiDataGrid-row.Mui-hovered": {
            // backgroundColor: "rgba(0, 0, 0, 0.04)", // default MUI style
          },
          "& .MuiDataGrid-row:hover": {
            // backgroundColor: "rgba(0, 0, 0, 0.04)", // default MUI style

            // hide row number
            ".MuiDataGrid-customRowNumber": {
              display: "none",
            },
            // show row selection checkbox
            ".MuiDataGrid-customRowSelectionCheckbox": {
              display: "block",
            },
          },
          ...sx,
        }}
        apiRef={apiRef}
        columns={columnsComputed}
        // has an impact only when the data grid is rendered for the first time
        columnVisibilityModel={columnVisibilityModel}
        sortModel={sortModel}
        rows={rows || []}
        loading={isLoading}
        getRowId={getRowId}
        pageSizeOptions={PaginationHelper.pageSizeOptions}
        rowCount={rowCount}
        density='standard'
        paginationMode='server'
        filterMode='server'
        sortingMode='server'
        autosizeOnMount={false} // disable as we use resize & persist column width
        disableAutosize={false}
        autosizeOptions={{
          includeHeaders: true,
          expand: true,
        }}
        checkboxSelection={isRowCheckboxSelectionEnabled}
        rowSelectionModel={rowSelectionModel}
        paginationModel={paginationModel}
        disableVirtualization={false}
        disableColumnMenu={!isColumnMenuEnabledComputed}
        disableColumnResize={!isColumnResizeEnabled}
        disableColumnSelector={!isColumnSelectorEnabled}
        disableColumnSorting={!isColumnSortEnabledComputed}
        disableColumnFilter={!isColumnFilterEnabledComputed}
        disableDensitySelector={false}
        disableRowSelectionOnClick // disable as we use checkboxSelection
        disableMultipleRowSelection={false} // enable as we use checkboxSelection
        autoHeight
        pagination
        rowHeight={rowHeight}
        // safely cast as we just added generic param to custom type for better typing
        getRowHeight={getRowHeight as (params: GridRowHeightParams) => GridRowHeightReturnValue}
        getEstimatedRowHeight={() => rowHeight}
        onRowClick={(params) => {
          console.log("onRowClick", params);
          onRowClick && onRowClick(params.row);

          // NB: Vadym commented this as it triggers redirect with little ability to handle row/cell click in other places.
          // This logic was moved to TableCellContent.
          // if (rowTo) {
          //   history.push(_.isFunction(rowTo) ? rowTo(params.row) : rowTo);
          // } else if (onRowClick) {
          //   onRowClick(params.row);
          // }
        }}
        onRowSelectionModelChange={(newRowSelectionModel, details) => {
          console.log("DataGrid.onRowSelectionModelChange", { newRowSelectionModel, details });
          onRowSelectionModelChange && onRowSelectionModelChange(newRowSelectionModel);
        }}
        onColumnVisibilityModelChange={(newModel, details) => {
          const newState: GridStateCommunity = {
            ...apiRef.current.state,
            columns: {
              ...apiRef.current.state.columns,
              columnVisibilityModel: newModel,
            },
          };
          console.log("DataGrid.onColumnVisibilityModelChange", { newModel, details, newState });
          setColumnVisibilityModel(newModel);

          columnVisibility?.onChange && columnVisibility.onChange({ ...newModel });
        }}
        onSortModelChange={(newModel, details) => {
          const newState: GridStateCommunity = {
            ...apiRef.current.state,
            sorting: {
              ...apiRef.current.state.sorting,
              sortModel: newModel,
            },
          };
          setSortModel(newModel);

          const newSortDefinition = SortHelper.mapGridSortModelToSortDefinition(newModel);
          sort?.onChange && sort?.onChange(newSortDefinition);

          console.log("DataGrid.onSortModelChange", {
            sortModel,
            newModel,
            details,
            newState,
            newSortDefinition,
          });
        }}
        onColumnResize={(params, event) => {
          console.log("DataGrid.onColumnResize", { params, event });
        }}
        onColumnWidthChange={(params, event) => {
          const newState: GridStateCommunity = apiRef.current.state;
          console.log("DataGrid.onColumnWidthChange", { params, event, newState });

          if (columnWidth) {
            columnWidth?.onChange &&
              columnWidth?.onChange({
                field: params.colDef.field,
                width: params.width,
              });
          }
        }}
        onPaginationModelChange={(newModel, details) => {
          handlePaginationModelChange(newModel);
        }}
        onRowCountChange={(newCount) => {
          console.log("DataGrid.onRowCountChange", { newCount });
          setRowCount(newCount);
        }}
        onStateChange={(state, event, details) => {
          // console.log("DataGrid.onStateChange", {
          //   state,
          //   event,
          //   details,
          // });
        }}
        slots={{
          // NB: there is a difference between how slots are specified:
          // 1. toolbar: CustomDataGridToolbar
          // 2. (props) => <CustomDataGridToolbar {...props} />
          // Don't know why, but in the second scenario the slot component can be re-rendered breaking the logic of the component.
          // E.g. Popovers can be auto-closed on re-render.
          pagination: CustomDataGridPagination,
          columnMenu: CustomDataGridColumnMenu,
          // filterPanel: CustomDataGridFilterPanel,
          toolbar: CustomDataGridToolbar,
          footer: CustomDataGridFooter,
          noRowsOverlay: NoDataAlert,
          noResultsOverlay: NoDataAlert,
        }}
        slotProps={{
          pagination: {
            isEnabled: isPaginationEnabledComputed,
            maxPageSize: dataGridMaxPageSize,
            // use custom callback to avoid issue with simultaneously setting page/pageSize via grid API.
            onChangeCustom: (newValue) => {
              const newModel: GridPaginationModel = {
                page: newValue.page,
                pageSize: newValue.pageSize,
              };
              handlePaginationModelChange(newModel);
            },
          },
          columnsManagement: {
            disableResetButton: false,
            disableShowHideToggle: false,
            getTogglableColumns: (columns2) => {
              const result = columns2
                .filter(
                  (column) =>
                    column.field !== rowCheckboxSelectionField &&
                    column.field !== rowNumberField &&
                    (column.hideable || _.isNil(column.hideable)),
                )
                .map((column) => column.field);
              // console.log("DataGrid.getTogglableColumns.", result);
              return result;
            },
          },
          // columnMenu: {
          //   openFilterPanel: (targetColumn) => {
          //     apiRef.current?.showFilterPanel();
          //     setTargetFilterColumn(targetColumn);
          //   },
          // },
          // filterPanel: { column: targetFilterColumn, filterValues },
          row: {
            style: {
              // // NB: see onRowClick comment.
              // cursor: rowTo ? "pointer" : undefined,
            },
          },
          toolbar: {
            tabs: tabs,
            sort: sortComputed,
            quickFilter: quickFilter,
            filters: filters,
            refetch: refetch,
          },
          footer: {
            isPaginationEnabled: isPaginationEnabledComputed,
          },
          noRowsOverlay: {
            style: {
              position: "relative",
              zIndex: 1,
            },
          },
        }}
      />
    </CustomDataGridContext.Provider>
  );
}

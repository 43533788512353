import { Button, Stack, TypographyProps } from "@mui/material";
import { useCallback, useMemo } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  WebhookDto,
  WebhookEventType,
} from "@/core/api/generated";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import WebhookActivationsTabContent from "./Tabs/WebhookActivationsTabContent";

export enum WebhookViewPageTabs {
  Overview = "Overview",
  Activations = "Activations",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<WebhookDto> {
  webhookId: string | null | undefined;
  webhook: WebhookDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function WebhookView({
  webhookId,
  webhook,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const currentTenant = useCurrentTenant();

  const webhookRequest = useApiRequest(
    apiClient.webhooksApi.apiV1WebhooksWebhookIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      webhookId: webhookId!,
    },
    {
      deps: [webhookId],
      skip: !webhookId || !!webhook,
    },
  );
  webhook = webhook || webhookRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Webhook, webhookId || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      webhookRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        webhook && {
          idValue: webhook.id!,
          newTitle: webhook.localNumber || "",
        }) ||
      undefined,
  });

  const handleTestActivation = useCallback(async () => {
    console.log(webhook);
    if (!webhook) return;
    if (!webhook.eventTypes?.includes(WebhookEventType.Test)) {
      return;
    }

    await apiClient.webhooksApi.apiV1WebhooksTriggerTestPost({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    });
  }, [webhook]);

  const hasTestEventType = useMemo(
    () => webhook?.eventTypes?.includes(WebhookEventType.Test),
    [webhook],
  );

  return (
    <BaseEntityView
      entityType={EntityType.Webhook}
      entityId={webhookId}
      entity={webhook}
      entityRequest={webhookRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`Webhook ${webhook?.localNumber}`}
              titleProps={{
                to: headerProps?.withLink
                  ? ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOK_VIEW(webhook?.id)
                  : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                displayProps.actions && (
                  <Stack direction='row' spacing={1}>
                    <AuthorizedElement permissions={[AppPermission.WebhookManage]}>
                      <Button
                        component={AppLink}
                        to={ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOK_EDIT(webhook?.id)}
                        variant='outlined'
                        color='secondary'
                        size='medium'
                        startIcon={<AppIcon of='edit' />}
                      >
                        Edit
                      </Button>
                    </AuthorizedElement>
                    {hasTestEventType && (
                      <AuthorizedElement permissions={[AppPermission.WebhookManage]}>
                        <Button
                          variant='contained'
                          color='primary'
                          size='medium'
                          startIcon={<AppIcon of='activate' />}
                          onClick={() => {
                            handleTestActivation();
                          }}
                        >
                          Trigger Test event
                        </Button>
                      </AuthorizedElement>
                    )}
                  </Stack>
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <PageTabs
            tabIdsDefinition={WebhookViewPageTabs}
            defaultTabId={WebhookViewPageTabs.Overview}
            tabs={[
              { label: "Overview", value: WebhookViewPageTabs.Overview },
              { label: "Activations", value: WebhookViewPageTabs.Activations },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              webhook && (
                <>
                  {activeTab === WebhookViewPageTabs.Overview && (
                    <OverviewTabContent webhook={webhook} />
                  )}
                  {activeTab === WebhookViewPageTabs.Activations && (
                    <WebhookActivationsTabContent webhook={webhook} />
                  )}
                </>
              )
            }
          </PageTabs>
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}

import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { PartyDto, PartyReferenceDto } from "@/core/api/generated";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import TextLineSkeleton from "../../Skeleton/TextLineSkeleton";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";

export interface PartyInlineProps extends BaseEntityInlineInheritableProps {
  entity: PartyDto | PartyReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the party. */
export default function PartyInline({ entity, entityId, ...otherProps }: PartyInlineProps) {
  const request = useApiRequest(
    apiClient.partiesApi.apiV1PartiesPartyIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      partyId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as PartyDto;
  const ref = entity as PartyReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='party' inText />}
      content={
        <>
          {entity?.name || full?.name} (<InlineApiEnumValue type='PartyType' value={entity?.type} />
          )
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Party"}
    />
  );
}

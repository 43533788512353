import { Box, BoxProps, Theme, Tooltip, TooltipProps } from "@mui/material";

import { forwardRef } from "react";

export interface AppTooltipProps extends TooltipProps {
  /** If `true` - children are wrapped, `false` - children are rendered directly.
   * @default true
   */
  enabled?: boolean;
  // TODO: remove this and use AppTypography (not here but on the places where helpText tooltip is rendered)
  variant?: "default" | "helpText";
  isInline?: boolean;
  /** Props for element that wraps the children. */
  wrapperProps?: BoxProps;
  children: TooltipProps["children"];
}

/** Extends MUI Tooltip with custom logic. */
export default forwardRef<unknown, AppTooltipProps>(function AppTooltip(
  {
    enabled = true,
    variant = "default",
    isInline,
    wrapperProps,
    children,
    ...tooltipProps
  }: AppTooltipProps,
  ref,
) {
  if (!enabled) {
    return children ? <>{children}</> : null;
  }
  return (
    <Tooltip
      ref={ref}
      placement={variant === "helpText" ? "bottom-start" : undefined}
      {...tooltipProps}
      sx={{ ...tooltipProps.sx, ...(isInline && { display: "inline" }) }}
    >
      {variant === "helpText" ? (
        <Box
          component='div'
          {...wrapperProps}
          sx={
            {
              ...(variant === "helpText" && {
                textDecoration: "underline dotted 2px",
                textDecorationColor: (th: Theme) => th.palette.divider,
                "& *": {
                  textDecoration: "underline dotted 2px",
                  textDecorationColor: (th: Theme) => th.palette.divider,
                },
              }),
              ...tooltipProps.sx,
              ...wrapperProps?.sx,
              ...(isInline && { display: "inline" }),
            } as BoxProps["sx"]
          }
        >
          {children}
        </Box>
      ) : (
        children
      )}
    </Tooltip>
  );
});

import { Divider, LinearProgress, Stack, Typography } from "@mui/material";
import { useParams } from "react-router";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { DataUpdatesHubClientMethodName, EntityType } from "@/core/api/generated";
import VehicleHistoryList from "./VehicleHistoryList";

export default function VehicleHistoryPage() {
  const { vehicleId } = useParams<{ vehicleId?: string }>();
  const currentTenant = useCurrentTenant();

  const vehicleRequest = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicleId!,
    },
    {
      deps: [vehicleId],
      skip: !vehicleId,
    },
  );
  const vehicle = vehicleRequest?.data;

  const paginatedHistoryItemsRequest = useApiRequest(
    apiClient.vehicleHistoryApi.apiV1VehiclesVehicleIdHistoryGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicleId!,
      vehicleHistoryGetPaginatedDto: {
        offset: 0,
        limit: 100,
      },
    },
    {
      deps: [vehicleId],
      // debouncedDeps: {
      //   deps: [search],
      //   wait: 500,
      //   options: { leading: false, trailing: true },
      // },
    },
  );
  const paginatedHistoryItems = paginatedHistoryItemsRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.VehicleHistory)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedHistoryItemsRequest.handleEntityChanged(data);
    },
  });
  return (
    <ViewPageLayout
      breadcrumbs={{
        replacements: {
          waitTimeout: 10_000,
          idBreadcrumb: {
            idValue: vehicle?.id || "",
            newTitle: vehicle?.localNumber || "",
          },
        },
      }}
    >
      <Stack direction='column' spacing={2}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "flex-start", mb: 2 }}>
          <Typography component='div' variant='h4' sx={{ display: "flex", alignItems: "center" }}>
            <span>
              <>Vehicle {vehicle?.plateNo?.plateNo || vehicle?.identificationNumber} (history)</>
            </span>
          </Typography>
        </Stack>

        <Divider />

        {paginatedHistoryItemsRequest.isLoading && <LinearProgress />}

        {paginatedHistoryItems && (
          <VehicleHistoryList paginatedHistoryItems={paginatedHistoryItems} />
        )}
      </Stack>
    </ViewPageLayout>
  );
}

import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { VisualInspectionDto } from "@/core/api/generated";

import { pageTabHeaderTypographyProps } from "@/App/Layouts/PageBody/PageTabHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ContractView from "@/common/components/Entity/Contract/View/ContractView";

interface Props {
  visualInspection: VisualInspectionDto;
}

export default function ContractTabContent({ visualInspection }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <ContractView
          contractId={visualInspection.contract?.id}
          contract={undefined}
          displayProps={{
            breadcrumbs: false,
            header: false,
            actions: true,
            viewVariant: ViewLayoutVariant.Tab,
          }}
          headerProps={{
            withLink: true,
            typographyProps: pageTabHeaderTypographyProps.title,
          }}
        />
      </Stack>
    </PageTabContent>
  );
}

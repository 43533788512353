import { Chip, Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { TenantConnectionDto } from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import TenantInline from "../../../Tenant/TenantInline";

interface Props {
  tenantConnection: TenantConnectionDto;
}

export default function OverviewTabContent({ tenantConnection }: Props) {
  const currentTenant = useCurrentTenant();

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={tenantConnection} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={1}>
            <FieldValue label='Number'>{tenantConnection?.globalNumber}</FieldValue>

            <FieldValue label='Company 1'>
              <TenantInline entity={tenantConnection?.connectedTenant1} />{" "}
              {currentTenant?.id === tenantConnection?.connectedTenant1?.id && (
                <Chip size='small' variant='outlined' color='secondary' label='You' />
              )}
            </FieldValue>

            <FieldValue label='Company 2'>
              <TenantInline entity={tenantConnection?.connectedTenant2} />{" "}
              {currentTenant?.id === tenantConnection?.connectedTenant2?.id && (
                <Chip size='small' variant='outlined' color='secondary' label='You' />
              )}
            </FieldValue>

            <FieldValue label='Status'>
              <InlineApiEnumValue
                type='TenantConnectionStatus'
                value={tenantConnection?.status}
                direction='column'
                withDescription
              />
            </FieldValue>

            <FieldValue label='Connection request'>
              <AppLink to={ROUTE_PATH.TENANT_CONNECTION_REQUEST_VIEW(tenantConnection?.requestId)}>
                View
              </AppLink>
            </FieldValue>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

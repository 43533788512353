import { Box, SxProps, Theme } from "@mui/material";

import { FileHelper } from "@/common/helpers/file";

import { useState } from "react";
import SvgInline from "./SvgInline";

interface Props extends React.DOMAttributes<HTMLImageElement> {
  src?: string | null;
  alt?: string | null;
  sx?: SxProps<Theme>;
}

export default function Image({ src, alt, sx, ...other }: Props) {
  const isSvg = FileHelper.getFileExtension(src, true) === ".svg";
  const [isError, setIsError] = useState<boolean>(false);
  return isSvg ? (
    <SvgInline imageUrl={src} sx={sx} />
  ) : (
    <Box
      sx={{
        width: isError ? "auto" : "100%",
        height: isError ? "auto" : "100%",
        pl: isError ? 1 : 0,
        objectFit: "cover",
        borderRadius: "inherit",
        ...sx,
      }}
      component='img'
      onError={() => setIsError(true)}
      src={src || undefined}
      alt={alt || undefined}
      {...other}
    />
  );
}

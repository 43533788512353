import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import DamageDetectionPaginatedList from "@/common/components/Entity/DamageDetection/ListView/DamageDetectionPaginatedList";

export default function DamageDetections() {
  return (
    <>
      <DamageDetectionPaginatedList />
    </>
  );
}

import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  DamageDetectionDto,
  DamageDetectionItemDto,
  DamageDetectionItemReferenceDto,
  DamageDetectionReferenceDto,
} from "@/core/api/generated";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getDamageDetectionTitle } from "./DamageDetectionAutocomplete";

export interface DamageDetectionInlineProps extends BaseEntityInlineInheritableProps {
  entity: DamageDetectionDto | DamageDetectionReferenceDto | null | undefined;
  entityId?: string | null;
  item?: DamageDetectionItemDto | DamageDetectionItemReferenceDto;
  itemId?: string | null;
}

export default function DamageDetectionInline({
  entity,
  entityId,
  item,
  itemId,
  ...otherProps
}: DamageDetectionInlineProps) {
  const request = useApiRequest(
    apiClient.damageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      damageDetectionId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as DamageDetectionDto;
  const ref = entity as DamageDetectionReferenceDto;

  item = item || (itemId ? full?.items?.find((x) => x.id === itemId) : undefined);

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='damageDetection' inText />}
      content={
        <>
          {full && getDamageDetectionTitle(full)}
          {item && <> (#{item.localNumber})</>}
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Damage detection"}
    />
  );
}

import { Alert } from "@mui/lab";
import { Route, RouteProps } from "react-router-dom";

import { useAuthenticationInfo } from "@/common/hooks/auth/useAuthenticationInfo";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission } from "@/core/api/generated";
import AppRoute from "../Route/AppRoute";

type AuthorizedElementProps = {
  /** List of permissions user must have (all) */
  permissions?: AppPermission[];
  /** List of permissions user must have (at least one from the list) */
  permissionsAny?: AppPermission[];
} & RouteProps;

/** Renders Route that renders forbidden page if user doesn't have required permissions */
export default function AuthorizedRoute({
  permissions,
  permissionsAny,
  ...routeProps
}: AuthorizedElementProps) {
  const { isAuthenticated } = useAuthenticationInfo();
  const { isPermissionsReady, userPermissions } = useAuthorizationInfo();

  if (!isPermissionsReady || !userPermissions) {
    return null;
  }
  if (!permissions && !permissionsAny) {
    return null;
  }
  if (isAuthenticated === undefined || isAuthenticated === false) {
    return null;
  }

  const hasPermissionsRequired = (permissions || []).every((x) => userPermissions.includes(x));
  const hasPermissionsAny = permissionsAny
    ? permissionsAny.some((x) => userPermissions.includes(x))
    : true;
  const hasPermissions = hasPermissionsRequired && hasPermissionsAny;

  return (
    <AppRoute
      {...routeProps}
      render={(renderProps) => {
        if (hasPermissions) {
          return routeProps?.render && routeProps?.render(renderProps);
        } else {
          return (
            <Alert
              sx={{ my: 1 }}
              severity='error'
            >{`You don't have enough permissions to access the resource!`}</Alert>
          );
        }
      }}
    />
  );
}

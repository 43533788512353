import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppTooltip from "@/common/components/AppTooltip";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DropdownButton from "@/common/components/Button/DropdownButton";
import Datetime from "@/common/components/Datetime/Datetime";
import AssetLink from "@/common/components/Entity/Asset/AssetLink";
import AssetSubscriptionPlanLink from "@/common/components/Entity/AssetSubscriptionPlan/AssetSubscriptionPlanLink";
import ContractLink from "@/common/components/Entity/Contract/ContractLink";
import CustomerLink from "@/common/components/Entity/Customer/CustomerLink";
import SubscriptionPlanDurationInline from "@/common/components/Entity/Subscription/common/SubscriptionPlanDurationInline";
import SubscriptionPlanExtraOptionInline from "@/common/components/Entity/Subscription/common/SubscriptionPlanExtraOptionInline";
import SubscriptionPlanPriceInline from "@/common/components/Entity/Subscription/common/SubscriptionPlanPriceInline";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AllocationStatusIcon from "@/common/components/Icons/AllocationStatusIcon";
import ApiEnumIcon from "@/common/components/Icons/ApiEnumIcon";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppMenuItem from "@/common/components/Menu/AppMenuItem";
import HeaderMenuItem from "@/common/components/Menu/HeaderMenuItem";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { enumService } from "@/common/services/enum";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AssetSubscriptionDto, EntityType, SubscriptionStatus } from "@/core/api/generated";
import { useState } from "react";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import AssetSubscriptionReallocateAssetModal from "../../AssetSubscriptionReallocateAssetModal";
import AssetSubscriptionSpotInfoDisplay from "../../AssetSubscriptionSpotInfoDisplay";

interface Props {
  assetSubscription: AssetSubscriptionDto;
  onUpdated: (newValue?: AssetSubscriptionDto) => void;
}

export default function OverviewTabContent({ assetSubscription, onUpdated }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isReallocateAssetModalOpen, setIsReallocateAssetModalOpen] = useState(false);
  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={assetSubscription} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={2}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: `1fr`,
                  md: `1fr`,
                  lg: `1fr`,
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 4,
              }}
            >
              <Box>
                <Stack spacing={1}>
                  <FieldValue label='Subscription plan' isEmpty={!assetSubscription?.plan}>
                    <AssetSubscriptionPlanLink entity={assetSubscription?.plan} />
                  </FieldValue>

                  <FieldValue label='Customer' isEmpty={!assetSubscription?.customer}>
                    <CustomerLink entity={assetSubscription?.customer} />
                  </FieldValue>

                  <FieldValue label='Contract' isEmpty={!assetSubscription?.contract}>
                    <ContractLink entity={undefined} entityId={assetSubscription?.contract?.id} />
                  </FieldValue>

                  <FieldValue label='Asset' isEmpty={!assetSubscription?.asset}>
                    <Stack>
                      <Box>
                        {assetSubscription.asset?.asset && (
                          <AssetLink entity={assetSubscription.asset?.asset} />
                        )}

                        {!assetSubscription.asset?.asset && (
                          <InlineApiEnumValue
                            type='AssetEntityType'
                            value={assetSubscription.asset?.entityType}
                          />
                        )}
                      </Box>

                      <Box>
                        <AllocationStatusIcon status={assetSubscription.allocationStatus} />{" "}
                        <InlineApiEnumValue
                          type='AllocationStatus'
                          value={assetSubscription.allocationStatus}
                          withHelperTooltip
                        />{" "}
                        {assetSubscription?.canReallocate && (
                          <AppTooltip title='Reallocate asset' isInline>
                            <IconButton
                              size='small'
                              onClick={() => setIsReallocateAssetModalOpen(true)}
                            >
                              <AppIcon of='edit' />
                            </IconButton>
                          </AppTooltip>
                        )}
                      </Box>
                    </Stack>
                  </FieldValue>

                  <FieldValue label='Base price' isEmpty={!assetSubscription?.basePrice}>
                    <SubscriptionPlanPriceInline
                      basePrice={assetSubscription.basePrice}
                      price={assetSubscription.basePrice}
                      currency={assetSubscription.currency}
                    />
                  </FieldValue>
                  <FieldValue label='Pice' isEmpty={!assetSubscription?.price}>
                    <SubscriptionPlanPriceInline
                      basePrice={assetSubscription.basePrice}
                      price={assetSubscription.price}
                      currency={assetSubscription.currency}
                    />
                  </FieldValue>
                  <FieldValue label='Duration' isEmpty={!assetSubscription?.duration}>
                    <SubscriptionPlanDurationInline
                      duration={assetSubscription.duration}
                      currency={assetSubscription.currency}
                    />
                  </FieldValue>
                  <FieldValue label='Extra options' isEmpty={!assetSubscription?.extraOptions}>
                    <Stack spacing={0.5}>
                      {assetSubscription?.extraOptions?.map((x, i) => (
                        <SubscriptionPlanExtraOptionInline
                          key={i}
                          extraOption={x}
                          currency={assetSubscription.currency}
                        />
                      ))}
                    </Stack>
                  </FieldValue>
                </Stack>
              </Box>

              <Box>
                <Stack spacing={1}>
                  <FieldValue label='Local number' isEmpty={!assetSubscription.localNumber}>
                    {assetSubscription.localNumber}
                  </FieldValue>

                  <FieldValue label='External number' isEmpty={!assetSubscription.externalNumber}>
                    {assetSubscription.externalNumber}
                  </FieldValue>

                  <FieldValue label='Status' isEmpty={!assetSubscription?.status}>
                    <DropdownButton
                      size='small'
                      autoCloseOnClick={false}
                      buttonProps={{
                        color: "secondary",
                        size: "small",
                        variant: "text",
                        startIcon: (
                          <ApiEnumIcon
                            type='SubscriptionStatus'
                            value={assetSubscription?.status}
                          />
                        ),
                      }}
                      dropdownContent={(params) => {
                        const statusesToWhichCanTransit = enumService
                          .getEnumValues("SubscriptionStatus", {
                            except: [SubscriptionStatus.None],
                          })
                          .filter((status) =>
                            enumService.canTransit(
                              "SubscriptionStatus",
                              assetSubscription.status!,
                              status,
                            ),
                          );

                        return (
                          <MenuList>
                            {statusesToWhichCanTransit.length !== 0 && (
                              <HeaderMenuItem primaryTitle='Change status to:' />
                            )}
                            {statusesToWhichCanTransit.length === 0 && (
                              <HeaderMenuItem
                                primaryTitle={`Can't change status when it's ${enumService.getEnumValueName(
                                  "SubscriptionStatus",
                                  assetSubscription.status,
                                )}.`}
                              />
                            )}

                            {statusesToWhichCanTransit.map((status, i) => (
                              <AppMenuItem
                                key={i}
                                onClick={async () => {
                                  try {
                                    const response =
                                      await apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdStatusPut(
                                        {
                                          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                                          assetSubscriptionId: assetSubscription!.id!,
                                          assetSubscriptionUpdateStatusDto: {
                                            newStatus: status,
                                          },
                                        },
                                      );
                                    onUpdated && onUpdated(response.data);
                                    params.handleClose();
                                  } catch (err) {
                                    const validation = ValidationHelper.handleApiErrorResponse(err);
                                    enqueueSnackbar(validation.getErrorsAsString(), {
                                      variant: "error",
                                    });
                                  }
                                }}
                              >
                                <ListItemIcon>
                                  <ApiEnumIcon
                                    type='SubscriptionStatus'
                                    value={status}
                                    fontSize='small'
                                  />
                                </ListItemIcon>
                                <ListItemText>
                                  <InlineApiEnumValue type='SubscriptionStatus' value={status} />
                                </ListItemText>
                              </AppMenuItem>
                            ))}
                          </MenuList>
                        );
                      }}
                    >
                      <InlineApiEnumValue
                        type='SubscriptionStatus'
                        value={assetSubscription?.status}
                      />
                    </DropdownButton>
                  </FieldValue>

                  <FieldValue label='Starts at' isEmpty={!assetSubscription?.startsAt}>
                    <Datetime datetime={assetSubscription.startsAt} withDurationFromNow />
                  </FieldValue>

                  <FieldValue label='Ends at' isEmpty={!assetSubscription?.endsAt}>
                    <Datetime datetime={assetSubscription.endsAt} withDurationFromNow />
                  </FieldValue>

                  <FieldValue label='Pending at' isEmpty={!assetSubscription?.pendingAt}>
                    <Datetime datetime={assetSubscription.pendingAt} withDurationFromNow />
                  </FieldValue>

                  <FieldValue label='Unpaid at' isEmpty={!assetSubscription?.unpaidAt}>
                    <Datetime datetime={assetSubscription.unpaidAt} withDurationFromNow />
                  </FieldValue>

                  <FieldValue label='Active at' isEmpty={!assetSubscription?.activeAt}>
                    <Datetime datetime={assetSubscription.activeAt} withDurationFromNow />
                  </FieldValue>

                  <FieldValue label='Past due at' isEmpty={!assetSubscription?.pastDueAt}>
                    <Datetime datetime={assetSubscription.pastDueAt} withDurationFromNow />
                  </FieldValue>

                  <FieldValue label='Paused at' isEmpty={!assetSubscription?.pausedAt}>
                    <Datetime datetime={assetSubscription.pausedAt} withDurationFromNow />
                  </FieldValue>

                  <FieldValue label='Ended at' isEmpty={!assetSubscription?.endedAt}>
                    <Datetime datetime={assetSubscription.endedAt} withDurationFromNow />
                  </FieldValue>

                  <FieldValue label='Canceled at' isEmpty={!assetSubscription?.canceledAt}>
                    <Datetime datetime={assetSubscription.canceledAt} withDurationFromNow />
                  </FieldValue>
                </Stack>
              </Box>
            </Box>

            {/* Affiliation */}
            <Stack spacing={1} sx={{ mt: 2 }}>
              <Typography variant='subtitle1'>
                Affiliation{" "}
                <AppIconButton
                  tooltipProps={{ title: "Edit entity affiliation" }}
                  onClick={() => setIsUpdateAffiliationModalOpened(true)}
                >
                  <AppIcon of='edit' fontSize='small' />
                </AppIconButton>
              </Typography>
              <AffiliationInfoDisplay
                tenantId={assetSubscription.tenantId}
                departmentIds={assetSubscription.departmentIds}
                locationIds={assetSubscription.locationIds}
                sx={{ mt: 1 }}
              />
              <UpdateEntityAffiliationCascadeModal
                open={isUpdateAffiliationModalOpened}
                onClose={() => setIsUpdateAffiliationModalOpened(false)}
                entityType={EntityType.AssetSubscription}
                entityId={assetSubscription.id}
              />
            </Stack>

            {/* Check-in, check-out spot info */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: `1fr`,
                  lg: `repeat(2, 1fr)`,
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 4,
              }}
            >
              <AssetSubscriptionSpotInfoDisplay
                title='Check-out spot info'
                info={assetSubscription?.checkOutSpotInfo}
              />

              <AssetSubscriptionSpotInfoDisplay
                title='Check-in spot info'
                info={assetSubscription?.checkInSpotInfo}
              />
            </Box>
          </Stack>
        </Stack>

        {/* Reallocate asset */}
        {assetSubscription && (
          <AssetSubscriptionReallocateAssetModal
            open={isReallocateAssetModalOpen}
            onClose={() => setIsReallocateAssetModalOpen(false)}
            updateProps={{
              assetSubscription: assetSubscription,
              onSave: (newValue) => {
                onUpdated(newValue);
                setIsReallocateAssetModalOpen(false);
              },
            }}
          />
        )}
      </ViewLayout>
    </PageTabContent>
  );
}

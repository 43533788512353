import { FilterDefinitionItem } from "@/common/filters/filterDefinitionItem";
import {
  GeneralAttachedTagsInputDto,
  GeneralAttachedTagsQueryDto,
  TagStaticFilterDto,
} from "@/core/api/generated";
import _ from "lodash";

export type TagStaticFilterInputDto = Omit<TagStaticFilterDto, "query"> & {
  query?: GeneralAttachedTagsInputDto;
};

export class TagsHelper {
  public static mapToQueryDto(
    value: GeneralAttachedTagsInputDto | undefined,
  ): GeneralAttachedTagsQueryDto | undefined {
    return (
      value && {
        targetTypes: _.uniq(value.tags?.map((x) => x.targetType!).filter(Boolean)),
        targetIds: _.uniq(value.tags?.map((x) => x.target?.targetId || "").filter(Boolean)),
      }
    );
  }

  public static mapFromFilter(filter: FilterDefinitionItem): TagStaticFilterDto | undefined {
    return {
      operator: filter.operator,
      query: filter.value
        ? this.mapToQueryDto(filter.value as GeneralAttachedTagsInputDto)
        : undefined,
    } as TagStaticFilterDto;
  }
}

import { Box, Stack, Typography } from "@mui/material";

import InlineCode from "@/common/components/Code/InlineCode";
import FieldValue, { FieldValueProps } from "@/common/components/Form/Display/FieldValue";
import AppTypography from "@/common/components/Text/AppTypography";
import AppIcon from "@/common/components/Icons/AppIcon";

export default function DevFieldValue() {
  return (
    <Stack spacing={4}>
      <Typography component='div' variant='h2'>
        FieldValue
      </Typography>

      <Stack spacing={4}>
        <Stack spacing={1}>
          <Typography component='div' variant='h3'>
            Variants
          </Typography>

          <Stack spacing={1}>
            <FieldValue label='Label text'>Default</FieldValue>
          </Stack>

          {(["normal", "compact"] as Array<FieldValueProps["variant"]>).map((variant, i) => (
            <Stack spacing={1} key={i}>
              <Typography component='div' variant='h4'>
                {variant}
              </Typography>

              <Stack spacing={1}>
                <FieldValue label='Label text' variant={variant} direction='row'>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                </FieldValue>

                <FieldValue label='Label text' variant={variant} direction='column'>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                </FieldValue>

                <FieldValue
                  labelIcon={<AppIcon of='vehicle' inText />}
                  label='Label text'
                  variant={variant}
                  direction='column'
                >
                  Label with icon.
                </FieldValue>

                <FieldValue
                  labelIcon={<AppIcon of='vehicle' inText />}
                  label='Label text'
                  variant={variant}
                  direction='row'
                >
                  Label with icon.
                </FieldValue>

                <FieldValue
                  labelIcon={<AppIcon of='vehicle' inText />}
                  variant={variant}
                  direction='row'
                >
                  Icon as label.
                </FieldValue>

                <FieldValue
                  labelIcon={<AppIcon of='vehicle' inText />}
                  variant={variant}
                  direction='column'
                >
                  Icon as label.
                </FieldValue>
              </Stack>

              <Stack spacing={1}>
                <Typography component='div' variant='h6'>
                  Overflow & ellipsis
                </Typography>

                <Stack spacing={2}>
                  <FieldValue
                    variant={variant}
                    label='Default long text & overflow.  Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit iste consequatur minima
          veniam aspernatur voluptate voluptatem inventore, quis earum, atque alias officiis tenetur
          vitae cum similique sunt! Dolor, ut consectetur!'
                    direction='row'
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit iste consequatur
                    minima veniam aspernatur voluptate voluptatem inventore, quis earum, atque alias
                    officiis tenetur vitae cum similique sunt! Dolor, ut consectetur!
                  </FieldValue>

                  <FieldValue
                    variant={variant}
                    label='Default long text & overflow.  Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit iste consequatur minima
          veniam aspernatur voluptate voluptatem inventore, quis earum, atque alias officiis tenetur
          vitae cum similique sunt! Dolor, ut consectetur!'
                    direction='column'
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit iste consequatur
                    minima veniam aspernatur voluptate voluptatem inventore, quis earum, atque alias
                    officiis tenetur vitae cum similique sunt! Dolor, ut consectetur!
                  </FieldValue>

                  <FieldValue
                    variant={variant}
                    label='Custom long text & overflow. Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Odit iste consequatur minima veniam aspernatur voluptate
                    voluptatem inventore, quis earum, atque alias officiis tenetur vitae cum
                    similique sunt! Dolor, ut consectetur!'
                    direction='row'
                    ellipsing={{ enabled: true }}
                  >
                    <AppTypography ellipsing={{ enabled: true }} isInheritFontStyle>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit iste consequatur
                      minima veniam aspernatur voluptate voluptatem inventore, quis earum, atque
                      alias officiis tenetur vitae cum similique sunt! Dolor, ut consectetur!
                    </AppTypography>
                  </FieldValue>

                  <FieldValue
                    variant={variant}
                    label='Custom long text & overflow. Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Odit iste consequatur minima veniam aspernatur voluptate
                    voluptatem inventore, quis earum, atque alias officiis tenetur vitae cum
                    similique sunt! Dolor, ut consectetur!'
                    direction='column'
                    ellipsing={{ enabled: true }}
                  >
                    <AppTypography ellipsing={{ enabled: true }} isInheritFontStyle>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit iste consequatur
                      minima veniam aspernatur voluptate voluptatem inventore, quis earum, atque
                      alias officiis tenetur vitae cum similique sunt! Dolor, ut consectetur!
                    </AppTypography>
                  </FieldValue>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>

        <Stack spacing={1}>
          <Typography component='div' variant='h3'>
            Directions
          </Typography>

          <Stack spacing={1}>
            <FieldValue label='Label text'>Default</FieldValue>

            <FieldValue direction='row' label='Label text'>
              <InlineCode>{`direction='row'`}</InlineCode>
            </FieldValue>

            <FieldValue direction='column' label='Label text'>
              <InlineCode>{`direction='column'`}</InlineCode>
            </FieldValue>

            {(["row", "column"] as Array<FieldValueProps["direction"]>).map((direction, i) => (
              <Stack key={i} spacing={1}>
                <Typography component='div' variant='h4'>
                  <>{direction}</>
                </Typography>

                <Stack spacing={1}>
                  <Typography component='div' variant='h6'>
                    Label
                  </Typography>

                  <Stack spacing={1}>
                    <FieldValue
                      direction={direction}
                      labelAlight={{ horizontal: "start" }}
                      label='Label text'
                    >
                      <InlineCode>{`direction='${direction}', labelAlight={horizontal: "start"}`}</InlineCode>
                    </FieldValue>

                    <FieldValue
                      direction={direction}
                      labelAlight={{ horizontal: "center" }}
                      label='Label text'
                    >
                      <InlineCode>{`direction='${direction}', labelAlight={horizontal: "center"}`}</InlineCode>
                    </FieldValue>

                    <FieldValue
                      direction={direction}
                      labelAlight={{ horizontal: "end" }}
                      label='Label text'
                    >
                      <InlineCode>{`direction='${direction}', labelAlight={horizontal: "end"}`}</InlineCode>
                    </FieldValue>

                    <FieldValue
                      direction={direction}
                      labelAlight={{ vertical: "start" }}
                      label='Label text'
                    >
                      <InlineCode>{`direction='${direction}', labelAlight={vertical: "start"}`}</InlineCode>
                      <Box sx={{ height: 40, background: "beige", mt: 0.5 }}>
                        Height placeholder
                      </Box>
                    </FieldValue>

                    <FieldValue
                      direction={direction}
                      labelAlight={{ vertical: "center" }}
                      label='Label text'
                    >
                      <InlineCode>{`direction='${direction}', labelAlight={vertical: "center"}`}</InlineCode>
                      <Box sx={{ height: 40, background: "beige", mt: 0.5 }}>
                        Height placeholder
                      </Box>
                    </FieldValue>

                    <FieldValue
                      direction={direction}
                      labelAlight={{ vertical: "end" }}
                      label='Label text'
                    >
                      <InlineCode>{`direction='${direction}', labelAlight={vertical: "end"}`}</InlineCode>
                      <Box sx={{ height: 40, background: "beige", mt: 0.5 }}>
                        Height placeholder
                      </Box>
                    </FieldValue>
                  </Stack>
                </Stack>

                <Stack spacing={1}>
                  <Typography component='div' variant='h6'>
                    Value
                  </Typography>

                  <Stack spacing={1}>
                    <FieldValue
                      direction={direction}
                      valueAlight={{ horizontal: "start" }}
                      label='Label text'
                    >
                      <InlineCode>{`direction='${direction}', valueAlight={horizontal: "start"}`}</InlineCode>
                    </FieldValue>

                    <FieldValue
                      direction={direction}
                      valueAlight={{ horizontal: "center" }}
                      label='Label text'
                    >
                      <InlineCode>{`direction='${direction}', valueAlight={horizontal: "center"}`}</InlineCode>
                    </FieldValue>

                    <FieldValue
                      direction={direction}
                      valueAlight={{ horizontal: "end" }}
                      label='Label text'
                    >
                      <InlineCode>{`direction='${direction}', valueAlight={horizontal: "end"}`}</InlineCode>
                    </FieldValue>

                    <FieldValue
                      direction={direction}
                      valueAlight={{ vertical: "start" }}
                      label={
                        <Box>
                          <Box>Label text</Box>
                          <Box sx={{ height: 40, background: "beige", mt: 0.5 }}>
                            Height placeholder
                          </Box>
                        </Box>
                      }
                    >
                      <InlineCode>{`direction='${direction}', valueAlight={vertical: "start"}`}</InlineCode>
                    </FieldValue>

                    <FieldValue
                      direction={direction}
                      valueAlight={{ vertical: "center" }}
                      label={
                        <Box>
                          <Box>Label text</Box>
                          <Box sx={{ height: 40, background: "beige", mt: 0.5 }}>
                            Height placeholder
                          </Box>
                        </Box>
                      }
                    >
                      <InlineCode>{`direction='${direction}', valueAlight={vertical: "center"}`}</InlineCode>
                    </FieldValue>

                    <FieldValue
                      direction={direction}
                      valueAlight={{ vertical: "end" }}
                      label={
                        <Box>
                          <Box>Label text</Box>
                          <Box sx={{ height: 40, background: "beige", mt: 0.5 }}>
                            Height placeholder
                          </Box>
                        </Box>
                      }
                    >
                      <InlineCode>{`direction='${direction}', valueAlight={vertical: "end"}`}</InlineCode>
                    </FieldValue>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <Typography component='div' variant='h3'>
            Misc
          </Typography>

          <Stack spacing={1}>
            <FieldValue label='Label text 2'>243.34</FieldValue>

            <FieldValue label='Empty value' isEmpty={true}>
              <></>
            </FieldValue>

            <FieldValue label='With placeholder' placeholder='This is a placeholder'>
              {undefined}
            </FieldValue>

            <FieldValue label='Label text' helperTooltip='This is helper text'>
              some value with helper tooltip
            </FieldValue>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

import {
  FormControl,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
  TextFieldProps,
} from "@mui/material";
import _ from "lodash";
import { ChangeEvent, useCallback, useEffect, useRef } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { deepCompareEquals } from "@/common/helpers/comparison";
import { NumberHelper } from "@/common/helpers/number";
import { useEffectWithDeepCompare } from "@/common/hooks/effect/useEffectWithDeepCompare";
import { useCurrentTax } from "@/common/hooks/useCurrentTax";
import { enumService } from "@/common/services/enum";
import {
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  GeneralTaxDto,
  GeneralTaxInputDto,
  TaxType,
  TaxValueType,
  VehicleTaxCalcRequestDto,
} from "@/core/api/generated";

import DropdownButton from "@/common/components/Button/DropdownButton";
import { VALIDATION_CONSTANTS } from "@/common/constants/validation";
import { PriceHelper } from "@/common/helpers/price";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import DropdownIconButton from "../../../Button/DropdownIconButton";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import VehicleTaxInputHelper from "../../Vehicle/VehicleTax/VehicleTaxInputHelper";

const defaultDisplayProps = {
  typeInput: true,
  valueTypeInput: true,
};

export interface GeneralTaxInputProps
  extends Omit<TextFieldProps, "onChange" | "type" | "value" | "startAdornment"> {
  value: GeneralTaxInputDto | GeneralTaxDto | null | undefined;
  /** To which value tax is applied. */
  appliesTo:
    | {
        subTotal: number;
      }
    | undefined;
  defaultType?: TaxType;
  defaultValueType?: TaxValueType;
  currency?: GeneralCurrencyDto | GeneralCurrencyInputDto;
  allowTypeChange?: boolean;
  allowValueTypeChange?: boolean;
  disabled?: boolean;
  /** Select tax from tenant or user settings by default; */
  useCurrentTaxByDefault?: boolean;
  displayProps?: Partial<typeof defaultDisplayProps>;

  /** If passed, tax will be recalculated for specified vehicle params. */
  vehicleTaxCalcRequestParams?: VehicleTaxCalcRequestDto;
  onChange?: (newValue?: GeneralTaxInputDto | null) => void;
}

/** Complex component for entering tax value.
 *  Percents are displayed as [0; 100], but stored as [0;1].
 */
export default function GeneralTaxInput({
  value,
  appliesTo,
  defaultType = TaxType.Custom,
  defaultValueType = TaxValueType.Percent,
  currency,
  allowTypeChange = true,
  allowValueTypeChange = true,
  disabled,
  useCurrentTaxByDefault,
  displayProps = defaultDisplayProps,
  vehicleTaxCalcRequestParams,
  onChange,
  ...textFieldProps
}: GeneralTaxInputProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();

  const currentTax = useCurrentTax({ useDefault: true });
  const isUsedCurrentTaxByDefaultRef = useRef(false);

  const taxType = value?.type || defaultType;
  const taxValueType = value?.valueType || defaultValueType;

  let inputValue: number | string = "";
  if (taxValueType === TaxValueType.Percent && !_.isNil(value?.percent)) {
    inputValue = NumberHelper.normalizePercentToZeroHundred(value!.percent);
  } else if (taxValueType === TaxValueType.Value && !_.isNil(value?.value)) {
    inputValue = value!.value;
  }

  // trigger change when currency changes from outside
  useEffectWithDeepCompare(() => {
    const newCurrency = currency || undefined;
    if (value && !deepCompareEquals(value?.currency, newCurrency)) {
      onChange && onChange({ ...value, currency: newCurrency });
    }
  }, [currency]);

  // trigger change to use current value
  useEffect(() => {
    if (!isUsedCurrentTaxByDefaultRef.current && !value && currentTax && useCurrentTaxByDefault) {
      onChange && onChange(currentTax);
      isUsedCurrentTaxByDefaultRef.current = true;
    }
  }, [value, useCurrentTaxByDefault, currentTax]);

  const changeType = useCallback(
    (newType: TaxType) => {
      onChange && onChange({ ...value, type: newType });
    },
    [value, taxType, taxValueType, onChange],
  );

  const changeValueType = useCallback(
    (newValueType: TaxValueType) => {
      let newValue: GeneralTaxInputDto = {
        ...value,
        valueType: newValueType,
        percent: newValueType === TaxValueType.Percent ? 0 : undefined,
        value: newValueType === TaxValueType.Value ? 0 : undefined,
      };

      //auto-convert between value types
      if (value && appliesTo) {
        newValue = PriceHelper.convertTaxTo(value, appliesTo.subTotal, newValueType);
      }

      onChange && onChange(newValue);
    },
    [value, appliesTo, taxValueType, onChange],
  );

  const _onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newInputValue =
        _.isNil(e.target.value) || _.isEmpty(e.target.value) ? undefined : +e.target.value;
      const newValue = {
        ...value,
        type: taxType,
        valueType: taxValueType,
        percent:
          taxValueType === TaxValueType.Percent && !_.isNil(newInputValue)
            ? Math.min(
                NumberHelper.normalizePercentToZeroOne(newInputValue),
                VALIDATION_CONSTANTS.TaxMaxValueForPercent,
              )
            : undefined,
        value:
          taxValueType === TaxValueType.Value && !_.isNil(newInputValue)
            ? Math.min(newInputValue, VALIDATION_CONSTANTS.TaxMaxValueForValue)
            : undefined,
        currency: undefined,
      };
      onChange && onChange(newValue);
    },
    [value, taxType, taxValueType, onChange],
  );

  return (
    <FormControl margin='none' fullWidth>
      <TextField
        label='Tax'
        inputMode='decimal'
        value={_.isNil(inputValue) ? "" : inputValue}
        placeholder=''
        onChange={_onChange}
        disabled={disabled}
        InputProps={{
          inputProps: {
            ...(taxValueType === TaxValueType.Percent && {
              min: VALIDATION_CONSTANTS.TaxMinValueForPercent * 100,
              max: VALIDATION_CONSTANTS.TaxMaxValueForPercent * 100,
              maxlength: VALIDATION_CONSTANTS.TaxMaxLengthForPercent,
            }),
            ...(taxValueType === TaxValueType.Value && {
              min: VALIDATION_CONSTANTS.TaxMinValueForValue,
              max: VALIDATION_CONSTANTS.TaxMaxValueForValue,
              maxlength: VALIDATION_CONSTANTS.TaxMaxLengthForValue,
            }),
          },
          startAdornment: (
            <>
              {/* TaxValueType input */}
              {displayProps?.valueTypeInput && (
                <InputAdornment sx={{ mx: 0 }} position='start'>
                  <DropdownIconButton
                    variant='default'
                    size='extraSmall'
                    color='secondary'
                    disabled={disabled || !allowValueTypeChange}
                    dropdownContent={
                      <MenuList>
                        {enumService
                          .getEnumDtos("TaxValueType")
                          .filter((x) => x.value !== TaxValueType.None)
                          .map((x, i) => (
                            <MenuItem
                              key={i}
                              onClick={() => {
                                changeValueType(x.value as TaxValueType);
                              }}
                            >
                              <ListItemIcon>
                                {x.value === TaxValueType.Value && <AppIcon of='money' />}
                                {x.value === TaxValueType.Percent && <AppIcon of='percent' />}
                              </ListItemIcon>
                              <ListItemText>{x.name}</ListItemText>
                            </MenuItem>
                          ))}
                      </MenuList>
                    }
                  >
                    {taxValueType === TaxValueType.Value && (
                      <AppIcon of='money' fontWeight='inherit' />
                    )}
                    {taxValueType === TaxValueType.Percent && (
                      <AppIcon of='percent' fontWeight='inherit' />
                    )}
                  </DropdownIconButton>
                </InputAdornment>
              )}
            </>
          ),
          endAdornment: (
            <>
              {/* TaxType input */}
              {displayProps?.typeInput && allowTypeChange && (
                <InputAdornment sx={{ mx: 0 }} position='end'>
                  <DropdownButton
                    disabled={disabled || !allowTypeChange}
                    variant='text'
                    size='extraSmall'
                    color='secondary'
                    dropdownContent={
                      <MenuList>
                        {enumService
                          .getEnumDtos("TaxType")
                          .filter((x) => x.value !== TaxType.None)
                          .map((x, i) => (
                            <MenuItem
                              key={i}
                              onClick={() => {
                                changeType(x.value as TaxType);
                              }}
                            >
                              <ListItemText primary={x.name} secondary={x.description} />
                            </MenuItem>
                          ))}
                      </MenuList>
                    }
                  >
                    <InlineApiEnumValue type='TaxType' value={taxType} />
                  </DropdownButton>
                </InputAdornment>
              )}
              {displayProps?.typeInput && !allowTypeChange && (
                <InputAdornment position='end'>
                  <InlineApiEnumValue type='TaxType' value={taxType} />
                </InputAdornment>
              )}
            </>
          ),
        }}
        {...textFieldProps}
      />
      {vehicleTaxCalcRequestParams && (
        <VehicleTaxInputHelper
          tax={value}
          taxAppliesTo={appliesTo}
          vehicleTaxCalcRequestParams={vehicleTaxCalcRequestParams}
          onChange={(newValue) => {
            onChange && onChange(newValue);
          }}
        />
      )}
    </FormControl>
  );
}

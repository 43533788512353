import { DialogProps } from "@mui/material";

import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { PoolDto } from "@/core/api/generated";
import { useCallback } from "react";
import ConfirmationModal from "../../Modals/ConfirmationModal";

export interface OwnProps {
  pool: PoolDto;
  onDeleted?: () => void;
}

type Props = OwnProps & DialogProps;

export default function PoolItemDeleteAllModal({ pool, onDeleted, ...dialogProps }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const handleDeleteConfirmed = useCallback(async () => {
    try {
      await apiClient.poolItemsApi.apiV1PoolsPoolIdItemsAllDelete({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        poolId: pool.id!,
      });
      enqueueSnackbar("All pool items deleted.", { variant: "success" });
      onDeleted && onDeleted();
      dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [pool]);

  return (
    <ConfirmationModal
      {...dialogProps}
      title='Delete all items from the pool?'
      body={
        <>
          {`You're going to delete all items from the pool`}{" "}
          <strong>
            {pool.localNumber}, {pool.name}
          </strong>
          . {`This action can't be undone.`}
        </>
      }
      onConfirm={handleDeleteConfirmed}
    />
  );
}

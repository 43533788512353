import { IconButton, Tooltip } from "@mui/material";
import { ReactNode } from "react";

import { useAppThunkDispatch } from "@/common/hooks/redux";

import AppIcon from "../Icons/AppIcon";
import AppLink from "./AppLink";
import { useHistory } from "react-router";

interface Props {
  icon?: ReactNode;
  title?: string;
  to?: string;
  children?: ReactNode;
}

export default function GoBackLink({ icon, title, to, children }: Props) {
  const history = useHistory();
  return (
    <AppLink
      to={to || ""}
      onClick={() => {
        if (!to) {
          history.goBack();
        }
      }}
    >
      {!children && (
        <>
          {icon || <AppIcon of='goBack' />} {title || "Go back"}
        </>
      )}
      {children && children}
    </AppLink>
  );
}

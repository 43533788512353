import { Box, Grid, Stack } from "@mui/material";
import _ from "lodash";
import { useState } from "react";

import { ArrayHelper } from "@/common/helpers/array";
import { GeneralAttachedTagsInputDto } from "@/core/api/generated";

import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AppIconButton from "@/common/components/Button/AppIconButton";
import AppIcon from "../../../Icons/AppIcon";
import GeneralTagDisplay from "./GeneralTagDisplay";
import GeneralTagSearchCandidatesPopover from "./GeneralTagSearchCandidatesPopover";

export interface OwnProps {
  value: GeneralAttachedTagsInputDto | null | undefined;
  onChange: (newValue: GeneralAttachedTagsInputDto | undefined) => void | Promise<void>;
}

type Props = OwnProps;

export default function GeneralAttachedTagsInput({ value, onChange }: Props) {
  const [tagCandidatesPopoverAnchorEl, setTagCandidatesPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  return (
    <Box>
      <Stack direction='column' spacing={2}>
        {/* Selected tags */}
        <Grid container spacing={0.5}>
          {_.isEmpty(value?.tags) && (
            <Grid item xxs='auto'>
              <NoDataAlert variant='inline' title='No tags' />
            </Grid>
          )}

          {value?.tags?.map((tag, i) => (
            <Grid key={i} item xxs='auto'>
              <GeneralTagDisplay
                tag={{
                  ...tag,
                  id: tag?.id || undefined,
                }}
                enableLink
                onDelete={() => {
                  onChange(
                    value
                      ? { ...value, tags: ArrayHelper.removeByIndex(value.tags, i) }
                      : undefined,
                  );
                }}
              />
            </Grid>
          ))}

          <Grid item xxs='auto'>
            <AppIconButton
              variant='outlined'
              size='extraSmall'
              color='secondary'
              tooltipProps={{ title: "Add new tag" }}
              onClick={(e) => {
                setTagCandidatesPopoverAnchorEl(e.currentTarget);
              }}
            >
              <AppIcon of='add' />
            </AppIconButton>
          </Grid>
        </Grid>
      </Stack>

      {/* Search tag candidates popover */}
      <GeneralTagSearchCandidatesPopover
        anchorEl={tagCandidatesPopoverAnchorEl}
        open={Boolean(tagCandidatesPopoverAnchorEl)}
        excludeTags={value?.tags}
        onClose={() => setTagCandidatesPopoverAnchorEl(null)}
        onTagSelected={(candidate) => {
          if (candidate.tag) {
            onChange({
              ...value,
              tags: [...(value?.tags || []), candidate.tag],
            });
          }
          setTagCandidatesPopoverAnchorEl(null);
        }}
      />
    </Box>
  );
}

import { Redirect } from "react-router";

import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthenticationInfo } from "@/common/hooks/auth/useAuthenticationInfo";

import TenantRegisterEmailVerificationPage from "@/App/BaseApp/Auth/Register/Tenant/TenantRegisterEmailVerificationPage";
import { RoutePathBuilder } from "@/common/builders/routePath";
import AppRoute from "@/common/components/Route/AppRoute";
import CheckCompanyIncompleteRegistrationPage from "./TenantRegisterCheckIncompleteRegistrationPage";
import TenantRegisterCreatePasswordPage from "./TenantRegisterCreatePasswordPage";
import TenantRegisterInfoPage from "./TenantRegisterInfoPage";
import TenantRegisterCheckIncompleteRegistrationPage from "./TenantRegisterCheckIncompleteRegistrationPage";

export default function TenantRegisterRoutes() {
  const { isAuthenticated } = useAuthenticationInfo();

  return (
    <>
      <AppRoute
        exact={false}
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_SIGN_UP)}
        render={() => {
          // must logout before register
          if (isAuthenticated) {
            return <Redirect to={ROUTE_PATH.AUTH_SIGN_UP_MUST_SIGN_OUT_WARNING} />;
          }
          return null;
        }}
      />

      <AppRoute
        exact={true}
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_SIGN_UP)}
        render={() => {
          // must logout before register
          if (isAuthenticated) {
            return <Redirect to={ROUTE_PATH.AUTH_SIGN_UP_MUST_SIGN_OUT_WARNING} />;
          }
          return <Redirect to={ROUTE_PATH.AUTH_SIGN_UP_TENANT_CHECK_INCOMPLETE_REGISTRATION} />;
        }}
      />

      <AppRoute
        path={RoutePathBuilder.toDefinition(
          ROUTE_PATH.AUTH_SIGN_UP_TENANT_CHECK_INCOMPLETE_REGISTRATION,
        )}
        render={() => {
          return <TenantRegisterCheckIncompleteRegistrationPage />;
        }}
      />

      <AppRoute
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_SIGN_UP_TENANT_INFO)}
        render={() => {
          return <TenantRegisterInfoPage />;
        }}
      />

      <AppRoute
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_SIGN_UP_TENANT_EMAIL_VERIFICATION)}
        render={() => {
          return <TenantRegisterEmailVerificationPage />;
        }}
      />

      <AppRoute
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_SIGN_UP_TENANT_EMAIL_PASSWORD)}
        render={() => {
          return <TenantRegisterCreatePasswordPage />;
        }}
      />
    </>
  );
}

import AppIcon from "@/common/components/Icons/AppIcon";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  TenantRequestDto,
  TenantRequestOperationResultInputDto,
  TenantRequestStage,
  TenantRequestType,
} from "@/core/api/generated";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import TenantRequestCreateOperationModal from "./TenantRequestCreateOperationModal";

export interface TenantRequestInlineProps {
  tenantRequest: TenantRequestDto;
  onSave?: (newValue: TenantRequestOperationResultInputDto) => void;
  onEntityUpdate?: (newValue: TenantRequestDto) => void;
}

export default function TenantRequestCreateOperationControl({
  tenantRequest,
  onSave,
  onEntityUpdate,
}: TenantRequestInlineProps) {
  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const isSentByMe = currentTenant?.id === tenantRequest?.senderTenant?.id;
  const isReceivedByMe = currentTenant?.id === tenantRequest?.receiverTenant?.id;

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {isReceivedByMe &&
        tenantRequest.content?.type === TenantRequestType.Operation &&
        tenantRequest.stageHistory?.stage === TenantRequestStage.InProgress && (
          <Button
            variant='contained'
            color='primary'
            size='small'
            startIcon={<AppIcon of='create' />}
            onClick={() => setIsModalOpen(true)}
          >
            <Box component='span'>
              Create new{" "}
              <strong>
                <InlineApiEnumValue
                  type='TenantRequestOperationType'
                  value={tenantRequest.content?.operation?.operationType}
                  withDescription={false}
                />
              </strong>
            </Box>
          </Button>
        )}

      <TenantRequestCreateOperationModal
        tenantRequest={tenantRequest}
        onSave={async (newValue) => {
          onSave && (await onSave(newValue));

          try {
            const response =
              await apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut(
                {
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  tenantRequestId: tenantRequest.id || "",
                  tenantRequestOperationResultInputDto: newValue,
                },
              );
            onEntityUpdate && onEntityUpdate(response.data);
            setIsModalOpen(false);
          } catch (err) {
            const validation2 = ValidationHelper.handleApiErrorResponse(err);
            validation2.hasErrors &&
              enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
          }
        }}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}

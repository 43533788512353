import { Alert } from "@mui/material";

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import PoolPaginatedList from "@/common/components/Entity/Pool/ListView/PoolPaginatedList";

export default function PoolsPage() {
  return (
    <>
      <PoolPaginatedList
        displayProps={{
          breadcrumbs: true,
          filters: true,
        }}
      />
    </>
  );
}

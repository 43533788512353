import { entityAffiliationEventEmitter } from "@/common/eventEmmiters/entityAffiliationEventEmitter";
import { EntityHelper } from "@/common/helpers/entity";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssetDto,
  IBaseEntityDto,
  PoolItemDto,
  PoolItemEntityType,
  PoolItemType,
} from "@/core/api/generated";
import { LinearProgress, Stack } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";
import EntityPoolsFoldableBlock from "../../EntityInfo/EntityPoolsInfoFoldableBlock";
import { PoolAutocompleteProps } from "../Pool/PoolAutocomplete";
import PoolItemCreateMany from "./PoolItemCreateMany";

export interface PoolItemsCreateFromEntitiesOwnProps {
  entityType: PoolItemEntityType;
  entities: IBaseEntityDto[];
  searchFilters?: {
    pool?: PoolAutocompleteProps["searchFilters"];
  };
  onSave?: (newValue: PoolItemDto[] | null | undefined) => void;
}

export type PoolItemsCreateFromEntitiesProps = PoolItemsCreateFromEntitiesOwnProps;

export default function PoolItemCreateManyFromEntities({
  entityType,
  entities,
  searchFilters,
  onSave,
}: PoolItemsCreateFromEntitiesProps) {
  const entitiesMeta = useMemo(
    () => entities.map((entity) => EntityHelper.getEntityMeta(entity)),
    [entities],
  );

  const assetIds = useMemo(
    () => entitiesMeta.map((entityMeta) => entityMeta?.assetMeta?.assetId || "").filter(Boolean),
    [entitiesMeta],
  );

  const createManyItems = entities.map((entity) => {
    return {
      poolId: undefined,
      type: PoolItemType.Specific,
      entityType: entityType,
      entity: {
        entityType: entityType,
        entityId: entity.id,
      },
    };
  });
  const assetsRequest = useApiRequest(
    apiClient.assetsApi.apiV1AssetsSearchPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSearchPaginatedDto: {
        ids: assetIds || [],
        limit: assetIds.length || 0,
      },
    },
    {
      deps: [assetIds],
      skip: _.isEmpty(assetIds),
    },
  );
  const assets = assetsRequest?.data;

  const isLoading = assetsRequest?.isLoading && !assetsRequest.isEnded;

  // include Pools for Assets if applicable
  const anyItemEntityTypes = useMemo(() => {
    const result = entitiesMeta
      .map((entityMeta) => (entityMeta?.assetMeta?.assetId ? PoolItemEntityType.Asset : undefined))
      .filter(Boolean) as PoolItemEntityType[];
    return _.uniq([entityType, ...result]);
  }, [entityType, assetIds]);
  const anyItemEntitySubTypes = useMemo(() => {
    return assets
      ? _.uniq(assets.items?.map((x) => x.entityType!)?.filter(Boolean))
      : _.uniq(entities.map((x) => (x as AssetDto).entityType!).filter(Boolean));
  }, [entities, assets]);

  // todo: refactor to better logic
  // const poolIdsComputed = useMemo(() => {
  //   const result = [] as string[];
  //   const poolsIds = assets?.items?.map((asset) => asset?.poolsMeta?.poolIds || []).flat();
  //   const metaPools = entitiesMeta
  //     ?.map((entityMeta) => entityMeta?.poolsMeta?.poolIds || [])
  //     .flat();
  //   result.push(...(poolsIds || []));
  //   result.push(...(metaPools || []));
  //   return _.uniq(result);
  // }, [entitiesMeta, assets]);

  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        {/* {entityMeta?.assetId && (
          <Alert severity='info'>
            Showing also pools for the asset{" "}
            <AssetLink entity={undefined} entityId={entityMeta.assetId} withTooltip />.
          </Alert>
        )} */}

        {entities.map((entity, i) => (
          <EntityPoolsFoldableBlock key={i} entityType={entityType} entity={entity} />
        ))}
      </Stack>

      {isLoading && <LinearProgress />}

      {!isLoading && (
        <PoolItemCreateMany
          defaultValues={{
            poolId: undefined,
          }}
          entities={createManyItems}
          searchFilters={{
            ...searchFilters,
            pool: {
              ...searchFilters?.pool,
              itemType: PoolItemType.Specific,
              anyItemEntityTypes: _.isEmpty(anyItemEntityTypes) ? undefined : anyItemEntityTypes,
              anyItemEntitySubTypes: _.isEmpty(anyItemEntitySubTypes)
                ? undefined
                : anyItemEntitySubTypes,
              // excludeIds: poolIdsComputed,
            },
          }}
          onSave={(newValue) => {
            newValue.forEach((item) => {
              entityAffiliationEventEmitter.emit("entityPoolAffiliationChanged", item);
            });
            onSave && onSave(newValue);
          }}
        />
      )}
    </Stack>
  );
}

import { LocationDto } from "@/core/api/generated";
import BaseEntityAutocompleteOrCreate, {
  BaseEntityAutocompleteOrCreateInheritableProps,
} from "../components/BaseEntityAutocompleteOrCreate";
import LocationCreateUpdate, { LocationCreateUpdateProps } from "./LocationCreateUpdate";
import LocationCreateUpdateModal from "./LocationCreateUpdateModal";
import LocationsAutocomplete, { LocationsAutocompleteProps } from "./LocationsAutocomplete";

interface Props
  extends BaseEntityAutocompleteOrCreateInheritableProps<
    LocationDto,
    LocationCreateUpdateProps["defaultValues"],
    LocationsAutocompleteProps,
    Pick<LocationCreateUpdateProps, "defaultValues">
  > {
  inputDisplayProps?: LocationCreateUpdateProps["inputDisplayProps"];
}

export default function SelectOrCreateLocation({
  autocompleteProps,
  createUpdateProps,
  inputDisplayProps,
  createFormPlacement = "modal",
  onCreateStart,
  onCreate,
}: Props) {
  return (
    <BaseEntityAutocompleteOrCreate<
      LocationDto,
      LocationCreateUpdateProps["defaultValues"],
      LocationsAutocompleteProps,
      Pick<LocationCreateUpdateProps, "defaultValues">
    >
      createFormPlacement={createFormPlacement}
      onCreateStart={onCreateStart}
      onCreate={onCreate}
      renderAutocomplete={(params) => (
        <LocationsAutocomplete
          {...autocompleteProps}
          withCreate
          createOptionTitle={(inputValue) => `Create new location "${inputValue}"`}
          onCreate={(newOption) => {
            params.setDefaultValues({
              ...createUpdateProps?.defaultValues,
              name: newOption?.inputValue || createUpdateProps?.defaultValues?.name || undefined,
            });
            params.setIsCreate(true);
            params.onCreateStart && params.onCreateStart();
          }}
        />
      )}
      renderCreateUpdate={(params) => (
        <LocationCreateUpdate
          inputDisplayProps={inputDisplayProps}
          defaultValues={params.defaultValues}
          onCreate={params.onCreate}
          onSave={(newValue) => {
            params.setIsCreate(false);
          }}
        />
      )}
      renderCreateUpdateModal={(params) => (
        <LocationCreateUpdateModal
          open={params.isCreate}
          onClose={() => params.setIsCreate(false)}
          createUpdateProps={{
            inputDisplayProps: inputDisplayProps,
            defaultValues: params.defaultValues,
            onCreate: params.onCreate,
            onSave: (newValue) => {
              params.setIsCreate(false);
            },
          }}
        />
      )}
    />
  );
}

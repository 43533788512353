import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";

import AssessmentFlowView from "@/common/components/Entity/AssessmentFlow/View/AssessmentFlowView";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface AssessmentFlowViewPageQueryParams extends GeneralQueryParams {
  contractId?: string | null;
}

export default function AssessmentFlowViewPage() {
  const { assessmentFlowId } = useParams<{
    assessmentFlowId?: string;
  }>();

  return (
    <>
      <AssessmentFlowView assessmentFlowId={assessmentFlowId} assessmentFlow={undefined} />
    </>
  );
}

import { Dialog, DialogContent, DialogProps } from "@mui/material";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import AssetSubscriptionPlanToggleEnabled, {
  AssetSubscriptionPlanToggleEnabledProps,
} from "./AssetSubscriptionPlanToggleEnabled";

export interface OwnProps {
  props: AssetSubscriptionPlanToggleEnabledProps;
}

type Props = OwnProps & DialogProps;

export default function AssetSubscriptionPlanToggleEnabledModal({ props, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {props.assetSubscriptionPlan?.isEnabled ? "Disable" : "Enable"} the plan?
      </AppModalTitle>
      <DialogContent>
        <AssetSubscriptionPlanToggleEnabled {...props} />
      </DialogContent>
    </Dialog>
  );
}

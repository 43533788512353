import PageTabs from "@/App/Layouts/PageBody/PageTabs";

import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { Box, Chip, Tooltip } from "@mui/material";
import ConnectionsTabContent from "./Tabs/ConnectionsTabContent";
import RequestsTabContent from "./Tabs/RequestsTabContent";
import { useTenantToTenantCounters } from "@/common/hooks/entity/tenantToTenant/useTenantToTenantCounters";

export enum TenantConnectionIndexPageTabs {
  Connections = "Connections",
  Requests = "Requests",
}

const defaultPropagatedDeps = {
  refetch: {},
};
export type LocalPropagatedDepsMap = typeof defaultPropagatedDeps;

export default function TenantConnectionIndexPage() {
  const currentTenant = useCurrentTenant();

  const counters = useTenantToTenantCounters();

  // const internalPropagatedDeps = usePropagatedDeps<DefaultDepsMapForRefetch>();

  // const countersRequest = useApiRequest(
  //   apiClient.tenantToTenantApi.apiV1TenantToTenantCountersGet,
  //   {
  //     nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
  //   },
  //   {
  //     deps: [],
  //     debouncedDeps: {
  //       deps: [internalPropagatedDeps.depsMap["refetch"]],
  //       wait: 500,
  //       options: { leading: false, trailing: true },
  //     },
  //   },
  // );
  // const counters = countersRequest?.data;

  // const inPropagatedDeps = usePropagatedDeps<DefaultDepsMapForChange>({
  //   onChange: (params) => {
  //     if (params.depKey === "change") {
  //       // countersRequest.refetch();
  //     }
  //   },
  // });

  // useEffect(() => {
  //   const subs = [
  //     tenantToTenantEventEmitter.on2("tenantConnectionRequestCreated", (params) => {
  //       internalPropagatedDeps.trigger("refetch");
  //     }),
  //     tenantToTenantEventEmitter.on2("tenantConnectionRequestDeleted", (params) => {
  //       internalPropagatedDeps.trigger("refetch");
  //     }),
  //   ];
  //   return () => {
  //     subs.forEach((x) => x.off());
  //   };
  // }, []);

  return (
    <Box>
      <ViewLayout>
        <PageTabs
          tabIdsDefinition={TenantConnectionIndexPageTabs}
          defaultTabId={TenantConnectionIndexPageTabs.Connections}
          tabs={[
            {
              label: (
                <Box>
                  Connections{" "}
                  <Tooltip title='Total'>
                    <Chip
                      sx={{ ml: 1 }}
                      size='small'
                      color='secondary'
                      variant='outlined'
                      label={counters?.connections?.totalCount ?? "..."}
                    />
                  </Tooltip>
                </Box>
              ),
              value: TenantConnectionIndexPageTabs.Connections,
            },
            {
              label: (
                <Box>
                  Requests{" "}
                  {((counters?.connectionRequests?.incoming?.pendingCount ?? 0 > 0) && (
                    <Tooltip title='Incoming - Pending'>
                      <Chip
                        sx={{ ml: 1 }}
                        size='small'
                        color='primary'
                        variant='filled'
                        label={counters?.connectionRequests?.incoming?.pendingCount ?? "..."}
                      />
                    </Tooltip>
                  )) ||
                    undefined}
                  <Tooltip title='Total'>
                    <Chip
                      sx={{ ml: 1 }}
                      size='small'
                      color='secondary'
                      variant='outlined'
                      label={counters?.connectionRequests?.totalCount ?? "..."}
                    />
                  </Tooltip>
                </Box>
              ),
              value: TenantConnectionIndexPageTabs.Requests,
            },
          ]}
        >
          {({ activeTabId: activeTab }) => (
            <>
              {activeTab === TenantConnectionIndexPageTabs.Connections && <ConnectionsTabContent />}
              {activeTab === TenantConnectionIndexPageTabs.Requests && <RequestsTabContent />}
            </>
          )}
        </PageTabs>
      </ViewLayout>
    </Box>
  );
}

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { CustomerDto, CustomerReferenceDto } from "@/core/api/generated";
import AppIcon from "../../Icons/AppIcon";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getCustomerOptionTitle } from "./CustomerAutocomplete";

export interface CustomerInlineProps extends BaseEntityInlineInheritableProps {
  entity: CustomerDto | CustomerReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function CustomerInline({ entity, entityId, ...otherProps }: CustomerInlineProps) {
  const request = useApiRequest(
    apiClient.customersApi.apiV1CustomersCustomerIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      customerId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as CustomerDto;
  const ref = entity as CustomerReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      icon={<AppIcon of='customer' inText />}
      content={<>{full && getCustomerOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Customer "}
    />
  );
}

import { LinearProgress, Stack } from "@mui/material";
import { useHistory, useParams } from "react-router";

import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import FieldValue from "@/common/components/Form/Display/FieldValue";

export default function TenantViewPage() {
  const { tenantId } = useParams<{ tenantId?: string }>();
  const history = useHistory();

  const tenantRequest = useApiRequest(
    apiClient.tenantsApi.apiV1TenantsTenantIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantId: tenantId!,
    },
    {
      deps: [tenantId],
      skip: !tenantId,
    },
  );
  const tenant = tenantRequest?.data;

  if (tenantRequest.isLoading) {
    return <LinearProgress />;
  }
  if (!tenant && tenantRequest.isEnded) {
    return <EntityNotFoundAlert />;
  }

  return (
    <ViewPageLayout
      breadcrumbs={{
        replacements: {
          isWaitInfinitely: true,
          idBreadcrumb: tenant && {
            idValue: tenant.id!,
            newTitle: tenant.name || "",
          },
        },
      }}
      header={<DetailedViewPageHeader image={undefined} title={`Company ${tenant?.name}`} />}
    >
      {tenant && (
        <Stack spacing={2}>
          <Stack spacing={1}>
            <FieldValue label='Name' isEmpty={!tenant?.name}>
              {tenant?.name}
            </FieldValue>

            <FieldValue label='Identifier' isEmpty={!tenant?.identifier}>
              {tenant?.identifier}
            </FieldValue>
          </Stack>
        </Stack>
      )}
    </ViewPageLayout>
  );
}

import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { AssetSubscriptionPlanDto } from "@/core/api/generated";

import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AssetSubscriptionPaginatedList from "../../../AssetSubscription/ListView/AssetSubscriptionPaginatedList";

interface Props {
  assetSubscriptionPlan: AssetSubscriptionPlanDto;
}

export default function AssetSubscriptionsTabContent({ assetSubscriptionPlan }: Props) {
  return (
    <PageTabContent>
      <ViewLayout displayProps={{ viewVariant: ViewLayoutVariant.Tab }}>
        <Stack direction='column' spacing={2}>
          <AssetSubscriptionPaginatedList
            defaultValues={{
              planId: assetSubscriptionPlan.id,
            }}
            displayProps={{
              viewVariant: ViewLayoutVariant.Tab,
              header: false,
            }}
          />
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

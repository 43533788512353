import { useHistory, useParams } from "react-router";

import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";

export default function IntegrationApiClientCreateUpdatePage() {
  const { integrationApiClientId } = useParams<{ integrationApiClientId?: string }>();
  const history = useHistory();

  const isCreate = !integrationApiClientId;

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader title={isCreate ? "Create new API client" : "Edit API client"} />
      }
    >
      Not supported!
    </CreateUpdatePageLayout>
  );
}

import { Stack, SxProps, Theme } from "@mui/material";
import DamagePointsVisualizer from "./DamagePointsVisualizer";
import { VehicleVisualModelDto } from "@/core/api/generated";

interface Props {
  visualModel: VehicleVisualModelDto;
  imageContainerSx?: SxProps<Theme>;
}

export const VisualModelImagesList = ({ visualModel, imageContainerSx }: Props) => {
  return (
    <>
      <Stack direction='row' spacing={2} sx={{ flexWrap: "wrap" }}>
        {visualModel.images?.map((image, i) => (
          <DamagePointsVisualizer
            key={i}
            pointsInfo={[]}
            imageUrl={image.file?.url}
            containerSx={imageContainerSx}
          />
        ))}
      </Stack>
    </>
  );
};

import {
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  TypographyProps,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router";

import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralAttachedTagsDisplay from "@/common/components/Entity/General/GeneralTag/GeneralAttachedTagsDisplay";
import UserLink from "@/common/components/Entity/User/UserLink";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppLink from "@/common/components/Link/AppLink";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import {
  AppPermission,
  DataGrantDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
} from "@/core/api/generated";
import TenantLink from "../../Tenant/TenantLink";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import DataGrantDeleteModal from "../DataGrantDeleteModal";
import DataGrantEntityInline from "../DataGrantEntityInline";
import DataGrantEntityLink from "../DataGrantEntityLink";

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<DataGrantDto> {
  dataGrantId: string | null | undefined;
  dataGrant: DataGrantDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
  onSave?: (newValue: DataGrantDto) => void;
}

export type DataGrantViewProps = OwnProps;

export default function DataGrantView({
  dataGrantId,
  dataGrant,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
  onSave,
}: DataGrantViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const profile = useUserProfile();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const dataGrantRequest = useApiRequest(
    apiClient.dataGrantsApi.apiV1DataGrantsDataGrantIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      dataGrantId: dataGrantId!,
    },
    {
      deps: [dataGrantId],
      skip: !dataGrantId || !!dataGrant,
    },
  );
  dataGrant = dataGrantRequest?.data || dataGrant;

  const isIssuedByMe = currentTenant?.id === dataGrant?.issuerTenant?.id;
  const isConsumedByMe = currentTenant?.id === dataGrant?.consumerTenant?.id;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(null, EntityType.DataGrant, dataGrant?.id || ""),
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.DataGrant, dataGrant?.id || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      dataGrantRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        dataGrant && {
          idValue: dataGrant.id!,
          newTitle: `${dataGrant.entityType} data grant` || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.DataGrant}
      entityId={dataGrantId}
      entity={dataGrant}
      entityRequest={dataGrantRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={
                <>
                  Data grant for entity{" "}
                  <DataGrantEntityInline value={dataGrant} inlineProps={{ withIcon: false }} />{" "}
                  {dataGrant?.isAuto && (
                    <Tooltip title='This data grant is managed automatically.'>
                      <Chip variant='outlined' size='small' color='secondary' label='Auto' />
                    </Tooltip>
                  )}
                </>
              }
              titleProps={{
                to: headerProps?.withLink ? ROUTE_PATH.DATA_GRANT_VIEW(dataGrant?.id) : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              subtitle={
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  {isIssuedByMe && (
                    <GeneralAttachedTagsDisplay
                      tags={dataGrant?.tags}
                      defaultIsFolded={false}
                      edit={{
                        entityType: TagEntityType.DataGrant,
                        entityId: dataGrant?.id,
                        onSaved: (newValue) => {
                          dataGrantRequest.updateData((x) => {
                            x.tags = newValue || undefined;
                          });
                        },
                      }}
                    />
                  )}
                </AuthorizedElement>
              }
              primaryActions={
                displayProps.actions &&
                isIssuedByMe && (
                  <AuthorizedElement permissions={[AppPermission.DataGrantManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.DATA_GRANT_EDIT(dataGrant?.id)}
                      variant='outlined'
                      color='secondary'
                      size='medium'
                      disabled={dataGrant?.isAuto}
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                dataGrant &&
                displayProps.actions && (
                  <MenuWithTrigger
                    withAuthCloseOnClick
                    trigger={
                      <IconButton sx={{ ml: "auto" }}>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                  >
                    {isIssuedByMe && (
                      <AuthorizedMenuItem
                        permissions={[AppPermission.DataGrantManage]}
                        disabled={dataGrant.isAuto}
                        onClick={() => {
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='delete' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                      </AuthorizedMenuItem>
                    )}
                  </MenuWithTrigger>
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <Stack spacing={1}>
            <FieldValue label='Issuer company'>
              <TenantLink entity={dataGrant?.issuerTenant} entityId={undefined} />{" "}
              {currentTenant?.id === dataGrant?.issuerTenant?.id && (
                <Chip size='small' variant='outlined' color='secondary' label='You' />
              )}
            </FieldValue>

            <FieldValue label='Issuer user' isEmpty={!dataGrant?.issuerUserId}>
              <UserLink user={undefined} userId={dataGrant?.issuerUserId} />{" "}
              {profile?.id === dataGrant?.issuerUserId && (
                <Chip size='small' variant='outlined' color='secondary' label='You' />
              )}
            </FieldValue>

            <FieldValue label='Consumer company'>
              <TenantLink entity={dataGrant?.consumerTenant} entityId={undefined} />{" "}
              {currentTenant?.id === dataGrant?.consumerTenant?.id && (
                <Chip size='small' variant='outlined' color='secondary' label='You' />
              )}
            </FieldValue>

            <FieldValue label='Type'>
              <InlineApiEnumValue type='DataGrantType' value={dataGrant?.type} withDescription />
            </FieldValue>

            <FieldValue label='Entity type'>
              <InlineApiEnumValue type='EntityType' value={dataGrant?.entityType} withDescription />
            </FieldValue>

            <FieldValue label='Entity'>
              <DataGrantEntityLink value={dataGrant} />
            </FieldValue>

            <FieldValue label='Permissions'>
              <InlineApiEnumValueList
                type='DataGrantPermission'
                values={dataGrant?.permissions}
                valueProps={{
                  withDescription: true,
                }}
              />
            </FieldValue>

            <FieldValue label='Expires at' isEmpty={!dataGrant?.expiresAt}>
              <Datetime datetime={dataGrant?.expiresAt} withDurationFromNow />
            </FieldValue>
          </Stack>

          {/* Delete */}
          {dataGrant && (
            <DataGrantDeleteModal
              dataGrant={dataGrant}
              open={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
            />
          )}
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}

import { Stack, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";

import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ContractReminderUpdatedDto, ContractDto } from "@/core/api/generated";

import ContractReminderInfo from "./ContractReminderInfo";

export interface OwnProps {
  contract: ContractDto;
  onUpdate?: (newValue: ContractReminderUpdatedDto) => void;
  sx?: SxProps<Theme>;
}

export type ContractReminderSettingsDisplayProps = OwnProps;

export default function ContractReminderSettingsDisplay({
  contract,
  onUpdate,
  sx,
}: ContractReminderSettingsDisplayProps) {
  const reminders = useMemo(
    () =>
      (
        contract.reminderSettings && [
          contract.reminderSettings.ofDamageDetectionToCustomer,
          contract.reminderSettings.ofDamageCostEvaluationToCustomer,
        ]
      )
        ?.filter((x) => !!x)
        .map((x) => x!) || [],
    [contract],
  );

  return (
    <Stack sx={sx}>
      <Stack spacing={1}>
        {reminders.length === 0 && (
          <EntityNotFoundAlert
            title='No reminders yet'
            icon={<AppIcon of='reminder' fontSize='medium' />}
          />
        )}

        {reminders.map((x, i) => (
          <ContractReminderInfo key={i} contract={contract} reminderInfo={x} onUpdate={onUpdate} />
        ))}
      </Stack>
    </Stack>
  );
}

import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export default function PageTabContent({ children, sx }: Props) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        background: (t) => t.palette.background.paper,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

import InlineCode from "@/common/components/Code/InlineCode";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import StarIcon from "@mui/icons-material/Star";
import { Box, Stack, Typography, styled } from "@mui/material";
import Rating, { IconContainerProps } from "@mui/material/Rating";
import React from "react";

const labels: { [index: string]: string } = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}
const StyledRadioGroupRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color='error' />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color='error' />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color='warning' />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color='success' />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color='success' />,
    label: "Very Satisfied",
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}
export default function DevRatings() {
  const [value, setValue] = React.useState<number | null>(2);
  const [value1, setValue1] = React.useState<number | null>(2);
  const [hover, setHover] = React.useState(-1);
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#ff6d75",
    },
    "& .MuiRating-iconHover": {
      color: "#ff3d47",
    },
  });

  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Ratings
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>MUI Ratings</InlineCode>
      </Typography>
      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={1}>
          <Box
            sx={{
              "& > legend": { mt: 2 },
            }}
          >
            <Typography component='legend'>Controlled</Typography>
            <Rating
              name='simple-controlled'
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
            <Typography component='legend'>Read only</Typography>
            <Rating name='read-only' value={value} readOnly />
            <Typography component='legend'>Disabled</Typography>
            <Rating name='disabled' value={value} disabled />
            <Typography component='legend'>No rating given</Typography>
            <Rating name='no-value' value={null} />
          </Box>
        </Stack>
        <Stack direction='row' spacing={1}>
          {" "}
          <Stack spacing={1}>
            <Typography component='legend'>Rating precision</Typography>
            <Rating name='half-rating' defaultValue={2.5} precision={0.5} />
            <Rating name='half-rating-read' defaultValue={2.5} precision={0.5} readOnly />
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Box
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Rating
              name='hover-feedback'
              value={value1}
              precision={0.5}
              getLabelText={getLabelText}
              onChange={(event1, newValue) => {
                setValue1(newValue);
              }}
              onChangeActive={(event1, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />}
            />
            {!!value1 && <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value1]}</Box>}
          </Box>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Stack spacing={1}>
            <Rating name='size-small' defaultValue={2} size='small' />
            <Rating name='size-medium' defaultValue={2} />
            <Rating name='size-large' defaultValue={2} size='large' />
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Box
            sx={{
              "& > legend": { mt: 2 },
            }}
          >
            <Typography component='legend'>Custom icon and color</Typography>
            <StyledRating
              name='customized-color'
              defaultValue={2}
              getLabelText={(v) => `${v} Heart${v !== 1 ? "s" : ""}`}
              precision={0.5}
              icon={<FavoriteIcon fontSize='inherit' />}
              emptyIcon={<FavoriteBorderIcon fontSize='inherit' />}
            />
            <Typography component='legend'>10 stars</Typography>
            <Rating name='customized-10' defaultValue={2} max={10} />
          </Box>
        </Stack>
        <Stack direction='row' spacing={1}>
          <StyledRadioGroupRating
            name='highlight-selected-only'
            defaultValue={2}
            IconContainerComponent={IconContainer}
            getLabelText={(radioValue: number) => customIcons[radioValue].label}
            highlightSelectedOnly
          />
        </Stack>
      </Stack>
    </Box>
  );
}

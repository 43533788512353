import { Stack, Typography } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { DepartmentDto } from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import GeneralBrandingDisplay from "../../../General/GeneralBranding/GeneralBrandingDisplay";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";

interface Props {
  department: DepartmentDto;
}

export default function OverviewTabContent({ department }: Props) {
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={department} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <FieldValue label='Name' isEmpty={!department?.name}>
                {department?.name}
              </FieldValue>

              <FieldValue label='Description' isEmpty={!department?.description}>
                {department?.description}
              </FieldValue>

              <FieldValue label='Address' isEmpty={!department?.address}>
                <GeneralAddressDisplay address={department?.address} />
              </FieldValue>
            </Stack>

            <Stack spacing={1}>
              <Typography component='div' variant='h2'>
                Branding
              </Typography>

              <GeneralBrandingDisplay branding={department?.branding} />
            </Stack>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

import { Box, Chip, Popover, PopoverProps, Stack, Typography } from "@mui/material";

import { GeneralByWhoDto } from "@/core/api/generated";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import AppAvatar from "../../../Avatar/AppAvatar";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";

interface Props extends PopoverProps {
  who: GeneralByWhoDto | null | undefined;
}

export default function GeneralByWhoDetailsPopover({ who, ...popoverProps }: Props) {
  const profile = useUserProfile();

  const isMe = who && (who?.userId === profile?.id || who?.email === profile?.email);

  return (
    <Popover
      sx={{
        pointerEvents: "none",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      disableRestoreFocus
      {...popoverProps}
    >
      <Box sx={{ minWidth: "200px", maxWidth: { sm: "95vw", md: "400px" }, p: 2 }}>
        <Stack direction='row' spacing={2}>
          <AppAvatar size='medium' who={who} withPopover={false} />

          <Box>
            <Typography component='div' variant='subtitle1'>
              {who?.personName?.name}{" "}
              <Chip
                size='small'
                color='secondary'
                variant='outlined'
                label={<InlineApiEnumValue type='WhoType' value={who?.type} />}
              />{" "}
              {isMe && <Chip size='small' color='primary' variant='outlined' label='You' />}
            </Typography>
            <Typography component='div' variant='body1'>
              {who?.email}
            </Typography>
            <Typography component='div' variant='body1'>
              {who?.phone}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Popover>
  );
}

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AppIcon from "@/common/components/Icons/AppIcon";
import RouteCategoryIndex from "@/common/components/Route/RouteCategoryIndex";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function OtherPage() {
  return (
    <ViewPageLayout header={<SimpleViewPageHeader title='Other' />}>
      <RouteCategoryIndex
        direction='row'
        childrenLinks={[
          {
            icon: <AppIcon of='accessory' />,
            title: "Accessories",
            to: ROUTE_PATH.ACCESSORIES,
          },
          {
            icon: <AppIcon of='customers' />,
            title: "Customers",
            to: ROUTE_PATH.CUSTOMERS,
          },
        ]}
      />
    </ViewPageLayout>
  );
}

import { Box } from "@mui/material";

import { TextHelper } from "@/common/helpers/text";
import FoldableBlock from "../../../Display/FoldableBlock";
import TagList, { TagListProps } from "./TagList";

export interface FoldingTagListProps {
  tagListProps: TagListProps;
  isFolded?: boolean;
}

export default function FoldingTagList({ tagListProps, isFolded = false }: FoldingTagListProps) {
  return (
    <FoldableBlock
      defaultIsFolded={isFolded}
      trigger={{
        size: "small",
        label: (
          <Box>
            {tagListProps?.tags?.length}{" "}
            {TextHelper.pluralizeManual("tag", tagListProps?.tags?.length || 0, "tags")}:
          </Box>
        ),
      }}
    >
      <Box>
        <TagList {...tagListProps} />
      </Box>
    </FoldableBlock>
  );
}

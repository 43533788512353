import { Alert, Box, Grid, Stack, Typography } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import {
  AppPermission,
  EntityType,
  RepairOperationDto,
  RepairOperationStage,
} from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppTooltip from "@/common/components/AppTooltip";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralByWhoDisplay from "@/common/components/Entity/General/Display/GeneralByWhoDisplay";
import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import VehicleBlock from "@/common/components/Entity/Vehicle/VehicleBlock";
import VehicleDamageLink from "@/common/components/Entity/VehicleDamage/VehicleDamageLink";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import GeneralStageHistory from "@/common/components/GeneralStageHistory";
import AppIcon from "@/common/components/Icons/AppIcon";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import AppTypography from "@/common/components/Text/AppTypography";
import { FileItem } from "@/common/fileItem";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useHighlightPropsFromQueryString } from "@/common/hooks/useHighlightPropsFromQueryString";
import { useState } from "react";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import { RepairOperationViewPageHighlightProps } from "../RepairOperationView";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";

interface Props {
  repairOperation: RepairOperationDto;
  handleUpdateStage: (newStage: RepairOperationStage) => Promise<void>;
}

export default function OverviewTabContent({ repairOperation, handleUpdateStage }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const { hasPermissions } = useAuthorizationInfo();
  const highlightProps = useHighlightPropsFromQueryString<RepairOperationViewPageHighlightProps>();

  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={repairOperation} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack direction='column' spacing={2}>
            {repairOperation?.stageHistory?.stage === RepairOperationStage.Draft && (
              <AuthorizedElement permissions={[AppPermission.RepairOperationManage]}>
                <Alert severity='info' icon={<AppIcon of='tip' />}>
                  This operation is in{" "}
                  <strong>
                    <InlineApiEnumValue
                      type='RepairOperationStage'
                      value={RepairOperationStage.Draft}
                    />
                  </strong>{" "}
                  stage. Please review and complete it in order for changes to take effect.
                </Alert>
              </AuthorizedElement>
            )}

            <Stack direction={{ xxs: "column", md: "row" }} alignItems='flex-start' spacing={2}>
              <VehicleBlock
                sx={{ maxWidth: { md: "400px" } }}
                variant='reference'
                vehicle={repairOperation.vehicle}
                withDetailsToggle
                withLink
              />
            </Stack>

            <Box>
              <Grid container columnSpacing={2} rowSpacing={1} sx={{ mb: 1 }}>
                <Grid item xxs={12} md minWidth={200}>
                  <Stack direction='column' spacing={1}>
                    <FieldValue label='Date'>
                      <Datetime datetime={repairOperation.date} withDurationFromNow />
                    </FieldValue>
                    <FieldValue label='Responsible user'>
                      <GeneralByWhoDisplay who={repairOperation.responsibleUser} />
                    </FieldValue>
                    <FieldValue label='Name'>
                      <AppTypography ellipsing={{ enabled: true }} component='div'>
                        {repairOperation.name || "-"}
                      </AppTypography>
                    </FieldValue>
                    <FieldValue label='Description'>
                      <AppTypography ellipsing={{ enabled: true }} component='div'>
                        {repairOperation.description || "-"}
                      </AppTypography>
                    </FieldValue>
                  </Stack>
                </Grid>
                <Grid item xxs={12} md minWidth={200}>
                  <Stack direction='column' spacing={1}>
                    {repairOperation.currency && (
                      <FieldValue label='Currency'>
                        <GeneralCurrencyDisplay currency={repairOperation.currency} />
                      </FieldValue>
                    )}
                    {repairOperation.notes && (
                      <FieldValue label='Notes'>{repairOperation.notes}</FieldValue>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>

            {repairOperation.attachments!.length !== 0 && (
              <Box>
                <FileListView
                  files={FileItem.createManyFrom(repairOperation.attachments)}
                  maxVisibleFiles={10}
                  itemWidth={45}
                />
              </Box>
            )}

            {/* Affiliation */}
            <Stack spacing={1} sx={{ mt: 2 }}>
              <Typography variant='subtitle1'>
                Affiliation{" "}
                <AppIconButton
                  tooltipProps={{ title: "Edit entity affiliation" }}
                  onClick={() => setIsUpdateAffiliationModalOpened(true)}
                >
                  <AppIcon of='edit' fontSize='small' />
                </AppIconButton>
              </Typography>
              <AffiliationInfoDisplay
                tenantId={repairOperation.tenantId}
                departmentIds={repairOperation.departmentIds}
                locationIds={repairOperation.locationIds}
                sx={{ mt: 1 }}
              />
              <UpdateEntityAffiliationCascadeModal
                open={isUpdateAffiliationModalOpened}
                onClose={() => setIsUpdateAffiliationModalOpened(false)}
                entityType={EntityType.RepairOperation}
                entityId={repairOperation.id}
              />
            </Stack>

            {/* Stages */}
            <Box>
              <Typography component='div' variant='h6'>
                Stages
              </Typography>
              <GeneralStageHistory
                stageEnumType='RepairOperationStage'
                stageHistory={repairOperation.stageHistory}
                withUpdateButtons={hasPermissions([AppPermission.RepairOperationManage])}
                onUpdateStage={async (newStage) => await handleUpdateStage(newStage)}
              />
            </Box>

            <Alert severity='info' icon={<AppIcon of='tip' />}>
              After the operation is{" "}
              <strong>
                <InlineApiEnumValue
                  type='RepairOperationStage'
                  value={RepairOperationStage.Completed}
                />
              </strong>
              , the damages will be marked as repaired in the vehicle history.
            </Alert>

            <DataTabular
              // headerProps={{
              //   variant: "bordered",
              // }}
              // contentProps={{
              //   itemVariant: "bordered",
              // }}
              columns={[
                {
                  field: "damage",
                  title: "Damage",
                  flex: 1,
                  renderCell: (item) => (
                    <VehicleDamageLink
                      vehicleId={item.damage?.vehicleId}
                      entity={item.damage}
                      withIcon
                      withTooltip
                    />
                  ),
                },
                {
                  field: "notes",
                  title: "Notes",
                  flex: 1,
                  renderCell: (item) =>
                    item.notes ? (
                      <AppTooltip title={item.notes || ""}>
                        <AppTypography ellipsing={{ enabled: true }}>{item.notes}</AppTypography>
                      </AppTooltip>
                    ) : (
                      "-"
                    ),
                },
                {
                  field: "attachments",
                  title: "Attachments",
                  flex: 1,
                  renderCell: (item) => (
                    <>
                      {item.attachments!.length !== 0 && (
                        <FileListView
                          files={FileItem.createManyFrom(item.attachments)}
                          maxVisibleFiles={2}
                          itemWidth={45}
                        />
                      )}
                    </>
                  ),
                },
                {
                  field: "cost",
                  title: "Cost",
                  flex: 1,
                  renderCell: (item) => (
                    <CurrencyValue value={item.cost} currency={repairOperation.currency} />
                  ),
                },
                {
                  field: "discount",
                  title: "Discount",
                  flex: 1,
                  renderCell: (item) => (
                    <GeneralDiscountDisplay
                      discount={item.discount || undefined}
                      currency={repairOperation.currency}
                      detailsPlacement='tooltip'
                    />
                  ),
                },
                {
                  field: "tax",
                  title: "Tax",
                  flex: 1,
                  renderCell: (item) => (
                    <GeneralTaxDisplay
                      tax={item.tax || undefined}
                      currency={repairOperation.currency}
                    />
                  ),
                },
                {
                  field: "total",
                  title: "Total",
                  flex: 1,
                  renderCell: (item) => (
                    <CurrencyValue value={item.total} currency={repairOperation.currency} />
                  ),
                },
              ]}
              rows={repairOperation.items!}
              getRowId={(item) => item.id!}
              borderVariant='bordered'
              isColumnSelectorEnabled={false}
              rowHeight={65}
              isColumnMenuEnabled={false}
              isPaginationEnabled={false}
              // rowHighlightPropsGetter={(item) =>
              //   (item.id === highlightProps?.itemId && {
              //     isHighlighted: true,
              //     durationMs: 5000,
              //     withAutoScroll: true,
              //   }) ||
              //   {}
              // }
            />

            {/* Summary */}
            <Stack direction='row' justifyContent='flex-end'>
              <GeneralPriceSummaryDisplay
                sx={{ minWidth: { xxs: "100%", md: "300px" } }}
                summary={{
                  currency: repairOperation.currency,
                  subTotal: repairOperation.subTotal,
                  subTotalIncDiscount: repairOperation.subTotalIncDiscount,
                  discount: repairOperation.discount,
                  tax: repairOperation.tax,
                  total: repairOperation.total,
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

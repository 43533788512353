import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { TenantDto } from "@/core/api/generated";
import { Stack, Typography } from "@mui/material";

interface Props {
  tenant?: TenantDto | null;
}

export default function OverviewTabContent({ tenant }: Props) {
  return (
    <PageTabContent>
      <Stack spacing={2}>
        <PageTabHeader title='Overview' />
        <Stack direction='column' spacing={2}>
          <FieldValue label='Name'>{tenant?.name}</FieldValue>
          <FieldValue label='Identifier'>{tenant?.identifier}</FieldValue>
          {/* Branding */}
          <Typography variant='h4'>Branding</Typography>
          <FieldValue label='Short name'>{tenant?.branding?.shortName}</FieldValue>
          <FieldValue label='Full legal name'>{tenant?.branding?.fullLegalName}</FieldValue>
          <FieldValue label='Disclaimer'>{tenant?.branding?.disclaimer}</FieldValue>
        </Stack>
      </Stack>
    </PageTabContent>
  );
}

import { ListItemIcon, ListItemText } from "@mui/material";

import AppMenuItem from "@/common/components/Menu/AppMenuItem";
import { BaseSelectOption } from "@/common/ts/select";
import {
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  SubscriptionPlanDurationDto,
} from "@/core/api/generated";
import AppIcon from "../../../Icons/AppIcon";
import BaseSelect, { BaseItemSelectInheritableProps } from "../../components/BaseSelect";
import SubscriptionPlanDurationInline from "./SubscriptionPlanDurationInline";

type SelectOption = BaseSelectOption<SubscriptionPlanDurationDto>;

const itemToOption = (data: SubscriptionPlanDurationDto): SelectOption => ({
  id: data.id!,
  title: "",
  data: data,
});

interface Props extends BaseItemSelectInheritableProps<SubscriptionPlanDurationDto> {
  currency: GeneralCurrencyDto | GeneralCurrencyInputDto | null | undefined;
}

export default function SubscriptionPlanDurationSelect({ currency, ...otherProps }: Props) {
  return (
    <BaseSelect
      {...otherProps}
      itemToOption={itemToOption}
      label={otherProps?.label || "Subscription plan duration"}
      renderOption={(props, option) => (
        <AppMenuItem {...props}>
          <ListItemIcon>
            <AppIcon of='duration' fontSize='medium' />
          </ListItemIcon>
          <ListItemText
            primary={
              <SubscriptionPlanDurationInline
                duration={option.data}
                currency={currency}
                withIcon={false}
                withTooltip={false}
              />
            }
          />
        </AppMenuItem>
      )}
    />
  );
}

import { Box, TablePaginationProps } from "@mui/material";

import { PaginationHelper } from "@/common/helpers/pagination";
import { PaginationInfoDto } from "@/core/api/generated";
import {
  gridRowCountSelector,
  PaginationPropsOverrides,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { useMemo } from "react";
import AppPagination from "../Pagination/AppPagination";

declare module "@mui/x-data-grid" {
  interface PaginationPropsOverrides {
    isEnabled?: boolean;
    maxPageSize?: number;
    onChangeCustom: (newValue: { page: number; pageSize: number }) => void;
  }
}

export type CustomDataGridPaginationProps = Partial<TablePaginationProps> &
  PaginationPropsOverrides;

export default function CustomDataGridPagination({
  isEnabled,
  maxPageSize,
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  onChangeCustom,
}: CustomDataGridPaginationProps) {
  const apiRef = useGridApiContext();
  // const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  // const page = useGridSelector(apiRef, gridPageSelector);
  // const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);

  const paginationInfoComputed = useMemo<PaginationInfoDto>(
    () => PaginationHelper.mapGridPaginationStateToPaginationInfo(apiRef.current.state.pagination),
    [apiRef.current.state.pagination],
  );

  // console.log(3, {
  //   count,
  //   page,
  //   rowsPerPage,
  //   rowCount,
  //   paginationState: apiRef.current.state.pagination,
  //   paginationInfoComputed,
  // });

  if (!isEnabled) {
    return null;
  }

  return (
    <Box sx={{ flex: 1 }}>
      <AppPagination
        paginationInfo={paginationInfoComputed}
        maxPageSize={maxPageSize}
        onChange={(newValue) => {
          const newPage = newValue.currentPage ?? 0;
          const newPageSize = newValue.limit ?? PaginationHelper.defaultLimit;
          console.log("CustomDataGridPagination.onChange.", { newValue, newPage, newPageSize });
          // onPageChange && onPageChange(null, newValue.currentPage ?? 0);

          // NB: calling setPage and setPageSize at the same time leads to grid state not having time to update properly, leading to incorrect state:
          // pageSize/page overrides each other.
          // apiRef.current.setPageSize(newPageSize);
          // apiRef.current.setPage(newPage);

          // use custom change callback to fix the issue
          onChangeCustom({
            page: newPage,
            pageSize: newPageSize,
          });

          // apiRef.current.setRowCount(newValue.totalCount ?? rowCount);
        }}
      />
    </Box>
  );
}

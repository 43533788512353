import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import { VehicleDto } from "@/core/api/generated";
import VehicleActualStateView from "../../VehicleActualState/VehicleActualStateView";

interface Props {
  vehicle: VehicleDto;
}
export default function VehicleActualStateTabContent({ vehicle }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column'>
        <VehicleActualStateView
          vehicleId={vehicle.id || ""}
          displayProps={{ header: true, breadcrumbs: false }}
          headerProps={{
            withLink: false,
            title: <PageTabHeader title={"Actual state"} subtitle2={undefined} />,
            typographyProps: {},
          }}
        />
      </Stack>
    </PageTabContent>
  );
}

import useCascadeActionSpec from "@/common/hooks/entity/cascadeActions/useCascadeActionSpec";
import {
  CascadeActionType,
  EntityType,
  TenantMembershipOfEntityUpdateDto,
} from "@/core/api/generated";
import { Box, Checkbox, FormControlLabel, IconButton, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import AppTooltip from "../../AppTooltip";
import CascadeActionSettingsInputModal from "../../CascadeActions/RelatedEntityTypesInputModal";
import AppIcon from "../../Icons/AppIcon";
import AppTypography from "../../Text/AppTypography";
import DepartmentAutocompleteOrCreate from "../Department/DepartmentAutocompleteOrCreate";
import LocationAutocompleteOrCreate from "../Location/LocationAutocompleteOrCreate";

export type TenantStructureMembershipFormikValues = Pick<
  TenantMembershipOfEntityUpdateDto,
  "departmentId" | "locationId" | "relatedEntitiesTypes"
>;

interface Props {
  entityType: EntityType;
}

export default function TenantStructureMembershipInputs({ entityType }: Props) {
  const [isUpdateCascade, setIsUpdateCascade] = useState(false);
  const [isUpdateCascadeSettingsModalOpen, setIsUpdateCascadeSettingsModalOpen] = useState(false);

  const formikContext = useFormikContext<TenantStructureMembershipFormikValues>();
  const { values, setFieldValue } = formikContext;

  const cascadeActionSpec = useCascadeActionSpec(
    entityType,
    CascadeActionType.UpdateEntityAffiliation,
  );

  useEffect(() => {
    if (cascadeActionSpec) {
      setFieldValue(
        "relatedEntitiesTypes",
        isUpdateCascade ? cascadeActionSpec?.relatedEntitiesTypes : undefined,
      );
    }
  }, [cascadeActionSpec]);

  return (
    <Stack spacing={1}>
      <DepartmentAutocompleteOrCreate
        autocompleteProps={{
          fullWidth: true,
          label: "Department",
          entityId: values.departmentId,
          onChange: (newValue) => {
            setFieldValue("departmentId", newValue?.id);
            setFieldValue("locationId", null);
          },
        }}
        createUpdateProps={{
          defaultValues: {},
        }}
        onCreate={(newValue) => {
          setFieldValue("departmentId", newValue?.id);
        }}
      />

      <LocationAutocompleteOrCreate
        autocompleteProps={{
          fullWidth: true,
          label: "Location",
          entityId: values.locationId,
          onChange: (newValue) => {
            setFieldValue("locationId", newValue?.id);
          },
          searchFilters: { departmentId: values.departmentId },
          isAutoSelectSingleOption: false,
        }}
        createUpdateProps={{
          defaultValues: { departmentId: values.departmentId },
        }}
        onCreate={(newValue) => {
          setFieldValue("locationId", newValue?.id);
        }}
      />

      <Stack direction='row' alignItems='center' spacing={1}>
        <Box>
          <FormControlLabel
            sx={{ mr: 0 }}
            control={
              <Checkbox
                checked={isUpdateCascade}
                onChange={(e) => {
                  setIsUpdateCascade(e.target.checked);
                  setFieldValue(
                    "relatedEntitiesTypes",
                    e.target.checked ? cascadeActionSpec?.relatedEntitiesTypes : undefined,
                  );
                }}
              />
            }
            label={
              <AppTooltip title='Affiliation will also be applied to related entities'>
                <AppTypography>Update affiliation cascade</AppTypography>
              </AppTooltip>
            }
          />
        </Box>

        {isUpdateCascade && (
          <IconButton size='small' onClick={() => setIsUpdateCascadeSettingsModalOpen(true)}>
            <AppIcon of='settings' />
          </IconButton>
        )}
      </Stack>

      <CascadeActionSettingsInputModal
        open={isUpdateCascadeSettingsModalOpen}
        onClose={() => setIsUpdateCascadeSettingsModalOpen(false)}
        spec={cascadeActionSpec}
        relatedEntitiesTypes={values.relatedEntitiesTypes || []}
        onRelatedTypesChange={(relatedEntitiesTypes) =>
          setFieldValue("relatedEntitiesTypes", relatedEntitiesTypes)
        }
      />
    </Stack>
  );
}

import { apiClient } from "@/core/api/ApiClient";
import { ContractDto, EntityType } from "@/core/api/generated";
import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: ContractDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function ContractDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.Contract}
      entity={entity}
      entityId={entity.id}
      deleteFunc={(params) =>
        apiClient.contractsApi.apiV1ContractsContractIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          contractId: params.entityId,
          isDeleteCascade: params.isDeleteCascade,
        })
      }
      {...otherProps}
    />
  );
}

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import AssetSubscriptionPlanPaginatedList from "@/common/components/Entity/AssetSubscriptionPlan/ListView/AssetSubscriptionPlanPaginatedList";

export default function AssetSubscriptionPlansPage() {
  return (
    <>
      <AssetSubscriptionPlanPaginatedList />
    </>
  );
}

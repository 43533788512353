import { SortOrder } from "@/core/api/generated";
import { TypeHelper } from "../helpers/type";
import { SortDefinitionItem } from "./sortDefinitionItem";

export class SortFieldSpec {
  public readonly field: string;
  public readonly title: string;
  public readonly sortOrders: SortOrder[];
  public readonly onChange?: (newValue: SortDefinitionItem) => void;

  constructor(params: {
    field: string;
    title: string;
    /** If undefined, default sort orders are used. */
    sortOrders?: SortOrder[];
    onChange?: (newValue: SortDefinitionItem) => void;
  }) {
    this.field = params.field;
    this.title = params.title;
    this.sortOrders = params.sortOrders ?? [SortOrder.Asc, SortOrder.Desc];
    this.onChange = params.onChange;
  }

  public get isValid(): boolean {
    return (
      !TypeHelper.isEmpty(this.field) &&
      !TypeHelper.isEmpty(this.title) &&
      !TypeHelper.isEmpty(this.sortOrders)
    );
  }

  public getFirstSortOrder(): SortOrder | undefined {
    return this.sortOrders.at(0);
  }

  public getFirstSortOrderOrThrow(): SortOrder {
    const result = this.getFirstSortOrder();
    if (!result) {
      throw new Error(`Unable to get first sort order.`);
    }
    return result;
  }
}

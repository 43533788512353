import { DialogContentText, FormControl, TextField } from "@mui/material";

import { ValidationHelper } from "@/common/validation";
import { AdminUserDto, UserDto, UserSuspendDto } from "@/core/api/generated";

import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useState } from "react";
import ConfirmationModal, { ConfirmationModalProps } from "../../Modals/ConfirmationModal";

export interface OwnProps {
  user: UserDto | AdminUserDto;
  isGlobal?: boolean;
  suspendFunc: (params: { targetUserId: string } & UserSuspendDto) => Promise<void>;
}

type Props = OwnProps &
  Omit<ConfirmationModalProps, "title" | "content" | "onCancel" | "onConfirm">;

export default function SuspendUserModal({
  user,
  isGlobal = false,
  suspendFunc,
  ...dialogProps
}: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [reason, setReason] = useState<string | undefined>(undefined);

  return (
    <ConfirmationModal
      fullWidth
      maxWidth='sm'
      {...dialogProps}
      title='Suspend the user?'
      body={
        <>
          <DialogContentText>
            You are going to suspend user with the email <strong>{user.email}</strong>.
          </DialogContentText>
          <DialogContentText>
            {isGlobal
              ? `Suspended user won't be able to access the app (including all user's workspaces) until resumed.`
              : `Suspended user won't be able to access your workspace until resumed.`}
          </DialogContentText>
          <DialogContentText>Please confirm the action.</DialogContentText>

          <DialogContentText>
            <FormControl fullWidth margin='dense'>
              <TextField
                multiline
                value={reason}
                label='Reason'
                onChange={(e) => setReason(e.target.value)}
              />
            </FormControl>
          </DialogContentText>
        </>
      }
      onCancel={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      onConfirm={async () => {
        try {
          await suspendFunc({ targetUserId: user.id!, reason });
          enqueueSnackbar("User suspended.", { variant: "success" });
          dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");
        } catch (err) {
          const validation2 = ValidationHelper.handleApiErrorResponse(err);
          validation2.hasErrors &&
            enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
        }
      }}
    />
  );
}

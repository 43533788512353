import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AppIcon from "@/common/components/Icons/AppIcon";
import RouteCategoryIndex from "@/common/components/Route/RouteCategoryIndex";
import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission } from "@/core/api/generated";

export default function ProductsIndexPage() {
  return (
    <ViewPageLayout header={<SimpleViewPageHeader title='Products' />}>
      <RouteCategoryIndex
        direction='row'
        childrenLinks={[
          {
            icon: <AppIcon of='productLocation' />,
            title: "Sales locations",
            to: ROUTE_PATH.PRODUCT_SALES_LOCATIONS,
            permissions: [AppPermission.ProductLocationRead],
          },
          {
            icon: <AppIcon of='asset' />,
            title: "Assets",
            to: ROUTE_PATH.ASSETS,
            permissions: [AppPermission.AssetRead],
          },
          {
            icon: <AppIcon of='pool' />,
            title: "Pools",
            to: ROUTE_PATH.POOLS,
            permissions: [AppPermission.PoolRead],
          },
          {
            icon: <AppIcon of='assetSubscriptionPlan' />,
            title: "Asset subscription plans",
            to: ROUTE_PATH.ASSET_SUBSCRIPTION_PLANS,
            permissions: [AppPermission.AssetSubscriptionPlanRead],
          },
          {
            icon: <AppIcon of='assetSubscription' />,
            title: "Asset subscriptions",
            to: ROUTE_PATH.ASSET_SUBSCRIPTIONS,
            permissions: [AppPermission.AssetSubscriptionRead],
          },
        ]}
      />
    </ViewPageLayout>
  );
}

import { Stack } from "@mui/material";

import { IBasePageLayout } from "@/common/ts/layout";
import PageBody, { PageBodyProps } from "../PageBody/PageBody";
import PageFooter, { PageFooterProps } from "../PageFooter/PageFooter";
import PageHeader, { PageHeaderProps } from "../PageHeader/PageHeader";
import BaseGoBackPageLayout, { BaseGoBackPageLayoutProps } from "./BaseGoBackPageLayout";

interface Props extends IBasePageLayout {
  goBackLayoutProps?: BaseGoBackPageLayoutProps;
  headerProps?: PageHeaderProps;
  bodyProps?: PageBodyProps;
  footerProps?: PageFooterProps;
}

export default function CreateUpdatePageLayout({
  header,
  body,
  footer,
  children,
  goBackLayoutProps,
  headerProps,
  bodyProps,
  footerProps,
}: Props) {
  return (
    <BaseGoBackPageLayout {...goBackLayoutProps}>
      <Stack direction='column' spacing={2} sx={{ height: "100%", pb: 10 }}>
        <PageHeader
          sx={{
            px: 3,
            pb: 1.5,
            background: (t) => t.palette.background.paper,
          }}
          {...headerProps}
        >
          {header}
        </PageHeader>
        <PageBody {...bodyProps}>{body || children}</PageBody>
        <PageFooter {...footerProps}>{footer}</PageFooter>
      </Stack>
    </BaseGoBackPageLayout>
  );
}

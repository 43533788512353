import { useParams } from "react-router-dom";

import PoolItemPaginatedList from "@/common/components/Entity/PoolItem/PoolItemPaginatedList";
import { Box } from "@mui/material";

export default function PoolItemsPage() {
  const { poolId } = useParams<{ poolId: string }>();

  return (
    <Box>
      <PoolItemPaginatedList
        poolId={poolId}
        pool={undefined}
        displayProps={{
          breadcrumbs: true,
          filters: true,
        }}
      />
    </Box>
  );
}

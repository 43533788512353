import { DATETIME_FORMATS } from "@/common/constants/common";
import { CustomFormikSubProps } from "@/common/helpers/formik";
import { ContractSpotInfoInputDto, SpotType } from "@/core/api/generated";
import { FormControl, FormHelperText, SxProps, TextField, Theme } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import SpotAutocompleteOrCreate from "../Spot/SpotAutocompleteOrCreate";
import VisualInspectionAutocompleteOrCreate from "../VisualInspection/VisualInspectionAutocompleteOrCreate";

interface Props {
  contractId: string | null | undefined;
  formikProps: CustomFormikSubProps<ContractSpotInfoInputDto | null | undefined>;
  spotType: SpotType | undefined | null;
  onDateChange?: (newValue: Moment | null) => void;
  sx?: SxProps<Theme>;
}

export default function ContractVehicleTimeLocationInfoInput({
  contractId,
  formikProps,
  spotType,
  onDateChange,
  sx,
}: Props) {
  const { values, touched, errors, setFieldValue, setFieldError } = formikProps;

  return (
    <>
      <FormControl margin='dense' fullWidth error={Boolean(errors?.date)}>
        <MobileDateTimePicker
          ampm={false}
          label='Date & Time'
          value={(values?.date && moment(values.date)) || null}
          format={DATETIME_FORMATS.DISPLAY_DATETIME}
          onChange={(newValue) => {
            setFieldValue("date", newValue?.format() || null);
            onDateChange && onDateChange(newValue || null);
          }}
          slots={{ textField: (params) => <TextField {...params} /> }}
        />
        <FormHelperText error>{errors?.date}</FormHelperText>
      </FormControl>

      <FormControl margin='dense' fullWidth error={Boolean(errors?.spotId)}>
        <SpotAutocompleteOrCreate
          autocompleteProps={{
            entityId: values?.spotId,
            searchFilters: {
              type: spotType || undefined,
            },
            onChange: (newValue) => {
              setFieldValue("spotId", newValue?.id);
            },
          }}
          createFormPlacement='modal'
          onCreate={(newValue) => {
            setFieldValue("spotId", newValue?.id);
          }}
        />
        <FormHelperText error>{errors?.spotId}</FormHelperText>
      </FormControl>

      <FormControl margin='dense' fullWidth error={Boolean(errors?.visualInspectionId)}>
        <VisualInspectionAutocompleteOrCreate
          autocompleteProps={{
            entityId: values?.visualInspectionId,
            searchFilters: {
              contractId: contractId,
            },
            onChange: (newValue) => {
              setFieldValue("visualInspectionId", newValue?.id);
            },
          }}
          createFormPlacement='modal'
          onCreate={(newValue) => {
            setFieldValue("visualInspectionId", newValue?.id);
          }}
        />
        <FormHelperText error>{errors?.visualInspectionId}</FormHelperText>
      </FormControl>

      <FormControl margin='dense' fullWidth>
        <TextField
          label='Notes'
          multiline
          rows={2}
          value={values?.notes || ""}
          error={Boolean(errors?.notes)}
          helperText={errors?.notes}
          onChange={(e) => {
            setFieldValue("notes", e.target.value);
          }}
        />
      </FormControl>
    </>
  );
}

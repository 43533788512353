import {
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";

import GoBackButton from "@/common/components/Button/GoBackButton";
import InlineCode from "@/common/components/Code/InlineCode";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityChangedByInfoDisplay from "@/common/components/EntityInfo/EntityChangedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import DurationDisplay from "@/common/components/Form/Display/DurationDisplay";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import InlinePermissionDisplay from "@/common/components/Permission/InlinePermissionDisplay";
import InlineRoleDisplay from "@/common/components/Entity/Role/InlineRoleDisplay";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import AppTypography from "@/common/components/Text/AppTypography";

export default function CustomSessionViewPage() {
  const { customSessionId } = useParams<{ customSessionId?: string }>();

  const customSessionRequest = useApiRequest(
    apiClient.adminCustomSessionsApi.apiV1AdminCustomSessionsSessionIdGet,
    {
      sessionId: customSessionId!,
    },
    {
      deps: [customSessionId],
      skip: !customSessionId,
    },
  );
  const customSession = customSessionRequest?.data;

  return (
    <Stack direction='column' spacing={2}>
      {customSessionRequest.isLoading && !customSession && <LinearProgress />}

      {customSession && (
        <>
          <Stack direction='row' spacing={1} sx={{ alignItems: "flex-start", mb: 2 }}>
            <AppTypography
              component='div'
              variant='h4'
              ellipsing={{ enabled: true }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <GoBackButton />
              <AppTypography variant='h4' component='span' ellipsing={{ enabled: true }}>
                Custom session{" "}
                {customSession.isExpired && (
                  <Chip variant='filled' color='error' label={"Expired"} />
                )}
              </AppTypography>
            </AppTypography>

            <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ flex: 1 }}>
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton sx={{ ml: "auto" }}>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <MenuItem
                  component={RouterLink}
                  to={ROUTE_PATH.ADMIN_CUSTOM_SESSION_EDIT(customSession.id)}
                >
                  <ListItemIcon>
                    <AppIcon of='edit' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
              </MenuWithTrigger>
            </Stack>
          </Stack>

          <Stack
            direction={{ xxs: "column", md: "row" }}
            alignItems='flex-start'
            spacing={2}
          ></Stack>

          <Grid container columnSpacing={2} rowSpacing={1}>
            <Grid item xxs={12} md minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Id'>
                  <InlineCode>{customSession.id}</InlineCode>
                </FieldValue>
                <FieldValue label='Identifier'>
                  <InlineCode>{customSession.identifier}</InlineCode>
                </FieldValue>
                <FieldValue label=' Membership type'>
                  <InlineApiEnumValue
                    type='UserMembershipType'
                    value={customSession.membershipType}
                  />
                </FieldValue>
                <FieldValue label='Expires at' isEmpty={!customSession.expiresAt}>
                  <Datetime datetime={customSession.expiresAt} withDurationFromNow />
                </FieldValue>
                <FieldValue label='Lifetime' isEmpty={!customSession.lifetime}>
                  <DurationDisplay value={customSession.lifetime} />
                </FieldValue>
                <FieldValue label='Lifetime left' isEmpty={!customSession.lifetimeLeft}>
                  <DurationDisplay value={customSession.lifetimeLeft} />
                </FieldValue>
              </Stack>
            </Grid>
            <Grid item xxs={12} md minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Roles' isEmpty={!customSession.roles}>
                  {customSession.roles?.map((x) => <InlineRoleDisplay key={x.id!} role={x} />)}
                </FieldValue>
                <FieldValue label='Permissions' isEmpty={!customSession.permissions}>
                  {customSession.permissions?.map((x) => (
                    <InlinePermissionDisplay key={x!} permission={x} />
                  ))}
                </FieldValue>
                <FieldValue label='Modules' isEmpty={!customSession.modules}>
                  {customSession.modules?.map((x) => (
                    <InlineApiEnumValue key={x!} type='AppModule' value={x} />
                  ))}
                </FieldValue>
              </Stack>
            </Grid>
          </Grid>

          <Grid container columnSpacing={2} rowSpacing={1}>
            <Grid item xxs={12} md minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Created' isEmpty={!customSession.createdAt}>
                  <EntityChangedByInfoDisplay
                    datetime={customSession?.createdAt}
                    userId={undefined}
                  />
                </FieldValue>
              </Stack>
            </Grid>
            <Grid item xxs={12} md minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Updated' isEmpty={!customSession.updatedAt}>
                  <EntityChangedByInfoDisplay
                    datetime={customSession?.updatedAt}
                    userId={undefined}
                  />
                </FieldValue>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    </Stack>
  );
}

import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import RepairOperationCreateUpdate from "@/common/components/Entity/RepairOperation/RepairOperationCreateUpdate";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { ROUTE_PATH } from "@/common/constants/routing";

export interface RepairOperationCreateUpdatePageQueryParams extends GeneralQueryParams {
  vehicleId?: string | null;
  contractId?: string | null;
}

export default function RepairOperationCreateUpdatePage() {
  const { repairOperationId } = useParams<{ repairOperationId?: string }>();
  const { vehicleId, contractId } = useQueryParams<RepairOperationCreateUpdatePageQueryParams>();
  const isCreate = !repairOperationId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new repair operation" : "Edit repair operation"}
        />
      }
    >
      <RepairOperationCreateUpdate
        repairOperationId={repairOperationId}
        defaultValues={{
          vehicleId: vehicleId || undefined,
          contractId: contractId || undefined,
        }}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.REPAIR_OPERATION_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}

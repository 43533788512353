import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppTypography from "@/common/components/Text/AppTypography";
import { TypeHelper } from "@/common/helpers/type";
import { TeslaConnectionDto } from "@/core/api/generated/v0.1-demo";
import { Stack } from "@mui/material";
import UserInline from "../../../User/UserInline";

interface Props {
  teslaConnection: TeslaConnectionDto;
  viewVariant?: ViewLayoutVariant;
  onUpdate: (newValue?: TeslaConnectionDto) => void;
}

export default function OverviewTabContent({
  teslaConnection,
  viewVariant = ViewLayoutVariant.Tab,
  onUpdate,
}: Props) {
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={viewVariant && { viewVariant: viewVariant }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={teslaConnection} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack direction='column' spacing={1}>
            <FieldValue label='ID' isEmpty={TypeHelper.isEmpty(teslaConnection.id)}>
              {teslaConnection.id}
            </FieldValue>

            <FieldValue label='User' isEmpty={TypeHelper.isEmpty(teslaConnection.userId)}>
              <UserInline entityId={teslaConnection.userId} entity={undefined} />
            </FieldValue>

            <FieldValue label='User email' isEmpty={TypeHelper.isEmpty(teslaConnection.userEmail)}>
              {teslaConnection.userEmail}
            </FieldValue>

            <FieldValue label='Vehicles #' isEmpty={TypeHelper.isEmpty(teslaConnection.vehicles)}>
              {teslaConnection.vehicles?.length}
            </FieldValue>

            <Stack direction='column' spacing={1}>
              <AppTypography variant='h4'>Auth tokens</AppTypography>

              <FieldValue label='Type' isEmpty={TypeHelper.isEmpty(teslaConnection.tokens?.type)}>
                <InlineApiEnumValue
                  type='TeslaAuthTokenType'
                  value={teslaConnection.tokens?.type}
                />
              </FieldValue>

              <FieldValue
                label='Refreshed at'
                isEmpty={TypeHelper.isEmpty(teslaConnection.tokens?.refreshedAt)}
              >
                <Datetime datetime={teslaConnection.tokens?.refreshedAt} withDurationFromNow />
              </FieldValue>

              <FieldValue
                label='Next refresh after'
                isEmpty={TypeHelper.isEmpty(teslaConnection.tokens?.refreshAccessTokenAfter)}
              >
                <Datetime
                  datetime={teslaConnection.tokens?.refreshAccessTokenAfter}
                  withDurationFromNow
                />
              </FieldValue>
            </Stack>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

import { FormGroup, MenuItem, Select, SelectProps } from "@mui/material";
import { useEffect, useState } from "react";

import {
  VehicleBodyType,
  VehicleType,
  VehicleVisualModelDto,
  VehicleVisualModelType,
} from "@/core/api/generated";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

interface VehicleBodyTypeSelectProps {
  /** Id that is currently selected. */
  visualModelId?: string | null;

  // filters
  filters?: {
    type?: VehicleVisualModelType | null;
    vehicleType?: VehicleType;
    bodyType?: VehicleBodyType | null;
    vehicleId?: string | null;
  };

  // other params
  label?: string;
  disabled?: boolean;
  selectProps?: SelectProps;
  onChange?: (newValue?: VehicleVisualModelDto | null) => void;
}

/** Loads and selects latest or default visual model. User can't edit this input. */
export default function VehicleVisualModelLatestOrDefaultSelect({
  visualModelId,
  filters,
  label,
  disabled,
  selectProps,
  onChange,
}: VehicleBodyTypeSelectProps) {
  const defaultVisualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet,
    {
      type: filters?.type || undefined,
      vehicleType: filters?.vehicleType || undefined,
      bodyType: filters?.bodyType || undefined,
      vehicleId: filters?.vehicleId || undefined,
    },
    {
      deps: [visualModelId],
      skip: !!visualModelId,
    },
  );
  const defaultVisualModel = defaultVisualModelRequest?.data;

  const exactVisualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: visualModelId!,
    },
    {
      deps: [visualModelId],
      skip: !visualModelId,
    },
  );
  const exactVisualModel = exactVisualModelRequest?.data;

  useEffect(() => {
    if (visualModelId) {
      onChange && onChange(exactVisualModel);
    } else {
      onChange && onChange(defaultVisualModel);
    }
  }, [defaultVisualModel, exactVisualModel]);

  const model = visualModelId ? exactVisualModel : defaultVisualModel;

  return (
    <FormGroup>
      <Select
        displayEmpty={false}
        fullWidth
        disabled={disabled}
        label={label || "Vehicle visual model"}
        value={model?.id || ""}
        // onChange={_onChange}
        {...selectProps}
      >
        <MenuItem value=''>
          <em>None</em>
        </MenuItem>
        <MenuItem value={model?.id || ""}>
          {model?.name}, v{model?.version}
        </MenuItem>
      </Select>
    </FormGroup>
  );
}

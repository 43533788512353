import { Dialog, DialogContent, DialogProps } from "@mui/material";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import ContractUpdateSpotInfo, { ContractUpdateSpotInfoProps } from "./ContractUpdateSpotInfo";

export interface OwnProps {
  subProps: ContractUpdateSpotInfoProps;
}

type Props = OwnProps & DialogProps;

export default function ContractUpdateSpotInfoModal({ subProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Update{" "}
        {(subProps.spotInfoType === "CheckOut" ? "check-out" : undefined) ||
          (subProps.spotInfoType === "CheckIn" ? "check-in" : undefined) ||
          "????"}{" "}
        info
      </AppModalTitle>
      <DialogContent>
        <ContractUpdateSpotInfo {...subProps} />
      </DialogContent>
    </Dialog>
  );
}

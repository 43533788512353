import { Stack, TypographyProps } from "@mui/material";
import { useHistory } from "react-router";

import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { StringHelper } from "@/common/helpers/string";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { DataUpdatesHubClientMethodName, EntityType, PartyDto } from "@/core/api/generated";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import MembersTabContent from "./Tabs/MembersTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";

export enum PartyViewPageTabs {
  Overview = "Overview",
  Members = "Members",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<PartyDto> {
  partyId: string | null | undefined;
  party: PartyDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function PartyView({
  partyId,
  party,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.Party),
      viewVariant: displayProps?.viewVariant,
    },
  });

  const partyRequest = useApiRequest(
    apiClient.partiesApi.apiV1PartiesPartyIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      partyId: partyId!,
    },
    {
      deps: [partyId],
      skip: !partyId || !!party,
    },
  );
  party = party || partyRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Party, partyId || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      partyRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        party && {
          idValue: party.id!,
          newTitle: party.name || party.type || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.Party}
      entityId={partyId}
      entity={party}
      entityRequest={partyRequest}
    >
      <Stack spacing={2}>
        {displayProps.header && (
          <DetailedViewPageHeader
            image={undefined}
            title={`Party ${StringHelper.joinIntoString([party?.name], " / ", {
              skipEmpty: true,
            })} `}
            titleProps={{
              to: headerProps?.withLink ? ROUTE_PATH.PARTY_VIEW(party?.id) : undefined,
              typographyProps: headerProps?.typographyProps,
            }}
            primaryActions={undefined}
            secondaryActions={undefined}
          />
        )}

        <PageTabs
          tabIdsDefinition={PartyViewPageTabs}
          defaultTabId={PartyViewPageTabs.Overview}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: PartyViewPageTabs.Overview, isHideable: false },
            { label: "Members", value: PartyViewPageTabs.Members },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            party && (
              <>
                {activeTab === PartyViewPageTabs.Overview && <OverviewTabContent party={party} />}
                {activeTab === PartyViewPageTabs.Members && <MembersTabContent party={party} />}
              </>
            )
          }
        </PageTabs>
      </Stack>
    </BaseEntityView>
  );
}

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AppIcon from "@/common/components/Icons/AppIcon";
import RouteCategoryIndex from "@/common/components/Route/RouteCategoryIndex";
import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission } from "@/core/api/generated";

export default function TenantToTenantIndexPage() {
  return (
    <ViewPageLayout header={<SimpleViewPageHeader title='Products' />}>
      <RouteCategoryIndex
        direction='row'
        childrenLinks={[
          {
            icon: <AppIcon of='tenantConnection' />,
            title: "Company connections",
            to: ROUTE_PATH.TENANT_CONNECTIONS_INDEX,
            permissions: [AppPermission.TenantConnectionRead],
          },
          {
            icon: <AppIcon of='tenantRequest' />,
            title: "Company requests",
            to: ROUTE_PATH.TENANT_REQUESTS,
            permissions: [AppPermission.TenantRequestRead],
          },
          {
            icon: <AppIcon of='dataGrant' />,
            title: "Data grants",
            to: ROUTE_PATH.DATA_GRANTS(),
            permissions: [AppPermission.DataGrantRead],
          },
        ]}
      />
    </ViewPageLayout>
  );
}

import { ValidationProblemDetails } from "@/core/api/generated";
import _ from "lodash";
import { ValidationProblemDetailsWithExtensions } from "../ts/problemDetails";

export class ProblemDetailsHelper {
  public static getWithExtensions(
    problemDetails: ValidationProblemDetails,
  ): ValidationProblemDetailsWithExtensions {
    return problemDetails as ValidationProblemDetailsWithExtensions;
  }
}

import InlineCode from "@/common/components/Code/InlineCode";
import { Box, FormHelperText, Pagination, Stack, Typography } from "@mui/material";

export default function DevPagination() {
  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Pagination
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>MUI Pagination</InlineCode>
      </Typography>
      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={1}>
          <Stack spacing={2}>
            <Pagination count={10} />
            <Pagination count={10} color='primary' />
            <Pagination count={10} color='secondary' />
            <Pagination count={10} disabled />
            <Typography component='div' variant='h5' sx={{ my: 2 }}>
              outlined
            </Typography>
            <Pagination count={10} variant='outlined' />
            <Pagination count={10} variant='outlined' color='primary' />
            <Pagination count={10} variant='outlined' color='secondary' />
            <Pagination count={10} variant='outlined' disabled />
            <Typography component='div' variant='h5' sx={{ my: 2 }}>
              rounded
            </Typography>
            <Pagination count={10} shape='rounded' />
            <Pagination count={10} variant='outlined' shape='rounded' />
            <Typography component='div' variant='h5' sx={{ my: 2 }}>
              sized
            </Typography>
            <Pagination count={10} size='small' />
            <Pagination count={10} />
            <Pagination count={10} size='large' />
            <Typography component='div' variant='h5' sx={{ my: 2 }}>
              buttons
            </Typography>
            <Pagination count={10} showFirstButton showLastButton />
            <Pagination count={10} hidePrevButton hideNextButton />
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1}></Stack>
        <Stack direction='row' spacing={1}></Stack>
      </Stack>
    </Box>
  );
}

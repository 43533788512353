import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { enumService } from "@/common/services/enum";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { AssetSubscriptionDto, AssetSubscriptionSearchPaginatedDto } from "@/core/api/generated";
import { StringHelper } from "@/common/helpers/string";

export type AssetSubscriptionAutocompleteOption = BaseAutocompleteOption<AssetSubscriptionDto>;

export const getAssetSubscriptionOptionTitle = (data: AssetSubscriptionDto): string =>
  StringHelper.joinIntoString(
    [
      `${data.localNumber}${data.externalNumber ? ` / ${data.externalNumber}` : ""}`,
      data.plan?.name,
      `(${enumService.getEnumValueName("SubscriptionStatus", data.status)})`,
    ],
    ", ",
    {
      skipEmpty: true,
    },
  );

export const assetSubscriptionToOption = (
  data: AssetSubscriptionDto,
): AssetSubscriptionAutocompleteOption => ({
  id: data.id!,
  title: getAssetSubscriptionOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface AssetSubscriptionAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<AssetSubscriptionDto> {
  searchFilters?: Partial<Omit<AssetSubscriptionSearchPaginatedDto, "search" | "includeIds">>;
}

export default function AssetSubscriptionAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: AssetSubscriptionAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={assetSubscriptionToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          assetSubscriptionSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          assetSubscriptionSearchPaginatedDto: {
            ...params.assetSubscriptionSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Asset subscription'
      placeholder='Search for asset subscription...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='assetSubscription' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body2' color='secondary'></Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

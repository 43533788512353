import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import EntityCommentsChat from "@/App/MainAppView/Chats/components/EntityCommentsChat";
import { EntityType, VisualInspectionDto } from "@/core/api/generated";
import { Stack } from "@mui/material";

interface Props {
  visualInspection: VisualInspectionDto;
}

export default function CommentsTabContent({ visualInspection }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <PageTabHeader title={"Comments"} />
        <EntityCommentsChat
          entityId={visualInspection.id!}
          entityType={EntityType.VisualInspection}
        />
      </Stack>
    </PageTabContent>
  );
}

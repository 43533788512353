import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import BlockApiEnumValue from "@/common/components/Enum/BlockApiEnumValue";
import { authService } from "@/common/services/auth";
import { Box, Stack, Typography } from "@mui/material";

export default function MyPermissionsTabContent() {
  return (
    <PageTabContent>
      <Box sx={{ mb: 2 }}>
        <PageTabHeader title='My Permissions' />
      </Box>
      <Typography component='div' variant='body2' sx={{ mb: 2 }}>
        Permissions from all your roles.
      </Typography>
      <Stack direction='column' spacing={1}>
        {authService.userPermissions.map((perm, i) => (
          <Box key={i}>
            <BlockApiEnumValue
              type='AppPermission'
              value={perm}
              direction='row'
              withValue
              withName
              withDescription
            />
          </Box>
        ))}
      </Stack>
    </PageTabContent>
  );
}

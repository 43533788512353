import CircleIcon from "@mui/icons-material/Circle";
import { Box, Stack, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";
import AppTooltip from "../AppTooltip";

export interface StatusDisplayProps {
  title?: ReactNode | null;
  subtitle?: ReactNode | null;
  variant?: "default" | "compact";
  color?: string | ((theme: Theme) => string);
  size?: "small" | "medium" | "large" | number;
  sx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
}

export const StatusDisplay = ({
  sx,
  iconSx,
  variant = "default",
  title,
  subtitle,
  color,
  size = "small",
}: StatusDisplayProps) => {
  const getColor = (theme: Theme) => (_.isFunction(color) ? color(theme) : color);
  const iconSize = _.isString(size) ? size : `${size}px`;

  return (
    <>
      {variant === "default" && (
        <Box
          component='span'
          sx={{ display: "inline-flex", flexDirection: "center", alignItems: "center" }}
        >
          <CircleIcon
            sx={{ color: (theme) => getColor(theme), fontSize: iconSize, mr: 0.5, ...iconSx }}
          />
          <Stack sx={{ ...sx }}>
            <Typography>{title}</Typography>
            <Typography sx={{ color: (theme) => theme.palette.grey[400] }}>{subtitle}</Typography>
          </Stack>
        </Box>
      )}
      {variant === "compact" && (
        <AppTooltip title={title!} wrapperProps={{ component: "span" }}>
          <Box
            component='span'
            sx={{ display: "inline-flex", flexDirection: "center", alignItems: "center" }}
          >
            <CircleIcon sx={{ color: (theme) => getColor(theme), fontSize: iconSize, ...iconSx }} />
          </Box>
        </AppTooltip>
      )}
    </>
  );
};

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import VehicleVisualModelsPaginatedList from "@/common/components/Entity/VehicleVisualModel/ListView/VehicleVisualModelsPaginatedList";

export default function VehicleVisualModelsPage() {
  return (
    <ListPageLayout>
      <VehicleVisualModelsPaginatedList />
    </ListPageLayout>
  );
}

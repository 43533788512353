import { useAppThunkDispatch } from "@/common/hooks/redux";
import { useEffect } from "react";
import * as authSlice from "@/store/auth/slice";

/** Logs out if visited */
function LogoutHandler() {
  const thunkDispatch = useAppThunkDispatch();
  useEffect(() => {
    try {
      console.log("Logout...");
      thunkDispatch(authSlice.logout());
    } catch (error) {
      console.error("Logout error:", error);
    }
  }, []);

  return <div>Handling logout...</div>;
}

export default LogoutHandler;

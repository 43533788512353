import { TypeHelper } from "@/common/helpers/type";
import { CascadeActionInputDto, CascadeActionSpecDto, EntityType } from "@/core/api/generated";
import {
  Alert,
  Button,
  Checkbox,
  DialogContent,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback } from "react";
import InlineApiEnumValue from "../Enum/InlineApiEnumValue";
import AppModal, { AppModalProps } from "../Modals/AppModal";
import AppModalTitle from "../Modals/AppModalTitle";
import AppTypography from "../Text/AppTypography";

export interface RelatedEntityTypesInputModalProps extends Omit<AppModalProps, "children"> {
  spec?: CascadeActionSpecDto | null;
  relatedEntitiesTypes: EntityType[] | undefined;
  onRelatedTypesChange: (values: EntityType[] | undefined) => void;
}
export default function RelatedEntityTypesInputModal({
  spec,
  relatedEntitiesTypes,
  onRelatedTypesChange,
  ...modalProps
}: RelatedEntityTypesInputModalProps) {
  const handleChange = useCallback(
    (types: EntityType[] | undefined) => {
      onRelatedTypesChange && onRelatedTypesChange(types);
    },
    [onRelatedTypesChange],
  );

  if (!spec) {
    return <LinearProgress />;
  }

  return (
    <AppModal fullWidth keepMounted={false} {...modalProps}>
      <AppModalTitle
        title='Cascade action settings'
        withCloseIcon
        onCloseClicked={() => modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown")}
      />
      <DialogContent>
        <Stack spacing={2} justifyContent='center'>
          <Alert severity='info'>
            Select related entities to which the action should be applied.
          </Alert>

          {TypeHelper.isEmpty(spec?.relatedEntitiesTypes) && (
            <AppTypography variant='body2'>
              {`This type of entity doesn't have any related entities`}
            </AppTypography>
          )}
          {!TypeHelper.isEmpty(spec?.relatedEntitiesTypes) && (
            <Table sx={{ width: "60%" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "40%", p: 0 }}>
                    <Checkbox
                      checked={relatedEntitiesTypes?.length === spec?.relatedEntitiesTypes?.length}
                      onChange={(e) =>
                        handleChange(e.target.checked ? spec?.relatedEntitiesTypes || [] : [])
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ width: "60%", p: 0 }}>Entity type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {spec?.relatedEntitiesTypes?.map((rt, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ p: 0 }}>
                      <Checkbox
                        checked={relatedEntitiesTypes?.includes(rt)}
                        onChange={(e) =>
                          e.target.checked
                            ? handleChange([...(relatedEntitiesTypes || []), rt])
                            : handleChange(relatedEntitiesTypes?.filter((v) => v !== rt))
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ p: 0 }}>
                      <InlineApiEnumValue type='EntityType' value={rt} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          <Button variant='contained' onClick={() => modalProps.onClose && modalProps.onClose()}>
            Save
          </Button>
        </Stack>
      </DialogContent>
    </AppModal>
  );
}

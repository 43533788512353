import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import PartyView from "@/common/components/Entity/Party/View/PartyView";

export default function PartyViewPage() {
  const { partyId } = useParams<{ partyId?: string }>();

  return (
    <ViewPageLayout>
      <PartyView
        partyId={partyId}
        party={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </ViewPageLayout>
  );
}

import {
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TypographyProps,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";

import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import SplitDropdownButton from "@/common/components/Button/SplitDropdownButton";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { enumService } from "@/common/services/enum";
import { ValidationHelper } from "@/common/validation";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  TenantConnectionRequestDto,
  TenantConnectionRequestResponseType,
  TenantConnectionRequestStatus,
} from "@/core/api/generated";
import { enqueueSnackbar } from "notistack";
import ChatActivator from "../../Chat/ChatActivator";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";

export enum TenantConnectionRequestViewTabs {
  Overview = "Overview",
  GeneralHistory = "GeneralHistory",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<TenantConnectionRequestDto> {
  tenantConnectionRequestId: string | null | undefined;
  tenantConnectionRequest: TenantConnectionRequestDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
  onChanged?: (newValue: TenantConnectionRequestDto) => void;
}

export type TenantConnectionRequestViewProps = OwnProps;

export default function TenantConnectionRequestView({
  tenantConnectionRequestId,
  tenantConnectionRequest,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
  onChanged: onSave,
}: TenantConnectionRequestViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();

  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);

  const tenantConnectionRequestRequest = useApiRequest(
    apiClient.tenantConnectionRequestsApi
      .apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantConnectionRequestId: tenantConnectionRequestId!,
    },
    {
      deps: [tenantConnectionRequestId],
      skip: !tenantConnectionRequestId || !!tenantConnectionRequest,
    },
  );
  tenantConnectionRequest = tenantConnectionRequestRequest?.data || tenantConnectionRequest;

  const isSentByMe = currentTenant?.id === tenantConnectionRequest?.senderTenant?.id;
  const isReceivedByMe = currentTenant?.id === tenantConnectionRequest?.receiverTenant?.id;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        null,
        EntityType.TenantConnectionRequest,
        tenantConnectionRequest?.id || "",
      ),
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.TenantConnectionRequest,
        tenantConnectionRequest?.id || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      tenantConnectionRequestRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        tenantConnectionRequest && {
          idValue: tenantConnectionRequest.id!,
          newTitle: tenantConnectionRequest.globalNumber || "",
        }) ||
      undefined,
  });

  const handleRevokeConfirmed = useCallback(async () => {
    if (!tenantConnectionRequest) {
      return;
    }
    try {
      const response =
        await apiClient.tenantConnectionRequestsApi.apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            tenantConnectionRequestId: tenantConnectionRequest.id!,
          },
        );
      enqueueSnackbar("Request was successfully revoked.", { variant: "success" });
      setIsRevokeModalOpen(false);
      tenantConnectionRequestRequest.replaceData(response.data);
      onSave && onSave(response.data);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [tenantConnectionRequest]);

  const handleRespond = useCallback(
    async (responseType: TenantConnectionRequestResponseType) => {
      if (!tenantConnectionRequest) {
        return;
      }
      try {
        const response =
          await apiClient.tenantConnectionRequestsApi.apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost(
            {
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              tenantConnectionRequestId: tenantConnectionRequest.id!,
              tenantConnectionRequestRespondDto: {
                responseType: responseType,
              },
            },
          );
        enqueueSnackbar("Your response was successfully saved.", { variant: "success" });
        setIsRevokeModalOpen(false);
        tenantConnectionRequestRequest.replaceData(response.data);
        onSave && onSave(response.data);
      } catch (err) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        validation2.hasErrors &&
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      }
    },
    [tenantConnectionRequest],
  );

  if (tenantConnectionRequestRequest.isLoading) {
    return <LinearProgress />;
  }
  if (!tenantConnectionRequest && tenantConnectionRequestRequest.isEnded) {
    return <EntityNotFoundAlert />;
  }

  return (
    <BaseEntityView
      entityType={EntityType.TenantConnectionRequest}
      entityId={tenantConnectionRequestId}
      entity={tenantConnectionRequest}
      entityRequest={tenantConnectionRequestRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`Company connection request ${tenantConnectionRequest?.globalNumber}`}
              titleProps={{
                to: headerProps?.withLink
                  ? ROUTE_PATH.TENANT_CONNECTION_REQUEST_VIEW(tenantConnectionRequest?.id)
                  : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                <Stack direction='row' spacing={1}>
                  {/* Chat */}
                  {tenantConnectionRequest?.chat && (
                    <ChatActivator
                      chatType={tenantConnectionRequest.chat.type!}
                      chatId={tenantConnectionRequest.chat.id}
                      chatPlacement='popover'
                      to={undefined}
                      withActivityIndicator
                      tooltipTitle='Open chat'
                      activatorButtonProps={{
                        disableRipple: true,
                      }}
                    />
                  )}

                  {isReceivedByMe && !tenantConnectionRequest?.isResponded && (
                    <SplitDropdownButton
                      isSelectAndTriggerAction={false}
                      options={[
                        {
                          content: enumService.getEnumValueName(
                            "TenantConnectionRequestResponseType",
                            TenantConnectionRequestResponseType.Accept,
                          ),
                          buttonProps: { color: "success" },
                          onClick: () => handleRespond(TenantConnectionRequestResponseType.Accept),
                        },
                        {
                          content: enumService.getEnumValueName(
                            "TenantConnectionRequestResponseType",
                            TenantConnectionRequestResponseType.Decline,
                          ),
                          buttonProps: { color: "error" },
                          onClick: () => handleRespond(TenantConnectionRequestResponseType.Decline),
                        },
                      ]}
                    />
                  )}
                </Stack>
              }
              secondaryActions={
                tenantConnectionRequest &&
                displayProps.actions && (
                  <MenuWithTrigger
                    withAuthCloseOnClick
                    trigger={
                      <IconButton sx={{ ml: "auto" }}>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                  >
                    {isSentByMe &&
                      tenantConnectionRequest.status !== TenantConnectionRequestStatus.Revoked && (
                        <AuthorizedElement permissions={[AppPermission.TenantConnectionManage]}>
                          <MenuItem
                            onClick={() => {
                              setIsRevokeModalOpen(true);
                            }}
                          >
                            <ListItemIcon>
                              <AppIcon of='revoke' fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Revoke</ListItemText>
                          </MenuItem>
                        </AuthorizedElement>
                      )}

                    {isReceivedByMe && (
                      <AuthorizedElement permissions={[AppPermission.TenantConnectionManage]}>
                        <MenuItem
                          onClick={() => {
                            setIsRevokeModalOpen(true);
                          }}
                        >
                          <ListItemIcon>
                            <AppIcon of='revoke' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>Revoke</ListItemText>
                        </MenuItem>
                      </AuthorizedElement>
                    )}
                  </MenuWithTrigger>
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <PageTabs
            tabIdsDefinition={TenantConnectionRequestViewTabs}
            defaultTabId={TenantConnectionRequestViewTabs.Overview}
            tabs={[
              { label: "Overview", value: TenantConnectionRequestViewTabs.Overview },
              { label: "History", value: TenantConnectionRequestViewTabs.GeneralHistory },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              tenantConnectionRequest && (
                <>
                  {activeTab === TenantConnectionRequestViewTabs.Overview && (
                    <OverviewTabContent tenantConnectionRequest={tenantConnectionRequest} />
                  )}
                  {activeTab === TenantConnectionRequestViewTabs.GeneralHistory && (
                    <GeneralHistoryTabContent tenantConnectionRequest={tenantConnectionRequest} />
                  )}
                </>
              )
            }
          </PageTabs>

          {/* Revoke */}
          {tenantConnectionRequest && (
            <ConfirmationModal
              title='Revoke the company connection request?'
              body={
                <>
                  {`You're going to revoke the company connection request `}
                  <strong>{tenantConnectionRequest.globalNumber}</strong>.
                  {` This action can't be undone.`}
                </>
              }
              open={isRevokeModalOpen}
              onClose={() => setIsRevokeModalOpen(false)}
              onCancel={() => setIsRevokeModalOpen(false)}
              onConfirm={handleRevokeConfirmed}
            />
          )}
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}

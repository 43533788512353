import { Alert, Box, LinearProgress, Stack, Typography, TypographyProps } from "@mui/material";
import _ from "lodash";
import { ReactNode, useMemo } from "react";

import { ROUTE_PATH } from "@/common/constants/routing";
import { apiClient } from "@/core/api/ApiClient";

import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { usePageTabs } from "@/common/hooks/layout/usePageTabs";
import { EntityType, VehicleDamageGetPaginatedDto, VehicleDamageState } from "@/core/api/generated";
import DamagePointsByVisualModelViewer from "../../Images/DamagePointsByVisualModelViewer";
import AppLink from "../../Link/AppLink";
import VehicleDamageList from "./VehicleDamageList";

export enum VehicleDamagePaginatedListTabs {
  All = "All",
  Actual = "Actual",
  Repaired = "Repaired",
}

const defaultHeaderProps = {
  withLink: false,
  title: undefined as ReactNode | undefined,
  typographyProps: undefined as TypographyProps<"div"> | undefined,
};

const defaultDisplayProps = {
  header: true,
  filters: true,
  tabs: true,
  pagination: true,
  vehicleDamagesWithModels: true,
  vehicleColumn: true,
};

type OwnProps = {
  vehicleId?: string;
  defaultValues?: {
    activeTab?: VehicleDamagePaginatedListTabs;
    offset?: number;
    limit?: number;
  };
  headerProps?: Partial<typeof defaultHeaderProps>;
  displayProps?: Partial<typeof defaultDisplayProps>;
};

type Props = OwnProps;

export default function VehicleDamagePaginatedList({
  vehicleId,
  defaultValues,
  headerProps = defaultHeaderProps,
  displayProps = defaultDisplayProps,
}: Props) {
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const pageTabs = usePageTabs<VehicleDamagePaginatedListTabs>({
    tabIdsDefinition: VehicleDamagePaginatedListTabs,
    defaultTabId: defaultValues?.activeTab || VehicleDamagePaginatedListTabs.All,
    tabs: [],
  });
  const commonRequestParams = useCommonRequestParams<VehicleDamageGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.VehicleDamage,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
  });

  const stateComputed = useMemo(
    () =>
      pageTabs.activeTabId === VehicleDamagePaginatedListTabs.All
        ? undefined
        : (pageTabs.activeTabId as VehicleDamageState),
    [pageTabs.activeTabId],
  );

  const paginatedVehicleDamagesRequest = useApiRequest(
    apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicleId || "",
      vehicleDamageGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
        state: stateComputed,
      },
    },
    {
      deps: [vehicleId, stateComputed, ...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
      skip: !vehicleId,
    },
  );
  const paginatedVehicleDamages = paginatedVehicleDamagesRequest?.data;

  const vehicleRequest = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicleId || "",
    },
    {
      deps: [vehicleId],
      skip: !vehicleId,
    },
  );
  const vehicle = vehicleRequest.data;
  const isAnyDamages = paginatedVehicleDamages?.items?.length !== 0;
  const groupedByVisualModel = _.groupBy(paginatedVehicleDamages?.items, (d) => {
    return d.visualModel?.id;
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumbs:
      (vehicle && [
        {
          idValue: vehicle?.id || "",
          newTitle: vehicle?.localNumber || "",
        },
      ]) ||
      undefined,
  });

  return (
    <Stack spacing={2}>
      {displayProps?.header && (
        <Box>
          <AppLink
            enabled={headerProps?.withLink}
            to={ROUTE_PATH.VEHICLE_DAMAGES({ vehicleId: vehicleId })}
          >
            {headerProps?.title || (
              <Typography component='div' variant='h6' {...headerProps?.typographyProps}>
                Vehicle damages
              </Typography>
            )}
          </AppLink>
        </Box>
      )}

      {!isAnyDamages && (
        <Alert severity='info' title='Vehicle has no damages.'>
          Vehicle has no damages.
        </Alert>
      )}

      {/* Visual models */}
      {displayProps?.vehicleDamagesWithModels && (
        <>
          {isAnyDamages &&
            Object.keys(groupedByVisualModel).map((visualModelId) => {
              return (
                <DamagePointsByVisualModelViewer
                  key={visualModelId}
                  visualModelId={visualModelId}
                  damages={groupedByVisualModel[visualModelId]}
                />
              );
            })}

          {/* Show empty visual model */}
          {!isAnyDamages && (
            <DamagePointsByVisualModelViewer
              visualModelId={undefined}
              visualModelLatestOrDefaultFor={{
                vehicleId: vehicleId,
              }}
              damages={[]}
            />
          )}
        </>
      )}

      <Box
        sx={{
          width: "100%",
          flex: 1,
          backgroundColor: "background.default",
          borderRadius: (theme) => `${theme.shape.borderRadius}px`,
        }}
      >
        {paginatedVehicleDamagesRequest.isLoading && <LinearProgress sx={{ my: 1 }} />}

        <VehicleDamageList
          vehicleDamages={paginatedVehicleDamages?.items || []}
          dataTabularProps={{
            isLoading: paginatedVehicleDamagesRequest.isLoading,
            statePersistence: commonRequestParams.dataTabularProps.statePersistence,
            pagination: commonRequestParams.dataTabularProps.pagination,
            sort: commonRequestParams.dataTabularProps.sort,
            quickFilter: commonRequestParams.dataTabularProps.quickFilter,
            filters: commonRequestParams.dataTabularProps.filters,
            tabs: displayProps?.tabs
              ? {
                  value: pageTabs.activeTabId,
                  onChange: (e, val) => pageTabs.setActiveTab(val),
                  tabs: [
                    {
                      label: <Box>{VehicleDamagePaginatedListTabs.All}</Box>,
                      value: VehicleDamagePaginatedListTabs.All,
                    },
                    {
                      label: <Box>{VehicleDamagePaginatedListTabs.Actual}</Box>,
                      value: VehicleDamagePaginatedListTabs.Actual,
                    },
                    {
                      label: <Box>{VehicleDamagePaginatedListTabs.Repaired}</Box>,
                      value: VehicleDamagePaginatedListTabs.Repaired,
                    },
                  ],
                }
              : undefined,
          }}
        />
      </Box>
    </Stack>
  );
}

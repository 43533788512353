import { createBrowserHistory } from "history";
import { AnyAction, applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware, { ThunkAction, ThunkDispatch } from "redux-thunk";

import { tenantService } from "@/common/services/tenant";

import { errorHandlingMiddleware } from "./appCommon/errorHandlingMiddleware";
import { appCommonMiddleware } from "./appCommon/middleware";
import { authMiddleware } from "./auth/middlewares/auth";
import { communicationMiddleware } from "./communication/middleware";
import { paymentsMiddleware } from "./management/payments/middleware";
import { subscriptionPlansMiddleware } from "./management/subscriptionPlans/middleware";
import { tenantAccountMiddleware } from "./management/tenantAccount/middleware";
import { tenantInvitesMiddleware } from "./management/tenantInvites/middleware";
import { usersMiddleware } from "./management/users/middleware";
import createRootReducer from "./rootReducer";
import { tenantsMiddleware } from "./tenants/middleware";
import { profileMiddleware } from "./profile/middleware";

const tenantInfo = tenantService.resolveTenant("basepath");
const basename = tenantInfo?.identifier || tenantService.defaultTenantIdentifier;

export const history = createBrowserHistory({
  basename: tenantService.strategy === "basepath" ? "/" + basename : undefined,
});

export function configureStore(preloadedState?: any, _history = history) {
  const middlewares = [
    errorHandlingMiddleware,
    thunkMiddleware,
    authMiddleware,
    appCommonMiddleware,
    tenantsMiddleware,
    profileMiddleware,
    subscriptionPlansMiddleware,
    paymentsMiddleware,
    usersMiddleware,
    tenantInvitesMiddleware,
    tenantAccountMiddleware,
    communicationMiddleware,
  ];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const rootReducer = createRootReducer(_history);
  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}

export const store = configureStore({}, history);
export default store;

// export type AppRootStateType = ReturnType<typeof store.getState>;
export type AppRootStateType = ReturnType<ReturnType<typeof createRootReducer>>; // avoid circular type reference between the middleware and store definitions

export type AppDispatchType = typeof store.dispatch;
export type AppThunkDispatchType = ThunkDispatch<AppRootStateType, any, AnyAction>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppRootStateType,
  unknown,
  AnyAction
>;

import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  AccessoryDto,
  AccessoryFullReferenceDto,
  AccessoryReferenceDto,
} from "@/core/api/generated";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getAccessoryOptionTitle } from "./AccessoryAutocomplete";

export interface AccessoryInlineProps extends BaseEntityInlineInheritableProps {
  entity: AccessoryDto | AccessoryReferenceDto | AccessoryFullReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function AccessoryInline({ entity, entityId, ...otherProps }: AccessoryInlineProps) {
  const request = useApiRequest(
    apiClient.accessoriesApi.apiV1AccessoriesAccessoryIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      accessoryId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as AccessoryDto | AccessoryFullReferenceDto;
  const ref = entity as AccessoryReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='accessory' inText />}
      content={<>{full && getAccessoryOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Accessory"}
    />
  );
}

import { Box } from "@mui/material";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { VehicleGenerationDto, VehicleGenerationReferenceDto } from "@/core/api/generated";
import { ReactNode } from "react";
import AppTooltip from "../../../AppTooltip";

interface Props {
  generation: VehicleGenerationDto | VehicleGenerationReferenceDto | null | undefined;
  withIcon?: boolean;
  withLink?: boolean;
  withTooltip?: boolean;
  tooltipTitle?: ReactNode;
}

export default function VehicleGenerationInline({
  generation,
  withIcon = true,
  withLink = true,
  withTooltip = false,
  tooltipTitle,
}: Props) {
  if (!generation) {
    return null;
  }

  const full = generation as VehicleGenerationDto;
  const ref = generation as VehicleGenerationReferenceDto;

  return (
    <AppTooltip enabled={withTooltip} title={tooltipTitle || "Vehicle generation"}>
      <AppLink
        // enabled={withLink}
        // to={
        //   (withLink &&
        //     ROUTE_PATH.VEHICLE_VISUAL_MODEL_VIEW({ vehicleVisualGenerationId: visualGeneration.id! })) ||
        //   ""
        // }
        enabled={false}
        to=''
        sx={{
          display: "inline-flex",
          alignItems: "center",
          fontSize: "inherit",
          underline: "none",
        }}
      >
        {withIcon && <AppIcon of='vehicleGeneration' inText sx={{ mr: 1 }} />}

        <Box component='span'>{generation.name}</Box>
      </AppLink>
    </AppTooltip>
  );
}

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { AccessoryDto, EntityType } from "@/core/api/generated";
import GeneralHistoryPaginatedList from "../../../GeneralHistory/ListView/GeneralHistoryPaginatedList";

interface Props {
  accessory: AccessoryDto;
}

export default function GeneralHistoryTabContent({ accessory }: Props) {
  return (
    <PageTabContent>
      <GeneralHistoryPaginatedList
        defaultValues={{
          subjectEntityType: EntityType.Accessory,
          subjectEntityId: accessory.id,
        }}
        displayProps={{
          viewVariant: ViewLayoutVariant.Tab,
        }}
      />
    </PageTabContent>
  );
}

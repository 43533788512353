import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import AssetSubscriptionReallocateAsset, {
  AssetSubscriptionUpdateAssetProps,
} from "./AssetSubscriptionReallocateAsset";

export interface OwnProps {
  updateProps: AssetSubscriptionUpdateAssetProps;
}

type Props = OwnProps & DialogProps;

export default function AssetSubscriptionReallocateAssetModal({
  updateProps,
  ...dialogProps
}: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Reallocate asset for the asset subscription {updateProps.assetSubscription.localNumber}
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <AssetSubscriptionReallocateAsset {...updateProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

import { Stack, Tab, Tabs, Typography } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import {
  AssetSubscriptionPlanDto,
  AssetSubscriptionPlanProductLocationDto,
} from "@/core/api/generated";

import { pageTabHeaderTypographyProps } from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import FoldableBlock from "@/common/components/Display/FoldableBlock";
import PoolView from "@/common/components/Entity/Pool/View/PoolView";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import _ from "lodash";
import { useState } from "react";
import ProductLocationInline from "../../../ProductLocation/ProductLocationInline";

interface Props {
  assetSubscriptionPlan: AssetSubscriptionPlanDto;
}

export default function PoolsTabContent({ assetSubscriptionPlan }: Props) {
  const [selectedPlanProductLocation, setSelectedPlanProductLocation] = useState<
    AssetSubscriptionPlanProductLocationDto | undefined
  >(_.first(assetSubscriptionPlan.productLocations));

  return (
    <PageTabContent>
      <ViewLayout displayProps={{ viewVariant: ViewLayoutVariant.Tab }}>
        <Stack direction='column' spacing={2}>
          <FieldValue
            label='Ensure pool belongs to a single plan'
            isEmpty={_.isNil(assetSubscriptionPlan?.settings2?.isEnsurePoolBelongToSinglePlan)}
          >
            <BooleanValue value={assetSubscriptionPlan.settings2?.isEnsurePoolBelongToSinglePlan} />
          </FieldValue>

          {/* Main pool */}
          <FoldableBlock
            defaultIsFolded
            trigger={{
              label: (
                <Typography component='span' variant='h6'>
                  Primary pool
                </Typography>
              ),
            }}
          >
            <Stack spacing={2}>
              {!assetSubscriptionPlan.pool?.id && <NoDataAlert />}

              {assetSubscriptionPlan.pool?.id && (
                <PoolView
                  poolId={assetSubscriptionPlan.pool?.id}
                  pool={undefined}
                  displayProps={{
                    breadcrumbs: false,
                    header: true,
                    actions: true,
                  }}
                  headerProps={{
                    withLink: true,
                    typographyProps: pageTabHeaderTypographyProps.title,
                  }}
                />
              )}
            </Stack>
          </FoldableBlock>

          {/* Secondary pools */}
          <FoldableBlock
            defaultIsFolded
            trigger={{
              label: (
                <Typography component='span' variant='h6'>
                  Secondary pools (per sales location)
                </Typography>
              ),
            }}
          >
            <Stack spacing={2}>
              {_.isEmpty(assetSubscriptionPlan.productLocations) && <NoDataAlert />}

              {!_.isEmpty(assetSubscriptionPlan.productLocations) && (
                <>
                  <Tabs
                    value={selectedPlanProductLocation}
                    onChange={(e, newValue) => setSelectedPlanProductLocation(newValue)}
                  >
                    {assetSubscriptionPlan.productLocations?.map((x, i) => (
                      <Tab
                        key={i}
                        label={
                          <ProductLocationInline
                            entity={undefined}
                            entityId={x.productLocation?.id}
                            withIcon={false}
                          />
                        }
                        value={x}
                      />
                    ))}
                  </Tabs>

                  {selectedPlanProductLocation && !selectedPlanProductLocation.pool && (
                    <NoDataAlert />
                  )}

                  {selectedPlanProductLocation && (
                    <PoolView
                      poolId={selectedPlanProductLocation.pool?.id}
                      pool={undefined}
                      displayProps={{
                        breadcrumbs: false,
                        header: true,
                        actions: true,
                      }}
                      headerProps={{
                        withLink: true,
                        typographyProps: pageTabHeaderTypographyProps.title,
                      }}
                    />
                  )}
                </>
              )}
            </Stack>
          </FoldableBlock>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

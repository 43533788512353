import InlineCode from "@/common/components/Code/InlineCode";
import { MiscHelper } from "@/common/helpers/misc";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

export default function DevAutocomplete() {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isConfirmation1ModalOpen, setIsConfirmation1ModalOpen] = useState(false);

  const successfulActionWithDelay = async () => {
    await MiscHelper.wait(2000);
    enqueueSnackbar("Action completed.", { variant: "success" });
    setIsConfirmation1ModalOpen(false);
  };

  const top3Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
  ];
  const top5Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "The Dark Knight", year: 2008 },
    { title: "12 Angry Men", year: 1957 },
  ];

  interface CountryType {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
  }

  // From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
  const countries: readonly CountryType[] = [
    { code: "AD", label: "Andorra", phone: "376" },
    {
      code: "AE",
      label: "United Arab Emirates",
      phone: "971",
    },
    { code: "AF", label: "Afghanistan", phone: "93" },
    {
      code: "AG",
      label: "Antigua and Barbuda",
      phone: "1-268",
    },
    { code: "AI", label: "Anguilla", phone: "1-264" },
    { code: "AL", label: "Albania", phone: "355" },
    { code: "AM", label: "Armenia", phone: "374" },
    { code: "AO", label: "Angola", phone: "244" },
    { code: "AQ", label: "Antarctica", phone: "672" },
    { code: "AR", label: "Argentina", phone: "54" },
    { code: "AS", label: "American Samoa", phone: "1-684" },
    { code: "AT", label: "Austria", phone: "43" },
  ];

  interface FilmOptionType {
    title: string;
    year: number;
  }

  const defaultProps = {
    options: top5Films,
    getOptionLabel: (option: FilmOptionType) => option.title,
  };

  const flatProps = {
    options: top5Films.map((option) => option.title),
  };
  const [value, setValue] = React.useState<FilmOptionType | null>(null);

  const options = top5Films.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Autocomplete
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>MUI Autocomplete</InlineCode>
      </Typography>

      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={1}>
          <Autocomplete
            disablePortal
            id='combo-box-demo'
            options={top3Films}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label='Movie' />}
          />
        </Stack>
        <Stack direction='row' spacing={1}>
          <Stack spacing={1} sx={{ width: 300 }}>
            <Autocomplete
              {...defaultProps}
              id='disable-close-on-select'
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField {...params} label='disableCloseOnSelect' variant='standard' />
              )}
            />
            <Autocomplete
              {...defaultProps}
              id='clear-on-escape'
              clearOnEscape
              renderInput={(params) => (
                <TextField {...params} label='clearOnEscape' variant='standard' />
              )}
            />
            <Autocomplete
              {...defaultProps}
              id='disable-clearable'
              disableClearable
              renderInput={(params) => (
                <TextField {...params} label='disableClearable' variant='standard' />
              )}
            />
            <Autocomplete
              {...defaultProps}
              id='include-input-in-list'
              includeInputInList
              renderInput={(params) => (
                <TextField {...params} label='includeInputInList' variant='standard' />
              )}
            />
            <Autocomplete
              {...flatProps}
              id='flat-demo'
              renderInput={(params) => <TextField {...params} label='flat' variant='standard' />}
            />
            <Autocomplete
              {...defaultProps}
              id='controlled-demo'
              value={value}
              onChange={(event: any, newValue: FilmOptionType | null) => {
                setValue(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label='controlled' variant='standard' />
              )}
            />
            <Autocomplete
              {...defaultProps}
              id='auto-complete'
              autoComplete
              includeInputInList
              renderInput={(params) => (
                <TextField {...params} label='autoComplete' variant='standard' />
              )}
            />
            <Autocomplete
              {...defaultProps}
              id='disable-list-wrap'
              disableListWrap
              renderInput={(params) => (
                <TextField {...params} label='disableListWrap' variant='standard' />
              )}
            />
            <Autocomplete
              {...defaultProps}
              id='open-on-focus'
              openOnFocus
              renderInput={(params) => (
                <TextField {...params} label='openOnFocus' variant='standard' />
              )}
            />
            <Autocomplete
              {...defaultProps}
              id='auto-highlight'
              autoHighlight
              renderInput={(params) => (
                <TextField {...params} label='autoHighlight' variant='standard' />
              )}
            />
            <Autocomplete
              {...defaultProps}
              id='auto-select'
              autoSelect
              renderInput={(params) => (
                <TextField {...params} label='autoSelect' variant='standard' />
              )}
            />
            <Autocomplete
              {...defaultProps}
              id='disabled'
              disabled
              renderInput={(params) => (
                <TextField {...params} label='disabled' variant='standard' />
              )}
            />
            <Autocomplete
              {...defaultProps}
              id='disable-portal'
              disablePortal
              renderInput={(params) => (
                <TextField {...params} label='disablePortal' variant='standard' />
              )}
            />
            <Autocomplete
              {...defaultProps}
              id='blur-on-select'
              blurOnSelect
              renderInput={(params) => (
                <TextField {...params} label='blurOnSelect' variant='standard' />
              )}
            />
            <Autocomplete
              {...defaultProps}
              id='clear-on-blur'
              clearOnBlur
              renderInput={(params) => (
                <TextField {...params} label='clearOnBlur' variant='standard' />
              )}
            />
            <Autocomplete
              {...defaultProps}
              id='select-on-focus'
              selectOnFocus
              renderInput={(params) => (
                <TextField {...params} label='selectOnFocus' variant='standard' />
              )}
            />
            <Autocomplete
              {...flatProps}
              id='readOnly'
              readOnly
              defaultValue={flatProps.options[13]}
              renderInput={(params) => (
                <TextField {...params} label='readOnly' variant='standard' />
              )}
            />
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Autocomplete
            id='country-select-demo'
            sx={{ width: 300 }}
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box component='li' sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading='lazy'
                  width='20'
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=''
                />
                {option.label} ({option.code}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Choose a country'
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Stack>
        <Stack direction='row' spacing={1}>
          <Autocomplete
            id='grouped-demo'
            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.title}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label='With categories' />}
          />
        </Stack>
        <Stack direction='row' spacing={1}>
          <Stack spacing={2} sx={{ width: 300 }}>
            <Autocomplete
              freeSolo
              disableClearable
              options={top5Films.map((option) => option.title)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Search input'
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Stack spacing={3} sx={{ width: 500 }}>
            <Autocomplete
              multiple
              id='tags-standard'
              options={top5Films}
              getOptionLabel={(option) => option.title}
              defaultValue={[top5Films[1]]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  label='Multiple values'
                  placeholder='Favorites'
                />
              )}
            />
            <Autocomplete
              multiple
              id='tags-outlined'
              options={top5Films}
              getOptionLabel={(option) => option.title}
              defaultValue={[top5Films[1]]}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label='filterSelectedOptions' placeholder='Favorites' />
              )}
            />
            <Autocomplete
              multiple
              id='tags-readOnly'
              options={top5Films.map((option) => option.title)}
              defaultValue={[top5Films[1].title, top5Films[2].title]}
              readOnly
              renderInput={(params) => (
                <TextField {...params} label='readOnly' placeholder='Favorites' />
              )}
            />
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Stack spacing={2} sx={{ width: 500 }}>
            <Autocomplete
              id='size-small-standard'
              size='small'
              options={top5Films}
              getOptionLabel={(option) => option.title}
              defaultValue={top5Films[1]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  label='Size small'
                  placeholder='Favorites'
                />
              )}
            />
            <Autocomplete
              multiple
              id='size-small-standard-multi'
              size='small'
              options={top5Films}
              getOptionLabel={(option) => option.title}
              defaultValue={[top5Films[1]]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  label='Size small'
                  placeholder='Favorites'
                />
              )}
            />
            <Autocomplete
              id='size-small-outlined'
              size='small'
              options={top5Films}
              getOptionLabel={(option) => option.title}
              defaultValue={top5Films[1]}
              renderInput={(params) => (
                <TextField {...params} label='Size small' placeholder='Favorites' />
              )}
            />
            <Autocomplete
              multiple
              id='size-small-outlined-multi'
              size='small'
              options={top5Films}
              getOptionLabel={(option) => option.title}
              defaultValue={[top5Films[1]]}
              renderInput={(params) => (
                <TextField {...params} label='Size small' placeholder='Favorites' />
              )}
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

import { TypedEventEmitter } from "@/common/eventEmmiters/typedEventEmitter";
import { FilterDefinition } from "@/common/filters/filterDefinition";
import { CommonRequestParamsHelper } from "@/common/helpers/commonRequestParams";
import { PaginationHelper } from "@/common/helpers/pagination";
import { SortHelper } from "@/common/helpers/sort";
import { SortDefinition } from "@/common/sorting/sortDefinition";
import {
  CommonRequestParamsState,
  CommonRequestParamsStatePersistenceProps,
  CommonRequestParamsStatePersistenceStrategy,
} from "@/common/ts/commonRequestParams";
import {
  TabularFilterProps,
  TabularPaginationProps,
  TabularQuickFilterProps,
  TabularRefetchProps,
  TabularSortProps,
  TabularStatePersistenceProps,
} from "@/common/ts/dataTabular";
import { FilterDefinitionDto, PaginationInfoDto, SortDefinitionDto } from "@/core/api/generated";
import _ from "lodash";
import { DependencyList, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRefWithFactory } from "../ref/useRefWithFactory";

type BaseCommonRequestParams = object;

const defaultStatePersistenceProps: Partial<CommonRequestParamsStatePersistenceProps> = {
  strategy: CommonRequestParamsStatePersistenceStrategy.LocalStorage,
};

/** @template TParams custom request params object.  */
interface Props<TParams extends BaseCommonRequestParams> {
  /** NB: values from the persisted state are prioritized over default values. */
  defaultValues?: {
    offset?: number;
    limit?: number;
    sortDefinition?: SortDefinition;
    filterDefinition?: FilterDefinition;
    params?: TParams | undefined;
  };
  /** State persistence is enabled if non-empty value passed. */
  statePersistence?: CommonRequestParamsStatePersistenceProps;

  /** Function to initialize params from filterDefinition */
  initParams?: (params: { filterDefinition: FilterDefinition | undefined }) => TParams;
}

export type RefetchEventsMap = {
  // list of supported events
  triggerRefetch: undefined;
  requestStarted: undefined;
  requestEnded: undefined;
  refetchStarted: undefined;
  refetchEnded: undefined;
};

class RefetchEventEmitter extends TypedEventEmitter<RefetchEventsMap> {}

export interface UseCommonRequestParamsHookResultRefetch {
  eventEmitter: RefetchEventEmitter;
  triggerRefetch: () => void;
}

export interface UseCommonRequestParamsHookResult<TParams extends BaseCommonRequestParams> {
  offset: number;
  limit: number;
  paginationInfo: PaginationInfoDto | undefined;
  sortDefinition: SortDefinition | undefined;
  sortDefinitionDto: SortDefinitionDto | undefined;
  search: string | undefined;
  params: TParams | undefined;
  filterDefinition: FilterDefinition | undefined;
  filterDefinitionDto: FilterDefinitionDto | undefined;
  deps: DependencyList;
  debouncedDeps: DependencyList;
  /** Props for quick integration with DataTabular. */
  dataTabularProps: {
    statePersistence: TabularStatePersistenceProps | undefined;
    pagination: TabularPaginationProps;
    sort: TabularSortProps;
    quickFilter: TabularQuickFilterProps;
    filters: TabularFilterProps;
    refetch: TabularRefetchProps;
  };
  /** `true` if set any of: `search`, `filterDefinition`. */
  isAnyFilters: boolean;
  refetch: UseCommonRequestParamsHookResultRefetch;
  setOffset: (newValue: number) => void;
  setLimit: (newValue: number) => void;
  setPaginationInfo: (newValue: PaginationInfoDto | undefined) => void;
  setSearch: (newValue: string | undefined) => void;
  setSortDefinition: (newValue: SortDefinition | undefined) => void;
  setFilterDefinition: (newValue: FilterDefinition | undefined) => void;
  setOneParam: <TKey extends keyof TParams>(key: TKey, newValue: TParams[TKey] | undefined) => void;
  setOneParam2: <TKey extends keyof TParams>(newValue: {
    [K in TKey]: TParams[K] | undefined;
  }) => void;
  setManyParams: (newValue: Partial<TParams>) => void;
  setAllParams: (newValue: TParams | undefined) => void;
}

/** Provides common query parameters for paginated requests. */
export function useCommonRequestParams<TParams extends BaseCommonRequestParams>({
  defaultValues,
  statePersistence,
  initParams,
}: Props<TParams> = {}): UseCommonRequestParamsHookResult<TParams> {
  const statePersistenceComputed = useMemo(
    () =>
      statePersistence
        ? {
            ...defaultStatePersistenceProps,
            ...statePersistence,
            isEnabled: statePersistence.isEnabled ?? true,
          }
        : undefined,
    [],
  );

  const persistedState = useMemo<CommonRequestParamsState | undefined>(
    () =>
      statePersistenceComputed && statePersistenceComputed.isEnabled
        ? CommonRequestParamsHelper.getPersistedState(statePersistenceComputed.persistenceKey)
        : undefined,
    [statePersistenceComputed],
  );

  const initialState = useMemo<CommonRequestParamsState | undefined>(() => persistedState, []);

  // non-filters
  const [offset, setOffset] = useState(PaginationHelper.getOffsetOrDefault(defaultValues?.offset));
  const [limit, setLimit] = useState(
    PaginationHelper.getLimitOrDefault(
      initialState?.pagination?.limit ?? defaultValues?.limit ?? PaginationHelper.defaultLimit,
    ),
  );

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfoDto | undefined>({
    offset,
    limit,
    totalCount: undefined,
    currentPage: 0,
    totalPages: undefined,
  });
  const [sortDefinition, setSortDefinition] = useState<SortDefinition | undefined>(
    initialState?.sort || defaultValues?.sortDefinition,
  );

  // filters
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [filterDefinition, setFilterDefinition] = useState<FilterDefinition | undefined>(
    initialState?.filter || defaultValues?.filterDefinition,
  );

  const [params, setParams] = useState<TParams | undefined>(() => {
    if (initParams) {
      const initializedParams = initParams({ filterDefinition });
      const initialParams: TParams = {
        ...defaultValues?.params,
        ...initializedParams,
      };
      return initialParams;
    }
    return defaultValues?.params;
  });

  const stateComputed = useMemo<CommonRequestParamsState>(
    () => ({
      ...initialState,
      pagination: {
        limit: limit,
      },
      sort: sortDefinition,
      filter: filterDefinition,
    }),
    [limit, sortDefinition, filterDefinition],
  );

  const sortDefinitionDtoComputed = useMemo<SortDefinitionDto | undefined>(
    () => SortHelper.mapSortDefinitionToSortDefinitionDto(sortDefinition),
    [sortDefinition],
  );

  const filterDefinitionDtoComputed = useMemo<FilterDefinitionDto | undefined>(
    () => filterDefinition?.mapToDto({ filterType: "Dynamic" }),
    [filterDefinition],
  );

  const isAnyFiltersComputed = useMemo<boolean>(
    () => !!search || (!!filterDefinition && filterDefinition.isValid),
    [search, filterDefinition],
  );

  // console.log(0, {
  //   persistedState,
  //   initialState,
  //   sortDefinition,
  //   filterDefinition,
  //   sortDefinitionDtoComputed,
  //   filterDefinitionDtoComputed,
  // });

  const persistState = useCallback((newState: CommonRequestParamsState) => {
    if (statePersistenceComputed?.isEnabled) {
      console.log(`RequestParams. Persist state.`, { statePersistenceComputed, newState });
      CommonRequestParamsHelper.persistState(
        statePersistenceComputed.persistenceKey,
        newState,
        statePersistenceComputed.strategy,
      );
    }
  }, []);

  const persistStateDebounce = useCallback(
    _.debounce(persistState, 500, { leading: false, trailing: true }),
    [persistState],
  );

  // persist state when it changes
  useEffect(() => {
    persistStateDebounce(stateComputed);
  }, [stateComputed]);

  const setOneParam = <TKey extends keyof TParams>(
    key: TKey,
    newValue: TParams[TKey] | undefined,
  ) => {
    const newParams = { ...params, [key]: newValue } as TParams;
    setParams(newParams);
  };

  const setOneParam2 = <TKey extends keyof TParams>(newValue: {
    [K in TKey]: TParams[K] | undefined;
  }) => {
    const first = Object.entries(newValue).at(0);
    if (first) {
      const [key, value] = first;
      setParams({ ...params, [key]: value } as TParams);
    }
  };

  const setManyParams = (newValue: Partial<TParams>) => {
    setParams({ ...params, ...newValue } as TParams);
  };

  //#region Result

  // always return the same instance of the result object (same reference),
  // to allow callers to capture the result once and access the same instance regardless of re-renders.

  const refetchEventEmitterRef = useRefWithFactory(() => {
    const eventEmitter = new RefetchEventEmitter();
    return {
      eventEmitter: eventEmitter,
      triggerRefetch: () => {
        eventEmitter.emit("triggerRefetch", undefined);
      },
    };
  });

  const computeResult = (): UseCommonRequestParamsHookResult<TParams> => ({
    offset,
    limit,
    paginationInfo: paginationInfo,
    sortDefinition,
    sortDefinitionDto: sortDefinitionDtoComputed,
    search,
    params,
    filterDefinition,
    filterDefinitionDto: filterDefinitionDtoComputed,
    deps: [offset, limit, params],
    debouncedDeps: [search, sortDefinitionDtoComputed, filterDefinitionDtoComputed],
    dataTabularProps: {
      statePersistence: statePersistence
        ? {
            persistenceKey: statePersistence.persistenceKey,
          }
        : undefined,
      pagination: {
        initialPaginationInfo: paginationInfo,
        currentPaginationInfo: paginationInfo,
        onChange: (newValue) => {
          setPaginationInfo(newValue);
          setOffset(newValue?.offset ?? 0);
          setLimit(newValue?.limit ?? 0);
        },
      },
      sort: {
        sortSpec: undefined,
        initialSortDefinition: sortDefinition,
        sortDefinition: sortDefinition,
        onChange: (newValue) => {
          setSortDefinition(newValue);
        },
      },
      quickFilter: {
        initialSearch: search,
        search: search,
        onChange: (newValue) => {
          setSearch(newValue);
        },
      },
      filters: {
        filterSpec: undefined,
        initialFilterDefinition: filterDefinition,
        filterDefinition: filterDefinition,
        onChange: (newValue) => {
          setFilterDefinition(newValue);
        },
      },
      refetch: refetchEventEmitterRef.current,
    },
    isAnyFilters: isAnyFiltersComputed,
    refetch: refetchEventEmitterRef.current,
    setOffset: (newValue: number | undefined) => {
      setOffset(PaginationHelper.getOffsetOrDefault(newValue));
      setPaginationInfo(
        paginationInfo
          ? { ...paginationInfo, offset: PaginationHelper.getOffsetOrDefault(newValue) }
          : undefined,
      );
    },
    setLimit: (newValue: number | undefined) => {
      setLimit(PaginationHelper.getLimitOrDefault(newValue));
      setPaginationInfo(
        paginationInfo
          ? { ...paginationInfo, limit: PaginationHelper.getLimitOrDefault(newValue) }
          : undefined,
      );
    },
    setPaginationInfo: (newValue: PaginationInfoDto | undefined) => {
      setPaginationInfo(newValue);
      setOffset(PaginationHelper.getOffsetOrDefault(newValue?.offset));
      setLimit(PaginationHelper.getLimitOrDefault(newValue?.limit));
    },
    setSearch,
    setSortDefinition,
    setFilterDefinition,
    setOneParam,
    setOneParam2,
    setManyParams,
    setAllParams: setParams,
  });

  const initialResult = useMemo(() => computeResult(), []);
  const result = useRef(initialResult);
  Object.assign(result.current, computeResult());

  //#endregion

  return result.current;
}

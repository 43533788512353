import { Tooltip } from "@mui/material";
import AppIcon from "./AppIcon";

interface Props {
  title: string;
}

export default function HelpTooltipIcon({ title }: Props) {
  return (
    <Tooltip title={title}>
      <AppIcon of='help' />
    </Tooltip>
  );
}

import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { VehicleTaxCalcRequestDto } from "@/core/api/generated";
import { Box, Stack, SxProps, Theme } from "@mui/material";
import GeneralCountryDisplay from "../../General/Display/GeneralCountryDisplay";
import GeneralCurrencyDisplay from "../../General/Display/GeneralCurrencyDisplay";
import GeneralPriceDisplay from "../../General/GeneralPrice/GeneralPriceDisplay";
import GeneralTaxDisplay from "../../General/GeneralTax/GeneralTaxDisplay";

interface Props {
  vehicleTaxCalcRequestParams: VehicleTaxCalcRequestDto | null | undefined;
  sx?: SxProps<Theme>;
}

export default function VehicleTaxCalcRequestParamsDisplay({
  vehicleTaxCalcRequestParams,
  sx,
}: Props) {
  if (!vehicleTaxCalcRequestParams) {
    return null;
  }

  return (
    <Box sx={sx}>
      <Stack spacing={1}>
        <FieldValue label='Vehicle tax type' isEmpty={!vehicleTaxCalcRequestParams?.type}>
          <InlineApiEnumValue
            type='VehicleTaxType'
            value={vehicleTaxCalcRequestParams?.type}
            withDescription
            direction='column'
          />
        </FieldValue>

        <FieldValue label='Country' isEmpty={!vehicleTaxCalcRequestParams?.country}>
          <GeneralCountryDisplay country={vehicleTaxCalcRequestParams?.country} />
        </FieldValue>

        <FieldValue label='Currency' isEmpty={!vehicleTaxCalcRequestParams?.currency}>
          <GeneralCurrencyDisplay currency={vehicleTaxCalcRequestParams?.currency} />
        </FieldValue>

        <FieldValue label='Price' isEmpty={!vehicleTaxCalcRequestParams?.price}>
          <GeneralPriceDisplay price={vehicleTaxCalcRequestParams?.price} />
        </FieldValue>

        <FieldValue label='Tax' isEmpty={!vehicleTaxCalcRequestParams?.tax}>
          <GeneralTaxDisplay tax={vehicleTaxCalcRequestParams?.tax} />
        </FieldValue>

        {/* <FieldValue label='Vehicle spec' isEmpty={!vehicleTaxCalcRequestParams?.spec}>
          <VehicleSpecDisplay spec={vehicleTaxCalcRequestParams?.spec} />
        </FieldValue> */}
      </Stack>
    </Box>
  );
}

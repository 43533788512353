import { useHistory, useParams } from "react-router";

import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import AssetCreateUpdate from "@/common/components/Entity/Asset/AssetCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function AssetCreateUpdatePage() {
  const { assetId } = useParams<{ assetId?: string }>();
  const history = useHistory();

  const isCreate = !assetId;

  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={isCreate ? "Create new asset" : "Edit asset"} />}
    >
      <AssetCreateUpdate
        entityId={assetId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.ASSET_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}

import { Box, Stack, Tooltip } from "@mui/material";
import { ForwardedRef, ReactNode, forwardRef } from "react";

import { ApiEnumValueTypeMap, enumService } from "@/common/services/enum";
import {
  AccessoryStatus,
  ApprovalResponseType,
  ApprovalStatus,
  ConsensusType,
  ContractAssessmentFlowStateFilterType,
  EntityType,
  SubscriptionStatus,
} from "@/core/api/generated";

import AppIcon, { AppIconProps } from "./AppIcon";

const noneValue = "None";

type ApiEnumNameLocal = keyof Pick<
  ApiEnumValueTypeMap,
  | "ApprovalResponseType"
  | "ApprovalStatus"
  | "ConsensusType"
  | "AccessoryStatus"
  | "DocumentStatus"
  | "ContractAssessmentFlowStateFilterType"
  | "EntityType"
  | "SubscriptionStatus"
>;
type ApiEnumValueLocal = ApiEnumValueTypeMap[ApiEnumNameLocal];

type AppIconPropsLocal = Omit<AppIconProps, "of">;

export interface ApiEnumIconProps extends AppIconPropsLocal {
  type: ApiEnumNameLocal;
  value?: ApiEnumValueLocal | null;
  // value?: EnumValueType | null;
  withNoneValue?: boolean;
  withTooltip?: boolean;
  tooltipTitle?: ReactNode | string;
}

// const iconMap: Partial<
//   Record<
//     keyof AllApiEnumTypes,
//     Record<
//       AllApiEnumTypes[],
//       (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => JSX.Element | null
//     >
//   >
// > =
type GetApiEnumIconFunc = (
  props: AppIconPropsLocal,
  ref?: ForwardedRef<SVGSVGElement>,
) => JSX.Element | null;

const iconMap: Partial<
  Record<"None" | ApiEnumNameLocal, Partial<Record<ApiEnumValueLocal, GetApiEnumIconFunc>>>
> = {
  None: {
    None: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='none' ref={ref} {...props} />
    ),
  },

  ApprovalResponseType: {
    [ApprovalResponseType.None]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='waiting' color='secondary' ref={ref} {...props} />
    ),
    [ApprovalResponseType.Approve]: (
      props: AppIconPropsLocal,
      ref?: ForwardedRef<SVGSVGElement>,
    ) => <AppIcon of='approve' color='success' ref={ref} {...props} />,
    [ApprovalResponseType.ForceApprove]: (
      props: AppIconPropsLocal,
      ref?: ForwardedRef<SVGSVGElement>,
    ) => <AppIcon of='forceApprove' color='success' ref={ref} {...props} />,
    [ApprovalResponseType.Decline]: (
      props: AppIconPropsLocal,
      ref?: ForwardedRef<SVGSVGElement>,
    ) => <AppIcon of='decline' color='error' ref={ref} {...props} />,
    [ApprovalResponseType.ForceDecline]: (
      props: AppIconPropsLocal,
      ref?: ForwardedRef<SVGSVGElement>,
    ) => <AppIcon of='forceDecline' color='error' ref={ref} {...props} />,
  },

  ApprovalStatus: {
    [ApprovalStatus.Approved]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='approved' color='success' ref={ref} {...props} />
    ),
    [ApprovalStatus.ForceApproved]: (
      props: AppIconPropsLocal,
      ref?: ForwardedRef<SVGSVGElement>,
    ) => <AppIcon of='forceApproved' color='success' ref={ref} {...props} />,
    [ApprovalStatus.Declined]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='declined' color='error' ref={ref} {...props} />
    ),
    [ApprovalStatus.ForceDeclined]: (
      props: AppIconPropsLocal,
      ref?: ForwardedRef<SVGSVGElement>,
    ) => <AppIcon of='forceDeclined' color='error' ref={ref} {...props} />,
  },

  ConsensusType: {
    [ConsensusType.Consensus]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='consensus' color='success' ref={ref} {...props} />
    ),
    [ConsensusType.ForcedConsensus]: (
      props: AppIconPropsLocal,
      ref?: ForwardedRef<SVGSVGElement>,
    ) => <AppIcon of='forcedConsensus' color='success' ref={ref} {...props} />,
    [ConsensusType.NoConsensus]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='noConsensus' color='error' ref={ref} {...props} />
    ),
  },

  AccessoryStatus: {
    [AccessoryStatus.InOrder]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='accessoryInOrder' color='success' ref={ref} {...props} />
    ),
    [AccessoryStatus.Missing]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='accessoryMissing' color='error' ref={ref} {...props} />
    ),
    [AccessoryStatus.Damaged]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='accessoryDamaged' color='error' ref={ref} {...props} />
    ),
  },

  // DocumentStatus: {
  //   [DocumentStatus.Approved]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
  //     <AppIcon of='approved' color='success' ref={ref} {...props} />
  //   ),
  //   [DocumentStatus.ForceApproved]: (
  //     props: AppIconPropsLocal,
  //     ref?: ForwardedRef<SVGSVGElement>,
  //   ) => <AppIcon of='approved' color='success' ref={ref} {...props} />,
  //   [DocumentStatus.Declined]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
  //     <AppIcon of='declined' color='error' ref={ref} {...props} />
  //   ),
  //   [DocumentStatus.ForceDeclined]: (
  //     props: AppIconPropsLocal,
  //     ref?: ForwardedRef<SVGSVGElement>,
  //   ) => <AppIcon of='declined' color='error' ref={ref} {...props} />,
  // },

  ContractAssessmentFlowStateFilterType: {
    [ContractAssessmentFlowStateFilterType.All]: (
      props: AppIconPropsLocal,
      ref?: ForwardedRef<SVGSVGElement>,
    ) => <AppIcon of='check' color='success' ref={ref} {...props} />,
    [ContractAssessmentFlowStateFilterType.Pending]: (
      props: AppIconPropsLocal,
      ref?: ForwardedRef<SVGSVGElement>,
    ) => <AppIcon of='clock' color='secondary' ref={ref} {...props} />,
    [ContractAssessmentFlowStateFilterType.ActionRequired]: (
      props: AppIconPropsLocal,
      ref?: ForwardedRef<SVGSVGElement>,
    ) => <AppIcon of='warning' color='error' ref={ref} {...props} />,
  },

  EntityType: {
    [EntityType.Vehicle]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='vehicle' ref={ref} {...props} />
    ),
    [EntityType.Accessory]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='accessory' ref={ref} {...props} />
    ),
    [EntityType.Asset]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='asset' ref={ref} {...props} />
    ),
    [EntityType.Pool]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='pool' ref={ref} {...props} />
    ),
    [EntityType.PoolItem]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='poolItem' ref={ref} {...props} />
    ),
  },

  SubscriptionStatus: {
    [SubscriptionStatus.Draft]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='draft' ref={ref} {...props} />
    ),
    [SubscriptionStatus.Pending]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='pending' ref={ref} {...props} />
    ),
    [SubscriptionStatus.Unpaid]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='unpaid' ref={ref} {...props} />
    ),
    [SubscriptionStatus.Active]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='active' ref={ref} {...props} />
    ),
    [SubscriptionStatus.PastDue]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='pastDue' ref={ref} {...props} />
    ),
    [SubscriptionStatus.Ended]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='ended' ref={ref} {...props} />
    ),
    [SubscriptionStatus.Paused]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
      <AppIcon of='paused' ref={ref} {...props} />
    ),
    [SubscriptionStatus.Canceled]: (
      props: AppIconPropsLocal,
      ref?: ForwardedRef<SVGSVGElement>,
    ) => <AppIcon of='canceled' ref={ref} {...props} />,
  },
};

/** Icon based on enum value. */
export default forwardRef<SVGSVGElement, ApiEnumIconProps>(function ApiEnumIcon(
  {
    type,
    value,
    withNoneValue = true,
    withTooltip = true,
    tooltipTitle = undefined,
    ...other
  }: ApiEnumIconProps,
  ref,
) {
  const isNoneValue = value === noneValue;

  if (!value) {
    return null;
  }

  if (isNoneValue && !withNoneValue) {
    return null;
  }

  let renderIcon = (iconMap[type] || {})[value];
  if (!renderIcon && isNoneValue) {
    renderIcon = (iconMap["None"] || {})["None"];
  }

  const iconEl = renderIcon ? renderIcon(other, ref) : null;
  if (!iconEl) {
    return null;
  }

  const enumValueName = enumService.getEnumValueName(type, value);
  const enumValueDescription = enumService.getEnumValueDescription(type, value);

  return withTooltip ? (
    <Tooltip
      title={
        tooltipTitle || (
          <Stack>
            <Box>{enumValueName}</Box>
            {enumValueDescription && <Box>{enumValueDescription}</Box>}
          </Stack>
        )
      }
    >
      {iconEl}
    </Tooltip>
  ) : (
    iconEl
  );
});

import { Box, LinearProgress, Stack } from "@mui/material";
import { ReactNode, useRef } from "react";

import { ROUTE_PATH } from "@/common/constants/routing";
import { apiClient } from "@/core/api/ApiClient";
import {
  DataUpdatesHubClientMethodName,
  EntityType,
  GeneralEventLogDto,
  GeneralEventLogGetPaginatedDto,
  SortOrder,
} from "@/core/api/generated";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { enumService } from "@/common/services/enum";
import { SortDefinition } from "@/common/sorting/sortDefinition";
import { SortDefinitionItem } from "@/common/sorting/sortDefinitionItem";
import AppLink from "../../Link/AppLink";
import AppPagination from "../../Pagination/AppPagination";
import GeneralStrictEntityRelationLink from "../General/Display/GeneralStrictEntityRelationLink";
import GeneralEventLogList from "./GeneralEventLogList";

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  filters: true,
  pagination: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultHeaderProps = {
  withLink: false,
  title: undefined as ReactNode | undefined,
};

type OwnProps = {
  entityId?: string;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
};

type Props = OwnProps;

/** @deprecated Use GeneralHistory. */
export default function GeneralEventLogsEmbeddedView({
  entityId,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const topAnchorRef = useRef<HTMLElement | null>(null);
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<GeneralEventLogGetPaginatedDto>({
    defaultValues: {
      sortDefinition: new SortDefinition({
        items: [
          new SortDefinitionItem({
            field: getTypedPath<GeneralEventLogDto>().date.$path,
            sortOrder: SortOrder.Desc,
          }),
        ],
      }),
    },
  });

  const paginatedEventLogsRequest = useApiRequest(
    apiClient.generalEventLogsApi.apiV1GeneralEventLogsEntityIdGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      entityId: entityId || "",
      generalEventLogGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedEventLogs = paginatedEventLogsRequest.data;
  const firstItem = paginatedEventLogs?.items?.at(0);

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.GeneralEventLog)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedEventLogsRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    isEnabled: displayProps.breadcrumbs,
    waitTimeout: 10_000,
    idBreadcrumb: firstItem && {
      idValue: entityId!,
      newTitle: `${firstItem.entity?.nameComputed || ""} (${enumService.getEnumValueName(
        "EntityType",
        firstItem.entity?.entityType,
      )})`,
    },
  });

  return (
    <ViewLayout
      displayProps={displayProps}
      header={
        displayProps.header && (
          <SimpleViewPageHeader
            title={
              <AppLink enabled={headerProps?.withLink} to={ROUTE_PATH.GENERAL_EVENT_LOGS(entityId)}>
                {headerProps.title || (
                  <>
                    <span>Event log for</span>{" "}
                    <GeneralStrictEntityRelationLink
                      value={firstItem?.entity}
                      linkProps={{
                        withIcon: false,
                        withTooltip: true,
                      }}
                    />
                  </>
                )}
              </AppLink>
            }
          />
        )
      }
    >
      <Stack spacing={2}>
        {paginatedEventLogsRequest.isLoading && <LinearProgress />}

        <Stack direction='column' spacing={0}>
          <Box ref={topAnchorRef} sx={{ m: 0, p: 0 }}></Box>

          {paginatedEventLogs && <GeneralEventLogList paginatedEventLogs={paginatedEventLogs} />}
        </Stack>

        {displayProps?.pagination && (
          <AppPagination
            paginationInfo={paginatedEventLogs?.pagination}
            onChange={(newValue) => {
              commonRequestParams.setPaginationInfo(newValue);
              topAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
          />
        )}
      </Stack>
    </ViewLayout>
  );
}

import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function VehicleFrontIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      color='secondary'
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M23.5 4C23.776 4 24 4.224 24 4.5V5.011C24 5.804 23.074 6 22.384 6L21.298 4H23.5ZM22.059 7.506C22.698 8.692 23.005 9.758 23.005 11.172C23.005 12.542 22.608 13.705 22 15.153V17C22 17.552 21.552 18 21 18H19.5C18.948 18 18.5 17.552 18.5 17V16H5.5V17C5.5 17.552 5.052 18 4.5 18H3C2.448 18 2 17.552 2 17V15.153C1.392 13.705 0.995 12.542 0.995 11.172C0.995 9.758 1.302 8.692 1.941 7.506C2.77 5.969 3.792 4.053 4.871 2.254C5.699 0.872 6.133 0.547 7.149 0.365C8.681 0.09 10.067 0 12 0C13.933 0 15.319 0.09 16.851 0.365C17.867 0.547 18.301 0.872 19.129 2.254C20.208 4.053 21.23 5.969 22.059 7.506ZM6 10.5C6 9.672 5.328 9 4.5 9C3.672 9 3 9.672 3 10.5C3 11.328 3.672 12 4.5 12C5.328 12 6 11.328 6 10.5ZM16 11.5C16 11.224 15.776 11 15.5 11H8.5C8.224 11 8 11.224 8 11.5C8 11.776 8.224 12 8.5 12H15.5C15.776 12 16 11.776 16 11.5ZM18.941 5.973C18.941 5.973 18.201 4.147 17.31 2.831C17.108 2.533 16.795 2.329 16.441 2.265C14.93 1.993 13.606 1.906 12 1.906C10.394 1.906 9.07 1.993 7.559 2.265C7.205 2.328 6.892 2.532 6.69 2.831C5.799 4.146 5.059 5.973 5.059 5.973C6.699 6.286 9.368 6.47 12 6.47C14.632 6.47 17.301 6.286 18.941 5.973ZM21 10.5C21 9.672 20.328 9 19.5 9C18.672 9 18 9.672 18 10.5C18 11.328 18.672 12 19.5 12C20.328 12 21 11.328 21 10.5ZM2.702 4H0.5C0.224 4 0 4.224 0 4.5V5.011C0 5.804 0.926 6 1.616 6L2.702 4Z' />
    </SvgIcon>
  );
});

import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import _ from "lodash";
import { ReactNode, useState } from "react";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import Datetime from "@/common/components/Datetime/Datetime";
import DamageDetectionLink from "@/common/components/Entity/DamageDetection/DamageDetectionLink";
import GeneralInspectorDisplay from "@/common/components/Entity/General/Display/GeneralInspectorDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import LoadingMenuItem from "@/common/components/Menu/LoadingMenuItem";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FileItem } from "@/common/fileItem";
import { FileHelper } from "@/common/helpers/file";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DamageDetectionAggregateDto,
  DamageDetectionAggregateItemDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
} from "@/core/api/generated";

import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import ContractBlock from "@/common/components/Entity/Contract/ContractBlock";
import DamageDetectionAggregateDeleteModal from "@/common/components/Entity/DamageDetectionAggregate/DamageDetectionAggregateDeleteModal";
import GeneralApprovalStatusInfoDisplay from "@/common/components/Entity/General/Display/GeneralApprovalStatusInfoDisplay";
import VehicleBlock from "@/common/components/Entity/Vehicle/VehicleBlock";
import VehicleDamageLink from "@/common/components/Entity/VehicleDamage/VehicleDamageLink";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import HeaderMenuItem from "@/common/components/Menu/HeaderMenuItem";
import AppTypography from "@/common/components/Text/AppTypography";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { TextHelper } from "@/common/helpers/text";
import { TypeHelper } from "@/common/helpers/type";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useHistory } from "react-router";
import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import RepairOperationCreateUpdateModal from "../../RepairOperation/RepairOperationCreateUpdateModal";
import BaseEntityView from "../../components/BaseEntityView";
import DamageDetectionAggregateItemDeleteModal from "../DamageDetectionAggregateItemDeleteModal";
import StartDamageCostEvalModal from "../StartDamageCostEvalModal";

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  vehicle: true,
  contract: true,
  items: true,
  attachments: true,
  sourceItems: true,
  customSections: true,
};

const defaultHeaderProps = {
  withLink: false,
  title: undefined as
    | ReactNode
    | ((entity: DamageDetectionAggregateDto | null | undefined) => ReactNode)
    | undefined,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
  primaryActions: undefined as
    | ReactNode
    | ((entity: DamageDetectionAggregateDto | null | undefined) => ReactNode)
    | undefined,
};

interface OwnProps {
  aggregateId?: string | null;
  aggregate?: DamageDetectionAggregateDto | null;
  withRealtimeDataUpdates?: boolean;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
  highlightProps?: {
    /** Item to highlight when mounted. */
    itemId?: string | null;
  };
  customSections?: {
    afterHeader?: ReactNode;
  };
  onEntityChange?: () => void;
}

export type DamageDetectionAggregateViewProps = OwnProps;

export default function DamageDetectionAggregateView({
  aggregateId,
  aggregate,
  withRealtimeDataUpdates = true,
  displayProps = defaultDisplayProps,
  headerProps,
  highlightProps,
  customSections,
  onEntityChange,
}: DamageDetectionAggregateViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const { hasPermissions } = useAuthorizationInfo();
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();
  const thunkDispatch = useAppThunkDispatch();
  const currentTenant = useCurrentTenant();

  const request = useApiRequest(
    apiClient.damageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesAggregateIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      aggregateId: aggregateId!,
    },
    {
      deps: [aggregateId],
      skip: !aggregateId,
    },
  );
  aggregate = request?.data || aggregate;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.DamageDetectionAggregate,
        aggregateId,
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      request.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        aggregate && {
          idValue: aggregate?.id || "",
          newTitle: aggregate?.localNumber || "",
        }) ||
      undefined,
  });

  // menu, dialogs
  const [isCreateDamageCostEvaluationModalOpen, setIsCreateDamageCostEvaluationModalOpen] =
    useState(false);
  const [isCreateRepairOperationModalOpen, setIsCreateRepairOperationModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<DamageDetectionAggregateItemDto | undefined>(
    undefined,
  );
  const [isItemDeleteModalOpen, setIsItemDeleteModalOpen] = useState(false);

  return (
    <BaseEntityView
      entityType={EntityType.DamageDetectionAggregate}
      entityId={aggregateId}
      entity={aggregate}
      entityRequest={request}
    >
      <Stack direction='column' spacing={2}>
        {displayProps.header && (
          <DetailedViewPageHeader
            image={undefined}
            title={
              <Box>
                {headerProps?.title &&
                  _.isFunction(headerProps?.title) &&
                  headerProps.title(aggregate)}
                {headerProps?.title && !_.isFunction(headerProps?.title) && headerProps.title}
                {!headerProps?.title && (
                  <Box>
                    Damage detection aggregate {aggregate?.localNumber}{" "}
                    {aggregate && (
                      <Typography component='span' variant='body2'>
                        ({aggregate?.items?.length}{" "}
                        {TextHelper.pluralize("item", aggregate?.items?.length ?? 0)})
                      </Typography>
                    )}{" "}
                    <EntityChipList entity={aggregate} variant='normal' />
                  </Box>
                )}
              </Box>
            }
            titleProps={{
              to: headerProps?.withLink
                ? ROUTE_PATH.DAMAGE_DETECTION_AGGREGATE_VIEW(aggregate?.id)
                : undefined,
              typographyProps: headerProps?.typographyProps,
            }}
            subtitle={
              <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                <GeneralAttachedTagsDisplay
                  tags={aggregate?.tags}
                  defaultIsFolded={false}
                  edit={{
                    entityType: TagEntityType.DamageDetectionAggregate,
                    entityId: aggregate?.id,
                    onSaved: (newValue) => {
                      request.updateData((x) => {
                        x.tags = newValue || undefined;
                      });
                    },
                  }}
                />
              </AuthorizedElement>
            }
            primaryActions={
              displayProps.actions && (
                <Stack direction='row' spacing={1}>
                  {headerProps?.primaryActions &&
                    _.isFunction(headerProps?.primaryActions) &&
                    headerProps.primaryActions(aggregate)}
                  {headerProps?.primaryActions &&
                    !_.isFunction(headerProps?.primaryActions) &&
                    headerProps.primaryActions}
                </Stack>
              )
            }
            secondaryActions={
              displayProps.actions &&
              aggregate && (
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <MenuWithTrigger
                    trigger={
                      <IconButton sx={{ ml: "auto" }}>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                  >
                    {({ handleClose }) => [
                      <LoadingMenuItem
                        key='downloadPdf'
                        onClick={async () => {
                          const downloadResult = await FileHelper.getDownloadFileApiResult(() =>
                            apiClient.damageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet(
                              {
                                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                                aggregateId: aggregate?.id || "",
                              },
                              {
                                responseType: "blob",
                              },
                            ),
                          );
                          FileHelper.downloadBlobFromApiResult(downloadResult);
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='download' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Download PDF</ListItemText>
                      </LoadingMenuItem>,

                      <HeaderMenuItem key='quickActions' primaryTitle='Quick actions' />,
                      <AuthorizedMenuItem
                        key='startCostEval'
                        permissionsAny={[
                          AppPermission.DamageCostEvaluationPerform,
                          AppPermission.DamageCostEvaluationManage,
                        ]}
                        onClick={() => {
                          setIsCreateDamageCostEvaluationModalOpen(true);
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='damageCostEvaluation' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Start damage cost evaluation</ListItemText>
                      </AuthorizedMenuItem>,
                      <AuthorizedMenuItem
                        key='repair'
                        permissionsAny={[
                          AppPermission.RepairOperationPerform,
                          AppPermission.RepairOperationManage,
                        ]}
                        onClick={() => {
                          setIsCreateRepairOperationModalOpen(true);
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='repair' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>New repair operation</ListItemText>
                      </AuthorizedMenuItem>,
                      // NB: temporary disabled
                      // <MenuItem
                      //   key='documents'
                      //   component={RouterLink}
                      //   to={ROUTE_PATH.DOCUMENTS({
                      //     sourceType: EntitySourceType.DamageDetectionAggregate,
                      //     sourceId: aggregate?.id,
                      //   })}
                      // >
                      //   <ListItemIcon>
                      //     <AppIcon of='documents' fontSize='small' />
                      //   </ListItemIcon>
                      //   <ListItemText>Documents</ListItemText>
                      // </MenuItem>,
                      !aggregate?.contract && (
                        <AuthorizedMenuItem
                          key='delete'
                          permissions={[AppPermission.DamageCostEvaluationManage]}
                          onClick={() => setIsDeleteModalOpen(true)}
                        >
                          <ListItemIcon>
                            <AppIcon of='delete' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>Delete</ListItemText>
                        </AuthorizedMenuItem>
                      ),
                    ]}
                  </MenuWithTrigger>
                </AuthorizedElement>
              )
            }
          />
        )}

        {displayProps?.customSections && customSections?.afterHeader && (
          <Box>{customSections.afterHeader}</Box>
        )}

        {(displayProps?.vehicle || displayProps?.contract) && (
          <Stack direction={{ xxs: "column", md: "row" }} alignItems='flex-start' spacing={2}>
            {displayProps?.vehicle && (
              <VehicleBlock
                sx={{ maxWidth: { md: 400 } }}
                variant='reference'
                vehicle={aggregate?.vehicle}
                withDetailsToggle
                withLink
              />
            )}

            {displayProps?.contract && aggregate?.contract && (
              <ContractBlock
                sx={{ maxWidth: { md: 400 } }}
                variant='reference'
                contract={aggregate?.contract}
                withDetailsToggle
                withLink
              />
            )}
          </Stack>
        )}

        {aggregate?.notes && (
          <Grid container columnSpacing={2} rowSpacing={1}>
            <Grid item xxs={12} md minWidth={200}>
              <Stack direction='column' spacing={1}>
                {aggregate?.notes && <FieldValue label='Notes'>{aggregate?.notes}</FieldValue>}
              </Stack>
            </Grid>
            <Grid item xxs={12} md minWidth={200}>
              <Stack direction='column' spacing={1}></Stack>
            </Grid>
          </Grid>
        )}

        {displayProps?.attachments && aggregate?.attachments?.length !== 0 && (
          <Box>
            <FileListView
              files={FileItem.createManyFrom(aggregate?.attachments)}
              maxVisibleFiles={10}
              itemWidth={45}
            />
          </Box>
        )}

        {displayProps?.items && (
          <DataTabular
            columns={[
              {
                field: "partType.name",
                title: "Part & damage type",
                flex: 2,
                renderCell: (item) => (
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Box>
                      {item.item?.damage ? (
                        <VehicleDamageLink
                          vehicleId={item.item?.damage?.vehicleId}
                          entity={item.item?.damage}
                          withIcon
                        />
                      ) : (
                        <>
                          {item.item?.partType!.name} - {item.item?.damageType!.name}
                        </>
                      )}
                    </Box>
                    <Typography component='div' variant='body2' color='text.secondary'>
                      (<InlineApiEnumValue type='VehicleArea' value={item.item?.area} /> /{" "}
                      {(item.item?.projection && (
                        <InlineApiEnumValue
                          type='VehicleProjection'
                          value={item.item?.projection}
                        />
                      )) ||
                        "-"}
                      )
                    </Typography>
                  </Stack>
                ),
              },
              {
                field: "item.notes",
                title: "Notes",
                flex: 0,
                renderCell: (item) =>
                  item.item?.notes ? (
                    <AppTypography ellipsing={{ enabled: true }}>{item.item?.notes}</AppTypography>
                  ) : (
                    "-"
                  ),
              },
              {
                field: "attachments",
                title: "Attachments",
                flex: 0,
                renderCell: (item) =>
                  !TypeHelper.isEmpty(item.item?.attachments) ? (
                    <FileListView
                      files={FileItem.createManyFrom(item.item?.attachments)}
                      maxVisibleFiles={2}
                      itemWidth={45}
                    />
                  ) : (
                    "-"
                  ),
              },
              {
                field: "damageDetection.inspectedAt",
                title: "Inspected",
                flex: 1,
                renderCell: (item) => (
                  <Stack>
                    <GeneralInspectorDisplay
                      inspector={item.damageDetection!.inspector}
                      inlineUserProps={{
                        withAvatar: true,
                        withLink: true,
                      }}
                    />
                    <Datetime datetime={item.damageDetection!.inspectedAt} />
                  </Stack>
                ),
              },
              {
                field: "approval",
                title: "Approval status",
                flex: 1,
                renderCell: (item) => <GeneralApprovalStatusInfoDisplay approval={item.approval} />,
              },
            ]}
            rows={aggregate?.items}
            getRowId={(item) => item.id!}
            borderVariant='bordered'
            getRowHeight={() => "auto"}
            rowHeight={65}
            isColumnSelectorEnabled={false}
            isColumnMenuEnabled={false}
            isPaginationEnabled={false}
            // rowHighlightPropsGetter={(item) =>
            //   (item.id === highlightProps?.itemId && {
            //     isHighlighted: true,
            //     durationMs: 5000,
            //     withAutoScroll: true,
            //   }) ||
            //   {}
            // }
            renderRowAction={
              (displayProps?.actions &&
                (({ item }) => (
                  <>
                    <MenuWithTrigger
                      withAuthCloseOnClick
                      trigger={
                        <IconButton>
                          <AppIcon of='moreVert' />
                        </IconButton>
                      }
                    >
                      <AuthorizedMenuItem
                        permissions={[AppPermission.DamageDetectionManage]}
                        onClick={() => {
                          setItemToDelete(item);
                          setIsItemDeleteModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='delete' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Delete item</ListItemText>
                      </AuthorizedMenuItem>
                    </MenuWithTrigger>
                  </>
                ))) ||
              undefined
            }
          />
        )}

        {displayProps?.sourceItems && !_.isEmpty(aggregate?.sourceDamageDetections) && (
          <Box>
            <Typography component='div' variant='subtitle1'>
              <span>Source damage detections</span>
            </Typography>
            <Stack direction='column' spacing={0}>
              {aggregate?.sourceDamageDetections?.map((source, i) => (
                <DamageDetectionLink key={i} entity={source} />
              ))}
            </Stack>
          </Box>
        )}

        {/* Delete modal */}
        {aggregate && (
          <DamageDetectionAggregateDeleteModal
            entity={aggregate}
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onDelete={() => {
              setIsDeleteModalOpen(false);
              onEntityChange && onEntityChange();
            }}
          />
        )}

        {/* Delete item modal */}
        {aggregate && itemToDelete && (
          <DamageDetectionAggregateItemDeleteModal
            entity={aggregate}
            item={itemToDelete}
            open={isItemDeleteModalOpen}
            onClose={() => setIsItemDeleteModalOpen(false)}
            onDelete={() => {
              setIsItemDeleteModalOpen(false);
              onEntityChange && onEntityChange();
              request.refetch();
            }}
          />
        )}

        {/* Start cost eval */}
        {aggregate && (
          <StartDamageCostEvalModal
            open={isCreateDamageCostEvaluationModalOpen}
            onClose={() => setIsCreateDamageCostEvaluationModalOpen(false)}
            aggregate={aggregate}
            withRedirectToCostEval
          />
        )}

        {/* Create RepairOperation */}
        {aggregate && (
          <RepairOperationCreateUpdateModal
            open={isCreateRepairOperationModalOpen}
            onClose={() => setIsCreateRepairOperationModalOpen(false)}
            createUpdateProps={{
              defaultValues: {
                vehicleId: aggregate?.vehicle?.id,
                contractId: aggregate?.contract?.id,
                vehicleDamageIds: aggregate?.items
                  ?.map((x) => x.item?.damage?.id || "")
                  ?.filter((x) => !!x),
              },
              onSave: (newValue) => {
                setIsCreateRepairOperationModalOpen(false);
                history.push(ROUTE_PATH.REPAIR_OPERATION_VIEW(newValue.id));
              },
            }}
          />
        )}
      </Stack>
    </BaseEntityView>
  );
}

import { IconButton, LinearProgress, Stack, Typography, Grid, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import ContactCard from "@/common/components/Contact/ContactCard";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import { AppPermission, TenantPaymentProfileDto } from "@/core/api/generated/api";
import * as paymentsSlice from "@/store/management/payments/slice";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AppIcon from "@/common/components/Icons/AppIcon";
import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { useHistory } from "react-router";
import { ROUTE_PATH } from "@/common/constants/routing";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import _ from "lodash";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import AppLink from "@/common/components/Link/AppLink";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";

function PaymentProfile() {
  const history = useHistory();

  const getPaymentProfileRequest = useApiRequest(
    apiClient.paymentsApi.apiV1PaymentsPaymentProfileGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
  );
  const paymentProfile = getPaymentProfileRequest.data;

  return (
    <ViewLayout
      header={
        <DetailedViewPageHeader
          title={"Payment profile"}
          primaryActions={
            <AuthorizedElement permissions={[AppPermission.TenantSubscriptionManage]}>
              <Button
                component={AppLink}
                to={ROUTE_PATH.MANAGEMENT_PAYMENT_PROFILE_EDIT}
                variant='outlined'
                color='secondary'
                size='medium'
                startIcon={<AppIcon of='edit' />}
              >
                Edit
              </Button>
            </AuthorizedElement>
          }
        />
      }
    >
      <Stack spacing={2}>
        {getPaymentProfileRequest.isLoading && <LinearProgress />}

        <Stack spacing={1}>
          <FieldValue label='Business name'>
            {paymentProfile?.businessInfo?.businessName || ""}
          </FieldValue>
          <FieldValue label='First name'>
            {paymentProfile?.businessInfo?.personName?.firstName || ""}
          </FieldValue>
          <FieldValue label='Last name'>
            {paymentProfile?.businessInfo?.personName?.lastName || ""}
          </FieldValue>
          <FieldValue label='Email'>{paymentProfile?.businessInfo?.email || ""}</FieldValue>
          <FieldValue label='Phone number'>
            {paymentProfile?.businessInfo?.phoneNumber || ""}
          </FieldValue>
          <FieldValue label='Address' isEmpty={!paymentProfile?.businessInfo?.address}>
            <GeneralAddressDisplay
              address={paymentProfile?.businessInfo?.address}
              direction='column'
            />
          </FieldValue>
        </Stack>

        <Stack spacing={1}>
          <Typography component='div' variant='h6'>
            Contacts
          </Typography>

          <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ mt: 1 }}>
            {_.isEmpty(paymentProfile?.contacts) && <NoDataAlert />}

            {paymentProfile?.contacts?.map((c, i) => {
              return (
                <ContactCard
                  key={i}
                  contact={{
                    name: c.personName?.name,
                    email: c.email,
                    phoneNumber: c.phoneNumber,
                  }}
                  sx={{ minWidth: 300 }}
                />
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </ViewLayout>
  );
}

export default withTranslation()(PaymentProfile);

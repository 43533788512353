import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import InlineCode from "@/common/components/Code/InlineCode";
import ApiEnumDtoDataList from "@/common/components/Enum/ApiEnumDtoDataList";
import { enumService } from "@/common/services/enum";
import { ValidationInfo } from "@/common/validation";

export default function ApplicationEnumViewPage() {
  const { enumName } = useParams<{ enumName?: string }>();
  const [validation, setValidation] = useState(new ValidationInfo());

  const enumCatalog = enumService.getOrRestoreEnumCatalog();

  const enumDtos = useMemo(
    () =>
      _.chain(enumCatalog?.catalog?.enumsMap || {})
        .entries()
        .filter(
          ([enumTypeName2, dtos]) =>
            enumTypeName2.toLocaleLowerCase() === enumName?.toLocaleLowerCase(),
        )
        .map(([enumTypeName2, dtos]) => dtos)
        .flatten()
        .value(),
    [enumName, enumCatalog],
  );

  if (enumDtos.length === 0) {
    return <EntityNotFoundAlert />;
  }

  return (
    <ViewPageLayout
      header={
        <>
          <Typography sx={{ mr: 1 }}>Enum</Typography>
          <InlineCode>{enumName}</InlineCode>
        </>
      }
    >
      <Stack direction='column' spacing={2}>
        <ApiEnumDtoDataList values={enumDtos} />
      </Stack>
    </ViewPageLayout>
  );
}

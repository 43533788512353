import { FilterOperator, FilterType, FilterValueType } from "@/core/api/generated";
import { ReactNode } from "react";
import { FilterHelper } from "../helpers/filter";
import { FilterValue, FilterValueInputProps } from "../ts/filters";
import { FilterDefinitionItem } from "./filterDefinitionItem";

export class FilterFieldOperatorSpec {
  public readonly operator: FilterOperator;
  public readonly filterType: FilterType;
  public readonly valueType: FilterValueType;
  public readonly defaultValue?: FilterValue;
  /** Renders value input component. */
  public readonly valueInput: ((props: FilterValueInputProps) => ReactNode) | undefined;
  public readonly onChange?: (newValue: FilterDefinitionItem) => void;

  constructor(params: {
    operator: FilterOperator;
    filterType: FilterType;
    valueType: FilterValueType;
    defaultValue?: FilterValue;
    /** Pass undefined if operator doesn't needs an input. */
    valueInput: ((props: FilterValueInputProps) => ReactNode) | undefined;
    onChange?: (newValue: FilterDefinitionItem) => void;
  }) {
    this.operator = params.operator;
    this.filterType = params.filterType;
    this.valueType = params.valueType;
    this.defaultValue = params.defaultValue;
    this.valueInput = params.valueInput;
    this.onChange = params.onChange;
  }

  /** Formats filter value according to the value type. */
  public formatValue(value: FilterValue): FilterValue {
    return FilterHelper.formatValue(this, value);
  }
}

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import VisualInspectionPaginatedList from "@/common/components/Entity/VisualInspection/ListView/VisualInspectionPaginatedList";

export default function VisualInspections() {
  return (
    <>
      <VisualInspectionPaginatedList />
    </>
  );
}

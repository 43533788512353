import { Box, ClickAwayListener, List, useMediaQuery, useTheme } from "@mui/material";
import { ReactNode, useRef } from "react";

import { NavDisplayVariants, useNavDisplay } from "@/common/contexts/useNavDisplay";

interface OwnProps {
  isNavOpened: boolean;
  toggleNav: () => void;
  titleTo?: string;
  items: ReactNode;
}

type Props = OwnProps;

function AppNav({ isNavOpened, toggleNav, titleTo, items }: Props) {
  const { navDisplay, navToggle, isControlled, navDisplayWidth } = useNavDisplay();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const topAnchorRef = useRef<HTMLElement | null>(null);
  const headerHeight = "69.125px"; // manual computed

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (isMobile) toggleNav();
      }}
    >
      <Box
        sx={{
          position: { xxs: "absolute", md: isControlled ? "relative" : "absolute" },
          top: isControlled ? 0 : headerHeight,
          left: 0,
          height: { xxs: "100%", md: isControlled ? "100%" : `calc(100% - ${headerHeight})` },
          width: { xxs: "300px", md: navDisplayWidth[navDisplay] },
          zIndex: (t) =>
            !isControlled && navDisplay === NavDisplayVariants.expanded
              ? t.zIndex.modal + 1
              : t.zIndex.modal, // ensure it's above the autocomplete popper because them use modal z-index
          background: (t) => t.palette.background.navbar,
          fontWeight: 400,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          pb: 4,
          boxShadow: (t) => (isMobile ? t.boxShadowCustom?.mobileNavShadow : "unset"),
          transition: "width 0.3s ease-in-out",
          ...theme.scrollBarCustom?.navBar(theme),
        }}
        onMouseEnter={() =>
          navToggle && navToggle({ variant: "hover", display: NavDisplayVariants.expanded })
        }
        onMouseLeave={() =>
          navToggle && navToggle({ variant: "hover", display: NavDisplayVariants.collapsed })
        }
      >
        <Box ref={topAnchorRef} sx={{ m: 0, p: 0 }}></Box>

        {/* Nav items */}
        <List
          sx={{
            p: 0,
            position: "absolute",
            top: "10px",
            width: "100%",
            pb: 4,
          }}
        >
          {items}
        </List>
      </Box>
    </ClickAwayListener>
  );
}

export default AppNav;

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import TenantRequestPaginatedList from "@/common/components/Entity/TenantRequest/ListView/TenantRequestPaginatedList";

export default function TenantRequestsPage() {
  return (
    <>
      <TenantRequestPaginatedList
        displayProps={{
          breadcrumbs: true,
          filters: true,
        }}
      />
    </>
  );
}

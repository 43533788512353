import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ContractAssessmentFlowStateFilterTypeIcon from "@/App/MainAppView/Flows/Assessment/components/ContractAssessmentFlowStateFilterTypeIcon";
import SendAssessmentFlowToCustomerModal from "@/App/MainAppView/Flows/Assessment/components/SendAssessmentFlowToCustomerModal";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import GeneralEventLogsModal from "@/common/components/Entity/GeneralEventLog/GeneralEventLogsModal";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppTypography from "@/common/components/Text/AppTypography";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { renderIf } from "@/common/helpers/render/renderIf";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { usePropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import {
  AppPermission,
  AssessmentFlowDto,
  ChatType,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
} from "@/core/api/generated";
import _ from "lodash";
import AccessoryCheckView from "../../AccessoryCheck/View/AccessoryCheckView";
import ChatActivator from "../../Chat/ChatActivator";
import ContractBlock from "../../Contract/ContractBlock";
import ContractCustomerCommunicationInfo from "../../Contract/ContractCustomerCommunicationInfo";
import ContractCustomerCommunicationInfoModal from "../../Contract/ContractCustomerCommunicationInfoModal";
import ContractReminderSettingsDisplay from "../../Contract/ContractReminderSettingsDisplay";
import ContractReminderSettingsModal from "../../Contract/ContractReminderSettingsModal";
import CreateContractCustomerCommunicationEntryModal from "../../Contract/CreateContractCustomerCommunicationEntryModal";
import CustomerBlock from "../../Customer/CustomerBlock";
import DamageCostEvaluationAggregateView from "../../DamageCostEvaluationAggregate/View/DamageCostEvaluationAggregateView";
import DamageDetectionAggregateView from "../../DamageDetectionAggregate/View/DamageDetectionAggregateView";
import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import NegotiationProposalComputedStatusIconsStrip from "../../Negotiation/NegotiationProposal/NegotiationProposalComputedStatusIconsStrip";
import VehicleBlock from "../../Vehicle/VehicleBlock";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import { AssessmentFlowRelatedTenantRequestsPropagatedDepsMap } from "../AssessmentFlowRelatedTenantRequests";
import AssessmentFlowRelatedTenantRequestsForDamageCostEvaluation from "../AssessmentFlowRelatedTenantRequestsForDamageCostEvaluation";
import SendDamagesToAssessmentModal from "../SendDamagesToAssessmentModal";

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
};

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

interface OwnProps extends BaseEntityViewInheritableProps<AssessmentFlowDto> {
  assessmentFlowId: string | null | undefined;
  assessmentFlow: AssessmentFlowDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function AssessmentFlowView({
  assessmentFlowId,
  assessmentFlow,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const currentTenant = useCurrentTenant();
  const { hasFleetAppAccess, hasFleetCustomerAppAccess } = useAuthorizationInfo();
  const tenantRequestsForDamageCostEvaluationPropagatedDeps =
    usePropagatedDeps<AssessmentFlowRelatedTenantRequestsPropagatedDepsMap>();

  // menu, dialogs
  const [isSendToCustomerModalOpen, setIsSendToCustomerModalOpen] = useState(false);
  const [isCreateCommunicationEntryModalOpen, setIsCreateCommunicationEntryModalOpen] =
    useState(false);
  const [isCommunicationInfoModalOpen, setIsCommunicationInfoModalOpen] = useState(false);
  const [isContractRemindersModalOpen, setIsContractRemindersModalOpen] = useState(false);
  const [isEventLogModalOpen, setIsEventLogModalOpen] = useState(false);
  const [isSendToAssessmentModalOpen, setIsSendToAssessmentModalOpen] = useState(false);

  const assessmentFlowRequest = useApiRequest(
    apiClient.assessmentFlowsApi.apiV1FlowsAssessmentFlowsAssessmentFlowIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assessmentFlowId: assessmentFlowId!,
    },
    {
      deps: [assessmentFlowId],
      skip: !assessmentFlowId,
      // use polling to ensure user will get fresh data after background operations completed.
      // shortPolling: {
      //   intervalMs: 5000,
      //   if: (context) =>
      //     context.data?.state?.isProcessingDamages ||
      //     context.data?.state?.isProcessingDamageCosts ||
      //     context.data?.state?.isDamageCostEvaluating ||
      //     false,
      // },
    },
  );
  assessmentFlow = assessmentFlow || assessmentFlowRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.AssessmentFlow,
        assessmentFlowId || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      // refetch as DTO contains additional computed fields
      assessmentFlowRequest.refetch();
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        assessmentFlow && {
          idValue: assessmentFlow.id!,
          newTitle: `${assessmentFlow?.localNumber} (contract ${
            assessmentFlow?.contract?.externalNumber || assessmentFlow?.contract?.localNumber
          })`,
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.AssessmentFlow}
      entityId={assessmentFlowId}
      entity={assessmentFlow}
      entityRequest={assessmentFlowRequest}
    >
      <ViewLayout
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={
                <>
                  {renderIf()
                    .if(hasFleetAppAccess)
                    .then(<span>Assessment</span>)
                    .elseif(hasFleetCustomerAppAccess)
                    .then(<span>Vehicle assessment</span>)
                    .render()}
                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    <ContractAssessmentFlowStateFilterTypeIcon
                      assessmentFlowId={assessmentFlow?.id || ""}
                      value={assessmentFlow?.state?.filterState}
                      isEnableStateRecompute
                      fontSize='medium'
                      withTooltip
                      onUpdated={(newValue) => assessmentFlowRequest.replaceData(newValue)}
                      sx={{ ml: 1 }}
                    />
                  </AuthorizedElement>
                </>
              }
              titleProps={{
                to: headerProps?.withLink
                  ? ROUTE_PATH.CONTRACT_VIEW(assessmentFlow?.id)
                  : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              subtitle={
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <GeneralAttachedTagsDisplay
                    tags={assessmentFlow?.tags}
                    defaultIsFolded={false}
                    edit={{
                      entityType: TagEntityType.AssessmentFlow,
                      entityId: assessmentFlow?.id,
                      onSaved: (newValue) => {
                        assessmentFlowRequest.updateData((x) => {
                          x.tags = newValue || undefined;
                        });
                      },
                    }}
                  />
                </AuthorizedElement>
              }
              primaryActions={
                assessmentFlow && (
                  <Button
                    sx={{ ml: "auto" }}
                    component={RouterLink}
                    to={ROUTE_PATH.ASSESSMENT_FLOW_CHAT_VIEW(assessmentFlow.id, {
                      chatId: assessmentFlow?.chatId,
                    })}
                    variant='text'
                    color='secondary'
                    size='medium'
                    startIcon={
                      <ChatActivator
                        chatType={ChatType.Negotiation}
                        chatId={assessmentFlow.chatId || ""}
                        chatPlacement='custom'
                        to={undefined}
                        withActivityIndicator
                        tooltipTitle='Open chat'
                        activatorButtonProps={{
                          disableRipple: true,
                        }}
                        sx={{ ml: 1 }}
                      />
                    }
                  >
                    Chat
                  </Button>
                )
              }
              secondaryActions={
                displayProps?.actions && (
                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    <MenuWithTrigger
                      withAuthCloseOnClick
                      trigger={
                        <IconButton sx={{ ml: "auto" }}>
                          <AppIcon of='moreVert' />
                        </IconButton>
                      }
                    >
                      <MenuItem
                        component={RouterLink}
                        to={ROUTE_PATH.CONTRACT_VIEW(assessmentFlow?.contract?.id)}
                      >
                        <ListItemIcon>
                          <AppIcon of='contract' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>View contract</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => setIsSendToCustomerModalOpen(true)}>
                        <ListItemIcon>
                          <AppIcon of='email' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Send to customer</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => setIsCommunicationInfoModalOpen(true)}>
                        <ListItemIcon>
                          <AppIcon of='contacted' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Customer communication</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => setIsContractRemindersModalOpen(true)}>
                        <ListItemIcon>
                          <AppIcon of='reminder' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Contract reminders</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setIsEventLogModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='history' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Event log (contract)</ListItemText>
                      </MenuItem>
                      <ListItem sx={{ pt: 0 }}>
                        <ListItemText
                          sx={{ m: 0, p: 0 }}
                          primary={
                            <Typography
                              component='div'
                              variant='body2'
                              color='text.secondary'
                              sx={{ m: 0, p: 0 }}
                            >
                              Quick actions
                            </Typography>
                          }
                        />
                      </ListItem>
                      <AuthorizedElement
                        permissions={[
                          AppPermission.DamageDetectionPerform,
                          AppPermission.DamageDetectionManage,
                        ]}
                      >
                        <MenuItem
                          component={RouterLink}
                          to={ROUTE_PATH.DAMAGE_DETECTION_CREATE({
                            vehicleId: assessmentFlow?.vehicle?.id,
                            contractId: assessmentFlow?.contract?.id,
                          })}
                        >
                          <ListItemIcon>
                            <AppIcon of='damageDetection' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>New damage detection</ListItemText>
                        </MenuItem>
                      </AuthorizedElement>
                      <AuthorizedElement
                        permissions={[
                          AppPermission.DamageCostEvaluationPerform,
                          AppPermission.DamageCostEvaluationManage,
                        ]}
                      >
                        <MenuItem
                          component={RouterLink}
                          to={ROUTE_PATH.DAMAGE_COST_EVALUATION_CREATE({
                            vehicleId: assessmentFlow?.vehicle?.id,
                            contractId: assessmentFlow?.contract?.id,
                          })}
                        >
                          <ListItemIcon>
                            <AppIcon of='damageCostEvaluation' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>New damage cost evaluation</ListItemText>
                        </MenuItem>
                      </AuthorizedElement>
                      <AuthorizedElement
                        permissions={[
                          AppPermission.AccessoryCheckPerform,
                          AppPermission.AccessoryCheckManage,
                        ]}
                      >
                        <MenuItem
                          component={RouterLink}
                          to={ROUTE_PATH.ACCESSORY_CHECK_CREATE({
                            vehicleId: assessmentFlow?.vehicle?.id,
                            contractId: assessmentFlow?.contract?.id,
                          })}
                        >
                          <ListItemIcon>
                            <AppIcon of='accessoryCheck' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>New accessory check</ListItemText>
                        </MenuItem>
                      </AuthorizedElement>
                    </MenuWithTrigger>
                  </AuthorizedElement>
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <Stack direction='column' spacing={2}>
            {assessmentFlow && (
              <>
                {/* Contract and vehicle data */}
                <Stack direction={{ xxs: "column", md: "row" }} spacing={{ xxs: 2, md: 2 }}>
                  <VehicleBlock
                    sx={{
                      flex: 1,
                    }}
                    variant='full'
                    vehicle={assessmentFlow?.vehicle}
                    withDetailsToggle={true}
                    withLink
                  />
                  <ContractBlock
                    sx={{
                      flex: 1,
                    }}
                    variant='full'
                    contract={assessmentFlow?.contract}
                    briefDataProps={{
                      vehicle: false,
                      customer: false,
                    }}
                    detailedDataProps={{
                      vehicle: false,
                      customer: false,
                    }}
                    withDetailsToggle={true}
                    withLink
                  />
                  <CustomerBlock
                    sx={{
                      flex: 1,
                    }}
                    variant='full'
                    customer={assessmentFlow?.customer}
                    briefDataProps={{}}
                    detailedDataProps={{}}
                    withDetailsToggle={true}
                    withLink
                  />
                </Stack>

                {/* Statuses */}
                <Grid sx={{ mb: 2 }} container columnSpacing={2} rowSpacing={1}>
                  <Grid item xxs={12} md minWidth={200}>
                    <Stack direction='column' spacing={1}>
                      <FieldValue label='Damage status'>
                        <InlineApiEnumValue
                          type='DamageDetectionStatusInContract'
                          value={assessmentFlow?.state?.damageDetectionStatus}
                          withDescription
                        />
                      </FieldValue>
                      <FieldValue
                        label='Damage negotiations status'
                        helperTooltip='Statuses of negotiations on the damage'
                      >
                        {assessmentFlow && assessmentFlow.negotiationRefs && (
                          <Stack direction='row' sx={{ alignItems: "center" }}>
                            &nbsp;
                            <NegotiationProposalComputedStatusIconsStrip
                              negotiationRefs={assessmentFlow.negotiationRefs.filter(
                                (x) => assessmentFlow!.damageNegotiationIdsMap![x.id!],
                              )}
                              to={ROUTE_PATH.ASSESSMENT_FLOW_CHAT_VIEW(assessmentFlow.id)}
                            />
                          </Stack>
                        )}
                      </FieldValue>
                    </Stack>
                  </Grid>
                  <Grid item xxs={12} md minWidth={200}>
                    <Stack direction='column' spacing={1}>
                      <FieldValue label='Damage cost status'>
                        <InlineApiEnumValue
                          type='DamageCostEvaluationStatusInContract'
                          value={assessmentFlow?.state?.damageCostEvaluationStatus}
                          withDescription
                        />
                      </FieldValue>
                      <FieldValue
                        label='Damage cost negotiations status'
                        helperTooltip='Statuses of negotiations on the damage cost'
                      >
                        {assessmentFlow && assessmentFlow?.negotiationRefs && (
                          <Stack direction='row' sx={{ alignItems: "center" }}>
                            &nbsp;
                            <NegotiationProposalComputedStatusIconsStrip
                              negotiationRefs={assessmentFlow.negotiationRefs.filter(
                                (x) => assessmentFlow!.damageCostNegotiationIdsMap![x.id!],
                              )}
                              to={ROUTE_PATH.ASSESSMENT_FLOW_CHAT_VIEW(assessmentFlow.id)}
                            />
                          </Stack>
                        )}
                      </FieldValue>
                    </Stack>
                  </Grid>
                </Grid>
                {/* Affiliation */}
                <Stack>
                  <AppTypography variant='h6'>Affiliation</AppTypography>
                  <AffiliationInfoDisplay
                    tenantId={assessmentFlow.tenantId}
                    departmentIds={assessmentFlow.departmentIds}
                    locationIds={assessmentFlow.locationIds}
                    sx={{ mt: 1 }}
                  />
                </Stack>

                {/* Customer communication */}
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <Grid sx={{ mb: 2 }} container rowSpacing={1}>
                    <Grid item xxs={12} md={12}>
                      <Stack direction='row' justifyContent='space-between'>
                        <Typography component='div' variant='h6'>
                          Customer communication
                        </Typography>

                        <Stack direction='row' spacing={1} alignItems='center'>
                          <Button
                            variant='outlined'
                            color='secondary'
                            size='small'
                            onClick={() => setIsCreateCommunicationEntryModalOpen(true)}
                          >
                            Customer contacted
                          </Button>

                          <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                            <MenuWithTrigger
                              withAuthCloseOnClick
                              trigger={
                                <IconButton sx={{ ml: "auto" }}>
                                  <AppIcon of='moreVert' />
                                </IconButton>
                              }
                            >
                              <MenuItem onClick={() => setIsCommunicationInfoModalOpen(true)}>
                                <ListItemIcon>
                                  <AppIcon of='history' fontSize='small' />
                                </ListItemIcon>
                                <ListItemText>View communication entries</ListItemText>
                              </MenuItem>
                            </MenuWithTrigger>
                          </AuthorizedElement>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xxs={12} md={12}>
                      {assessmentFlow?.contract && (
                        <ContractCustomerCommunicationInfo
                          contract={assessmentFlow.contract}
                          displayProps={{ overview: true, createNewEntry: false, entries: false }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </AuthorizedElement>

                {/* Reminders */}
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <Grid sx={{ mb: 2 }} container rowSpacing={1}>
                    <Grid item xxs={12} md={12}>
                      <Typography component='div' variant='h6'>
                        Reminders
                      </Typography>
                    </Grid>
                    <Grid item xxs={12} md={12}>
                      {assessmentFlow?.contract && (
                        <ContractReminderSettingsDisplay
                          contract={assessmentFlow.contract}
                          onUpdate={(newValue) => {
                            assessmentFlowRequest.updateData((fetchedData) => {
                              fetchedData.contract = newValue.contract;
                            });
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </AuthorizedElement>

                <Stack direction='column' spacing={2}>
                  {/* DDA */}
                  <Card>
                    <CardContent>
                      {assessmentFlow && !assessmentFlow?.damageDetectionAggregateId && (
                        <EntityNotFoundAlert
                          title='No damage detections yet'
                          icon={<AppIcon of='damageDetection' fontSize='medium' />}
                        />
                      )}
                      {assessmentFlow?.damageDetectionAggregateId && (
                        <DamageDetectionAggregateView
                          aggregateId={assessmentFlow?.damageDetectionAggregateId}
                          withRealtimeDataUpdates
                          headerProps={{
                            withLink: true,
                            typographyProps: {
                              variant: "h6",
                            },
                            primaryActions: (aggregate) => (
                              <>
                                {!_.isEmpty(aggregate?.items) && (
                                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                                    <Button
                                      variant='outlined'
                                      color='secondary'
                                      size='medium'
                                      startIcon={<AppIcon of='send' />}
                                      onClick={() => {
                                        setIsSendToAssessmentModalOpen(true);
                                      }}
                                    >
                                      Send to assessment
                                    </Button>
                                  </AuthorizedElement>
                                )}
                              </>
                            ),
                          }}
                          displayProps={{
                            vehicle: false,
                            contract: false,
                          }}
                          customSections={{
                            afterHeader: assessmentFlow?.state?.isProcessingDamages && (
                              <Alert severity='info'>
                                <AlertTitle>
                                  Damages are being processed at the moment{" "}
                                  <CircularProgress color='secondary' size={14} />
                                </AlertTitle>
                                <Box>
                                  <Typography component='div' variant='body1'>
                                    The page will update automatically in short time. Please wait
                                    for a moment.
                                  </Typography>
                                </Box>
                              </Alert>
                            ),
                          }}
                        />
                      )}
                    </CardContent>
                  </Card>

                  {/* DCEA */}
                  <Card>
                    <CardContent>
                      <Stack spacing={2}>
                        {assessmentFlow && (
                          <AssessmentFlowRelatedTenantRequestsForDamageCostEvaluation
                            assessmentFlow={assessmentFlow}
                            propagatedDeps={tenantRequestsForDamageCostEvaluationPropagatedDeps}
                          />
                        )}

                        {assessmentFlow && !assessmentFlow?.damageCostEvaluationAggregateId && (
                          <EntityNotFoundAlert
                            title='No damage cost evaluations yet'
                            icon={<AppIcon of='damageCostEvaluation' fontSize='medium' />}
                          />
                        )}
                        {assessmentFlow?.damageCostEvaluationAggregateId && (
                          <DamageCostEvaluationAggregateView
                            aggregateId={assessmentFlow?.damageCostEvaluationAggregateId}
                            withRealtimeDataUpdates
                            headerProps={{
                              withLink: true,
                              typographyProps: {
                                variant: "h6",
                              },
                            }}
                            displayProps={{
                              vehicle: false,
                              contract: false,
                            }}
                            customSections={{
                              afterHeader: assessmentFlow?.state?.isDamageCostEvaluating && (
                                <Alert severity='info'>
                                  <AlertTitle>
                                    Damage costs are being evaluated at the moment.
                                  </AlertTitle>
                                  <Box>
                                    <Typography component='div' variant='body1'>
                                      As soon as Damage Cost Evaluations will be completed the info
                                      will appear here.
                                    </Typography>
                                  </Box>
                                </Alert>
                              ),
                            }}
                          />
                        )}
                      </Stack>
                    </CardContent>
                  </Card>

                  {/* AC */}
                  {assessmentFlow &&
                    (!assessmentFlow?.accessoryCheckIds ||
                      assessmentFlow?.accessoryCheckIds.length === 0) && (
                      <Card>
                        <CardContent>
                          <EntityNotFoundAlert
                            title='No accessory checks yet'
                            icon={<AppIcon of='accessoryCheck' fontSize='medium' />}
                          />
                        </CardContent>
                      </Card>
                    )}
                  {assessmentFlow?.accessoryCheckIds?.map((accessoryCheckId) => (
                    <Card key={accessoryCheckId!}>
                      <CardContent>
                        <AccessoryCheckView
                          accessoryCheckId={accessoryCheckId}
                          withRealtimeDataUpdates
                          headerProps={{
                            withLink: true,
                            titleTypographyProps: {
                              variant: "h6",
                            },
                          }}
                          displayProps={{
                            breadcrumbs: false,
                            vehicle: false,
                            contract: false,
                            viewVariant: ViewLayoutVariant.Tab,
                          }}
                        />
                      </CardContent>
                    </Card>
                  ))}
                </Stack>
              </>
            )}

            {/* Send to customer */}
            {assessmentFlow && (
              <SendAssessmentFlowToCustomerModal
                assessmentFlow={assessmentFlow}
                open={isSendToCustomerModalOpen}
                onClose={() => setIsSendToCustomerModalOpen(false)}
              />
            )}

            {/* Customer contacted */}
            {assessmentFlow?.contract && (
              <CreateContractCustomerCommunicationEntryModal
                createEntryProps={{
                  contractId: assessmentFlow.contract.id!,
                  onSave: () => setIsCreateCommunicationEntryModalOpen(false),
                }}
                open={isCreateCommunicationEntryModalOpen}
                onClose={() => setIsCreateCommunicationEntryModalOpen(false)}
              />
            )}

            {/* Communication info */}
            {assessmentFlow?.contract && (
              <ContractCustomerCommunicationInfoModal
                contractCustomerCommunicationInfoProps={{
                  contract: assessmentFlow.contract,
                }}
                open={isCommunicationInfoModalOpen}
                onClose={() => setIsCommunicationInfoModalOpen(false)}
              />
            )}

            {/* Reminders */}
            {assessmentFlow?.contract && (
              <ContractReminderSettingsModal
                reminderSettingsDisplayProps={{
                  contract: assessmentFlow.contract,
                  onUpdate: (newValue) => {
                    assessmentFlowRequest.updateData((fetchedData) => {
                      fetchedData.contract = newValue.contract;
                    });
                  },
                }}
                open={isContractRemindersModalOpen}
                onClose={() => setIsContractRemindersModalOpen(false)}
              />
            )}

            {/* Event log */}
            {assessmentFlow && (
              <GeneralEventLogsModal
                open={isEventLogModalOpen}
                onClose={() => setIsEventLogModalOpen(false)}
                entityId={assessmentFlow.contract!.id!}
              />
            )}

            {/* Send to assessment (render only if open to ensure fresh data is loaded) */}
            {assessmentFlow && isSendToAssessmentModalOpen && (
              <SendDamagesToAssessmentModal
                open={isSendToAssessmentModalOpen}
                onClose={() => setIsSendToAssessmentModalOpen(false)}
                assessmentFlow={assessmentFlow}
                onSent={(newValue) => {
                  tenantRequestsForDamageCostEvaluationPropagatedDeps.trigger("refetch");
                }}
              />
            )}
          </Stack>
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}

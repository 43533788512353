import { LoadingButton } from "@mui/lab";
import { FormControl, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import AppBreadcrumbs from "@/common/components/AppBreadcrumbs";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import PermissionsSelector from "@/common/components/Permission/PermissionsSelector";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AdminCreateGlobalRoleDto, AdminUpdateGlobalRoleDto } from "@/core/api/generated";

export default function RoleCreateUpdatePage() {
  const { roleId } = useParams<{ roleId?: string }>();
  const mounted = useMounted();
  const isCreate = !roleId;
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();

  const roleRequest = useApiRequest(
    apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesRoleIdGet,
    {
      roleId: roleId!,
    },
    {
      skip: !roleId,
    },
  );
  const role = roleRequest?.data;

  return (
    <Formik<BaseFormikValues & AdminCreateGlobalRoleDto & AdminUpdateGlobalRoleDto>
      enableReinitialize={!isCreate}
      initialValues={{
        name: role?.name || undefined,
        description: role?.description || undefined,
        permissions: role?.permissions || undefined,
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // name: Yup.string().required("Name is required"),
        // description: Yup.string().required("Description is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          if (isCreate) {
            await apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesPost({
              adminCreateGlobalRoleDto: {
                name: values.name,
                description: values.description,
                permissions: values.permissions,
              },
            });
            enqueueSnackbar("Role created.", { variant: "success" });
          } else {
            await apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesRoleIdPut({
              roleId,
              adminUpdateGlobalRoleDto: {
                name: values.name,
                description: values.description,
                permissions: values.permissions,
              },
            });
            enqueueSnackbar("Role updated.", { variant: "success" });
          }

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
          history.goBack();
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <CreateUpdatePageLayout
            header={
              <SimpleViewPageHeader
                title={isCreate ? "Create new global role" : "Edit global role"}
              />
            }
          >
            <form noValidate onSubmit={handleSubmit}>
              <AppBreadcrumbs />

              <Box>
                <TextField
                  autoFocus
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='dense'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name || ""}
                  variant='outlined'
                />

                <TextField
                  autoFocus
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  label='Description'
                  margin='dense'
                  name='description'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.description || ""}
                  variant='outlined'
                />

                <FormControl margin='dense'>
                  <Typography component='div' variant='subtitle1'>
                    Permissions
                  </Typography>

                  <PermissionsSelector
                    getPermissions={async () => {
                      const response =
                        await apiClient.adminAccountApi.apiV1AdminAccountGlobalPermissionsGet();
                      return response.data;
                    }}
                    permissions={values.permissions}
                    onChange={(newValue) => {
                      setFieldValue("permissions", newValue);
                    }}
                  />
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                disabled={!values.name}
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </form>
          </CreateUpdatePageLayout>
        );
      }}
    </Formik>
  );
}

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { ContractDto, EntityType } from "@/core/api/generated";
import GeneralHistoryPaginatedList from "../../../GeneralHistory/ListView/GeneralHistoryPaginatedList";

interface Props {
  contract: ContractDto;
}

export default function GeneralHistoryTabContent({ contract }: Props) {
  return (
    <PageTabContent>
      <GeneralHistoryPaginatedList
        defaultValues={{
          subjectEntityType: EntityType.Contract,
          subjectEntityId: contract.id,
        }}
        displayProps={{
          viewVariant: ViewLayoutVariant.Tab,
        }}
      />
    </PageTabContent>
  );
}

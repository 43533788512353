import { Box, Chip, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { PoolItemDto, PoolItemSearchPaginatedDto } from "@/core/api/generated";
import _ from "lodash";
import GeneralStrictEntityRelationInline from "../General/Display/GeneralStrictEntityRelationInline";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import PoolItemIcon from "./PoolItemIcon";
import { enumService } from "@/common/services/enum";
import { getAccessoryOptionTitle } from "../Accessory/AccessoryAutocomplete";
import { getVehicleOptionTitle } from "../Vehicle/VehicleAutocomplete";
import { getAssetOptionTitle } from "../Asset/AssetAutocomplete";

type PoolItemAutocompleteOption = BaseAutocompleteOption<PoolItemDto>;

export const getPoolItemOptionTitle = (data: PoolItemDto): string => {
  const computedTitle =
    (data.entity?.accessory && getAccessoryOptionTitle(data.entity.accessory)) ||
    (data.entity?.accessoryFullRef && getAccessoryOptionTitle(data.entity.accessoryFullRef)) ||
    (data.entity?.vehicle && getVehicleOptionTitle(data.entity.vehicle)) ||
    (data.entity?.vehicleFullRef && getVehicleOptionTitle(data.entity.vehicleFullRef)) ||
    (data.entity?.asset && getAssetOptionTitle(data.entity.asset)) ||
    (data.entity?.assetFullRef && getAssetOptionTitle(data.entity.assetFullRef)) ||
    StringHelper.joinIntoString(
      [
        data.entity?.nameComputed,
        enumService.getEnumValueName("PoolItemEntityType", data.entityType),
        enumService.getEnumValueName("PoolItemType", data.type),
        enumService.getEnumValueName("PoolItemStatus", data.status),
      ],
      ", ",
      {
        skipEmpty: true,
      },
    );

  return `${computedTitle} (${enumService.getEnumValueName(
    "EntityType",
    data.entityType || data.entity?.entityType,
  )})`;
};

export const entityToOption = (data: PoolItemDto): PoolItemAutocompleteOption => ({
  id: data.id!,
  title: getPoolItemOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface PoolItemAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<PoolItemDto> {
  searchFilters: { poolId: string } & Partial<
    Omit<PoolItemSearchPaginatedDto, "search" | "includeIds">
  >;
}

export default function PoolItemAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: PoolItemAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={entityToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.poolItemsApi.apiV1PoolsPoolIdItemsSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          poolId: searchFilters.poolId,
          poolItemSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          poolItemSearchPaginatedDto: {
            ...params.poolItemSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Pool item'
      placeholder='Search for pool item...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<PoolItemIcon entity={option.data} />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    <GeneralStrictEntityRelationInline
                      value={option?.data?.entity}
                      inlineProps={{ withIcon: false }}
                    />
                  </Typography>
                </>
              }
              secondary={
                <Typography component='div' variant='body2' color='secondary'>
                  {option.data && (
                    <Box>
                      <Stack direction='row' spacing={1}>
                        <Chip
                          variant='outlined'
                          size='small'
                          color='secondary'
                          label={
                            <InlineApiEnumValue type='PoolItemType' value={option.data.type} />
                          }
                        />
                        {option.data.entityType && (
                          <Chip
                            variant='outlined'
                            size='small'
                            color='secondary'
                            label={
                              <InlineApiEnumValue
                                type='PoolItemEntityType'
                                value={option.data.entityType}
                              />
                            }
                          />
                        )}
                        <Chip
                          variant='outlined'
                          size='small'
                          color='secondary'
                          label={
                            <InlineApiEnumValue type='PoolItemStatus' value={option.data.status} />
                          }
                        />
                      </Stack>
                    </Box>
                  )}
                </Typography>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

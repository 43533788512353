import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import { StringHelper } from "@/common/helpers/string";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { Avatar } from "@mui/material";
import { ReactNode, useMemo } from "react";

interface Props {
  primaryActions?: ReactNode;
  secondaryActions?: ReactNode;
}

export default function ProfilePageHeader({ primaryActions, secondaryActions }: Props) {
  const profileRequest = useApiRequest(apiClient.profileApi.apiV1ProfileGet, {
    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
  });
  const profile = useMemo(() => profileRequest.data, [profileRequest.data]);

  return (
    <>
      <DetailedViewPageHeader
        image={
          profile && (
            <Avatar
              sx={{ width: 164, height: 164 }}
              alt={profile?.personName?.name || ""}
              src={profile?.avatar?.file?.url || ""}
            />
          )
        }
        title={<>{profile?.personName?.name}</>}
        subtitle={StringHelper.joinIntoString([profile?.email, profile?.phoneNumber], " • ", {
          skipEmpty: true,
        })}
        primaryActions={primaryActions}
        secondaryActions={secondaryActions}
      />
    </>
  );
}

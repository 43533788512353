import UserSecretInput from "@/common/components/Form/Input/UserSecretInput";
import { CustomFormikSubProps } from "@/common/helpers/formik";
import { ValidationHelper } from "@/common/validation";
import { SmtpEmailSettingsInputDto } from "@/core/api/generated";
import {
  FormControl,
  FormControlLabel,
  Stack,
  SxProps,
  TextField,
  Theme,
  Checkbox,
  FormHelperText,
  Button,
  Box,
} from "@mui/material";

interface Props {
  formikProps: CustomFormikSubProps<SmtpEmailSettingsInputDto | null | undefined>;
  sx?: SxProps<Theme>;
}

export default function SmtpInputs({ formikProps, sx }: Props) {
  const { values, errors, setFieldValue, setValues } = formikProps;

  return (
    <Stack spacing={2} sx={sx}>
      {/* From */}
      <FormControl margin='dense' fullWidth>
        <TextField
          required
          label='From'
          placeholder='Enter email'
          variant='outlined'
          autoComplete='new-password'
          error={Boolean(errors?.from)}
          helperText={ValidationHelper.getFormikErrorsAsString(errors?.from)}
          value={values?.from || ""}
          onChange={(e) => setFieldValue("from", e.target.value || undefined)}
        />
      </FormControl>

      {/* FromName */}
      <FormControl margin='dense' fullWidth>
        <TextField
          required
          label='From Name'
          variant='outlined'
          autoComplete='new-password'
          error={Boolean(errors?.fromName)}
          helperText={ValidationHelper.getFormikErrorsAsString(errors?.fromName)}
          value={values?.fromName || ""}
          onChange={(e) => setFieldValue("fromName", e.target.value || undefined)}
        />
      </FormControl>

      {/* ReplyTo */}
      <FormControl margin='dense' fullWidth>
        <TextField
          label='Reply to'
          placeholder='Enter email'
          variant='outlined'
          autoComplete='new-password'
          error={Boolean(errors?.replyTo)}
          helperText={ValidationHelper.getFormikErrorsAsString(errors?.replyTo)}
          value={values?.replyTo || ""}
          onChange={(e) => setFieldValue("replyTo", e.target.value || undefined)}
        />
      </FormControl>

      {/* Host */}
      <FormControl margin='dense' fullWidth>
        <TextField
          required
          label='Host'
          variant='outlined'
          error={Boolean(errors?.host)}
          helperText={ValidationHelper.getFormikErrorsAsString(errors?.host)}
          value={values?.host || ""}
          onChange={(e) => setFieldValue("host", e.target.value || undefined)}
        />
      </FormControl>

      {/* Port */}
      <FormControl margin='dense' fullWidth>
        <TextField
          required
          label='Port'
          inputMode='numeric'
          variant='outlined'
          error={Boolean(errors?.port)}
          helperText={ValidationHelper.getFormikErrorsAsString(errors?.port)}
          value={values?.port || ""}
          onChange={(e) => setFieldValue("port", e.target.value || undefined)}
        />
      </FormControl>

      {/* Username */}
      <FormControl margin='dense' fullWidth>
        <TextField
          required
          label='Username'
          variant='outlined'
          error={Boolean(errors?.username)}
          helperText={ValidationHelper.getFormikErrorsAsString(errors?.username)}
          value={values?.username || ""}
          onChange={(e) => setFieldValue("username", e.target.value || undefined)}
        />
      </FormControl>

      {/* Password */}
      <FormControl margin='dense' fullWidth>
        <UserSecretInput
          required
          label='Password'
          value={values?.password}
          error={Boolean(errors?.password)}
          helperText={ValidationHelper.getFormikErrorsAsString(errors?.password)}
          onChange={(newValue) => setFieldValue("password", newValue)}
        />
      </FormControl>

      <FormControl margin='dense'>
        <Box>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              setValues(undefined);
            }}
          >
            Reset
          </Button>
        </Box>
      </FormControl>

      <FormHelperText error>
        {ValidationHelper.getFormikErrorsAsString(errors, { isIncludeNested: false })}
      </FormHelperText>
    </Stack>
  );
}

import { Box } from "@mui/material";
import { Switch } from "react-router";

import { RoutePathBuilder } from "@/common/builders/routePath";
import AppRoute from "@/common/components/Route/AppRoute";
import { ROUTE_PATH } from "../../common/constants/routing";
import AppGuards from "../BaseApp/AppGuards/AppGuards";
import GlobalAlerts from "../BaseApp/GlobalAlerts/GlobalAlerts";
import AdminAppContent from "./AdminAppContent";
import BaseAppContent from "./BaseAppContent";
import FleetAppContent from "./FleetAppContent";
import FleetCustomerAppContent from "./FleetCustomerAppContent";

// eslint-disable-next-line react/display-name
// const Loadable = (Component: React.ComponentType) => (props: any) =>
//   (
//     <Suspense fallback={<CircularProgress />}>
//       <Component {...props} />
//     </Suspense>
//   );

// const Login = Loadable(lazy(() => import("../Auth/Login/Login.container")));

export default function Content() {
  return (
    <>
      <AppGuards />

      {/* Global alerts */}
      <Box sx={{ width: "100%" }}>
        <GlobalAlerts />
      </Box>

      <Switch>
        {/* FleetApp area */}
        <AppRoute
          path={RoutePathBuilder.toDefinition(ROUTE_PATH.APP_AREA_FLEET_APP)}
          render={() => <FleetAppContent />}
        />

        {/* FleetCustomerApp area */}
        <AppRoute
          path={RoutePathBuilder.toDefinition(ROUTE_PATH.APP_AREA_FLEET_CUSTOMER)}
          render={() => <FleetCustomerAppContent />}
        />

        {/* AdminApp area */}
        <AppRoute
          path={RoutePathBuilder.toDefinition(ROUTE_PATH.APP_AREA_ADMIN)}
          render={() => <AdminAppContent />}
        />

        {/* Base app (basic routes like auth, errors, etc) */}
        <BaseAppContent />

        {/* <AppRoute exact path='/' render={() => <RootRouteEntry />} /> */}

        {/* <AppRoute path="*" render={() => <NotFound />} /> */}
      </Switch>
    </>
  );
}

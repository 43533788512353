import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  Divider,
  FormControl,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import _ from "lodash";
import { Moment } from "moment";
import { useCallback, useEffect, useRef, useState } from "react";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { DATETIME_FORMATS } from "@/common/constants/common";
import { useChatParticipants } from "@/common/hooks/communication/useChatParticipants";
import * as chatActivitiesSlice from "@/store/communication/chatActivitySlice";

import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import AppIcon from "../../Icons/AppIcon";
import MenuWithTrigger from "../../Menu/MenuWithTrigger";
import UpdateChatUserSettingsModal from "../ChatUserSettings/UpdateChatUserSettingsModal";
import ChatActivityListItem from "./ChatActivityListItem";

export interface OwnProps {
  chatId: string;
}

type Props = OwnProps &
  DialogProps & {
    isPaginatedLoading?: boolean;
  };

export default function ChatActivitiesModal({ chatId, isPaginatedLoading, ...dialogProps }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const { participants, participantsMap } = useChatParticipants(chatId);
  const topAnchorRef = useRef<HTMLElement | null>(null);
  const prevOpenRef = useRef(false);
  const thunkDispatch = useAppThunkDispatch();
  const paginatedChatActivities = useAppSelector(
    (x) => x.communication.chatActivities.paginatedActivities,
  );

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [from, setFrom] = useState<Moment | undefined | null>(null);
  const [to, setTo] = useState<Moment | undefined | null>(null);

  const [isChatUserSettingsModalOpen, setIsChatUserSettingsModalOpen] = useState(false);

  const getChatActivitiesWithParams = useCallback(() => {
    thunkDispatch(
      chatActivitiesSlice.getChatActivities({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        chatId,
        chatActivityGetPaginatedDto: {
          offset,
          limit,
          search,
          range: {
            from: from?.clone()?.utc()?.format() || undefined,
            to: to?.clone()?.utc()?.format() || undefined,
          },
        },
      }),
    );
  }, [offset, limit, search, from, to]);

  const getChatActivitiesThrottle = useCallback(_.throttle(getChatActivitiesWithParams, 1000), [
    getChatActivitiesWithParams,
  ]);
  const getChatActivitiesDebounce = useCallback(_.debounce(getChatActivitiesWithParams, 1000), [
    getChatActivitiesWithParams,
  ]);

  // load on open
  useEffect(() => {
    const isOpening = !prevOpenRef.current && dialogProps.open;
    if (isOpening) {
      getChatActivitiesThrottle();
    }
  }, [prevOpenRef.current, dialogProps.open]);
  useEffect(() => {
    if (dialogProps.open) {
      getChatActivitiesThrottle();
    }
  }, [offset, limit, from, to]);
  useEffect(() => {
    if (dialogProps.open) {
      getChatActivitiesDebounce();
    }
  }, [search]);

  // reset on close
  useEffect(() => {
    return () => {
      thunkDispatch(chatActivitiesSlice.resetChatActivities());
    };
  }, []);

  useEffect(() => {
    const isClosing = prevOpenRef.current && !dialogProps.open;
    if (isClosing) {
      thunkDispatch(chatActivitiesSlice.resetChatActivities());
    }
  }, [prevOpenRef.current, dialogProps.open]);

  useEffect(() => {
    prevOpenRef.current = dialogProps.open;
  }, [dialogProps.open]);

  return (
    <Box>
      <Dialog fullWidth maxWidth='sm' {...dialogProps}>
        <AppModalTitle
          onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
        >
          <Stack direction='row' spacing={1} sx={{ alignItems: "flex-start" }}>
            <Box>
              <Typography component='div' variant='h6'>
                Chat activity
              </Typography>
            </Box>

            <Stack direction='row' spacing={1} sx={{ flex: 1 }}>
              <MenuWithTrigger
                trigger={
                  <IconButton edge='end' sx={{ ml: "auto" }}>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
                withAuthCloseOnClick
              >
                <MenuItem dense onClick={() => setIsChatUserSettingsModalOpen(true)}>
                  <ListItemIcon>
                    <AppIcon of='settings' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>My chat settings</ListItemText>
                </MenuItem>
              </MenuWithTrigger>
            </Stack>
          </Stack>
        </AppModalTitle>

        <DialogContent>
          <Box>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={1}
              sx={{ alignItems: { xs: "stretch", md: "center" }, m: 1 }}
            >
              {/* <TextField
                label='Search'
          size="small"
                margin='normal'
                type='text'
                variant='outlined'
                value={search || ""}
                onChange={(e) => setSearch(e.target.value)}
                sx={{ margin: 0, minWidth: 200 }}
              /> */}

              <FormControl>
                <MobileDatePicker
                  label='From date'
                  value={from}
                  format={DATETIME_FORMATS.DISPLAY_DATE}
                  onChange={(newValue) => {
                    setFrom(newValue);
                  }}
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <IconButton onClick={() => setFrom(null)}>
                              <AppIcon of='clear' color='inherit' />
                            </IconButton>
                          ),
                        }}
                      />
                    ),
                  }}
                />
              </FormControl>

              <FormControl>
                <MobileDatePicker
                  label='To date'
                  value={to}
                  format={DATETIME_FORMATS.DISPLAY_DATE}
                  onChange={(newValue) => {
                    setTo(newValue);
                  }}
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <IconButton onClick={() => setTo(null)}>
                              <AppIcon of='clear' color='inherit' />
                            </IconButton>
                          ),
                        }}
                      />
                    ),
                  }}
                />
              </FormControl>
            </Stack>

            <Divider sx={{ my: 2 }} />

            <Box>{isPaginatedLoading && <LinearProgress sx={{ my: 1 }} />}</Box>

            <Stack direction='column' spacing={1}>
              <Box ref={topAnchorRef} sx={{ m: 0, p: 0 }}></Box>

              {paginatedChatActivities?.items?.map((item, index) => {
                return (
                  <ChatActivityListItem
                    key={index}
                    activityItem={item}
                    participant={participantsMap[item.participantId || ""]}
                  />
                );
              })}

              {paginatedChatActivities?.items && paginatedChatActivities?.items?.length !== 0 && (
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    p: 2,
                    mt: "auto !important",
                  }}
                >
                  <Pagination
                    color='primary'
                    size='large'
                    count={paginatedChatActivities?.pagination?.totalPages || 1}
                    onChange={(e, page) => {
                      setOffset((page - 1) * limit);
                      topAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
                    }}
                  />
                </Box>
              )}
            </Stack>
          </Box>
        </DialogContent>

        {/* Update chat user settings */}
        {isChatUserSettingsModalOpen && (
          <UpdateChatUserSettingsModal
            open={isChatUserSettingsModalOpen}
            onClose={() => setIsChatUserSettingsModalOpen(false)}
            updateChatUserSettingsProps={{
              chatId: chatId,
              onSave: () => setIsChatUserSettingsModalOpen(false),
            }}
          />
        )}
      </Dialog>
    </Box>
  );
}

import { LoadingButton } from "@mui/lab";
import {
  Badge,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";

import {
  GeneralStageHistoryDtoOfDamageCostEvaluationStage,
  GeneralStageHistoryItemDtoOfDamageCostEvaluationStage,
  GeneralStageInfoDtoOfDamageCostEvaluationStage,
} from "@/core/api/generated";

import { DatetimeHelper } from "../helpers/datetime";
import { TextHelper } from "../helpers/text";
import { ApiEnumName, ApiEnumValue, enumService } from "../services/enum";
import Datetime from "./Datetime/Datetime";
import InlineApiEnumValue from "./Enum/InlineApiEnumValue";
import AppIcon from "./Icons/AppIcon";
import ConfirmationModal from "./Modals/ConfirmationModal";

// make OpenAPI types generic
interface GeneralStageHistoryItemDto<TStageEnumName extends ApiEnumName>
  extends Omit<GeneralStageHistoryItemDtoOfDamageCostEvaluationStage, "stage"> {
  stage?: ApiEnumValue<TStageEnumName> | null;
}
interface GeneralStageInfoDto<TStageEnumName extends ApiEnumName>
  extends Omit<GeneralStageInfoDtoOfDamageCostEvaluationStage, "stage"> {
  stage?: ApiEnumValue<TStageEnumName> | null;
}
interface GeneralStageHistoryDto<TStageEnumName extends ApiEnumName>
  extends Omit<
    GeneralStageHistoryDtoOfDamageCostEvaluationStage,
    "stage" | "items" | "allStagesInfo"
  > {
  stage?: ApiEnumValue<TStageEnumName> | null;
  items?: Array<GeneralStageHistoryItemDto<TStageEnumName>> | null;
  allStagesInfo?: Array<GeneralStageInfoDto<TStageEnumName>> | null;
}

interface Props<TStageEnumName extends ApiEnumName> {
  stageEnumType: TStageEnumName;
  stageHistory?: GeneralStageHistoryDto<TStageEnumName>;
  withUpdateButtons?: boolean;
  withUpdateConfirmation?: boolean;
  excludeUpdateFor?: ApiEnumValue<TStageEnumName>[];
  onUpdateStage?: (newStage: ApiEnumValue<TStageEnumName>) => void | Promise<void>;
  sx?: SxProps<Theme>;
}

/** Displays stage history with select new stage buttons. */
export default function GeneralStageHistory<TStageEnumName extends ApiEnumName>({
  stageEnumType,
  stageHistory,
  withUpdateButtons = false,
  withUpdateConfirmation = true,
  excludeUpdateFor,
  onUpdateStage,
  sx,
}: Props<TStageEnumName>) {
  const [isStageUpdatingMap, setIsStageUpdatingMap] = useState<
    Partial<Record<ApiEnumValue<TStageEnumName>, boolean>>
  >({});
  const [tempNewStage, setTempNewStage] = useState<ApiEnumValue<TStageEnumName> | undefined>(
    undefined,
  );
  const [isUpdateConfirmationModalOpen, setIsUpdateConfirmationModalOpen] = useState(false);

  const historyItems = useMemo(
    () => stageHistory?.items && _.orderBy(stageHistory.items, (x) => x.sortOrder, "asc"),
    [stageHistory],
  );
  const possibleNewStagesInfo = useMemo(
    () =>
      stageHistory?.allStagesInfo &&
      _.orderBy(stageHistory.allStagesInfo, (x) => x.sortOrder, "asc")
        .filter((x) => x.sortOrder! > stageHistory.sortOrder!)
        .filter((x) => (excludeUpdateFor ? !excludeUpdateFor.includes(x.stage!) : true)),
    [stageHistory, excludeUpdateFor],
  );

  const handleUpdateStage = useCallback(
    async (newStage: ApiEnumValue<TStageEnumName>) => {
      if (stageHistory && stageHistory.stage !== newStage) {
        try {
          setIsStageUpdatingMap({
            ...isStageUpdatingMap,
            [newStage]: true,
          });
          onUpdateStage && (await onUpdateStage(newStage));
          setTempNewStage(undefined);
        } finally {
          setIsStageUpdatingMap({
            ...isStageUpdatingMap,
            [newStage]: false,
          });
        }
      }
    },
    [stageHistory, onUpdateStage],
  );

  return (
    <Box sx={sx}>
      <List dense>
        {historyItems?.map((item, i) => (
          <ListItem key={i}>
            <ListItemIcon>
              <Tooltip
                title={
                  <>
                    <Box>{item.isEnded || item.isLast ? "Completed" : "Pending"}.</Box>

                    {!_.isNil(item.startCount) && (
                      <Box>
                        This stage has been started {item.startCount}{" "}
                        {TextHelper.pluralizeManual("time", item.startCount, "times")}.
                      </Box>
                    )}
                  </>
                }
              >
                <Badge
                  badgeContent={
                    item.startCount && item.startCount >= 2 ? item.startCount : undefined
                  }
                  color='secondary'
                  showZero={false}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <AppIcon
                    of={item.isEnded || item.isLast ? "done" : "pending"}
                    fontSize='medium'
                  />
                </Badge>
              </Tooltip>
            </ListItemIcon>

            <ListItemText
              primary={
                <Typography component='span' variant={item.isCurrent ? "subtitle1" : "body1"}>
                  <Stack direction='row' spacing={0.5}>
                    <InlineApiEnumValue type={stageEnumType} value={item.stage} withHelperTooltip />
                  </Stack>
                </Typography>
              }
              secondary={
                <Stack>
                  {!item.isLast && (
                    <Typography component='span' variant='body2' color='secondary'>
                      <Datetime datetime={item.startedAt} />
                      {" - "}
                      <Datetime datetime={item.endedAt} />
                      {item.startedAt &&
                        item.endedAt &&
                        ` (${DatetimeHelper.humanizeDateRangeDuration(
                          item.startedAt,
                          item.endedAt,
                        )})`}
                    </Typography>
                  )}

                  {item.isLast && (
                    <Typography component='span' variant='body2' color='secondary'>
                      <Datetime datetime={item.startedAt} withDurationFromNow />
                    </Typography>
                  )}

                  {item.startReason && (
                    <Typography component='span' variant='body2' color='secondary'>
                      Start reason: {item.startReason}
                    </Typography>
                  )}

                  {item.endReason && (
                    <Typography component='span' variant='body2' color='secondary'>
                      End reason: {item.endReason}
                    </Typography>
                  )}
                </Stack>
              }
            />
          </ListItem>
        ))}
      </List>

      {withUpdateButtons && (
        <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={1}>
          {possibleNewStagesInfo?.map((info, index) => (
            <Tooltip
              key={index}
              title={`Start '${enumService.getEnumValueName(stageEnumType, info.stage)}' stage`}
            >
              <LoadingButton
                loading={isStageUpdatingMap[info.stage!]}
                variant='contained'
                onClick={() => {
                  if (withUpdateConfirmation) {
                    setTempNewStage(info.stage!);
                    setIsUpdateConfirmationModalOpen(true);
                  } else {
                    handleUpdateStage(info.stage!);
                  }
                }}
              >
                <InlineApiEnumValue type={stageEnumType} value={info.stage} />
              </LoadingButton>
            </Tooltip>
          ))}
        </Stack>
      )}

      <ConfirmationModal
        title={`Do you confirm stage update?`}
        body={
          <>
            You are going to update stage from{" "}
            <strong>
              <InlineApiEnumValue type={stageEnumType} value={stageHistory?.stage} />
            </strong>{" "}
            to{" "}
            <strong>
              <InlineApiEnumValue type={stageEnumType} value={tempNewStage} />
            </strong>
            .
          </>
        }
        open={isUpdateConfirmationModalOpen}
        onClose={() => setIsUpdateConfirmationModalOpen(false)}
        onCancel={() => {
          setIsUpdateConfirmationModalOpen(false);
          setTempNewStage(undefined);
        }}
        onConfirm={async () => {
          tempNewStage && (await handleUpdateStage(tempNewStage));
          setIsUpdateConfirmationModalOpen(false);
        }}
      />
    </Box>
  );
}

import { LoadingButton } from "@mui/lab";
import { FormControl, FormLabel, Stack, TextField, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Formik, getIn } from "formik";
import * as Yup from "yup";

import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CreateProductLocationDto,
  EntityType,
  LocationDto,
  ProductLocationDto,
  UpdateProductLocationDto,
} from "@/core/api/generated";
import GeneralValidationError from "../../Error/GeneralValidationError";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralAddressInput from "../General/Input/GeneralAddressInput";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
}));

type DefaultValues = {
  sourceLocation?: LocationDto | null;
  locationId?: CreateProductLocationDto["locationId"];
  departmentId?: CreateProductLocationDto["departmentId"];
  name?: CreateProductLocationDto["name"];
};

export interface ProductLocationCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<ProductLocationDto, DefaultValues> {
  productLocationId?: string;
}

export type ProductLocationCreateUpdateProps = ProductLocationCreateUpdateOwnProps;

export default function ProductLocationCreateUpdate({
  productLocationId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: ProductLocationCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !productLocationId;

  const productLocationRequest = useApiRequest(
    apiClient.productLocationsApi.apiV1ProductLocationsProductLocationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      productLocationId: productLocationId!,
    },
    {
      skip: !productLocationId,
    },
  );
  const productLocation = productLocationRequest?.data;

  const { departments, locations } = useUserAffiliation();

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: productLocation && {
      idValue: productLocation.id!,
      newTitle: productLocation.localNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.ProductLocation}
      entityId={productLocationId}
      entity={productLocation}
      entityRequest={productLocationRequest}
    >
      <Formik<CreateProductLocationDto & UpdateProductLocationDto & BaseFormikValues>
        enableReinitialize={true}
        initialValues={{
          departmentId:
            defaultValues?.departmentId ||
            defaultValues?.sourceLocation?.department?.id ||
            (productLocation?.departmentIds || [])[0] ||
            (departments && departments[0] && departments[0].id) ||
            undefined,
          locationId:
            defaultValues?.locationId ||
            defaultValues?.sourceLocation?.id ||
            (productLocation?.locationIds || [])[0] ||
            (locations && locations[0] && locations[0].id) ||
            undefined,
          name:
            defaultValues?.name ||
            defaultValues?.sourceLocation?.name ||
            productLocation?.name ||
            undefined,
          description:
            defaultValues?.sourceLocation?.description || productLocation?.description || undefined,
          address: defaultValues?.sourceLocation?.address || productLocation?.address || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = await apiClient.productLocationsApi.apiV1ProductLocationsPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                createProductLocationDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Sales location created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response =
                await apiClient.productLocationsApi.apiV1ProductLocationsProductLocationIdPut({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  productLocationId,
                  updateProductLocationDto: {
                    ...values,
                  },
                });
              enqueueSnackbar("Sales location updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  {/* Entity affiliation */}
                  <EntityAffiliationInput
                    department={{
                      departmentId: values.departmentId,
                      onChange: (d) => {
                        setFieldValue("departmentId", d?.id);
                        setFieldValue("locationId", undefined);
                      },
                      error: errors.departmentId,
                    }}
                    location={{
                      locationId: values.locationId,
                      onChange: (l) => {
                        setFieldValue("locationId", l?.id);
                        l?.address && setFieldValue("address", l?.address);
                      },
                      searchFilters: { departmentId: values.departmentId },
                      createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                      error: errors.locationId,
                      disabled: !values.departmentId,
                    }}
                  />

                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    required
                    fullWidth
                    helperText={touched.name && errors.name}
                    label='Name'
                    margin='dense'
                    name='name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.name || ""}
                    variant='outlined'
                  />

                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    multiline
                    minRows={2}
                    helperText={touched.description && errors.description}
                    label='Description'
                    margin='dense'
                    name='description'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.description || ""}
                    variant='outlined'
                  />

                  <FormControl fullWidth>
                    <Stack spacing={1}>
                      <FormLabel required>
                        <Typography component='span' variant='subtitle1'>
                          Address
                        </Typography>
                      </FormLabel>

                      <GeneralAddressInput
                        errors={getIn(errors, "address")}
                        touched={getIn(touched, "address")}
                        value={values.address}
                        inputsProps={{ all: {} }}
                        onChange={(newValue) => setFieldValue("address", newValue)}
                      />
                    </Stack>
                  </FormControl>
                </Box>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <LoadingButton
                  sx={{ mt: { xs: "auto", md: 2 } }}
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import DropdownButton from "@/common/components/Button/DropdownButton";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { ArrayHelper } from "@/common/helpers/array";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { enumService } from "@/common/services/enum";
import { apiClient } from "@/core/api/ApiClient";
import { AdminTestDomainEventType, TestEventDto } from "@/core/api/generated";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, getIn } from "formik";
import { useState } from "react";

interface FormValues {
  testEvents: TestEventDto[];
}

const DomainEventsPage = () => {
  const { enqueueSnackbar } = useAppSnackbar();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  return (
    <>
      <Typography
        component='div'
        variant='h4'
        sx={{ display: "flex", alignItems: "center", mb: 2 }}
      >
        Send test domain events
      </Typography>

      <Formik<FormValues>
        initialValues={{ testEvents: [{ eventType: AdminTestDomainEventType.TestEvent }] }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            await apiClient.adminDomainEventsApi.apiV1AdminDomainEventsTestSendPost({
              sendTestEventsDto: {
                testEvents: values.testEvents,
              },
            });
            enqueueSnackbar("Event sent successfully.", { variant: "success" });
          } catch (error) {
            console.error("Submission error:", error);
            enqueueSnackbar("Error submitting number.", { variant: "error" });
          } finally {
            setIsConfirmModalOpen(false);
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleBlur, handleSubmit, setFieldValue, isSubmitting, values }) => {
          const eventTypes = enumService
            .getEnumValues("AdminTestDomainEventType", { exceptNone: false })
            .filter((e) => !values.testEvents.some((te) => te.eventType == e));
          return (
            <ViewPageLayout header={<>Domain events</>}>
              <form noValidate onSubmit={handleSubmit}>
                {values.testEvents?.map((testEvent, i) => (
                  <Box key={i} sx={{ mb: 2 }}>
                    <Typography>{testEvent.eventType}</Typography>
                    <FormControl
                      fullWidth
                      margin='dense'
                      error={Boolean(getIn(errors, `testEvents[${i}].numberOfEvents`))}
                    >
                      <TextField
                        name='eventNumber'
                        type='number'
                        label='Event number'
                        value={testEvent.numberOfEvents}
                        onChange={(e) => {
                          const newValue = e.target.value ? +e.target.value : 1;
                          setFieldValue(`testEvents[${i}]`, {
                            ...testEvent,
                            eventNumber: newValue,
                          });
                        }}
                        onBlur={handleBlur}
                        helperText={getIn(errors, `testEvents[${i}].numberOfEvents`)}
                        error={Boolean(getIn(errors, `testEvents[${i}].numberOfEvents`))}
                        variant='outlined'
                        fullWidth
                      />
                      <FormHelperText>
                        {getIn(errors, `testEvents[${i}].numberOfEvents`)}
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      fullWidth
                      margin='dense'
                      error={Boolean(getIn(errors, `testEvents[${i}].proccessDurationMs`))}
                    >
                      <TextField
                        name='proccessDurationMs'
                        type='number'
                        label='Process Duration (ms)'
                        value={testEvent.proccessDurationMs}
                        onChange={(e) => {
                          const newValue = e.target.value ? +e.target.value : 1;
                          setFieldValue(`testEvents[${i}]`, {
                            ...testEvent,
                            proccessDurationMs: newValue,
                          });
                        }}
                        onBlur={handleBlur}
                        helperText={getIn(errors, `testEvents[${i}].proccessDurationMs`)}
                        error={Boolean(getIn(errors, `testEvents[${i}].proccessDurationMs`))}
                        variant='outlined'
                        fullWidth
                        disabled={isSubmitting}
                      />
                      <FormHelperText>
                        {getIn(errors, `testEvents[${i}].proccessDurationMs`)}
                      </FormHelperText>
                    </FormControl>

                    <Stack direction='row' alignItems='flex-start' justifyContent='flex-end'>
                      <IconButton
                        onClick={() =>
                          setFieldValue(
                            "testEvents",
                            ArrayHelper.removeByIndex(values.testEvents, i),
                          )
                        }
                      >
                        <AppIcon of='delete' />
                      </IconButton>
                    </Stack>
                  </Box>
                ))}

                <DropdownButton
                  sx={{ mt: 1 }}
                  disabled={eventTypes.length === 0}
                  buttonProps={{
                    color: "secondary",
                    size: "small",
                    variant: "outlined",
                    startIcon: <AppIcon of='add' />,
                  }}
                  dropdownContent={
                    <MenuList>
                      {eventTypes.map((type) => (
                        <MenuItem
                          key={type}
                          onClick={() => {
                            setFieldValue("testEvents", [
                              ...(values.testEvents || []),
                              {
                                eventType: type,
                              },
                            ]);
                          }}
                        >
                          <ListItemIcon>
                            <AppIcon of='add' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>
                            <InlineApiEnumValue type='AdminTestDomainEventType' value={type} />
                          </ListItemText>
                        </MenuItem>
                      ))}
                    </MenuList>
                  }
                >
                  Add item
                </DropdownButton>

                <Button
                  sx={{ mt: 2 }}
                  color='primary'
                  fullWidth
                  variant='contained'
                  onClick={() => setIsConfirmModalOpen(true)}
                >
                  Submit
                </Button>

                <ConfirmationModal
                  title={`Confirm send events`}
                  body={`Are you sure you want to submit this event?`}
                  open={isConfirmModalOpen}
                  onClose={() => setIsConfirmModalOpen(false)}
                  onCancel={() => setIsConfirmModalOpen(false)}
                  onConfirm={() => {
                    handleSubmit();
                  }}
                />
              </form>
            </ViewPageLayout>
          );
        }}
      </Formik>
    </>
  );
};

export default DomainEventsPage;

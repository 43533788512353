import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AppIcon from "@/common/components/Icons/AppIcon";
import RouteCategoryIndex from "@/common/components/Route/RouteCategoryIndex";
import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission } from "@/core/api/generated";

export default function ReferenceDataIndexPage() {
  return (
    <ViewPageLayout header={<SimpleViewPageHeader title='Reference data' />}>
      <RouteCategoryIndex
        direction='row'
        childrenLinks={[
          {
            icon: <AppIcon of='vehicleVisualModel' />,
            title: "Vehicle visual models",
            to: ROUTE_PATH.VEHICLE_VISUAL_MODELS,
            permissions: [AppPermission.FleetAppAccess],
          },
        ]}
      />
    </ViewPageLayout>
  );
}

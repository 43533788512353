import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import { AdminUserCompanyDto, AdminUserDto, AppPermission } from "@/core/api/generated";
import { Stack } from "@mui/material";
import UserSuspensionInfoDisplay from "../../UserSuspensionInfoDisplay";

interface Props {
  user: AdminUserDto;
  selectedUserProfile: AdminUserDto | AdminUserCompanyDto;
}

export default function SuspensionInfoTabContent({ user, selectedUserProfile }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <PageTabHeader title={"Suspension info"} />
        <AuthorizedElement permissions={[AppPermission.AdminUserManage]}>
          <Stack spacing={1}>
            {!selectedUserProfile.suspension && <NoDataAlert />}
            {selectedUserProfile.suspension && (
              <UserSuspensionInfoDisplay info={selectedUserProfile.suspension} />
            )}
          </Stack>
        </AuthorizedElement>
      </Stack>
    </PageTabContent>
  );
}

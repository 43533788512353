import { AppPermission } from "@/core/api/generated";

import { apiClient } from "@/core/api/ApiClient";
import { TeslaConnectionDto } from "@/core/api/generated/v0.1-demo";
import { ListItemIcon, ListItemText, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactNode } from "react";
import AuthorizedMenuItem from "../../Auth/AuthorizedMenuItem";
import EntityMenu from "../../EntityMenu/EntityMenu";
import AppIcon from "../../Icons/AppIcon";
import TeslaConnectionDeleteModal from "./TeslaConnectionDeleteModal";

enum ActionFlags {
  RefreshVehicles = "RefreshVehicles",
  RefreshAuthTokens = "RefreshAuthTokens",
  Delete = "Delete",
}

const defaultDisplayProps = {
  actions: {
    refreshVehicles: true,
    refreshAuthTokens: true,
    delete: true,
  },
};

interface TriggersProps {
  entity?: TeslaConnectionDto | null;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity?: TeslaConnectionDto | null;
  disabled?: boolean;
  onUpdate: ((newValue: TeslaConnectionDto | null | undefined) => void) | undefined;
  onDelete?: (() => void) | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customActions?: (props: TriggersProps) => ReactNode | Element;
}

export default function TeslaConnectionMenu({
  entity,
  disabled,
  customActions,
  onUpdate,
  onDelete,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useSnackbar();

  return (
    <EntityMenu
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {customActions &&
            customActions({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}

          {displayProps?.actions?.refreshVehicles && (
            <AuthorizedMenuItem
              permissions={[AppPermission.FleetAppAccess, AppPermission.TeslaConnectionManage]}
              onClick={async () => {
                try {
                  const response =
                    await apiClient.teslaConnectionsApi.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost(
                      {
                        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                        teslaConnectionId: entity?.id || "",
                      },
                    );
                  onUpdate && onUpdate(response.data);
                  enqueueSnackbar("Vehicle refresh success.", { variant: "success" });
                } catch (err) {
                  const problemDetails = apiClient.getProblemDetails(err);
                  enqueueSnackbar(
                    `Vehicle refresh error: ${problemDetails?.title}, ${problemDetails?.detail}`,
                    { variant: "error" },
                  );
                }
              }}
            >
              <ListItemIcon>
                <AppIcon of='refresh' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Refresh vehicles</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.refreshAuthTokens && (
            <AuthorizedMenuItem
              permissions={[AppPermission.FleetAppAccess, AppPermission.TeslaConnectionManage]}
              disabled={!entity?.tokens}
              onClick={async () => {
                try {
                  const response =
                    await apiClient.teslaConnectionsApi.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost(
                      {
                        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                        teslaConnectionId: entity?.id || "",
                      },
                    );
                  onUpdate && onUpdate(response.data);
                  enqueueSnackbar("Auth tokens refresh success.", { variant: "success" });
                } catch (err) {
                  const problemDetails = apiClient.getProblemDetails(err);
                  enqueueSnackbar(
                    `Auth tokens refresh error: ${problemDetails?.title}, ${problemDetails?.detail}`,
                    { variant: "error" },
                  );
                }
              }}
            >
              <ListItemIcon>
                <AppIcon of='refresh' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Refresh auth tokens</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.delete && (
            <AuthorizedMenuItem
              permissions={[AppPermission.FleetAppAccess, AppPermission.TeslaConnectionManage]}
              onClick={() => startAction(ActionFlags.Delete)}
            >
              <ListItemIcon>
                <AppIcon of='delete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </AuthorizedMenuItem>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {/* Delete */}
          {entity && (
            <TeslaConnectionDeleteModal
              entity={entity}
              open={currentAction === ActionFlags.Delete}
              onClose={() => cancelAction()}
              onDelete={() => {
                onDelete && onDelete();
                completeAction();
              }}
            />
          )}
        </Stack>
      )}
    />
  );
}

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppTypography from "@/common/components/Text/AppTypography";
import { FileItem } from "@/common/fileItem";
import { renderIf } from "@/common/helpers/render/renderIf";
import { TypeHelper } from "@/common/helpers/type";
import {
  GeneralHistoryDto,
  GeneralHistoryEventType,
  GeneralHistoryEventTypeOfVehicle,
  GeneralHistoryEventTypeOfVehicleDamage,
  GeneralHistoryType,
} from "@/core/api/generated";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import AssetLink from "../../../Asset/AssetLink";
import AssetSubscriptionLink from "../../../AssetSubscription/AssetSubscriptionLink";
import ContractLink from "../../../Contract/ContractLink";
import CustomerLink from "../../../Customer/CustomerLink";
import DamageDetectionLink from "../../../DamageDetection/DamageDetectionLink";
import DepartmentLink from "../../../Department/DepartmentLink";
import GeneralByWhoDisplay from "../../../General/Display/GeneralByWhoDisplay";
import GeneralStrictEntityRelationLink from "../../../General/Display/GeneralStrictEntityRelationLink";
import LocationLink from "../../../Location/LocationLink";
import RepairOperationLink from "../../../RepairOperation/RepairOperationLink";
import SpotLink from "../../../Spot/SpotLink";
import TenantLink from "../../../Tenant/TenantLink";
import VehicleLink from "../../../Vehicle/VehicleLink";
import VehicleDamageLink from "../../../VehicleDamage/VehicleDamageLink";
import VisualInspectionLink from "../../../VisualInspection/VisualInspectionLink";

interface Props {
  generalHistory: GeneralHistoryDto;
}

export default function OverviewTabContent({ generalHistory }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={1}>
        <PageTabHeader
          title={"Details"}
          subtitle2={<EntityCreatedByInfoDisplay entity={generalHistory} />}
        />

        <Stack spacing={2}>
          <Stack spacing={1}>
            <FieldValue label='ID' isEmpty={TypeHelper.isEmpty(generalHistory.id)}>
              {generalHistory.id}
            </FieldValue>

            <FieldValue label='Company' isEmpty={TypeHelper.isEmpty(generalHistory.tenantId)}>
              <TenantLink entityId={generalHistory.tenantId} entity={undefined} />
            </FieldValue>

            <FieldValue
              label='Subject type'
              isEmpty={TypeHelper.isEmpty(generalHistory.subject?.type)}
            >
              <InlineApiEnumValue
                type='GeneralHistorySubjectType'
                value={generalHistory.subject?.type}
              />
            </FieldValue>

            <FieldValue
              label='Subject entity'
              isEmpty={TypeHelper.isEmpty(generalHistory.subject?.entityId)}
            >
              {generalHistory.subject?.entityType && generalHistory.subject?.entityId && (
                <GeneralStrictEntityRelationLink
                  value={{
                    entityType: generalHistory.subject?.entityType,
                    entityId: generalHistory.subject?.entityId,
                  }}
                />
              )}
            </FieldValue>

            <FieldValue label='Type' isEmpty={TypeHelper.isEmpty(generalHistory.type)}>
              <InlineApiEnumValue type='GeneralHistoryType' value={generalHistory.type} />
            </FieldValue>

            <FieldValue
              label='Create mode'
              isEmpty={TypeHelper.isEmpty(generalHistory.autoCreateMode)}
            >
              <InlineApiEnumValue
                type='AutomationCreateMode'
                value={generalHistory.autoCreateMode}
              />
            </FieldValue>

            <FieldValue label='Raised at' isEmpty={TypeHelper.isEmpty(generalHistory.raisedAt)}>
              <Datetime datetime={generalHistory.raisedAt} withDurationFromNow />
            </FieldValue>

            <FieldValue label='Raised by' isEmpty={TypeHelper.isEmpty(generalHistory.raisedBy)}>
              <GeneralByWhoDisplay who={generalHistory.raisedBy} />
            </FieldValue>

            <FieldValue
              label='Attachments'
              isEmpty={TypeHelper.isEmpty(generalHistory.attachments)}
              labelAlight={{
                vertical: "start",
              }}
              valueAlight={{
                vertical: "start",
              }}
            >
              <FileListView
                files={FileItem.createManyFrom(generalHistory.attachments)}
                maxVisibleFiles={10}
                itemWidth={100}
                itemHeight={80}
              />
            </FieldValue>
          </Stack>

          <Card>
            <CardContent>
              <Typography variant='h4' sx={{ mb: 2 }}>
                <InlineApiEnumValue type='GeneralHistoryType' value={generalHistory.type} />
              </Typography>

              {renderIf()
                .if(generalHistory.type === GeneralHistoryType.Log)
                .then(
                  <Stack spacing={1}>
                    <Stack spacing={1}>
                      <FieldValue
                        label='Message'
                        isEmpty={TypeHelper.isEmpty(generalHistory.log?.message)}
                      >
                        {generalHistory.log?.message}
                      </FieldValue>
                    </Stack>
                  </Stack>,
                )
                .elseif(generalHistory.type === GeneralHistoryType.Event)
                .then(
                  <Card>
                    <CardContent>
                      <Stack spacing={2}>
                        <Typography variant='h4'>
                          <InlineApiEnumValue
                            type='GeneralHistoryEventType'
                            value={generalHistory.event?.type}
                          />
                        </Typography>

                        {renderIf()
                          .if(generalHistory.event?.type === GeneralHistoryEventType.Arbitrary)
                          .then(
                            <Stack spacing={2}>
                              <Stack spacing={1}>
                                <FieldValue
                                  label='Name'
                                  isEmpty={TypeHelper.isEmpty(
                                    generalHistory.event?.arbitrary?.name,
                                  )}
                                >
                                  {generalHistory.event?.arbitrary?.name}
                                </FieldValue>

                                <FieldValue
                                  label='Description'
                                  isEmpty={TypeHelper.isEmpty(
                                    generalHistory.event?.arbitrary?.description,
                                  )}
                                >
                                  {generalHistory.event?.arbitrary?.description}
                                </FieldValue>
                              </Stack>
                            </Stack>,
                          )
                          .elseif(generalHistory.event?.type === GeneralHistoryEventType.Vehicle)
                          .then(
                            <Card>
                              <CardContent>
                                <Stack spacing={2}>
                                  <Typography variant='h4'>
                                    <InlineApiEnumValue
                                      type='GeneralHistoryEventTypeOfVehicle'
                                      value={generalHistory.event?.vehicle?.type}
                                    />
                                  </Typography>

                                  {renderIf()
                                    .if(
                                      generalHistory.event?.vehicle?.type ===
                                        GeneralHistoryEventTypeOfVehicle.MileageChanged,
                                    )
                                    .then(
                                      <Stack spacing={1}>
                                        <FieldValue
                                          label='Vehicle'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.mileageChanged
                                              ?.vehicleId,
                                          )}
                                        >
                                          <VehicleLink
                                            entityId={
                                              generalHistory.event?.vehicle?.mileageChanged
                                                ?.vehicleId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>

                                        <FieldValue
                                          label='Visual inspection'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.mileageChanged
                                              ?.visualInspectionId,
                                          )}
                                        >
                                          <VisualInspectionLink
                                            entityId={
                                              generalHistory.event?.vehicle?.mileageChanged
                                                ?.visualInspectionId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>

                                        <FieldValue
                                          label='Damage detection'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.mileageChanged
                                              ?.damageDetectionId,
                                          )}
                                        >
                                          <DamageDetectionLink
                                            entityId={
                                              generalHistory.event?.vehicle?.mileageChanged
                                                ?.damageDetectionId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>

                                        <FieldValue
                                          label='Old mileage'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.mileageChanged
                                              ?.oldMileage,
                                          )}
                                        >
                                          {
                                            generalHistory.event?.vehicle?.mileageChanged
                                              ?.oldMileage
                                          }
                                        </FieldValue>

                                        <FieldValue
                                          label='New mileage'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.mileageChanged
                                              ?.newMileage,
                                          )}
                                        >
                                          {
                                            generalHistory.event?.vehicle?.mileageChanged
                                              ?.newMileage
                                          }
                                        </FieldValue>

                                        <FieldValue
                                          label='Notes'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.mileageChanged?.notes,
                                          )}
                                        >
                                          {generalHistory.event?.vehicle?.mileageChanged?.notes}
                                        </FieldValue>
                                      </Stack>,
                                    )
                                    .elseif(
                                      generalHistory.event?.vehicle?.type ===
                                        GeneralHistoryEventTypeOfVehicle.DamageDetected,
                                    )
                                    .then(
                                      <Stack spacing={1}>
                                        <FieldValue
                                          label='Vehicle'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.damageDetected
                                              ?.vehicleId,
                                          )}
                                        >
                                          <VehicleLink
                                            entityId={
                                              generalHistory.event?.vehicle?.damageDetected
                                                ?.vehicleId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>

                                        <FieldValue
                                          label='Vehicle damage'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.damageDetected
                                              ?.vehicleDamageId,
                                          )}
                                        >
                                          <VehicleDamageLink
                                            vehicleId={
                                              generalHistory.event?.vehicle?.damageDetected
                                                ?.vehicleId
                                            }
                                            entityId={
                                              generalHistory.event?.vehicle?.damageDetected
                                                ?.vehicleDamageId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>

                                        <FieldValue
                                          label='Damage detection'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.damageDetected
                                              ?.damageDetectionId,
                                          )}
                                        >
                                          <DamageDetectionLink
                                            entityId={
                                              generalHistory.event?.vehicle?.damageDetected
                                                ?.damageDetectionId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                      </Stack>,
                                    )
                                    .elseif(
                                      generalHistory.event?.vehicle?.type ===
                                        GeneralHistoryEventTypeOfVehicle.DamageRepaired,
                                    )
                                    .then(
                                      <Stack spacing={1}>
                                        <FieldValue
                                          label='Vehicle'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.damageRepaired
                                              ?.vehicleId,
                                          )}
                                        >
                                          <VehicleLink
                                            entityId={
                                              generalHistory.event?.vehicle?.damageRepaired
                                                ?.vehicleId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>

                                        <FieldValue
                                          label='Vehicle damage'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.damageRepaired
                                              ?.vehicleDamageId,
                                          )}
                                        >
                                          <VehicleDamageLink
                                            vehicleId={
                                              generalHistory.event?.vehicle?.damageRepaired
                                                ?.vehicleId
                                            }
                                            entityId={
                                              generalHistory.event?.vehicle?.damageRepaired
                                                ?.vehicleDamageId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>

                                        <FieldValue
                                          label='Repair operation'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.damageRepaired
                                              ?.repairOperationId,
                                          )}
                                        >
                                          <RepairOperationLink
                                            entityId={
                                              generalHistory.event?.vehicle?.damageRepaired
                                                ?.repairOperationId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                      </Stack>,
                                    )
                                    .elseif(
                                      generalHistory.event?.vehicle?.type ===
                                        GeneralHistoryEventTypeOfVehicle.DamageDeleted,
                                    )
                                    .then(
                                      <Stack spacing={1}>
                                        <FieldValue
                                          label='Vehicle'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.damageDeleted?.vehicleId,
                                          )}
                                        >
                                          <VehicleLink
                                            entityId={
                                              generalHistory.event?.vehicle?.damageDeleted
                                                ?.vehicleId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>

                                        <FieldValue
                                          label='Vehicle damage'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.damageDeleted
                                              ?.vehicleDamageId,
                                          )}
                                        >
                                          <VehicleDamageLink
                                            vehicleId={
                                              generalHistory.event?.vehicle?.damageDeleted
                                                ?.vehicleId
                                            }
                                            entityId={
                                              generalHistory.event?.vehicle?.damageDeleted
                                                ?.vehicleDamageId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                      </Stack>,
                                    )
                                    .elseif(
                                      generalHistory.event?.vehicle?.type ===
                                        GeneralHistoryEventTypeOfVehicle.CheckedOut,
                                    )
                                    .then(
                                      <Stack spacing={1}>
                                        <FieldValue
                                          label='Vehicle'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.checkedOut?.vehicleId,
                                          )}
                                        >
                                          <VehicleLink
                                            entityId={
                                              generalHistory.event?.vehicle?.checkedOut?.vehicleId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                        <FieldValue
                                          label='Contract'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.checkedOut?.contractId,
                                          )}
                                        >
                                          <ContractLink
                                            entityId={
                                              generalHistory.event?.vehicle?.checkedOut?.contractId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                        <FieldValue
                                          label='Customer'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.checkedOut?.customerId,
                                          )}
                                        >
                                          <CustomerLink
                                            entityId={
                                              generalHistory.event?.vehicle?.checkedOut?.customerId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                        {generalHistory.event?.vehicle?.checkedOut
                                          ?.departmentId && (
                                          <FieldValue
                                            label='Department'
                                            isEmpty={
                                              !generalHistory.event?.vehicle?.checkedOut
                                                ?.departmentId
                                            }
                                          >
                                            <DepartmentLink
                                              entity={undefined}
                                              entityId={
                                                generalHistory.event?.vehicle?.checkedOut
                                                  ?.departmentId
                                              }
                                            />
                                          </FieldValue>
                                        )}

                                        {generalHistory.event?.vehicle?.checkedOut?.locationId && (
                                          <FieldValue
                                            label='Location'
                                            isEmpty={
                                              !generalHistory.event?.vehicle?.checkedOut?.locationId
                                            }
                                          >
                                            <LocationLink
                                              entity={undefined}
                                              entityId={
                                                generalHistory.event?.vehicle?.checkedOut
                                                  ?.locationId
                                              }
                                            />
                                          </FieldValue>
                                        )}

                                        <FieldValue
                                          label='Spot'
                                          isEmpty={
                                            !generalHistory.event?.vehicle?.checkedOut?.spotId
                                          }
                                        >
                                          <SpotLink
                                            entity={undefined}
                                            entityId={
                                              generalHistory.event?.vehicle?.checkedOut?.spotId
                                            }
                                          />
                                        </FieldValue>

                                        {generalHistory.event?.vehicle?.checkedOut?.mileage && (
                                          <FieldValue label='Mileage' direction='row'>
                                            {generalHistory.event?.vehicle?.checkedOut?.mileage}
                                          </FieldValue>
                                        )}
                                        {generalHistory.event?.vehicle?.checkedOut?.mileage && (
                                          <FieldValue label='Fuel level' direction='row'>
                                            {generalHistory.event?.vehicle?.checkedOut?.fuelLevel}
                                          </FieldValue>
                                        )}
                                      </Stack>,
                                    )
                                    .elseif(
                                      generalHistory.event?.vehicle?.type ===
                                        GeneralHistoryEventTypeOfVehicle.CheckedIn,
                                    )
                                    .then(
                                      <Stack spacing={1}>
                                        <FieldValue
                                          label='Vehicle'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.checkedIn?.vehicleId,
                                          )}
                                        >
                                          <VehicleLink
                                            entityId={
                                              generalHistory.event?.vehicle?.checkedIn?.vehicleId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                        <FieldValue
                                          label='Contract'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.checkedIn?.contractId,
                                          )}
                                        >
                                          <ContractLink
                                            entityId={
                                              generalHistory.event?.vehicle?.checkedIn?.contractId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                        <FieldValue
                                          label='Customer'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.checkedIn?.customerId,
                                          )}
                                        >
                                          <CustomerLink
                                            entityId={
                                              generalHistory.event?.vehicle?.checkedIn?.customerId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                        {generalHistory.event?.vehicle?.checkedIn?.departmentId && (
                                          <FieldValue
                                            label='Department'
                                            isEmpty={
                                              !generalHistory.event?.vehicle?.checkedIn
                                                ?.departmentId
                                            }
                                          >
                                            <DepartmentLink
                                              entity={undefined}
                                              entityId={
                                                generalHistory.event?.vehicle?.checkedIn
                                                  ?.departmentId
                                              }
                                            />
                                          </FieldValue>
                                        )}

                                        {generalHistory.event?.vehicle?.checkedIn?.locationId && (
                                          <FieldValue
                                            label='Location'
                                            isEmpty={
                                              !generalHistory.event?.vehicle?.checkedIn?.locationId
                                            }
                                          >
                                            <LocationLink
                                              entity={undefined}
                                              entityId={
                                                generalHistory.event?.vehicle?.checkedIn?.locationId
                                              }
                                            />
                                          </FieldValue>
                                        )}

                                        <FieldValue
                                          label='Spot'
                                          isEmpty={
                                            !generalHistory.event?.vehicle?.checkedIn?.spotId
                                          }
                                        >
                                          <SpotLink
                                            entity={undefined}
                                            entityId={
                                              generalHistory.event?.vehicle?.checkedIn?.spotId
                                            }
                                          />
                                        </FieldValue>

                                        {generalHistory.event?.vehicle?.checkedIn?.mileage && (
                                          <FieldValue label='Mileage' direction='row'>
                                            {generalHistory.event?.vehicle?.checkedIn?.mileage}
                                          </FieldValue>
                                        )}
                                        {generalHistory.event?.vehicle?.checkedIn?.totalMileage && (
                                          <FieldValue
                                            label='Total mileage per contract'
                                            direction='row'
                                          >
                                            {generalHistory.event?.vehicle?.checkedIn?.totalMileage}
                                          </FieldValue>
                                        )}
                                        {generalHistory.event?.vehicle?.checkedIn?.mileage && (
                                          <FieldValue label='Fuel level' direction='row'>
                                            {generalHistory.event?.vehicle?.checkedIn?.fuelLevel}
                                          </FieldValue>
                                        )}
                                      </Stack>,
                                    )
                                    .elseif(
                                      generalHistory.event?.vehicle?.type ===
                                        GeneralHistoryEventTypeOfVehicle.Allocated,
                                    )
                                    .then(
                                      <Stack spacing={1}>
                                        <FieldValue
                                          label='Vehicle'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.allocated?.vehicleId,
                                          )}
                                        >
                                          <VehicleLink
                                            entityId={
                                              generalHistory.event?.vehicle?.allocated?.vehicleId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                        <FieldValue
                                          label='Contract'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.allocated?.contractId,
                                          )}
                                        >
                                          <ContractLink
                                            entityId={
                                              generalHistory.event?.vehicle?.allocated?.contractId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                        <FieldValue
                                          label='Asset subscription'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.allocated
                                              ?.assetSubscriptionId,
                                          )}
                                        >
                                          <AssetSubscriptionLink
                                            entityId={
                                              generalHistory.event?.vehicle?.allocated
                                                ?.assetSubscriptionId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                        <FieldValue
                                          label='Customer'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.allocated?.customerId,
                                          )}
                                        >
                                          <CustomerLink
                                            entityId={
                                              generalHistory.event?.vehicle?.allocated?.customerId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>

                                        <FieldValue
                                          label='Asset pool'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicle?.allocated?.assetId,
                                          )}
                                        >
                                          <AssetLink
                                            entityId={
                                              generalHistory.event?.vehicle?.allocated?.assetId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                      </Stack>,
                                    )
                                    .else(
                                      <InlineApiEnumValue
                                        type='GeneralHistoryEventTypeOfVehicle'
                                        value={generalHistory.event?.vehicle?.type}
                                      />,
                                    )
                                    .render()}
                                </Stack>
                              </CardContent>
                            </Card>,
                          )
                          .elseif(
                            generalHistory.event?.type === GeneralHistoryEventType.VehicleDamage,
                          )
                          .then(
                            <Card>
                              <CardContent>
                                <Stack spacing={2}>
                                  <Typography variant='h4'>
                                    <InlineApiEnumValue
                                      type='GeneralHistoryEventTypeOfVehicleDamage'
                                      value={generalHistory.event?.vehicleDamage?.type}
                                    />
                                  </Typography>

                                  {renderIf()
                                    .if(
                                      generalHistory.event?.vehicleDamage?.type ===
                                        GeneralHistoryEventTypeOfVehicleDamage.DamageDetected,
                                    )
                                    .then(
                                      <Stack spacing={1}>
                                        <FieldValue
                                          label='Vehicle'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicleDamage?.damageDetected
                                              ?.vehicleId,
                                          )}
                                        >
                                          <VehicleLink
                                            entityId={
                                              generalHistory.event?.vehicleDamage?.damageDetected
                                                ?.vehicleId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>

                                        <FieldValue
                                          label='Vehicle damage'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicleDamage?.damageDetected
                                              ?.vehicleDamageId,
                                          )}
                                        >
                                          <VehicleDamageLink
                                            vehicleId={
                                              generalHistory.event?.vehicleDamage?.damageDetected
                                                ?.vehicleId
                                            }
                                            entityId={
                                              generalHistory.event?.vehicleDamage?.damageDetected
                                                ?.vehicleDamageId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>

                                        <FieldValue
                                          label='Damage detection'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicleDamage?.damageDetected
                                              ?.damageDetectionId,
                                          )}
                                        >
                                          <DamageDetectionLink
                                            entityId={
                                              generalHistory.event?.vehicleDamage?.damageDetected
                                                ?.damageDetectionId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                      </Stack>,
                                    )
                                    .elseif(
                                      generalHistory.event?.vehicleDamage?.type ===
                                        GeneralHistoryEventTypeOfVehicleDamage.DamageRepaired,
                                    )
                                    .then(
                                      <Stack spacing={1}>
                                        <FieldValue
                                          label='Vehicle'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicleDamage?.damageRepaired
                                              ?.vehicleId,
                                          )}
                                        >
                                          <VehicleLink
                                            entityId={
                                              generalHistory.event?.vehicleDamage?.damageRepaired
                                                ?.vehicleId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>

                                        <FieldValue
                                          label='Vehicle damage'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicleDamage?.damageRepaired
                                              ?.vehicleDamageId,
                                          )}
                                        >
                                          <VehicleDamageLink
                                            vehicleId={
                                              generalHistory.event?.vehicleDamage?.damageDetected
                                                ?.vehicleId
                                            }
                                            entityId={
                                              generalHistory.event?.vehicleDamage?.damageRepaired
                                                ?.vehicleDamageId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>

                                        <FieldValue
                                          label='Damage detection'
                                          isEmpty={TypeHelper.isEmpty(
                                            generalHistory.event?.vehicleDamage?.damageRepaired
                                              ?.repairOperationId,
                                          )}
                                        >
                                          <RepairOperationLink
                                            entityId={
                                              generalHistory.event?.vehicleDamage?.damageRepaired
                                                ?.repairOperationId
                                            }
                                            entity={undefined}
                                          />
                                        </FieldValue>
                                      </Stack>,
                                    )
                                    .else(
                                      <InlineApiEnumValue
                                        type='GeneralHistoryEventTypeOfVehicleDamage'
                                        value={generalHistory.event?.vehicleDamage?.type}
                                      />,
                                    )
                                    .render()}
                                </Stack>
                              </CardContent>
                            </Card>,
                          )
                          .else(
                            <AppTypography color='error'>
                              History event of type {generalHistory.event?.type} is not supported.
                            </AppTypography>,
                          )
                          .render()}
                      </Stack>
                    </CardContent>
                  </Card>,
                )
                .else(
                  <AppTypography color='error'>
                    History record of type {generalHistory.type} is not supported.
                  </AppTypography>,
                )
                .render()}
            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </PageTabContent>
  );
}

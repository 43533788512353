import { IBasePageLayout } from "@/common/ts/layout";
import { Box, Stack } from "@mui/material";
import PageBody, { PageBodyProps } from "../PageBody/PageBody";
import PageFooter, { PageFooterProps } from "../PageFooter/PageFooter";
import PageHeader, { PageHeaderProps } from "../PageHeader/PageHeader";
import BaseGoBackPageLayout, { BaseGoBackPageLayoutProps } from "./BaseGoBackPageLayout";

export interface ListPageLayoutProps extends IBasePageLayout {
  goBackLayoutProps?: BaseGoBackPageLayoutProps;
  headerProps?: PageHeaderProps;
  bodyProps?: PageBodyProps;
  footerProps?: PageFooterProps;
}

export default function ListPageLayout({
  goBackLayoutProps,
  breadcrumbs,
  header,
  body,
  footer,
  children,
  headerProps,
  bodyProps,
  footerProps,
}: ListPageLayoutProps) {
  return (
    <BaseGoBackPageLayout
      sx={{ width: "100%", height: "100%" }}
      breadcrumbs={breadcrumbs}
      withGoBack={false}
      {...goBackLayoutProps}
    >
      <Stack direction='column' spacing={2} sx={{ height: "100%", pb: 10 }}>
        <PageHeader {...headerProps}>{header}</PageHeader>
        <PageBody {...bodyProps}>{body || children}</PageBody>
        <PageFooter {...footerProps}>{footer}</PageFooter>
      </Stack>
    </BaseGoBackPageLayout>
  );

  // old
  // return (
  //   <Box sx={{ width: "100%", height: "100%" }}>
  //     <Stack direction='column' spacing={2} sx={{ height: "100%" }}>
  //       <PageHeader {...headerProps}>{header}</PageHeader>
  //       <PageBody {...bodyProps} sx={{ pl: 2, pr: 3, ...bodyProps?.sx }}>
  //         {body || children}
  //       </PageBody>
  //       <PageFooter {...footerProps}>{footer}</PageFooter>
  //     </Stack>
  //   </Box>
  // );
}

import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function PersonOutlineIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      color='secondary'
      {...props}
      width='17'
      height='17'
      viewBox='0 0 17 17'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.8335 5.16667C9.8335 4.43133 9.2355 3.83333 8.50016 3.83333C7.76483 3.83333 7.16683 4.43133 7.16683 5.16667C7.16683 5.902 7.76483 6.5 8.50016 6.5C9.2355 6.5 9.8335 5.902 9.8335 5.16667ZM11.1668 5.16667C11.1668 6.63733 9.97083 7.83333 8.50016 7.83333C7.0295 7.83333 5.8335 6.63733 5.8335 5.16667C5.8335 3.696 7.0295 2.5 8.50016 2.5C9.97083 2.5 11.1668 3.696 11.1668 5.16667ZM3.8335 13.8333C3.8335 11.26 5.9275 9.16667 8.50016 9.16667C11.0728 9.16667 13.1668 11.26 13.1668 13.8333C13.1668 14.2013 12.8688 14.5 12.5002 14.5C12.1315 14.5 11.8335 14.2013 11.8335 13.8333C11.8335 11.9953 10.3382 10.5 8.50016 10.5C6.66216 10.5 5.16683 11.9953 5.16683 13.8333C5.16683 14.2013 4.86883 14.5 4.50016 14.5C4.1315 14.5 3.8335 14.2013 3.8335 13.8333Z'
      />
      <mask
        id='mask0_3784_25527'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='3'
        y='2'
        width='11'
        height='13'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.8335 5.16667C9.8335 4.43133 9.2355 3.83333 8.50016 3.83333C7.76483 3.83333 7.16683 4.43133 7.16683 5.16667C7.16683 5.902 7.76483 6.5 8.50016 6.5C9.2355 6.5 9.8335 5.902 9.8335 5.16667ZM11.1668 5.16667C11.1668 6.63733 9.97083 7.83333 8.50016 7.83333C7.0295 7.83333 5.8335 6.63733 5.8335 5.16667C5.8335 3.696 7.0295 2.5 8.50016 2.5C9.97083 2.5 11.1668 3.696 11.1668 5.16667ZM3.8335 13.8333C3.8335 11.26 5.9275 9.16667 8.50016 9.16667C11.0728 9.16667 13.1668 11.26 13.1668 13.8333C13.1668 14.2013 12.8688 14.5 12.5002 14.5C12.1315 14.5 11.8335 14.2013 11.8335 13.8333C11.8335 11.9953 10.3382 10.5 8.50016 10.5C6.66216 10.5 5.16683 11.9953 5.16683 13.8333C5.16683 14.2013 4.86883 14.5 4.50016 14.5C4.1315 14.5 3.8335 14.2013 3.8335 13.8333Z'
        />
      </mask>
      <g mask='url(#mask0_3784_25527)'>
        <rect x='0.5' y='0.5' width='16' height='16' />
      </g>
    </SvgIcon>
  );
});

import { Grid, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import EntityDataBlock, {
  EntityDataBlockProps,
} from "@/common/components/EntityData/EntityDataBlock";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, CustomerDto, CustomerReferenceDto } from "@/core/api/generated";

import CustomerContactCard from "./CustomerContactCard";

interface BaseProps extends Pick<EntityDataBlockProps, "withDetailsToggle" | "isDetailsVisible"> {
  withLink?: boolean;
  briefDataProps?: {
    address?: boolean;
  };
  detailedDataProps?: {
    address?: boolean;
    contacts?: boolean;
    primaryContactOnly?: boolean;
  };
  sx?: SxProps<Theme>;
}

type Props =
  | ({ variant: "full"; customer?: CustomerDto } & BaseProps)
  | ({ variant: "reference"; customer?: CustomerReferenceDto } & BaseProps);

const defaultBriefDataProps: Props["briefDataProps"] = {
  address: true,
};
const defaultDetailedDataProps: Props["detailedDataProps"] = {
  address: true,
  contacts: true,
  primaryContactOnly: false,
};

/** Customer info as block element. */
export default function CustomerBlock({
  variant,
  customer,
  withLink,
  briefDataProps = defaultBriefDataProps,
  detailedDataProps = defaultDetailedDataProps,
  sx,
  ...entityDataBlockProps
}: Props) {
  briefDataProps = { ...defaultBriefDataProps, ...briefDataProps };
  detailedDataProps = {
    ...defaultDetailedDataProps,
    ...detailedDataProps,
  };

  const { hasPermissions } = useAuthorizationInfo();

  const [fetchedCustomer, setFetchedCustomer] = useState<CustomerDto | null>(null);

  useEffect(() => {
    (async () => {
      if (!!customer && variant === "reference") {
        try {
          const response = await apiClient.customersApi.apiV1CustomersCustomerIdGet({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            customerId: customer.id as string,
          });
          setFetchedCustomer(response.data);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [customer && customer.id]);

  if (!customer) {
    return null;
  }

  const fullDto = fetchedCustomer || (customer as CustomerDto | undefined);

  return (
    <EntityDataBlock
      sx={{
        flex: 1,
        ...sx,
      }}
      {...entityDataBlockProps}
      to={
        withLink && hasPermissions([AppPermission.FleetAppAccess, AppPermission.CustomerRead])
          ? ROUTE_PATH.CUSTOMER_VIEW(customer.id)
          : undefined
      }
      title={
        <>
          <AppIcon of='customer' inText /> Customer
        </>
      }
      briefContent={
        <>
          <Typography component='div' variant='body1'>
            {customer.name || customer.contact?.personName?.name}
          </Typography>
          <Typography component='div' variant='body2'>
            <InlineApiEnumValue type='CustomerType' value={customer.type} />
          </Typography>
          {!!customer.contact?.email && (
            <Typography component='div' variant='body2'>
              {customer.contact?.email}
            </Typography>
          )}
        </>
      }
      detailedContent={
        <>
          <Grid container columnSpacing={2} rowSpacing={1}>
            <Grid item xxs={12} md minWidth={250}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Name'>
                  {customer.name || customer.contact?.personName?.name}
                </FieldValue>
                <FieldValue label='Email' withCopyButton isEmpty={!customer.contact?.email}>
                  {customer.contact?.email}
                </FieldValue>
                <FieldValue label='Type'>
                  <InlineApiEnumValue type='CustomerType' value={fullDto?.type} />
                </FieldValue>
                {fullDto?.notes && <FieldValue label='Notes'>{fullDto?.notes || "-"}</FieldValue>}
              </Stack>
            </Grid>
            <Grid item xxs={12} md minWidth={250}>
              <Stack direction='column' spacing={1}>
                {detailedDataProps.contacts && fullDto?.contacts && (
                  <>
                    <Typography component='div' variant='subtitle2'>
                      Contacts
                    </Typography>
                    <Stack direction='column' spacing={1}>
                      {detailedDataProps.primaryContactOnly && (
                        <CustomerContactCard contact={fullDto?.contact} />
                      )}
                      {!detailedDataProps.primaryContactOnly && (
                        <>
                          {(fullDto?.contacts || []).map((contact) => (
                            <CustomerContactCard
                              key={contact.id}
                              contact={contact}
                              sx={{ width: "100%" }}
                            />
                          ))}
                        </>
                      )}
                    </Stack>
                  </>
                )}

                {detailedDataProps.address && fullDto?.address && (
                  <>
                    <Typography component='div' variant='subtitle2'>
                      Address
                    </Typography>
                    <FieldValue label='Country' withCopyButton>
                      {fullDto?.address?.country}
                    </FieldValue>
                    <FieldValue label='City' withCopyButton>
                      {fullDto?.address?.city}
                    </FieldValue>
                    <FieldValue label='State' withCopyButton>
                      {fullDto?.address?.state}
                    </FieldValue>
                    <FieldValue label='Line 1' withCopyButton>
                      {fullDto?.address?.line1}
                    </FieldValue>
                    <FieldValue label='Line 2' withCopyButton>
                      {fullDto?.address?.line2}
                    </FieldValue>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </>
      }
    />
  );
}

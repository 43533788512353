import { Stack, Typography, TypographyProps } from "@mui/material";

import AppIcon, { AppIconProps } from "../Icons/AppIcon";

interface Props {
  title?: string;
  description?: string;
  titleProps?: TypographyProps<"div">;
  descriptionProps?: TypographyProps<"div">;
  iconProps?: Partial<AppIconProps>;
}

export default function ExpiredAlert({
  title,
  description,
  titleProps,
  descriptionProps,
  iconProps,
}: Props): JSX.Element {
  return (
    <Stack direction='column' spacing={1} sx={{ alignItems: "center", px: 4, py: 2 }}>
      <AppIcon of='expired' fontSize='medium' color='secondary' {...iconProps} />

      <Typography component='div' variant='body2' {...titleProps}>
        {title || "Expired"}
      </Typography>

      {description && (
        <Typography component='div' variant='caption' {...descriptionProps}>
          {description}
        </Typography>
      )}
    </Stack>
  );
}

import { mapEntityTypeToGeneralScopeType } from "@/common/helpers/entity/entityComments";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { ChatType, EntityType } from "@/core/api/generated";
import { LinearProgress } from "@mui/material";
import EmbeddedChat from "./EmbeddedChat";

interface EntityCommentsChatProps {
  entityId: string;
  entityType: EntityType;
}

export default function EntityCommentsChat({ entityId, entityType }: EntityCommentsChatProps) {
  const chatRequest = useApiRequest(
    apiClient.chatsApi.apiV1ChatsCommentEntityGetOrCreatePost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      getOrCreateCommentChatDto: {
        scope: {
          identifier: entityId,
          type: mapEntityTypeToGeneralScopeType(entityType),
        },
      },
    },
    {
      skip: !entityId,
      deps: [entityId, entityType],
    },
  );

  if (chatRequest.isLoading) {
    return <LinearProgress />;
  }

  return (
    <EmbeddedChat
      chatType={ChatType.Comment}
      chatId={chatRequest.data?.id}
      chatProps={{
        size: "medium",
      }}
    />
  );
}

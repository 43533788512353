import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Paper,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";
import NoDataAlert from "../AppAlerts/NoDataAlert";

export interface Props<TCard> {
  items?: TCard[];
  header: ReactNode;
  footer?: ReactNode;
  isLoading?: boolean;
  isFolded?: boolean;
  /** List items. */
  children: (item: TCard, index: number) => ReactNode;
  sx?: SxProps<Theme>;
}

export default function CardList<TCard>({
  header,
  footer,
  items,
  isLoading,
  isFolded,
  children,
}: Props<TCard>) {
  return (
    <Stack direction='column'>
      {/* Header */}
      <Card
        sx={{
          maxHeight: "64px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <CardContent sx={{ pb: 0 }}>{header}</CardContent>
      </Card>

      {/* Content */}
      <Box>
        {/* Loading */}
        {_.isEmpty(items) && isLoading && (
          <Card>
            <CardContent>
              <LinearProgress />
            </CardContent>
          </Card>
        )}

        {/* No data */}
        {_.isEmpty(items) && !isLoading && !isFolded && (
          <Card>
            <CardContent>
              <NoDataAlert />
            </CardContent>
          </Card>
        )}

        {/* Items */}
        {!isFolded &&
          items?.map((item, index) => (
            <Card key={index}>
              <CardContent>{children(item, index)}</CardContent>
            </Card>
          ))}
      </Box>

      {/* Footer */}
      {footer && (
        <Card
          sx={{
            minHeight: 20,
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          <CardContent>{footer}</CardContent>
        </Card>
      )}
      {!footer && (
        <Paper
          sx={{
            minHeight: 20,
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          {footer}
        </Paper>
      )}
    </Stack>
  );
}

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";

import CustomerPaginatedList from "@/common/components/Entity/Customer/ListView/CustomerPaginatedList";

export default function CustomersPage() {
  return (
    <>
      <CustomerPaginatedList />
    </>
  );
}

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import AccessoryCheckPaginatedList from "@/common/components/Entity/AccessoryCheck/ListView/AccessoryCheckPaginatedList";

export default function AccessoryChecksPage() {
  return (
    <>
      <AccessoryCheckPaginatedList />
    </>
  );
}

import { Box, DialogActions, DialogTitleProps, Stack, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import { AppModalProps } from "./AppModal";

interface Props {
  modalProps: Partial<AppModalProps>;
  submitButton?: ReactNode;
  cancelButton?: ReactNode;
  isAutoCloseOnCancel?: boolean;
  sx?: SxProps<Theme>;
  children?: DialogTitleProps["children"];
}

export default function AppModalActions({
  modalProps,
  submitButton,
  cancelButton,
  isAutoCloseOnCancel = true,
  sx,
  children,
}: Props) {
  return (
    <Stack
      direction={{ xxs: "column", md: "row" }}
      spacing={1}
      sx={{
        m: 0,
        p: 0,
        justifyContent: { xxs: "flex-start", md: "flex-end" },
        alignItems: { xxs: "flex-start", md: "flex-start" },
        ...sx,
      }}
    >
      {children}

      {cancelButton && (
        <Box
          sx={{ display: "flex" }}
          onClick={() =>
            isAutoCloseOnCancel && modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown")
          }
        >
          {cancelButton}
        </Box>
      )}

      {submitButton}
    </Stack>
  );
}

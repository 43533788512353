import { Box, Stack, Typography } from "@mui/material";

import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralPriceDisplay from "@/common/components/Entity/General/GeneralPrice/GeneralPriceDisplay";
import {
  GeneralCurrencyDto,
  SubscriptionPlanExtraOptionDto,
  SubscriptionPlanIncludedOptionDto,
  SubscriptionPlanOptionForVehicleType,
  SubscriptionPlanOptionType,
} from "@/core/api/generated";
import _ from "lodash";
import AccessoryDisplay from "@/common/components/Entity/Accessory/AccessoryDisplay";

interface Props {
  optionType: "included" | "extra";
  options:
    | Array<SubscriptionPlanIncludedOptionDto | SubscriptionPlanExtraOptionDto>
    | null
    | undefined;
  currency: GeneralCurrencyDto | null | undefined;
}

export default function SubscriptionPlanOptionList({ optionType, options, currency }: Props) {
  if (!options || _.isEmpty(options)) {
    return null;
  }

  return (
    <Stack spacing={1}>
      {options.map((option, i) => {
        const includedOption =
          optionType === "included" ? (option as SubscriptionPlanIncludedOptionDto) : undefined;
        const extraOption =
          optionType === "extra" ? (option as SubscriptionPlanExtraOptionDto) : undefined;

        return (
          <Stack key={i} spacing={1}>
            <Typography component='div' variant='subtitle1'>
              {i + 1}.{" "}
              <InlineApiEnumValue type='SubscriptionPlanOptionType' value={option.option!.type} />
            </Typography>

            {/* Option */}
            <Stack spacing={1}>
              <FieldValue label='Name' direction='column' isEmpty={_.isNil(option.option!.name)}>
                {option.option!.name}
              </FieldValue>

              <FieldValue
                label='Description'
                direction='column'
                isEmpty={_.isNil(option.option!.description)}
              >
                {option.option!.description}
              </FieldValue>
            </Stack>

            <Box>
              {option.option!.type === SubscriptionPlanOptionType.Accessory && (
                <FieldValue label='Accessory' direction='column'>
                  <AccessoryDisplay accessory={option.option!.accessory} />
                </FieldValue>
              )}

              {option.option!.type === SubscriptionPlanOptionType.ForVehicle && (
                <>
                  {option.option!.forVehicle?.type ===
                    SubscriptionPlanOptionForVehicleType.Mileage && (
                    <FieldValue label='Mileage' direction='column'>
                      {option.option!.forVehicle?.mileage}
                    </FieldValue>
                  )}
                </>
              )}
            </Box>

            {/* ExtraOption */}
            {extraOption && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xxs: "repeat(1, 1fr)",
                    md: "repeat(3, 1fr)",
                  },
                  gridTemplateRows: "auto",
                  rowGap: 1,
                  columnGap: 1,
                }}
              >
                <FieldValue
                  label='Regularity type'
                  direction='column'
                  isEmpty={!extraOption.regularityType}
                >
                  <InlineApiEnumValue
                    type='PaymentRegularityType'
                    value={extraOption.regularityType}
                  />
                </FieldValue>

                <FieldValue label='Price' direction='column' isEmpty={_.isNil(extraOption.price)}>
                  <GeneralPriceDisplay
                    price={{
                      price: extraOption.price,
                      currency: currency || undefined,
                    }}
                  />
                </FieldValue>

                <FieldValue
                  label='Discount'
                  direction='column'
                  isEmpty={_.isNil(extraOption.discount)}
                >
                  <GeneralDiscountDisplay discount={extraOption.discount} currency={currency} />
                </FieldValue>
              </Box>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
}

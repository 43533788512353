import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import _ from "lodash";
import { ReactNode, useCallback, useEffect, useState } from "react";

import { ROUTE_PATH } from "@/common/constants/routing";
import { FileHelper } from "@/common/helpers/file";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, VehicleActualStateDto, VehicleDamageDto } from "@/core/api/generated";

import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import Datetime from "../../../Datetime/Datetime";
import FieldValue from "../../../Form/Display/FieldValue";
import AppIcon from "../../../Icons/AppIcon";
import LoadingMenuItem from "../../../Menu/LoadingMenuItem";
import MenuWithTrigger from "../../../Menu/MenuWithTrigger";
import VehicleDamagePaginatedList, {
  VehicleDamagePaginatedListTabs,
} from "../../VehicleDamage/VehicleDamagePaginatedList";
import VehicleDamageViewModal from "../../VehicleDamage/View/VehicleDamageViewModal";
import VehicleBlock from "../VehicleBlock";
import VehicleLink from "../VehicleLink";

const defaultDisplayProps = {
  header: true,
  actions: true,
  vehicle: true,
  breadcrumbs: true,
};

const defaultHeaderProps = {
  withLink: false,
  title: undefined as ReactNode | undefined,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

type OwnProps = {
  vehicleId?: string;
  headerProps?: Partial<typeof defaultHeaderProps>;
  displayProps?: Partial<typeof defaultDisplayProps>;
};

type Props = OwnProps;

export default function VehicleActualStateView({
  vehicleId,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = { ...defaultDisplayProps, ...displayProps };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [vehicleActualState, setVehicleActualState] = useState<VehicleActualStateDto | undefined>(
    undefined,
  );
  const [isVehicleDamageViewModalOpen, setIsVehicleDamageViewModalOpen] = useState(false);
  const [vehicleDamageToView, setVehicleDamageToView] = useState<VehicleDamageDto | undefined>(
    undefined,
  );

  const apiParams: Parameters<typeof apiClient.vehiclesApi.apiV1VehiclesVehicleIdActualStateGet> = [
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicleId || "",
    },
  ];

  const getVehicleActualState = useCallback(
    async (
      ...args: Parameters<typeof apiClient.vehiclesApi.apiV1VehiclesVehicleIdActualStateGet>
    ) => {
      setIsLoading(true);
      try {
        const response = await apiClient.vehiclesApi.apiV1VehiclesVehicleIdActualStateGet(...args);
        setVehicleActualState(response.data);
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const getVehicleActualStateNormal = useCallback(
    _.debounce(getVehicleActualState, 500, {
      leading: true,
      trailing: false,
    }),
    [getVehicleActualState],
  );

  useEffect(() => {
    getVehicleActualStateNormal(...apiParams);
  }, [vehicleId]);

  const groupedByVisualModel = _.groupBy(vehicleActualState?.damages, (d) => {
    return d.visualModel?.id;
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps.breadcrumbs && {
        idValue: vehicleActualState?.vehicle?.id || "",
        newTitle: vehicleActualState?.vehicle?.localNumber || "",
      }) ||
      undefined,
  });

  return (
    <Stack spacing={2}>
      {displayProps?.header && (
        <DetailedViewPageHeader
          image={undefined}
          title={
            <Box>
              {headerProps?.title || (
                <Typography component='div' variant='h4'>
                  <span>Actual state of </span>
                  <VehicleLink entity={vehicleActualState?.vehicle} withIcon={false} />
                </Typography>
              )}
            </Box>
          }
          titleProps={{
            to: headerProps?.withLink ? ROUTE_PATH.VEHICLE_ACTUAL_STATE(vehicleId) : undefined,
            typographyProps: headerProps?.typographyProps,
          }}
          secondaryActions={
            displayProps.actions && (
              <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                <MenuWithTrigger
                  withAuthCloseOnClick={false}
                  trigger={
                    <IconButton sx={{ ml: "auto" }}>
                      <AppIcon of='moreVert' />
                    </IconButton>
                  }
                >
                  {(params) => (
                    <LoadingMenuItem
                      key='downloadPdf'
                      onClick={async () => {
                        const downloadResult = await FileHelper.getDownloadFileApiResult(() =>
                          apiClient.vehiclesApi.apiV1VehiclesVehicleIdActualStatePdfGet(
                            {
                              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                              vehicleId: vehicleId!,
                            },
                            {
                              responseType: "blob",
                            },
                          ),
                        );
                        FileHelper.downloadBlobFromApiResult(downloadResult);
                        params.handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='download' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Download PDF</ListItemText>
                    </LoadingMenuItem>
                  )}
                </MenuWithTrigger>
              </AuthorizedElement>
            )
          }
        />
      )}

      {displayProps?.vehicle && (
        <VehicleBlock
          variant='full'
          vehicle={vehicleActualState?.vehicle}
          withDetailsToggle
          withLink
        />
      )}

      <Grid container rowSpacing={1}>
        <Grid item xxs={12} md minWidth={200}>
          <Stack direction='column' spacing={1}>
            <FieldValue label='Mileage'>{vehicleActualState?.vehicle?.mileage}</FieldValue>

            <FieldValue label='Total damages'>
              {vehicleActualState?.vehicle?.totalDamagesCount}
            </FieldValue>

            <FieldValue label='Actual damages'>
              {vehicleActualState?.vehicle?.actualDamagesCount}
            </FieldValue>

            <FieldValue label='Repaired damages'>
              {vehicleActualState?.vehicle?.repairedDamagesCount}
            </FieldValue>
          </Stack>
        </Grid>
        <Grid item xxs={12} md minWidth={200} sx={{ ml: 2 }}>
          <Stack direction='column' spacing={1}>
            <FieldValue
              label='Last visual inspection at'
              isEmpty={!vehicleActualState?.vehicle?.lastVisualInspectionAt}
            >
              <Datetime datetime={vehicleActualState?.vehicle?.lastVisualInspectionAt} />
            </FieldValue>

            <FieldValue
              label='Last damage detection at'
              isEmpty={!vehicleActualState?.vehicle?.lastDamageDetectionAt}
            >
              <Datetime datetime={vehicleActualState?.vehicle?.lastDamageDetectionAt} />
            </FieldValue>

            <FieldValue
              label='Last accessory check at'
              isEmpty={!vehicleActualState?.vehicle?.lastAccessoryCheckAt}
            >
              <Datetime datetime={vehicleActualState?.vehicle?.lastAccessoryCheckAt} />
            </FieldValue>

            <FieldValue
              label='Last mileage update at'
              isEmpty={!vehicleActualState?.vehicle?.lastMileageUpdatedAt}
            >
              <Datetime datetime={vehicleActualState?.vehicle?.lastMileageUpdatedAt} />
            </FieldValue>
          </Stack>
        </Grid>
      </Grid>

      {isLoading && <LinearProgress />}

      <Typography component='div' variant='h2'>
        Damages
      </Typography>

      <VehicleDamagePaginatedList
        vehicleId={vehicleId}
        defaultValues={{
          activeTab: VehicleDamagePaginatedListTabs.Actual,
        }}
        displayProps={{
          header: false,
          filters: false,
          pagination: true,
          vehicleDamagesWithModels: true,
        }}
      />

      {/* Vehicle damage view modal */}
      {vehicleDamageToView && (
        <VehicleDamageViewModal
          open={isVehicleDamageViewModalOpen}
          onClose={() => {
            setIsVehicleDamageViewModalOpen(false);
            setVehicleDamageToView(undefined);
          }}
          viewProps={{
            vehicleId: vehicleDamageToView.vehicle?.id,
            vehicleDamageId: vehicleDamageToView.id,
          }}
        />
      )}
    </Stack>
  );
}

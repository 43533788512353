import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ContractDto } from "@/core/api/generated";

import { pageTabHeaderTypographyProps } from "@/App/Layouts/PageBody/PageTabHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import VehicleView from "@/common/components/Entity/Vehicle/View/VehicleView";

interface Props {
  contract: ContractDto;
  viewVariant?: ViewLayoutVariant;
}

export default function VehicleTabContent({ contract, viewVariant }: Props) {
  return (
    // todo: refactor
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <VehicleView
          vehicleId={contract.vehicle?.id}
          vehicle={undefined}
          displayProps={{
            breadcrumbs: false,
            header: false,
            actions: true,
            viewVariant: ViewLayoutVariant.Tab,
          }}
          headerProps={{
            withLink: true,
            typographyProps: pageTabHeaderTypographyProps.title,
          }}
        />
      </Stack>
    </PageTabContent>
  );
}

import { Box } from "@mui/material";
import FileUploader, { FileUploaderProps } from "./FileUploader";

type Props = FileUploaderProps;

export default function ImageUploader({ accept, onChange, ...otherProps }: Props) {
  return (
    <Box>
      <FileUploader accept={accept ?? "image/*"} onChange={onChange} {...otherProps} />
    </Box>
  );
}

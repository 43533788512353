import { Alert, Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import VehicleOperations, {
  VehicleOperationsTabs,
} from "@/common/components/EntityComposed/Operations/VehicleOperations";
import { DamageDetectionDto } from "@/core/api/generated";

interface Props {
  damageDetection: DamageDetectionDto;
}

export default function RelatedOperationsTabContent({ damageDetection }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <Alert severity='info'>
          Here you can see related operations. For instance, operations for the same damages or
          operations that are in the same context.
        </Alert>

        <VehicleOperations
          vehicleId={damageDetection.vehicle?.id}
          anyVehicleDamageIds={damageDetection.items
            ?.map((x) => x.damage?.id || "")
            .filter((x) => !!x)}
          displayProps={{
            header: false,
            filters: false,
            viewVariant: ViewLayoutVariant.Tab,
          }}
          excludeTabs={[
            VehicleOperationsTabs.DamageDetections,
            VehicleOperationsTabs.AccessoryChecks,
          ]}
        />
      </Stack>
    </PageTabContent>
  );
}

import AdminTenantRolesSelector from "@/common/components/Entity/Role/AdminTenantRolesSelector";
import AppModal, { AppModalProps } from "@/common/components/Modals/AppModal";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { UserDto } from "@/core/api/generated";
import { LoadingButton } from "@mui/lab";
import { Box, DialogContent, FormControl, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import AdminUserAutocomplete from "../../components/Entity/User/UserAutocomplete";

interface Props extends Omit<AppModalProps, "children"> {
  tenantId: string;
  onAdded?: () => void;
}

export default function AddUserToTenantModal({ tenantId, onAdded, ...modalProps }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<UserDto | undefined>();
  const [roleIds, setRoleIds] = useState<string[]>([]);

  const reset = () => {
    setSelectedCandidate(undefined);
  };

  useEffect(() => {
    if (!modalProps.open) {
      reset();
    }
  }, [modalProps.open]);

  const handleAdd = async () => {
    setIsSubmitting(true);

    try {
      await apiClient.adminUsersApi.apiV1AdminUsersTenantPost({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        adminAddUserToTenantDto: {
          tenantId: tenantId,
          userId: selectedCandidate?.id,
          roleIds: roleIds,
        },
      });
      enqueueSnackbar("User added to tenant", {
        variant: "success",
      });
      modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown");
      onAdded && onAdded();
      reset();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AppModal fullWidth keepMounted={false} {...modalProps}>
      <AppModalTitle
        title={"Add user to tenant"}
        withCloseIcon
        onCloseClicked={() => modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown")}
      />
      <DialogContent>
        <Stack spacing={2}>
          <Box>
            <FormControl margin='dense' fullWidth>
              <AdminUserAutocomplete
                fullWidth
                required
                userId={selectedCandidate?.id}
                searchFilters={{
                  notForTenantId: tenantId || undefined,
                }}
                onChange={(newValue) => setSelectedCandidate(newValue)}
              />
              {/* <Autocomplete
                fullWidth
                open={autoCompleteOpen}
                onOpen={() => setAutoCompleteOpen(true)}
                onClose={() => setAutoCompleteOpen(false)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.personName!.name!}
                filterOptions={(x) => x}
                options={candidates || []}
                autoComplete
                includeInputInList
                value={selectedCandidate}
                onChange={(e, newOption) => {
                  setSelectedCandidate(newOption || undefined);
                }}
                onInputChange={(e, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Users'
                    placeholder='Search by name or email...'
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {paginatedUserCandidatesRequest.isLoading ? (
                            <CircularProgress color='inherit' size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <ListItem {...props} key={option.id}>
                      <ListItemIcon>
                        <AppIcon of='person' color='secondary' />
                      </ListItemIcon>
                      <ListItemText primary={option.personName?.name} secondary={option.email} />
                    </ListItem>
                  );
                }}
              /> */}
            </FormControl>
            <FormControl margin='none' fullWidth>
              <AdminTenantRolesSelector
                tenantId={tenantId}
                roleIds={roleIds}
                onChange={(newValue) => setRoleIds(newValue?.map((x) => x.id!) || [])}
              />
            </FormControl>
          </Box>

          <LoadingButton
            fullWidth
            variant='contained'
            disabled={!selectedCandidate}
            loading={isSubmitting}
            onClick={handleAdd}
          >
            Save
          </LoadingButton>
        </Stack>
      </DialogContent>
    </AppModal>
  );
}

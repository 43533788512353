import { Grid, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import EntityDataBlock, {
  EntityDataBlockProps,
} from "@/common/components/EntityData/EntityDataBlock";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { apiClient } from "@/core/api/ApiClient";
import { VehiclePartTypeDto, VehiclePartTypeReferenceDto } from "@/core/api/generated";
import InlineApiEnumValueList from "../../../Enum/InlineApiEnumValueList";

interface BaseProps extends Pick<EntityDataBlockProps, "withDetailsToggle" | "isDetailsVisible"> {
  withLink?: boolean;
  briefDataProps?: {
    tempPlaceholder?: boolean;
  };
  detailedDataProps?: {
    tempPlaceholder?: boolean;
  };
  sx?: SxProps<Theme>;
}

type Props =
  | ({ variant: "full"; vehiclePartType?: VehiclePartTypeDto } & BaseProps)
  | ({ variant: "reference"; vehiclePartType?: VehiclePartTypeReferenceDto } & BaseProps);

const defaultBriefDataProps: Props["briefDataProps"] = {
  tempPlaceholder: true,
};
const defaultDetailedDataProps: Props["detailedDataProps"] = {
  tempPlaceholder: true,
};

/** VehiclePartType info as block element. */
export default function VehiclePartTypeBlock({
  variant,
  vehiclePartType,
  withLink,
  briefDataProps = defaultBriefDataProps,
  detailedDataProps = defaultDetailedDataProps,
  sx,
  ...entityDataBlockProps
}: Props) {
  briefDataProps = { ...defaultBriefDataProps, ...briefDataProps };
  detailedDataProps = {
    ...defaultDetailedDataProps,
    ...detailedDataProps,
  };

  const { hasPermissions } = useAuthorizationInfo();

  const [fetchedVehiclePartType, setFetchedVehiclePartType] = useState<VehiclePartTypeDto | null>(
    null,
  );

  useEffect(() => {
    (async () => {
      if (!!vehiclePartType && variant === "reference") {
        try {
          const response =
            await apiClient.vehiclePartTypesApi.apiV1ReferenceDataVehiclesPartTypesPartTypeIdGet({
              partTypeId: vehiclePartType.id!,
            });
          setFetchedVehiclePartType(response.data);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [vehiclePartType && vehiclePartType.id]);

  if (!vehiclePartType) {
    return null;
  }

  const fullDto = fetchedVehiclePartType || (vehiclePartType as VehiclePartTypeDto | undefined);

  return (
    <EntityDataBlock
      sx={{
        flex: 1,
        ...sx,
      }}
      {...entityDataBlockProps}
      // TODO
      // to={
      //   withLink &&
      //   hasPermissions([AppPermission.FleetAppAccess, AppPermission.VehiclePartTypeRead])
      //     ? ROUTE_PATH.CONTRACT_VIEW(vehiclePartType.id)
      //     : undefined
      // }
      title={
        <>
          <AppIcon of='vehiclePartType' inText /> Vehicle part type
        </>
      }
      briefContent={
        <>
          <Typography component='div' variant='body1'>
            {vehiclePartType?.name}
          </Typography>
          {vehiclePartType?.description && (
            <Typography component='div' variant='body2'>
              {vehiclePartType?.description}
            </Typography>
          )}
        </>
      }
      detailedContent={
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              {vehiclePartType.globalNumber && (
                <FieldValue label='Global number'>{vehiclePartType.globalNumber}</FieldValue>
              )}
              <FieldValue label='Name'>{vehiclePartType.name}</FieldValue>
              <FieldValue label='Description'>{vehiclePartType.description}</FieldValue>
              {fullDto && (
                <FieldValue label='Built-in'>
                  <BooleanValue value={fullDto.isBuiltIn} />
                </FieldValue>
              )}
              <FieldValue label='Vehicle type'>
                <InlineApiEnumValue
                  type='VehicleType'
                  value={vehiclePartType.vehicleType}
                  withDescription
                />
              </FieldValue>
              <FieldValue label='Category'>
                <InlineApiEnumValue
                  type='VehiclePartCategory'
                  value={vehiclePartType.category}
                  withDescription
                />
              </FieldValue>
              <FieldValue label='Type'>
                <InlineApiEnumValue
                  type='VehiclePartType'
                  value={vehiclePartType.type}
                  withDescription
                />
              </FieldValue>
              <FieldValue label='Areas'>
                <InlineApiEnumValueList type='VehicleArea' values={vehiclePartType.areas} />
              </FieldValue>
              {vehiclePartType.projection && (
                <FieldValue label='Projection'>
                  <InlineApiEnumValue
                    type='VehicleProjection'
                    value={vehiclePartType.projection}
                    withDescription
                  />
                </FieldValue>
              )}
              {vehiclePartType.projections && (
                <FieldValue label='Projections'>
                  <InlineApiEnumValueList
                    type='VehicleProjection'
                    values={vehiclePartType.projections}
                  />
                </FieldValue>
              )}
            </Stack>
          </Grid>
        </Grid>
      }
    />
  );
}

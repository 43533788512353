import {
  Box,
  Divider,
  Grow,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  Stack,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppAvatar from "@/common/components/Avatar/AppAvatar";
import ApiConnectionStatusIndicator from "@/common/components/Connection/ApiConnectionStatusIndicator";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import LastNotifications from "@/common/components/Notification/LastNotifications";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { tenantService } from "@/common/services/tenant";

import DepartmentLink from "@/common/components/Entity/Department/DepartmentLink";
import LocationLink from "@/common/components/Entity/Location/LocationLink";
import HeaderMenuItem from "@/common/components/Menu/HeaderMenuItem";
import { THEMES } from "@/common/constants/common";
import { EnvHelper } from "@/common/helpers/env";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useAppDispatch, useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { apiClient } from "@/core/api/ApiClient";
import * as appCommonSlice from "@/store/appCommon/slice";
import * as authSlice from "@/store/auth/slice";
import { AppPermission, LocationDto } from "../../../core/api/generated";
import CreateTenantModal from "../SelectTenant/CreateTenantModal";

interface BaseTreeViewNode {
  id: string;
  type: "department" | "location";
  name: string;
}
interface DepartmentTreeViewNode extends BaseTreeViewNode {
  type: "department";
  locations: LocationTreeViewNode[];
}

interface LocationTreeViewNode extends BaseTreeViewNode {
  type: "location";
}

interface Props {
  mainContent?: ReactNode;
}

export default function BaseHeader({ mainContent }: Props) {
  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const profile = useUserProfile();
  const authorizationInfo = useAuthorizationInfo();
  const thunkDispatch = useAppThunkDispatch();
  const dispatch = useAppDispatch();
  const isNavOpened = useAppSelector((x) => x.app.isNavOpened);
  const selectedTenant = useAppSelector((x) => x.tenants.selectedTenant);

  const tenantInfo = tenantService.resolveTenant();
  const isTenantSelected = !!tenantInfo?.identifier;

  const [createTenantModalOpen, setCreateTenantModalOpen] = useState(false);

  // popper props
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const handlePopperOpen = useCallback(() => {
    setIsPopperOpen(true);
  }, []);
  const handlePopperClose = useCallback(() => {
    setIsPopperOpen(false);
  }, []);

  const userDepartmentsRequest = useApiRequest(
    apiClient.departmentsApi.apiV1DepartmentsSearchPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      departmentSearchPaginatedDto: {
        ids: profile?.departments?.map((x) => x.id!) || [],
      },
    },
    {
      deps: [profile?.departments],
      skip: !profile || profile.departments?.length === 0,
    },
  );
  const userDepartments = userDepartmentsRequest.data?.items || [];

  const userLocationsRequest = useApiRequest(
    apiClient.locationsApi.apiV1LocationsSearchPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      locationSearchPaginatedDto: {
        ids: profile?.locations?.map((x) => x.id!) || [],
      },
    },
    {
      deps: [profile?.locations],
      skip: !profile || profile.locations?.length === 0,
    },
  );
  const userLocations = userLocationsRequest.data?.items || [];

  const userAffiliationTreeViewInfo = useMemo(() => {
    if (!profile) {
      return [];
    }

    const insertedLocations: LocationDto[] = [];
    const result: BaseTreeViewNode[] = userDepartments.map(
      (dep) =>
        ({
          id: dep.id,
          type: "department",
          name: dep.name,
          locations: userLocations.filter((loc) => {
            const ok = dep.locationIds?.includes(loc.id!);
            if (ok) {
              insertedLocations.push(loc);
            }
            return ok;
          }),
        }) as DepartmentTreeViewNode,
    );

    userLocations.forEach((loc) => {
      if (!insertedLocations.some((x) => x.id === loc.id)) {
        result.push({
          id: loc.id,
          type: "location",
          name: loc.name,
        } as LocationTreeViewNode);
      }
    });

    return result;
  }, [userDepartments, userLocations]);

  const renderAffiliationTreeView = useCallback(function renderNode(nodes: BaseTreeViewNode[]) {
    return nodes.map((node, index) => {
      return (
        <Stack key={index} direction='column'>
          {node.type === "department" ? (
            <DepartmentLink entityId={node.id} />
          ) : (
            <LocationLink entityId={node.id} />
          )}
          {node.type === "department" && (node as DepartmentTreeViewNode).locations.length > 0 && (
            <Stack direction='column' sx={{ pl: 2 }}>
              {renderNode((node as DepartmentTreeViewNode).locations)}
            </Stack>
          )}
        </Stack>
      );
    });
  }, []);

  useEffect(() => {
    // auto-hide initially nav on mobile
    if (isMobile) {
      if (isNavOpened) {
        thunkDispatch(appCommonSlice.toggleNav());
      }
    }
  }, [isDesktop, isMobile]);

  const handleLogout = async () => {
    await thunkDispatch(authSlice.logout());
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flex: "0 0 64px",
        width: "100%",
        backgroundColor: (t) => t.palette.background.paper,
        pr: 1,
        boxShadow: (t) => t.boxShadowCustom?.headerShadow,
        zIndex: (t) => t.zIndex.drawer,
      }}
    >
      {/* {!isNavOpened && ( // todo: adaptive for mobile 
        <IconButton
          id='nav-bar-menu-icon'
          color='secondary'
          onClick={() => {
            thunkDispatch(appCommonSlice.toggleNav());
          }}
          sx={{ mr: 1 }}
        >
          <AppIcon of='menu' />
        </IconButton>
      )} */}

      {/* Custom content */}
      <Box sx={{ width: "100%", mr: 1 }}>{mainContent}</Box>

      <Stack direction='row' spacing={2} sx={{ alignItems: "center", ml: "auto" }}>
        {/* API connection status */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ApiConnectionStatusIndicator />
        </Box>

        {/* Notification bell */}
        {isTenantSelected && (
          <Box>
            <LastNotifications />
          </Box>
        )}

        <Box
          sx={{
            backgroundColor: "background.gray",
            pl: 2,
            borderRadius: (th) => th.shapeCustom.borderRadiusButton,
          }}
        >
          <Stack
            direction='row'
            alignItems='center'
            ref={anchorRef}
            onMouseEnter={() => handlePopperOpen()}
            onMouseLeave={() => handlePopperClose()}
          >
            {/* Current tenant */}
            <Link
              sx={{ width: "max-content" }}
              underline='none'
              component={RouterLink}
              to={ROUTE_PATH.SELECT_TENANT}
            >
              {selectedTenant && selectedTenant.name}
            </Link>
            {/* Department and locations */}
            {profile && (profile.departments?.length !== 0 || profile.locations?.length !== 0) && (
              <Popper
                anchorEl={anchorRef.current}
                open={isPopperOpen}
                transition
                placement='bottom-end'
                sx={{ zIndex: (th) => th.zIndex.modal }}
              >
                {({ TransitionProps }) => (
                  <Grow {...TransitionProps}>
                    <Paper
                      sx={{
                        borderRadius: (th) => th.shape.borderRadius,
                        p: 2,
                      }}
                    >
                      <Stack
                        onMouseLeave={() => handlePopperClose()}
                        sx={{ minWidth: 150, minHeight: 20 }}
                      >
                        {renderAffiliationTreeView(userAffiliationTreeViewInfo)}
                      </Stack>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            )}

            {/* Main header menu */}
            <MenuWithTrigger
              withAuthCloseOnClick
              trigger={
                <IconButton>
                  <AppAvatar profile={profile} size={38} />
                </IconButton>
              }
            >
              <HeaderMenuItem primaryTitle='User' />

              <MenuItem
                sx={{ minWidth: "200px" }}
                component={RouterLink}
                to={
                  ((authorizationInfo.hasFleetAppAccess ||
                    authorizationInfo.hasFleetCustomerAppAccess) &&
                    ROUTE_PATH.FLEET_PROFILE) ||
                  // (authorizationInfo.hasFleetCustomerAppAccess && ROUTE_PATH.FLEET_CUSTOMER_PROFILE) ||
                  ROUTE_PATH.PROFILE
                }
              >
                <ListItemIcon>
                  <AppIcon of='profile' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Profile</ListItemText>
              </MenuItem>

              {!EnvHelper.isProductionAny && (
                <MenuItem
                  sx={{ minWidth: "200px" }}
                  onClick={() =>
                    dispatch(
                      appCommonSlice._themeChange(
                        theme.palette.mode === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT,
                      ),
                    )
                  }
                >
                  <ListItemIcon>
                    <AppIcon of='theme' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Appearance</ListItemText>
                  <Switch checked={theme.palette.mode === THEMES.LIGHT} />
                </MenuItem>
              )}

              <HeaderMenuItem primaryTitle='Company' />

              <AuthorizedElement
                permissions={[AppPermission.FleetAppAccess, AppPermission.TenantManage]}
              >
                <MenuItem
                  sx={{ minWidth: "200px" }}
                  component={RouterLink}
                  to={ROUTE_PATH.MANAGEMENT_TENANT_PROFILE}
                >
                  <ListItemIcon>
                    <AppIcon of='companyProfile' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Company profile</ListItemText>
                </MenuItem>
              </AuthorizedElement>

              <MenuItem
                sx={{ minWidth: "200px" }}
                component={RouterLink}
                to={ROUTE_PATH.SELECT_TENANT}
              >
                <ListItemIcon>
                  <AppIcon of='switchAccount' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Switch company</ListItemText>
              </MenuItem>

              <AuthorizedElement permissions={[AppPermission.TenantCreate]}>
                <MenuItem sx={{ minWidth: "200px" }} onClick={() => setCreateTenantModalOpen(true)}>
                  <ListItemIcon>
                    <AppIcon of='company' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Create new company</ListItemText>
                </MenuItem>
              </AuthorizedElement>

              <Divider />

              <MenuItem onClick={handleLogout} sx={{ minWidth: "200px" }}>
                <ListItemIcon>
                  <AppIcon of='logout' fontSize='small' color='error' />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </MenuWithTrigger>
          </Stack>
        </Box>
      </Stack>

      {/* Create tenant */}
      {createTenantModalOpen && (
        <AuthorizedElement permissions={[AppPermission.TenantCreate]}>
          <CreateTenantModal
            open={createTenantModalOpen}
            onClose={() => setCreateTenantModalOpen(false)}
            onCompanyCreated={() => setCreateTenantModalOpen(false)}
          />
        </AuthorizedElement>
      )}
    </Box>
  );
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "@/core/api/ApiClient";
import {
  ImportFromCatalogInfoDto,
  RepairCatalogDto,
  PaginationDtoOfRepairCatalogDto,
} from "@/core/api/generated";
import { AppThunk } from "@/store";

interface RepairCatalogsState {
  loading: {
    importFromCatalog?: boolean;
  };
  repairCatalog?: RepairCatalogDto;
}

const initialState: RepairCatalogsState = {
  loading: {},
  repairCatalog: undefined,
};

const slice = createSlice({
  name: "RepairCatalogs",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<RepairCatalogsState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    resetRepairCatalog: (state, action: PayloadAction<undefined>) => {
      state.repairCatalog = undefined;
    },
  },
});

export const { setLoading, resetRepairCatalog } = slice.actions;

export const repairCatalogsReducer = slice.reducer;

export const importFromCatalog =
  (
    ...arg: Parameters<typeof apiClient.repairCatalogsApi.apiV1RepairCatalogsImportFromCatalogPost>
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        importFromCatalog: true,
      }),
    );

    try {
      const response = await apiClient.repairCatalogsApi.apiV1RepairCatalogsImportFromCatalogPost(
        ...arg,
      );
    } finally {
      dispatch(
        setLoading({
          importFromCatalog: false,
        }),
      );
    }
  };

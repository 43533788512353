import { Chip } from "@mui/material";

import { DamageCostEvaluationHelper } from "@/common/helpers/entity/damageCostEvaluation";
import AppIcon from "../../Icons/AppIcon";
import AppPopover from "../../Popover/AppPopover";
import AppPopoverContent from "../../Popover/AppPopoverContent";
import CurrencyMismatchWithRepairSpecAlert, {
  CurrencyMismatchWithRepairSpecAlertProps,
} from "./CurrencyMismatchWithRepairSpecAlert";

export default function CurrencyMismatchWithRepairSpecAlertCompact(
  props: CurrencyMismatchWithRepairSpecAlertProps,
) {
  return DamageCostEvaluationHelper.isCurrencyMismatchWithRepairSpec(
    props.entity,
    props.item?.repairSpec,
  ) ? (
    <AppPopover
      hoverBehavior={{}}
      trigger={
        <Chip variant='outlined' size='small' color='warning' label={<AppIcon of='warning' />} />
      }
    >
      <AppPopoverContent sx={{ maxWidth: 300 }}>
        <CurrencyMismatchWithRepairSpecAlert {...props} />
      </AppPopoverContent>
    </AppPopover>
  ) : null;
}

import { FilterValueInputProps } from "@/common/ts/filters";
import { FilterValueType } from "@/core/api/generated";
import ApiEnumsAutocomplete from "../../Enum/ApiEnumsAutocomplete";

interface Props extends FilterValueInputProps {}

export default function FilterArrayOfEnumValueInput({
  fieldSpec,
  item,
  autocompleteProps,
  onChange,
}: Props) {
  if (item.valueType !== FilterValueType.ArrayOfEnum) {
    console.error(`Invalid value type '${item.valueType}'.`);
    return null;
  }

  const enumName = fieldSpec.fieldTypeMeta?.enum?.enumName;
  if (!enumName) {
    console.error(`Enum name is required for enum filter value input (check field type meta).`, {
      fieldSpec,
      item,
    });
    return null;
  }

  return (
    <ApiEnumsAutocomplete
      label='Values'
      {...autocompleteProps}
      type={enumName}
      values={item.valueAsArrayOfEnumTyped(enumName)}
      onChange={(newValue) => {
        onChange(newValue);
      }}
    />
  );
}

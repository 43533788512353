import { FilterValueInputProps } from "@/common/ts/filters";
import { FilterValueType } from "@/core/api/generated";
import { TextField } from "@mui/material";

interface Props extends FilterValueInputProps {}

export default function FilterStringValueInput({ item, textFieldProps, onChange }: Props) {
  if (item.valueType !== FilterValueType.String) {
    console.error(`Invalid value type '${item.valueType}'.`);
    return null;
  }

  return (
    <TextField
      label='Value'
      {...textFieldProps}
      type='text'
      value={item.valueAsString() ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}

import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { Formik, getIn } from "formik";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import { ContactCardData } from "@/common/components/Contact/ContactCard";
import ContactInput from "@/common/components/Contact/ContactInput";
import GeneralAddressInput from "@/common/components/Entity/General/Input/GeneralAddressInput";
import PhoneInput from "@/common/components/Form/Input/PhoneInput";
import AppIcon from "@/common/components/Icons/AppIcon";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { BaseFormikValues, ValidationErrors } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { TenantPaymentProfileInputDto } from "@/core/api/generated";
import { PaymentContactInputDto } from "@/core/api/generated/api";
import * as paymentSlice from "@/store/management/payments/slice";
import { useHistory } from "react-router";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
}));

function PaymentProfileEdit() {
  const mounted = useMounted();
  const profile = useUserProfile();
  const history = useHistory();
  const thunkDispatch = useAppThunkDispatch();
  const paymentProfile = useAppSelector((x) => x.management.payments.paymentProfile);

  const [addNewContactInProcess, setAddNewContactInProcess] = useState(false);

  return (
    <CreateUpdatePageLayout header={<SimpleViewPageHeader title={"Payment profile edit"} />}>
      <Formik<
        BaseFormikValues &
          TenantPaymentProfileInputDto & {
            contactName: string;
            contactEmail: string;
            contactPhoneNumber: string;
          }
      >
        initialValues={{
          businessInfo: {
            businessName: paymentProfile?.businessInfo?.businessName || undefined,
            personName: {
              firstName: paymentProfile?.businessInfo?.personName?.firstName || undefined,
              lastName: paymentProfile?.businessInfo?.personName?.lastName || undefined,
            },
            email: paymentProfile?.businessInfo?.email || undefined,
            phoneNumber: paymentProfile?.businessInfo?.phoneNumber || undefined,
            address: {
              country: paymentProfile?.businessInfo?.address?.country || undefined,
              state: paymentProfile?.businessInfo?.address?.state || undefined,
              city: paymentProfile?.businessInfo?.address?.city || undefined,
              line1: paymentProfile?.businessInfo?.address?.line1 || undefined,
              line2: paymentProfile?.businessInfo?.address?.line2 || undefined,
              postalCode: paymentProfile?.businessInfo?.address?.postalCode || undefined,
            },
          },

          contacts: paymentProfile?.contacts
            ? paymentProfile.contacts.map((c) => ({
                personName: c.personName,
                email: c.email,
                phoneNumber: c.phoneNumber,
                isPrimary: c.isPrimary,
              }))
            : new Array<PaymentContactInputDto>(),

          contactName: "",
          contactEmail: "",
          contactPhoneNumber: "",

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // businessInfo: Yup.object().shape({
          //   businessName: Yup.string().max(100).nullable().required("Business name is required"),
          //   name: Yup.string().max(100).nullable().required("Name is required"),
          //   email: Yup.string()
          //     .nullable()
          //     .email("Must be a valid email")
          //     .max(255)
          //     .required("Email is required"),
          //   phoneNumber: Yup.string().nullable().required("Phone number is required"),
          //   address: Yup.object().shape({
          //     country: Yup.string().nullable().required("Country is required"),
          //     state: Yup.string().nullable().required("State is required"),
          //     city: Yup.string().nullable().required("City is required"),
          //     line1: Yup.string().nullable().required("Line 1 is required"),
          //     postalCode: Yup.string().nullable().required("Postal code is required"),
          //   }),
          // }),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            await thunkDispatch(
              paymentSlice.setPaymentProfile({
                businessInfo: {
                  businessName: values.businessInfo?.businessName || undefined,
                  personName: values.businessInfo?.personName || undefined,
                  email: values.businessInfo?.email || undefined,
                  phoneNumber: values.businessInfo?.phoneNumber || undefined,
                  address: {
                    country: values.businessInfo?.address?.country || undefined,
                    state: values.businessInfo?.address?.state || undefined,
                    city: values.businessInfo?.address?.city || undefined,
                    line1: values.businessInfo?.address?.line1 || undefined,
                    line2: values.businessInfo?.address?.line2 || undefined,
                    postalCode: values.businessInfo?.address?.postalCode || undefined,
                  },
                },
                contacts: values.contacts,
              }),
            );

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
            history.goBack();
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Button
                variant='text'
                disabled={!profile}
                onClick={() => {
                  setFieldValue("businessInfo.personName", profile!.personName);
                  setFieldValue("businessInfo.email", profile!.email);
                  setFieldValue("businessInfo.phoneNumber", profile!.phoneNumber);
                  if (!values.contacts!.some((x) => x.email === profile!.email)) {
                    const contact: PaymentContactInputDto = {
                      personName: profile!.personName,
                      email: profile!.email,
                      phoneNumber: profile!.phoneNumber,
                      isPrimary: !values.contacts!.some((x) => x.isPrimary),
                    };
                    setFieldValue("contacts", [...values.contacts!, contact]);
                  }
                }}
              >
                Use my profile data
              </Button>

              <Box>
                <Stack direction='column' spacing={0}>
                  <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={1}>
                    <FormControl
                      error={Boolean(getIn(errors, `businessInfo.businessName`))}
                      sx={{ width: "50%" }}
                    >
                      <TextField
                        error={Boolean(getIn(errors, `businessInfo.businessName`))}
                        autoFocus
                        fullWidth
                        required
                        label='Business name'
                        margin='normal'
                        name='businessInfo.businessName'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={values.businessInfo?.businessName || ""}
                        variant='outlined'
                      />
                      <FormHelperText>{getIn(errors, `businessInfo.businessName`)}</FormHelperText>
                    </FormControl>
                    <FormControl
                      error={Boolean(getIn(errors, `businessInfo.personName.firstName`))}
                      sx={{ width: "50%" }}
                    >
                      <TextField
                        error={Boolean(getIn(errors, `businessInfo.personName.firstName`))}
                        fullWidth
                        required
                        label='FirstName'
                        margin='normal'
                        name='businessInfo.personName.firstName'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={values.businessInfo?.personName?.firstName || ""}
                        variant='outlined'
                      />
                      <FormHelperText>
                        {getIn(touched, `businessInfo.personName.firstName`) &&
                          getIn(errors, `businessInfo.personName.firstName`)}
                      </FormHelperText>
                    </FormControl>
                    <FormControl
                      error={Boolean(getIn(errors, `businessInfo.personName.lastName`))}
                      sx={{ width: "50%" }}
                    >
                      <TextField
                        error={Boolean(getIn(errors, `businessInfo.personName.lastName`))}
                        fullWidth
                        required
                        label='LastName'
                        margin='normal'
                        name='businessInfo.personName.lastName'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={values.businessInfo?.personName?.lastName || ""}
                        variant='outlined'
                      />
                      <FormHelperText>
                        {getIn(touched, `businessInfo.personName.lastName`) &&
                          getIn(errors, `businessInfo.personName.lastName`)}
                      </FormHelperText>
                    </FormControl>
                  </Stack>

                  <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={1}>
                    <FormControl
                      error={Boolean(getIn(errors, `businessInfo.email`))}
                      sx={{ width: "50%" }}
                    >
                      <TextField
                        error={Boolean(getIn(errors, `businessInfo.email`))}
                        fullWidth
                        label='Email'
                        margin='normal'
                        name='businessInfo.email'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='email'
                        value={values.businessInfo?.email || ""}
                        variant='outlined'
                      />
                      <FormHelperText>
                        {getIn(touched, `businessInfo.email`) &&
                          getIn(errors, `businessInfo.email`)}
                      </FormHelperText>
                    </FormControl>
                    <FormControl sx={{ width: "50%" }}>
                      <PhoneInput
                        error={Boolean(getIn(errors, `businessInfo.phoneNumber`))}
                        fullWidth
                        helperText={
                          getIn(errors, `businessInfo.phoneNumber`) &&
                          getIn(errors, `businessInfo.phoneNumber`)
                        }
                        label='Phone number'
                        margin='normal'
                        name='businessInfo.phoneNumber'
                        value={values.businessInfo?.phoneNumber || ""}
                        variant='outlined'
                        color='primary'
                        placeholder='Phone number'
                        onBlur={handleBlur}
                        onChange={(e, ph, iso) => {
                          setFieldValue(`businessInfo.phoneNumber`, ph || undefined);
                        }}
                      />
                    </FormControl>
                  </Stack>

                  <Paper sx={{ my: 1, p: 1 }}>
                    <Typography component='div' sx={{ mb: 1, fontWeight: "bold" }}>
                      Address *
                    </Typography>

                    <GeneralAddressInput
                      errors={getIn(errors, "businessInfo.address")}
                      touched={getIn(touched, "businessInfo.address")}
                      value={values.businessInfo?.address}
                      inputsProps={{ all: {} }}
                      onChange={(newValue) => setFieldValue("businessInfo.address", newValue)}
                    />
                  </Paper>
                </Stack>

                <Paper sx={{ mt: 1, p: 1 }}>
                  <Typography component='div' sx={{ fontWeight: "bold" }}>
                    Contacts *
                  </Typography>

                  <Stack
                    direction={{ xxs: "column", md: "row" }}
                    spacing={1}
                    sx={{ mt: 1, flexWrap: "wrap" }}
                  >
                    {values.contacts?.map((contact, i) => (
                      <Box key={i}>
                        <ContactInput
                          sx={{ mb: 1 }}
                          value={{
                            firstName: contact.personName?.firstName,
                            lastName: contact.personName?.lastName,
                            email: contact.email,
                            phoneNumber: contact.phoneNumber,
                          }}
                          inputsProps={{
                            firstName: {
                              name: `contacts[${i}].personName.firstName`,
                              onChange: handleChange,
                              onBlur: handleBlur,
                            },
                            lastName: {
                              name: `contacts[${i}].personName.lastName`,
                              onChange: handleChange,
                              onBlur: handleBlur,
                            },
                            email: {
                              name: `contacts[${i}].email`,
                              onChange: handleChange,
                              onBlur: handleBlur,
                            },
                            phoneNumber: {
                              name: `contacts[${i}].phoneNumber`,
                              onChange: handleChange,
                              onBlur: handleBlur,
                            },
                          }}
                          errors={
                            getIn(errors, `contacts.${i}`) as ValidationErrors<ContactCardData>
                          }
                          action={
                            <IconButton
                              onClick={() => {
                                setFieldValue(
                                  "contacts",
                                  values.contacts!.filter((c1, j) => j !== i),
                                );
                              }}
                            >
                              <AppIcon of='close' />
                            </IconButton>
                          }
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={contact.isPrimary}
                                name={`contacts[${i}].isPrimary`}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    contacts: values.contacts!.map((x) => ({
                                      ...x,
                                      isPrimary: false,
                                    })),
                                  });
                                  setFieldValue(`contacts[${i}].isPrimary`, e.target.checked);
                                }}
                              />
                            }
                            label={
                              <Typography component='span' sx={{ display: "flex" }}>
                                Primary contact
                              </Typography>
                            }
                          />
                        </ContactInput>
                      </Box>
                    ))}
                  </Stack>

                  <Button
                    sx={{ mt: 1, width: "fit-content" }}
                    color='primary'
                    type='button'
                    startIcon={<AppIcon of='add' />}
                    onClick={() => {
                      setValues({
                        ...values,
                        contacts: [
                          ...values.contacts!,
                          {
                            personName: undefined,
                            email: undefined,
                            phoneNumber: undefined,
                            isPrimary: values.contacts!.length === 0 ? true : false,
                          },
                        ],
                      });
                    }}
                  >
                    Add contact
                  </Button>

                  {errors.contacts && (
                    <Box sx={{ mt: 1, mb: 1 }}>
                      {!Array.isArray(errors.contacts) && (
                        <FormHelperText error>
                          {ValidationHelper.getFormikErrorsAsString(errors.contacts)}
                        </FormHelperText>
                      )}
                    </Box>
                  )}
                </Paper>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                loading={isSubmitting}
                color='primary'
                fullWidth
                type='submit'
                variant='contained'
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
              >
                Save
              </LoadingButton>
            </form>
          );
        }}
      </Formik>
    </CreateUpdatePageLayout>
  );
}

export default withTranslation()(PaymentProfileEdit);

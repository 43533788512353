import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { PartyDto } from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import TenantLink from "../../../Tenant/TenantLink";
import PartyLink from "../../PartyLink";

interface Props {
  party: PartyDto;
}

export default function OverviewTabContent({ party }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <PageTabHeader
          title={"Details"}
          subtitle2={<EntityCreatedByInfoDisplay entity={party} />}
        />

        <Stack direction='column' spacing={1}>
          <FieldValue label='Type'>
            <InlineApiEnumValue type='PartyType' value={party.type} />
          </FieldValue>

          <FieldValue label='Type'>{party.name}</FieldValue>

          <FieldValue label='Is system'>
            <BooleanValue value={party.isSystem} />
          </FieldValue>

          <FieldValue label='Members'>{party.memberCount}</FieldValue>

          <FieldValue label='Company' isEmpty={!party.tenantId}>
            <TenantLink entity={undefined} entityId={party.tenantId} />
          </FieldValue>

          <FieldValue label='Parent party' isEmpty={!party.parentParty}>
            <PartyLink entity={party.parentParty} entityId={undefined} />
          </FieldValue>
        </Stack>
      </Stack>
    </PageTabContent>
  );
}

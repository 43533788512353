import {
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactNode, useMemo, useState } from "react";

import { NegotiationHelper } from "@/common/helpers/entity/negotiation";
import { ValidationHelper } from "@/common/validation";
import {
  ApprovalResponseType,
  NegotiationAllowedActionsDto,
  NegotiationDto,
  NegotiationProposalDto,
  UserPartiesMembershipDto,
} from "@/core/api/generated";
import * as negotiationsSlice from "@/store/communication/negotiationsSlice";

import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import _ from "lodash";
import DropdownButton, { DropdownButtonProps } from "../../../Button/DropdownButton";
import ApiEnumIcon from "../../../Icons/ApiEnumIcon";
import AppIcon from "../../../Icons/AppIcon";
import LoadingMenuItem from "../../../Menu/LoadingMenuItem";

export interface OwnProps {
  variant: "full" | "compact";
  negotiation: NegotiationDto;
  proposal: NegotiationProposalDto;
  allowedActions?: NegotiationAllowedActionsDto;
  partiesMembership?: UserPartiesMembershipDto | null;
  disabled?: boolean;
  compactVariantProps?: {
    dropdownButtonContent?: ReactNode;
    dropdownButtonProps?: Partial<DropdownButtonProps>;
  };
}

type Props = OwnProps;

/** Allows to respond on many proposals in one or many negotiations with one click. */
export default function RespondOnNegotiationProposalControl({
  variant,
  negotiation,
  proposal,
  allowedActions,
  partiesMembership,
  disabled,
  compactVariantProps,
}: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const thunkDispatch = useAppThunkDispatch();
  const authorizationInfo = useAuthorizationInfo();

  const [isRespondingMap, setIsRespondingMap] = useState<Record<ApprovalResponseType, boolean>>(
    {} as Record<ApprovalResponseType, boolean>,
  );
  const isAnyResponding = useMemo(
    () => Object.values(isRespondingMap).some((x) => x === true),
    [isRespondingMap],
  );

  const myParty = useMemo(
    () => NegotiationHelper.getMyNegotiationParty(negotiation, partiesMembership),
    [negotiation],
  );
  const otherParties = useMemo(
    () => NegotiationHelper.getOtherNegotiationParties(negotiation, partiesMembership),
    [negotiation],
  );
  const partiesResponseMap = useMemo(
    () => NegotiationHelper.getProposalPartiesResponseMap(negotiation, proposal),
    [negotiation, proposal],
  );

  const isMyPartyIsInitiatorParty =
    negotiation.initiatorParty?.id === myParty?.id && !_.isNil(myParty);
  const isMyPartyIsTargetParty = negotiation.targetParty?.id === myParty?.id && !_.isNil(myParty);

  const canRespond = NegotiationHelper.canRespondOnProposal({
    proposal,
    allowedActions,
    partiesMembership,
  });
  const canForceRespond = allowedActions?.canForceRespondOnProposal;
  const proposalResponseMap = NegotiationHelper.getProposalResponseMap(proposal, partiesMembership);
  const isMyPartyResponded = NegotiationHelper.isRespondedOnProposal(proposal, partiesMembership);
  const myPartyResponse = NegotiationHelper.getMyPartyProposalResponse(proposal, partiesMembership);

  const _disabled = !canRespond || disabled || !allowedActions || !partiesMembership;

  const handleRespondOnProposal = async (responseType: ApprovalResponseType) => {
    setIsRespondingMap({
      ...isRespondingMap,
      [responseType]: true,
    });

    try {
      await thunkDispatch(
        negotiationsSlice.respondOnNegotiationProposal({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          negotiationId: proposal!.negotiationId!,
          respondOnNegotiationProposalDto: {
            proposalId: proposal!.id,
            responseType,
          },
        }),
      );
      enqueueSnackbar("Your response saved.", { variant: "success" });
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      if (validation2.hasErrors) {
        enqueueSnackbar(`Error: ${validation2.getErrorsAsString()}`, { variant: "error" });
      }
    } finally {
      setIsRespondingMap({
        ...isRespondingMap,
        [responseType]: false,
      });
    }
  };

  const variant1 = variant === "full" && (
    <Stack direction='row' spacing={1} alignItems='center'>
      <DropdownButton
        behavior={canForceRespond ? "dropdown" : "button"}
        disabled={_disabled}
        isLoading={
          isRespondingMap[ApprovalResponseType.Approve] ||
          isRespondingMap[ApprovalResponseType.ForceApprove]
        }
        buttonProps={{
          variant:
            proposalResponseMap[ApprovalResponseType.Approve] ||
            proposalResponseMap[ApprovalResponseType.ForceApprove]
              ? "contained"
              : "outlined",
          size: "small",
          color: "success",
          startIcon: <AppIcon of='approve' />,
        }}
        onClick={() => handleRespondOnProposal(ApprovalResponseType.Approve)}
        dropdownContent={
          <MenuList>
            <MenuItem
              dense
              onClick={() => {
                handleRespondOnProposal(ApprovalResponseType.Approve);
              }}
            >
              <ListItemIcon>
                <AppIcon of='approve' fontSize='small' />
              </ListItemIcon>
              <ListItemText>
                {NegotiationHelper.getApprovalResponseTypeText(
                  ApprovalResponseType.Approve,
                  authorizationInfo,
                )}
              </ListItemText>
            </MenuItem>
            <MenuItem
              dense
              onClick={() => {
                handleRespondOnProposal(ApprovalResponseType.ForceApprove);
              }}
            >
              <ListItemIcon>
                <AppIcon of='approve' fontSize='small' />
              </ListItemIcon>
              <ListItemText>
                {NegotiationHelper.getApprovalResponseTypeText(
                  ApprovalResponseType.ForceApprove,
                  authorizationInfo,
                )}
              </ListItemText>
            </MenuItem>
          </MenuList>
        }
      >
        Approve
      </DropdownButton>

      <DropdownButton
        behavior={canForceRespond ? "dropdown" : "button"}
        disabled={_disabled}
        isLoading={
          isRespondingMap[ApprovalResponseType.Decline] ||
          isRespondingMap[ApprovalResponseType.ForceDecline]
        }
        buttonProps={{
          variant:
            proposalResponseMap[ApprovalResponseType.Decline] ||
            proposalResponseMap[ApprovalResponseType.ForceDecline]
              ? "contained"
              : "outlined",
          size: "small",
          color: "error",
          startIcon: <AppIcon of='decline' />,
        }}
        onClick={() => handleRespondOnProposal(ApprovalResponseType.Decline)}
        dropdownContent={
          <MenuList>
            <MenuItem
              dense
              onClick={() => {
                handleRespondOnProposal(ApprovalResponseType.Decline);
              }}
            >
              <ListItemIcon>
                <AppIcon of='decline' fontSize='small' />
              </ListItemIcon>
              <ListItemText>
                {NegotiationHelper.getApprovalResponseTypeText(
                  ApprovalResponseType.Decline,
                  authorizationInfo,
                )}
              </ListItemText>
            </MenuItem>
            <MenuItem
              dense
              onClick={() => {
                handleRespondOnProposal(ApprovalResponseType.ForceDecline);
              }}
            >
              <ListItemIcon>
                <AppIcon of='decline' fontSize='small' />
              </ListItemIcon>
              <ListItemText>
                {NegotiationHelper.getApprovalResponseTypeText(
                  ApprovalResponseType.ForceDecline,
                  authorizationInfo,
                )}
              </ListItemText>
            </MenuItem>
          </MenuList>
        }
      >
        Decline
      </DropdownButton>

      {!canRespond && (
        <Tooltip title={`You can't respond now on this proposal.`}>
          <AppIcon of='info' sx={{ color: (theme) => theme.palette.text.secondary }} />
        </Tooltip>
      )}
    </Stack>
  );

  const variant2 = variant === "compact" && (
    <DropdownButton
      component='iconButton'
      expandIconVariant='outlined'
      isLoading={isAnyResponding}
      autoCloseOnClick
      color='primary'
      buttonProps={{
        size: "medium",
      }}
      iconButtonProps={{
        size: "medium",
      }}
      tooltipProps={{
        enabled: true,
        title: (
          <>
            Your response is{" "}
            <strong>
              {NegotiationHelper.getApprovalResponseTypeText(
                myPartyResponse?.responseType,
                authorizationInfo,
              )}
            </strong>
          </>
        ),
      }}
      {...compactVariantProps?.dropdownButtonProps}
      sx={{ p: 0, px: 1, ...compactVariantProps?.dropdownButtonProps?.sx }}
      dropdownContent={
        <MenuList>
          {!canRespond && (
            <ListItem sx={{ pt: 0 }}>
              <ListItemText
                sx={{ m: 0, p: 0 }}
                primary={
                  <Typography component='div' variant='body1' color='warning' sx={{ m: 0, p: 0 }}>
                    {`You can't respond now on this proposal.`}
                  </Typography>
                }
              />
            </ListItem>
          )}

          <ListItem sx={{ pt: 0 }}>
            <ListItemText
              sx={{ m: 0, p: 0 }}
              primary={
                <>
                  <Typography
                    component='div'
                    variant='body2'
                    color='text.secondary'
                    sx={{ m: 0, p: 0 }}
                  >
                    {isMyPartyResponded ? "Change your response:" : "Respond:"}
                  </Typography>
                </>
              }
            />
          </ListItem>

          {[
            ApprovalResponseType.Approve,
            ApprovalResponseType.Decline,
            ...(canForceRespond ? [ApprovalResponseType.ForceApprove] : []),
            ...(canForceRespond ? [ApprovalResponseType.ForceDecline] : []),
          ].map((approvalResponseType, j) => (
            <LoadingMenuItem
              key={j}
              dense
              disabled={_disabled}
              selected={proposalResponseMap[approvalResponseType]}
              isLoading={isRespondingMap[approvalResponseType]}
              onClick={() => {
                handleRespondOnProposal(approvalResponseType);
              }}
            >
              <ListItemIcon>
                <ApiEnumIcon
                  type='ApprovalResponseType'
                  value={approvalResponseType}
                  withTooltip={false}
                  fontSize='small'
                />
              </ListItemIcon>
              <ListItemText>
                {NegotiationHelper.getApprovalResponseTypeText(
                  approvalResponseType,
                  authorizationInfo,
                )}
              </ListItemText>
            </LoadingMenuItem>
          ))}
        </MenuList>
      }
    >
      {/* {isAnyResponding && <CircularProgress color='inherit' size={16} />}

      {!isAnyResponding && (
        <>
          {compactVariantProps?.dropdownButtonContent && compactVariantProps?.dropdownButtonContent}

          {!compactVariantProps?.dropdownButtonContent && (
            <Tooltip
              title={
                <>
                  Your response is{" "}
                  <strong>
                    {NegotiationHelper.getApprovalResponseTypeText(
                      myPartyResponse?.responseType,
                      authorizationInfo,
                    )}
                  </strong>
                </>
              }
            >
              <Stack
                direction='row'
                spacing={0.5}
                sx={{ display: "inline-flex", alignItems: "center" }}
              >
                <ApiEnumIcon
                  type='ApprovalResponseType'
                  value={myPartyResponse?.responseType}
                  withTooltip={false}
                  inText
                  fontSize='small'
                />

                <Box>
                  {NegotiationHelper.getApprovalResponseTypeText(
                    myPartyResponse?.responseType,
                    authorizationInfo,
                  )}
                </Box>
              </Stack>
            </Tooltip>
          )}
        </>
      )} */}
    </DropdownButton>
  );

  return variant1 || variant2 || null;
}

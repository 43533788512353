import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AppIcon from "@/common/components/Icons/AppIcon";
import RouteCategoryIndex from "@/common/components/Route/RouteCategoryIndex";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function OperationsPage() {
  return (
    <ViewPageLayout header={<SimpleViewPageHeader title='Operations' />}>
      <RouteCategoryIndex
        direction='row'
        childrenLinks={[
          {
            icon: <AppIcon of='damageDetections' />,
            title: "Damage detections",
            to: ROUTE_PATH.DAMAGE_DETECTIONS,
          },
          {
            icon: <AppIcon of='damageCostEvaluations' />,
            title: "Damage cost evaluations",
            to: ROUTE_PATH.DAMAGE_COST_EVALUATIONS,
          },
          {
            icon: <AppIcon of='accessoryChecks' />,
            title: "Accessory checks",
            to: ROUTE_PATH.ACCESSORY_CHECKS,
          },
          {
            icon: <AppIcon of='repairOperations' />,
            title: "Repair operations",
            to: ROUTE_PATH.REPAIR_OPERATIONS,
          },
        ]}
      />
    </ViewPageLayout>
  );
}

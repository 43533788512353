import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  WebhookDto,
  WebhookGetPaginatedDto,
} from "@/core/api/generated";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

const defaultDisplayProps = {
  hints: true,
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type WebhookPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface WebhookPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
  };
  propagatedDeps?: PropagatedDeps<WebhookPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type WebhookPaginatedListProps = WebhookPaginatedListOwnProps;

export default function WebhookPaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
  sx,
}: WebhookPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<WebhookGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.Webhook,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
  });

  const [webhook, setWebhook] = useState<WebhookDto | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const paginatedWebhooksRequest = useApiRequest(
    apiClient.webhooksApi.apiV1WebhooksGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      webhookGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedWebhooks = paginatedWebhooksRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Webhook)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedWebhooksRequest.handleEntityChanged(data);
    },
  });

  const handleRemoveWebhook = useCallback(async (webhookId: string) => {
    await apiClient.webhooksApi.apiV1WebhooksWebhookIdDelete({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      webhookId,
    });
    enqueueSnackbar("Webhook deleted successfully.", { variant: "success" });
    paginatedWebhooksRequest.refetch();
  }, []);

  return (
    <Box>
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title={undefined}
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement permissions={[AppPermission.WebhookManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='add' />}
                    component={RouterLink}
                    to={ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOK_CREATE}
                  >
                    Create new webhook
                  </Button>
                </AuthorizedElement>
              )
            }
          />
        }
      >
        <Stack direction='column' spacing={1}>
          <DataTabular
            columns={[
              {
                field: "name",
                title: "Name",
                flex: 1,
                renderCell: (item) => <>{item.name}</>,
              },
              {
                field: "eventTypes",
                title: "Event Types",
                flex: 1,
                renderCell: (item) => (
                  <InlineApiEnumValueList
                    type='WebhookEventType'
                    values={item.eventTypes}
                    direction='row'
                  />
                ),
              },
              {
                field: "url",
                title: "Endpoint",
                flex: 2,
                renderCell: (item) => (
                  <AppTypography ellipsing={{ enabled: true }} component='div'>
                    {item.url}
                  </AppTypography>
                ),
              },
              {
                field: "isEnabled",
                title: "Enabled",
                flex: 1,
                renderCell: (item) => (
                  <AppTypography>{item.isEnabled ? "Yes" : "No"}</AppTypography>
                ),
              },
            ]}
            isLoading={paginatedWebhooksRequest.isLoading}
            rows={paginatedWebhooks?.items}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOK_VIEW(item.id)}
            renderRowAction={(actionParams) => (
              <>
                <MenuWithTrigger
                  trigger={
                    <IconButton>
                      <AppIcon of='moreVert' />
                    </IconButton>
                  }
                >
                  <MenuItem
                    component={RouterLink}
                    to={ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOK_VIEW(actionParams.item.id)}
                  >
                    <ListItemIcon>
                      <AppIcon of='view' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>View</ListItemText>
                  </MenuItem>
                  <AuthorizedMenuItem
                    permissions={[AppPermission.FleetAppAccess, AppPermission.ContractManage]}
                    onClick={() => handleRemoveWebhook(actionParams.item.id!)}
                  >
                    <ListItemIcon>
                      <AppIcon of='delete' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </AuthorizedMenuItem>
                </MenuWithTrigger>
              </>
            )}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            refetch={commonRequestParams.dataTabularProps.refetch}
          />
        </Stack>

        {/* Delete confirmation */}
        {/* {spot && (
          <SpotDeleteModal
            entity={spot}
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onDelete={() => paginatedSpotsRequest.refetch()}
          />
        )} */}
      </ViewLayout>
    </Box>
  );
}

import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { RepairOperationDto } from "@/core/api/generated";

import { pageTabHeaderTypographyProps } from "@/App/Layouts/PageBody/PageTabHeader";
import VehicleView from "@/common/components/Entity/Vehicle/View/VehicleView";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";

interface Props {
  repairOperation: RepairOperationDto;
}

export default function VehicleTabContent({ repairOperation }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <VehicleView
          vehicleId={repairOperation.vehicle?.id}
          vehicle={undefined}
          displayProps={{
            breadcrumbs: false,
            header: false,
            actions: true,
            viewVariant: ViewLayoutVariant.Tab,
          }}
          headerProps={{
            withLink: true,
            typographyProps: pageTabHeaderTypographyProps.title,
          }}
        />
      </Stack>
    </PageTabContent>
  );
}

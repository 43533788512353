import { useParams } from "react-router-dom";

import { useHighlightPropsFromQueryString } from "@/common/hooks/useHighlightPropsFromQueryString";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import DamageDetectionAggregateView, {
  DamageDetectionAggregateViewProps,
} from "../../../common/components/Entity/DamageDetectionAggregate/View/DamageDetectionAggregateView";

export type DamageDetectionAggregateViewPageHighlightProps = Exclude<
  DamageDetectionAggregateViewProps["highlightProps"],
  null | undefined
>;

export type DamageDetectionAggregateViewPageQueryParams = GeneralQueryParams;

export default function DamageDetectionAggregateViewPage() {
  const { aggregateId } = useParams<{ aggregateId?: string }>();
  const highlightProps =
    useHighlightPropsFromQueryString<DamageDetectionAggregateViewPageHighlightProps>();

  return (
    <ViewPageLayout>
      <DamageDetectionAggregateView
        aggregateId={aggregateId}
        withRealtimeDataUpdates
        headerProps={{
          withLink: false,
        }}
        displayProps={{
          breadcrumbs: true,
        }}
        highlightProps={highlightProps}
      />
    </ViewPageLayout>
  );
}

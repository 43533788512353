import { Dialog, DialogContent, DialogProps } from "@mui/material";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import TenantRequestApplyResult, {
  TenantRequestApplyResultProps,
} from "./TenantRequestApplyResult";

export interface OwnProps {
  applyProps: TenantRequestApplyResultProps;
}

type Props = OwnProps & DialogProps;

export default function TenantRequestApplyResultModal({ applyProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Apply company request result
      </AppModalTitle>
      <DialogContent>
        <TenantRequestApplyResult {...applyProps} />
      </DialogContent>
    </Dialog>
  );
}

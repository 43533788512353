import { useMediaQuery, useTheme } from "@mui/material";
import { useCallback } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppNav from "@/common/components/Nav/AppNav";
import AppNavItem from "@/common/components/Nav/AppNavItem";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { tenantService } from "@/common/services/tenant";
import * as appCommonSlice from "@/store/appCommon/slice";
import AppNavIcon from "@/common/components/Icons/AppNavIcon";

function Nav() {
  const theme = useTheme();
  const thunkDispatch = useAppThunkDispatch();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isNavOpened = useAppSelector((x) => x.app.isNavOpened);

  const tenantInfo = tenantService.resolveTenant();
  const isTenantSelected = !!tenantInfo?.identifier;

  const handleNavItemClick = useCallback(
    (props: { to?: string }) => {
      // scroll top top
      // topAnchorRef.current?.scrollIntoView({ behavior: "smooth" });

      // on mobile auto-close nav after clicking link
      if (props.to && isMobile && isNavOpened) {
        thunkDispatch(appCommonSlice.toggleNav());
      }
    },
    [isMobile, isNavOpened],
  );

  return (
    <AppNav
      isNavOpened={isNavOpened}
      toggleNav={() => thunkDispatch(appCommonSlice.toggleNav())}
      titleTo='/'
      items={
        <>
          {isTenantSelected && (
            <>
              <AppNavItem
                type='item'
                item={{
                  startIcon: <AppNavIcon of='dashboard' />,
                  content: "Dashboard",
                  to: ROUTE_PATH.FLEET_CUSTOMER_DASHBOARD,
                  onClick: handleNavItemClick,
                }}
              />

              {/* <AppNavItem
                  type='item'
                  item={{
                    startIcon: <AppNavIcon of='vehicles' />,
                    content: "Vehicles",
                    to: ROUTE_PATH.VEHICLES,
                    onClick: handleNavItemClick,
                  }}
                />
                <AppNavItem
                  type='item'
                  item={{
                    startIcon: <AppNavIcon of='contracts' />,
                    content: "Contracts",
                    to: ROUTE_PATH.CONTRACTS,
                    onClick: handleNavItemClick,
                  }}
                /> */}

              {/* Flows */}
              <AppNavItem
                type='itemGroup'
                itemGroup={{
                  startIcon: (fill) => <AppNavIcon sx={{ fill: fill }} of='flows' />,
                  content: "Flows",
                  to: undefined,
                  onClick: handleNavItemClick,
                  items: (
                    <>
                      <AppNavItem
                        type='item'
                        item={{
                          isFromGroup: true,
                          startIcon: <AppNavIcon of='assessmentFlow' />,
                          content: "Assessment",
                          to: ROUTE_PATH.FLEET_CUSTOMER_ASSESSMENT_FLOWS(),
                          onClick: handleNavItemClick,
                        }}
                      />
                    </>
                  ),
                }}
              />

              {/* Operations */}
              {/* <AppNavItem
                  type='itemGroup'
                  itemGroup={{
                    startIcon: <AppNavIcon of='operations' />,
                    content: "Operations",
                    to: undefined,
                    items: (
                      <>
                        <AuthorizedElement permissions={[AppPermission.DamageDetectionRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              startIcon: <AppNavIcon of='damageDetections' />,
                              content: "Damage detections",
                              to: ROUTE_PATH.DAMAGE_DETECTIONS,
                              onClick: handleNavItemClick,
                            }}
                          />
                        </AuthorizedElement>
                        <AuthorizedElement
                          permissions={[AppPermission.DamageCostEvaluationRead]}
                        >
                          <AppNavItem
                            type='item'
                            item={{
                              startIcon: (
                                <AppIcon of='damageCostEvaluations' />
                              ),
                              content: "Damage cost evaluations",
                              to: ROUTE_PATH.DAMAGE_COST_EVALUATIONS,
                              onClick: handleNavItemClick,
                            }}
                          />
                        </AuthorizedElement>
                        <AuthorizedElement permissions={[AppPermission.AccessoryCheckRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              startIcon: <AppNavIcon of='accessoryChecks' />,
                              content: "Accessory checks",
                              to: ROUTE_PATH.ACCESSORY_CHECKS,
                              onClick: handleNavItemClick,
                            }}
                          />
                        </AuthorizedElement>
                      </>
                    ),
                  }}
                /> */}

              {/* Documents */}
              {/* <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                  <AppNavItem
                    type='itemGroup'
                    itemGroup={{
                      startIcon: <AppNavIcon of='documents' />,
                      content: "Documents",
                      to: undefined,
                      onClick: handleNavItemClick,
                      items: (
                        <>
                          <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                            <AppNavItem
                              type='item'
                              item={{
                                startIcon: <AppNavIcon of='documents' />,
                                content: "Index",
                                to: ROUTE_PATH.DOCUMENTS(),
                                onClick: handleNavItemClick,
                              }}
                            />
                          </AuthorizedElement>
                          <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                            <AppNavItem
                              type='item'
                              item={{
                                startIcon: <AppNavIcon of='documents' />,
                                content: "Damage detections",
                                to: ROUTE_PATH.DOCUMENTS_DAMAGE_DETECTIONS(),
                                onClick: handleNavItemClick,
                              }}
                            />
                          </AuthorizedElement>
                          <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                            <AppNavItem
                              type='item'
                              item={{
                                startIcon: <AppNavIcon of='documents' />,
                                content: "Damage cost evaluations",
                                to: ROUTE_PATH.DOCUMENTS_DAMAGE_COST_EVALUATIONS(),
                                onClick: handleNavItemClick,
                              }}
                            />
                          </AuthorizedElement>
                          <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                            <AppNavItem
                              type='item'
                              item={{
                                startIcon: <AppNavIcon of='documents' />,
                                content: "Accessory checks",
                                to: ROUTE_PATH.DOCUMENTS_ACCESSORY_CHECKS(),
                                onClick: handleNavItemClick,
                              }}
                            />
                          </AuthorizedElement>
                        </>
                      ),
                    }}
                  />
                </AuthorizedElement> */}
            </>
          )}
        </>
      }
    />
  );
}

export default Nav;

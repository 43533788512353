import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";

import DamageDetectionAggregatesPaginatedList from "@/common/components/Entity/DamageDetectionAggregate/ListView/DamageDetectionAggregatesPaginatedList";

export default function DamageCostEvaluationsPage() {
  return (
    <ListPageLayout>
      <DamageDetectionAggregatesPaginatedList />
    </ListPageLayout>
  );
}

import { LoadingButton } from "@mui/lab";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";

import ApiEnumAutocomplete from "@/common/components/Enum/ApiEnumAutocomplete";
import ApiEnumMultiselect from "@/common/components/Enum/ApiEnumMultiselect";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AccessoryDto, CreateAccessoryDto, EntityType } from "@/core/api/generated";
import GeneralValidationError from "../../Error/GeneralValidationError";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";

type DefaultValues = {
  type?: CreateAccessoryDto["type"];
  name?: CreateAccessoryDto["name"];
  description?: CreateAccessoryDto["description"];
};

export interface AccessoryCreateUpdateProps
  extends BaseEntityCreateUpdateInheritableProps<AccessoryDto, DefaultValues> {}

export default function AccessoryCreateUpdate({
  entityId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: AccessoryCreateUpdateProps) {
  const mounted = useMounted();
  const isCreate = !entityId;
  const { enqueueSnackbar } = useAppSnackbar();

  const accessoryRequest = useApiRequest(
    apiClient.accessoriesApi.apiV1AccessoriesAccessoryIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      accessoryId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId,
    },
  );
  const accessory = accessoryRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: accessory && {
      idValue: accessory.id!,
      newTitle: accessory.name || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.Accessory}
      entityId={entityId}
      entity={accessory}
      entityRequest={accessoryRequest}
    >
      <Formik<CreateAccessoryDto & BaseFormikValues>
        enableReinitialize
        initialValues={{
          type: accessory?.type || defaultValues?.type || undefined,
          vehicleTypes: accessory?.vehicleTypes || undefined,
          name: accessory?.name || defaultValues?.name || "",
          description: accessory?.description || defaultValues?.description || "",
          tags: accessory?.tags || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // name: Yup.string().required("Name is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = await apiClient.accessoriesApi.apiV1AccessoriesPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                createAccessoryDto: {
                  ...values,
                  description: values.description || undefined,
                },
              });
              enqueueSnackbar("Accessory created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = await apiClient.accessoriesApi.apiV1AccessoriesAccessoryIdPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                accessoryId: entityId,
                updateAccessoryDto: {
                  ...values,
                  description: values.description || undefined,
                },
              });
              enqueueSnackbar("Accessory updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Box>
                <Box>
                  {/* Tags */}
                  <FormControl margin='dense' fullWidth>
                    <GeneralAttachedTagsInput
                      value={values.tags}
                      onChange={(newValue) => {
                        setFieldValue("tags", newValue);
                      }}
                    />
                    <FormHelperText error>
                      {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                        isIncludeNested: false,
                      })}
                    </FormHelperText>
                  </FormControl>
                </Box>

                <FormControl margin='dense' fullWidth required error={Boolean(errors.type)}>
                  <ApiEnumAutocomplete
                    type='AccessoryType'
                    value={values.type}
                    onChange={(newValue) => setFieldValue("type", newValue)}
                    label='Type'
                    textFieldProps={{
                      error: Boolean(errors.type),
                    }}
                  />
                  <FormHelperText>
                    {errors.type && ValidationHelper.getErrorsAsString(errors.type)}
                  </FormHelperText>
                </FormControl>

                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  required
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='dense'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name}
                  variant='outlined'
                />

                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  multiline
                  rows={2}
                  helperText={touched.description && errors.description}
                  label='Description'
                  margin='dense'
                  name='description'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.description}
                  variant='outlined'
                />

                <FormControl
                  margin='dense'
                  fullWidth
                  error={Boolean(touched.vehicleTypes && errors.vehicleTypes)}
                >
                  <ApiEnumMultiselect
                    type='VehicleType'
                    label='Vehicle types'
                    values={values.vehicleTypes}
                    onChange={(newValues) => setFieldValue("vehicleTypes", newValues)}
                  />

                  <FormHelperText>For all vehicle types if list is empty</FormHelperText>
                  <FormHelperText>{touched.vehicleTypes && errors.vehicleTypes}</FormHelperText>
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}

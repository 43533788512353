import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AdminCreateVehicleBodyTypeDto,
  AdminUpdateVehicleBodyTypeDto,
  VehicleBodyType,
  VehicleType,
} from "@/core/api/generated";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { BaseFormikValues } from "@/common/ts/error";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import ReferenceDataSyncStopAlert from "../ReferenceDataSyncStopAlert";

export default function VehicleBodyTypeCreateUpdatePage() {
  const { bodyTypeId } = useParams<{ bodyTypeId?: string }>();
  const mounted = useMounted();
  const history = useHistory();

  const isCreate = !bodyTypeId;
  const isEdit = !!bodyTypeId;

  const bodyTypeRequest = useApiRequest(
    apiClient.adminVehicleBodyTypesApi.apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGet,
    {
      bodyTypeId: bodyTypeId!,
    },
    {
      skip: !bodyTypeId,
    },
  );
  const bodyType = bodyTypeRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: bodyType && {
      idValue: bodyType.id!,
      newTitle: bodyType.name || bodyType.globalNumber || "",
    },
  });

  return (
    <Formik<AdminCreateVehicleBodyTypeDto & AdminUpdateVehicleBodyTypeDto & BaseFormikValues>
      enableReinitialize={!isCreate}
      initialValues={{
        vehicleType: bodyType?.vehicleType || VehicleType.Car,
        bodyType: (bodyType?.bodyType || "") as VehicleBodyType,
        name: bodyType?.name || "",
        isEnabled: bodyType?.isEnabled ?? true,
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // vehicleType: Yup.string().required("Vehicle type is required"),
        // name: Yup.string().required("Name is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          if (isCreate) {
            await apiClient.adminVehicleBodyTypesApi.apiV1AdminReferenceDataVehiclesBodyTypesPost({
              adminCreateVehicleBodyTypeDto: {
                vehicleType: values.vehicleType as VehicleType,
                bodyType: values.bodyType,
                name: values.name,
                sortOrder: values.sortOrder,
              },
            });
          } else {
            await apiClient.adminVehicleBodyTypesApi.apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPut(
              {
                bodyTypeId,
                adminUpdateVehicleBodyTypeDto: {
                  name: values.name,
                  sortOrder: values.sortOrder,
                },
              },
            );
          }

          if (mounted.current) {
            setStatus({ success: true });
          }
          history.goBack();
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <CreateUpdatePageLayout
            header={
              <SimpleViewPageHeader title={isCreate ? "Create new body type" : "Edit body type"} />
            }
          >
            <form noValidate onSubmit={handleSubmit}>
              {bodyType && isEdit && (
                <ReferenceDataSyncStopAlert
                  sx={{ mb: 2 }}
                  isBuiltIn={bodyType.isBuiltIn ?? false}
                  car2DbId={undefined}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  width: "100%",
                  minHeight: 0,
                  overflow: "auto",
                }}
              >
                <FormControl
                  sx={{ minWidth: 200 }}
                  margin='normal'
                  error={Boolean(touched.vehicleType && errors.vehicleType)}
                >
                  <InputLabel>Vehicle type</InputLabel>
                  <ApiEnumSelect
                    type='VehicleType'
                    value={values.vehicleType}
                    onChange={(newValue) => setFieldValue("vehicleType", newValue)}
                    selectProps={{
                      required: true,
                      disabled: isEdit,
                      label: "Vehicle type",
                    }}
                  />
                  <FormHelperText>{touched.vehicleType && errors.vehicleType}</FormHelperText>
                </FormControl>

                <FormControl
                  sx={{ minWidth: 200 }}
                  margin='normal'
                  error={Boolean(touched.bodyType && errors.bodyType)}
                >
                  <InputLabel>Type</InputLabel>
                  <ApiEnumSelect
                    type='VehicleBodyType'
                    value={values.bodyType}
                    onChange={(newValue) => {
                      setFieldValue("bodyType", newValue);
                      setFieldValue("name", newValue);
                    }}
                    selectProps={{
                      disabled: !isCreate,
                      label: "Type",
                    }}
                  />
                  <FormHelperText>{touched.bodyType && errors.bodyType}</FormHelperText>
                </FormControl>

                <TextField
                  autoFocus
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='normal'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name}
                  variant='outlined'
                />

                <TextField
                  autoFocus
                  rows={2}
                  error={Boolean(touched.sortOrder && errors.sortOrder)}
                  fullWidth
                  helperText={touched.sortOrder && errors.sortOrder}
                  label='SortOrder'
                  margin='normal'
                  name='sortOrder'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputMode='numeric'
                  value={values.sortOrder || 0}
                  variant='outlined'
                  InputProps={{ inputProps: { min: 0, max: 10000000 } }}
                />

                <FormControl error={Boolean(errors.isEnabled)}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isEnabled'
                        checked={values.isEnabled}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label='Enabled'
                  />
                  <FormHelperText>
                    Whether this reference data entity is enabled for referencing by other entities.
                    When disabled, the entity is unavailable for paginated/search/list requests but
                    available by id for old entities already referencing it.
                  </FormHelperText>
                  {errors.isEnabled && <FormHelperText error>{errors.isEnabled}</FormHelperText>}
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                disabled={!values.name}
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </form>
          </CreateUpdatePageLayout>
        );
      }}
    </Formik>
  );
}

import { Box, Stack } from "@mui/material";
import { useEffect } from "react";

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import {
  CheckoutManualDto,
  TenantPaymentProfileDto,
  SubscriptionDto,
  SubscriptionPlanDto,
} from "@/core/api/generated";

import Plan from "./Plan/Plan";
import { useHistory } from "react-router";
import { ROUTE_PATH } from "@/common/constants/routing";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import _ from "lodash";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { selectSubscriptionPlan } from "@/store/management/subscriptionPlans/slice";
import { useAppSelector } from "@/common/hooks/redux";

function SubscriptionPlans() {
  const history = useHistory();
  const paymentProfile = useAppSelector((x) => x.management.payments.paymentProfile);

  const getCurrentSubscriptionRequest = useApiRequest(
    apiClient.subscriptionsApi.apiV1SubscriptionsCurrentGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
  );
  const currentSubscription = getCurrentSubscriptionRequest.data!;

  const getSubscriptionPlansRequest = useApiRequest(
    apiClient.subscriptionPlansApi.apiV1SubscriptionsPlansGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
  );
  const subscriptionPlans = getSubscriptionPlansRequest.data;
  return (
    <ListPageLayout>
      <Stack direction={{ mobile: "column", laptop: "row" }} spacing={2}>
        {_.isEmpty(subscriptionPlans) && <NoDataAlert />}

        {subscriptionPlans?.map((sp, index) => {
          return (
            <Plan
              selectSubscriptionPlan={selectSubscriptionPlan}
              plan={sp}
              key={index}
              currentSubscription={currentSubscription}
              getCurrentSubscription={getCurrentSubscriptionRequest.refetch}
              paymentProfile={paymentProfile}
              redirectToAppPaymentProfileEdit={() =>
                history.push(ROUTE_PATH.MANAGEMENT_PAYMENT_PROFILE_EDIT)
              }
            />
          );
        })}
      </Stack>
    </ListPageLayout>
  );
}

export default SubscriptionPlans;

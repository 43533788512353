import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { CustomerDto } from "@/core/api/generated";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AssetSubscriptionPaginatedList from "../../../AssetSubscription/ListView/AssetSubscriptionPaginatedList";

interface Props {
  customer: CustomerDto;
}

export default function AssetSubscriptionsTabContent({ customer }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <AssetSubscriptionPaginatedList
          defaultValues={{
            customerId: customer.id!,
          }}
          displayProps={{
            viewVariant: ViewLayoutVariant.Tab,
            header: false,
            filters: true,
          }}
        />
      </Stack>
    </PageTabContent>
  );
}

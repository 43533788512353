import { FilterValueInputProps } from "@/common/ts/filters";
import { FilterValueType } from "@/core/api/generated";
import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";

interface Props extends FilterValueInputProps {}

export default function FilterEnumValueInput({
  fieldSpec,
  item,
  autocompleteProps,
  onChange,
}: Props) {
  if (item.valueType !== FilterValueType.Enum) {
    console.error(`Invalid value type '${item.valueType}'.`);
    return null;
  }

  const enumName = fieldSpec.fieldTypeMeta?.enum?.enumName;
  if (!enumName) {
    console.error(`Enum name is required for enum filter value input (check field type meta).`, {
      fieldSpec,
      item,
    });
    return null;
  }

  return (
    <ApiEnumAutocomplete
      label='Value'
      {...autocompleteProps}
      type={enumName}
      value={item.valueAsEnumTyped(enumName)}
      onChange={(newValue) => {
        onChange(newValue);
      }}
    />
  );
}

import DepartmentAutocomplete from "@/common/components/Entity/Department/DepartmentAutocomplete";
import AppModal, { AppModalProps } from "@/common/components/Modals/AppModal";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { apiClient } from "@/core/api/ApiClient";
import { UserDto } from "@/core/api/generated";
import { LoadingButton } from "@mui/lab";
import { DialogContent, FormControl, Stack } from "@mui/material";
import { useCallback, useState } from "react";

interface Props extends Omit<AppModalProps, "children"> {
  user: UserDto;
  onAdded?: () => void;
}

export default function UserAddDepartmentModal({ user, onAdded, ...modalProps }: Props) {
  const [departmentId, setDepartmentId] = useState<string | undefined>(undefined);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useAppSnackbar();

  const handleSave = useCallback(async () => {
    if (!departmentId) {
      return;
    }
    setIsSubmitting(true);
    try {
      await apiClient.usersApi.apiV1UsersUserIdDepartmentsDepartmentIdPost({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        userId: user.id!,
        departmentId: departmentId,
      });
      onAdded?.();
      modalProps.onClose?.({}, "escapeKeyDown");
    } catch (err) {
      enqueueSnackbar(
        `Failed to add user to department. ${(err as any).response?.data?.detail || ""}`,
        {
          variant: "error",
        },
      );
    } finally {
      setIsSubmitting(false);
    }
  }, [user, departmentId]);

  return (
    <AppModal fullWidth keepMounted={false} {...modalProps}>
      <AppModalTitle
        title={"Add user to department"}
        withCloseIcon
        onCloseClicked={() => modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown")}
      />
      <DialogContent>
        <Stack sx={{ mt: 1 }} spacing={2}>
          <FormControl fullWidth>
            <DepartmentAutocomplete
              entityId={departmentId}
              onChange={(d) => {
                if (d) {
                  setDepartmentId(d.id || "");
                }
              }}
            />
          </FormControl>
          <LoadingButton
            variant='contained'
            disabled={!departmentId}
            loading={isSubmitting}
            onClick={() => handleSave()}
          >
            Save
          </LoadingButton>
        </Stack>
      </DialogContent>
    </AppModal>
  );
}

import { ROUTE_PATH } from "@/common/constants/routing";
import { AccessoryCheckDto, AppPermission } from "@/core/api/generated";

import { FileHelper } from "@/common/helpers/file";
import { apiClient } from "@/core/api/ApiClient";
import { ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import AuthorizedElement from "../../Auth/AuthorizedElement";
import AuthorizedMenuItem from "../../Auth/AuthorizedMenuItem";
import EntityMenu from "../../EntityMenu/EntityMenu";
import AppIcon from "../../Icons/AppIcon";
import LoadingMenuItem from "../../Menu/LoadingMenuItem";
import AccessoryCheckDeleteModal from "./AccessoryCheckDeleteModal";
import AddAccessoryCheckToContractModal from "./AddAccessoryCheckToContractModal";

enum ActionFlags {
  DownloadPdf = "DownloadPdf",
  Edit = "Edit",
  AddToContract = "AddToContract",
  Delete = "Delete",
}
const defaultDisplayProps = {
  actions: {
    downloadPdf: true,
    edit: true,
    addToContract: true,
    delete: true,
  },
};

interface TriggersProps {
  entity?: AccessoryCheckDto | null;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity?: AccessoryCheckDto | null;
  disabled?: boolean;
  onUpdate: ((newValue: AccessoryCheckDto | null | undefined) => void) | undefined;
  onDelete?: (() => void) | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customActions?: (props: TriggersProps) => ReactNode | Element;
}

export default function AccessoryCheckMenu({
  entity,
  disabled,
  customActions,
  onDelete,
  onUpdate,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  displayProps = { ...defaultDisplayProps, ...displayProps };
  return (
    <EntityMenu
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {customActions &&
            customActions({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}
          {displayProps?.actions?.edit && (
            <AuthorizedMenuItem
              permissions={[AppPermission.AccessoryCheckManage]}
              component={RouterLink}
              to={ROUTE_PATH.ACCESSORY_CHECK_EDIT(entity?.id)}
            >
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.addToContract && (
            <AuthorizedElement permissions={[AppPermission.AccessoryCheckManage]}>
              <MenuItem
                disabled={!!entity?.contract}
                onClick={() => startAction(ActionFlags.AddToContract)}
              >
                <ListItemIcon>
                  <AppIcon of='add' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Add to contract</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}
          {displayProps?.actions?.delete && (
            <AuthorizedMenuItem
              permissions={[AppPermission.AccessoryCheckManage]}
              onClick={() => startAction(ActionFlags.Delete)}
            >
              <ListItemIcon>
                <AppIcon of='delete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </AuthorizedMenuItem>
          )}
          {/** NB: temporary disabled
            <MenuItem
              key='documents'
              component={RouterLink}
              to={ROUTE_PATH.DOCUMENTS({
                sourceType: EntitySourceType.AccessoryCheck,
                sourceId: accessoryCheck?.id,
              })}
            >
              <ListItemIcon>
                <AppIcon of='documents' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Documents</ListItemText>
            </MenuItem> */}
          {displayProps?.actions?.downloadPdf && (
            <LoadingMenuItem
              key='downloadPdf'
              onClick={async () => {
                const downloadResult = await FileHelper.getDownloadFileApiResult(() =>
                  apiClient.accessoryChecksApi.apiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet(
                    {
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      accessoryCheckId: entity?.id || "",
                    },
                    {
                      responseType: "blob",
                    },
                  ),
                );
                FileHelper.downloadBlobFromApiResult(downloadResult);
              }}
            >
              <ListItemIcon>
                <AppIcon of='download' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Download PDF</ListItemText>
            </LoadingMenuItem>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {/* Add to contract */}
          {entity && (
            <AddAccessoryCheckToContractModal
              open={currentAction === ActionFlags.AddToContract}
              onClose={() => cancelAction()}
              accessoryCheck={entity}
              onSaved={(newValue) => {
                onUpdate && onUpdate(newValue);
                completeAction();
              }}
            />
          )}

          {/* Delete modal */}
          {entity && (
            <AccessoryCheckDeleteModal
              entity={entity}
              open={currentAction === ActionFlags.Delete}
              onClose={() => cancelAction()}
              onDelete={() => {
                onDelete && onDelete();
                completeAction();
              }}
            />
          )}
        </Stack>
      )}
    />
  );
}

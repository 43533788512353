import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { VehicleDto } from "@/core/api/generated";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ContractPaginatedList from "../../../Contract/ListView/ContractPaginatedList";

interface Props {
  vehicle: VehicleDto;
}

export default function ContractsTabContent({ vehicle }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <ContractPaginatedList
          defaultValues={{
            vehicleId: vehicle.id!,
          }}
          displayProps={{
            viewVariant: ViewLayoutVariant.Tab,
            header: false,
            counters: false,
          }}
        />
      </Stack>
    </PageTabContent>
  );
}

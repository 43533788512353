import { PoolItemDto } from "@/core/api/generated";
import { TypedEventEmitter } from "./typedEventEmitter";

export type EntityAffiliationEventsMap = {
  // list of supported events
  entityPoolAffiliationChanged: PoolItemDto;
};

/** Common entity affiliation events. */
class EntityAffiliationEventEmitter extends TypedEventEmitter<EntityAffiliationEventsMap> {}

export const entityAffiliationEventEmitter = new EntityAffiliationEventEmitter();

import { apiClient } from "@/core/api/ApiClient";
import { DamageDetectionAggregateDto, EntityType } from "@/core/api/generated";
import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";
import { Alert } from "@mui/material";
import ContractLink from "../Contract/ContractLink";

export interface OwnProps {
  entity: DamageDetectionAggregateDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function DamageDetectionAggregateDeleteModal({
  entity,
  onDelete,
  ...otherProps
}: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.DamageDetectionAggregate}
      entity={entity}
      entityId={entity.id}
      deleteFunc={(params) =>
        apiClient.damageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesAggregateIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          aggregateId: params.entityId,
        })
      }
      bodyAfter={
        entity.contract && (
          <Alert severity='warning'>
            This aggregate is linked to the contract <ContractLink entity={entity.contract} /> and
            might be used in assessment flow. Recheck before deletion.
          </Alert>
        )
      }
      {...otherProps}
    />
  );
}

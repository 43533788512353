import { useHistory, useParams } from "react-router";

import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CustomTagCreateUpdate from "@/common/components/Entity/CustomTag/CustomTagCreateUpdate";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { CustomTagCreateDto } from "@/core/api/generated";

export interface CustomTagCreateUpdatePageQueryParams extends GeneralQueryParams {
  name?: CustomTagCreateDto["name"];
}

export default function CustomTagCreateUpdatePage() {
  const { customTagId } = useParams<{ customTagId?: string }>();
  const queryParams = useQueryParams<CustomTagCreateUpdatePageQueryParams>();
  const history = useHistory();

  const isCreate = !customTagId;

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader title={isCreate ? "Create new custom tag" : "Edit custom tag"} />
      }
    >
      <CustomTagCreateUpdate
        customTagId={customTagId}
        defaultValues={{
          ...queryParams,
        }}
        onSave={(newValue) => {
          history.goBack();
        }}
      />
    </CreateUpdatePageLayout>
  );
}

import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ContractDto } from "@/core/api/generated";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import VehicleOperations from "@/common/components/EntityComposed/Operations/VehicleOperations";

interface Props {
  contract: ContractDto;
}

export default function OperationsTabContent({ contract }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <VehicleOperations
          displayProps={{
            viewVariant: ViewLayoutVariant.Tab,
            header: false,
          }}
          vehicleId={contract.vehicle?.id}
          contractId={contract.id}
        />
      </Stack>
    </PageTabContent>
  );
}

import { SxProps, Theme } from "@mui/material";

import { GeneralStrictEntityRelationDto } from "@/core/api/generated";

import { BaseEntityLinkInheritableProps } from "../../components/BaseEntityLink";
import EntityLink from "../../components/EntityLink";

interface Props {
  value: GeneralStrictEntityRelationDto | null | undefined;
  linkProps?: Partial<BaseEntityLinkInheritableProps>;
  sx?: SxProps<Theme>;
}

export default function GeneralStrictEntityRelationLink({ value, linkProps, sx }: Props) {
  if (!value) {
    return null;
  }

  return (
    <EntityLink
      entityType={value.entityType}
      entityId={value.entityId}
      entity={
        value.vehicle ??
        value.vehicleDamage ??
        value.contract ??
        value.accessory ??
        value.accessoryCheck ??
        value.visualInspection ??
        value.damageDetection ??
        value.damageDetectionAggregate ??
        value.damageCostEvaluation ??
        value.damageCostEvaluationAggregate ??
        value.repairOperation ??
        value.assetSubscriptionPlan ??
        value.assetSubscription ??
        value.tenantConnectionRequest ??
        value.tenantConnection ??
        value.tenantRequest
      }
      linkProps={linkProps}
      sx={sx}
    />
  );
}

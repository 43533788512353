import { Badge, Stack, SxProps, Theme } from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";

import { FileItem } from "@/common/fileItem";

import { MimeTypeHelper } from "@/common/helpers/mimeType";
import NoFilesAlert from "../AppAlerts/NoFilesAlert";
import FullScreenFileViewerV2 from "../Images/FullScreenFileViewerV2";
import FileItemView, { FileItemViewActions } from "./FileItemView";

interface Props {
  files: FileItem[];
  /** Show '+N' if file count is greater than max value */
  maxVisibleFiles?: number;
  itemWidth?: number;
  itemHeight?: number;
  itemActions?: (file: FileItem) => FileItemViewActions;
  sx?: SxProps<Theme>;
}

/** Displays list of files. */
export default function FileListView({
  files = [],
  maxVisibleFiles,
  itemWidth = 60,
  itemHeight = 25,
  itemActions,
  sx,
}: Props) {
  // FullScreen
  const [fsFiles, setFsFiles] = useState<FileItem[]>([]);
  const [fsFileId, setFsFileId] = useState<string | undefined>(undefined);

  const maxVisibleFilesValue = useMemo(
    () =>
      _.isNil(maxVisibleFiles)
        ? files.length
        : Math.max(Math.min(maxVisibleFiles, files.length), 0),
    [files, maxVisibleFiles],
  );
  const visibleFiles = useMemo(
    () => files.slice(0, maxVisibleFiles),
    [files, maxVisibleFilesValue],
  );
  const hiddenFiles = useMemo(
    () => files.slice(maxVisibleFilesValue, files.length),
    [files, maxVisibleFilesValue],
  );

  const lastVisibleFileIndex = visibleFiles.length - 1;

  if (files.length === 0) {
    return <NoFilesAlert />;
  }

  return (
    <>
      <Stack direction='row' sx={{ alignItems: "center", flexWrap: "wrap", ...sx }} gap={1}>
        {visibleFiles.map((f, i) => (
          <Badge
            key={i}
            color='primary'
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              mb: 1,
              mr: 1,
              zIndex: 0,
            }}
            badgeContent={`+${hiddenFiles.length}`}
            invisible={i !== lastVisibleFileIndex || hiddenFiles.length === 0}
          >
            <FileItemView
              fileItem={f}
              size={{
                width: itemWidth,
                height: itemHeight,
              }}
              actions={{
                enabled: true,
                click: MimeTypeHelper.isImage(f.mimeType),
                onClick: () => {
                  if (MimeTypeHelper.isImage(f.mimeType)) {
                    setFsFiles(files.filter((x) => MimeTypeHelper.isImage(x.mimeType)));
                    setFsFileId(f.id);
                  }
                },
                download: true,
                openInNewTab: false,
                ...(itemActions?.(f) || {}),
              }}
              imageSx={{
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Badge>
        ))}
      </Stack>
      {/* Fullscreen file viewer */}
      <FullScreenFileViewerV2
        files={fsFiles}
        selectedFileId={fsFileId}
        onSelectFile={(file) => {
          setFsFileId(file.id);
        }}
        onClose={() => {
          setFsFiles([]);
          setFsFileId(undefined);
        }}
      />
    </>
  );
}

import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { VisualInspectionDto, VisualInspectionReferenceDto } from "@/core/api/generated";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getVisualInspectionOptionTitle } from "./VisualInspectionAutocomplete";

export interface VisualInspectionInlineProps extends BaseEntityInlineInheritableProps {
  entity: VisualInspectionDto | VisualInspectionReferenceDto | null | undefined;
  entityId?: string | null;
}

export default function VisualInspectionInline({
  entity,
  entityId,
  ...otherProps
}: VisualInspectionInlineProps) {
  const request = useApiRequest(
    apiClient.visualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      visualInspectionId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as VisualInspectionDto;
  const ref = entity as VisualInspectionReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='visualInspection' inText />}
      content={<>{full && getVisualInspectionOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Visual inspection"}
    />
  );
}

import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission, VehicleDamageDto, VehicleDamageReferenceDto } from "@/core/api/generated";

import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import VehicleDamageInline, { VehicleDamageInlineProps } from "./VehicleDamageInline";

interface Props extends BaseEntityLinkInheritableProps {
  vehicleId: string | null | undefined;
  entity: VehicleDamageDto | VehicleDamageReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<VehicleDamageInlineProps>;
}

export default function VehicleDamageLink({
  vehicleId,
  entity,
  entityId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  const full = entity as Nil<VehicleDamageDto>;
  const ref = entity as Nil<VehicleDamageReferenceDto>;
  const vehicleIdComputed = full?.vehicle?.id || ref?.vehicleId || vehicleId || undefined;
  const vehicleDamageIdComputed = entity?.id || entityId || undefined;

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.VehicleDamageRead])}
      to={
        vehicleIdComputed
          ? ROUTE_PATH.VEHICLE_DAMAGE_VIEW({
              vehicleId: vehicleIdComputed,
              vehicleDamageId: vehicleDamageIdComputed,
            })
          : ROUTE_PATH.VEHICLE_DAMAGE_VIEW_ALT({
              vehicleDamageId: vehicleDamageIdComputed,
            })
      }
      icon={undefined}
      content={
        <VehicleDamageInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Vehicle damage"}
    />
  );
}

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import AdjustIcon from "@mui/icons-material/Adjust";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AlarmIcon from "@mui/icons-material/Alarm";
import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import ApiIcon from "@mui/icons-material/Api";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BuildIcon from "@mui/icons-material/Build";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import BusinessIcon from "@mui/icons-material/Business";
import CableIcon from "@mui/icons-material/Cable";
import CancelIcon from "@mui/icons-material/Cancel";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import CategoryIcon from "@mui/icons-material/Category";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import CodeIcon from "@mui/icons-material/Code";
import DirectionsCarsIcon from "@mui/icons-material/Commute";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ConstructionIcon from "@mui/icons-material/Construction";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SourceIcon from "@mui/icons-material/DescriptionOutlined";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import EmailIcon from "@mui/icons-material/Email";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ExtensionIcon from "@mui/icons-material/Extension";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import FlagIcon from "@mui/icons-material/Flag";
import FolderIcon from "@mui/icons-material/Folder";
import ForwardIcon from "@mui/icons-material/Forward";
import GridOffIcon from "@mui/icons-material/GridOff";
import GroupsIcon from "@mui/icons-material/Groups";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HistoryIcon from "@mui/icons-material/History";
import HomeIcon from "@mui/icons-material/Home";
import ImageIcon from "@mui/icons-material/Image";
import LanguageIcon from "@mui/icons-material/Language";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LinkIcon from "@mui/icons-material/Link";
import ListIcon from "@mui/icons-material/List";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LockClockIcon from "@mui/icons-material/LockClock";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import MessageIcon from "@mui/icons-material/Message";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import NotesIcon from "@mui/icons-material/Notes";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PageViewIcon from "@mui/icons-material/Pageview";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PaymentIcon from "@mui/icons-material/Payment";
import PaymentsIcon from "@mui/icons-material/Payments";
import PendingIcon from "@mui/icons-material/Pending";
import PeopleIcon from "@mui/icons-material/People";
import PercentIcon from "@mui/icons-material/Percent";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PreviewIcon from "@mui/icons-material/Preview";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import PublicIcon from "@mui/icons-material/Public";
import PushPinIcon from "@mui/icons-material/PushPin";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RefreshIcon from "@mui/icons-material/Refresh";
import RemoveIcon from "@mui/icons-material/Remove";
import ReplayIcon from "@mui/icons-material/Replay";
import ReplyIcon from "@mui/icons-material/Reply";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RuleIcon from "@mui/icons-material/Rule";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SellIcon from "@mui/icons-material/Sell";
import SendIcon from "@mui/icons-material/Send";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountTreeIcon from "@mui/icons-material/Share";
import ShieldIcon from "@mui/icons-material/Shield";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import StopIcon from "@mui/icons-material/Stop";
import StorageIcon from "@mui/icons-material/Storage";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import TableRowsIcon from "@mui/icons-material/TableRows";
import TextureIcon from "@mui/icons-material/Texture";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import TodayIcon from "@mui/icons-material/Today";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import UploadIcon from "@mui/icons-material/Upload";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WebIcon from "@mui/icons-material/Web";
import WidgetsIcon from "@mui/icons-material/Widgets";
import WorkIcon from "@mui/icons-material/Work";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactNode, forwardRef } from "react";

import FileIcon from "../Files/FileIcon";
import TooltipFacade from "../Mui/Facades/TooltipFacade";
import ExpandCircleUp from "./FromMui/ExpandCircleUp";
import ArrowDownLeftIcon from "./Svg/ArrowDownLeftIcon";
import ArrowUpRightIcon from "./Svg/ArrowUpRightIcon";
import CalendarIcon from "./Svg/CalendarIcon";
import CancelCircleIcon from "./Svg/CancelCircleIcon";
import CancelCircleOutlineIcon from "./Svg/CancelCircleOutlineIcon";
import ChatIcon from "./Svg/ChatIcon";
import CheckCircleIconCustom from "./Svg/CheckCircleIcon";
import CheckCircleOutlineIcon from "./Svg/CheckCircleOutlineIcon";
import CheckOutlineIcon from "./Svg/CheckOutlineIcon";
import ClockIcon from "./Svg/ClockIcon";
import ClockOutlineIcon from "./Svg/ClockOutlineIcon";
import ContractIcon from "./Svg/ContractIcon";
import DashboardIcon from "./Svg/DashboardIcon";
import DataIcon from "./Svg/DataIcon";
import DeleteIcon from "./Svg/DeleteIcon";
import DocIcon from "./Svg/DocIcon";
import DownloadIcon from "./Svg/DownloadIcon";
import EditIcon from "./Svg/EditIcon";
import ExpandLessIcon from "./Svg/ExpandLessIcon";
import ExpandMoreIcon from "./Svg/ExpandMoreIcon";
import FuelIcon from "./Svg/FuelIcon";
import GaugeIcon from "./Svg/GaugeIcon";
import GaugeTimeIcon from "./Svg/GaugeTimeIcon";
import InfoOutlineIcon from "./Svg/InfoOutlineIcon";
import ManagementIcon from "./Svg/ManagementIcon";
import ModificationIcon from "./Svg/ModificationIcon";
import NexusIcon from "./Svg/NexusIcon";
import PaperPlaneIcon from "./Svg/PaperPlaneIcon";
import PersonIdIcon from "./Svg/PersonIdIcon";
import PersonOutlineIcon from "./Svg/PersonOutlineIcon";
import PinIcon from "./Svg/PinIcon";
import PlateNoIcon from "./Svg/PlateNoIcon";
import PoolIcon from "./Svg/Pool";
import RepairCar from "./Svg/RepairCar";
import ShieldCancelCircleIcon from "./Svg/ShieldCancelCircleIcon";
import ShieldCheckCircleIcon from "./Svg/ShieldCheckCircleIcon";
import SmashIcon from "./Svg/SmashIcon";
import SmashTimeIcon from "./Svg/SmashTimeIcon";
import VehicleFrontIcon from "./Svg/VehicleFrontIcon";
import VehicleSideIcon from "./Svg/VehicleSideIcon";
import WarningTriangleIcon from "./Svg/WarningTriangleIcon";

export const AppIconOf = {
  // general

  none: true,
  create: true,
  add: true,
  edit: true,
  settings: true,
  delete: true,
  remove: true,
  removePerson: true,
  clear: true,
  copy: true,
  check: true,
  email: true,
  mail: true,
  phone: true,
  operations: true,
  view: true,
  preview: true,
  hide: true,
  item: true,
  download: true,
  openInNew: true,
  openInFull: true,
  agree: true,
  disagree: true,
  done: true,
  clock: true,
  waiting: true,
  pending: true,
  info: true,
  help: true,
  tip: true,
  apply: true,
  accept: true,
  approve: true,
  approveAll: true,
  approved: true,
  decline: true,
  declineAll: true,
  declined: true,
  forceApprove: true,
  forceApproved: true,
  forceDecline: true,
  forceDeclined: true,
  acknowledge: true,
  pin: true,
  unpin: true,
  close: true,
  list: true,
  message: true,
  moreVert: true,
  moreHoriz: true,
  expandMore: true,
  expandMoreOutline: true,
  expandLess: true,
  expandLessOutline: true,
  chevronLeft: true,
  chevronRight: true,
  leave: true,
  exit: true,
  logout: true,
  history: true,
  data: true,
  entity: true,
  noData: true,
  notFound: true,
  contact: true,
  contacted: true,
  code: true,
  goBack: true,
  person: true,
  profile: true,
  arrowDropDown: true,
  arrowDropUp: true,
  money: true,
  price: true,
  percent: true,
  import: true,
  export: true,
  notes: true,
  addNotes: true,
  attachment: true,
  attachments: true,
  addAttachments: true,
  invite: true,
  invites: true,
  expired: true,
  send: true,
  resend: true,
  isFree: true,
  isPaid: true,
  enabled: true,
  disabled: true,
  start: true,
  dashboard: true,
  exclamationMark: true,
  visibility: true,
  visibilityOff: true,
  other: true,
  tag: true,
  customTag: true,
  subItem: true,
  webApp: true,
  success: true,
  successFilled: true,
  warning: true,
  error: true,
  address: true,
  reminder: true,
  reminderOn: true,
  reminderOff: true,
  suspend: true,
  unsuspend: true,
  arrowBack: true,
  arrowForward: true,
  arrowUpward: true,
  arrowDownward: true,
  replay: true,
  image: true,
  photo: true,
  file: true,
  session: true,
  modification: true,
  gauge: true,
  gaugeTime: true,
  damage: true,
  damageTime: true,
  refresh: true,
  search: true,
  country: true,
  currency: true,
  culture: true,
  duration: true,
  availability: true,
  unpaid: true,
  active: true,
  activate: true,
  pastDue: true,
  ended: true,
  paused: true,
  cancel: true,
  canceled: true,
  revoke: true,
  nothing: true,
  respond: true,
  complete: true,
  report: true,
  menu: true,
  folder: true,
  pageView: true,
  bookmarkBorder: true,
  bookmark: true,
  favorite: true,
  link: true,
  addRoad: true,
  widgets: true,
  adjust: true,
  notifications: true,
  notificationsActive: true,
  date: true,
  time: true,
  swapHoriz: true,
  swapVert: true,
  draft: true,
  zoomIn: true,
  zoomOut: true,
  rotateLeft: true,
  rotateRight: true,
  global: true,
  moveFile: true,
  moveFolder: true,
  moveItem: true,
  columns: true,
  rows: true,
  sort: true,
  sorting: true,
  sortAsc: true,
  sortDesc: true,
  filter: true,
  filters: true,

  // specific
  nexus: true,
  role: true,
  roles: true,
  permission: true,
  permissions: true,
  user: true,
  users: true,
  inspector: true,
  accessory: true,
  accessoryInOrder: true,
  accessoryMissing: true,
  accessoryDamaged: true,
  vehicle: true,
  vehicleSide: true,
  vehicles: true,
  contract: true,
  contracts: true,
  customer: true,
  customers: true,
  invoice: true,
  invoices: true,
  visualInspection: true,
  visualInspections: true,
  damageDetections: true,
  damageDetection: true,
  damageDetectionAggregate: true,
  damageCostEvaluation: true,
  damageCostEvaluationAggregate: true,
  damageCostEvaluations: true,
  department: true,
  departments: true,
  location: true,
  productLocation: true,
  spot: true,
  accessoryCheck: true,
  accessoryChecks: true,
  repairCar: true,
  repairOperation: true,
  repairOperations: true,
  documents: true,
  document: true,
  calendar: true,
  chat: true,
  chatMessage: true,
  chatEvent: true,
  chatPersonal: true,
  chatGroup: true,
  chatComment: true,
  chatNegotiation: true,
  negotiation: true,
  negotiations: true,
  negotiationProposal: true,
  repair: true,
  repairMaterial: true,
  repairWork: true,
  repairSparePart: true,
  repairSpec: true,
  vehicleDamage: true,
  flow: true,
  flows: true,
  fuel: true,
  assessmentFlow: true,
  management: true,
  integration: true,
  apiClient: true,
  subscription: true,
  subscriptionPlan: true,
  assetSubscriptionPlan: true,
  assetSubscription: true,
  adminPanel: true,
  payment: true,
  paymentProfile: true,
  party: true,
  consensus: true,
  forcedConsensus: true,
  noConsensus: true,
  company: true,
  companyProfile: true,
  tenant: true,
  tenants: true,
  tenantProfile: true,
  tenantToTenant: true,
  tenantConnection: true,
  tenantRequest: true,
  tenantRequestAppliedResult: true,
  dataGrant: true,
  referenceData: true,
  switchAccount: true,
  vehiclePartType: true,
  vehicleVisualModel: true,
  vehicleMake: true,
  vehicleModel: true,
  vehicleGeneration: true,
  vehicleModification: true,
  damageType: true,
  plateNo: true,
  VIN: true,
  personId: true,
  personOutline: true,
  product: true,
  pool: true,
  poolItem: true,
  pinLocation: true,
  asset: true,
  includedOption: true,
  extraOption: true,
  theme: true,
  checkOut: true,
  checkIn: true,
  tesla: true,
  teslaConnection: true,
} as const;
export type AppIconOf = keyof typeof AppIconOf;

export type AppIconProps = SvgIconProps & {
  /** What entity/object this icon represents. */
  of: AppIconOf;
  /** Whether icon is rendered in text and should be vertically aligned. */
  inText?: boolean;
  tooltipTitle?: string | ReactNode;
};

type iconType = typeof SvgIcon | ReturnType<typeof forwardRef>;

const iconMap: Record<AppIconProps["of"], iconType> = {
  // general
  none: RemoveIcon,
  create: AddIcon,
  add: AddIcon,
  edit: EditIcon as iconType,
  settings: SettingsIcon,
  remove: DeleteIcon as iconType,
  removePerson: PersonRemoveIcon,
  delete: DeleteIcon as iconType,
  clear: ClearIcon,
  copy: ContentCopyIcon,
  check: CheckIcon,
  email: EmailIcon,
  mail: EmailIcon,
  phone: PhoneIcon,
  operations: ExtensionIcon,
  view: VisibilityIcon,
  preview: PreviewIcon,
  hide: VisibilityOffIcon,
  item: DataIcon as iconType,
  download: FileDownloadOutlinedIcon,
  openInNew: OpenInNewIcon,
  openInFull: OpenInFullIcon,
  agree: ThumbUpIcon,
  disagree: ThumbDownIcon,
  done: DoneIcon,
  clock: ClockIcon as iconType,
  waiting: ClockOutlineIcon as iconType,
  pending: PendingIcon,
  info: InfoOutlineIcon as iconType,
  help: HelpOutlineIcon,
  tip: LightbulbIcon,
  apply: CheckCircleIconCustom as iconType,
  accept: CheckCircleIconCustom as iconType,
  approve: CheckCircleIconCustom as iconType,
  approveAll: CheckCircleOutlineIcon as iconType,
  approved: CheckCircleIconCustom as iconType,
  decline: CancelCircleIcon as iconType,
  declineAll: CancelCircleOutlineIcon as iconType,
  declined: CancelCircleIcon as iconType,
  forceApprove: ShieldCheckCircleIcon as iconType,
  forceApproved: ShieldCheckCircleIcon as iconType,
  forceDecline: ShieldCancelCircleIcon as iconType,
  forceDeclined: ShieldCancelCircleIcon as iconType,
  acknowledge: DoneAllIcon,
  pin: PushPinIcon,
  unpin: PushPinIcon,
  close: CloseIcon,
  list: ListIcon,
  message: MessageIcon,
  moreVert: MoreVertIcon,
  moreHoriz: MoreHorizIcon,
  expandMore: ExpandMoreIcon as iconType,
  expandMoreOutline: ExpandCircleDownIcon,
  expandLess: ExpandLessIcon as iconType,
  expandLessOutline: ExpandCircleUp as iconType,
  chevronLeft: ChevronLeftIcon,
  chevronRight: ChevronRightIcon,
  leave: LogoutIcon,
  exit: LogoutIcon,
  logout: LogoutIcon,
  history: HistoryIcon,
  data: DataIcon as iconType,
  entity: DataIcon as iconType,
  noData: GridOffIcon,
  notFound: SearchOffIcon,
  contact: PersonIcon,
  contacted: ConnectWithoutContactIcon,
  code: CodeIcon,
  goBack: ArrowBackIcon,
  person: PersonIcon,
  profile: PersonIcon,
  arrowDropDown: ArrowDropDownIcon,
  arrowDropUp: ArrowDropUpIcon,
  money: PaymentsIcon,
  price: PaymentsIcon,
  percent: PercentIcon,
  import: DownloadIcon as iconType,
  export: UploadIcon,
  notes: NotesIcon,
  addNotes: PostAddIcon,
  attachment: AttachFileIcon,
  attachments: AttachFileIcon,
  addAttachments: AttachFileIcon,
  invite: EmailIcon,
  invites: PersonAddIcon,
  expired: TimerOffIcon,
  send: PaperPlaneIcon as iconType,
  resend: SendIcon,
  isFree: MoneyOffIcon,
  isPaid: AttachMoneyIcon,
  enabled: CheckIcon,
  disabled: UnpublishedIcon,
  start: PlayCircleOutlineIcon,
  dashboard: DashboardIcon as iconType,
  exclamationMark: PriorityHighIcon,
  visibility: VisibilityIcon,
  visibilityOff: VisibilityOffIcon,
  other: MoreHorizIcon,
  tag: SellIcon,
  customTag: SellIcon,
  subItem: AccountTreeIcon,
  webApp: WebIcon,
  success: DoneIcon,
  successFilled: CheckCircleIcon,
  warning: WarningTriangleIcon as iconType,
  error: ErrorIcon,
  address: HomeIcon,
  reminder: AlarmIcon,
  reminderOn: AlarmIcon,
  reminderOff: AlarmOffIcon,
  suspend: PersonOffIcon,
  unsuspend: PersonAddIcon,
  arrowBack: ArrowBackIcon,
  arrowForward: ArrowForwardIcon,
  arrowUpward: ArrowUpwardIcon,
  arrowDownward: ArrowDownwardIcon,
  replay: ReplayIcon,
  image: ImageIcon,
  photo: ImageIcon,
  file: FileIcon as iconType,
  session: PermIdentityIcon,
  personId: PersonIdIcon as iconType,
  personOutline: PersonOutlineIcon as iconType,
  modification: ModificationIcon as iconType,
  gauge: GaugeIcon as iconType,
  gaugeTime: GaugeTimeIcon as iconType,
  damage: SmashIcon as iconType,
  damageTime: SmashTimeIcon as iconType,
  refresh: RefreshIcon,
  search: SearchIcon as iconType,
  country: FlagIcon,
  currency: AttachMoneyIcon,
  culture: LanguageIcon,
  duration: TimelapseIcon,
  availability: ChecklistIcon,
  unpaid: MoneyOffIcon,
  active: CheckCircleIconCustom as iconType,
  activate: CheckCircleIconCustom as iconType,
  pastDue: MoneyOffIcon,
  ended: StopIcon,
  paused: PauseCircleOutlineIcon,
  cancel: CancelIcon,
  canceled: CancelIcon,
  revoke: CancelIcon,
  nothing: NotInterestedIcon,
  respond: ReplyIcon,
  complete: CheckCircleOutlineIcon as iconType,
  report: AssignmentTurnedInIcon,
  menu: MenuIcon,
  folder: FolderIcon,
  pageView: PageViewIcon,
  bookmarkBorder: BookmarkBorderIcon,
  bookmark: BookmarkIcon,
  favorite: FavoriteIcon,
  link: LinkIcon,
  addRoad: AddRoadIcon,
  widgets: WidgetsIcon,
  adjust: AdjustIcon,
  notifications: NotificationsIcon,
  notificationsActive: NotificationsActiveIcon,
  date: TodayIcon,
  time: AccessTimeIcon,
  swapHoriz: SwapHorizIcon,
  swapVert: SwapVertIcon,
  draft: EditNoteIcon,
  zoomIn: ZoomInIcon,
  zoomOut: ZoomOutIcon,
  rotateLeft: RotateLeftIcon,
  rotateRight: RotateRightIcon,
  global: PublicIcon,
  moveFile: ForwardIcon,
  moveFolder: DriveFileMoveIcon,
  moveItem: ForwardIcon,
  columns: ViewColumnIcon,
  rows: TableRowsIcon,
  sorting: SwapVertIcon,
  sort: SwapVertIcon,
  sortAsc: ArrowUpwardIcon,
  sortDesc: ArrowDownwardIcon,
  filter: FilterListIcon,
  filters: FilterListIcon,

  // specific
  nexus: NexusIcon as iconType,
  role: PermContactCalendarIcon,
  roles: PermContactCalendarIcon,
  permission: ShieldIcon,
  permissions: ShieldIcon,
  user: PersonIcon,
  users: PeopleIcon,
  inspector: PersonIcon,
  accessory: DataIcon as iconType,
  accessoryInOrder: CheckCircleOutlineIcon as iconType,
  accessoryMissing: HighlightOffIcon,
  accessoryDamaged: HeartBrokenIcon,
  vehicle: VehicleFrontIcon as iconType,
  vehicleSide: VehicleSideIcon as iconType,
  vehicles: DirectionsCarsIcon,
  contract: WorkIcon,
  contracts: ContractIcon as iconType,
  customer: PersonIcon,
  customers: PeopleIcon,
  invoice: ReceiptIcon,
  invoices: ReceiptIcon,
  visualInspection: BurstModeIcon,
  visualInspections: BurstModeIcon,
  damageDetections: CarCrashIcon,
  damageDetection: CarCrashIcon,
  damageDetectionAggregate: CarCrashIcon,
  damageCostEvaluation: PriceCheckIcon,
  damageCostEvaluationAggregate: PriceCheckIcon,
  damageCostEvaluations: PriceCheckIcon,
  department: BusinessIcon,
  departments: BusinessIcon,
  location: LocationCityIcon,
  productLocation: LocationCityIcon,
  spot: PlaceIcon,
  accessoryCheck: RuleIcon,
  accessoryChecks: RuleIcon,
  repairCar: RepairCar as iconType,
  repairOperation: BuildIcon,
  repairOperations: BuildIcon,
  documents: SourceIcon,
  document: DocIcon as iconType,
  chat: ChatIcon as iconType,
  calendar: CalendarIcon as iconType,
  chatMessage: MessageIcon,
  chatEvent: ElectricBoltIcon,
  chatPersonal: ChatIcon as iconType,
  chatGroup: ChatIcon as iconType,
  chatComment: ChatIcon as iconType,
  chatNegotiation: ChatIcon as iconType,
  negotiation: SafetyDividerIcon,
  negotiations: SafetyDividerIcon,
  negotiationProposal: TipsAndUpdatesIcon,
  repair: BuildIcon,
  repairMaterial: ArchitectureIcon,
  repairWork: ConstructionIcon,
  repairSparePart: BuildIcon,
  repairSpec: AssignmentIcon,
  vehicleDamage: HeartBrokenIcon,
  flow: AccountTreeIcon,
  flows: AccountTreeIcon,
  fuel: FuelIcon as iconType,
  assessmentFlow: AssessmentIcon,
  management: ManagementIcon as iconType,
  integration: CableIcon,
  apiClient: ApiIcon,
  payment: PaymentIcon,
  paymentProfile: PaymentIcon,
  pinLocation: PinIcon as iconType,
  subscription: SubscriptionsIcon,
  subscriptionPlan: SubscriptionsIcon,
  assetSubscriptionPlan: SubscriptionsIcon,
  assetSubscription: SubscriptionsIcon,
  adminPanel: AdminPanelSettingsIcon,
  party: GroupsIcon,
  consensus: CheckOutlineIcon as iconType,
  forcedConsensus: ShieldCheckCircleIcon as iconType,
  noConsensus: CancelCircleIcon as iconType,
  company: BusinessIcon,
  companyProfile: BusinessIcon,
  tenant: BusinessIcon,
  tenants: BusinessIcon,
  tenantProfile: BusinessIcon,
  tenantToTenant: HandshakeIcon,
  tenantConnection: HandshakeIcon,
  tenantRequest: HandshakeIcon,
  tenantRequestAppliedResult: PlaylistAddCheckCircleIcon,
  dataGrant: LockClockIcon,
  referenceData: StorageIcon,
  switchAccount: SwitchAccountIcon,
  vehiclePartType: ConstructionIcon,
  vehicleVisualModel: TextureIcon,
  vehicleMake: CategoryIcon,
  vehicleModel: CategoryIcon,
  vehicleGeneration: CategoryIcon,
  vehicleModification: CategoryIcon,
  damageType: CarCrashIcon,
  plateNo: PlateNoIcon as iconType,
  VIN: PersonIdIcon as iconType,
  product: ShoppingBasketIcon,
  pool: PoolIcon as iconType,
  poolItem: DataIcon as iconType,
  asset: LocalOfferIcon,
  includedOption: PlaylistAddCheckIcon,
  extraOption: PlaylistAddIcon,
  theme: LightModeOutlinedIcon,
  checkOut: ArrowDownLeftIcon as iconType,
  checkIn: ArrowUpRightIcon as iconType,
  tesla: VehicleFrontIcon as iconType,
  teslaConnection: VehicleFrontIcon as iconType,
};

/** Simplifies and unifies usage of MUI icons across the app.
 *  Also reduces number of imports in components using many icons.
 */
export default forwardRef<SVGSVGElement, AppIconProps>(function AppIcon(
  { of, inText, tooltipTitle, ...other }: AppIconProps,
  ref,
) {
  const IconComp = iconMap[of];
  if (!IconComp) {
    return null;
  }
  return (
    <TooltipFacade enabled={!!tooltipTitle} title={tooltipTitle || ""}>
      <IconComp
        ref={ref}
        color='inherit'
        fontSize='inherit'
        {...other}
        sx={{
          verticalAlign: inText ? "middle" : undefined,
          ...(other?.sx || {}),
        }}
      />
    </TooltipFacade>
  );
});

import { appCommonConfig } from "@/config/config";
import { CustomEnvName } from "../constants/customEnvName";

export class EnvHelper {
  public static get customEnv(): CustomEnvName {
    /** Returns current env. */
    return appCommonConfig.customEnv;
  }

  public static get isLocalhostAny() {
    return /^\w+Localhost$/.test(this.customEnv);
  }

  public static get isDevelopmentAny() {
    return /^Development\w+$/.test(this.customEnv);
  }

  public static get isStagingAny() {
    return /^Staging\w+$/.test(this.customEnv);
  }

  public static get isProductionAny() {
    return /^Production\w+$/.test(this.customEnv);
  }
}

import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  RepairOperationDto,
  RepairOperationItemDto,
  RepairOperationReferenceDto,
} from "@/core/api/generated";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import TextLineSkeleton from "../../Skeleton/TextLineSkeleton";
import { getRepairOperationOptionTitle } from "./RepairOperationAutocomplete";

export interface RepairOperationInlineProps extends BaseEntityInlineInheritableProps {
  entity: RepairOperationDto | RepairOperationReferenceDto | null | undefined;
  entityId?: string | null;
  item?: RepairOperationItemDto;
  itemId?: string | null;
}

export default function RepairOperationInline({
  entity,
  entityId,
  item,
  itemId,
  ...otherProps
}: RepairOperationInlineProps) {
  const request = useApiRequest(
    apiClient.repairOperationsApi.apiV1RepairOperationsRepairOperationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairOperationId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as RepairOperationDto;
  const ref = entity as RepairOperationReferenceDto;

  item = item || (itemId ? full?.items?.find((x) => x.id === itemId) : undefined);

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='repairOperation' inText />}
      content={
        <>
          {full && getRepairOperationOptionTitle(full)}
          {item && <> (#{item.localNumber})</>}
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Repair operation"}
    />
  );
}

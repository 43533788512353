import { apiClient } from "@/core/api/ApiClient";
import { ContractDto, EntityType } from "@/core/api/generated";
import BaseEntitiesDeleteModal, {
  BaseEntitiesDeleteModalInheritableProps,
} from "../components/BaseEntitiesDeleteModal";

export interface OwnProps {
  entities: ContractDto[];
}

type Props = OwnProps & BaseEntitiesDeleteModalInheritableProps;

export default function ContractsDeleteModal({ entities, ...otherProps }: Props) {
  return (
    <BaseEntitiesDeleteModal
      cascadeActionDisplay={true}
      entityType={EntityType.Contract}
      entities={entities}
      deleteFunc={(params) =>
        apiClient.contractsApi.apiV1ContractsBulkDeleteDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          contractBulkDeleteDto: {
            ids: params.entityIds,
            relatedEntitiesTypesToDelete: params.relatedEntitiesTypes,
          },
        })
      }
      {...otherProps}
    />
  );
}

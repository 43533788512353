import { InAppNotificationReceivedDto } from "@/core/api/generated";
import { TypedEventEmitter } from "../eventEmmiters/typedEventEmitter";

export class NotificationService extends TypedEventEmitter<{
  // list of supported events
  inAppNotificationReceived: InAppNotificationReceivedDto;
}> {
  public handleNewInAppNotification(data: InAppNotificationReceivedDto) {
    this.emit("inAppNotificationReceived", data);
  }
}

export const notificationService = new NotificationService();

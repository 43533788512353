import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import useMounted from "@/common/hooks/mount/useMounted";
import { ValidationHelper } from "@/common/validation";
import {
  AdminCreateVehicleModificationDto,
  AdminUpdateVehicleModificationDto,
} from "@/core/api/generated";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { BaseFormikValues } from "@/common/ts/error";
import { apiClient } from "@/core/api/ApiClient";
import VehicleGenerationAutocomplete from "../../components/ReferenceData/VehicleGeneration/VehicleGenerationAutocomplete";
import VehicleMakeAutocomplete from "../../components/ReferenceData/VehicleMake/VehicleMakeAutocomplete";
import VehicleModelAutocomplete from "../../components/ReferenceData/VehicleModel/VehicleModelAutocomplete";
import ReferenceDataSyncStopAlert from "../ReferenceDataSyncStopAlert";
import VehicleBodyTypeAutocomplete from "../../components/ReferenceData/VehicleBodyType/VehicleBodyTypeAutocomplete";
import VehicleFuelTypeAutocomplete from "../../components/ReferenceData/VehicleFuelType/VehicleFuelTypeAutocomplete";

export default function VehicleModificationCreateUpdatePage() {
  const { modificationId } = useParams<{ modificationId?: string }>();
  const mounted = useMounted();
  const history = useHistory();

  const isCreate = !modificationId;
  const isEdit = !!modificationId;

  const modificationRequest = useApiRequest(
    apiClient.adminVehicleModificationsApi
      .apiV1AdminReferenceDataVehiclesModificationsModificationIdGet,
    {
      modificationId: modificationId!,
    },
    {
      skip: !modificationId,
    },
  );
  const modification = modificationRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: modification && {
      idValue: modification.id!,
      newTitle: modification.name || modification.globalNumber || "",
    },
  });

  if (isEdit && modificationRequest.isLoading) {
    return <LinearProgress />;
  }
  if (isEdit && !modificationRequest.isLoading && !modification) {
    return <EntityNotFoundAlert />;
  }

  return (
    <Formik<
      AdminCreateVehicleModificationDto & AdminUpdateVehicleModificationDto & BaseFormikValues
    >
      enableReinitialize={!isCreate}
      initialValues={{
        makeId: modification?.make?.id || "",
        modelId: modification?.model?.id || "",
        generationId: modification?.generation?.id || undefined,
        name: modification?.name || "",
        bodyTypeId: modification?.bodyType?.id || undefined,
        fuelTypeId: modification?.fuelType?.id || undefined,
        vehicleSize: modification?.vehicleSize || undefined,
        isEnabled: modification?.isEnabled ?? true,
        sortOrder: modification?.sortOrder || 0,
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // vehicleType: Yup.string().required("This field is required"),
        // name: Yup.string().required("This field is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          if (isCreate) {
            await apiClient.adminVehicleModificationsApi.apiV1AdminReferenceDataVehiclesModificationsPost(
              {
                adminCreateVehicleModificationDto: {
                  ..._.omit(values, "submit"),
                },
              },
            );
          } else {
            await apiClient.adminVehicleModificationsApi.apiV1AdminReferenceDataVehiclesModificationsModificationIdPut(
              {
                modificationId,
                adminUpdateVehicleModificationDto: {
                  ..._.omit(values, "submit"),
                },
              },
            );
          }

          if (mounted.current) {
            setStatus({ success: true });
          }
          history.goBack();
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <CreateUpdatePageLayout
            header={
              <SimpleViewPageHeader
                title={isCreate ? "Create new modification" : "Edit modification"}
              />
            }
          >
            <form noValidate onSubmit={handleSubmit}>
              {modification && isEdit && (
                <ReferenceDataSyncStopAlert
                  sx={{ mb: 2 }}
                  isBuiltIn={modification.isBuiltIn ?? false}
                  car2DbId={undefined}
                />
              )}

              <Box>
                <Grid container columnSpacing={2} rowSpacing={0}>
                  <Grid item xxs={12} md={4}>
                    <FormControl margin='normal' fullWidth>
                      <VehicleMakeAutocomplete
                        disabled={isEdit}
                        entityId={values.makeId}
                        required
                        textFieldProps={{
                          error: Boolean(errors.makeId),
                          helperText: ValidationHelper.getFormikErrorsAsString(errors.makeId),
                        }}
                        onChange={(newValue) => {
                          setFieldValue("makeId", newValue?.id || undefined);
                          setFieldValue("modelId", undefined);
                          setFieldValue("generationId", undefined);
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xxs={12} md={4}>
                    <FormControl margin='normal' fullWidth>
                      <VehicleModelAutocomplete
                        disabled={isEdit || !values.makeId}
                        entityId={values.modelId}
                        required
                        searchFilters={{
                          makeId: values.makeId,
                        }}
                        textFieldProps={{
                          error: Boolean(errors.modelId),
                          helperText: ValidationHelper.getFormikErrorsAsString(errors.modelId),
                        }}
                        onChange={(newValue) => {
                          setFieldValue("modelId", newValue?.id || undefined);
                          setFieldValue("generationId", undefined);
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xxs={12} md={4}>
                    <FormControl margin='normal' fullWidth>
                      <VehicleGenerationAutocomplete
                        entityId={values.generationId}
                        searchFilters={{
                          makeId: values.makeId,
                          modelId: values.modelId,
                        }}
                        disabled={!values.makeId || !values.modelId}
                        textFieldProps={{
                          error: Boolean(errors.generationId),
                          helperText: ValidationHelper.getFormikErrorsAsString(errors.generationId),
                        }}
                        onChange={(newValue) => {
                          setFieldValue("generationId", newValue?.id || undefined);
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <TextField
                  required
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='normal'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name}
                  variant='outlined'
                />

                <FormControl
                  sx={{ minWidth: 200 }}
                  fullWidth
                  margin='normal'
                  error={Boolean(errors.vehicleSize)}
                >
                  <InputLabel>Vehicle size</InputLabel>
                  <ApiEnumSelect
                    type='VehicleSize'
                    value={values.vehicleSize}
                    onChange={(newValue) => {
                      setFieldValue("vehicleSize", newValue || undefined);
                    }}
                    selectProps={{
                      label: "Vehicle type",
                    }}
                  />
                  <FormHelperText>{errors.vehicleSize}</FormHelperText>
                </FormControl>

                <FormControl margin='normal' fullWidth>
                  <VehicleBodyTypeAutocomplete
                    entityId={values.bodyTypeId}
                    textFieldProps={{
                      error: Boolean(errors.bodyTypeId),
                      helperText: ValidationHelper.getFormikErrorsAsString(errors.bodyTypeId),
                    }}
                    onChange={(newValue) => {
                      setFieldValue("bodyTypeId", newValue?.id || undefined);
                    }}
                  />
                </FormControl>

                <FormControl margin='normal' fullWidth>
                  <VehicleFuelTypeAutocomplete
                    entityId={values.fuelTypeId}
                    textFieldProps={{
                      error: Boolean(errors.fuelTypeId),
                      helperText: ValidationHelper.getFormikErrorsAsString(errors.fuelTypeId),
                    }}
                    onChange={(newValue) => {
                      setFieldValue("fuelTypeId", newValue?.id || undefined);
                    }}
                  />
                </FormControl>

                <TextField
                  autoFocus
                  rows={2}
                  error={Boolean(touched.sortOrder && errors.sortOrder)}
                  fullWidth
                  helperText={touched.sortOrder && errors.sortOrder}
                  label='SortOrder'
                  margin='normal'
                  name='sortOrder'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputMode='numeric'
                  value={values.sortOrder || 0}
                  variant='outlined'
                  InputProps={{ inputProps: { min: 0, max: 10000000 } }}
                />

                <FormControl error={Boolean(errors.isEnabled)}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isEnabled'
                        checked={values.isEnabled}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label='Enabled'
                  />
                  <FormHelperText>
                    Whether this reference data entity is enabled for referencing by other entities.
                    When disabled, the entity is unavailable for paginated/search/list requests but
                    available by id for old entities already referencing it.
                  </FormHelperText>
                  {errors.isEnabled && <FormHelperText error>{errors.isEnabled}</FormHelperText>}
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </form>
          </CreateUpdatePageLayout>
        );
      }}
    </Formik>
  );
}

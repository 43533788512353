import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitiesSearchAutocomplete, {
  InheritableBaseEntitiesSearchAutocompleteProps,
} from "@/common/components/Entity/components/BaseEntitiesSearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { DamageDetectionDto, DamageDetectionSearchPaginatedDto } from "@/core/api/generated";
import ContractInline from "../Contract/ContractInline";
import VehicleInline from "../Vehicle/VehicleInline";
import { damageDetectionToOption } from "./DamageDetectionAutocomplete";

export interface DamageDetectionsAutocompleteProps
  extends InheritableBaseEntitiesSearchAutocompleteProps<DamageDetectionDto> {
  searchFilters: Partial<Omit<DamageDetectionSearchPaginatedDto, "search" | "includeIds">>;
}

export default function DamageDetectionsAutocomplete({
  isPreload = true,
  searchFilters,
  ...otherProps
}: DamageDetectionsAutocompleteProps) {
  return (
    <BaseEntitiesSearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={damageDetectionToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.damageDetectionsApi.apiV1DamageDetectionsSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          damageDetectionSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          damageDetectionSearchPaginatedDto: {
            ...params.damageDetectionSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Damage detections'
      placeholder='Search for damage detection...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='damageDetection' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography component='div' variant='body1'>
                    {option.title}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body2' color='secondary'>
                      <Box>
                        <VehicleInline
                          entity={option.data.vehicle}
                          withIcon={false}
                          withTooltip={false}
                        />
                      </Box>
                      {option.data?.contract && (
                        <Box>
                          <ContractInline
                            entity={option.data?.contract}
                            withIcon={false}
                            withTooltip={false}
                          />
                        </Box>
                      )}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

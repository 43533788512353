import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission, UserDto, UserStatus } from "@/core/api/generated";

import { apiClient } from "@/core/api/ApiClient";
import { ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { ReactNode } from "react";
import AuthorizedElement from "../../Auth/AuthorizedElement";
import AuthorizedMenuItem from "../../Auth/AuthorizedMenuItem";
import EntityMenu from "../../EntityMenu/EntityMenu";
import AppIcon from "../../Icons/AppIcon";
import AppLink from "../../Link/AppLink";
import SuspendUserModal from "./SuspendUserModal";
import UnsuspendUserModal from "./UnsuspendUserModal";
import UserDeleteModal from "./UserDeleteModal";

enum ActionFlags {
  Edit = "Edit",
  EditRole = "EditRole",
  Delete = "Delete",
  Status = "Status",
}
const defaultDisplayProps = {
  actions: {
    edit: true,
    editRole: true,
    delete: true,
    status: true,
  },
};

interface TriggersProps {
  entity?: UserDto | null;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity?: UserDto | null;
  disabled?: boolean;
  onUpdate: ((newValue: UserDto | null | undefined) => void) | undefined;
  onDelete?: (() => void) | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customActions?: (props: TriggersProps) => ReactNode | Element;
}

export default function UserMenu({
  entity,
  disabled,
  customActions,
  onUpdate,
  onDelete,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = { ...defaultDisplayProps, ...displayProps };
  return (
    <EntityMenu
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {customActions &&
            customActions({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}
          {displayProps?.actions?.edit && (
            <AuthorizedMenuItem
              permissions={[AppPermission.TenantUserManage]}
              component={AppLink}
              to={ROUTE_PATH.TENANT_USER_EDIT(entity?.id)}
            >
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.editRole && (
            <AuthorizedMenuItem
              permissions={[AppPermission.RoleAssignmentManage]}
              component={AppLink}
              to={ROUTE_PATH.TENANT_USER_ROLES_EDIT(entity?.id)}
            >
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit roles</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.status && (
            <AuthorizedElement permissions={[AppPermission.TenantUserManage]}>
              {entity?.status !== UserStatus.Suspended && (
                <MenuItem onClick={() => startAction(ActionFlags.Status)}>
                  <ListItemIcon>
                    <AppIcon of='suspend' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Suspend</ListItemText>
                </MenuItem>
              )}
              {entity?.status === UserStatus.Suspended && (
                <MenuItem onClick={() => startAction(ActionFlags.Status)}>
                  <ListItemIcon>
                    <AppIcon of='unsuspend' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Resume (unsuspend)</ListItemText>
                </MenuItem>
              )}
            </AuthorizedElement>
          )}
          {displayProps?.actions?.delete && (
            <AuthorizedMenuItem
              permissions={[AppPermission.TenantUserManage]}
              onClick={() => startAction(ActionFlags.Delete)}
            >
              <ListItemIcon>
                <AppIcon of='removePerson' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Remove</ListItemText>
            </AuthorizedMenuItem>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {/* Suspend */}
          {entity && (
            <SuspendUserModal
              open={currentAction === ActionFlags.Status && entity.status !== UserStatus.Suspended}
              onClose={() => {
                cancelAction();
              }}
              user={entity}
              suspendFunc={async (params) => {
                await apiClient.usersApi.apiV1UsersTargetUserIdSuspendPost({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  targetUserId: params.targetUserId,
                  userSuspendDto: {
                    ...params,
                  },
                });
                onUpdate && onUpdate(null);
                completeAction();
              }}
            />
          )}

          {/* Unsuspend */}
          {entity && (
            <UnsuspendUserModal
              open={currentAction === ActionFlags.Status && entity.status === UserStatus.Suspended}
              onClose={() => {
                cancelAction();
              }}
              user={entity}
              unsuspendFunc={async (params) => {
                await apiClient.usersApi.apiV1UsersTargetUserIdUnsuspendPost({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  targetUserId: params.targetUserId,
                  userUnsuspendDto: {
                    ...params,
                  },
                });
                onUpdate && onUpdate(null);
                completeAction();
              }}
            />
          )}
          {/* Delete entity */}
          {entity && (
            <UserDeleteModal
              entity={entity}
              open={currentAction === ActionFlags.Delete}
              onClose={() => cancelAction()}
              onDelete={() => {
                onDelete && onDelete();
                completeAction();
              }}
            />
          )}
        </Stack>
      )}
    />
  );
}

import { useHistory, useParams } from "react-router";

import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import VehicleCreateUpdate from "@/common/components/Entity/Vehicle/VehicleCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function VehicleCreateUpdatePage() {
  const { vehicleId } = useParams<{ vehicleId?: string }>();
  const isCreate = !vehicleId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={isCreate ? "Create new vehicle" : "Edit vehicle"} />}
    >
      <VehicleCreateUpdate
        entityId={vehicleId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.VEHICLE_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}

import { apiClient } from "@/core/api/ApiClient";
import { RoleDto } from "@/core/api/generated";
import RolesSelector, { RolesSelectorProps } from "./RolesSelector";
import { useCallback, useMemo } from "react";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";

export interface TenantRolesSelectorProps
  extends Omit<RolesSelectorProps, "getRoles" | "roleList" | "roleAssignmentRestrictions"> {
  tenantId?: string;
}

export default function AdminTenantRolesSelector({
  tenantId,
  ...otherProps
}: TenantRolesSelectorProps) {
  const getRoles = useCallback(async () => {
    if (!tenantId) return [];
    console.log("FETCH ROLES");
    const response = await apiClient.adminAccountApi.apiV1AdminAccountTenantsTenantIdRolesGet({
      tenantId: tenantId!,
    });
    return response.data;
  }, [tenantId]);

  return <RolesSelector getRoles={getRoles} disabled={!tenantId} {...otherProps} />;
}

import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { clipboardService } from "@/common/services/clipboard";
import { useResponsiveValueResolver } from "@/theme/hooks";
import { ResponsiveStyleValueCustom } from "@/theme/types";
import { Box, BoxProps, Divider, IconButton, SxProps, Theme, Tooltip } from "@mui/material";
import _ from "lodash";
import { forwardRef, ReactNode, useMemo, useRef } from "react";
import AppTooltip from "../../AppTooltip";
import AppIcon from "../../Icons/AppIcon";
import AppTypography, { AppTypographyProps } from "../../Text/AppTypography";

export interface FieldValueProps {
  variant?: "normal" | "compact";
  direction?: ResponsiveStyleValueCustom<"row" | "column">;
  labelAlight?: {
    horizontal?: ResponsiveStyleValueCustom<"start" | "center" | "end">;
    vertical?: ResponsiveStyleValueCustom<"start" | "center" | "end">;
  };
  valueAlight?: {
    horizontal?: ResponsiveStyleValueCustom<"start" | "center" | "end">;
    vertical?: ResponsiveStyleValueCustom<"start" | "center" | "end">;
  };
  /** Of container. */
  sx?: SxProps<Theme>;
  labelIcon?: ReactNode;
  label?: string | ReactNode;
  placeholder?: string | ReactNode;
  isEmpty?: boolean;
  withCopyButton?: boolean;
  helperTooltip?: string;
  /** Handles ellipsing for label only.
   *  Ellipsing for value must be handled by calling component.
   */
  ellipsing?: {
    enabled: boolean;
    label?: AppTypographyProps["ellipsing"];
  };
  onClick?: BoxProps["onClick"];
  children: ReactNode;
}

/** Displays field value. */
export default forwardRef<HTMLDivElement, FieldValueProps>(function FieldValue(
  {
    variant = "normal",
    direction = "row",
    labelAlight,
    valueAlight,
    sx,
    labelIcon,
    label,
    placeholder,
    isEmpty,
    withCopyButton,
    helperTooltip,
    ellipsing,
    onClick,
    children,
  }: FieldValueProps,
  ref,
) {
  const directionComputed = useResponsiveValueResolver(direction, "row");
  const { enqueueSnackbar } = useAppSnackbar();

  const valueRef = useRef<HTMLElement>(null);

  const isLabelIconOnly = !!labelIcon && !label;

  const isChildrenEmpty = useMemo(
    () => isEmpty || _.isNil(children) || (!_.isNumber(children) && _.isEmpty(children)),
    [children, isEmpty],
  );

  return (
    <Box
      ref={ref}
      sx={{
        ...sx,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          columnGap: isLabelIconOnly ? 1 : 2,
          rowGap: directionComputed === "row" ? 0 : 1,
          alignItems: "center",
          ...(variant === "normal" && {
            gridTemplateColumns: directionComputed === "row" ? "1fr 1.5fr 0.2fr" : "1fr",
          }),
          // NB: in compact variant label must not expand and take little space.
          ...(variant === "compact" && {
            gridTemplateColumns: directionComputed === "row" ? "min-content 1fr" : "1fr",
          }),
        }}
      >
        {/* Title */}
        {(labelIcon || label) && (
          <AppTypography
            component='div'
            variant={variant == "compact" ? "body1" : "subtitle1"}
            ellipsing={
              ellipsing && {
                enabled: ellipsing.enabled,
                ...ellipsing.label,
              }
            }
            sx={{
              minWidth: 0, // fix content overflow
              ...(labelAlight && {
                justifySelf: labelAlight.horizontal,
                alignSelf: labelAlight.vertical,
              }),
            }}
          >
            {labelIcon && <>{labelIcon} </>}
            {label}
            {helperTooltip && (
              <>
                {" "}
                <Tooltip title={helperTooltip}>
                  <AppIcon of='info' />
                </Tooltip>
              </>
            )}
          </AppTypography>
        )}

        {/* Value */}
        {/* NB: let parent element to handle content overflow. */}
        <AppTypography
          component='div'
          variant='body1'
          ellipsing={{ enabled: false }}
          sx={{
            // overflow: "unset", // don't crop content's shadows
            minWidth: 0, // fix content overflow
            ...(valueAlight && {
              justifySelf: valueAlight.horizontal,
              alignSelf: valueAlight.vertical,
            }),
          }}
        >
          <Box ref={valueRef} component='span'>
            {!isChildrenEmpty ? (
              <AppTypography ellipsing={{ enabled: true }}>{children}</AppTypography>
            ) : (
              <AppTypography component='span' variant='body2'>
                {placeholder || "-" || <>&nbsp;</>}
              </AppTypography>
            )}
          </Box>
        </AppTypography>
        {withCopyButton && !isChildrenEmpty && (
          <AppTooltip title='Copy' wrapperProps={{ sx: { display: "inline" } }}>
            <Box display='flex' justifyContent='center'>
              <IconButton
                size='small'
                sx={{ p: 0.5 }}
                onClick={async () => {
                  try {
                    const valueText = valueRef.current?.innerText;
                    await clipboardService.writeText(valueText);
                    enqueueSnackbar("Value copied to clipboard.", {
                      variant: "success",
                    });
                  } catch (error) {
                    console.error("Clipboard copy error", error);
                  }
                }}
              >
                <AppIcon of='copy' />
              </IconButton>
            </Box>
          </AppTooltip>
        )}
      </Box>

      {variant === "normal" && (
        <Divider
          sx={{
            mt: 1,
            ...(directionComputed === "column" && {}),
            ...(directionComputed === "row" && {}),
          }}
        />
      )}
    </Box>
  );
});

import { ListItemIcon, ListItemText } from "@mui/material";

import AppMenuItem from "@/common/components/Menu/AppMenuItem";
import { BaseSelectOption } from "@/common/ts/select";
import {
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  SubscriptionPlanExtraOptionDto,
} from "@/core/api/generated";
import AppIcon from "../../../Icons/AppIcon";
import BaseMultiselect, {
  BaseItemMultiselectInheritableProps,
} from "../../components/BaseMultiselect";
import SubscriptionPlanExtraOptionInline from "./SubscriptionPlanExtraOptionInline";

type MultiselectOption = BaseSelectOption<SubscriptionPlanExtraOptionDto>;

const itemToOption = (data: SubscriptionPlanExtraOptionDto): MultiselectOption => ({
  id: data.id!,
  title: "",
  data: data,
});

interface Props extends BaseItemMultiselectInheritableProps<SubscriptionPlanExtraOptionDto> {
  currency: GeneralCurrencyDto | GeneralCurrencyInputDto | null | undefined;
}

export default function SubscriptionPlanExtraOptionMultiselect({ currency, ...otherProps }: Props) {
  return (
    <BaseMultiselect
      {...otherProps}
      itemToOption={itemToOption}
      label={otherProps?.label || "Subscription plan extra options"}
      renderOption={(props, option) => (
        <AppMenuItem {...props}>
          <ListItemIcon>
            <AppIcon of='extraOption' fontSize='medium' />
          </ListItemIcon>
          <ListItemText
            primary={
              <SubscriptionPlanExtraOptionInline
                extraOption={option.data}
                currency={currency}
                withIcon={false}
                withTooltip={false}
              />
            }
          />
        </AppMenuItem>
      )}
    />
  );
}

import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";

import {
  DataUpdatesHubClientMethodName,
  dataUpdatesHubService,
} from "@/common/realtime/dataUpdatesHubService";
import {
  DataUpdatesSubscription,
  DataUpdatesSubscriptionHandler,
  DataUpdatesSubscriptionHandlerForEntityChangedClientMethod,
  DataUpdatesSubscriptionTypedHandler,
} from "@/common/realtime/dataUpdatesSubscription";
import { EntityChangedDtoOfTEntityDto, EntityChangeType } from "@/core/api/generated";
import { useEffectWithDeepCompare } from "../effect/useEffectWithDeepCompare";

/** Subscribes on data updates from specified channel (unsubscribes automatically on unmount). */
export const useRealtimeDataUpdates = ({
  enabled = true,
  channelNames,
  methodNames,
  handler,
  entityChangedHandler,
  entityCreatedHandler,
  entityUpdatedHandler,
  entityDeletedHandler,
  channelHandlers,
}: {
  /** Enabled by default; */
  enabled?: boolean;
  /** Data updates channels that defined in DataUpdatesChannelName.ts. */
  channelNames: Array<string | null | undefined> | string | null | undefined;
  /** Client methods to listen for which handler is called. */
  methodNames: DataUpdatesHubClientMethodName[];
  /** Handler that is called when data updates arrive from the server.
   *  Handler for all channels.
   */
  handler: DataUpdatesSubscriptionHandler | null | undefined;
  /** Handlers that are called when data updates arrive from the server.
   *  Handlers for specific channels.
   *  {{ChannelName}:{Handler}}
   */
  channelHandlers?: Record<string, DataUpdatesSubscriptionTypedHandler<any>>;

  /** Typed handler for @see {DataUpdatesHubClientMethodName.EntityChanged} */
  entityChangedHandler?: DataUpdatesSubscriptionHandlerForEntityChangedClientMethod;
  /** Typed handler for @see {DataUpdatesHubClientMethodName.EntityChanged} when @see {EntityChangedDtoOfTEntityDto.changeType} is @see {EntityChangeType.Created}  */
  entityCreatedHandler?: DataUpdatesSubscriptionHandlerForEntityChangedClientMethod;
  /** Typed handler for @see {DataUpdatesHubClientMethodName.EntityChanged} when @see {EntityChangedDtoOfTEntityDto.changeType} is @see {EntityChangeType.Updated}  */
  entityUpdatedHandler?: DataUpdatesSubscriptionHandlerForEntityChangedClientMethod;
  /** Typed handler for @see {DataUpdatesHubClientMethodName.EntityChanged} when @see {EntityChangedDtoOfTEntityDto.changeType} is @see {EntityChangeType.Deleted}  */
  entityDeletedHandler?: DataUpdatesSubscriptionHandlerForEntityChangedClientMethod;
}): DataUpdatesSubscription => {
  const [_channelNames, setChannelNames] = useState<string[]>([]);
  const dataUpdatesSubscriptionRef = useRef<DataUpdatesSubscription>(
    new DataUpdatesSubscription({ channelNames: [] }),
  );

  const handlerRef = useRef(handler);
  const channelHandlersRef = useRef(channelHandlers);
  const entityChangedHandlerRef = useRef(entityChangedHandler);
  const entityCreatedHandlerRef = useRef(entityCreatedHandler);
  const entityUpdatedHandlerRef = useRef(entityUpdatedHandler);
  const entityDeletedHandlerRef = useRef(entityDeletedHandler);

  // track input change
  useEffectWithDeepCompare(() => {
    let newValue = (Array.isArray(channelNames) ? channelNames : [channelNames])
      .filter((x) => !!x && x.length > 0)
      .map((x) => x!);
    newValue = _.orderBy(newValue, (x) => x);
    if (!_.isEqual(newValue, _channelNames)) {
      setChannelNames(newValue);
    }
  }, [channelNames]);

  // track channels change
  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (
      _channelNames.length === 0 ||
      _channelNames.every((x) => dataUpdatesSubscriptionRef.current.channelNames.includes(x))
    ) {
      return;
    }

    // unsubscribe on prev subscription
    if (dataUpdatesSubscriptionRef.current.channelNames.length !== 0) {
      dataUpdatesHubService.unsubscribeFromDataUpdates(dataUpdatesSubscriptionRef.current);
      dataUpdatesSubscriptionRef.current.reset();
    }

    // create new subscription for new channels
    const newSubscription = new DataUpdatesSubscription({ channelNames: _channelNames });
    dataUpdatesHubService.subscribeOnDataUpdates(
      {
        channelNames: newSubscription.channelNames,
      },
      newSubscription,
    );
    dataUpdatesSubscriptionRef.current = newSubscription;

    // unsubscribe on unmount
    return () => {
      dataUpdatesHubService.unsubscribeFromDataUpdates(dataUpdatesSubscriptionRef.current);
    };
  }, [enabled, _channelNames]);

  // listen for data updates
  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (!methodNames || methodNames.length === 0) {
      return;
    }

    dataUpdatesSubscriptionRef.current.on(methodNames, (methodName, data) => {
      // call main handler
      handlerRef.current && handlerRef.current(methodName, data);

      // call channel handler
      const channelHandler =
        channelHandlersRef.current && methodName && channelHandlersRef.current[methodName];
      channelHandler && channelHandler(methodName, data);

      // call handlers for EntityChanged
      const entityChangedDto = data as EntityChangedDtoOfTEntityDto;
      const isEntityChangedMessage =
        entityChangedDto &&
        !_.isNil(entityChangedDto.changeType) &&
        !_.isNil(entityChangedDto.entityType) &&
        !_.isNil(entityChangedDto.entityId);
      if (isEntityChangedMessage) {
        entityChangedHandlerRef.current &&
          entityChangedHandlerRef.current(methodName, entityChangedDto);

        if (entityChangedDto.changeType === EntityChangeType.Created) {
          entityCreatedHandlerRef.current &&
            entityCreatedHandlerRef.current(methodName, entityChangedDto);
        }
        if (entityChangedDto.changeType === EntityChangeType.Updated) {
          entityUpdatedHandlerRef.current &&
            entityUpdatedHandlerRef.current(methodName, entityChangedDto);
        }
        if (entityChangedDto.changeType === EntityChangeType.Deleted) {
          entityDeletedHandlerRef.current &&
            entityDeletedHandlerRef.current(methodName, entityChangedDto);
        }
      }
    });

    return () => {
      dataUpdatesSubscriptionRef.current.off(methodNames);
    };
  }, [
    enabled,
    dataUpdatesSubscriptionRef.current,
    methodNames,
    handlerRef.current,
    channelHandlersRef.current,
    entityChangedHandlerRef.current,
    entityCreatedHandlerRef.current,
    entityUpdatedHandlerRef.current,
    entityDeletedHandlerRef.current,
  ]);

  return dataUpdatesSubscriptionRef.current;
};

import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { getIn, useFormik } from "formik";
import * as Yup from "yup";

import { FileItem } from "@/common/fileItem";
import useMounted from "@/common/hooks/mount/useMounted";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  GeneralAttachmentDto,
  VehicleCreateManySimilarDto,
  VehicleCreateManySimilarSharedDataDto,
  VehicleDto,
  VehicleSpecInputDto,
  VehicleType,
} from "@/core/api/generated";

import { ArrayHelper } from "@/common/helpers/array";
import { FormikHelper } from "@/common/helpers/formik";
import { TextHelper } from "@/common/helpers/text";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import _ from "lodash";
import NoDataAlert from "../../AppAlerts/NoDataAlert";
import FoldableBlock from "../../Display/FoldableBlock";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import PlateNoInput from "../../Form/Input/PlateNoInput";
import AppIcon from "../../Icons/AppIcon";
import AccessoriesSelectOrCreate from "../Accessory/AccessoriesSelectOrCreate";
import DepartmentAutocomplete from "../Department/DepartmentAutocomplete";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import LocationAutocomplete from "../Location/LocationAutocomplete";
import { BaseEntityCreateManyInheritableProps } from "../components/BaseEntityCreateMany";
import DefaultVehicleVisualModelInput from "./Inputs/DefaultVehicleVisualModelInput";
import VehicleSpecInput from "./Inputs/VehicleSpecInput";

type DefaultValues = {
  spec?: Partial<VehicleSpecInputDto>;
};

export type VehicleCreateManySimilarFormikValues = VehicleCreateManySimilarDto & {
  shared?: VehicleCreateManySimilarSharedDataDto & {
    initialAttachments?: GeneralAttachmentDto[];
    uploadedAttachments?: FileItem[];
  };
  submit: string;
};

export interface VehicleCreateManyOwnProps
  extends BaseEntityCreateManyInheritableProps<VehicleDto, DefaultValues> {}

export type VehicleCreateManyProps = VehicleCreateManyOwnProps;

export default function VehicleCreateManySimilar({
  defaultValues,
  onCreate,
  onSave,
}: VehicleCreateManyProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();

  const formikContext = useFormik<
    VehicleCreateManySimilarFormikValues &
      BaseFormikValues & {
        isAttachmentFilesUploading: boolean;
        isDefaultVisualModelSelectedManually: boolean;
      }
  >({
    enableReinitialize: true,
    initialValues: {
      vehicles: Array.from({ length: 3 }).map((x) => ({})), // add 1st N by default
      shared: {
        spec: {
          type: VehicleType.Car,
          ...defaultValues?.spec,
          size: defaultValues?.spec?.size,
        },
        accessoryIds: undefined,
        attachments: undefined,
        notes: undefined,
        departmentId: undefined,
        locationId: undefined,
      },
      isAttachmentFilesUploading: false,
      isDefaultVisualModelSelectedManually: false,
      submit: "",
    },
    validationSchema: Yup.object().shape({
      // vehicleId: Yup.string().required("Vehicle is required"),
    }),
    onSubmit: async (values, { setFieldError, setStatus, setSubmitting }) => {
      const newValues: typeof values = {
        ...values,
        vehicles: values.vehicles?.map((x) => ({
          ...x,
          plateNo: x.plateNo?.plateNo ? x.plateNo : undefined,
        })),
      };

      try {
        const response = await apiClient.vehiclesApi.apiV1VehiclesCreateManySimilarPost({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          vehicleCreateManySimilarDto: newValues,
        });
        enqueueSnackbar(
          `${response.data.length} ${TextHelper.pluralizeManual(
            "vehicle",
            response.data.length,
            "vehicles",
          )} created.`,
          { variant: "success" },
        );
        onCreate && onCreate(response.data);
        onSave && onSave(response.data);

        if (mounted.current) {
          setStatus({ success: true });
          setSubmitting(false);
        }
      } catch (err: any) {
        if (mounted.current) {
          ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    setErrors,
    setFieldValue,
    setValues,
    setStatus,
    setSubmitting,
    setFieldError,
  } = formikContext;

  const specFormikProps = FormikHelper.getSubProps(
    formikContext,
    "shared.spec",
    (v) => v.shared?.spec,
  );

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Alert severity='info'>Similar vehicles are vehicles with the same spec.</Alert>

        {/* Vehicles */}
        <Stack spacing={2}>
          <Typography variant='h4'>Vehicles</Typography>

          <Stack spacing={1}>
            {_.isEmpty(values.vehicles) && <NoDataAlert />}

            {values.vehicles?.map((vehicle, i) => (
              <Card key={i}>
                <CardContent>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "0fr repeat(3, 1fr) 0fr",
                      gap: 1,
                    }}
                  >
                    <Stack direction='row' sx={{ alignItems: "center" }}>
                      <Typography component='div' variant='subtitle1'>
                        {i + 1}.
                      </Typography>
                    </Stack>

                    <Box>
                      <PlateNoInput
                        fullWidth
                        error={Boolean(getIn(errors, `vehicles[${i}].plateNo`))}
                        helperText={ValidationHelper.getFormikErrorsAsString(
                          getIn(errors, `vehicles[${i}].plateNo`),
                        )}
                        label='Plate No'
                        margin='dense'
                        name='plateNo'
                        onBlur={handleBlur}
                        type='text'
                        variant='outlined'
                        color='primary'
                        size='small'
                        placeholder='AB 1234'
                        value={vehicle.plateNo}
                        onChange={(newValue) => {
                          setFieldValue(`vehicles[${i}].plateNo`, newValue);
                        }}
                      />
                    </Box>

                    <Box>
                      <TextField
                        fullWidth
                        error={Boolean(getIn(errors, `vehicles[${i}].identificationNumber`))}
                        helperText={getIn(errors, `vehicles[${i}].identificationNumber`)}
                        label='Identification number'
                        margin='dense'
                        name={`vehicles[${i}].identificationNumber`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={vehicle.identificationNumber || ""}
                        variant='outlined'
                        size='small'
                      />
                    </Box>

                    <Box>
                      <TextField
                        fullWidth
                        error={Boolean(getIn(errors, `vehicles[${i}].mileage`))}
                        helperText={getIn(errors, `vehicles[${i}].mileage`)}
                        label='Mileage'
                        margin='dense'
                        name={`vehicles[${i}].mileage`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputMode='decimal'
                        value={vehicle.mileage || ""}
                        variant='outlined'
                        size='small'
                      />
                    </Box>

                    <Stack direction='row' sx={{ alignItems: "center" }}>
                      <IconButton
                        onClick={() => {
                          setFieldValue(
                            `vehicles`,
                            ArrayHelper.removeByIndex([...(values.vehicles || [])], i),
                          );
                        }}
                      >
                        <AppIcon of='delete' />
                      </IconButton>
                    </Stack>
                  </Box>

                  {_.isString(getIn(errors, `vehicles[${i}]`)) && (
                    <FormHelperText error>{getIn(errors, `vehicles[${i}]`)}</FormHelperText>
                  )}
                </CardContent>
                <CardContent sx={{ display: "none" }}></CardContent>
              </Card>
            ))}
          </Stack>

          {_.isString(errors.vehicles) && <FormHelperText error>{errors.vehicles}</FormHelperText>}

          <Box>
            <Button
              variant='outlined'
              color='primary'
              size='small'
              startIcon={<AppIcon of='add' />}
              onClick={() => {
                setFieldValue(`vehicles`, [...(values.vehicles || []), {}]);
              }}
            >
              Add vehicle
            </Button>
          </Box>
        </Stack>

        {/* Shared data */}
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant='h4'>{`Vehicles' info`}</Typography>
            <Typography variant='body2'>{`Shared info that will be saved for all vehicles.`}</Typography>
          </Stack>

          <Box>
            {/* Tags */}
            <FormControl margin='dense' fullWidth>
              <GeneralAttachedTagsInput
                value={values.shared?.tags}
                onChange={(newValue) => {
                  setFieldValue("shared.tags", newValue);
                }}
              />
              <FormHelperText error>
                {ValidationHelper.getFormikErrorsAsString(getIn(errors, "shared.tags"), {
                  isIncludeNested: false,
                })}
              </FormHelperText>
            </FormControl>
          </Box>

          {/* Department and Location */}
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <FormControl margin='dense' fullWidth>
              <DepartmentAutocomplete
                label='Department'
                entityId={values.shared?.departmentId}
                onChange={(newValue) => {
                  setFieldValue("shared.departmentId", newValue?.id);
                  if (!newValue?.id) {
                    setFieldValue("shared.locationId", undefined);
                  }
                }}
              />
            </FormControl>
            <FormControl margin='dense' fullWidth>
              <LocationAutocomplete
                label='Location'
                disabled={!values.shared?.departmentId}
                searchFilters={{ departmentId: values.shared?.departmentId }}
                entityId={values.shared?.locationId}
                onChange={(newValue) => {
                  setFieldValue("shared.locationId", newValue?.id);
                }}
              />
            </FormControl>
          </Stack>

          {/* Spec */}
          <FoldableBlock
            defaultIsFolded={false}
            trigger={{
              label: (
                <Typography component='span' variant='subtitle1'>
                  Spec *
                </Typography>
              ),
            }}
          >
            <VehicleSpecInput formikProps={specFormikProps} />

            {_.isString(getIn(errors, "shared.spec")) && (
              <FormHelperText error>{getIn(errors, "shared.spec")}</FormHelperText>
            )}
          </FoldableBlock>

          {/* Other fields */}
          <FoldableBlock
            defaultIsFolded
            trigger={{
              label: (
                <Typography component='span' variant='subtitle1'>
                  Other
                </Typography>
              ),
            }}
          >
            <FormControl margin='dense' fullWidth>
              <AccessoriesSelectOrCreate
                autocompleteProps={{
                  withCreate: true,
                  entityIds: values.shared?.accessoryIds,
                  entities: undefined,
                  textFieldProps: {
                    error: Boolean(getIn(errors, "shared.accessoryIds")),
                    helperText: ValidationHelper.getFormikErrorsAsString(
                      getIn(errors, "shared.accessoryIds"),
                    ),
                  },
                  onChange: (newValues) =>
                    setFieldValue("shared.accessoryIds", newValues?.map((x) => x.id!) || []),
                }}
                onCreate={(newValue) => {
                  setFieldValue("shared.accessoryIds", [
                    ...(values.shared?.accessoryIds || []),
                    newValue.id,
                  ]);
                }}
              />
            </FormControl>

            <FormControl fullWidth margin='dense'>
              <DefaultVehicleVisualModelInput
                defaultVisualModelId={values.shared?.defaultVisualModelId}
                isDefaultVisualModelSelectedManually={values.isDefaultVisualModelSelectedManually}
                spec={values.shared?.spec}
                onChange={(newValue) => {
                  setFieldValue("shared.defaultVisualModelId", newValue?.defaultVisualModel?.id);
                  setFieldValue(
                    "isDefaultVisualModelSelectedManually",
                    newValue?.isDefaultVisualModelSelectedManually,
                  );
                }}
              />
            </FormControl>

            <FormControl fullWidth margin='dense'>
              <FileUploader
                multiple
                defaultFiles={FileItem.createManyFrom(
                  values.shared?.uploadedAttachments ||
                    values.shared?.initialAttachments ||
                    values.shared?.attachments,
                )}
                onChange={(newFiles) => {
                  setFieldValue(
                    "shared.attachments",
                    FileItem.toManyGeneralAttachmentInputDto(newFiles),
                  );
                  setFieldValue("shared.uploadedAttachments", newFiles);
                }}
                onUploadStarted={() => {
                  setFieldValue("isAttachmentFilesUploading", true);
                }}
                onUploadFinished={() => {
                  setFieldValue("isAttachmentFilesUploading", false);
                }}
              />

              {Boolean(getIn(errors, "errors.shared.attachments")) && (
                <FormHelperText error>
                  {ValidationHelper.getFormikErrorsAsString(
                    getIn(errors, "errors.shared.attachments"),
                  )}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl margin='dense' fullWidth error={Boolean(getIn(errors, "shared.notes"))}>
              <TextField
                error={Boolean(getIn(errors, "shared.notes"))}
                fullWidth
                multiline
                rows={2}
                helperText={ValidationHelper.getFormikErrorsAsString(getIn(errors, "shared.notes"))}
                label='Notes'
                name='shared.notes'
                onBlur={handleBlur}
                onChange={handleChange}
                type='text'
                value={values.shared?.notes}
                variant='outlined'
              />
            </FormControl>
          </FoldableBlock>

          {_.isString(errors.shared) && <FormHelperText error>{errors.shared}</FormHelperText>}
        </Stack>

        <GeneralValidationError sx={{ my: 1 }} errors={errors} />

        <Box>
          <LoadingButton
            sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
            color='primary'
            disabled={values.isAttachmentFilesUploading || _.isEmpty(values.vehicles)}
            loading={isSubmitting}
            fullWidth
            type='submit'
            variant='contained'
          >
            {`Save ${values.vehicles?.length || 0} ${TextHelper.pluralizeManual(
              "vehicle",
              values.vehicles?.length || 0,
              "vehicles",
            )}`}
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  );
}

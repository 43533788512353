import { apiClient } from "@/core/api/ApiClient";
import { RepairOperationDto, EntityType } from "@/core/api/generated";
import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";
import { Alert } from "@mui/material";

export interface OwnProps {
  entity: RepairOperationDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function RepairOperationDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.RepairOperation}
      entity={entity}
      entityId={entity.id}
      deleteFunc={(params) =>
        apiClient.repairOperationsApi.apiV1RepairOperationsRepairOperationIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          repairOperationId: params.entityId,
          isDeleteCascade: params.isDeleteCascade,
        })
      }
      isConfirmEnabled={entity?.canBeDeleted}
      bodyBefore={
        !entity?.canBeDeleted && (
          <Alert severity='warning'>{`Can't be deleted at this stage.`}</Alert>
        )
      }
      {...otherProps}
    />
  );
}

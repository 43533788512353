import { Box, Button, Stack, Typography } from "@mui/material";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { renderIf } from "@/common/helpers/render/renderIf";
import { InviteDto, InviteUserType } from "@/core/api/generated";

interface Props {
  invite: InviteDto;
  hasRedirectUrl: boolean;
  redirectToOnClick?: string;
}

export default function InviteInfo({ invite, hasRedirectUrl, redirectToOnClick }: Props) {
  return (
    <Stack direction='column' spacing={1} sx={{ alignItems: "center", px: 4, py: 2 }}>
      <AppIcon of='info' fontSize='large' color='secondary' />

      <Box sx={{ textAlign: "center" }}>
        <Typography component='div' variant='h6'>
          {renderIf()
            .if(invite.userType === InviteUserType.Customer)
            .then(
              <>
                You have been invited to join <strong>{invite.tenant?.name}</strong>.
              </>,
            )
            .else(
              <>
                You have been invited to join the <strong>{invite.tenant?.name}</strong> tenant.
              </>,
            )
            .render()}
        </Typography>
        <Typography component='div' variant='body1'>
          {`Register and accept the invitation`}
        </Typography>
        {hasRedirectUrl && (
          <Typography component='div' variant='body1'>
            You will be redirected to the destination page after registration.
          </Typography>
        )}
      </Box>

      {redirectToOnClick && (
        <Box sx={{ width: "100%" }}>
          <Button
            sx={{ mt: 2 }}
            variant='contained'
            color='primary'
            fullWidth
            component={AppLink}
            to={redirectToOnClick}
          >{`Let's go`}</Button>
        </Box>
      )}
    </Stack>
  );
}

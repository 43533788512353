import { AssetDto } from "@/core/api/generated";
import BaseEntityAutocompleteOrCreate, {
  BaseEntityAutocompleteOrCreateInheritableProps,
} from "../components/BaseEntityAutocompleteOrCreate";
import AssetAutocomplete, { AssetAutocompleteProps } from "./AssetAutocomplete";
import AssetCreateUpdate, { AssetCreateUpdateProps } from "./AssetCreateUpdate";
import AssetCreateUpdateModal from "./AssetCreateUpdateModal";

type Props = BaseEntityAutocompleteOrCreateInheritableProps<
  AssetDto,
  AssetCreateUpdateProps["defaultValues"],
  AssetAutocompleteProps,
  AssetCreateUpdateProps
>;

export default function AssetAutocompleteOrCreate({
  autocompleteProps,
  createUpdateProps,
  createFormPlacement = "modal",
  onCreateStart,
  onCreate,
}: Props) {
  return (
    <BaseEntityAutocompleteOrCreate<
      AssetDto,
      AssetCreateUpdateProps["defaultValues"],
      AssetAutocompleteProps,
      AssetCreateUpdateProps
    >
      createFormPlacement={createFormPlacement}
      onCreateStart={onCreateStart}
      onCreate={onCreate}
      renderAutocomplete={(params) => (
        <AssetAutocomplete
          {...autocompleteProps}
          withCreate
          createOptionTitle={(inputValue) => `Create new asset "${inputValue}"`}
          onCreate={(newOption) => {
            params.setDefaultValues({
              ...createUpdateProps?.defaultValues,
              name: newOption?.inputValue || createUpdateProps?.defaultValues?.name || undefined,
            });
            params.setIsCreate(true);
            params.onCreateStart && params.onCreateStart();
          }}
        />
      )}
      renderCreateUpdate={(params) => (
        <AssetCreateUpdate
          defaultValues={params.defaultValues}
          onCreate={params.onCreate}
          onSave={(newValue) => {
            params.setIsCreate(false);
          }}
        />
      )}
      renderCreateUpdateModal={(params) => (
        <AssetCreateUpdateModal
          open={params.isCreate}
          onClose={() => params.setIsCreate(false)}
          createUpdateProps={{
            defaultValues: params.defaultValues,
            onCreate: params.onCreate,
            onSave: (newValue) => {
              params.setIsCreate(false);
            },
          }}
        />
      )}
    />
  );
}

import {
  Box,
  Button,
  IconButton,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useState } from "react";

import AppButton from "@/common/components/Button/AppButton";
import AppIconButton, { AppIconButtonProps } from "@/common/components/Button/AppIconButton";
import DropdownButton from "@/common/components/Button/DropdownButton";
import DropdownIconButton from "@/common/components/Button/DropdownIconButton";
import SplitDropdownButton from "@/common/components/Button/SplitDropdownButton";
import InlineCode from "@/common/components/Code/InlineCode";
import AppIcon from "@/common/components/Icons/AppIcon";
import { MiscHelper } from "@/common/helpers/misc";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { LoadingButtonProps } from "@mui/lab";

export default function DevButtons() {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isConfirmation1ModalOpen, setIsConfirmation1ModalOpen] = useState(false);

  const successfulActionWithDelay = async () => {
    await MiscHelper.wait(2000);
    enqueueSnackbar("Action completed.", { variant: "success" });
    setIsConfirmation1ModalOpen(false);
  };

  return (
    <Stack spacing={4}>
      <Typography component='div' variant='h1'>
        Buttons
      </Typography>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          <InlineCode>MUI Button</InlineCode>
        </Typography>
        <Stack direction='column' spacing={1}>
          <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
            <Button variant='contained' size='extraSmall'>
              extraSmall
            </Button>
            <Button variant='contained' size='small'>
              small
            </Button>
            <Button variant='contained' size='medium'>
              medium
            </Button>
            <Button variant='contained' size='large'>
              large
            </Button>
          </Stack>

          <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
            <Button variant='outlined' size='extraSmall'>
              extraSmall
            </Button>
            <Button variant='outlined' size='small'>
              small
            </Button>
            <Button variant='outlined' size='medium'>
              medium
            </Button>
            <Button variant='outlined' size='large'>
              large
            </Button>
          </Stack>

          <Stack direction='row' spacing={1}>
            <Button variant='contained'>contained</Button>
            <Button variant='outlined'>outlined</Button>
            <Button variant='text'>text</Button>
          </Stack>

          <Stack direction='row' spacing={1}>
            <Button variant='contained' disabled>
              contained
            </Button>
            <Button variant='outlined' disabled>
              outlined
            </Button>
            <Button variant='text' disabled>
              text
            </Button>
          </Stack>

          <Stack direction='row' spacing={1}>
            <Button variant='contained' color='primary'>
              contained primary
            </Button>
            <Button variant='contained' color='secondary'>
              contained secondary
            </Button>
            <Button variant='contained' color='info'>
              contained info
            </Button>
            <Button variant='contained' color='error'>
              contained error
            </Button>
            <Button variant='contained' color='warning'>
              contained warning
            </Button>
            <Button variant='contained' color='success'>
              contained success
            </Button>
          </Stack>
          <Stack direction='row' spacing={1}>
            <Button variant='outlined' color='primary'>
              outlined primary
            </Button>
            <Button variant='outlined' color='secondary'>
              outlined secondary
            </Button>
            <Button variant='outlined' color='info'>
              outlined info
            </Button>
            <Button variant='outlined' color='error'>
              outlined error
            </Button>
            <Button variant='outlined' color='warning'>
              outlined warning
            </Button>
            <Button variant='outlined' color='success'>
              outlined success
            </Button>
          </Stack>
          <Stack direction='row' spacing={1}>
            <Button variant='text' color='primary'>
              text primary
            </Button>
            <Button variant='text' color='secondary'>
              text secondary
            </Button>
            <Button variant='text' color='info'>
              text info
            </Button>
            <Button variant='text' color='error'>
              text error
            </Button>
            <Button variant='text' color='warning'>
              text warning
            </Button>
            <Button variant='text' color='success'>
              text success
            </Button>
          </Stack>

          <Stack direction='row' spacing={1}>
            <Button variant='text' size='extraSmall'>
              text extraSmall
            </Button>
            <Button variant='text' size='small'>
              text small
            </Button>
            <Button variant='text' size='medium'>
              text medium
            </Button>
            <Button variant='text' size='large'>
              text large
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          <InlineCode>MUI IconButton</InlineCode>
        </Typography>
        <Stack direction='column' spacing={1}>
          <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
            <IconButton size='extraSmall'>
              <AppIcon of='user' />
            </IconButton>
            <IconButton size='small'>
              <AppIcon of='user' />
            </IconButton>
            <IconButton size='medium'>
              <AppIcon of='user' />
            </IconButton>
            <IconButton size='large'>
              <AppIcon of='user' />
            </IconButton>
          </Stack>

          <Stack direction='row' spacing={1}>
            <IconButton color='primary'>
              <AppIcon of='user' />
            </IconButton>
            <IconButton color='secondary'>
              <AppIcon of='user' />
            </IconButton>
            <IconButton color='info'>
              <AppIcon of='user' />
            </IconButton>
            <IconButton color='error'>
              <AppIcon of='user' />
            </IconButton>
            <IconButton color='warning'>
              <AppIcon of='user' />
            </IconButton>
            <IconButton color='success'>
              <AppIcon of='user' />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          <InlineCode>AppButton</InlineCode>
        </Typography>

        <Stack spacing={2}>
          <Typography component='div' variant='subtitle1'>
            loading
          </Typography>

          <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
            <AppButton size='extraSmall' loading>
              extraSmall
            </AppButton>
            <AppButton size='small' loading>
              small
            </AppButton>
            <AppButton size='medium' loading>
              medium
            </AppButton>
            <AppButton size='large' loading>
              large
            </AppButton>
          </Stack>

          <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
            <AppButton variant='contained' loading>
              extraSmall
            </AppButton>
            <AppButton variant='contained' loading>
              small
            </AppButton>
            <AppButton variant='contained' loading>
              medium
            </AppButton>
            <AppButton variant='contained' loading>
              large
            </AppButton>
          </Stack>

          <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
            <AppButton variant='outlined' loading>
              extraSmall
            </AppButton>
            <AppButton variant='outlined' loading>
              small
            </AppButton>
            <AppButton variant='outlined' loading>
              medium
            </AppButton>
            <AppButton variant='outlined' loading>
              large
            </AppButton>
          </Stack>

          <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
            <AppButton variant='text' loading>
              extraSmall
            </AppButton>
            <AppButton variant='text' loading>
              small
            </AppButton>
            <AppButton variant='text' loading>
              medium
            </AppButton>
            <AppButton variant='text' loading>
              large
            </AppButton>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          <InlineCode>AppIconButton</InlineCode>
        </Typography>

        <Stack spacing={2}>
          <Typography component='div' variant='h6'>
            sizes, loading
          </Typography>
          <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
            <AppIconButton size='extraSmall' loading>
              <AppIcon of='user' />
            </AppIconButton>
            <AppIconButton size='small' loading>
              <AppIcon of='user' />
            </AppIconButton>
            <AppIconButton size='medium' loading>
              <AppIcon of='user' />
            </AppIconButton>
            <AppIconButton size='large' loading>
              <AppIcon of='user' />
            </AppIconButton>
          </Stack>
        </Stack>

        <Stack direction='column' spacing={1}>
          <Typography component='div' variant='h6'>
            variants
          </Typography>

          <Stack direction='row' spacing={6}>
            {(
              [undefined, "default", "text", "contained", "outlined"] as Array<
                AppIconButtonProps["variant"]
              >
            ).map((variant, i) => {
              return (
                <Stack key={i} spacing={1}>
                  <Typography component='div' variant='subtitle1'>
                    variant={variant || "undefined"}
                  </Typography>
                  <Stack
                    spacing={1}
                    sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
                  >
                    {(
                      ["primary", "secondary", "info", "error", "warning", "success"] as Array<
                        AppIconButtonProps["color"]
                      >
                    ).map((color, j) => (
                      <Stack key={j} direction='row' spacing={1}>
                        <AppIconButton variant={variant} color={color}>
                          <AppIcon of='user' />
                        </AppIconButton>

                        <AppIconButton variant={variant} color={color} disabled>
                          <AppIcon of='user' />
                        </AppIconButton>

                        <AppIconButton variant={variant} color={color} loading>
                          <AppIcon of='user' />
                        </AppIconButton>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Typography component='div' variant='h6'>
            shapes
          </Typography>

          <Stack direction='row' spacing={6}>
            {([undefined, "round", "square"] as Array<AppIconButtonProps["shape"]>).map(
              (shape, i) => {
                return (
                  <Stack key={i} spacing={1}>
                    <Typography component='div' variant='subtitle1'>
                      shape={shape || "undefined"}
                    </Typography>
                    <Stack
                      spacing={1}
                      sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
                    >
                      {(
                        ["default", "text", "contained", "outlined"] as Array<
                          AppIconButtonProps["variant"]
                        >
                      ).map((variant, j) => (
                        <Stack key={j} direction='row' spacing={1}>
                          <AppIconButton shape={shape} variant={variant} color='primary'>
                            <AppIcon of='user' />
                          </AppIconButton>

                          <AppIconButton shape={shape} variant={variant} color='primary' disabled>
                            <AppIcon of='user' />
                          </AppIconButton>

                          <AppIconButton shape={shape} variant={variant} color='primary' loading>
                            <AppIcon of='user' />
                          </AppIconButton>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                );
              },
            )}
          </Stack>
        </Stack>

        <Stack spacing={2}>
          {(["h1", "subtitle1", "body1", "caption"] as Array<TypographyProps["variant"]>).map(
            (variant, i) => (
              <Typography key={i} variant={variant}>
                This the icon button in text{" "}
                <AppIconButton variant='text'>
                  <AppIcon of='user' />
                </AppIconButton>
                . Lorem ipsum{" "}
                <AppIconButton variant='text'>
                  <AppIcon of='vehicle' />
                </AppIconButton>{" "}
                dolor sit amet consectetur adipisicing elit.
              </Typography>
            ),
          )}
        </Stack>
      </Stack>

      <Typography component='div' variant='h2'>
        <InlineCode>DropdownButton</InlineCode>
      </Typography>
      <Box>
        <DropdownButton
          dropdownContent={
            <MenuList>
              <MenuItem>Status 1</MenuItem>
              <MenuItem>Status 2</MenuItem>
              <MenuItem>Status 3</MenuItem>
            </MenuList>
          }
        >
          Default
        </DropdownButton>

        <Typography component='div' variant='subtitle1'>
          button
        </Typography>
        <DropdownButton
          component='button'
          dropdownContent={
            <MenuList>
              <MenuItem>Status 1</MenuItem>
              <MenuItem>Status 2</MenuItem>
              <MenuItem>Status 3</MenuItem>
            </MenuList>
          }
        >
          Label
        </DropdownButton>

        <Typography component='div' variant='subtitle1'>
          iconButton
        </Typography>
        <DropdownButton
          component='iconButton'
          dropdownContent={
            <MenuList>
              <MenuItem>Status 1</MenuItem>
              <MenuItem>Status 2</MenuItem>
              <MenuItem>Status 3</MenuItem>
            </MenuList>
          }
        />

        <Typography component='div' variant='subtitle1'>
          expandIconVariant=outlined
        </Typography>
        <Box>
          <DropdownButton
            component='button'
            expandIconVariant='outlined'
            dropdownContent={
              <MenuList>
                <MenuItem>Status 1</MenuItem>
                <MenuItem>Status 2</MenuItem>
                <MenuItem>Status 3</MenuItem>
              </MenuList>
            }
          >
            Status
          </DropdownButton>

          <DropdownButton
            component='iconButton'
            expandIconVariant='outlined'
            dropdownContent={
              <MenuList>
                <MenuItem>Status 1</MenuItem>
                <MenuItem>Status 2</MenuItem>
                <MenuItem>Status 3</MenuItem>
              </MenuList>
            }
          />
        </Box>

        <Typography component='div' variant='subtitle1'>
          behaviors
        </Typography>
        <DropdownButton
          behavior='button'
          dropdownContent={
            <MenuList>
              <MenuItem>Status 1</MenuItem>
              <MenuItem>Status 2</MenuItem>
              <MenuItem>Status 3</MenuItem>
            </MenuList>
          }
        >
          behavior=button
        </DropdownButton>

        <DropdownButton
          behavior='dropdown'
          dropdownContent={
            <MenuList>
              <MenuItem>Status 1</MenuItem>
              <MenuItem>Status 2</MenuItem>
              <MenuItem>Status 3</MenuItem>
            </MenuList>
          }
        >
          behavior=dropdown
        </DropdownButton>

        <Typography component='div' variant='subtitle1'>
          loading
        </Typography>
        <DropdownButton
          component='button'
          isLoading
          dropdownContent={
            <MenuList>
              <MenuItem>Status 1</MenuItem>
              <MenuItem>Status 2</MenuItem>
              <MenuItem>Status 3</MenuItem>
            </MenuList>
          }
        >
          Loading
        </DropdownButton>

        <DropdownButton
          component='iconButton'
          isLoading
          dropdownContent={
            <MenuList>
              <MenuItem>Status 1</MenuItem>
              <MenuItem>Status 2</MenuItem>
              <MenuItem>Status 3</MenuItem>
            </MenuList>
          }
        >
          Loading
        </DropdownButton>

        <Typography component='div' variant='subtitle1'>
          Variants
        </Typography>
        <Stack direction='row' spacing={1}>
          {(["text", "outlined", "contained"] as Array<LoadingButtonProps["variant"]>).map(
            (variant, i) => (
              <DropdownButton
                key={i}
                buttonProps={{ variant: variant }}
                dropdownContent={
                  <MenuList>
                    <MenuItem>Status 1</MenuItem>
                    <MenuItem>Status 2</MenuItem>
                    <MenuItem>Status 3</MenuItem>
                  </MenuList>
                }
              >
                Status
              </DropdownButton>
            ),
          )}
        </Stack>
      </Box>

      <Typography component='div' variant='h2'>
        <InlineCode>DropdownIconButton</InlineCode>
      </Typography>
      <Stack direction='row' spacing={1} alignItems='center'>
        <DropdownIconButton
          dropdownContent={
            <MenuList>
              <MenuItem>Status 1</MenuItem>
              <MenuItem>Status 2</MenuItem>
              <MenuItem>Status 3</MenuItem>
            </MenuList>
          }
        >
          <AppIcon of='money' />
        </DropdownIconButton>

        <DropdownIconButton
          dropdownContent={
            <MenuList>
              <MenuItem>Status 1</MenuItem>
              <MenuItem>Status 2</MenuItem>
              <MenuItem>Status 3</MenuItem>
            </MenuList>
          }
        >
          <AppIcon of='user' />
        </DropdownIconButton>

        <DropdownIconButton
          disabled
          dropdownContent={
            <MenuList>
              <MenuItem>Status 1</MenuItem>
              <MenuItem>Status 2</MenuItem>
              <MenuItem>Status 3</MenuItem>
            </MenuList>
          }
        >
          <AppIcon of='user' />
        </DropdownIconButton>

        <DropdownIconButton
          loading
          dropdownContent={
            <MenuList>
              <MenuItem>Status 1</MenuItem>
              <MenuItem>Status 2</MenuItem>
              <MenuItem>Status 3</MenuItem>
            </MenuList>
          }
        >
          <AppIcon of='user' />
        </DropdownIconButton>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          <InlineCode>SplitDropdownButton</InlineCode>
        </Typography>

        <Box>
          <SplitDropdownButton
            options={[
              { content: "Option 1", buttonProps: { color: "primary" } },
              { content: "Option 2", buttonProps: { color: "success" } },
              { content: "Option 3", buttonProps: { color: "error" } },
            ]}
          />
        </Box>
      </Stack>
    </Stack>
  );
}

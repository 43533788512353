import { AxiosError } from "axios";
import { AnyAction, Middleware } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { authService } from "@/common/services/auth";
import { apiClient } from "@/core/api/ApiClient";
import { history } from "@/store";

import { ROUTE_PATH } from "@/common/constants/routing";

export const errorHandlingMiddleware: Middleware<any, any, ThunkDispatch<any, any, AnyAction>> =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    try {
      return await next(action);
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const response = error.response;
        const status = response?.status || -1;

        const apiError = apiClient.getApiError(error);
        if (status === 401) {
          const isAuthenticated = await authService.isAuthenticated();
          if (isAuthenticated === false) {
            history.push(ROUTE_PATH.AUTH_SIGN_IN());
            return;
          }
        }

        throw apiError;
      } else {
        console.error(error);
      }
    }
  };

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import EntityCommentsChat from "@/App/MainAppView/Chats/components/EntityCommentsChat";
import { DamageCostEvaluationDto, EntityType } from "@/core/api/generated";
import { Stack } from "@mui/material";

interface Props {
  damageCostEvaluation: DamageCostEvaluationDto;
}

export default function CommentsTabContent({ damageCostEvaluation }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <PageTabHeader title={"Comments"} />
        <EntityCommentsChat
          entityId={damageCostEvaluation.id!}
          entityType={EntityType.DamageCostEvaluation}
        />
      </Stack>
    </PageTabContent>
  );
}

import { FormHelperText, Stack, SxProps, Theme } from "@mui/material";

import { AssetSpecDto, AssetEntityType } from "@/core/api/generated";

import { renderIf } from "@/common/helpers/render/renderIf";
import VehicleSpecDisplay from "../Vehicle/VehicleSpecDisplay";
import InfoTile from "../../Form/Display/InfoTile";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";

interface Props {
  spec: AssetSpecDto | null | undefined;
  sx?: SxProps<Theme>;
}

/** Input for duration that works on Moment.js Duration. */
export default function AssetSpecDisplay({ spec, sx }: Props) {
  if (!spec) {
    return null;
  }

  return (
    <Stack spacing={1} sx={sx}>
      <InfoTile label='Type' iconOf='data'>
        <InlineApiEnumValue type='AssetEntityType' value={spec?.entityType} />
      </InfoTile>

      {renderIf()
        .if(spec.entityType === AssetEntityType.Vehicle)
        .then(<VehicleSpecDisplay spec={spec.vehicle} />)
        .elseif(spec.entityType === AssetEntityType.Accessory)
        .then(<></>)
        .else(<FormHelperText error>Unknown asset type {spec.entityType}</FormHelperText>)
        .render()}
    </Stack>
  );
}

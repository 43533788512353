import { Button, Stack, TypographyProps } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  SpotDto,
} from "@/core/api/generated";
import _ from "lodash";
import GeneralAddressDisplay from "../../General/Display/GeneralAddressDisplay";
import LocationLink from "../../Location/LocationLink";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import SpotDeleteModal from "../SpotDeleteModal";
import SpotMenu from "../SpotMenu";

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<SpotDto> {
  spotId: string | null | undefined;
  spot: SpotDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function SpotView({
  spotId,
  spot,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const spotRequest = useApiRequest(
    apiClient.spotsApi.apiV1SpotsSpotIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      spotId: spotId!,
    },
    {
      deps: [spotId],
      skip: !spotId || !!spot,
    },
  );
  spot = spot || spotRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Spot, spot?.id || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      spotRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        spot && {
          idValue: spot.id!,
          newTitle: spot.localNumber || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.Spot}
      entityId={spotId}
      entity={spot}
      entityRequest={spotRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`Spot ${spot?.name} (${spot?.localNumber})`}
              titleProps={{
                to: headerProps?.withLink ? ROUTE_PATH.ACCESSORY_VIEW(spot?.id) : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.SpotManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.SPOT_EDIT(spot?.id)}
                      variant='outlined'
                      color='secondary'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                displayProps.actions &&
                spot && (
                  <SpotMenu
                    onDelete={() => history.goBack()}
                    onUpdate={(newValue) =>
                      newValue ? spotRequest.replaceData(newValue) : spotRequest.refetch()
                    }
                    entity={spot}
                    displayProps={{ actions: { edit: false, delete: true } }}
                  />
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          {spot && (
            <Stack spacing={2}>
              <Stack spacing={1}>
                <FieldValue label='Location' isEmpty={!spot?.location}>
                  <LocationLink entity={spot.location} />
                </FieldValue>

                <FieldValue label='Types' isEmpty={_.isEmpty(spot?.types)}>
                  <InlineApiEnumValueList type='SpotType' values={spot.types} />
                </FieldValue>

                <FieldValue label='Name' isEmpty={!spot?.name}>
                  {spot?.name}
                </FieldValue>

                <FieldValue label='Description' isEmpty={!spot?.description}>
                  {spot?.description}
                </FieldValue>

                <FieldValue label='Address' isEmpty={!spot?.address}>
                  <GeneralAddressDisplay address={spot?.address} />
                </FieldValue>
              </Stack>
            </Stack>
          )}

          {spot && (
            <SpotDeleteModal
              entity={spot}
              open={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onDelete={() => history.goBack()}
            />
          )}
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}

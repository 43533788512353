import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { PartyDto } from "@/core/api/generated";

import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";

interface Props {
  party: PartyDto;
}

export default function PoolTabContent({ party }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <NoDataAlert />
      </Stack>
    </PageTabContent>
  );
}

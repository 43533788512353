import {
  Box,
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuList,
  Select,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import _ from "lodash";

import { FilterDefinition } from "@/common/filters/filterDefinition";
import { FilterDefinitionItem } from "@/common/filters/filterDefinitionItem";
import { FilterSpec } from "@/common/filters/filterSpec";
import { TextHelper } from "@/common/helpers/text";
import { TypeHelper } from "@/common/helpers/type";
import { FilterValueInputProps } from "@/common/ts/filters";
import { FilterOperator } from "@/core/api/generated";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import NoDataAlert from "../AppAlerts/NoDataAlert";
import AppIconButton from "../Button/AppIconButton";
import DropdownButton from "../Button/DropdownButton";
import AppIcon from "../Icons/AppIcon";
import FilterOperatorDisplayName from "./FilterOperatorDisplayName";
import FilterValueInputPlaceholder from "./ValueInputs/FilterValueInputPlaceholder";

const maxFilters = 5;

export interface FilterDefinitionInputProps {
  filterSpec: FilterSpec;
  /** If undefined is passed the input is uncontrolled. */
  filterDefinition: FilterDefinition | undefined;
  sx?: SxProps<Theme>;
  onChange: (newValue: FilterDefinition | undefined) => void;
}

export type FilterDefinitionInputHandle = {
  addFieldFilter: (params: { field: string }) => void;
};

export default forwardRef<FilterDefinitionInputHandle, FilterDefinitionInputProps>(
  function FilterDefinitionInput(
    { filterSpec, filterDefinition, sx, onChange }: FilterDefinitionInputProps,
    ref,
  ) {
    const [_filterDefinition, _setFilterDefinition] = useState<FilterDefinition | undefined>(
      filterDefinition,
    );

    const filterDefinitionComputed = useMemo(
      () => filterDefinition || _filterDefinition,
      [filterDefinition, _filterDefinition],
    );

    const isMaxFiltersLimitReached = useMemo(
      () => (filterDefinitionComputed?.items?.length ?? 0) >= maxFilters,
      [maxFilters, filterDefinitionComputed],
    );

    const handleFilterDefinitionChange = (newValue: FilterDefinition | undefined) => {
      _setFilterDefinition(newValue);
      onChange(newValue);
    };

    const addFilter = (params?: { field?: string }) => {
      // auto-select suitable field and operator
      const fieldSpec = params?.field
        ? filterSpec.getFieldOrThrow(params.field)
        : filterSpec.autoSelectFieldForNewFilter(filterDefinitionComputed);
      const operatorSpec = filterSpec.autoSelectOperatorForNewFilter(
        filterDefinitionComputed,
        fieldSpec,
      );

      if (fieldSpec && operatorSpec) {
        const newItem = new FilterDefinitionItem({
          // filterSpec: filterSpec,
          // fieldSpec: fieldSpec,
          // operatorSpec: operatorSpec,
          type: operatorSpec.filterType,
          field: fieldSpec.field,
          operator: operatorSpec.operator,
          valueType: operatorSpec.valueType,
          value: undefined,
        });

        const newValue = filterDefinitionComputed
          ? new FilterDefinition(filterDefinitionComputed)
          : FilterDefinition.newEmpty();
        newValue.addItem(newItem);
        handleFilterDefinitionChange(newValue);

        // trigger change for the item
        operatorSpec.onChange && operatorSpec.onChange(newItem);
      }
    };

    const updateFilter = (newItem: FilterDefinitionItem) => {
      const fieldSpec = filterSpec.getFieldOrThrow(newItem.field);
      const operatorSpec = fieldSpec.getOperatorOrThrow(newItem.operator);

      const newValue = filterDefinitionComputed
        ? new FilterDefinition(filterDefinitionComputed)
        : FilterDefinition.newEmpty();
      newValue.replaceItem(newItem);
      handleFilterDefinitionChange(newValue);

      // trigger change for the item
      operatorSpec.onChange && operatorSpec.onChange(newItem);
    };

    const removeFilter = (oldItem: FilterDefinitionItem) => {
      const fieldSpec = filterSpec.getFieldOrThrow(oldItem.field);
      const operatorSpec = fieldSpec.getOperatorOrThrow(oldItem.operator);

      const newValue = filterDefinitionComputed
        ? new FilterDefinition(filterDefinitionComputed)
        : FilterDefinition.newEmpty();
      newValue.removeItem(oldItem);
      handleFilterDefinitionChange(newValue);

      // trigger change for the item
      oldItem.value = undefined;
      operatorSpec.onChange && operatorSpec.onChange(oldItem);
    };

    const removeAllFilters = () => {
      handleFilterDefinitionChange(undefined);
    };

    useImperativeHandle(
      ref,
      () => ({
        addFieldFilter(params: { field: string }) {
          addFilter({ field: params.field });
        },
      }),
      [addFilter],
    );

    return (
      <Box sx={sx}>
        {/* Filters */}
        <Stack direction='column' spacing={1}>
          {_.isEmpty(filterDefinitionComputed?.items) && (
            <NoDataAlert variant='inline' title='No filters applied.' />
          )}

          {filterDefinitionComputed?.items?.map((item, i) => {
            const operatorSpec =
              item.field && item.operator
                ? filterSpec.getFieldOperator(item.field, item.operator)
                : undefined;

            const valueInputProps: FilterValueInputProps = {
              fieldSpec: filterSpec.getFieldOrThrow(item.field),
              operatorSpec: operatorSpec,
              item: item,
              formControlProps: {
                variant: "outlined",
                size: "small",
              },
              textFieldProps: {
                variant: "outlined",
                size: "small",
                label: "Value",
              },
              selectProps: {
                size: "small",
              },
              autocompleteProps: {
                size: "small",
              },
              baseEntitySearchAutocompleteProps: {
                size: "small",
                label: "Value",
                textFieldProps: {
                  variant: "outlined",
                  size: "small",
                },
              },
              onChange: (newValue) => {
                if (newValue !== item.value) {
                  const newItem = new FilterDefinitionItem({ ...item });
                  newItem.value = newValue;
                  if (operatorSpec) {
                    newItem.value = operatorSpec.formatValue(newItem.value);
                  }
                  updateFilter(newItem);
                }
              },
            };

            return (
              <Stack key={i} direction='row' spacing={1}>
                {/* Field */}
                <FormControl sx={{ width: 150, maxWidth: 300 }} variant='outlined' size='small'>
                  <InputLabel>Field</InputLabel>
                  <Select
                    value={item.field || ""}
                    label='Field'
                    onChange={(e) => {
                      const newItem = new FilterDefinitionItem({ ...item });
                      newItem.field = e.target.value;
                      if (newItem.field !== item.field) {
                        const fieldSpec = filterSpec.getFieldOrThrow(newItem.field);
                        const newOperatorSpec = fieldSpec.getFirstOperatorOrThrow();

                        newItem.operator = newOperatorSpec.operator;
                        newItem.valueType = newOperatorSpec.valueType;
                        newItem.value = newOperatorSpec.defaultValue ?? undefined;
                        updateFilter(newItem);
                      }
                    }}
                  >
                    {filterSpec.fields.map((field, j) => (
                      <MenuItem key={j} value={field.field}>
                        {field.title || field.field}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Operator */}
                <FormControl sx={{ width: 150, maxWidth: 300 }} size='small' disabled={!item.field}>
                  <InputLabel>Operator</InputLabel>
                  <Select
                    value={item.operator || ""}
                    label='Operator'
                    onChange={(e) => {
                      const newOperator = e.target.value as FilterOperator;
                      if (newOperator !== item.operator) {
                        const fieldSpec = filterSpec.getFieldOrThrow(item.field);
                        const newOperatorSpec = fieldSpec.getOperatorOrThrow(newOperator);

                        const newItem = new FilterDefinitionItem({ ...item });
                        newItem.operator = newOperator;
                        newItem.valueType = newOperatorSpec.valueType;
                        newItem.value = newOperatorSpec.defaultValue ?? undefined;
                        updateFilter(newItem);
                      }
                    }}
                  >
                    {filterSpec.getFieldOperators(item.field)?.map((operator, j) => (
                      <MenuItem key={j} value={operator.operator}>
                        <FilterOperatorDisplayName operator={operator.operator} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Value */}
                {item.field && item.operator && operatorSpec && (
                  <FormControl sx={{ flex: 1, width: 300, maxWidth: 300 }} size='small'>
                    {item.field && item.operator && (
                      <>
                        {operatorSpec.valueInput && operatorSpec.valueInput(valueInputProps)}

                        {!operatorSpec.valueInput && (
                          <FilterValueInputPlaceholder {...valueInputProps} />
                        )}
                      </>
                    )}
                  </FormControl>
                )}

                {/* Controls */}
                <Stack direction='row' sx={{ justifyContent: "flex-end" }}>
                  {/* Remove */}
                  <AppIconButton
                    sx={{ width: 40, height: 40 }}
                    tooltipProps={{ title: "Remove filter" }}
                    onClick={() => removeFilter(item)}
                  >
                    <AppIcon of='remove' />
                  </AppIconButton>
                </Stack>
              </Stack>
            );
          })}

          {/* Controls */}
          <Stack spacing={1}>
            <Stack
              direction='row'
              sx={{ flex: 1, justifyContent: "space-between", alignItems: "center" }}
            >
              {isMaxFiltersLimitReached && (
                <Typography variant='caption'>
                  You reached the limit of {maxFilters}{" "}
                  {TextHelper.pluralizeManual("filter", maxFilters, "filters")}.
                </Typography>
              )}
              {!isMaxFiltersLimitReached && (
                <DropdownButton
                  component='button'
                  variant='text'
                  size='small'
                  color='primary'
                  autoCloseOnClick
                  buttonProps={{
                    startIcon: <AppIcon of='add' />,
                  }}
                  dropdownContentWrapperProps={{
                    maxHeight: {
                      xxs: "90vh",
                      md: 300,
                    },
                  }}
                  dropdownContent={
                    <MenuList>
                      {filterSpec.fields.map((field, i) =>
                        !(
                          field.isUseSingleFilter &&
                          filterDefinitionComputed?.hasItemForField(field.field)
                        ) ? (
                          <MenuItem
                            key={i}
                            onClick={() => {
                              addFilter({ field: field.field });
                            }}
                          >
                            <ListItemText>{field.title || field.field}</ListItemText>
                          </MenuItem>
                        ) : null,
                      )}
                    </MenuList>
                  }
                >
                  Add filter
                </DropdownButton>
              )}

              {!TypeHelper.isEmpty(filterDefinition?.items) && (
                <Button
                  variant='text'
                  size='small'
                  color='primary'
                  startIcon={<AppIcon of='remove' />}
                  onClick={() => removeAllFilters()}
                >
                  Remove all
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    );
  },
);

import _ from "lodash";
import moment, { Moment } from "moment";
import { ArrayHelper } from "./array";

export class TypeHelper {
  /** Checks if the value is primitive (primitive value, primitive data type).
   * https://developer.mozilla.org/en-US/docs/Glossary/Primitive
   */
  public static isPrimitive(
    value: any,
  ): value is undefined | null | string | number | bigint | boolean | symbol {
    return (
      this.isUndefined(value) ||
      this.isNull(value) ||
      this.isString(value) ||
      this.isNumber(value) ||
      this.isBigInt(value) ||
      this.isBoolean(value) ||
      this.isSymbol(value)
    );
  }

  public static isUndefined(value: any): value is undefined {
    return typeof value === "undefined" && value === undefined;
  }

  public static isNull(value: any): value is null {
    return value === null;
  }

  public static isNil(value: any): value is undefined | null {
    return this.isUndefined(value) || this.isNull(value);
  }

  public static isString(value: any): value is string {
    return typeof value === "string" || value instanceof String;
  }

  public static isNumber(value: any): value is number {
    return typeof value === "number";
  }

  public static isBigInt(value: any): value is bigint {
    return typeof value === "bigint";
  }

  public static isBoolean(value: any): value is boolean {
    return typeof value === "boolean";
  }

  public static isSymbol(value: any): value is symbol {
    return typeof value === "symbol";
  }

  public static isArray(value: any): value is any[] {
    return ArrayHelper.isArray(value);
  }

  /** Checks if value is the language type of Object. (e.g. null, arrays, functions, objects, regexes, new Number(0), new String(''), new Date(), etc) */
  public static isObject(value: any): value is object {
    return typeof value === "object";
  }

  /** Checks if value is a callable function. */
  public static isFunction(value: any): value is (...args: any[]) => any {
    return typeof value === "function";
  }

  public static isDate(value: any): value is Date {
    return value instanceof Date;
  }

  public static isMoment(value: any): value is Moment {
    return moment.isMoment(value);
  }

  /** Checks if values is empty.
   *  Considers: undefined, null, default values for primitive types (0, '', false, etc), empty object, empty array.
   *  NB: _.isEmpty is not used as it treats number, boolean, Date as empty values.
   */
  public static isEmpty<T>(value: Nil<T>): boolean {
    if (value === undefined || value === null) {
      return true;
    }

    // handle simple types (primitives and plain function/object)
    switch (typeof value) {
      case "bigint":
        return value === BigInt(0);
      case "boolean":
        return value === false;
      case "function":
        return false;
      case "number":
        return value === 0;
      case "object": // object, array
        return this.isEmptyObject(value);
      case "string":
        return value === "";
      case "symbol":
        return false;
      case "undefined":
        return true;
    }

    return false;
  }

  /** Checks if string is empty. */
  public static isEmptyString(value: Nil<string>): boolean {
    if (this.isNil(value)) {
      return false;
    }
    if (!this.isString(value)) {
      return false;
    }
    return value === "" || value.length === 0;
  }

  /** Checks if object is empty.
   *  Ignores non-object types: boolean, number, string, function, etc.
   *  NB: _.isEmpty is not used as it treats number, boolean, Date as empty values.
   */
  public static isEmptyObject<T extends object>(obj: Nil<T>): boolean {
    if (obj === undefined || obj === null) {
      return true;
    }
    if (Array.isArray(obj)) {
      return this.isEmptyArray(obj);
    }
    if (typeof obj !== "object") {
      return false;
    }

    // distinguish {}-like empty objects from other objects with no own properties (e.g. Date).
    const proto = Object.getPrototypeOf(obj);
    const isDirectlyInheritsObject = proto !== null && proto === Object.prototype;
    if (!isDirectlyInheritsObject) {
      return false;
    }

    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  /** Checks if array is empty.
   *  NB: _.isEmpty is not used as it treats number, boolean, Date as empty values.
   */
  public static isEmptyArray<T, TArray extends Array<T>>(arr: Nil<TArray>): boolean {
    if (arr === undefined || arr === null) {
      return true;
    }
    if (!Array.isArray(arr)) {
      return false;
    }
    return arr.length === 0;
  }

  /** Return JS type of provided value.
   *  For object type seeks for constructor name.
   */
  public static getType<T>(value: T): TypeofType | string {
    const type = typeof value;

    if (type !== "object") return type; // primitive or function
    // if (value === null) return "null"; // null

    // Everything else, check for a constructor
    if (type === "object") {
      const ctor = (value as unknown as any)?.constructor;
      const name = typeof ctor === "function" && ctor.name;
      return typeof name === "string" && name.length > 0 && name !== "Object" ? name : "object";
    }

    throw new Error(`Unable to resolve type for value: ${value}.`);
  }

  /** Returns default value of specified JS type (similar to C# 'default' operator). */
  public static getTypeDefaultValue(type: TypeofType | string): any {
    if (typeof type !== "string") throw new TypeError("Type must be a string.");

    // handle simple types (primitives and plain function/object)
    switch (type) {
      case "bigint":
        return BigInt(0);
      case "boolean":
        return false;
      case "function":
        return function () {};
      // case "null":
      //   return null;
      case "number":
        return 0;
      case "object":
        return {};
      case "string":
        return "";
      case "symbol":
        return Symbol();
      case "undefined":
        return void 0;
    }

    // try {
    //   // look for constructor in this or current scope
    //   const ctor = typeof this[type] === "function" ? this[type] : eval(type);
    //   return new ctor();
    //   // constructor not found, return new object
    // } catch (e) {
    //   return {};
    // }

    throw new Error(`Unable to resolve default value for type: ${type}.`);
  }

  public static parseBoolean(
    value: string | undefined,
    options?: { isLenient?: boolean },
  ): boolean | undefined {
    const lenientFalseValues = ["no", "-"];
    const lenientTrueValues = ["yes", "+"];

    if (_.isNil(value) || value === "") {
      return undefined;
    }
    return (
      (value?.toLowerCase() === "false" ? false : undefined) ??
      (value?.toLowerCase() === "true" ? true : undefined) ??
      (options?.isLenient && lenientFalseValues.includes(value) ? false : undefined) ??
      (options?.isLenient && lenientTrueValues.includes(value) ? true : undefined) ??
      undefined
    );
  }
}

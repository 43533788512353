import type {} from "@mui/material/Box/index";
import { ThemeOptions, createTheme, responsiveFontSizes } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";

import { THEMES } from "@/common/constants/common";

import _ from "lodash";
import { darkShadows, lightShadows } from "./shadows";

const fontSize = 14; // the default font size of the Material Specification.
const htmlFontSize = 16; // default html font size is 16px

/** Converts value in pixels to rem units.
 * MUI doesn't export this function directly, but in can be accessed via theme.typography.pxToRem()
 * Based on MUI source code:
 * material-ui\packages\mui-material\src\styles\createTypography.js
 */
function pxToRem(sizeInPx: number): string {
  const coef = fontSize / 14;
  return `${(sizeInPx / htmlFontSize) * coef}rem`;
}

const baseOptions: ThemeOptions = {
  breakpoints: {
    values: {
      // default
      xs: 321, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens

      // custom
      xxs: 0, // small phone
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
  direction: "ltr",
  shape: {
    borderRadius: 4, // MUI default is 4
  },
  shapeCustom: {
    borderRadius: "4px", // must match MUI's shape
    borderRadiusButton: "20px",
    borderRadiusIconButton: "50%",
    borderRadiusRoundAppIconButton: "50%",
    borderRadiusSquareAppIconButton: "10px",
    borderRadiusInput: "10px",
    borderRadiusAlert: "10px",
    borderRadiusCard: "10px",
    borderRadiusTab: "10px",
  },
  boxShadowCustom: {
    headerShadow: "0px 0px 10px 0px #07173D1A",
    mobileNavShadow: "12px 0px 14px 0px rgba(184,184,184,0.79)",
  },
  scrollBarCustom: {
    navBar: (theme) => ({
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#E5EAF4",
        borderRadius: "6px",
        borderImage: `${theme.palette.background.navbar} 1`,
        borderWidth: "3px",
        borderStyle: "solid",
      },
    }),
  },
  components: {
    MuiTypography: {
      defaultProps: {
        component: "div", // by default <p></p>, set to div to avoid error with nested div in p.
        // sx: { background: (t) => t.palette.background.paper },
      },
    },
    MuiAvatar: {
      defaultProps: {
        sx: { background: (t) => t.palette.background.paper },
      },
      styleOverrides: {
        fallback: {
          height: "75%",
          width: "75%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: (props) => ({
          borderRadius: props.theme.shapeCustom.borderRadiusButton,
        }),
      },
      variants: [
        {
          props: {
            size: "extraSmall",
          },
          style: (props) => ({
            padding: `0 ${props.theme.spacing(1)}`,
            fontSize: "0.75rem",
            lineHeight: 2,
          }),
        },
        {
          props: {
            size: "small",
          },
          style: (props) => ({
            padding: `${props.theme.spacing(0.5)} ${props.theme.spacing(2)}`,
            fontSize: "0.8125rem",
            lineHeight: 1.7,
          }),
        },
        {
          props: {
            size: "medium",
          },
          style: (props) => ({
            padding: `${props.theme.spacing(1)} ${props.theme.spacing(2)}`,
            fontSize: "0.875rem",
            lineHeight: 1.7,
          }),
        },
        {
          props: {
            size: "large",
          },
          style: (props) => ({
            padding: `${props.theme.spacing(1.75)} ${props.theme.spacing(2.5)}`,
            fontSize: "1rem",
            lineHeight: 1.7,
          }),
        },
      ],
    },
    MuiListItemText: {
      styleOverrides: {
        root: (props) => ({
          fontSize: props.theme.typography.pxToRem(14),
        }),
      },
    },
    MuiIconButton: {
      defaultProps: {
        sx: { background: "transparent" },
      },
      styleOverrides: {
        root: (props) => ({
          borderRadius: props.theme.shapeCustom.borderRadiusIconButton,
        }),
      },
      variants: [
        {
          props: {
            size: "extraSmall",
          },
          style: (props) => ({
            padding: `${props.theme.spacing(0.25)}`,
            fontSize: props.theme.typography.pxToRem(18),
          }),
        },
        {
          props: {
            size: "small",
          },
          style: (props) => ({
            padding: props.theme.spacing(0.375),
            fontSize: props.theme.typography.pxToRem(22),
          }),
        },
        {
          props: {
            size: "medium",
          },
          style: (props) => ({
            padding: props.theme.spacing(0.625),
            fontSize: props.theme.typography.pxToRem(26),
          }),
        },
        {
          props: {
            size: "large",
          },
          style: (props) => ({
            padding: props.theme.spacing(1),
            fontSize: props.theme.typography.pxToRem(30),
          }),
        },
      ],
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
          width: "100%",
        },
        "#root": {
          height: "100%",
          width: "100%",
          "&&": {
            // also a root selector but with higher CSS specificity (.#-root.#-root)
            // background: theme.palette.warning.light,
          },
        },
        "#nprogress .bar": {
          zIndex: "2000 !important",
        },
      }),
    },
    MuiCard: {
      defaultProps: {
        sx: { background: (t) => t.palette.background.paper },
      },
      styleOverrides: {
        root: {
          overflow: "visible", // allow dropdowns in card
          // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.12)",
          // boxShadow: darkShadows[1],
        },
      },
      variants: [],
    },
    MuiCardHeader: {
      defaultProps: {
        sx: { background: (t) => t.palette.background.paper },
        titleTypographyProps: {
          variant: "h6",
        },
      },
    },
    MuiCardContent: {
      defaultProps: {
        sx: { background: (t) => t.palette.background.paper },
      },
      styleOverrides: {
        root: {
          overflow: "visible", // allow dropdowns in card
          "&:last-child": {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: (props) => ({
          borderRadius: props.theme.shape.borderRadius,
          overflow: "hidden",
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "initial",
          marginRight: "8px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "& .MuiListItemIcon-root": {
            minWidth: "initial",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: (props) => ({
          background: props.theme.palette.background.paper,
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: ({ ownerState, theme: th }) => ({
          background: th.palette.background.paper,
          fontSize: "inherit",
          borderColor: `${th.palette.divider}`,
          // border: "none",
          // borderTop: `1px solid ${th.palette.divider}`,
          "& .MuiDataGrid-withBorderColor": {
            borderColor: `${th.palette.divider}`,
          },
          "& .MuiDataGrid-toolbarContainer": {
            // borderTop: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            // borderTop: "none",
          },
        }),
        toolbarContainer: ({ ownerState, theme: th }) => ({
          paddingTop: th.spacing(1),
          paddingBottom: th.spacing(1),
          paddingLeft: th.spacing(1),
          paddingRight: th.spacing(1),
          border: "none",
          borderBottom: `1px solid ${th.palette.divider}`,
        }),
        footerContainer: ({ ownerState, theme: th }) => ({
          border: "none",
          borderTop: `1px solid ${th.palette.divider}`,
        }),
        columnHeaders: ({ ownerState, theme: th }) => ({
          background: th.palette.background.paper,

          // MuiDataGrid-columnHeaderRow
          "& [role=row]": {
            background: `${th.palette.background.paper} !important`,
          },
        }),
        columnHeader: ({ ownerState, theme: th }) => ({
          "&:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 600,
            color: th.palette.text.secondary,
          },
          "&.MuiDataGrid-columnHeader--sorted": {
            background: th.palette.primary.light,
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: (props) => ({
          background: props.theme.palette.background.paper, // ????
          borderRadius: props.theme.shapeCustom.borderRadiusInput,
        }),
      },
    },
    MuiInput: {
      styleOverrides: {
        root: (props) => ({
          background: props.theme.palette.background.paper,
          borderRadius: props.theme.shapeCustom.borderRadiusInput,
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: (props) => ({
          background: props.theme.palette.background.paper,
          borderRadius: props.theme.shapeCustom.borderRadiusInput,
        }),
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: (props) => ({
          background: props.theme.palette.background.paper,
          borderRadius: props.theme.shapeCustom.borderRadiusInput,
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: (props) => ({
          background: props.theme.palette.background.paper,
          borderRadius: props.theme.shapeCustom.borderRadiusInput,
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: (props) => ({
          background: "transparent",
          paddingLeft: 5,
          paddingRight: 5,
        }),
      },
    },
    MuiDatePicker: {
      // styleOverrides: {
      //   root: {
      //     background: "red",
      //   },
      // },
    },
    MuiModal: {
      styleOverrides: {
        root: {},
      },
    },
    MuiPopper: {
      defaultProps: {
        sx: {
          // zIndex: (th) => th.zIndex.modal,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {},
        paper: (props) => ({
          borderRadius: props.theme.shape.borderRadius,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: "text.secondary",
          fontWeight: 600,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: (props) => ({
          width: "100%",
        }),
      },
    },
    MuiChip: {
      variants: [
        // custom
        // {
        //   props: {
        //     variant: "filled",
        //   },
        //   style: (props) => ({
        //     background: props.theme.palette.background.gray,
        //     color: props.theme.palette.text.primary,
        //   }),
        // },
        {
          props: {
            size: "extraSmall",
          },
          style: (props) => ({
            height: 20,
            fontSize: props.theme.typography.caption.fontSize,
            lineHeight: props.theme.typography.caption.lineHeight,
            "& .MuiChip-label": {
              padding: "0px 6px",
            },
          }),
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        root: (props) => ({
          padding: `${props.theme.spacing(1)} ${props.theme.spacing(2)}`,
          borderRadius: props.theme.shapeCustom.borderRadiusAlert,

          "& .MuiAlert-icon": {
            padding: 0,
            marginRight: props.theme.spacing(1.25),
            fontSize: props.theme.typography.pxToRem(24),
          },

          "& .MuiAlertTitle-root": {
            color: props.theme.palette,
            ...props.theme.typography.subtitle1,
          },

          "& .MuiAlert-message": {
            padding: 0,
            ...props.theme.typography.body1,
          },
        }),
        standard: ({ theme, ownerState }) => {
          const color = ownerState.severity || ownerState.color;
          return {
            color: color ? theme.palette[color].dark : undefined,
            background: color ? theme.palette[color].light : undefined,
            borderColor: color ? theme.palette[color].light : undefined,
          };
        },
        filled: ({ theme, ownerState }) => {
          const color = ownerState.severity || ownerState.color;
          return {
            color: color ? theme.palette[color].contrastText : undefined,
            background: color ? theme.palette[color].main : undefined,
            borderColor: color ? theme.palette[color].main : undefined,
          };
        },
        outlined: ({ theme, ownerState }) => {
          const color = ownerState.severity || ownerState.color;
          return {
            color: color ? theme.palette[color].dark : undefined,
            background: "transparent",
            borderColor: color ? theme.palette[color].main : undefined,
          };
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: (props) => ({
          "& .MuiBadge-badge": {
            zIndex: props.theme.zIndex.badge, // For cases where the count badge is not fully displayed.
          },
        }),
      },
      variants: [
        {
          props: { color: "default" },
          style: (props) => ({
            "& .MuiBadge-badge": {
              backgroundColor: props.theme.palette.accent?.dark,
              color: props.theme.palette.primary.contrastText,
            },
          }),
        },
      ],
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: "h1",
      },
    },
  },
  typography: {
    fontFamily: [
      // custom
      "Inter",
      "Inter Variable",

      // from MUI docs
      // https://mui.com/material-ui/customization/typography/
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),

    // default html font size is 16px
    // fontSize: fontSize,
    // htmlFontSize: htmlFontSize,

    // default variants
    // https://mui.com/material-ui/react-typography/
    h1: {
      fontWeight: 700,
      fontSize: "1.75rem",
      lineHeight: 1.167,
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: 1.2,
    },
    h3: {
      fontWeight: 500,
      fontSize: "1.375rem",
      lineHeight: 1.167,
    },
    h4: {
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.235,
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.125rem",
      lineHeight: 1.234,
    },
    h6: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: 1.3,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: "0.875rem",
      lineHeight: 1.3,
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: "0.813rem",
      lineHeight: 1.4,
    },
    body1: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.42,
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.813rem",
      lineHeight: 1.43,
    },
    body3: {
      fontWeight: 400,
      fontStyle: "italic",
      fontSize: "0.875rem",
      lineHeight: 1.43,
    },
    body4: {
      fontWeight: 500,
      fontSize: "0.813rem",
      lineHeight: 1.43,
    },
    button: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.7,
      textTransform: "none",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    button2: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.7,
      textTransform: "none",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.688rem",
      lineHeight: 1,
    },
    overline: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.5,
    },

    // custom variants
  },
  /** https://mui.com/material-ui/customization/z-index/ */
  zIndex: {
    badge: 100,
  },
};

const themesOptions: { [key in THEMES]: ThemeOptions } = {
  [THEMES.LIGHT]: {
    components: {
      MuiLink: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiAlert: {
        styleOverrides: {},
      },
    },
    palette: {
      mode: "light",

      // default
      primary: {
        contrastText: "#ffffff",
        main: "#4670D4",
        light: "#EFF7FF",
        dark: "#3B62BE",
      },
      accent: {
        contrastText: "#ffffff",
        main: "#78BEFF",
        light: "#C0E1FF",
        dark: "#3C97EC",
      },
      // deleted from figma style guide
      secondary: {
        contrastText: "#ffffff",
        main: "#5A5A5A",
        light: "#737373",
      },
      error: {
        contrastText: "#ffffff",
        main: "#E5313C",
        dark: "#A0222A",
        light: "#FEF7F7",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#F48400",
        dark: "#B46508",
        light: "#FDF0E0",
      },
      info: {
        contrastText: "#ffffff",
        main: "#0E9AB9",
        dark: "#096B81",
        light: "#E2F2F6",
      },
      infoDetails: {
        contrastText: "#ffffff",
        main: "#9228FC",
        dark: "#6B0EC7",
        light: "#F1E5FE",
      },
      success: {
        contrastText: "#ffffff",
        main: "#34A853",
        dark: "#24753A",
        light: "#E6F4EA",
      },
      text: {
        primary: "#5A5A5A",
        secondary: "#737373",
        disabled: "#C4C4C4",
        disabledLight: "#DDDDDD",
      },
      // grey: {
      //   50: "",
      //   100: "",
      // },
      divider: "#CDCDCD",
      background: {
        default: "#FAFAFA",
        paper: "#FFFFFF",
        gray: "#FAFAFA",
        navbar: "linear-gradient(180deg, #F5FBFF 0%, #E8EFFF 100%)",
        logo: {
          whiteWave: "#FFFFFF",
          blueWave: "rgba(173, 223, 255, 0.18)",
          text: "#464646",
        },
      },
      /** For text highlight */
      highlight: {
        contrastText: "#35ECE1",
        light: "#E6FCFB",
        main: "#35ECE1",
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiLink: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standard: ({ theme, ownerState }) => {
            const color = ownerState.severity || ownerState.color;
            return {
              color: color ? theme.palette[color].contrastText : undefined,
              background: color ? theme.palette[color].dark : undefined,
              borderColor: color ? theme.palette[color].dark : undefined,
            };
          },
          filled: ({ theme, ownerState }) => {
            const color = ownerState.severity || ownerState.color;
            return {
              color: color ? theme.palette[color].contrastText : undefined,
              background: color ? theme.palette[color].main : undefined,
              borderColor: color ? theme.palette[color].main : undefined,
            };
          },
          outlined: ({ theme, ownerState }) => {
            const color = ownerState.severity || ownerState.color;
            return {
              color: color ? theme.palette[color].contrastText : undefined,
              background: "transparent",
              borderColor: color ? theme.palette[color].main : undefined,
            };
          },
        },
      },
    },
    palette: {
      mode: "dark",
      background: {
        default: "#414141",
        paper: "#5A5A5A",
        navbar: "#353535",
        logo: {
          whiteWave: "#5A5A5A",
          blueWave: "#ADDFFF",
          text: "#FFFFFF",
        },
      },
      divider: "rgba(145, 158, 171, 0.24)",
      primary: {
        contrastText: "#ffffff",
        main: "#78BEFF",
        dark: "#3C97EC",
        light: "#C0E1FF",
      },
      secondary: {
        contrastText: "#ffffff",
        main: "#ffffff",
        light: "#737373",
      },
      accent: {
        contrastText: "#ffffff",
        main: "#4670D4",
        dark: "#3B62BE",
        light: "#EFF7FF",
      },
      error: {
        contrastText: "#ffffff",
        main: "#E5313C",
        dark: "#A0222A",
        light: "#FEF7F7",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#F48400",
        dark: "#B46508",
        light: "#FDF0E0",
      },
      info: {
        contrastText: "#ffffff",
        main: "#0E9AB9",
        dark: "#096B81",
        light: "#E2F2F6",
      },
      infoDetails: {
        contrastText: "#ffffff",
        main: "#9228FC",
        dark: "#6B0EC7",
        light: "#F1E5FE",
      },
      success: {
        contrastText: "#ffffff",
        main: "#34A853",
        dark: "#24753A",
        light: "#E6F4EA",
      },
      text: {
        primary: "#ffffff",
        secondary: "#FAFAFA",
        disabled: "#7B7B7B",
      },

      // custom
      highlight: {
        contrastText: "rgba(246, 255, 0, 1)",
        light: "rgba(246, 255, 0, 0.25)",
        main: "rgba(246, 255, 0, 0.5)",
        dark: "rgba(246, 255, 0, 0.75)",
      },
    },
    shadows: darkShadows,
  },
};

export const createCustomTheme = (config = { theme: THEMES.LIGHT, responsiveFontSizes: false }) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.error(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  const mergedOptions = _.merge({}, baseOptions, themeOptions);
  let theme = createTheme(mergedOptions);

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

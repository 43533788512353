import { useHistory, useParams } from "react-router";

import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ProductLocationCreateUpdate from "@/common/components/Entity/ProductLocation/ProductLocationCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function ProductLocationCreateUpdatePage() {
  const { productLocationId } = useParams<{ productLocationId?: string }>();
  const history = useHistory();

  const isCreate = !productLocationId;

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new sales location" : "Edit sales location"}
        />
      }
    >
      <ProductLocationCreateUpdate
        productLocationId={productLocationId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.PRODUCT_SALES_LOCATION_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}

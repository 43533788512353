import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode, useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";

export type CreateUpdateFormPlacement = "modal" | "page";

export interface BaseEntityAutocompleteOrCreateProps<
  TEntity,
  TDefaultValues,
  AutocompleteProps,
  CreateUpdateProps,
> {
  createFormPlacement?: CreateUpdateFormPlacement;
  renderAutocomplete: (params: {
    defaultValues?: TDefaultValues;
    isCreate: boolean;
    setDefaultValues: (newValues: TDefaultValues | undefined) => void;
    setIsCreate: (newValue: boolean) => void;
    onCreateStart?: () => void;
    onCreate: (newValue: TEntity) => void;
  }) => ReactNode;
  renderCreateUpdate: (params: {
    defaultValues?: TDefaultValues;
    isCreate: boolean;
    setDefaultValues: (newValues: TDefaultValues | undefined) => void;
    setIsCreate: (newValue: boolean) => void;
    onCreateStart?: () => void;
    onCreate: (newValue: TEntity) => void;
  }) => ReactNode;
  renderCreateUpdateModal: (params: {
    defaultValues?: TDefaultValues;
    isCreate: boolean;
    setDefaultValues: (newValues: TDefaultValues | undefined) => void;
    setIsCreate: (newValue: boolean) => void;
    onCreateStart?: () => void;
    onCreate: (newValue: TEntity) => void;
  }) => ReactNode;
  onCreateStart?: () => void;
  onCreate: (newValue: TEntity) => void;
}

export interface BaseEntityAutocompleteOrCreateInheritableProps<
  TEntity,
  TDefaultValues,
  AutocompleteProps,
  CreateUpdateProps,
> {
  autocompleteProps: AutocompleteProps;
  createUpdateProps?: CreateUpdateProps | null;
  createFormPlacement?: CreateUpdateFormPlacement;
  onCreateStart?: () => void;
  onCreate: (newValue: TEntity) => void;
}

/** EntityAutocomplete + EntityCreateUpdate */
export default function BaseEntityAutocompleteOrCreate<
  TEntity,
  TDefaultValues,
  AutocompleteProps,
  CreateUpdateProps,
>({
  createFormPlacement = "modal",
  renderAutocomplete,
  renderCreateUpdate,
  renderCreateUpdateModal,
  onCreateStart,
  onCreate,
}: BaseEntityAutocompleteOrCreateProps<
  TEntity,
  TDefaultValues,
  AutocompleteProps,
  CreateUpdateProps
>) {
  const [isCreate, setIsCreate] = useState(false);
  const [defaultValues, setDefaultValues] = useState<TDefaultValues | undefined>(undefined);

  const params = {
    defaultValues,
    isCreate,
    setDefaultValues,
    setIsCreate,
    onCreateStart,
    onCreate,
  };

  return (
    <Stack spacing={2}>
      {/* 1. Search */}
      {!isCreate && <Box>{renderAutocomplete(params)}</Box>}

      {/* 2. Create */}
      {isCreate && (
        <Box>
          {isCreate && (
            <>
              {createFormPlacement === "modal" && renderCreateUpdateModal(params)}

              {createFormPlacement === "page" && (
                <Stack spacing={1}>
                  <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                    <Button
                      variant='text'
                      color='secondary'
                      size='small'
                      startIcon={<AppIcon of='goBack' />}
                      onClick={() => setIsCreate(false)}
                    >
                      Back to search
                    </Button>

                    <Typography component='div' variant='subtitle1'>
                      Create new
                    </Typography>
                  </Stack>

                  {renderCreateUpdate(params)}
                </Stack>
              )}
            </>
          )}
        </Box>
      )}
    </Stack>
  );
}

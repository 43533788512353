import { Grid, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import EntityDataBlock, {
  EntityDataBlockProps,
} from "@/common/components/EntityData/EntityDataBlock";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, VehicleDto, VehicleReferenceDto } from "@/core/api/generated";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";

interface BaseProps extends Pick<EntityDataBlockProps, "withDetailsToggle" | "isDetailsVisible"> {
  withLink?: boolean;
  withDetailsToggle?: boolean;
  isDetailsVisible?: boolean;
  sx?: SxProps<Theme>;
}

type Props =
  | ({ variant: "full"; vehicle?: VehicleDto } & BaseProps)
  | ({ variant: "reference"; vehicle?: VehicleReferenceDto } & BaseProps);

/** Vehicle info as block element. */
export default function VehicleBlock({
  variant,
  vehicle,
  withLink,
  sx,
  ...entityDataBlockProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  const [fetchedVehicle, setFetchedVehicle] = useState<VehicleDto | null>(null);

  useEffect(() => {
    (async () => {
      if (!!vehicle && variant === "reference") {
        try {
          const response = await apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            vehicleId: vehicle.id as string,
          });
          setFetchedVehicle(response.data);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [vehicle && vehicle.id]);

  if (!vehicle) {
    return null;
  }

  const fullDto = variant === "full" ? vehicle : fetchedVehicle || (vehicle as VehicleDto);

  return (
    <EntityDataBlock
      sx={{
        flex: 1,
        ...sx,
      }}
      {...entityDataBlockProps}
      to={
        withLink && hasPermissions([AppPermission.FleetAppAccess, AppPermission.VehicleRead])
          ? ROUTE_PATH.VEHICLE_VIEW(vehicle?.id)
          : undefined
      }
      title={
        <>
          <AppIcon of='vehicle' inText /> Vehicle
        </>
      }
      briefContent={
        <>
          <Typography component='div' variant='body1'>
            {vehicle.plateNo?.plateNo || vehicle.identificationNumber} {vehicle.spec?.make?.name},{" "}
            {vehicle.spec?.model?.name}
          </Typography>
          <Typography component='div' variant='body2'>
            {fullDto.spec?.bodyType || fetchedVehicle?.spec?.bodyType},{" "}
            {fullDto.spec?.fuelType || fetchedVehicle?.spec?.fuelType}
          </Typography>
        </>
      }
      detailedContent={
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              <FieldValue label='Plate no' withCopyButton>
                {vehicle.plateNo?.plateNo}
              </FieldValue>
              <FieldValue label='ID number' withCopyButton>
                {vehicle.identificationNumber}
              </FieldValue>
              <FieldValue label='Make'>{vehicle.spec?.make!.name}</FieldValue>
              <FieldValue label='Model'>{vehicle.spec?.model!.name}</FieldValue>
              <FieldValue label='Accessories'>
                {fullDto?.accessories && fullDto.accessories?.length}
              </FieldValue>
            </Stack>
          </Grid>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              <FieldValue label='Type' isEmpty={!fullDto.spec?.type && !fetchedVehicle?.spec?.type}>
                <InlineApiEnumValue
                  type='VehicleType'
                  value={fullDto.spec?.type || fetchedVehicle?.spec?.type}
                />
              </FieldValue>
              <FieldValue
                label='Body type'
                isEmpty={!fullDto.spec?.bodyType && !fetchedVehicle?.spec?.bodyType}
              >
                <InlineApiEnumValue
                  type='VehicleBodyType'
                  value={fullDto.spec?.bodyType || fetchedVehicle?.spec?.bodyType}
                />
              </FieldValue>
              <FieldValue
                label='Fuel type'
                isEmpty={!fullDto.spec?.fuelType && !fetchedVehicle?.spec?.fuelType}
              >
                <InlineApiEnumValue
                  type='VehicleFuelType'
                  value={fullDto.spec?.fuelType || fetchedVehicle?.spec?.fuelType}
                />
              </FieldValue>
              <FieldValue label='Mileage'>{fullDto?.mileage}</FieldValue>
            </Stack>
          </Grid>
        </Grid>
      }
    />
  );
}

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

import { ImportEntityType, ImportMappingTemplateDto } from "@/core/api/generated";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

interface ImportMappingSelectProps {
  entityType?: ImportEntityType;
  importMappingId?: string;
  selectProps?: SelectProps;
  onChange?: (
    e: SelectChangeEvent<unknown>,
    importMapping?: ImportMappingTemplateDto | undefined,
  ) => void;
}

function ImportMappingSelect({
  entityType,
  importMappingId,
  selectProps,
  onChange,
}: ImportMappingSelectProps) {
  const mappingRequest = useApiRequest(
    apiClient.tenantImportApi.apiV1TenantImportsMappingsGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      importEntityType: entityType,
    },
    {
      deps: [],
      skip: entityType === ImportEntityType.None,
    },
  );

  const _onChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const mapping = (mappingRequest.data || []).find((x) => x.id === e.target.value);
      if (mapping) {
        onChange && onChange(e, mapping);
      }
    },
    [onChange],
  );

  return (
    <Box>
      <Select
        label='Import type'
        displayEmpty={false}
        fullWidth
        value={importMappingId || undefined}
        onChange={_onChange}
        {...selectProps}
      >
        {mappingRequest.data &&
          mappingRequest.data.map((mapping, index) => (
            <MenuItem key={index} value={mapping.id!}>
              {mapping.name}
            </MenuItem>
          ))}
        {(!mappingRequest.data || mappingRequest.data.length === 0) && (
          <MenuItem value={undefined}>No mappings</MenuItem>
        )}
      </Select>
    </Box>
  );
}

export default ImportMappingSelect;

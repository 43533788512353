import { Box } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { TenantRequestDto } from "@/core/api/generated";
import DataGrantPaginatedList from "../../../DataGrant/ListView/DataGrantPaginatedList";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";

interface Props {
  tenantRequest: TenantRequestDto;
}
export default function DataGrantsTabContent({ tenantRequest }: Props) {
  return (
    <PageTabContent>
      <Box>
        <DataGrantPaginatedList
          defaultValues={{
            tenantRequestsMeta: {
              tenantRequestId: tenantRequest.id!,
            },
          }}
          displayProps={{
            counters: false,
            viewVariant: ViewLayoutVariant.Tab,
          }}
        />
      </Box>
    </PageTabContent>
  );
}

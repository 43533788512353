import { LoadingButton } from "@mui/lab";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Formik, getIn } from "formik";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import AdminTenantRolesSelector from "@/common/components/Entity/Role/AdminTenantRolesSelector";
import AdminTenantAutocomplete from "@/common/components/Entity/Tenant/AdminTenantAutocomplete";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import DurationInput from "@/common/components/Form/Input/DurationInput";
import PhoneInput from "@/common/components/Form/Input/PhoneInput";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { TimeSpan } from "@/common/ts/timespan";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AdminCreateInviteDto, UnitOfTime } from "@/core/api/generated";
import { useHistory } from "react-router";

const StyledForm = styled("form")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: (theme.shadows as string[])[1],
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  width: "100%",
  height: "100%",
  overflow: "hidden",
}));

export default function CreatePersonalInvitePage() {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();
  const thunkDispatch = useAppThunkDispatch();

  return (
    <Formik<AdminCreateInviteDto & BaseFormikValues>
      enableReinitialize
      initialValues={{
        tenantId: undefined,
        departmentId: undefined,
        locationId: undefined,
        email: undefined,
        personName: {
          firstName: undefined,
          lastName: undefined,
        },
        phoneNumber: undefined,
        lifetime: new TimeSpan({ day: 30 }).toString(),
        roleIds: new Array<string>(),
        isInviteExistingUser: undefined,

        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // email: Yup.string().required("Email is required"),
        // firstName: Yup.string().required("First name is required"),
        // lastName: Yup.string().required("Last name is required"),
        // phoneNumber: Yup.string().required("Phone number is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          await apiClient.adminInvitesApi.apiV1AdminInvitesPost({
            adminCreateInviteDto: { ...values },
          });
          enqueueSnackbar("Invitation was sent.", { variant: "success" });

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
          history.goBack();
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <CreateUpdatePageLayout header={<SimpleViewPageHeader title={"New personal invite"} />}>
            <form noValidate onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  width: "100%",
                  minHeight: 0,
                  overflow: "auto",
                }}
              >
                <AdminTenantAutocomplete
                  entityId={values.tenantId}
                  fullWidth
                  onChange={(newValue) => {
                    console.log("ON CHANGE TENANT", newValue);
                    setFieldValue("tenantId", newValue?.id);
                    if (!newValue?.id) {
                      console.log("RESET ROLES");
                      setFieldValue("roleIds", []);
                    }
                  }}
                />

                <TextField
                  autoFocus
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  required
                  helperText={touched.email && errors.email}
                  label='Email'
                  margin='dense'
                  name='email'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='email'
                  value={values.email}
                  variant='outlined'
                />

                <TextField
                  error={Boolean(
                    getIn(touched, "personName.firstName") && getIn(errors, "personName.firstName"),
                  )}
                  fullWidth
                  required
                  helperText={
                    getIn(touched, "personName.firstName") && getIn(errors, "personName.firstName")
                  }
                  label='First name'
                  margin='dense'
                  name='personName.firstName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.personName?.firstName}
                  variant='outlined'
                />

                <TextField
                  error={Boolean(
                    getIn(touched, "personName.lastName") && getIn(errors, "personName.lastName"),
                  )}
                  fullWidth
                  required
                  helperText={
                    getIn(touched, "personName.lastName") && getIn(errors, "personName.lastName")
                  }
                  label='Last name'
                  margin='dense'
                  name='personName.lastName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.personName?.lastName}
                  variant='outlined'
                />

                <PhoneInput
                  error={Boolean(errors.phoneNumber)}
                  helperText={
                    errors.phoneNumber &&
                    ValidationHelper.getFormikErrorsAsString(errors.phoneNumber)
                  }
                  fullWidth
                  required
                  label='Phone number'
                  margin='dense'
                  name='phoneNumber'
                  value={values.phoneNumber || ""}
                  variant='outlined'
                  color='primary'
                  placeholder='Phone number'
                  onBlur={handleBlur}
                  onChange={(e, ph, iso) => {
                    setFieldValue(`phoneNumber`, ph);
                  }}
                />

                <DurationInput
                  error={Boolean(errors.lifetime)}
                  helperText={errors.lifetime}
                  fullWidth
                  margin='dense'
                  label='Invite lifetime'
                  variant='outlined'
                  defaultUnitOfTime={UnitOfTime.Day}
                  value={values.lifetime}
                  onChange={(newValue) => {
                    setFieldValue("lifetime", newValue);
                  }}
                  onBlur={handleBlur}
                />

                <Box>
                  <AdminTenantRolesSelector
                    tenantId={values.tenantId}
                    roleIds={values.roleIds}
                    onChange={(newValue) =>
                      setFieldValue("roleIds", newValue?.map((x) => x.id) || [])
                    }
                    textFieldProps={{
                      error: Boolean(touched.roleIds && errors.roleIds),
                      helperText: touched.roleIds && errors.roleIds,
                    }}
                  />
                </Box>

                <FormControl margin='dense'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isInviteExistingUser'
                        checked={values.isInviteExistingUser}
                        onChange={(e) => setFieldValue("isInviteExistingUser", e.target.checked)}
                      />
                    }
                    label='Invite existing company user'
                  />
                  <FormHelperText>
                    If checked, invite will be sent even if user already exists in your company.
                  </FormHelperText>
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                loading={isSubmitting}
                disabled={
                  isSubmitting ||
                  !values.email ||
                  !values.personName?.firstName ||
                  !values.personName.lastName ||
                  !values.phoneNumber
                }
                fullWidth
                type='submit'
                variant='contained'
              >
                Create
              </LoadingButton>
            </form>
          </CreateUpdatePageLayout>
        );
      }}
    </Formik>
  );
}

import { Chip, Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { TenantConnectionRequestDto } from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import TenantLink from "../../../Tenant/TenantLink";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";

interface Props {
  tenantConnectionRequest: TenantConnectionRequestDto;
}

export default function OverviewTabContent({ tenantConnectionRequest }: Props) {
  const currentTenant = useCurrentTenant();

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={tenantConnectionRequest} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={1}>
            <FieldValue label='Number'>{tenantConnectionRequest?.globalNumber}</FieldValue>

            <FieldValue label='Sender'>
              <TenantLink entity={tenantConnectionRequest?.senderTenant} entityId={undefined} />{" "}
              {currentTenant?.id === tenantConnectionRequest?.senderTenant?.id && (
                <Chip size='small' variant='outlined' color='secondary' label='You' />
              )}
            </FieldValue>

            <FieldValue label='Receiver'>
              <TenantLink entity={tenantConnectionRequest?.receiverTenant} entityId={undefined} />{" "}
              {currentTenant?.id === tenantConnectionRequest?.receiverTenant?.id && (
                <Chip size='small' variant='outlined' color='secondary' label='You' />
              )}
            </FieldValue>

            <FieldValue label='Status'>
              <InlineApiEnumValue
                type='TenantConnectionRequestStatus'
                value={tenantConnectionRequest?.status}
              />
            </FieldValue>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

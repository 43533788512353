import { apiClient } from "@/core/api/ApiClient";
import { IntegrationApiClientDto, EntityType } from "@/core/api/generated";
import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: IntegrationApiClientDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function IntegrationApiClientDeleteModal({
  entity,
  onDelete,
  ...otherProps
}: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.IntegrationApiClient}
      entity={entity}
      entityId={entity.id}
      deleteFunc={(params) =>
        apiClient.integrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          integrationApiClientId: params.entityId,
        })
      }
      {...otherProps}
    />
  );
}

import { useHistory, useParams } from "react-router";

import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CustomerCreateUpdate from "@/common/components/Entity/Customer/CustomerCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function CustomerCreateUpdatePage() {
  const { customerId } = useParams<{ customerId?: string }>();
  const isCreate = !customerId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={isCreate ? "Create new customer" : "Edit customer"} />}
    >
      <CustomerCreateUpdate
        customerId={customerId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.CUSTOMER_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}

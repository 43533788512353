import { Box } from "@mui/material";
import { useState } from "react";

import { FileItem } from "@/common/fileItem";
import { ValidationHelper, ValidationInfo } from "@/common/validation";
import { ChatDto, ChatMessageRootNodeDto, ChatParticipantDto } from "@/core/api/generated";
import * as chatMessagesSlice from "@/store/communication/chatMessagesSlice";
import * as chatsSlice from "@/store/communication/chatsSlice";

import { useAppDispatch, useAppThunkDispatch } from "@/common/hooks/redux";
import { ChatSize } from "../Chat/ChatDisplay";
import ChatMessageInput, { ChatMessageInputProps } from "./Input/ChatMessageInput";
import { useChatCurrentParticipant } from "@/common/hooks/communication/useChatCurrentParticipant";

interface OwnProps {
  chat: ChatDto;
  uploadedAttachments?: FileItem[];
  secondaryActions?: ChatMessageInputProps["secondaryActions"];
  size?: ChatSize;
  renderAttachments?: ChatMessageInputProps["renderAttachments"];
  onPasteFiles?: ChatMessageInputProps["onPasteFiles"];
  getUploadedAttachments?: () => FileItem[];
  clearUploadedAttachments?: () => void;
}

type Props = OwnProps;

export default function ChatMessageSender({
  chat,
  uploadedAttachments,
  secondaryActions,
  size,
  renderAttachments,
  onPasteFiles,
  getUploadedAttachments,
  clearUploadedAttachments,
}: Props) {
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();

  const currentParticipant = useChatCurrentParticipant(chat.id);

  const [validation, setValidation] = useState<ValidationInfo>(new ValidationInfo());

  const onSendMessage = async (data: { body: string; rootNode: ChatMessageRootNodeDto }) => {
    try {
      await thunkDispatch(
        chatMessagesSlice.sendChatMessage({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          chatId: chat.id!,
          sendChatMessageDto: {
            participantId: currentParticipant?.id,
            body: data.body,
            rootNode: data.rootNode,
            attachments: ((getUploadedAttachments && getUploadedAttachments()) || [])
              ?.filter((x) => !!x.file)
              ?.map((x) => FileItem.toGeneralAttachmentInputDto(x)),
          },
        }),
      );
      setValidation(new ValidationInfo());
      clearUploadedAttachments && clearUploadedAttachments();
    } catch (err) {
      setValidation(ValidationHelper.handleApiErrorResponse(err));
      throw err;
    }
  };

  return (
    <Box>
      <ChatMessageInput
        chatId={chat.id!}
        disabled={!chat.settings!.allowSendMessages}
        size={size}
        placeholder='Write a message...'
        actionText='Reply'
        validation={validation}
        hasUploadedAttachments={uploadedAttachments && uploadedAttachments.length !== 0}
        secondaryActions={secondaryActions}
        renderAttachments={renderAttachments}
        onFocus={() =>
          dispatch(
            chatsSlice.setChatIsEnterMessageAreaFocused({
              chatId: chat.id!,
              isEnterMessageAreaFocused: true,
            }),
          )
        }
        onBlur={() =>
          dispatch(
            chatsSlice.setChatIsEnterMessageAreaFocused({
              chatId: chat.id!,
              isEnterMessageAreaFocused: false,
            }),
          )
        }
        onPasteFiles={onPasteFiles}
        onAction={onSendMessage}
      />
    </Box>
  );
}

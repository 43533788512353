import { ProductLocationDto } from "@/core/api/generated";
import BaseEntityAutocompleteOrCreate, {
  BaseEntityAutocompleteOrCreateInheritableProps,
} from "../components/BaseEntityAutocompleteOrCreate";
import ProductLocationAutocomplete, {
  ProductLocationAutocompleteProps,
} from "./ProductLocationAutocomplete";
import ProductLocationCreateUpdate, {
  ProductLocationCreateUpdateProps,
} from "./ProductLocationCreateUpdate";
import ProductLocationCreateUpdateModal from "./ProductLocationCreateUpdateModal";

type Props = BaseEntityAutocompleteOrCreateInheritableProps<
  ProductLocationDto,
  ProductLocationCreateUpdateProps["defaultValues"],
  ProductLocationAutocompleteProps,
  Pick<ProductLocationCreateUpdateProps, "defaultValues">
>;

export default function SelectOrCreateProductLocation({
  autocompleteProps,
  createUpdateProps,
  createFormPlacement = "modal",
  onCreateStart,
  onCreate,
}: Props) {
  return (
    <BaseEntityAutocompleteOrCreate<
      ProductLocationDto,
      ProductLocationCreateUpdateProps["defaultValues"],
      ProductLocationAutocompleteProps,
      Pick<ProductLocationCreateUpdateProps, "defaultValues">
    >
      createFormPlacement={createFormPlacement}
      onCreateStart={onCreateStart}
      onCreate={onCreate}
      renderAutocomplete={(params) => (
        <ProductLocationAutocomplete
          {...autocompleteProps}
          withCreate
          createOptionTitle={(inputValue) => `Create new productLocation "${inputValue}"`}
          onCreate={(newOption) => {
            params.setDefaultValues({
              ...createUpdateProps?.defaultValues,
              name: newOption?.inputValue || createUpdateProps?.defaultValues?.name || undefined,
            });
            params.setIsCreate(true);
            params.onCreateStart && params.onCreateStart();
          }}
        />
      )}
      renderCreateUpdate={(params) => (
        <ProductLocationCreateUpdate
          defaultValues={params.defaultValues}
          onCreate={params.onCreate}
          onSave={(newValue) => {
            params.setIsCreate(false);
          }}
        />
      )}
      renderCreateUpdateModal={(params) => (
        <ProductLocationCreateUpdateModal
          open={params.isCreate}
          onClose={() => params.setIsCreate(false)}
          createUpdateProps={{
            defaultValues: params.defaultValues,
            onCreate: params.onCreate,
            onSave: (newValue) => {
              params.setIsCreate(false);
            },
          }}
        />
      )}
    />
  );
}

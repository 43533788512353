import { Alert, Button, Chip, Stack, TypographyProps } from "@mui/material";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import {
  AppPermission,
  AssetSubscriptionPlanDto,
  DataUpdatesHubClientMethodName,
  EntityType,
} from "@/core/api/generated";
import { useHistory } from "react-router";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import AssetSubscriptionPlanMenu from "../AssetSubscriptionPlanMenu";
import AssetSubscriptionsTabContent from "./Tabs/AssetSubscriptionsTabContent";
import AvailabilityTabContent from "./Tabs/AvailabilityTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import PoolsTabContent from "./Tabs/PoolsTabContent";
import ProductLocationsTabContent from "./Tabs/ProductLocationsTabContent";

export enum AssetSubscriptionPlanViewPageTabs {
  Overview = "Overview",
  ProductLocations = "ProductLocations",
  Availability = "Availability",
  Pools = "Pools",
  AssetSubscriptions = "AssetSubscriptions",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<AssetSubscriptionPlanDto> {
  assetSubscriptionPlanId: string | null | undefined;
  assetSubscriptionPlan: AssetSubscriptionPlanDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function AssetSubscriptionPlanView({
  assetSubscriptionPlanId,
  assetSubscriptionPlan,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.AssetSubscriptionPlan),
      viewVariant: displayProps.viewVariant,
    },
  });

  const assetSubscriptionPlanRequest = useApiRequest(
    apiClient.assetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionPlanId: assetSubscriptionPlanId!,
    },
    {
      deps: [assetSubscriptionPlanId],
      skip: !assetSubscriptionPlanId || !!assetSubscriptionPlan,
    },
  );
  assetSubscriptionPlan = assetSubscriptionPlan || assetSubscriptionPlanRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.AssetSubscriptionPlan,
        assetSubscriptionPlan?.id || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      assetSubscriptionPlanRequest.handleEntityChanged(data);
    },
  });
  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        assetSubscriptionPlan && {
          idValue: assetSubscriptionPlan.id!,
          newTitle: assetSubscriptionPlan.localNumber || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.AssetSubscriptionPlan}
      entityId={assetSubscriptionPlanId}
      entity={assetSubscriptionPlan}
      entityRequest={assetSubscriptionPlanRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          <>
            {displayProps.header && (
              <DetailedViewPageHeader
                image={undefined}
                title={
                  <>
                    {`Asset subscription plan ${assetSubscriptionPlan?.name} (${assetSubscriptionPlan?.localNumber})`}{" "}
                    {assetSubscriptionPlan?.isFree && (
                      <Chip variant='outlined' size='small' color='success' label='Free' />
                    )}
                  </>
                }
                titleProps={{
                  to: headerProps?.withLink
                    ? ROUTE_PATH.ASSET_SUBSCRIPTION_PLAN_VIEW(assetSubscriptionPlan?.id)
                    : undefined,
                  typographyProps: headerProps?.typographyProps,
                }}
                primaryActions={
                  displayProps.actions && (
                    <AuthorizedElement permissions={[AppPermission.AssetSubscriptionPlanManage]}>
                      <Button
                        component={AppLink}
                        to={ROUTE_PATH.ASSET_SUBSCRIPTION_PLAN_EDIT(assetSubscriptionPlan?.id)}
                        variant='outlined'
                        color='secondary'
                        size='medium'
                        startIcon={<AppIcon of='edit' />}
                      >
                        Edit
                      </Button>
                    </AuthorizedElement>
                  )
                }
                secondaryActions={
                  displayProps.actions &&
                  assetSubscriptionPlan && (
                    <AssetSubscriptionPlanMenu
                      entity={assetSubscriptionPlan}
                      onDelete={() => history.goBack()}
                      onUpdate={(newValue) =>
                        newValue
                          ? assetSubscriptionPlanRequest.replaceData(newValue)
                          : assetSubscriptionPlanRequest.refetch()
                      }
                      displayProps={{
                        actions: {
                          edit: false,
                          delete: true,
                          createAssetSubscription: true,
                        },
                      }}
                    />
                  )
                }
              />
            )}
            {!assetSubscriptionPlan?.isEnabled && (
              <Alert severity='warning'>
                This plan is disabled and not visible to end fleet customers.
              </Alert>
            )}
          </>
        }
      >
        <Stack spacing={2}>
          <PageTabs
            tabIdsDefinition={AssetSubscriptionPlanViewPageTabs}
            defaultTabId={AssetSubscriptionPlanViewPageTabs.Overview}
            commonViewParams={commonViewParams}
            tabs={[
              {
                label: "Overview",
                value: AssetSubscriptionPlanViewPageTabs.Overview,
                isHideable: false,
              },
              {
                label: "Sales locations",
                value: AssetSubscriptionPlanViewPageTabs.ProductLocations,
              },
              { label: "Availability", value: AssetSubscriptionPlanViewPageTabs.Availability },
              { label: "Pools", value: AssetSubscriptionPlanViewPageTabs.Pools },
              {
                label: "Asset subscriptions",
                value: AssetSubscriptionPlanViewPageTabs.AssetSubscriptions,
              },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              assetSubscriptionPlan && (
                <>
                  {activeTab === AssetSubscriptionPlanViewPageTabs.Overview && (
                    <OverviewTabContent
                      assetSubscriptionPlan={assetSubscriptionPlan}
                      onUpdate={(newValue) => assetSubscriptionPlanRequest.replaceData(newValue)}
                    />
                  )}
                  {activeTab === AssetSubscriptionPlanViewPageTabs.ProductLocations && (
                    <ProductLocationsTabContent assetSubscriptionPlan={assetSubscriptionPlan} />
                  )}
                  {activeTab === AssetSubscriptionPlanViewPageTabs.Availability && (
                    <AvailabilityTabContent assetSubscriptionPlan={assetSubscriptionPlan} />
                  )}
                  {activeTab === AssetSubscriptionPlanViewPageTabs.Pools && (
                    <PoolsTabContent assetSubscriptionPlan={assetSubscriptionPlan} />
                  )}
                  {activeTab === AssetSubscriptionPlanViewPageTabs.AssetSubscriptions && (
                    <AssetSubscriptionsTabContent assetSubscriptionPlan={assetSubscriptionPlan} />
                  )}
                </>
              )
            }
          </PageTabs>
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}

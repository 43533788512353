import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  DamageCostEvaluationDto,
  DamageCostEvaluationItemDto,
  DamageCostEvaluationItemReferenceDto,
  DamageCostEvaluationReferenceDto,
} from "@/core/api/generated";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getDamageCostEvaluationTitle } from "./DamageCostEvaluationAutocomplete";

export interface DamageCostEvaluationInlineProps extends BaseEntityInlineInheritableProps {
  entity: DamageCostEvaluationDto | DamageCostEvaluationReferenceDto | null | undefined;
  entityId?: string | null;
  item?: DamageCostEvaluationItemDto | DamageCostEvaluationItemReferenceDto;
  itemId?: string | null;
}

export default function DamageCostEvaluationInline({
  entity,
  entityId,
  item,
  itemId,
  ...otherProps
}: DamageCostEvaluationInlineProps) {
  const request = useApiRequest(
    apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      damageCostEvaluationId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as DamageCostEvaluationDto;
  const ref = entity as DamageCostEvaluationReferenceDto;

  item = item || (itemId ? full?.items?.find((x) => x.id === itemId) : undefined);

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='damageCostEvaluation' inText />}
      content={
        <>
          {full && getDamageCostEvaluationTitle(full)}
          {item && <> (#{item.localNumber})</>}
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Damage cost evaluation"}
    />
  );
}

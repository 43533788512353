import { Box, Stack, SxProps, Theme } from "@mui/material";

import { ApiEnumName, ApiEnumValue } from "@/common/services/enum";
import { ReactNode } from "react";
import InlineApiEnumValue, { InlineApiEnumValueProps } from "./InlineApiEnumValue";

export interface InlineApiEnumValueListProps<TEnumName extends ApiEnumName> {
  type: InlineApiEnumValueProps<TEnumName>["type"];
  values: Array<ApiEnumValue<TEnumName>> | null | undefined;
  direction?: "row" | "column";
  separator?: ReactNode;
  valueProps?: Partial<Omit<InlineApiEnumValueProps<TEnumName>, "type" | "value">>;
  sx?: SxProps<Theme>;
}

export default function InlineApiEnumValueList<TEnumName extends ApiEnumName>({
  type,
  values,
  direction = "column",
  separator,
  valueProps,
  sx,
}: InlineApiEnumValueListProps<TEnumName>) {
  if (!values) {
    return null;
  }

  return (
    <Stack
      component='span'
      direction={direction}
      spacing={0.5}
      sx={{
        display: "inline-flex",
        ...sx,
      }}
    >
      {values.map((value, i) => (
        <Box key={i} component='span'>
          <InlineApiEnumValue {...valueProps} type={type} value={value} />
          {i !== values.length - 1 && direction === "row" && <>{separator || <>, </>}</>}
        </Box>
      ))}
    </Stack>
  );
}

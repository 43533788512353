import { FileUploadSpecDto } from "@/core/api/generated";
import _ from "lodash";

interface IFileFormat {
  name: string;
  mimeType: string;
  extension: string;
}

const fileInputAcceptSeparator = ",";

export class MimeTypeHelper {
  private static readonly _spreadsheetFormats: IFileFormat[] = [
    {
      name: "Excel",
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      extension: "xlsx",
    },
    {
      name: "OpenDocument Spreadsheet",
      mimeType: "application/vnd.oasis.opendocument.spreadsheet",
      extension: "ods",
    },
    { name: "CSV", mimeType: "text/csv", extension: "csv" },
    { name: "Excel 97-2003", mimeType: "application/vnd.ms-excel", extension: "xls" },
  ];

  public static getSpreadsheetFormats(): IFileFormat[] {
    return this._spreadsheetFormats;
  }

  public static isImage(mimeType: string | null | undefined): boolean {
    return !!mimeType && /^image\/.+$/i.test(mimeType);
  }

  /**
   * Builds valid accept attribute of <input type="file" accept={...} />.
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   */
  public static buildFileInputAccept(params: {
    /** Extension that start with period '.' char (.jpg, .pdf, etc). */
    fileExtensions?: string[] | null;
    mimeTypes?: string[] | null;
  }): string {
    const parts = [...(params.fileExtensions || []), ...(params.mimeTypes || [])];
    const newAccept = _.uniq(parts).join(fileInputAcceptSeparator);
    return newAccept;
  }

  /**
   * Builds valid accept attribute of <input type="file" accept={...} />.
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   */
  public static buildFileInputAcceptFromUploadSpec(
    accept: string,
    uploadSpec: FileUploadSpecDto,
  ): string {
    const newAccept = accept
      ? accept
          ?.split(fileInputAcceptSeparator)
          .filter((part) => {
            const regex = new RegExp(part.replace("/", "/").replace(".", "."));
            return (
              uploadSpec?.uploadPermittedExtensions?.some((x) => regex.test(x)) ||
              uploadSpec?.uploadPermittedContentTypes?.some((x) => regex.test(x))
            );
          })
          .join(fileInputAcceptSeparator)
      : [
          ...(uploadSpec?.uploadPermittedContentTypes || []),
          ...(uploadSpec?.uploadPermittedExtensions || []),
        ].join(fileInputAcceptSeparator);
    return newAccept;
  }
}

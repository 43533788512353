import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import EntityCommentsChat from "@/App/MainAppView/Chats/components/EntityCommentsChat";
import { DamageDetectionDto, EntityType } from "@/core/api/generated";
import { Stack } from "@mui/material";

interface Props {
  damageDetection: DamageDetectionDto;
}

export default function CommentsTabContent({ damageDetection }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <PageTabHeader title={"Comments"} />
        <EntityCommentsChat
          entityId={damageDetection.id!}
          entityType={EntityType.DamageDetection}
        />
      </Stack>
    </PageTabContent>
  );
}

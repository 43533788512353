import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import UserAddLocationModal from "@/App/MainAppView/Management/Users/components/UserAddLocationModal";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { LocationDto, UserDto } from "@/core/api/generated";
import { Button, IconButton, ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import DepartmentInline from "../../../Department/DepartmentInline";
import GeneralAddressDisplay from "../../../General/Display/GeneralAddressDisplay";

interface Props {
  user: UserDto;
}

export default function LocationsTabContent({ user }: Props) {
  const [isAddLocationModalOpen, setIsAddLocationModalOpen] = useState(false);
  const [isRemoveLocationModalOpen, setIsRemoveLocationModalOpen] = useState(false);
  const [location, setLocation] = useState<LocationDto | undefined>(undefined);

  const userLocationsRequest = useApiRequest(
    apiClient.usersApi.apiV1UsersUserIdLocationsGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      userId: user.id!,
    },
    {
      skip: !user?.id,
    },
  );
  const userLocations = userLocationsRequest?.data;

  const handleUserDeletedFromLocation = async () => {
    if (!user || !location) {
      return;
    }
    try {
      await apiClient.usersApi.apiV1UsersUserIdLocationsLocationIdDelete({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        userId: user.id!,
        locationId: location.id!,
      });
      enqueueSnackbar("User was successfully removed from the department location.", {
        variant: "success",
      });
      setIsRemoveLocationModalOpen(false);
      setLocation(undefined);
      userLocationsRequest.refetch();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  };

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Locations"}
            secondaryAction={
              <Button
                variant='contained'
                onClick={() => {
                  setIsAddLocationModalOpen(true);
                }}
              >
                Add location
              </Button>
            }
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <DataTabular
            columns={[
              {
                field: "localNumber",
                title: "Number",
                flex: 1,
                renderCell: (item) => <>{item.localNumber}</>,
              },
              {
                field: "name",
                title: "Name",
                flex: 1,
                renderCell: (item) => <>{item.name}</>,
              },
              {
                field: "address",
                title: "Address",
                flex: 2,
                renderCell: (item) => (
                  <AppTypography ellipsing={{ enabled: true }} component='div'>
                    <GeneralAddressDisplay address={item.address} direction='row' />
                  </AppTypography>
                ),
              },
              {
                field: "department",
                title: "Department",
                flex: 1,
                renderCell: (item) => <DepartmentInline entity={item.department} />,
              },
            ]}
            rows={userLocations}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.LOCATION_VIEW(item.id!)}
            renderRowAction={({ item }) => (
              <MenuWithTrigger
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <MenuItem component={AppLink} to={ROUTE_PATH.LOCATION_VIEW(item.id!)}>
                  <ListItemIcon>
                    <AppIcon of='view' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>View location</ListItemText>
                </MenuItem>
                <MenuItem component={AppLink} to={ROUTE_PATH.DEPARTMENT_VIEW(item.id!)}>
                  <ListItemIcon>
                    <AppIcon of='view' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>View department</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setLocation(item);
                    setIsRemoveLocationModalOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='remove' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Remove from location</ListItemText>
                </MenuItem>
              </MenuWithTrigger>
            )}
          />

          {/* Add user to location */}
          {user && (
            <UserAddLocationModal
              open={isAddLocationModalOpen}
              user={user}
              onClose={() => {
                setIsAddLocationModalOpen(false);
              }}
              onAdded={() => {
                userLocationsRequest.refetch();
              }}
            />
          )}
          {/* Remove user from location */}
          {user && location && (
            <ConfirmationModal
              title='Remove user from the location?'
              body={
                <>
                  {`You're going to remove user`}{" "}
                  <strong>{user.personName?.name || user.email}</strong> from the location{" "}
                  <strong>{location?.name}</strong>.{`This action can't be undone.`}
                </>
              }
              open={isRemoveLocationModalOpen}
              onClose={() => setIsRemoveLocationModalOpen(false)}
              onCancel={() => setIsRemoveLocationModalOpen(false)}
              onConfirm={async () => {
                await handleUserDeletedFromLocation();
                userLocationsRequest.refetch();
              }}
            />
          )}
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}

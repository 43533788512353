import { Button, IconButton, IconButtonProps, SxProps, Theme, Tooltip } from "@mui/material";

import { renderIf } from "@/common/helpers/render/renderIf";
import { ReactElement, ReactNode } from "react";
import { useHistory } from "react-router";
import AppIcon from "../Icons/AppIcon";

interface OwnProps {
  title?: string | ReactNode;
  tooltipTitle?: string;
  size?: IconButtonProps["size"];
  sx?: SxProps<Theme>;
}

type Props = OwnProps;

export default function GoBackButton({ title, tooltipTitle, size, sx }: Props) {
  const history = useHistory();
  return (
    <Tooltip title={tooltipTitle || "Go back"}>
      {
        renderIf()
          .if(!!title)
          .then(
            <Button
              variant='text'
              color='secondary'
              startIcon={<AppIcon of='goBack' />}
              size={size || "medium"}
              onClick={() => history.goBack()}
              sx={{ ...sx }}
            >
              {title}
            </Button>,
          )
          .else(
            <IconButton size={size || "medium"} onClick={() => history.goBack()} sx={{ ...sx }}>
              <AppIcon of='goBack' />
            </IconButton>,
          )
          .render()! as ReactElement
      }
    </Tooltip>
  );
}

import { Button, LinearProgress, Stack, TypographyProps } from "@mui/material";

import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  PoolDto,
} from "@/core/api/generated";
import { useHistory } from "react-router";
import ItemsTabContent from "../../../../../common/components/Entity/Pool/View/Tabs/ItemsTabContent";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import PoolMenu from "../PoolMenu";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import StateTabContent from "./Tabs/StateTabContent";

export enum PoolViewPageTabs {
  Overview = "Overview",
  State = "State",
  Items = "Items",
  Spec = "Spec",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<PoolDto> {
  poolId: string | null | undefined;
  pool: PoolDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function PoolView({
  poolId,
  pool,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.Pool),
      viewVariant: displayProps.viewVariant,
    },
  });

  const poolRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsPoolIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolId: poolId!,
    },
    {
      deps: [poolId],
      skip: !poolId || !!pool,
    },
  );
  pool = pool || poolRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Pool, pool?.id || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      poolRequest.handleEntityChanged(data);
    },
  });
  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        pool && {
          idValue: pool.id!,
          newTitle: pool.localNumber || "",
        }) ||
      undefined,
  });

  if (poolRequest.isLoading) {
    return <LinearProgress />;
  }
  if (!pool && poolRequest.isEnded) {
    return <EntityNotFoundAlert />;
  }

  return (
    <BaseEntityView
      entityType={EntityType.Pool}
      entityId={poolId}
      entity={pool}
      entityRequest={poolRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`Pool ${pool?.localNumber}, ${pool?.name}`}
              titleProps={{
                to: headerProps?.withLink ? ROUTE_PATH.POOL_VIEW(pool?.id) : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.PoolManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.POOL_EDIT(pool?.id)}
                      variant='outlined'
                      color='secondary'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                pool &&
                displayProps.actions && (
                  <PoolMenu
                    onDelete={() => history.goBack()}
                    onUpdate={(newValue) =>
                      newValue ? poolRequest.replaceData(newValue) : poolRequest.refetch()
                    }
                    entity={pool}
                    displayProps={{ actions: { edit: false, delete: true } }}
                  />
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <PageTabs
            viewVariant={displayProps.viewVariant}
            tabIdsDefinition={PoolViewPageTabs}
            defaultTabId={PoolViewPageTabs.Overview}
            commonViewParams={commonViewParams}
            tabs={[
              { label: "Overview", value: PoolViewPageTabs.Overview, isHideable: false },
              { label: "State", value: PoolViewPageTabs.State },
              { label: "Items", value: PoolViewPageTabs.Items },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              pool && (
                <>
                  {activeTab === PoolViewPageTabs.Overview && <OverviewTabContent pool={pool} />}
                  {activeTab === PoolViewPageTabs.State && <StateTabContent pool={pool} />}
                  {activeTab === PoolViewPageTabs.Items && <ItemsTabContent pool={pool} />}
                </>
              )
            }
          </PageTabs>
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}

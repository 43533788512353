import { Chip, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { ContractDto, ContractSearchPaginatedDto, ContractType } from "@/core/api/generated";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import { getCustomerOptionTitle } from "../Customer/CustomerAutocomplete";
import { getVehicleOptionTitle } from "../Vehicle/VehicleAutocomplete";

export interface ContractAutocompleteOption extends BaseAutocompleteOption<ContractDto> {
  subtitle?: string;
}

export const getContractOptionTitle = (data: ContractDto): string =>
  StringHelper.joinIntoString(
    [
      data.externalNumber || data.localNumber,
      data.vehicle && getVehicleOptionTitle(data.vehicle),
      data.customer && getCustomerOptionTitle(data.customer),
    ],
    ", ",
    {
      skipEmpty: true,
    },
  );

export const contractToOption = (data: ContractDto): ContractAutocompleteOption => ({
  id: data.id!,
  title: getContractOptionTitle(data),
  subtitle: data.externalNumber!,
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface ContractAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<ContractDto> {
  searchFilters?: Partial<Omit<ContractSearchPaginatedDto, "search" | "includeIds">>;
}

export default function ContractAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: ContractAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={contractToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.contractsApi.apiV1ContractsSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          contractSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          contractSearchPaginatedDto: {
            ...params.contractSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Contract'
      placeholder='Search for contract...'
      renderOption={(props, option: ContractAutocompleteOption) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='contract' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                  <Typography component='div' variant='body1'>
                    {option.subtitle}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body1'>
                      {option.data && option.data.type !== ContractType.None && (
                        <Chip
                          size='small'
                          color='secondary'
                          variant={"outlined"}
                          label={
                            <InlineApiEnumValue type='ContractType' value={option.data.type} />
                          }
                          sx={{ mr: 1 }}
                        />
                      )}
                      {option.data && (
                        <Chip
                          size='small'
                          color='secondary'
                          variant={"outlined"}
                          label={
                            <InlineApiEnumValue
                              type='ContractStage'
                              value={option.data.stageHistory?.stage}
                            />
                          }
                          sx={{ mr: 1 }}
                        />
                      )}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

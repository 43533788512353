import { Button, Stack, TypographyProps } from "@mui/material";

import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  UserDto,
} from "@/core/api/generated";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppAvatar from "@/common/components/Avatar/AppAvatar";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import { useHistory } from "react-router";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import UserMenu from "../UserMenu";
import DepartmentsTabContent from "./Tabs/DepartmentsTabContent";
import LocationsTabContent from "./Tabs/LocationsTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import RolesAndPermissionsTabContent from "./Tabs/RolesAndPermissionsTabContent";
import SuspensionInfoTabContent from "./Tabs/SuspensionInfoTabContent";

export enum UserViewPageTabs {
  Overview = "Overview",
  Departments = "Departments",
  Locations = "Locations",
  RolesAndPermissions = "RolesAndPermissions",
  SuspensionInfo = "SuspensionInfo",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<UserDto> {
  userId: string | null | undefined;
  user: UserDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function UserView({
  userId,
  user,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const currentTenant = useCurrentTenant();
  const history = useHistory();

  const userRequest = useApiRequest(
    apiClient.usersApi.apiV1UsersUserIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      userId: userId!,
    },
    {
      deps: [userId],
      skip: !userId || !!user,
    },
  );
  user = user || userRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.User, user?.id || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      userRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        user && {
          idValue: user.id!,
          newTitle: user.personName?.name || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.User}
      entityId={userId}
      entity={user}
      entityRequest={userRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={<AppAvatar user={user} size={100} />}
              title={
                <>
                  {`User ${user?.personName?.name}`}
                  <EntityChipList entity={user} variant='normal' />
                </>
              }
              titleProps={{
                to: headerProps?.withLink ? ROUTE_PATH.USER_VIEW(user?.id) : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.TenantUserManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.TENANT_USER_EDIT(user?.id)}
                      variant='outlined'
                      color='secondary'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                displayProps.actions &&
                user && (
                  <UserMenu
                    onDelete={() => history.goBack()}
                    onUpdate={(newValue) =>
                      newValue ? userRequest.replaceData(newValue) : userRequest.refetch()
                    }
                    entity={user}
                  />
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <PageTabs
            tabIdsDefinition={UserViewPageTabs}
            defaultTabId={UserViewPageTabs.Overview}
            tabs={[
              { label: "Overview", value: UserViewPageTabs.Overview },
              { label: "Departments", value: UserViewPageTabs.Departments },
              { label: "Locations", value: UserViewPageTabs.Locations },
              { label: "Roles and permissions", value: UserViewPageTabs.RolesAndPermissions },
              { label: "Suspension info", value: UserViewPageTabs.SuspensionInfo },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              user && (
                <>
                  {activeTab === UserViewPageTabs.Overview && <OverviewTabContent user={user} />}
                  {activeTab === UserViewPageTabs.Departments && (
                    <DepartmentsTabContent user={user} />
                  )}
                  {activeTab === UserViewPageTabs.Locations && <LocationsTabContent user={user} />}
                  {activeTab === UserViewPageTabs.RolesAndPermissions && (
                    <RolesAndPermissionsTabContent user={user} />
                  )}
                  {activeTab === UserViewPageTabs.SuspensionInfo && (
                    <SuspensionInfoTabContent user={user} />
                  )}
                </>
              )
            }
          </PageTabs>
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}

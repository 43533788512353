import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import RepairOperationPaginatedList from "@/common/components/Entity/RepairOperation/ListView/RepairOperationPaginatedList";

export default function RepairOperationsPage() {
  return (
    <>
      <RepairOperationPaginatedList />
    </>
  );
}

import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AppIconButton from "@/common/components/Button/AppIconButton";
import AppIcon from "@/common/components/Icons/AppIcon";
import { GeneralAttachedTagsDto, TagEntityType } from "@/core/api/generated";
import { Box, Fade, Stack } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import GeneralAttachedTagsOfEntityEditModal from "./GeneralAttachedTagsOfEntityEditModal";
import GeneralTagDisplay from "./GeneralTagDisplay";
import TagList from "./TagList";

export interface OwnProps {
  tags: GeneralAttachedTagsDto | null | undefined;
  defaultIsFolded?: boolean;
  /** Set to non-nullable value to enable edit. */
  edit?: {
    entityType: TagEntityType;
    entityId: string | null | undefined;
    onSaved: (newTags: GeneralAttachedTagsDto | null | undefined) => void | Promise<void>;
  };
}

type Props = OwnProps;

export default function GeneralAttachedTagsDisplay({ tags, defaultIsFolded = false, edit }: Props) {
  const [isFolded, setIsFolded] = useState(false);
  const [isEditTagsModalOpen, setIsEditTagsModalOpen] = useState(defaultIsFolded);

  return (
    <>
      <Box>
        <Stack direction='row' spacing={1} alignItems='start'>
          {_.isEmpty(tags?.tags) && <NoDataAlert variant='inline' title='No tags' />}

          <Fade in={!isFolded} unmountOnExit>
            <Box>
              <TagList
                tags={tags?.tags?.map((tag, i) => <GeneralTagDisplay key={i} tag={tag} />) || []}
                maxVisibleItems={undefined}
                endAction={
                  edit?.entityId && (
                    <AppIconButton
                      variant='outlined'
                      size='extraSmall'
                      color='secondary'
                      tooltipProps={{
                        title: "Edit tags",
                      }}
                      onClick={(e) => {
                        setIsEditTagsModalOpen(true);
                      }}
                    >
                      <AppIcon of='edit' />
                    </AppIconButton>
                  )
                }
              />
            </Box>
          </Fade>
        </Stack>
      </Box>
      {edit && (
        <GeneralAttachedTagsOfEntityEditModal
          open={isEditTagsModalOpen}
          onClose={() => setIsEditTagsModalOpen(false)}
          entityType={edit.entityType}
          entityId={edit.entityId || ""}
          onSaved={(newValue) => {
            edit.onSaved && edit.onSaved(newValue);
          }}
        />
      )}
    </>
  );
}

import { Box, Chip, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { enumService } from "@/common/services/enum";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { AssetDto, AssetSearchPaginatedDto } from "@/core/api/generated";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import { getAccessoryOptionTitle } from "../Accessory/AccessoryAutocomplete";
import GeneralStrictEntityRelationInline from "../General/Display/GeneralStrictEntityRelationInline";
import { getVehicleOptionTitle } from "../Vehicle/VehicleAutocomplete";
import AssetIcon from "./AssetIcon";

type AssetAutocompleteOption = BaseAutocompleteOption<AssetDto>;

export const getAssetOptionTitle = (data: AssetDto): string =>
  (data.entity?.accessory && getAccessoryOptionTitle(data.entity.accessory)) ||
  (data.entity?.accessoryFullRef && getAccessoryOptionTitle(data.entity.accessoryFullRef)) ||
  (data.entity?.vehicle && getVehicleOptionTitle(data.entity.vehicle)) ||
  (data.entity?.vehicleFullRef && getVehicleOptionTitle(data.entity.vehicleFullRef)) ||
  StringHelper.joinIntoString(
    [data.localNumber, data.name, enumService.getEnumValueName("AssetEntityType", data.entityType)],
    ", ",
    {
      skipEmpty: true,
    },
  );

export const entityToOption = (data: AssetDto): AssetAutocompleteOption => ({
  id: data.id!,
  title: getAssetOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface AssetAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<AssetDto> {
  searchFilters?: Partial<Omit<AssetSearchPaginatedDto, "search" | "includeIds">>;
}

export default function AssetAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: AssetAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={entityToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.assetsApi.apiV1AssetsSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          assetSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          assetSearchPaginatedDto: {
            ...params.assetSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Asset'
      placeholder='Search for asset...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AssetIcon entity={option.data} />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    <GeneralStrictEntityRelationInline
                      value={option.data?.entity}
                      inlineProps={{
                        withIcon: false,
                      }}
                    />
                  </Typography>
                </>
              }
              secondary={
                <Typography component='div' variant='body2' color='secondary'>
                  {option.data && (
                    <Box>
                      <Stack direction='row' spacing={1}>
                        <Chip
                          variant='outlined'
                          size='small'
                          color='secondary'
                          label={option.data.localNumber}
                        />
                        {option.data.entityType && (
                          <Chip
                            variant='outlined'
                            size='small'
                            color='secondary'
                            label={
                              <InlineApiEnumValue
                                type='AssetEntityType'
                                value={option.data.entityType}
                              />
                            }
                          />
                        )}
                      </Stack>
                    </Box>
                  )}
                </Typography>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}

import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { Formik, getIn } from "formik";
import moment, { Moment } from "moment";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  ContractDto,
  ContractStage,
  EntityType,
  VehicleArea,
  VisualInspectionCreateDto,
  VisualInspectionDto,
  VisualInspectionItemDto,
  VisualInspectionItemInputDto,
  VisualInspectionUpdateDto,
} from "@/core/api/generated";

import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { ArrayHelper } from "@/common/helpers/array";
import { TextHelper } from "@/common/helpers/text";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { enumService } from "@/common/services/enum";
import { BaseFormikValues } from "@/common/ts/error";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useHistory } from "react-router";
import NoDataAlert from "../../AppAlerts/NoDataAlert";
import DropdownButton from "../../Button/DropdownButton";
import FoldableBlock from "../../Display/FoldableBlock";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import FuelLevelInput from "../../Form/Input/FuelLevelInput";
import AppIcon from "../../Icons/AppIcon";
import ContractAutocompleteOrCreate from "../Contract/ContractAutocompleteOrCreate";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  vehicleId?: VisualInspectionCreateDto["vehicleId"];
  contractId?: VisualInspectionCreateDto["contractId"];
  departmentId?: VisualInspectionCreateDto["departmentId"];
  locationId?: VisualInspectionCreateDto["locationId"];
  tenantRequestsMeta?: VisualInspectionCreateDto["tenantRequestsMeta"];
};

export interface VisualInspectionCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<VisualInspectionDto, DefaultValues> {
  visualInspectionId?: string;
}

export type VisualInspectionCreateUpdateProps = VisualInspectionCreateUpdateOwnProps;

export default function VisualInspectionCreateUpdate({
  visualInspectionId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: VisualInspectionCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();
  const history = useHistory();
  const isCreate = !visualInspectionId;
  const isEdit = !!visualInspectionId;

  const [contract, setContract] = useState<ContractDto | undefined>(undefined);
  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);

  const visualInspectionRequest = useApiRequest(
    apiClient.visualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      visualInspectionId: visualInspectionId!,
    },
    {
      deps: [visualInspectionId],
      skip: !visualInspectionId,
    },
  );
  const visualInspection = visualInspectionRequest?.data;

  const createMetaRequest = useApiRequest(
    apiClient.visualInspectionsApi.apiV1VisualInspectionsCreateMetaGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {
      deps: [],
    },
  );
  const createMeta = createMetaRequest?.data;

  const { departments, locations } = useUserAffiliation();

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: visualInspection && {
      idValue: visualInspection.id!,
      newTitle: visualInspection.localNumber || "",
    },
  });

  const vehicleIdComputed = defaultValues?.vehicleId || visualInspection?.vehicle?.id;
  const contractIdComputed = defaultValues?.contractId || visualInspection?.contract?.id;

  const settingsComputed = useMemo(
    () =>
      createMeta?.settings
        ? {
            ...createMeta.settings,
            perAreaMap: _.chain(createMeta.settings.perArea)
              .keyBy((x) => x.area!)
              .mapValues((x) => x)
              .valueOf(),
          }
        : undefined,
    [createMeta],
  );

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.VisualInspection}
      entityId={visualInspectionId}
      entity={visualInspection}
      entityRequest={visualInspectionRequest}
      isIniting={createMetaRequest.isLoading || !createMeta}
    >
      <Formik<
        BaseFormikValues &
          Omit<VisualInspectionCreateDto, "items"> &
          Omit<VisualInspectionUpdateDto, "items"> & {
            vehicle?: VisualInspectionDto["vehicle"];
            initialAttachments: VisualInspectionDto["attachments"];
            items?: Array<
              VisualInspectionItemInputDto & {
                initialAttachments?: VisualInspectionItemDto["attachments"];
              }
            >;
          }
      >
        initialValues={{
          // temporary
          departmentId:
            defaultValues?.departmentId ||
            (visualInspection?.departmentIds || []).at(0) ||
            (departments && departments[0] && departments[0].id) ||
            undefined,
          // temporary
          locationId:
            defaultValues?.locationId ||
            (visualInspection?.locationIds || []).at(0) ||
            (locations && locations[0] && locations[0].id) ||
            undefined,
          contractId: visualInspection?.contract?.id || defaultValues?.contractId || undefined,
          vehicleId: visualInspection?.vehicle?.id || defaultValues?.vehicleId || undefined,
          inspectedAt:
            visualInspection?.inspectedAt || moment().format(DATETIME_FORMATS.DISPLAY_DATETIME),
          inspector: {
            isCurrentUser: true,
            ...(visualInspection?.inspector || {}),
            phoneNumber: visualInspection?.inspector?.phoneNumber,
          },
          mileage: visualInspection?.mileage ?? undefined,
          fuelLevel: visualInspection?.fuelLevel ?? undefined,
          notes: visualInspection?.notes || "",
          initialAttachments: visualInspection?.attachments || undefined,
          attachments: !_.isEmpty(visualInspection?.attachments)
            ? visualInspection?.attachments
            : undefined,
          items:
            visualInspection?.items?.map((x) => ({
              ...x,
              initialAttachments: x?.attachments || undefined,
              attachments: !_.isEmpty(x?.attachments) ? x.attachments : undefined,
            })) ||
            createMeta?.settings?.perArea
              ?.filter((x) => x.isRequired)
              ?.map((x) => ({
                area: x.area!,
              })) ||
            [VehicleArea.Exterior, VehicleArea.Interior].map((x) => ({ area: x })),
          settings: visualInspection?.settings || createMeta?.settings || {}, // must be passed
          tenantRequestsMeta:
            visualInspection?.tenantRequestsMeta || defaultValues?.tenantRequestsMeta || undefined,
          tags: visualInspection?.tags || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // contractId: Yup.string().required("Contract is required"),
          // vehicleId: Yup.string().required("Vehicle is required"),
          // type: Yup.string().required("Type is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          const values2: typeof values = {
            ...values,
            contractId: contractIdComputed,
            inspectedAt: values.inspectedAt ? moment(values.inspectedAt).utc().format() : undefined,
          };

          try {
            if (isCreate) {
              const response = await apiClient.visualInspectionsApi.apiV1VisualInspectionsPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                visualInspectionCreateDto: { ...values2 },
              });
              enqueueSnackbar("Visual inspection created.", { variant: "success" });
              onCreate && (await onCreate(response.data));
              onSave && (await onSave(response.data));
            } else {
              const response =
                await apiClient.visualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdPut({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  visualInspectionId,
                  visualInspectionUpdateDto: {
                    ...values2,
                  },
                });
              enqueueSnackbar("Visual inspection updated.", { variant: "success" });
              onUpdate && (await onUpdate(response.data));
              onSave && (await onSave(response.data));
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          const areasToSelect = enumService.getEnumValues("VehicleArea", {
            exceptNone: true,
            except: values.items?.map((x) => x.area || VehicleArea.None),
          });

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Stack spacing={2}>
                  <Box>
                    {/* Tags */}
                    <FormControl margin='dense' fullWidth>
                      <GeneralAttachedTagsInput
                        value={values.tags}
                        onChange={(newValue) => {
                          setFieldValue("tags", newValue);
                        }}
                      />
                      <FormHelperText error>
                        {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                          isIncludeNested: false,
                        })}
                      </FormHelperText>
                    </FormControl>

                    {!isCreate && (
                      <TextField
                        fullWidth
                        disabled
                        label='Inspection number'
                        margin='dense'
                        type='text'
                        value={visualInspection?.localNumber}
                        variant='outlined'
                      />
                    )}

                    <FormControl margin='dense' fullWidth error={Boolean(errors.vehicleId)}>
                      <VehicleAutocompleteOrCreate
                        autocompleteProps={{
                          required: true,
                          disabled: !isCreate || !_.isEmpty(vehicleIdComputed),
                          entityId: values.vehicleId,
                          isPreload: isCreate,
                          onChange: (newValue) => {
                            setFieldValue(`vehicleId`, newValue?.id);
                            if (newValue?.id) {
                              if (!values.departmentId) {
                                setFieldValue("departmentId", newValue?.departmentIds?.at(0));
                              }
                              if (!values.locationId) {
                                setFieldValue("locationId", newValue?.locationIds?.at(0));
                              }
                            }
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={(newValue) => {
                          setFieldValue(`vehicleId`, newValue?.id);
                          if (newValue?.id) {
                            if (!values.departmentId) {
                              setFieldValue("departmentId", newValue?.departmentIds?.at(0));
                            }
                            if (!values.locationId) {
                              setFieldValue("locationId", newValue?.locationIds?.at(0));
                            }
                          }
                        }}
                      />
                      <FormHelperText>{errors.vehicleId}</FormHelperText>
                    </FormControl>

                    {/* Entity affiliation */}
                    <EntityAffiliationInput
                      department={{
                        departmentId: values.departmentId,
                        onChange: (d) => {
                          setFieldValue("departmentId", d?.id);
                          setFieldValue("locationId", undefined);
                        },
                        error: errors.departmentId,
                      }}
                      location={{
                        locationId: values.locationId,
                        onChange: (l) => {
                          setFieldValue("locationId", l?.id);
                        },
                        searchFilters: { departmentId: values.departmentId },
                        createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                        error: errors.locationId,
                        disabled: !values.departmentId,
                      }}
                    />

                    {values.contractId && (
                      <FormControl margin='dense' fullWidth error={Boolean(errors.contractId)}>
                        <ContractAutocompleteOrCreate
                          autocompleteProps={{
                            disabled: true,
                            entityId: values.contractId,
                            isPreload: isCreate,
                            searchFilters: {
                              vehicleId: values.vehicleId || undefined,
                              excludeStage: ContractStage.Draft,
                            },
                            onChange: (newValue) => {
                              setFieldValue(`contractId`, newValue?.id);
                              setFieldValue(`contract`, newValue || undefined);
                            },
                          }}
                          createFormPlacement='modal'
                          onCreate={(newValue) => {
                            setFieldValue(`contractId`, newValue?.id);
                            setFieldValue(`contract`, newValue || undefined);
                          }}
                        />
                        <FormHelperText>{errors.contractId}</FormHelperText>
                      </FormControl>
                    )}

                    <FormControl
                      margin='dense'
                      fullWidth
                      error={Boolean(touched.inspectedAt && errors.inspectedAt)}
                    >
                      <MobileDateTimePicker
                        ampm={false}
                        label='Inspected at'
                        value={(values.inspectedAt && moment(values.inspectedAt)) || null}
                        format={DATETIME_FORMATS.DISPLAY_DATETIME}
                        onChange={(newValue: Moment | null) => {
                          setFieldValue("inspectedAt", newValue?.format() || null);
                        }}
                        slots={{ textField: (params) => <TextField {...params} /> }}
                      />
                      <FormHelperText>{touched.inspectedAt && errors.inspectedAt}</FormHelperText>
                    </FormControl>

                    <FormControl
                      margin='dense'
                      fullWidth
                      error={Boolean(touched.mileage && errors.mileage)}
                    >
                      <Typography component='div' gutterBottom>
                        Mileage
                      </Typography>
                      <Stack direction='row' spacing={2} sx={{ alignItems: "center" }}>
                        <AppIcon of='addRoad' />
                        <Input
                          name='mileage'
                          value={values.mileage || ""}
                          size='small'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            step: 1,
                            min: 0,
                            max: 10000000,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </Stack>
                      <FormHelperText>{touched.mileage && errors.mileage}</FormHelperText>
                    </FormControl>

                    <FormControl
                      margin='dense'
                      fullWidth
                      error={Boolean(touched.fuelLevel && errors.fuelLevel)}
                    >
                      <Typography component='div' gutterBottom>
                        Fuel level
                      </Typography>

                      <FuelLevelInput
                        onBlur={handleBlur}
                        onChange={(e, val) => setFieldValue("fuelLevel", val ?? undefined)}
                        value={values.fuelLevel ?? undefined}
                      />
                      <FormHelperText>{touched.fuelLevel && errors.fuelLevel}</FormHelperText>
                    </FormControl>
                  </Box>

                  {/* Items */}
                  <FoldableBlock
                    defaultIsFolded={false}
                    trigger={{
                      label: (
                        <Typography component='span' variant='h6'>
                          Inspections *
                        </Typography>
                      ),
                    }}
                  >
                    <Stack spacing={2}>
                      {(!values.items || values.items.length === 0) && (
                        <NoDataAlert title='No items yet' />
                      )}

                      <Stack direction='column' spacing={1}>
                        {values.items?.map((item, index) => {
                          return (
                            <Card key={index}>
                              <CardContent>
                                <Stack spacing={2}>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      gridTemplateColumns: "1fr 0fr",
                                      columnGap: 2,
                                    }}
                                  >
                                    <Typography component='span' variant='subtitle1'>
                                      <InlineApiEnumValue type='VehicleArea' value={item.area} />
                                    </Typography>

                                    <Stack direction='row'>
                                      <IconButton
                                        onClick={() =>
                                          setFieldValue(
                                            "items",
                                            ArrayHelper.removeByIndex(values.items, index),
                                          )
                                        }
                                      >
                                        <AppIcon of='delete' />
                                      </IconButton>
                                    </Stack>
                                  </Box>

                                  <Box>
                                    <FormControl fullWidth margin='dense'>
                                      <FileUploader
                                        multiple
                                        defaultFiles={
                                          values.items &&
                                          FileItem.createManyFrom(
                                            values.items[index].initialAttachments,
                                          )
                                        }
                                        onChange={(newFiles) =>
                                          setFieldValue(
                                            `items[${index}].attachments`,
                                            FileItem.toManyGeneralAttachmentInputDto(newFiles),
                                          )
                                        }
                                        onUploadStarted={() => {
                                          setIsAttachmentFilesUploading(true);
                                        }}
                                        onUploadFinished={() => {
                                          setIsAttachmentFilesUploading(false);
                                        }}
                                      />

                                      {item.area &&
                                        settingsComputed?.perAreaMap &&
                                        settingsComputed.perAreaMap[item.area] && (
                                          <FormHelperText>
                                            {!_.isNil(
                                              settingsComputed.perAreaMap[item.area].minPhotoCount,
                                            ) && (
                                              <Box>
                                                Min{" "}
                                                {
                                                  settingsComputed.perAreaMap[item.area]
                                                    .minPhotoCount
                                                }{" "}
                                                {TextHelper.pluralizeManual(
                                                  "photo",
                                                  settingsComputed.perAreaMap[item.area]
                                                    .minPhotoCount || 0,
                                                  "photos",
                                                )}{" "}
                                                *
                                              </Box>
                                            )}

                                            {!_.isNil(
                                              settingsComputed.perAreaMap[item.area]
                                                .minAttachmentCount,
                                            ) && (
                                              <Box>
                                                Min{" "}
                                                {
                                                  settingsComputed.perAreaMap[item.area]
                                                    .minAttachmentCount
                                                }{" "}
                                                {TextHelper.pluralizeManual(
                                                  "attachment",
                                                  settingsComputed.perAreaMap[item.area]
                                                    .minAttachmentCount || 0,
                                                  "attachments",
                                                )}{" "}
                                                *
                                              </Box>
                                            )}
                                          </FormHelperText>
                                        )}

                                      <FormHelperText error>
                                        {getIn(errors, `items[${index}].attachments`)}
                                      </FormHelperText>
                                    </FormControl>
                                  </Box>
                                </Stack>
                              </CardContent>
                            </Card>
                          );
                        })}
                      </Stack>

                      {errors.items && _.isString(errors.items) && (
                        <FormHelperText error>{errors.items}</FormHelperText>
                      )}

                      {/* Add item */}
                      <Box>
                        <DropdownButton
                          disabled={_.isEmpty(areasToSelect)}
                          buttonProps={{
                            color: "secondary",
                            size: "small",
                            variant: "outlined",
                            startIcon: <AppIcon of='add' />,
                          }}
                          dropdownContent={
                            <MenuList>
                              {areasToSelect.map((area) => (
                                <MenuItem
                                  key={area}
                                  onClick={() => {
                                    setFieldValue("items", [
                                      ...(values.items || []),
                                      {
                                        area: area,
                                      },
                                    ]);
                                  }}
                                >
                                  <ListItemIcon>
                                    <AppIcon of='add' fontSize='small' />
                                  </ListItemIcon>
                                  <ListItemText>
                                    <InlineApiEnumValue type='VehicleArea' value={area} />
                                  </ListItemText>
                                </MenuItem>
                              ))}
                            </MenuList>
                          }
                        >
                          Add item
                        </DropdownButton>
                      </Box>
                    </Stack>
                  </FoldableBlock>

                  {/* Other */}
                  <FoldableBlock
                    defaultIsFolded
                    trigger={{
                      label: (
                        <Typography component='span' variant='h6'>
                          Other
                        </Typography>
                      ),
                    }}
                  >
                    <TextField
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      multiline
                      rows={2}
                      helperText={touched.notes && errors.notes}
                      label='Notes'
                      margin='dense'
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.notes}
                      variant='outlined'
                    />

                    <FormControl fullWidth component='fieldset' margin='dense'>
                      <FileUploader
                        multiple
                        defaultFiles={FileItem.createManyFrom(values.initialAttachments)}
                        onChange={(newFiles) => {
                          setFieldValue(
                            `attachments`,
                            FileItem.toManyGeneralAttachmentInputDto(newFiles),
                          );
                        }}
                        onUploadStarted={() => {
                          setIsAttachmentFilesUploading(true);
                        }}
                        onUploadFinished={() => {
                          setIsAttachmentFilesUploading(false);
                        }}
                      />
                    </FormControl>
                  </FoldableBlock>

                  <Divider />

                  <Box>
                    <FormControl margin='dense'>
                      <FormLabel>Inspected by</FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled
                              checked={values.inspector?.isCurrentUser}
                              onChange={handleChange}
                              name='inspector.isCurrentUser'
                            />
                          }
                          label={
                            values.inspector?.personName
                              ? `${values.inspector.personName?.firstName} ${values.inspector.personName?.lastName}`
                              : profile?.personName?.name
                          }
                        />
                      </FormGroup>
                      <FormHelperText>
                        Currently logged in user inspected the vehicle
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Stack>

                {errors.settings && _.isString(errors.settings) && (
                  <FormHelperText error>{errors.settings}</FormHelperText>
                )}

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <LoadingButton
                  sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                  color='primary'
                  disabled={isAttachmentFilesUploading}
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}

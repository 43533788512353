import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { AssetSubscriptionPlanDto, AssetSubscriptionPlanReferenceDto } from "@/core/api/generated";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getAssetSubscriptionOptionTitle } from "./AssetSubscriptionPlanAutocomplete";

export interface AssetSubscriptionPlanInlineProps extends BaseEntityInlineInheritableProps {
  entity: AssetSubscriptionPlanDto | AssetSubscriptionPlanReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function AssetSubscriptionPlanInline({
  entity,
  entityId,
  ...otherProps
}: AssetSubscriptionPlanInlineProps) {
  const request = useApiRequest(
    apiClient.assetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionPlanId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as AssetSubscriptionPlanDto;
  const ref = entity as AssetSubscriptionPlanReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='entity' inText />}
      content={<>{full && getAssetSubscriptionOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Asset subscription plan"}
    />
  );
}

import { EntityType } from "@/core/api/generated";
import { AppArea } from "./routing";

/** Helper for building persistence keys. */
export class PersistenceKey {
  public static forEntity(entityType: EntityType): string {
    return entityType;
  }

  public static inFleetArea(key: string): string {
    return key;
  }

  public static inAdminArea(key: string): string {
    return `${AppArea.Admin}:${key}`;
  }

  public static forEntityInFleetArea(entityType: EntityType): string {
    return this.inFleetArea(entityType);
  }

  public static forEntityInAdminArea(entityType: EntityType): string {
    return this.inAdminArea(entityType);
  }

  public static forView(key: string): string {
    return `${key}:view`;
  }

  public static forEntityListView(entityType: EntityType): string {
    return `${entityType}:list`;
  }

  public static forEntityView(entityType: EntityType): string {
    return `${entityType}:view`;
  }
}

import { SortDefinitionDto } from "@/core/api/generated";
import { ArrayHelper } from "../helpers/array";
import { SortHelper } from "../helpers/sort";
import { TypeHelper } from "../helpers/type";
import { SortDefinitionItem } from "./sortDefinitionItem";

/** Definition of final sorts that can used for data sorting.
 * NB: must be serializable to/from JSON.
 */
export class SortDefinition {
  public items: SortDefinitionItem[];

  constructor(params: { items: SortDefinitionItem[] }) {
    this.items = params.items.map((x) => new SortDefinitionItem(x));
  }

  // #region Static

  public static newEmpty(): SortDefinition {
    return new SortDefinition({ items: [] });
  }

  // #endregion

  public get isValid(): boolean {
    return !TypeHelper.isEmpty(this.items) && this.items.every((x) => x.isValid);
  }

  public getItemById(itemId: string): SortDefinitionItem | undefined {
    return this.items.find((x) => x.id === itemId);
  }

  public addItem(newItem: SortDefinitionItem): void {
    this.items.push(newItem);
  }

  public replaceItem(newItem: SortDefinitionItem): void {
    ArrayHelper.replaceByPredicate(this.items, (x) => x.id === newItem.id, newItem);
  }

  public replaceItemById(itemId: string, newItem: SortDefinitionItem): void {
    ArrayHelper.replaceByPredicate(this.items, (x) => x.id === itemId, newItem);
  }

  public removeItem(item: SortDefinitionItem): void {
    ArrayHelper.removeByPredicate(this.items, (x) => x.id === item.id);
  }

  public removeItemById(itemId: string): void {
    ArrayHelper.removeByPredicate(this.items, (x) => x.id === itemId);
  }

  /** Removes empty items to make sure the definition is relevant and valid. */
  public cleanup(): void {
    const invalidItems = this.items.filter((x) => !x.isValid);
    if (!TypeHelper.isEmpty(invalidItems)) {
      console.warn(
        `Found ${invalidItems.length} invalid items during cleanup. Invalid items:`,
        invalidItems,
      );
    }

    this.items = this.items.filter((x) => x.isValid);
  }

  public clone(): SortDefinition {
    return new SortDefinition({ items: this.items.map((x) => x.clone()) });
  }

  /** Converts internal values to valid format before serialization to JSON. */
  public formatBeforeJsonSerialization(): void {
    this.items.forEach((x) => x.formatBeforeJsonSerialization());
  }

  public mapToDto(): SortDefinitionDto | undefined {
    this.cleanup();
    if (!this.isValid) {
      return undefined;
    }
    return SortHelper.mapSortDefinitionToSortDefinitionDto(this);
  }

  public serializeIntoString(): string | undefined {
    this.cleanup();
    if (!this.isValid) {
      return undefined;
    }
    const dto = this.mapToDto();
    const json = JSON.stringify(dto);
    return json;
  }
}
